import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import React from 'react'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import withState from 'recompose/withState'

import colors from 'config/theme/colors'

import Panel from '../../../panel'

import Entry from './components/entry'
import AuditSelect from './components/audit-select'
import { withTranslation } from 'react-i18next'

const LIST_ID = 'all'

const auditModule = getModule('audits')

export default compose(
  withState('error', 'setError'),
  withState('step', 'setStep', 0),
  withState('auditId', 'setAuditId'),
  withTranslation(),
  withHandlers({ handleOnPrev, handleOnNext }),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(buildProps),
  lifecycle({ componentWillMount })
)(NewAudit)

function NewAudit(props) {
  const {
    auditId,
    error,
    handleOnNext,
    handleOnPrev,
    isResolving,
    onClose: handleClose,
    onFirstStep,
    onLastStep,
    setAuditId,
    title,
    toggleExpand,
    toggleIcon,
    urlGeometry,
    t,
  } = props

  return (
    <Panel
      color={colors.pink}
      error={error}
      isResolving={isResolving}
      onClose={handleClose}
      resource={t('auditPanel.header.audit')}
      title={title}
      toggleExpand={toggleExpand}
      toggleIcon={toggleIcon}
    >
      {onFirstStep && (
        <AuditSelect
          auditId={auditId}
          onCancel={handleClose}
          onNext={handleOnNext}
          setAuditId={setAuditId}
        />
      )}
      {onLastStep && (
        <Entry
          auditId={auditId}
          onCancel={handleOnPrev}
          onClose={handleClose}
          urlGeometry={urlGeometry}
        />
      )}
    </Panel>
  )
}

function buildProps(props) {
  const { isResolving, step, t } = props

  const onFirstStep = step === 0
  const onLastStep = step === 1

  let title = t('auditPanel.header.newAudit')

  if (onFirstStep && isResolving) {
    title = t('panel.header.loadingTemplates')
  }
  if (onFirstStep && !isResolving) {
    title = t('panel.header.selectTemplate')
  }

  return {
    onFirstStep,
    onLastStep,
    title,
  }
}

function componentWillMount() {
  const { fetchAudits, setError } = this.props

  return fetchAudits({ perPage: 9999 })
    .then(handleResponse)
    .catch(error => setError(error))
}

function handleOnPrev({ setStep, step }) {
  return () => setStep(step - 1)
}

function handleOnNext({ setStep, step }) {
  return () => setStep(step + 1)
}

function handleResponse(response) {
  const { data, error } = response

  if (error) return Promise.reject(new Error(error))

  return data
}

function mapStateToProps(state) {
  const selectors = auditModule.selectors(state)(LIST_ID)
  const isResolving = selectors.state === 'resolving'

  return {
    isResolving,
    auditCache: state.audits.cache,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAudits: params => dispatch(auditModule.query(LIST_ID, params)),
  }
}
