import Radium from 'radium'
import React from 'react'

import styles from './styles'

interface TextProps {
  bold: boolean
  children: any
  dataTestId?: string
  inline: boolean
  large: boolean
  medium: boolean
  small: boolean
  title: string
  xsmall: boolean
}

const BlockTextComponent = Radium(BlockText)
const InlineTextComponent = Radium(InlineText)

function Text(props: TextProps) {
  const {
    bold,
    children,
    dataTestId,
    inline,
    large,
    medium,
    small,
    title,
    xsmall,
    ...styleProps
  } = props

  const style: any = [styleProps, styles.defaultFont]

  if (bold) {
    style.push(styles.bold)
  }

  large
    ? style.push(styles.large)
    : medium
    ? style.push(styles.medium)
    : small
    ? style.push(styles.small)
    : xsmall
    ? style.push(styles.xsmall)
    : null

  const TextComponent = inline ? InlineTextComponent : BlockTextComponent

  return (
    <TextComponent dataTestId={dataTestId} style={style} title={title}>
      {children}
    </TextComponent>
  )
}

interface BaseProps {
  children: any
  dataTestId: string
  style: object
  title: string
}

function BlockText(props: BaseProps) {
  const { children, dataTestId, style, title } = props
  return (
    <p data-testid={dataTestId} style={style} title={title}>
      {children}
    </p>
  )
}

function InlineText(props: BaseProps) {
  const { children, dataTestId, style, title } = props
  return (
    <span data-testid={dataTestId} style={style} title={title}>
      {children}
    </span>
  )
}

export default Text
