import { pure } from 'recompose'
import React from 'react'

import { Block, Flex } from 'components/common'
import { colors, typography } from 'config/theme'
import { useTranslation } from 'react-i18next'

export default pure(NoMessagesBox)

function NoMessagesBox() {
  const { t } = useTranslation()

  return (
    <Flex height="100%" align="center" justify="center">
      <Block
        flexGrow={1}
        color={colors.gray.light}
        fontSize={typography.fontSizes.large}
        fontWeight={600}
        padding={64}
        textAlign="center"
      >
        {t('labelNoMessagesYet')}
      </Block>
    </Flex>
  )
}
