import {
  isRequired,
} from '../../validation'

export default {
  reference: {
    required: true,
    validate: [isRequired()],
  },
  date: {
    required: true,
    validate: [isRequired()],
  },
  status: {
    required: true,
    validate: [isRequired()],
  },
  time: {
    required: true,
    validate: [isRequired()],
  },
}
