import { chain, concat, filter, includes, map } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import Radium from 'radium'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Block } from 'components/common'
import colors from 'config/theme/colors'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'

import ControlWrapper from '../../../control-wrapper'
import FieldWrapper from '../../../field-wrapper'
import ListItem from '../../../list-item'
import emitter from 'utils/emitter'

const areas = getModule('areas')

class IssueTemplateList extends Component {
  constructor(props) {
    super(props)

    this.onCancel = this.onCancel.bind(this)
    this.onTemplateSelect = this.onTemplateSelect.bind(this)
    this.saveIssues = this.saveIssues.bind(this)

    const selectedIssueTemplates = props.areaIssues || []

    this.state = {
      selectedIssueTemplates,
    }
  }

  render() {
    const { cacheTemplates, t } = this.props

    const { selectedIssueTemplates } = this.state

    const templateItems = chain(cacheTemplates)
      .filter(['entity.type', 'issue'])
      .sortBy(['entity.name'])
      .map(({ entity }, index) => (
        <ListItem
          active={includes(selectedIssueTemplates, entity._id)}
          item={entity}
          key={index}
          locked={entity.locked}
          display="flex"
          alignItems="center"
          onClick={() => {
            if (entity.locked) {
              return emitter.emit('notification:add', {
                message: t('alert.message.locationAddIssueTemplateLocked'),
                title: t('alert.title.error'),
                theme: 'alert',
              })
            }
            this.onTemplateSelect(entity._id)
          }}
        />
      ))
      .value()

    const isDisabled = selectedIssueTemplates.length === 0

    return (
      <Block>
        <Block
          background={colors.gray.white}
          borderBottom={`1px solid ${colors.gray.lightest}`}
          fontSize={12}
          paddingTop={20}
          paddingRight={15}
          paddingBottom={20}
          paddingLeft={15}
        >
          Select new issue forms:
        </Block>
        <FieldWrapper top={156}>{templateItems}</FieldWrapper>
        <ControlWrapper>
          <ButtonGroup>
            <Button onClick={this.onCancel}>{t('button.cancel')}</Button>
            <Button
              disabled={isDisabled}
              theme="positive"
              onClick={this.saveIssues}
            >
              {t('button.save')}
            </Button>
          </ButtonGroup>
        </ControlWrapper>
      </Block>
    )
  }

  onCancel() {
    this.props.updateAreaView({ isAddIssueTemplateMode: false })
  }

  onTemplateSelect(id) {
    const { selectedIssueTemplates } = this.state

    if (includes(selectedIssueTemplates, id)) {
      return this.setState({
        selectedIssueTemplates: filter(
          selectedIssueTemplates,
          template => template !== id
        ),
      })
    }

    this.setState({
      selectedIssueTemplates: concat(selectedIssueTemplates, id),
    })
  }

  saveIssues() {
    const { area, updateAreaView, updateReferences } = this.props

    const { selectedIssueTemplates } = this.state

    const areaEntity = area.entity
    const id = areaEntity._id
    const issues = selectedIssueTemplates

    updateAreaView({ isLoading: true })

    return updateReferences(id, issues)
      .catch(handleError)
      .finally(() =>
        updateAreaView({
          isLoading: false,
          isAddIssueTemplateMode: false,
        })
      )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  Radium
)(IssueTemplateList)

function mapStateToProps(state) {
  return {
    cacheTemplates: state.templates.cache,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateReferences: (areaId, issueIds) =>
      dispatch(areas.updateReferences(areaId, 'issues', issueIds)),
  }
}

function handleError(error) {
  const message = error.message || 'Error saving issue template for area'
  console.error(message)
}
