import { Icon } from 'leaflet'

export const beaconIcon = new Icon({
  iconUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/map-pin.svg',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [16, 16],
})

export const nfcIcon = new Icon({
  iconUrl: 'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/nfc.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [16, 18],
})

export const qrIcon = new Icon({
  iconUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/qr-red.svg',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [14, 14],
})

export const activeBeaconIcon = new Icon({
  iconUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/map-pin-active.svg',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [28, 28],
})

export const activeNfcIcon = new Icon({
  iconUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/nfc_active.png',
  shadowUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/active_nfc_shadow.png',

  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [16, 18],
  shadowSize: [26, 28],
})

export const activeQrIcon = new Icon({
  iconUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/qr_active.png',
  shadowUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/active_qr_shadow.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [14, 14],
  shadowSize: [24, 24],
})

export const duressIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/pin-duress.svg',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  labelAnchor: [-30, 30],
})

export const issueIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/pin-issue.svg',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  labelAnchor: [-30, 30],
})

export const taskIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/pin-task.svg',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  labelAnchor: [-30, 30],
})

export const personIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/person.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const personActiveIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/person-active.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const personRecentIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/person-recent.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const personInactiveIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/person-inactive.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const vehicleActiveIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/vehicle-active-2.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const vehicleRecentIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/vehicle-recent-2.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const vehicleInactiveIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/vehicle-inactive-2.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const bicycleActiveIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/bike-active.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const bicycleRecentIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/bike-recent.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const bicycleInactiveIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/bike-inactive.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated',
  labelAnchor: [-30, 30],
})

export const patientIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/patient-alt.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [-40, 12],
  popupAnchor: [52, -14],
  className: 'leaflet-marker-icon-animated asset',
  labelAnchor: [22, 30],
})

export const equipmentIcon = new Icon({
  iconUrl: 'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/iv.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [-14, 12],
  popupAnchor: [26, -14],
  className: 'leaflet-marker-icon-animated asset',
  labelAnchor: [-4, 30],
})

export const machineryIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/machinery.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  className: 'leaflet-marker-icon-animated asset',
  labelAnchor: [-30, 30],
})

export const visitorIcon = new Icon({
  iconUrl: 'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/man.png',
  // if icon size is only specified, the 'point' of the marker will be
  // centered
  iconSize: [24, 24],
  iconAnchor: [-14, 12],
  popupAnchor: [26, -14],
  className: 'leaflet-marker-icon-animated asset',
  labelAnchor: [-4, 30],
})

export const buildingIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/pin-building-2.svg',
  iconSize: [24, 32],
  iconAnchor: [12, 32],
  popupAnchor: [0, -20],
  labelAnchor: [30, 30],
})

export const healthIcon = new Icon({
  iconUrl:
    'https://s3.amazonaws.com/assets-lighthouse-io/maps/icons/pin-health-2.svg',
  iconSize: [24, 32],
  iconAnchor: [12, 32],
  popupAnchor: [0, -20],
  labelAnchor: [30, 30],
})

export const crosshairIcon = new Icon({
  className: 'crosshair',
  iconUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/crosshair.png',
  iconSize: [15, 15],
  iconAnchor: [7, 8],
  popupAnchor: [0, 0],
  labelAnchor: [0, 0],
})

export const crosshairRedIcon = new Icon({
  className: 'crosshair',
  iconUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/crosshair-red.png',
  iconSize: [15, 15],
  iconAnchor: [7, 8],
  popupAnchor: [0, 0],
  labelAnchor: [0, 0],
})

export const unknownLocationIcon = new Icon({
  iconUrl:
    'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/pin-location-unknown.svg',
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  labelAnchor: [-30, 30],
  popupAnchor: [0, -14],
})

export const auditIcon = new Icon({
  iconUrl: 'https://assets-lighthouse-io.s3.amazonaws.com/img/audit-task.svg',
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -14],
  labelAnchor: [-30, 30],
})
