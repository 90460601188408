import colors from 'config/theme/colors'

export default {
  root: {
    color: colors.gray.light,
    display: 'block',
    textAlign: 'center',
    paddingTop: '2em',
    paddingBottom: '2em',
  },
}
