import { compose, lifecycle, withHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'
import { get, includes, stubObject } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import Immutable from 'seamless-immutable'
import React from 'react'

import MapControls from 'components/mapping/map-controls'
import emitter from 'utils/emitter'

const geoModule = getModule('geo')

const MARKER_OPTIONS = [
  'auditentries',
  'taskentries',
  'location',
  'issue',
  'signal',
  'signal.qrcode',
  'signal.beacon',
  'signal.nfc',
  'tooltip',
  'user',
]
const MAP_GEO_OPTIONS = ['geofences']
const MAP_TILE_OPTIONS = ['map', 'satellite']

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props => ({
    filters: {
      ...props.markerFilters,
      ...props.mapOptions,
      map: props.mapTile === 'map',
      satellite: props.mapTile === 'satellite',
    },
  })),
  withHandlers({ handleFilterChange })
)(ConnectedMapControls)

function ConnectedMapControls(props) {
  const { filters, handleFilterChange, markerFilters, mapOptions } = props

  return (
    <MapControls
      bottom={20}
      filters={filters}
      left={20}
      onFilterChange={handleFilterChange}
    />
  )
}

function handleFilterChange(props) {
  const {
    filters: currentFilters,
    markerFilters,
    onMapOptionChange: handleMapOptionChange,
    setMarkerFilters,
    setProperties,
  } = props

  return (filterKey, filterValue) => {
    // NOTE remove popup as clusters will be recomputed on filters change
    emitter.emit('markers:closePopup')

    // NOTE: we handle amalgamated filters stored in different state locations
    // so need to update each group independently
    if (includes(MAP_TILE_OPTIONS, filterKey)) {
      return setProperties({ mapTile: filterKey })
    }

    if (includes(MARKER_OPTIONS, filterKey)) {
      const nextFilters = Immutable.setIn(
        markerFilters,
        ['types', filterKey],
        filterValue
      )
      return setMarkerFilters(nextFilters)
    }

    return handleMapOptionChange(filterKey, filterValue)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setMarkerFilters: filters => dispatch(geoModule.setMarkerFilters(filters)),
    setProperties: options => dispatch(geoModule.setProperties(options)),
  }
}

function mapStateToProps(state) {
  return {
    markerFilters: get(state.geo, 'markers.filters.types', stubObject()),
  }
}
