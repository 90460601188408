import moment from 'moment'
import 'moment-timezone'

const FULL_FORMAT = 'DD MMM YYYY - h:mm:ss a'

/**
 * Converts date to supplied timezone
 * @param {Date} date
 * @param {String} timezone
 * @returns {Moment}
 */
export function getDateInTimezone(date, timezone) {
  const mDate = moment(date)
  const year = mDate.year()
  const dateOfMonth = mDate.date()
  const month = mDate.month()
  const hour = mDate.hour()
  const minute = mDate.minute()

  const dateInTimezone = moment(date)
    .tz(timezone)
    .year(year)
    .month(month)
    .date(dateOfMonth)
    .hour(hour)
    .minute(minute)

  return dateInTimezone
}

export function formatDateInTimezone(date, timezone) {
  const datetime = moment(new Date(date)).tz(timezone)
  const formattedDate = datetime.format(FULL_FORMAT)
  const zoneName = datetime.zoneName()
  const formatted = `${formattedDate} (${zoneName})`
  return formatted
}
