import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure, withHandlers, withProps, withState } from 'recompose'
import { noop, orderBy } from 'lodash'

import { List } from 'react-virtualized'
import { Flex } from 'components/common'
import ButtonGroup from 'components/button-group'
import Button from 'components/button'
import Chevron from 'components/chevron'

// NOTE Scrollbar offset is width to offset scroll bar UI which accommodates
// multiple browsers. See:
// https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
const SCROLLBAR_OFFSET = 35
const ROW_HEIGHT = 40
const ROW_WIDTH = 42
const VISIBLE_COUNT = 8
const LIST_MAX_HEIGHT = ROW_HEIGHT * VISIBLE_COUNT
const LIST_WIDTH = ROW_WIDTH + SCROLLBAR_OFFSET

const styles = {
  list: {
    overflowX: 'hidden',
    paddingRight: 15,
    width: LIST_WIDTH,
  },
}

export default compose(
  pure,
  withProps(mapBaseProps),
  withState('index', 'setIndex', props =>
    props.canScroll ? props.bottomOffset : 0
  ),
  // NOTE we need a scrollIndex as well as an index so we can keep track of
  // manual scrolling and clicks. We could change this in future to just use
  // scroll events when we migrate to react 16 as they support refs in
  // functional components (which will allow us to access
  // `list.scrollToPosition`)
  withState('scrollIndex', 'setScrollIndex'),
  withProps(mapScrollProps),
  withHandlers({
    handleScroll,
    handleScrollDown,
    handleScrollUp,
    rowRenderer,
  })
)(FloorNavigation)

function FloorNavigation(props) {
  const {
    bottomOffset,
    canScroll,
    canScrollDown,
    canScrollUp,
    floorCount,
    floors,
    handleScroll,
    handleScrollDown,
    handleScrollUp,
    index,
    rowRenderer,
    selectedLevel,
  } = props

  if (!floors) return null

  const height = canScroll ? LIST_MAX_HEIGHT : floorCount * ROW_HEIGHT

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-end"
      overflow="hidden"
      width={ROW_WIDTH}
    >
      {canScroll && (
        <Button
          disabled={!canScrollUp}
          first
          onClick={handleScrollUp}
          noPadding
          small
          stacked
          theme="simple"
        >
          <Chevron up />
        </Button>
      )}
      <List
        height={height}
        onScroll={handleScroll}
        rowCount={floorCount}
        rowHeight={ROW_HEIGHT}
        rowRenderer={rowRenderer}
        selectedLevel={selectedLevel}
        scrollToAlignment="start"
        scrollToIndex={index}
        style={styles.list}
        width={LIST_WIDTH}
      />
      {canScroll && (
        <Button
          disabled={!canScrollDown}
          last
          onClick={handleScrollDown}
          noPadding
          small
          stacked
          theme="simple"
        >
          <Chevron down />
        </Button>
      )}
    </Flex>
  )
}

function handleScroll(props) {
  const { setScrollIndex } = props
  return ({ clientHeight, scrollHeight, scrollTop }) => {
    const scrollIndex = Math.round(scrollTop / ROW_HEIGHT)
    setScrollIndex(scrollIndex)
  }
}

function handleScrollDown(props) {
  const { canScrollDown, index, scrollIndex, setIndex } = props

  if (!canScrollDown) return noop

  const nextSiblingIndex = index + 1
  const nextScrollIndex = scrollIndex + 1
  const nextIndex =
    nextScrollIndex === nextSiblingIndex ? nextSiblingIndex : nextScrollIndex

  return () => setIndex(nextIndex)
}

function handleScrollUp(props) {
  const { canScrollUp, index, scrollIndex, setIndex } = props

  if (!canScrollUp) return noop

  const nextSiblingIndex = index - 1
  const nextScrollIndex = scrollIndex - 1
  const nextIndex =
    nextScrollIndex === nextSiblingIndex ? nextSiblingIndex : nextScrollIndex

  return () => setIndex(nextIndex)
}

function mapBaseProps(props) {
  const { floors } = props
  const floorCount = floors.length
  const canScroll = floorCount > VISIBLE_COUNT

  // NOTE the bottom offset is the position of the visible item at the top of
  // the list when the first item is at the bottom. We need to use this because
  // our index references the list item at the top of the scroller
  const bottomOffset = canScroll && floorCount - VISIBLE_COUNT

  return {
    bottomOffset,
    canScroll,
    floorCount,
    floors: orderBy(floors, ['level'], ['desc']),
  }
}

function mapScrollProps(props) {
  const { bottomOffset, canScroll, index, scrollIndex } = props
  const canScrollDown = canScroll && scrollIndex < bottomOffset
  const canScrollUp = canScroll && scrollIndex > 0

  return { canScrollDown, canScrollUp }
}

function rowRenderer(props) {
  const {
    canScroll,
    floors,
    floorCount,
    handleSelectLevel,
    selectedLevel,
  } = props

  return data => {
    const { key, index, style } = data

    const floor = floors[index]
    const isFirst = !canScroll && index === 0
    const isLast = !canScroll && index + 1 === floorCount
    const isSelected = floor.level === selectedLevel
    const theme = isSelected ? 'positive' : 'simple'

    return (
      <Button
        first={isFirst}
        key={key}
        last={isLast}
        noPadding
        onClick={() => handleSelectLevel(floor.level)}
        theme={theme}
        stacked
        style={style}
        width={42}
      >
        {floor.labelShort}
      </Button>
    )
  }
}

FloorNavigation.propTypes = {
  floors: PropTypes.array.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  selectedLevel: PropTypes.number.isRequired,
}
