import React from 'react'
import { assign } from 'lodash'

const style = {
  borderBottom: '1px solid #eee',
  lineHeight: '1.5em',
  verticalAlign: 'top',
  paddingTop: '1em',
  paddingBottom: '1em',
  paddingRight: 20,
}

export default ({ children, width }) => {
  const customStyle = assign({}, style, {
    width: `${width}px`,
  })
  return <td style={customStyle}>{children}</td>
}
