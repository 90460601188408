/**
 * The messages middleware is responsible for registering and de-registering
 * message state for application users. It should listen to the switching of
 * applications and de-register the user for the current application and
 * register them for the next application
 */

import Promise from 'bluebird'

import {
  get,
  includes,
} from 'lodash'

import {
  actionCreators,
} from '../../modules/application-users'
import {
  SET_APPLICATION,
} from '../../modules/app'
import {
  AUTHENTICATE_SUCCESS,
  UNAUTHENTICATE_SUCCESS,
  AUTHENTICATION_UNSET,
} from '../../modules/authentication'

import { record } from '../../modules/logs'

const actionsToWatch = [
  SET_APPLICATION,
  AUTHENTICATE_SUCCESS,
  UNAUTHENTICATE_SUCCESS,
  AUTHENTICATION_UNSET,
]

export default store => next => (action) => {
  const isWatchedAction = includes(actionsToWatch, action.type)

  if (!isWatchedAction) {
    return next(action)
  }

  const state = store.getState()
  const dispatch = store.dispatch
  const currentApplication = get(state, 'app.applicationId')
  const isSetApplication = (action.type === SET_APPLICATION)
  const isLoggingIn = (action.type === AUTHENTICATE_SUCCESS)
  const isAuthenticated = get(state, 'authentication.accessToken')
  const isSwitchingApplication = (
    isSetApplication &&
    (currentApplication || isAuthenticated)
  )

  const isSwitchingToSameApplication = (
    isSwitchingApplication &&
    currentApplication === action.applicationId
  )

  const isSettingApplicationWhenUnauthenticated = (
    isSetApplication &&
    !isAuthenticated
  )

  const shouldSkipRegistration = (
    isSwitchingToSameApplication ||
    isSettingApplicationWhenUnauthenticated
  )

  // NOTE the message registration/deregistration should only be skipped when
  // the user is setting an application outside of authentication. It's also
  // important to return a promise here with the result of the action as that's
  // what setApplication expects because of the async nature of this middleware
  if (shouldSkipRegistration) return Promise.resolve(next(action))

  const deviceToken = get(state, 'mobile.deviceToken')
  const platform = get(state, 'mobile.platform')
  const isMobile = deviceToken && platform
  const messageRegistrationOpts = isMobile && {
    deviceToken,
    platform,
  }

  // only assign deregister if there is a current application set
  const deregister = () => (
    (isAuthenticated && currentApplication) ?
      dispatch(actionCreators.messagesDeregistration({ deviceToken })) :
      Promise.resolve()
  )

  // Register will be called when logging in (with a preset applicationId) or switching application
  const register = () => (
    ((isLoggingIn && currentApplication) || isSwitchingApplication) ?
      dispatch(actionCreators.messagesRegistration(messageRegistrationOpts)) :
      Promise.resolve()
  )

  // NOTE
  // the action we're watching should be fired in-between the
  // deregister/register actions. This is important because often the behaviour
  // of the app relies on data available in state. E.g. When switching apps, we
  // should deregister using the old application Id first, then set the new
  const promise = (
    deregister()
      .catch(catchMessagesError)
      .then(() => {
        return next(action)
      })
      .then(result => (
        register()
          .catch(catchMessagesError)
          .then(() => {
            return Promise.resolve(result)
          })
      ))
  )

  return promise

  // NOTE the purpose of this catch is really to swallow any errors from
  // messages de/registration. We don't want errors here to affect the
  // upstream action, we just want to log the problem. Registration issues are
  // usually configuration problems which the user can't solve themselves, so
  // there's not point holding them up from logging in/out
  function catchMessagesError(err) {
    dispatch(record('error', {
      message: 'MessagesError',
      data: {
        deviceToken,
        applicationId: currentApplication,
        err,
      },
    }))
    return Promise.resolve()
  }
}
