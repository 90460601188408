import moment from 'moment-timezone'

import { GetNext, Interval, Unit } from '../scheduling.types'

/**
 * Generates next occurrence interval for stopwatch and window strategy
 */
export function* getNext({
  end,
  isInitial,
  options,
  start,
  timezone,
}: GetNext): IterableIterator<Interval> {
  const { duration, frequency } = options
  const { unit: durationUnit, value: durationValue } = duration
  const { unit: frequencyUnit, value: frequencyValue } = frequency

  let initial: boolean = isInitial

  let dateCursor: number = initial
    ? moment.tz(start, timezone).valueOf()
    : moment
        .tz(start, timezone)
        .add(1, Unit.Millisecond)
        .valueOf()

  while (dateCursor < end) {
    const nextOccurrenceEnd = moment
      .tz(dateCursor, timezone)
      .add(frequencyValue, frequencyUnit)
      .valueOf()

    const nextOccurrenceStart = moment
      .tz(nextOccurrenceEnd, timezone)
      .subtract(durationValue, durationUnit)
      .valueOf()

    if (nextOccurrenceEnd <= nextOccurrenceStart || nextOccurrenceEnd > end) {
      return
    }

    if (nextOccurrenceStart >= start) {
      const nextOccurrence = [nextOccurrenceStart, nextOccurrenceEnd - 1]

      yield nextOccurrence
    }

    dateCursor = nextOccurrenceEnd
  }
}
