import { noop } from 'lodash'
import React from 'react'
import { Flex, Span } from 'components/common'
import Radio from 'components/radio'

interface TargetSelectProps {
  dataTestId?: string
  disabled?: boolean
  input: {
    name: string
    onChange: (value: string) => void
    value: string | string[]
  }
}

const options = [
  {
    label: 'All audits',
    value: 'all',
  },
  {
    label: 'Audits above or on target',
    value: 'above',
  },
  {
    label: 'Audits below target',
    value: 'below',
  },
]

export function TargetSelect(props: TargetSelectProps): JSX.Element {
  const { disabled = false, input } = props
  const { onChange, value: inputValue } = input

  return (
    <Flex flexDirection="column" paddingTop={12}>
      {options.map(({ label, value }) => {
        return (
          <RadioOption
            checked={value === inputValue}
            disabled={disabled}
            key={value}
            label={label}
            onClick={(): void => onChange(value)}
          />
        )
      })}
    </Flex>
  )
}

interface RadioOptionProps {
  checked: boolean
  disabled: boolean
  label: string
  onClick: () => void
}

function RadioOption(props: RadioOptionProps): JSX.Element {
  const { checked, disabled, label, onClick } = props

  return (
    <Flex alignItems="center" onClick={disabled ? noop : onClick}>
      <Radio checked={checked} disabled={disabled} />
      <Flex paddingBottom={8} paddingLeft={4} paddingTop={8}>
        <Span color={disabled ? '#aaa' : '#545454'}>{label}</Span>
      </Flex>
    </Flex>
  )
}
