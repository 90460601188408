/*
 * Graph Module
 */

import historyWrapper from '../../history'

const history = historyWrapper('graph', graphReducer)

export const {
  clearHistory,
  jump,
  jumpToFuture,
  jumpToPast,
  redo,
  resetHistory,
  setHistory,
  undo,
} = history.actionCreators

export const actions = history.actions
export const reducer = history.reducer

export function graphReducer(state = {}, action = {}) {
  switch (action.type) {
    case actions.SET_HISTORY:
      return action.data
    default:
      return state
  }
}
