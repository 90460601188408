import { isArray, isEmpty, join, first, map, startsWith } from 'lodash'
import Promise from 'bluebird'
import moment from 'moment-timezone'

import { buildFetchUrl, fetchImage } from '../../../helpers'
import { videoIcon } from '../../../images'
import { imageTables, parseValue, text, toPdf } from '../'

export function buildFile({ file, settings }) {
  const { extension, name, path } = file
  const { awsS3BaseUrl } = settings

  const link = `${awsS3BaseUrl}/${path}`
  return text(`${name}.${extension}`, { link, margin: [0, 0, 0, 10] })
}

function buildImage(options) {
  const {
    alignment = 'center',
    filepath,
    height = 210,
    settings,
    width = 210,
  } = options
  const { awsS3BaseUrl, cloudinaryBaseUrl } = settings

  const isVideoType = new RegExp('.mp4$').test(filepath)
  const link = `${awsS3BaseUrl}/${filepath}`

  if (isVideoType) {
    return {
      alignment,
      fit: [width, height],
      image: videoIcon,
      link,
    }
  }

  const url = buildFetchUrl(filepath, {
    awsS3BaseUrl,
    cloudinaryBaseUrl,
    fit: true,
    height: 400,
    width: 600,
    quality: 50,
  })

  return fetchImage(url).then(base64String => ({
    alignment,
    fit: [width, height],
    image: base64String,
    link,
  }))
}

export function buildSummaryField({ field, settings, timezone }) {
  const { fieldtype, options = {}, value } = field

  const isDayFirstDateField =
    fieldtype === 'date' && options.format === 'DD/MM/YY'
  const isMonthFirstDateField =
    fieldtype === 'date' && options.format === 'MM/DD/YY'
  const isFileField = fieldtype === 'file'
  const isHtmlField = fieldtype === 'text' && options.type === 'html'
  const isNumberField = fieldtype === 'number'
  const isPhotoField = fieldtype === 'list' && options.type === 'media'
  const isReferenceField = fieldtype === 'reference'
  const isSelectField = fieldtype === 'select' && options.type !== 'stars'
  const isSignatureField = fieldtype === 'text' && options.type === 'signature'
  const isStarField = fieldtype === 'select' && options.type === 'stars'
  const isSwitchField = fieldtype === 'switch'
  const isTextField = fieldtype === 'text'
  const is12HourTimeField = fieldtype === 'time' && options.format === 'h:mm a'
  const is24HourTimeField = fieldtype === 'time' && options.format === 'HH:mm'

  // NOTE: html field is not supported for the summary fields but adding
  // logic to catch this and return empty text for completeness
  if (isHtmlField) {
    return { text: '' }
  }

  if (isSignatureField) {
    if (!value) return ''

    if (isDataImage(value)) {
      return { alignment: 'left', image: value, width: 140 }
    }

    return fetchImage(value).then(base64String => {
      return {
        alignment: 'left',
        image: base64String,
        width: 140,
      }
    })
  }

  if (isPhotoField) {
    const filepath = first(value)

    // NOTE: if no filepath return empty text as user has not uploaded an image
    // for this field
    if (!filepath) {
      return { text: '' }
    }

    const image = buildImage({
      alignment: 'left',
      filepath,
      height: 140,
      width: 140,
      settings,
    })

    return image
  }

  if (isFileField) {
    const fileList = map(value, file => buildFile({ file, settings }))
    return { ol: fileList }
  }

  if (isNumberField || isSelectField || isSwitchField || isTextField) {
    const parsedValue = parseValue(value)
    const displayValue = isArray(parsedValue)
      ? { text: join(parsedValue, ', '), fontSize: 7 }
      : { text: parsedValue, fontSize: 7 }

    return displayValue
  }

  if (isReferenceField) {
    const parsedValue = parseValue(value)
    const displayValue = isArray(parsedValue)
      ? {
          text: parsedValue
            .filter(val => val && val.label)
            .map(val => val.label)
            .join(', '),
          fontSize: 7,
        }
      : { text: parsedValue.label, fontSize: 7 }

    return displayValue
  }

  if (isStarField) {
    if (!value) return ''
    const displayValue = value === '1' ? `${value} star` : `${value} stars`
    return displayValue
  }

  if (
    isDayFirstDateField ||
    isMonthFirstDateField ||
    is12HourTimeField ||
    is24HourTimeField
  ) {
    const format = options.format
    const displayValue = moment(value)
      .tz(timezone)
      .format(format)

    return displayValue
  }

  // NOTE: return empty text fallback value when no match
  return { text: '' }
}

export function buildTemplateFieldRow({ field, settings, timezone, skipped }) {
  const { fieldtype, label, options = {}, value } = field
  const { showOnRead = true } = options

  const isDayFirstDateField =
    fieldtype === 'date' && options.format === 'DD/MM/YY'
  const isMonthFirstDateField =
    fieldtype === 'date' && options.format === 'MM/DD/YY'
  const isFileField = fieldtype === 'file'
  const isHtmlField = fieldtype === 'text' && options.type === 'html'
  const isNumberField = fieldtype === 'number'
  const isPhotoField = fieldtype === 'list' && options.type === 'media'
  const isSelectField = fieldtype === 'select' && options.type !== 'stars'
  const isSignatureField = fieldtype === 'text' && options.type === 'signature'
  const isStarField = fieldtype === 'select' && options.type === 'stars'
  const isSwitchField = fieldtype === 'switch'
  const isTextField = fieldtype === 'text'
  const is12HourTimeField = fieldtype === 'time' && options.format === 'h:mm a'
  const is24HourTimeField = fieldtype === 'time' && options.format === 'HH:mm'
  const isDisplayImageField = fieldtype === 'image-display' && showOnRead
  const isReferenceField = fieldtype === 'reference'

  // NOTE: only show field when show on read is true
  if (!showOnRead) return null

  const labelText = [{ text: label }]

  if (skipped) {
    return [labelText, [{ text: '-' }]]
  }

  if (isHtmlField) {
    const parsed = value.replace(/<p><\/p>/g, '')
    return [labelText, toPdf(parsed)]
  }

  if (isSignatureField) {
    if (!value) return [labelText, '']

    return fetchImage(value).then(base64String => {
      const values = {
        alignment: 'left',
        image: base64String,
        width: 140,
      }
      return [labelText, values]
    })
  }

  if (isDisplayImageField) {
    return Promise.map([value], filepath =>
      buildImage({ filepath, settings })
    ).then(fieldImages => [fieldImages, {}])
  }

  if (isPhotoField) {
    return Promise.map(value, filepath =>
      buildImage({ filepath, settings })
    ).then(fieldImages => {
      const tables = !isEmpty(fieldImages) ? imageTables(fieldImages) : []

      const imagesRow = {
        colSpan: 2,
        layout: {
          hLineWidth: () => 0,
          paddingLeft: () => 0,
          paddingRight: () => 0,
          paddingTop: () => 0,
          paddingBottom: () => 0,
          vLineWidth: () => 0,
        },
        table: {
          body: [labelText, ...tables],
          widths: ['*'],
        },
      }

      // NOTE: pass empty value here as otherwise
      // we can accidentally hide content below
      // this row. Please see the following issue:
      // https://github.com/bpampuch/pdfmake/issues/1598
      return [imagesRow, {}]
    })
  }

  if (isFileField) {
    const fileList = map(value, file => buildFile({ file, settings }))
    return [labelText, { ol: fileList }]
  }

  if (isNumberField || isSelectField || isSwitchField || isTextField) {
    const parsedValue = parseValue(value)
    const displayValue = isArray(parsedValue)
      ? { text: join(parsedValue, ', ') }
      : { text: parsedValue }

    return [labelText, displayValue]
  }

  if (isReferenceField) {
    const parsedValue = parseValue(value)
    const displayValue = isArray(parsedValue)
      ? {
          text: parsedValue
            .filter(val => val && val.label)
            .map(val => val.label)
            .join(', '),
          fontSize: 7,
        }
      : { text: parsedValue.label, fontSize: 7 }

    return [labelText, displayValue]
  }

  if (isStarField) {
    if (!value) return [labelText, '']
    const displayValue = value === '1' ? `${value} star` : `${value} stars`
    return [labelText, displayValue]
  }

  if (
    isDayFirstDateField ||
    isMonthFirstDateField ||
    is12HourTimeField ||
    is24HourTimeField
  ) {
    const format = options.format
    const displayValue = value
      ? moment(value)
          .tz(timezone)
          .format(format)
      : ''

    return [labelText, displayValue]
  }

  // NOTE: if no match above null values will be stripped within calling fn
  return null
}

function isDataImage(string) {
  return startsWith(string, 'data:image/png;')
}
