import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { compose, withHandlers, withState } from 'recompose'

import ConfirmationBox from 'components/confirmation-box'

export default compose(
  withState('isConfirming', 'setConfirming', false),
  withState(
    'isSubmitting',
    'setSubmitting',
    props => props.isSubmitting || false
  ),
  withHandlers({
    handleCancel,
    handleConfirm,
    handleConfirmed,
  })
)(Confirmable)

function Confirmable(props) {
  const {
    children,
    handleCancel,
    handleConfirm,
    handleConfirmed,
    isConfirming,
    isSubmitting,
    message,
    showMessageAbove,
    size,
  } = props

  const confirmEl = (
    <ConfirmationBox
      onCancel={handleCancel}
      onConfirm={handleConfirmed}
      showAbove={showMessageAbove}
      message={message}
      submitting={isSubmitting}
      size={size}
    />
  )

  return children({ isConfirming, handleConfirm, confirmEl })
}

Confirmable.propTypes = {
  children: PropTypes.func.isRequired,
  message: PropTypes.string,
  onConfirmed: PropTypes.func.isRequired,
  showMessage: PropTypes.bool,
  size: PropTypes.string,
  submitting: PropTypes.bool,
}

function handleCancel({ onCancel = noop, setConfirming }) {
  return async () => {
    await onCancel()
    setConfirming(false)
  }
}

function handleConfirm({ setConfirming }) {
  return () => setConfirming(true)
}

function handleConfirmed({ onConfirmed, setConfirming, setSubmitting }) {
  return async () => {
    setSubmitting(true)

    await onConfirmed()

    // NOTE If the parent component triggers an unmount when this
    // resolves we'll get a react error. There's not much we can do
    // about it as we don't have control here (without hacking some
    // state in for if the component is mounted)
    setSubmitting(false)
    setConfirming(false)
  }
}
