import {
  isNil,
} from 'lodash'
import Immutable, { isImmutable } from 'seamless-immutable'

export function parseState(state, fallbackState = {}) {
  if (isNil(state)) return Immutable(fallbackState)

  if (!isImmutable(state)) return Immutable(state)

  return state
}
