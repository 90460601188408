const {
  chain,
  compact,
  find,
  includes,
  isBoolean,
  isEmpty,
  keys,
  map,
  reduce,
  some,
} = require('lodash')
const moment = require('moment-timezone')

module.exports = {
  buildOverridesById,
  checkDefinition,
  checkOpeningHours,
  findMatchingDefinition,
  getStrategyKey,
  isOpen,
}

/**
 * buildOverridesById
 * build an object of override ids with priorities
 *
 * @param  {Array} overridesArr
 * @return {Object} { overrideId: priority }
 */
function buildOverridesById(overridesArr) {
  return reduce(
    overridesArr,
    (accum, { id, priority }) => {
      accum[id] = priority
      return accum
    },
    {},
  )
}

/**
 * checkDefinition
 * compare and match the date with the array of hours within the definition.
 *
 * @param  {Date} opts.date Date to query
 * @param  {Object} opts.definition: definition object
 * @param  {String} opts.timezone: timezone
 * @return {Boolean}
 */
function checkDefinition({ date, definition, timezone }) {
  const strategies = compact(map(definition, 'day'))
  const key = getStrategyKey(date, strategies, timezone)
  const matched = find(definition, ['day', key])

  if (!matched) return null

  return some(matched.hours, timePeriod =>
    checkOpeningHours({
      date,
      timePeriod,
      timezone,
    }),
  )
}

/**
 * checkOpeningHours
 * check whether a date is between given hours and minutes
 *
 * @param  {Date} opts.date
 * @param  {Object} opts.timePeriod
 * @param  {String} opts.timezone
 * @return {Boolean}
 */
function checkOpeningHours({ date, timePeriod, timezone }) {
  const { close, open } = timePeriod
  const additional = {
    millisecond: 0,
    second: 0,
  }
  const closingTime = moment(date).tz(timezone).set({ ...close, ...additional })
  const openingTime = moment(date).tz(timezone).set({ ...open, ...additional })

  // NOTE: see https://moment.com/docs/#/query/is-before/ for opts below
  return moment(date).isBetween(openingTime, closingTime, 'minute', '[]')
}

/**
 * getStrategyKey
 * get the relevant strategy key based on what strategies are available
 *
 * @param  {Date} datetime
 * @param  {Array} strategies
 * @param  {String} timezone
 * @return {String}
 */
function getStrategyKey(datetime, strategies, timezone = 'UTC') {
  const queryDate = moment(datetime).tz(timezone)
  const day = queryDate.format('dddd').toLowerCase()
  const date = queryDate.format('YYYY-MM-DD').toString()

  if (includes(strategies, date)) {
    return date
  }

  if (includes(strategies, day)) {
    return day
  }

  return 'default'
}

/**
 * isOpen
 * parses the core serviceHour definition + overrides to determine
 * whether the hours are open or closed
 *
 * @param  {Date} opts.date (optional) - defaults to now
 * @param  {Object} opts.serviceHours serviceHours definition to check
 * @param  {Object} opts.serviceHoursCache serviceHours document cache
 * @param  {String} opts.timezone timezone of the serviceHours
 * @return {Boolean} isOpen
 */
function isOpen({
  date = new Date(),
  serviceHours,
  serviceHoursCache = [],
  timezone,
}) {
  const { definition, overrides } = serviceHours
  if (!timezone) {
    return new Error('Error: timezone is a required')
  }

  if (isEmpty(definition)) {
    return false
  }

  const overridePrioritiesById = buildOverridesById(overrides)
  const overrideDefinitions = chain(serviceHoursCache)
    .filter(({ _id }) => includes(keys(overridePrioritiesById), _id))
    .map(
      item => ({
        definition: item.definition,
        priority: overridePrioritiesById[item._id], // eslint-disable-line no-underscore-dangle
      }),
    )
    .orderBy(['priority'], ['desc'])
    .value()

  const combinedDefinitions = [
    ...overrideDefinitions,
    { definition, priority: 0 },
  ]

  const result = findMatchingDefinition({
    date,
    definitions: combinedDefinitions,
    timezone,
  })

  return result.isOpen
}

/**
 * findMatchingDefinition
 * Find and return the matching definition of the highest priority.
 *
 * @param  {Date} opts.date Date to query
 * @param  {Array} opts.definitions Definitions
 * @param  {String} opts.timezone timezone
 * @return {Object} definition
 */
function findMatchingDefinition({ date, definitions, timezone }) {
  return chain(definitions)
    .map(item => ({
      isOpen: checkDefinition({
        date,
        definition: item.definition,
        timezone,
      }),
      priority: item.priority,
    }))
    .orderBy(['priority'], ['desc'])
    .find(item => isBoolean(item.isOpen))
    .value()
}
