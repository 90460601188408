import PropTypes from 'prop-types'
import { isFunction, isPlainObject } from 'lodash'

import withPermissions from '../with-permissions'

export default withPermissions(HasPermission)

function HasPermission(props) {
  const {
    module,
    action = 'read',
    hasModulePermission,
    children,
  } = props

  const canAccessModule = !!hasModulePermission(module, action)

  // render empty component if no module permission
  if (!canAccessModule) return null

  const isRenderCallack = isFunction(children)

  if (isRenderCallack) return children(canAccessModule)

  const isSingleElement = isPlainObject(children)

  if (isSingleElement) return children

  // Otherwise the definition is invalid
  console.error('Invalid children supplied to <HasPermission /> component')

  return null
}

HasPermission.propTypes = {
  module: PropTypes.string.isRequired,
  action: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
}
