/*
 * Auth Module
 * Responsible for handling data required for authentication
 *
 * Example State:
 * ==============
 *
 * {
 *   accessToken: '123_abc',
 *   loading: false,
 *   error: {
 *     name: 'AuthenticationError',
 *     message: 'The request you made could not be authenticated'
 *   }
 * }
 */

import { get } from 'lodash'

import {
  getRegionUrl,
  authorizationHeadersFn,
} from '../app'

import hooks from './hooks'

import { REQUEST } from '../../middleware/request'
import { parseState } from '../../helpers'

export { default as schema } from './schema'

export const AUTHENTICATE_REQUEST = 'lighthouse/authentication/AUTHENTICATE_REQUEST'
export const AUTHENTICATE_SUCCESS = 'lighthouse/authentication/AUTHENTICATE_SUCCESS'
export const AUTHENTICATE_ERROR = 'lighthouse/authentication/AUTHENTICATE_ERROR'
export const UNAUTHENTICATE_REQUEST = 'lighthouse/authentication/UNAUTHENTICATE_REQUEST'
export const UNAUTHENTICATE_SUCCESS = 'lighthouse/authentication/UNAUTHENTICATE_SUCCESS'
export const UNAUTHENTICATE_ERROR = 'lighthouse/authentication/UNAUTHENTICATE_ERROR'
export const AUTHENTICATION_UNSET = 'lighthouse/authentication/AUTHENTICATION_UNSET'

hooks({ AUTHENTICATE_ERROR })


const initialState = {
  accessToken: null,
  loading: false,
  error: null,
}

export function reducer(state, action = {}) {
  state = parseState(state, initialState)

  switch (action.type) {
    case AUTHENTICATE_REQUEST:
      return state.merge({
        accessToken: null,
        loading: true,
        error: null,
      })
    // accept invite success returns auth token and will auto login
    // the user
    case AUTHENTICATE_SUCCESS:
      return state.merge({
        accessToken: get(action, 'data.accessToken'),
        loading: false,
        error: null,
      })
    case AUTHENTICATE_ERROR:
      return state.merge({
        accessToken: null,
        loading: false,
        error: action.error,
      })
    case UNAUTHENTICATE_REQUEST:
      return state.merge({
        loading: true,
      })
    case UNAUTHENTICATE_SUCCESS:
      return state.merge({
        accessToken: null,
        loading: false,
        error: null,
      })
    case UNAUTHENTICATE_ERROR:
      return state.merge({
        loading: false,
        error: action.error,
      })
    case AUTHENTICATION_UNSET:
      return state.merge({
        accessToken: null,
        loading: false,
        error: null,
      })

    default:
      return state
  }
}

export function authenticateRequest(data) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/authentication/login`
    return dispatch(authenticate(endpoint, data))
  }
}

function authenticate(endpoint, data) {
  const action = {
    types: [AUTHENTICATE_REQUEST, AUTHENTICATE_SUCCESS, AUTHENTICATE_ERROR],
    method: 'POST',
    body: data,
    endpoint,
    requiresAuthorization: false,
  }

  return {
    [REQUEST]: action,
  }
}

export function authenticateAsApplicationRequest(applicationId, body) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/applications/${applicationId}/authorizations/authenticate`
    return dispatch(authenticateAsApplication(endpoint, applicationId, body))
  }
}

function authenticateAsApplication(endpoint, applicationId, body) {
  return {
    [REQUEST]: {
      types: [AUTHENTICATE_REQUEST, AUTHENTICATE_SUCCESS, AUTHENTICATE_ERROR],
      method: 'POST',
      body,
      endpoint,
    },
  }
}

export function unauthenticateRequest(data) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/authentication/logout`
    const headers = authorizationHeadersFn(null, state)
    return dispatch(unauthenticate(endpoint, headers, data))
  }
}

export function unauthenticate(endpoint, headers, data) {
  return {
    [REQUEST]: {
      types: [UNAUTHENTICATE_REQUEST, UNAUTHENTICATE_SUCCESS, UNAUTHENTICATE_ERROR],
      headers,
      method: 'POST',
      body: data,
      endpoint,
    },
  }
}

export function clearAuthentication() {
  return { type: AUTHENTICATION_UNSET }
}
