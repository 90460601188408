import bbox from '@turf/bbox'
import buffer from '@turf/buffer'
import convex from '@turf/convex'

const DEFAULT_BUFFER = 0.05 // 50 meters

/**
 * Returns map bounds in format expected by leaflet
 * @param {GeoJSON} geojson
 * @param {Number} bufferKm
 * @returns {Array} [[lat, lng], [lat, lng]]
 */
export function geoJsonToMapBounds(geojson, bufferKm = DEFAULT_BUFFER) {
  const bufferedGeoJson = buffer(geojson, bufferKm)
  const bounds = bbox(bufferedGeoJson)

  const ne = [bounds[1], bounds[0]]
  const sw = [bounds[3], bounds[2]]
  return [ne, sw]
}
