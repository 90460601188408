import Radium from 'radium'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { withTranslation } from 'react-i18next'
import { chain, concat, filter, includes } from 'lodash'
import colors from 'config/theme/colors'
import { Block } from 'components/common'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'

import ControlWrapper from '../../../control-wrapper'
import FieldWrapper from '../../../field-wrapper'
import ListItem from '../../../list-item'
import emitter from 'utils/emitter'

const areas = getModule('areas')

class TaskTemplateList extends Component {
  constructor(props) {
    super(props)

    this.onCancel = this.onCancel.bind(this)
    this.onTaskSelect = this.onTaskSelect.bind(this)
    this.saveTasks = this.saveTasks.bind(this)

    const selectedTaskTemplates = props.areaTasks || []

    this.state = {
      selectedTaskTemplates,
    }
  }

  render() {
    const { cacheTemplates, t } = this.props

    const { selectedTaskTemplates } = this.state

    const taskItems = chain(cacheTemplates)
      .filter(['entity.type', 'task'])
      .sortBy(['entity.name'])
      .map(({ entity }, index) => (
        <ListItem
          active={includes(selectedTaskTemplates, entity._id)}
          item={entity}
          key={index}
          locked={entity.locked}
          display="flex"
          alignItems="center"
          onClick={() => {
            if (entity.locked) {
              return emitter.emit('notification:add', {
                message: t('alert.message.locationAddTaskTemplateLocked'),
                title: t('alert.title.error'),
                theme: 'alert',
              })
            }
            this.onTaskSelect(entity._id)
          }}
        />
      ))
      .value()

    const isDisabled = selectedTaskTemplates.length === 0

    return (
      <Block>
        <Block
          background={colors.gray.white}
          borderBottom={`1px solid ${colors.gray.lightest}`}
          fontSize={12}
          paddingTop={20}
          paddingRight={15}
          paddingBottom={20}
          paddingLeft={15}
        >
          Select new task forms:
        </Block>
        <FieldWrapper top={156}>{taskItems}</FieldWrapper>
        <ControlWrapper>
          <ButtonGroup>
            <Button onClick={this.onCancel}>{t('button.cancel')}</Button>
            <Button
              disabled={isDisabled}
              theme="positive"
              onClick={this.saveTasks}
            >
              {t('button.save')}
            </Button>
          </ButtonGroup>
        </ControlWrapper>
      </Block>
    )
  }

  onCancel() {
    this.props.updateAreaView({ isAddTaskTemplateMode: false })
  }

  onTaskSelect(id) {
    const { selectedTaskTemplates } = this.state

    if (includes(selectedTaskTemplates, id)) {
      return this.setState({
        selectedTaskTemplates: filter(
          selectedTaskTemplates,
          task => task !== id
        ),
      })
    }

    this.setState({
      selectedTaskTemplates: concat(selectedTaskTemplates, id),
    })
  }

  saveTasks() {
    const { area, updateAreaView, updateReferences } = this.props

    const { selectedTaskTemplates } = this.state

    const areaEntity = area.entity
    const id = areaEntity._id
    const tasks = selectedTaskTemplates

    updateAreaView({ isLoading: true })

    return updateReferences(id, tasks)
      .catch(handleError)
      .finally(() =>
        updateAreaView({
          isAddTaskTemplateMode: false,
          isLoading: false,
        })
      )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  Radium
)(TaskTemplateList)

function mapStateToProps(state) {
  return {
    cacheTemplates: state.templates.cache,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateReferences: (areaId, taskIds) =>
      dispatch(areas.updateReferences(areaId, 'tasks', taskIds)),
  }
}

function handleError(error) {
  const message = error.message || 'Error saving tasks for area'
  console.error(message)
}
