import { getModule, validation } from '@lighthouse/sdk'
import { compose } from 'recompose'
import Promise from 'bluebird'

import { chain, get, some } from 'lodash'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, Field } from 'redux-form'

import { InputSelect } from 'components/form'
import i18next from 'i18next'

const contentEntries = getModule('content', 'entries')
const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

const FETCH_ERROR =
  'Unable to fetch relationship options. Please contact support.'

class RelationshipField extends Component {
  constructor(props) {
    super(props)

    this.fetchOptions = this.fetchOptions.bind(this)
    this.getfilterCollectionFn = this.getfilterCollectionFn.bind(this)
    this.onChange = this.onChange.bind(this)

    this.state = {
      error: null,
      isLoading: false,
      selectOptions: [],
    }
  }

  componentWillMount() {
    this.setState({ isLoading: true })
    return this.fetchOptions()
  }

  render() {
    const { error, isLoading, selectOptions } = this.state

    const {
      field,
      fieldPath,
      showErrorOnUnTouched,
      showWarnOnUnTouched,
      small,
      readOnly,
    } = this.props

    const { label, options = {} } = field

    const { required } = options

    const name = `${fieldPath}.value.ids`
    const placeholder = `Select ${label}`
    const validate = required ? [isRequired] : []

    return (
      <Field
        clearable={false}
        component={InputSelect}
        error={error}
        isLoading={isLoading}
        label={label}
        multi
        name={name}
        onChange={this.onChange}
        options={selectOptions}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        showErrorOnUnTouched={showErrorOnUnTouched}
        showWarnOnUnTouched={showWarnOnUnTouched}
        small={small}
        validate={validate}
      />
    )
  }

  fetchOptions() {
    const { filters } = this.props
    const filterCollectionFn = this.getfilterCollectionFn()

    if (!filterCollectionFn) {
      this.setState({ error: FETCH_ERROR })
      return Promise.reject()
    }

    const filterPromises = filters.map(id => filterCollectionFn(id))

    return Promise.all(filterPromises)
      .then(handleResponse)
      .then(buildOptions)
      .then(options => this.setState({ selectOptions: options }))
      .catch(() => this.setState({ error: FETCH_ERROR }))
      .finally(() => this.setState({ isLoading: false }))
  }

  getfilterCollectionFn() {
    const { fetchContentEntries, filterCollection } = this.props

    if (filterCollection === 'contentTemplate') {
      return fetchContentEntries
    }
  }

  onChange(event, value) {
    const { change, fieldPath, filterCollection } = this.props

    let collectionName
    const newValue = value || []

    // prevent redux form from storing just
    // the id value so we can add an object
    event.preventDefault()

    if (filterCollection === 'contentTemplate') {
      collectionName = 'contentEntry'
    }

    change(`${fieldPath}.value`, { ids: newValue, collectionName })

    return newValue
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RelationshipField
)

function mapStateToProps(state, props) {
  const { field } = props

  const filterCollection = get(field, 'options.filterCollection')
  const filters = get(field, 'options.filters', [])

  return {
    filterCollection,
    filters,
  }
}

function mapDispatchToProps(dispatch, props) {
  const { form } = props

  return {
    change: (field, value) => dispatch(change(form, field, value)),
    fetchContentEntries: templateId =>
      dispatch(
        contentEntries.entriesByTemplate(templateId, {
          perPage: 9999,
        })
      ),
  }
}

function buildOptions(data) {
  return chain(data)
    .map(entry => ({
      label: entry.name,
      value: entry._id,
    }))
    .sortBy('label')
    .value()
}

function handleResponse(responses) {
  const hasErrors = some(responses, 'error')

  if (hasErrors)
    return Promise.reject(new Error('Error fetching relationship options'))

  const data = chain(responses)
    .map('data')
    .flatten()
    .value()

  return data
}
