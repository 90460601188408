import { flatten, isEmpty, round } from 'lodash'
import Promise from 'bluebird'

import { fetchImage } from '../../../helpers'

import { fourColumnTable, horizontalLine, imageTables, table, text } from '../'
import { LIGHT_BLUE, WHITE } from '../table'

export const buildAuditContent = Promise.method(items => {
  return Promise.map(items, group => {
    return Promise.map(group.items, (item, index) => {
      return Promise.map(item.assets, ({ assetUrl, link }) => {
        return fetchImage(assetUrl).then(base64String => ({
          alignment: 'center',
          fit: [210, 210],
          image: base64String,
          link,
        }))
      }).then(rowImages => {
        const rows = []

        const fillColor = index % 2 === 0 ? WHITE : LIGHT_BLUE
        const hasImages = !isEmpty(rowImages)
        const hasComments = !isEmpty(item.comments)

        rows.push([
          { text: item.label, fillColor },
          { text: item.scoreLabel, fillColor },
          { alignment: 'center', text: item.weight, fillColor },
          { alignment: 'right', text: item.scoreWeight, fillColor },
        ])

        if (hasImages) {
          const imageRow = [
            {
              colSpan: 4,
              fillColor,
              stack: imageTables(rowImages),
            },
          ]

          rows.push(imageRow)
        }

        if (hasComments) {
          const commentsRow = [
            {
              colSpan: 4,
              fillColor,
              margin: [0, -10, 0, 0],
              stack: [
                { text: 'Comments:', style: 'commentsHeader' },
                { text: item.comments },
              ],
            },
          ]

          rows.push(commentsRow)
        }

        return rows
      })
    }).then(groupTableRows => {
      const actual = round(group.groupActualScore, 2)
      const max = round(group.groupMaximumScore, 2)
      const resultText = `${group.groupPercentageResultScore}%`

      const { skipped } = group

      const groupScoreText = skipped
        ? null
        : text(`${actual} / ${max} (${resultText})`, {
            alignment: 'right',
            bold: true,
          })
      const headerText = text(group.title)

      const groupHeaderTable = table({
        body: [[headerText, groupScoreText]],
        layout: 'noBorders',
        style: 'groupHeaderTable',
        widths: ['*', '*'],
      })

      const groupTableHeader = [
        text('Question', { bold: true }),
        text('Answer', { bold: true }),
        text('Weight', { alignment: 'center', bold: true }),
        text('Score', { alignment: 'right', bold: true }),
      ]

      const groupTable = fourColumnTable({
        headerRows: 0,
        // NOTE: must flatten here as we have arrays of rows
        body: [groupTableHeader, ...flatten(groupTableRows)],
        widths: ['60%', '24%', '8%', '8%'],
      })

      const hLine = horizontalLine()

      return [groupHeaderTable, hLine, groupTable]
    })
  })
})
