import { isPlainObject, reduce, each } from 'lodash'
import cuid from 'cuid'
import draftToHtml from 'draftjs-to-html'

/**
 * Returns summary field count for all form groups
 */
export function getSummaryFieldCount(formGroups) {
  const count = reduce(
    formGroups,
    (accum, formGroup, formGroupIndex) => {
      each(formGroup.fieldGroups, ({ fields }) =>
        each(fields, ({ options = {} }) => {
          if (options.summary) {
            accum += 1
          }
        })
      )
      return accum
    },
    0
  )
  return count
}

/**
 * Converts a field from it's UI display format to object expected by the API
 */
export function parseField(field) {
  const { fieldtype, options, value } = field

  if (fieldtype === 'photo') {
    return {
      ...field,
      fieldtype: 'list',
      options: {
        ...options,
        type: 'media',
      },
    }
  }

  if (fieldtype === 'signature') {
    return {
      ...field,
      fieldtype: 'text',
      options: {
        ...options,
        type: 'signature',
      },
    }
  }

  if (fieldtype === 'text-display') {
    return {
      ...field,
      fieldtype: 'text',
      options: {
        ...options,
        type: 'html',
        // NOTE: Forcing this to be readOnly for now.  We can potentally
        // change this further down the line if the app gets a WYSIWYG editor
        readOnly: true,
      },
      // NOTE: DraftJs library sanitizes input as part of it's conversion
      // Also, we only need to convert if the value is a DraftJS object
      value: isPlainObject(value) ? draftToHtml(value) : value,
    }
  }

  return field
}

export function blankFormGroup() {
  return {
    name: undefined,
    fieldGroups: [
      {
        fields: [blankField()],
        tempId: cuid(),
      },
    ],
    repeatable: 1, // NOTE: by default not repeatable which is 1
    tempId: cuid(),
    canSkip: false,
  }
}

export function blankField() {
  return {
    label: undefined,
    fieldtype: 'text',
    tempId: cuid(),
  }
}
