import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'
import { getModule } from '@lighthouse/sdk'
import { HasPermission } from '@lighthouse/react-components'
import { get, take } from 'lodash'

import LocationSearch from './components/location-search'
import ToolbarButton from './components/button'
import styles from './styles'

const geoModule = getModule('geo')

const ACTIVITY_PANEL = 'activity'
const LOCATION_PANEL = 'location'
const SEARCH_PANEL = 'search'

export default compose(
  connect(mapStateToProps),
  withHandlers({ togglePanelView })
)(MapToolbar)

function MapToolbar(props) {
  const { position, rightPanelView, togglePanelView } = props

  const menuButtonStyles = styles.menuButton

  return (
    <div style={styles.nav}>
      <LocationSearch />
      <div style={styles.sidePanelButtons}>
        <ToolbarButton
          icon="locations"
          dataTestId="locations-button"
          onClick={togglePanelView(LOCATION_PANEL)}
          isSelected={rightPanelView === LOCATION_PANEL}
          sidePanel={LOCATION_PANEL}
        />
        <HasPermission module="activity">
          <ToolbarButton
            icon="activity"
            dataTestId="activity-button"
            onClick={togglePanelView(ACTIVITY_PANEL)}
            isSelected={rightPanelView === ACTIVITY_PANEL}
            sidePanel="activity"
          />
        </HasPermission>
        <ToolbarButton
          icon="search"
          dataTestId="search-button"
          onClick={togglePanelView(SEARCH_PANEL)}
          isSelected={rightPanelView === SEARCH_PANEL}
          sidePanel="search"
        />
      </div>
    </div>
  )
}

function togglePanelView(props) {
  return view => () => {
    const { onSelectSidePanel, rightPanelView } = props

    // Close if current view
    if (rightPanelView === view) {
      return onSelectSidePanel('')
    }

    onSelectSidePanel(view)
  }
}

function mapStateToProps(state) {
  return {
    markerFilters: get(state, 'geo.markers.filters', {}),
  }
}
