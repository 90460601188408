import { concat, get, isEmpty, join, map, trim } from 'lodash'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import compose from 'recompose/compose'
import React from 'react'
import Select from '../select'

import { Block } from 'components/common'

const userApplicationsModule = getModule('userApplications')

export default compose(connect(mapStateToProps))(UUIDSelect)

function UUIDSelect(props) {
  const {
    className,
    disabled,
    error,
    multi,
    onBlur,
    onChange,
    placeholder,
    readOnly,
    required,
    simpleValue,
    uuids,
    value,
  } = props

  if (readOnly) {
    const trimmedValues = map(concat([], value), value => trim(value))
    const values = join(trimmedValues, ', ')

    return <Block padding={10}>{isEmpty(values) ? '' : values}</Block>
  }

  const classes = error ? `${className}, error` : className

  const options = uuids
    .map(uuid => ({
      label: uuid,
      value: uuid,
    }))
    .asMutable()

  return (
    <Select
      className={classes}
      disabled={disabled}
      multi={multi}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      required={required}
      simpleValue={simpleValue}
      value={value}
    />
  )
}

function mapStateToProps(state) {
  const currentApplication = userApplicationsModule.getCurrentApplication(state)

  return {
    uuids: get(currentApplication, 'application.settings.uuids'),
  }
}
