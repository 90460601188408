const countryCode = 'AU'

const pageSize = 'A4'

const timezones = {
  Eastern: 'Australia/Sydney',
  'Eastern (QLD)': 'Australia/Brisbane',
  Central: 'Australia/Adelaide',
  'Central Western': 'Australia/Eucla',
  Western: 'Australia/Perth',
}

export default {
  countryCode,
  pageSize,
  timezones,
}
