import { getModule } from '@lighthouse/sdk'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

const userApplicationModule = getModule('userApplications')

function useFlags() {
  const flags = useSelector(state => userApplicationModule.getFlags(state))
  const email = useSelector(state => get(state, 'user.data.email'))
  const isLighthouseAdmin = email === 'support@lighthouse.io'

  return {
    ...flags,
    isLighthouseAdmin,
  }
}

export { useFlags }
