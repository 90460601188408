// import compose from 'recompose/compose'
import React from 'react'
import PropTypes from 'prop-types'

import Spinner from 'components/spinner'
import styles from './styles'

function ResultCount(props) {
  const { enableCount = true, isResolving, resultsCount, totalCount } = props

  return (
    <div style={styles.root}>
      <span style={styles.pagination}>
        {isResolving ? (
          <Spinner size="small" type="dots" />
        ) : enableCount ? (
          `${resultsCount.toLocaleString()} of ${totalCount.toLocaleString()}`
        ) : null}
      </span>
    </div>
  )
}

ResultCount.propTypes = {
  enableCount: PropTypes.bool,
  isResolving: PropTypes.bool.isRequired,
  resultsCount: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
}

export default ResultCount
