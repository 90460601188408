import { isEmpty, forEach, has, some, includes, isObject, cloneDeep } from 'lodash'
import { InvalidParameterError } from '../errors/index'

/**
 * Sanitize the returned document from a `save` action(i.e. `update` and `create`) by its permission rules.
 * @param {object} obj - The document that is going to be sanitized.
 * @param {object} permissionOptions - A object, which is generated by function `ask`, contains permission information
 * @param {string} moduleName - The model name of the target document
 * @param {string} options - a object which defines database/ORM namespace conventions.
 * Example:
 * ```
 *    options = {
 *      documentIdentifier: String //e.g. '_id'
 *      moduleNames: {
 *        $module: {
 *        singular: String,
 *        plural: String
 *      },
 *      e.g. location: ['location', 'locations']
 *    }
 *  }
 * ```
 * @param {function} callback - a callback method that will only be called when error occurs
 * @returns none
 */

export default function permissionsSanitize(obj, permissionOptions, moduleName, options, callback) {
  if (!options || isEmpty(options) || !options.documentIdentifier || !options.moduleNames) {
    const err = new InvalidParameterError('`options` in Permission Sanitize is not valid.')
    return callback(err)
  }

  const { filters, fields } = permissionOptions

  // for filters
  const thisModule = moduleName.toLowerCase()
  if (!isEmpty(filters) && isObject(filters)) {
    if (matchFilters(filters, obj, thisModule, options)) {
      return callback(null, null) // return null if no read permission on this obj/ its dependency
    }
  }

  // make a clone of the obj to avoid pollution
  const newObj = cloneDeep(obj)

  // for fields
  forEach(fields, minorsField => {
    // fields originally looks like this: ['-name', '-number', '-object']
    const field = minorsField.replace(/^-/, '') // strip `-` sign

    // check if the field is a nested field
    if (field.indexOf('.') > -1) {
      //  this is a nested field. split the field name string into array
      const pathToField = field.split('.')
      const targetField = pathToField.pop()
      const targetPath = getTargetPath(pathToField, newObj)
      if (targetPath[targetField]) {
        delete targetPath[targetField]
      }
    } else {
      // this is not a nested field. now check if the obj has this field
      if (has(newObj, field)) {
        delete newObj[field]
      }
    }
  })
  return callback(null, newObj)
}

function getTargetPath(fieldPaths, obj) {
  let subObj = obj
  if (some(fieldPaths, (path) => {
    subObj = subObj[path]
    // check if that nested path/level exists
    if (!subObj) {
      // path not found. Stop looking (i.e. stop function `some`).
      return true
    }
  })) {
    // path not found. return null as saying 'path not exists'
    return null
  }
  return subObj
}

function matchFilters(filters, obj, thisModule, options) {
  return some(filters, (filter, module) => {
    if (module === thisModule) {
      //  this is a document filter
      if (singularFilterCheck(filter, options.documentIdentifier, obj)) {
        return true
      }
    } else {
      //  this is a dependency filter
      // check for 1:1 ref, e.g. user: ref
      if (singularFilterCheck(filter, options.moduleNames[module].singular, obj)) {
        return true
      }
      // check for 1:n ref, e.g. users:[refs]
      if (pluralFilterCheck(filter, options.moduleNames[module].plural, obj)) {
        return true
      }
    }
  })
}

function singularFilterCheck(filter, field, obj) {
  if (filter.excludes) {
    if (obj[field] && includes(filter.excludes, obj[field].toString())) {
      // found obj ID inside `exclude` list, return true to trigger 'hide content'
      return true
    }
  } else if (filter.includes) {
    if (obj[field] && !includes(filter.includes, obj[field].toString())) {
      // can't find obj ID inside `include` list, return true to trigger 'hide content'
      return true
    }
  }
}

function pluralFilterCheck(filter, field, obj) {
  if (filter.excludes) {
    if (obj[field] && matchArrays(filter.excludes, obj[field])) {
      return true
    }
  } else if (filter.includes) {
    if (obj[field] && !matchArrays(filter.includes, obj[field])) {
      return true
    }
  }
}

/**
 * Check if any element of one array is also contained in the other array. Support array of string only.
 * @param {array} arrayOne
 * @param {array} arrayTwo
 * @returns {boolean} - True if they match, false if they don't match
 */
function matchArrays(arrayOne, arrayTwo) {
//  to check if any element of one array is also contained in the other array
  return some(arrayOne, element => includes(arrayTwo, element))
}
