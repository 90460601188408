import { combineReducers } from 'redux'
import * as entriesModule from './entries'
import * as templatesModule from './templates'

export const reducer = combineReducers({
  entries: entriesModule.reducer,
  templates: templatesModule.reducer,
})

// re-export the modules so we can access them easily
export const entries = entriesModule
export const templates = templatesModule
