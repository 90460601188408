import { Redirect, Route, RouteProps } from 'react-router-dom'
import React from 'react'
import { usePermission } from 'components/permissions'
import { isFunction } from 'lodash'
import { PermissionRequest } from '../components/permissions'

type PermissionFunc = () => boolean

interface ProtectedRouteProps extends RouteProps {
  children?: JSX.Element
  component?: RouteProps['component']
  permissions: PermissionRequest | PermissionFunc
  path: string
}

type ProtectedRouteType = (options: ProtectedRouteProps) => JSX.Element

export const ProtectedRoute: ProtectedRouteType = ({
  children,
  component: ComponentProp,
  permissions,
  ...rest
}) => {
  const { hasPermission } = usePermission()
  const hasPagePermission = isFunction(permissions)
    ? permissions()
    : hasPermission(permissions)

  if (!hasPagePermission) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  if (children) {
    return <Route {...rest}>{children}</Route>
  }

  return <Route {...rest} component={ComponentProp} />
}
