import { Interval } from '../scheduling.types'

import { intervalIntersectsEnd } from './intervalIntersectsEnd'
import { intervalIntersectsStart } from './intervalIntersectsStart'

/**
 * Check if interval only intersects another interval's end
 * Accepts two intervals and checks whether the first only intersects the second's end
 *
 * intervalIntersectsEnd([12,20], [5,15]))
 * returns true
 */

export function intervalOnlyIntersectsEnd(
  firstInterval: Interval,
  secondInterval: Interval
): Boolean {
  return (
    !intervalIntersectsStart(firstInterval, secondInterval) &&
    intervalIntersectsEnd(firstInterval, secondInterval)
  )
}
