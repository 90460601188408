import { store, getModule } from '@lighthouse/sdk'

const isDebug = process.env.NODE_ENV === 'development'
const logs = getModule('logs')

export const debug = log('debug')
export const error = log('error')
export const info = log('info')
export const warn = log('warn')

/* eslint-disable no-console */

function log(level) {
  return (msg, data) => {
    if (isDebug) {
      const debugLogger = console[level] || console.log
      return debugLogger(msg, data)
    }

    if (!store) {
      return console.log('Store should be initialised before calling logs')
    }

    const { dispatch } = store

    const payload = {
      message: msg,
      data,
    }

    return dispatch(logs.record(level, payload))
  }
}

/* eslint-enable no-console */
