import { Redirect, Route } from 'react-router-dom'
import React from 'react'

import { useAccessToken } from 'components/useAccessToken'

export default function PrivateRoute(props) {
  const { component: Component, ...rest } = props

  const { accessToken, loading } = useAccessToken()

  if (loading) return null

  const isAuthenticated = !!accessToken

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          return <Component {...props} />
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                nextPathname: props.location.pathname,
              },
            }}
          />
        )
      }}
    />
  )
}
