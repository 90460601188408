import React from 'react'

const style = {
  width: '100%',
  borderSpacing: 0,
  fontSize: 13,
}

export default function Table({ children }) {
  return <table style={style}>{children}</table>
}
