import { filter, isEmpty, isFunction, startsWith } from 'lodash'
import { compose, pure, setPropTypes, withHandlers, withProps } from 'recompose'
import { WithPermissions } from '@lighthouse/react-components'
import onClickOutside from 'react-onclickoutside'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'

import { Flex } from 'components/common'
import FlagsHOC from 'components/flags/hoc'

import { getFlexColumnDirection } from './utils'
import { MENU_ZINDEX } from './constants'
import FloatingButton from './components/floating-button'
import List from './components/list'

export const FAB_ITEMS = [
  {
    icon: 'pencil-circle',
    buttonColor: '#F6627E',
    label: 'New Audit',
    labelT: 'mapOptions.newAudit',
    dataTestId: 'add-audit-button',
    callback: onAuditCallback,
    predicate: ({ hasModulePermission }) =>
      !!hasModulePermission('auditEntry', 'create'),
  },
  {
    icon: 'alert-thick',
    buttonColor: '#FABE65',
    label: 'New Issue',
    labelT: 'mapOptions.newIssue',
    dataTestId: 'add-issue-button',
    callback: onIssueCallback,
    predicate: ({ hasModulePermission }) =>
      !!hasModulePermission('issue', 'create'),
  },
  {
    icon: 'check-checked',
    buttonColor: '#A1D370',
    label: 'New Task',
    labelT: 'mapOptions.newTask',
    dataTestId: 'add-task-button',
    callback: onTaskCallback,
    predicate: ({ hasModulePermission }) =>
      !!hasModulePermission('taskEntry', 'create'),
  },
  {
    icon: 'locations',
    buttonColor: '#00BEFF',
    label: 'New Location',
    labelT: 'mapOptions.newLocation',
    dataTestId: 'add-location-button',
    callback: onLocationCallback,
    predicate: ({ flags, hasModulePermission }) =>
      !!hasModulePermission('location', 'create'),
  },
]

const POSITION_STYLES = {
  topleft: { top: 0, left: 0 },
  topright: { top: 0, right: 0 },
  bottomleft: { bottom: 0, left: 0 },
  bottomright: { bottom: 0, right: 0 },
}

const PROP_TYPES = {
  enabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  listVisible: PropTypes.bool.isRequired,
  screenPosition: PropTypes.string,
  setListVisibility: PropTypes.func.isRequired,
}

export default compose(
  pure,
  FlagsHOC,
  WithPermissions,
  withProps(getItems),
  setPropTypes(PROP_TYPES),
  withHandlers({ handleClickOutside }),
  onClickOutside
)(FabMenu)

function FabMenu(props) {
  const {
    enabled = true,
    hasItems,
    items,
    listVisible,
    screenPosition = 'bottomright',
    setListVisibility,
    ...styleProps
  } = props

  if (!hasItems) {
    return null
  }

  const positionStyleProps = POSITION_STYLES[screenPosition]
  const positionProperty = startsWith(screenPosition, 'top') ? 'top' : 'bottom'

  const flexDirection = getFlexColumnDirection(screenPosition)

  return (
    <Flex
      alignItems="center"
      flexDirection={flexDirection}
      overflow="hidden"
      padding={20}
      position="absolute"
      zIndex={MENU_ZINDEX}
      {...positionStyleProps}
      {...styleProps}
      pointer-events={'none'}
    >
      <List
        items={items}
        listVisible={listVisible}
        screenPosition={screenPosition}
        positionProperty={positionProperty}
        setListVisibility={setListVisibility}
      />
      <FloatingButton
        dataTestId="add-item-button"
        enabled={enabled}
        listVisible={listVisible}
        screenPosition={screenPosition}
        setListVisibility={setListVisibility}
      />
    </Flex>
  )
}

function getItems(props) {
  const items = filter(FAB_ITEMS, item => {
    if (!isFunction(item.predicate) || props.isLighthouseAdmin) {
      return true
    }

    return item.predicate(props)
  })

  const hasItems = !isEmpty(items)

  return {
    hasItems,
    items,
  }
}

function handleClickOutside(props) {
  return () => props.setListVisibility(false)
}

function onAuditCallback(history) {
  history.push({
    search: queryString.stringify({
      resource: 'auditentries',
      showMarker: false,
    }),
  })
}

function onIssueCallback(history) {
  history.push({
    search: queryString.stringify({
      resource: 'issue',
      showMarker: false,
    }),
  })
}

function onLocationCallback(history) {
  history.push({
    search: queryString.stringify({
      action: 'edit-boundaries',
      resource: 'area',
    }),
  })
}

function onTaskCallback(history) {
  history.push({
    search: queryString.stringify({
      resource: 'taskentries',
      showMarker: false,
    }),
  })
}
