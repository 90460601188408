import { connect } from 'react-redux'
import { get } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import compose from 'recompose/compose'
import Loadable from 'react-loadable'
import React from 'react'
import withHandlers from 'recompose/withHandlers'
import withState from 'recompose/withState'

import { Block, Flex } from 'components/common'
import Alert from 'components/alert'
import Button from 'components/button'
import Icon from 'components/icon'

import getRegionSettings from '../../../lib/region'
import * as logger from 'utils/logger'
import { useTranslation } from 'react-i18next'

const userApplicationsModule = getModule('userApplications')

export default Loadable.Map({
  loader: {
    pdfMake: (): Promise<any> => import('pdfmake/build/pdfmake.min'),
    pdfFonts: (): Promise<any> => import('@lighthouse/common/lib/pdf/fonts'),
  },
  loading: () => null,
  render(loaded, props) {
    const pdfMake = loaded.pdfMake
    pdfMake.vfs = loaded.pdfFonts.pdfMake.vfs
    pdfMake.fonts = {
      Gotham: {
        normal: 'GothamBook.ttf',
        italics: 'GothamBook.ttf',
        bold: 'GothamBold.ttf',
        bolditalics: 'GothamBold.ttf',
      },
    }

    return <ComposedPdfDownloadButton {...props} pdfMake={pdfMake} />
  },
})

const ComposedPdfDownloadButton = compose(
  withState('isGenerating', 'setIsGenerating', false),
  withState('hasErrored', 'setHasErrored', false),
  connect(mapStateToProps),
  withHandlers({ handleError }),
  withHandlers({ handleClick })
)(PdfDownloadButton)

interface PdfDownloadButtonProps {
  disabled: boolean
  handleClick: () => void
  hasErrored: boolean
  isGenerating: boolean
}

function PdfDownloadButton(props: PdfDownloadButtonProps): JSX.Element {
  const { disabled, handleClick, hasErrored, isGenerating, ...styles } = props

  const { t } = useTranslation()

  const isDisabled = disabled || isGenerating

  return (
    <Flex>
      {hasErrored && (
        <Alert
          marginBottom={0}
          marginRight={10}
          messages={[t('alert.message.unableToExportPDF')]}
          padding="5px 15px"
          type="error"
        />
      )}
      <Button
        disabled={isDisabled}
        key="buttonDownloadPdf"
        loading={isGenerating}
        onClick={handleClick}
        style={styles}
        theme="action"
        type="button"
      >
        {!isGenerating && (
          <Icon fontSize="22px" marginRight="10px" name="download" />
        )}
        <Block>{t('button.pdf')}</Block>
      </Button>
    </Flex>
  )
}

function handleClick(props): () => void {
  const {
    data,
    definitionFn,
    flags,
    handleError,
    logoUrl,
    pdfMake,
    region,
    setHasErrored,
    setIsGenerating,
  } = props

  const { pageSize } = getRegionSettings(region)

  const options = {
    logoUrl,
    pageSize,
    flags,
  }

  return (): Promise<any> => {
    setHasErrored(false)
    setIsGenerating(true)

    return definitionFn(options, data)
      .then(definition =>
        /Edge/.test(window.navigator.userAgent)
          ? pdfMake.createPdf(definition).download(`${data.entity.title}.pdf`)
          : pdfMake.createPdf(definition).open()
      )
      .catch(handleError)
      .finally(() => setIsGenerating(false))
  }
}

function handleError(props): (error) => void {
  const { reference, setHasErrored } = props

  return (error): (() => void) => {
    setHasErrored(true)
    return logger.error(`PDF Export Error - ${reference} - ${error}`)
  }
}

function mapStateToProps(state): any {
  const currentApplication = userApplicationsModule.getCurrentApplication(state)

  return {
    logoUrl: get(currentApplication, 'application.theme.logos.pdf'),
    region: state.app.region,
  }
}
