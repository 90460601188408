import React from 'react'

import { colors as brandColors } from 'config/theme/brand'
import colors from 'config/theme/colors'
import { assign } from 'lodash'

const baseStyle = {
  backgroundColor: '#eee',
  borderRadius: 1,
  padding: '0.4em 0.8em',
  fontSize: '90%',
}
const styles = {
  primary: assign({}, baseStyle, {
    backgroundColor: brandColors.primary,
    color: colors.white,
  }),

  warning: assign({}, baseStyle, {
    backgroundColor: brandColors.warning,
    color: colors.white,
  }),

  positive: assign({}, baseStyle, {
    backgroundColor: colors.green.normal,
    color: colors.white,
  }),

  negative: assign({}, baseStyle, {
    backgroundColor: colors.red.normal,
    color: colors.white,
  }),
}

export default function Label({ type, children }) {
  const labelStyle = type ? styles[type] : baseStyle
  return <span style={labelStyle}>{children}</span>
}
