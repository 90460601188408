import React from 'react'
import colors from 'config/theme/colors'
import { Block } from 'components/common'

export default function TabItem(props) {
  const { active, children, onClick } = props

  const activeStyles = active
    ? {
        color: colors.gray.normal,
        backgroundColor: colors.white,
      }
    : null

  return (
    <Block
      onClick={onClick}
      backgroundColor={colors.gray.lighter}
      borderBottom={`1px solid ${colors.gray.lightest}`}
      color={colors.gray.light}
      flexGrow="1"
      fontSize="12px"
      padding="20px"
      textAlign="center"
      {...activeStyles}
    >
      {children}
    </Block>
  )
}
