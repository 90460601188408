import colors from 'config/theme/colors'

export default {
  readOnly: {
    backgroundColor: colors.gray.lightest,
    borderColor: colors.gray.lightest,
    color: colors.gray.lighter,
    ':focus': {
      borderColor: colors.gray.lightest,
    },
  },

  message: {
    color: colors.gray.light,
    display: 'block',
    fontSize: '10px',
    marginTop: 4,
  },

  errorMessage: {
    color: colors.red.normal,
  },

  help: {
    color: colors.gray.light,
    fontSize: '28px',
    fontWeight: 200,
    paddingLeft: '10px',
  },
}
