import { pure } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { colors, typography } from 'config/theme'
import { Block, Span } from 'components/common'

export default pure(ListSubtitle)

function ListSubtitle(props) {
  const { children } = props

  return (
    <Block maxWidth={250}>
      <Span
        color={colors.gray.normal}
        fontSize={typography.fontSizes.small}
        fontWeight={300}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width="100%"
      >
        {children}
      </Span>
    </Block>
  )
}

ListSubtitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
