import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'
import Entry from './entry'
import List from './list'

export default function ApplicationsRoute() {
  return (
    <Switch>
      <Route
        component={Entry}
        exact
        path={['/admin/applications/entry', '/admin/applications/entry/:id']}
      />
      <Route component={List} exact path="/admin/applications" />
      <Redirect to="/404" />
    </Switch>
  )
}
