export enum ISO_DAY_OF_WEEK {
  SUNDAY = 7,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum Types {
  Audit = 'audit',
  Task = 'task',
  Visit = 'visit',
}

export enum Enabled {
  On = 'On',
  Off = 'Off',
}

export enum Repeat {
  Interval = 'interval',
  NoRepeat = 'noRepeat',
  SinceCompletion = 'sinceCompletion',
}

export enum Unit {
  Millisecond = 'millisecond',
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'isoWeek',
  Month = 'month',
  Year = 'year',
}

export interface Frequency {
  unit: Unit
  value: number
}

export enum Strategies {
  ExactDateOfMonth = 'exactDateOfMonth',
  LastDayOfMonth = 'lastDayOfMonth',
  LastWeekdayOfMonth = 'lastWeekdayOfMonth',
  NoRepeat = 'noRepeat',
  Stopwatch = 'stopwatch',
  Weekdays = 'weekdays',
  Window = 'window',
  XDayOfXWeekOfMonth = 'xDayOfxWeekOfMonth',
}

export interface Strategy {
  options: {
    date?: number
    duration?: Frequency
    frequency?: Frequency
    weekdays?: number[]
  }
  type: Strategies
}

export interface ExactDateOfMonthStrategy extends Strategy {
  type: Strategies.ExactDateOfMonth
}

export interface StopwatchStrategy extends Strategy {
  type: Strategies.Stopwatch
}

export interface WindowStrategy extends Strategy {
  type: Strategies.Window
}

export interface WeekdaysStrategy extends Strategy {
  type: Strategies.Weekdays
}

export interface NoRepeatStrategy extends Strategy {
  type: Strategies.NoRepeat
}

export interface Schedule {
  applicationId?: string
  areas: any[]
  createdAt?: string
  enabled: boolean
  endAt?: Date
  locations: any
  name: string
  reference: string
  roles: any[]
  scheduleId?: string
  serviceHours: any
  startAt: Date
  strategy: any
  template?: string
  type: Types
  updatedAt?: string
  users: any[]
}

export interface FormRepeatOptions {
  endAt: Date
  firstRepeatEnd?: Date
  monthRepeatType: string
  repeat: Repeat
  repeatUnit: Unit
  repeatMinutes: number
  repeatHours: number
  repeatDays: number
  repeatWeeks: number
  repeatMonths: number
  repeatYears: number
  startAt: Date
  weekdays?: number[]
}

export interface FormData {
  areas?: string[]
  enabled: boolean
  endAt?: Date
  endAtTime?: string
  firstRepeatEnd?: Date
  locations?: string[]
  name: string
  monthRepeatType: string
  reference: string
  repeat: Repeat
  repeatDays: number
  repeatForever?: boolean
  repeatHours: number
  repeatMonths: number
  repeatMinutes: number
  repeatUnit: Unit
  repeatWeeks: number
  repeatYears: number
  roles?: string[]
  serviceHours: any
  startAt: Date
  startAtTime?: string
  template?: string
  type: Types
  useLocationServiceHours: boolean
  weekdays?: number[]
  users?: string[]
}
