import 'react-phone-number-input/style.css'
import './styles.css'
import { connect } from 'react-redux'
import React from 'react'
import PhoneInput from 'react-phone-number-input'
import getRegionSettings from '../../../lib/region'

const DEFAULT_COUNTRIES = ['AU', 'CA', 'NZ', 'US']
const DEFAULT_PLACEHOLDER = 'Enter phone number'
const FLAGS_PATH =
  'https://s3.amazonaws.com/assets-lighthouse-io/flags/{xx}.svg'

export default connect(mapStateToProps)(FormPhone)

function FormPhone(props) {
  const {
    autoComplete,
    country,
    countries = DEFAULT_COUNTRIES,
    disabled,
    error,
    international = false,
    onChange,
    placeholder = DEFAULT_PLACEHOLDER,
    region,
    required,
    readOnly,
    small,
    value,
  } = props

  const classNames = ['phone-number']
  const { countryCode } = getRegionSettings(region)
  if (error) classNames.push('error')
  if (small) classNames.push('small')
  if (disabled || readOnly) classNames.push('readonly')

  const classes = classNames.join(' ')

  return (
    <PhoneInput
      autoComplete={autoComplete}
      displayInitialValueAsLocalNumber
      defaultCountry={country || countryCode}
      country={country || countryCode}
      countries={countries}
      disabled={disabled}
      flagUrl={FLAGS_PATH}
      className={classes}
      international={international}
      onChange={value => {
        if (value === undefined) return onChange('')
        onChange(value)
      }}
      useNationalFormatForDefaultCountryValue={true}
      placeholder={placeholder}
      required={required}
      value={value}
    />
  )
}

function mapStateToProps(state) {
  return {
    region: state.app.region,
  }
}
