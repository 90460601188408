import React from 'react'

import { assign, isBoolean, isUndefined } from 'lodash'

import Measure from 'react-measure'

const positions = ['top', 'right', 'bottom', 'left']

const validStyles = ['width', 'zIndex']

export default function Absolute(props) {
  const { dataTestId } = props

  const style = {
    position: 'absolute',
  }

  positions.forEach(position => {
    const positionValue = processPositionProp(props[position])

    if (!isUndefined(positionValue)) {
      style[position] = positionValue
    }
  })

  validStyles.forEach(styleKey => {
    const styleValue = props[styleKey]
    if (styleValue) {
      style[styleKey] = styleValue
    }
  })

  const isAxisX = (props.top || props.bottom) && !props.left && !props.right

  const isAxisY = (props.right || props.left) && !props.top && !props.bottom

  return (
    <Measure includeMargin={false}>
      {dimensions => {
        let computedStyle = style
        if (props.center) {
          if (isAxisX) {
            // Center element along X axis
            const { width } = dimensions
            computedStyle = assign({}, style, {
              left: '50%',
              marginLeft: (width / 2) * -1,
            })
          }
          if (isAxisY) {
            // Center element along Y axis
            const { height } = dimensions
            computedStyle = assign({}, style, {
              top: '50%',
              marginTop: (height / 2) * -1,
            })
          }
        }

        return (
          <div data-testid={dataTestId} style={computedStyle}>
            {props.children}
          </div>
        )
      }}
    </Measure>
  )
}

function processPositionProp(value) {
  if (isBoolean(value)) {
    return 0
  }

  return value
}
