import PropTypes from 'prop-types'
import React from 'react'

import { colors, typography } from 'config/theme'
import { Block, Flex } from 'components/common'
import Icon from 'components/icon'

export default SearchItem
export { SearchItem as SearchItemForTest }

function SearchItem(props) {
  const { getItemProps, highlightedIndex, item, index, selectedItem } = props

  const isHighlighted = highlightedIndex === index
  const isSelected = selectedItem && selectedItem.value === item.value

  const backgroundColor = isHighlighted ? colors.blue.lightest : 'white'

  const fontWeight = isSelected ? 'bold' : 'normal'

  const showReference = !!item.reference && item.group === 'location'
  const title = showReference ? `${item.label}, ${item.reference}` : item.label

  return (
    <div
      {...getItemProps({
        key: item.value,
        index,
        item,
      })}
    >
      <Flex
        alignItems="center"
        backgroundColor={backgroundColor}
        cursor="pointer"
        fontWeight={fontWeight}
        lineHeight="30px"
        color="#666"
        paddingLeft={20}
        paddingRight={20}
      >
        <Block>
          <Icon name={item.icon} />
        </Block>
        <Block
          paddingLeft={10}
          fontSize="13px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          title={title}
        >
          {item.label}
        </Block>
        {showReference && (
          <Block
            fontSize="13px"
            whiteSpace="nowrap"
            color={colors.gray.light}
          >{`, ${item.reference}`}</Block>
        )}
      </Flex>
    </div>
  )
}

SearchItem.propTypes = {
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedItem: PropTypes.object,
}
