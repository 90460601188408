import { geojsonType, featureOf } from '@turf/invariant'
import { isError, map, attempt, includes } from 'lodash'

const VALID_GEOMETRY_TYPES = ['Point', 'Polygon', 'LineString']

export default function geojson2areas(featureCollection) {
  try {
    geojsonType(featureCollection, 'FeatureCollection', 'geojson2areas')
  } catch (err) {
    console.error(err.message)
    return
  }

  const areas = map(featureCollection.features, geojson2area)

  return areas
}

function geojson2area(feature) {
  const validateFeature = attempt(() => isValidFeature(feature))
  if (isError(validateFeature)) {
    console.error(validateFeature.message)
    return
  }

  const { properties = {} } = feature

  const propertiesType =
    properties.type ||
    (feature.geometry.type === 'Point' ? 'point' : 'building')

  return {
    name: properties.name,
    altName: properties.altName,
    type: propertiesType.toLowerCase(),
    slug: properties.slug,
    // floors is for building type
    floors: properties.floors,
    // floorsRef is for indoor types inc. floor
    floorsRef: properties.floorsRef,
    geometry: feature.geometry,
    properties,
  }
}

function isValidFeature(feature) {
  geojsonType(feature, 'Feature', 'geojson2areas')

  if (!feature.geometry) {
    throw new Error('Missing geometry property on feature')
  }

  const geometryType = feature.geometry.type
  validGeometryType(geometryType)

  featureOf(feature, geometryType, 'geojson2areas')
}

function validGeometryType(type) {
  const isValidGeometryType = includes(VALID_GEOMETRY_TYPES, type)

  if (!isValidGeometryType) {
    throw new Error(
      `Invalid feature type of ${type}. Should be one of: ${VALID_GEOMETRY_TYPES}`
    )
  }
}
