import { first, isEmpty, reduce } from 'lodash/fp'

import { Interval } from '../scheduling.types'

/**
 * Merges overlapping intervals
 * Accepts arrays of intervals and returns a flattened result of overlapping intervals
 *
 * mergeIntervals([[1,15], [11,25], [21,29], [40,1000]])
 * returns [[1,29], [40,1000]]
 */
export function mergeIntervals(intervals: Array<Interval>): Array<Interval> {
  if (isEmpty(intervals)) return intervals

  const sortedIntervals = intervals.sort((a, b) => a[0] - b[0])
  const firstInterval = first(sortedIntervals)

  const stack = reduce(
    (accum, interval) => {
      const top = accum[accum.length - 1]
      const topBeforeInterval = top[1] < interval[0]
      const topOverlapsInterval = top[1] < interval[1]

      if (topBeforeInterval) {
        accum.push(interval)
      } else if (topOverlapsInterval) {
        accum.pop()
        accum.push([top[0], interval[1]])
      }

      return accum
    },
    [firstInterval],
    sortedIntervals
  )

  return stack
}
