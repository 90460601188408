import moment from 'moment-timezone'

interface HasValidStartAndEnd {
  readonly end: number
  readonly start: number
}

/**
 * Checks if start and end are valid datetimes
 *
 * hasValidStartAndEnd({ end: 1566970473988, start: 1566970464839 })
 * returns true
 */
export function hasValidStartAndEnd({
  start,
  end,
}: HasValidStartAndEnd): Boolean {
  if (!start || !end) return false
  if (end <= start) return false

  const hasValidEnd: boolean = moment.utc(end).isValid()
  const hasValidStart: boolean = moment.utc(start).isValid()

  if (!hasValidEnd || !hasValidStart) return false

  return true
}
