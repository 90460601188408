import React from 'react'

interface Props {
  controls?: boolean
  src: string
  type: string
  width?: number
}

const DEFAULT_VIDEO_WIDTH = 320
const DEFAULT_VIDEO_THUMBNAIL_TIME = 0.5

const videoStyles = { minWidth: 240 }

const Video = (props: Props) => {
  const { controls = true, src, type, width = DEFAULT_VIDEO_WIDTH } = props

  return (
    <video
      width={width}
      controls={controls}
      preload="metadata"
      style={videoStyles}
    >
      <source src={`${src}#t=${DEFAULT_VIDEO_THUMBNAIL_TIME}`} type={type} />
      Your browser does not support playing videos.
    </video>
  )
}

export default Video
