import Radium from 'radium'
import React from 'react'

export default Radium(UnorderedList)

function UnorderedList(props) {
  const { children, onClick, radiumStyles, ...styleProps } = props

  const style = [styleProps, radiumStyles]

  return (
    <ul onClick={onClick} style={style}>
      {children}
    </ul>
  )
}
