/*
 * Audit Module
 */

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { REQUEST } from '../../middleware/request'

import {
  getRegionUrl,
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import { buildNearbySelectorFn } from '../../helpers/selectors'

import crud from '../../crud'
import hooks from './hooks'

import { selectors as areaSelectors } from '../areas'

export const SAVE_REQUEST = 'lighthouse/template/SAVE_REQUEST'
export const SAVE_SUCCESS = 'lighthouse/template/SAVE_SUCCESS'
export const SAVE_ERROR = 'lighthouse/template/SAVE_ERROR'

const resource = 'audits'

export const actions = crud.actions(resource)
hooks(actions)

export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn(resource),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const crudSelectors = crud.selectors(resource)

const nearbySelector = createSelector(
  areaSelectors.sortByPosition,
  crudSelectors.cache,
  buildNearbySelectorFn('audits')
)

export const selectors = {
  ...crudSelectors,
  nearbySelector,
}

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

export function lockRequest({ application, id }) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/applications/${application}/audits/${id}/actions/lock`
    const headers = authorizationHeadersFn(null, state)
    return dispatch({
      [REQUEST]: {
        types: [SAVE_REQUEST, SAVE_SUCCESS, SAVE_ERROR],
        method: 'PUT',
        headers,
        endpoint,
      },
    })
  }
}

export function unlockRequest({ application, id }) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/applications/${application}/audits/${id}/actions/unlock`
    const headers = authorizationHeadersFn(null, state)
    return dispatch({
      [REQUEST]: {
        types: [SAVE_REQUEST, SAVE_SUCCESS, SAVE_ERROR],
        method: 'PUT',
        headers,
        endpoint,
      },
    })
  }
}
