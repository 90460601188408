import { REQUEST } from '../../../middleware/request'
import request from '../../../request'


export default function buildActionCreators(actions, baseUrlFn, headersFn, paramsFn) {
  return {
    findByUserId,
    messagesRegistration,
    messagesDeregistration,
    resendOidcInvite,
  }

  // NOTE Deprecated:
  // This action creator is deprecated in favour of a direct request in the
  // application. See the useUsers hook in the web project
  function findByUserId(id, params) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = baseUrlFn(params)
      const url = `${baseUrl}/actions/get-by-user/${id}`
      const headers = headersFn(params, state)
      const opts = { headers }

      return request(url, opts)
        .then(({ json }) => (
          dispatch({
            data: json,
            // eslint-disable-next-line no-underscore-dangle
            id: json._id,
            type: actions.FIND_SUCCESS,
          })
        ))
    }
  }

  function resendOidcInvite(applicationUserId) {
    return (dispatch, getState) => {
      const state = getState()
      const params = paramsFn(state, {})

      const baseUrl = baseUrlFn(params, state)

      const endpoint = `${baseUrl}/${applicationUserId}/actions/resend-invite`
      const headers = headersFn(params, state)
      return dispatch({
        [REQUEST]: {
          types: [actions.SAVE_REQUEST, actions.SAVE_SUCCESS, actions.SAVE_ERROR],
          method: 'PUT',
          headers,
          endpoint,
        },
      })
    }
  }

  function messagesRegistration(payload) {
    return (dispatch, getState) => {
      const state = getState()
      let params = {}

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/messages-registration`
      const headers = headersFn(params, state)
      return dispatch(messagesRegistrationRequest(endpoint, headers, payload))
    }
  }

  function messagesRegistrationRequest(endpoint, headers, payload) {
    return {
      [REQUEST]: {
        types: [
          actions.MESSAGES_REGISTRATION_REQUEST,
          actions.MESSAGES_REGISTRATION_SUCCESS,
          actions.MESSAGES_REGISTRATION_ERROR,
        ],
        method: 'PUT',
        endpoint,
        headers,
        body: payload,
      },
    }
  }

  function messagesDeregistration(payload) {
    return (dispatch, getState) => {
      const state = getState()
      let params = {}

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/messages-deregistration`
      const headers = headersFn(params, state)
      return dispatch(messagesDeregistrationRequest(endpoint, headers, payload))
    }
  }

  function messagesDeregistrationRequest(endpoint, headers, payload) {
    return {
      [REQUEST]: {
        types: [
          actions.MESSAGES_DEREGISTRATION_REQUEST,
          actions.MESSAGES_DEREGISTRATION_SUCCESS,
          actions.MESSAGES_DEREGISTRATION_ERROR,
        ],
        method: 'PUT',
        endpoint,
        headers,
        body: payload,
      },
    }
  }
}
