import React from 'react'
import { Field } from 'redux-form'
import useApplicationList from '../../applications/helpers/use-fetch-applications'
import { InputSelect } from 'components/form'

const ApplicationSelect = props => {
  const { label, name } = props

  const { applications } = useApplicationList()

  const APPLICATION_OPTIONS = applications.map(({ _id, name }) => ({
    label: name,
    value: _id,
  }))

  return (
    <Field
      component={InputSelect}
      label={label}
      name={name}
      options={APPLICATION_OPTIONS}
      required
    />
  )
}

export default ApplicationSelect
