import { compose, setPropTypes, withHandlers, withState } from 'recompose'
import { isArray, noop } from 'lodash'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'
import ButtonNext from 'components/button-next'
import colors from 'config/theme/colors'
import Icon from 'components/icon'

import { getFlexRowDirection } from '../../../../utils'
import { LIST_ITEM_ZINDEX } from '../../../../constants'
import { useTranslation } from 'react-i18next'

const PROP_TYPES = {
  item: PropTypes.object.isRequired,
  labelVisible: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
  screenPosition: PropTypes.string.isRequired,
  setLabelVisible: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  withState('labelVisible', 'setLabelVisible', false),
  withHandlers({ onItemClick }),
  setPropTypes(PROP_TYPES)
)(Item)

function Item(props) {
  const {
    item,
    labelVisible,
    onItemClick,
    screenPosition,
    setLabelVisible,
    ...stylesProp
  } = props

  const {
    dataTestId,
    label,
    labelT,
    buttonColor = colors.blue.normal,
    icon = 'alert-thick',
    callback = noop,
  } = item

  const { t } = useTranslation()

  const flexDirection = getFlexRowDirection(screenPosition)

  return (
    <Flex
      alignItems="center"
      flexDirection={flexDirection}
      marginBottom={10}
      marginTop={10}
      position="relative"
      zIndex={LIST_ITEM_ZINDEX}
      {...stylesProp}
    >
      <Flex
        flexGrow={1}
        background={colors.white}
        border={`1px solid ${colors.gray.lighter}`}
        padding="10px 15px"
        textAlign="center"
        fontWeight={400}
        fontSize="12px"
        color={colors.gray.dark}
        flexDirection="column"
        marginLeft={10}
        marginRight={10}
        opacity={labelVisible ? 1 : 0}
      >
        {labelT ? (isArray(labelT) ? t(...labelT) : t(labelT)) : label}
      </Flex>
      <ButtonNext
        dataTestId={dataTestId}
        circle
        color={colors.white}
        backgroundColor={buttonColor}
        large
        padding={0}
        onClick={() => onItemClick(callback)}
        onMouseOut={() => setLabelVisible(false)}
        onMouseOver={() => setLabelVisible(true)}
        pointer-events={'all'}
      >
        <Icon name={icon} />
      </ButtonNext>
    </Flex>
  )
}

function onItemClick(props) {
  const { history, setListVisibility } = props

  return callback => {
    callback(history)
    setListVisibility(false)
  }
}
