import { getModule } from '@lighthouse/sdk'
import { WithPermissions } from '@lighthouse/react-components'
import {
  assign,
  debounce,
  chain,
  filter,
  find,
  get,
  map,
  reduce,
  split,
} from 'lodash'
import { connect } from 'react-redux'
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from 'recompose'
import Promise from 'bluebird'
import React from 'react'

import { Block, Flex } from 'components/common'
import Button from 'components/button'
import Messenger from 'components/messenger-next'
import Resolver from 'components/resolver'
import TitleBar from 'modules/title-bar'

import * as logger from 'utils/logger'
import { useTranslation } from 'react-i18next'

const userApplicationsModule = getModule('userApplications')
const speakersModule = getModule('messages', 'speakers')
const roomsModule = getModule('messages', 'rooms')
const messagesModule = getModule('messages', 'messages')

const sendMessageParams = {
  optimistic: true,
}

export default compose(
  WithPermissions,
  withState('resolvedRooms', 'setResolvedRooms', {}),
  withState('groups', 'setGroups', {}),
  connect(mapStateToProps, mapDispatchToProps),
  Resolver(resolver),
  withHandlers({
    handleSendMessage,
    handleSetRoom,
    markAsRead,
  }),
  lifecycle({
    componentWillMount,
    componentDidUpdate,
  }),
  withProps(getProps)
)(MessagesModule)

function MessagesModule(props) {
  const {
    getSpeakerAliases,
    handleSetRoom,
    handleSendMessage,
    hasUnread,
    messageCache,
    roomCache,
    selectedGroup,
    speakerId,
    speakers,
    hasModulePermission,
  } = props

  const hasMessageGroupCreatePermission = hasModulePermission(
    'messageGroups',
    'create'
  )

  const { t } = useTranslation()

  const groups = parseGroups({
    getSpeakerAliases,
    hasUnread,
    roomCache,
    speakerId,
  })
  const messages = parseMessages({ messageCache, speakers })

  return (
    <Flex
      flexDirection="column"
      height="100%"
      margin="0 auto"
      maxWidth={1200}
      paddingTop={10}
    >
      <Block>
        <TitleBar title={t('labelMessages')}>
          {hasMessageGroupCreatePermission ? (
            <Button
              dataTestId="new-group-button"
              link="/messages/groups/new"
              theme="primary noMargin"
            >
              {t('button.newGroup')}
            </Button>
          ) : null}
        </TitleBar>
      </Block>
      <Block height="100%" marginBottom={60} width="100%">
        <Messenger
          groups={groups}
          messages={messages}
          onGroupClick={handleSetRoom}
          onSend={handleSendMessage}
          selectedGroup={selectedGroup}
        />
      </Block>
    </Flex>
  )
}

function mapStateToProps(state) {
  const speakerId = userApplicationsModule.speakerIdSelector(state)
  const speakerSelectors = speakersModule.selectors(state)()
  const roomSelectors = roomsModule.selectors(state)()
  const messageSelectors = messagesModule.selectors(state)()
  const currentRoom = roomSelectors.current()
  const roomId = get(currentRoom, 'entity._id')

  return {
    getSpeakerAliases: speakerSelectors.aliases,
    hasUnread: roomSelectors.hasUnread,
    messageCache: messageSelectors.filterByRoom(roomId),
    roomCache: state.messages.rooms.cache,
    selectedGroup: roomId,
    speakerId,
    speakers: state.messages.speakers.cache,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMessages: roomId =>
      dispatch(
        messagesModule.query(roomId, {
          limit: 9999,
          roomId,
        })
      ),
    fetchRooms: speakerId =>
      dispatch(
        roomsModule.query('default', {
          speaker: speakerId,
        })
      ),
    fetchSpeakers: () =>
      dispatch(
        speakersModule.query('default', {
          perPage: 9999,
        })
      ),
    markAsReadRequest: opts => dispatch(roomsModule.markAsRead(opts)),
    sendMessage: (params, payload) =>
      dispatch(messagesModule.save(params, payload)),
    setRoom: id => dispatch(roomsModule.setCurrent(id)),
  }
}

function componentWillMount() {
  const { markAsRead, selectedGroup } = this.props

  if (selectedGroup) markAsRead()
}

function componentDidUpdate(prevProps) {
  const {
    debouncedMarkAsRead,
    hasUnread,
    markAsRead,
    rooms,
    selectedGroup,
    speakerId,
  } = this.props

  if (!selectedGroup) return

  const room = find(rooms, ['entity._id', selectedGroup])
  const roomUpdated = room !== prevProps.room
  const roomSwitched = selectedGroup !== prevProps.selectedGroup
  const speakerHasUnreadMessages = hasUnread(selectedGroup, speakerId)

  if (!speakerHasUnreadMessages) return

  // if we switch room, call mark as read immediatley
  if (roomSwitched) {
    markAsRead()
    return
  }

  // if the room updated (new messages) update the room with debounce
  if (roomUpdated) {
    debouncedMarkAsRead()
  }
}

function getProps({ markAsRead, ...props }) {
  return {
    debouncedMarkAsRead: debounce(() => markAsRead(), 2500),
    messages: parseMessages(props),
  }
}

function handleSetRoom(props) {
  const { fetchMessages, resolvedRooms, setResolvedRooms, setRoom } = props

  return roomId => {
    if (!roomId) {
      console.error('No `roomId` provided for setRoom')
    }

    setRoom(roomId)

    // Determine whether to fetch the room messages or not. Messages should only
    // be requested from the server when the room is first clicked, and from
    // then on sockets should control the data flow...

    const roomHasResolved = resolvedRooms[roomId]

    if (roomHasResolved) return

    fetchMessages(roomId).then(() =>
      setResolvedRooms({ ...resolvedRooms, [roomId]: true })
    )
  }
}

function handleSendMessage({ selectedGroup, sendMessage, speakerId }) {
  return (message, callback) => {
    const { text: body } = message

    const params = assign({}, sendMessageParams, {
      roomId: selectedGroup,
    })

    // NOTE we don't need to include room in the payload, but it helps with
    // optimistically upating the messages (as the room doesn't become part of
    // the entity until it is received from the server)
    const payload = {
      room: selectedGroup,
      speaker: speakerId,
      meta: {},
      body,
    }

    // NOTE callback immediatley as we are using optimisic updates
    callback()

    sendMessage(params, payload)
  }
}

function markAsRead(props) {
  const {
    markAsReadRequest,
    roomCache,
    selectedGroup: roomId,
    speakerId,
  } = props

  return () => {
    const room = find(roomCache, ['entity._id', roomId])
    const timestamp = get(room, 'entity.latestMessage.created')

    if (!timestamp) return

    markAsReadRequest({
      roomId,
      speakerId,
      timestamp,
    }).catch(err => {
      console.error('Error marking message as read', {
        err,
        speakerId,
        roomId,
      })
    })
  }
}

function parseGroups({ getSpeakerAliases, hasUnread, roomCache, speakerId }) {
  const speakersRooms = filter(roomCache, room =>
    find(room.entity.speakers, { speaker: speakerId })
  )

  return reduce(
    speakersRooms,
    (accum, item) => {
      const { entity = {} } = item
      const date = entity.lastUpdate || entity.created
      const roomId = entity._id

      const members = map(entity.speakers, ({ speaker }) => ({
        fullName: getSpeakerAliases(speaker)[0],
        id: speaker,
      }))

      const mySpeaker = find(members, ['id', speakerId])

      const otherNames = chain(members)
        .reject(['id', speakerId])
        .orderBy(['fullName'], ['asc'])
        .map(({ fullName }) => split(fullName, ' ', 1))
        .value()

      const name = entity.name
        ? entity.name
        : otherNames.length > 0
        ? otherNames.join(', ')
        : `${mySpeaker.fullName} (you)`

      accum.push({
        date: new Date(date),
        id: roomId,
        members,
        name,
        unread: hasUnread(roomId, speakerId),
      })

      return accum
    },
    []
  )
}

function parseMessages({ messageCache, speakers }) {
  return map(messageCache, ({ entity = {} }) => {
    const speaker = speakers[entity.speaker]
    const name = get(speaker, 'entity.alias') || 'Anonymous'

    return {
      id: entity._id,
      group: entity.room,
      name,
      text: entity.body,
      date: entity.created && new Date(entity.created),
    }
  })
}

function resolver(props) {
  const {
    fetchMessages,
    fetchRooms,
    fetchSpeakers,
    selectedGroup,
    setRoom,
    speakerId,
  } = props

  const promises = {
    rooms: fetchRooms(speakerId),
    speakers: fetchSpeakers(),
  }

  return Promise.props(promises)
    .then(({ rooms = {} }) => {
      const firstRoom = get(rooms, 'data[0]._id', false)
      if (selectedGroup) {
        fetchMessages(selectedGroup)
      } else if (firstRoom) {
        setRoom(firstRoom)
      }
    })
    .catch(err => logger.error(err))
}
