import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import Link from 'components/link'
import Icon from 'components/icon'
import styles from './styles'

export default Radium(NavItem)

function NavItem(props) {
  const {
    active,
    children,
    dataTestId,
    icon,
    link,
    onClick,
    style,
    type,
  } = props

  const baseStyles = [
    styles.navItem,
    styles[type],
    active && styles[`${type}Active`],
  ]
  const navStyles = baseStyles.concat(style)
  const navIcon = icon && <Icon name={icon} theme={styles.icon} />

  let itemComponent
  if (typeof link !== 'undefined') {
    itemComponent = (
      <Link to={link} style={navStyles}>
        {navIcon}
        {children}
      </Link>
    )
  } else if (typeof onClick !== 'undefined') {
    itemComponent = (
      <div data-testid={dataTestId} onClick={onClick} style={navStyles}>
        {navIcon}
        {children}
      </div>
    )
  } else {
    itemComponent = (
      <div data-testid={dataTestId} href="#" style={navStyles}>
        {children}
        {navIcon}
      </div>
    )
  }

  return (
    <div data-testid={dataTestId} style={styles.wrapper}>
      {itemComponent}
    </div>
  )
}

NavItem.propTypes = {
  active: PropTypes.bool,
}
