import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { WithPermissions } from '@lighthouse/react-components'
import { getModule } from '@lighthouse/sdk'
import NavList from 'modules/nav-list'
import Promise from 'bluebird'
import React, { Component } from 'react'
import { find, get, pick, map, isArray } from 'lodash'

import FlagsHOC from 'components/flags/hoc'
import navigationBuilder from 'components/navigation'
import { navigation as navigationConfig } from 'config/navigation'
import * as logger from 'utils/logger'
import { withTranslation } from 'react-i18next'

const applicationUsersModule = getModule('applicationUsers')
const auditsModule = getModule('audits')
const rolesModule = getModule('roles')
const templatesModule = getModule('templates')

const AUDIT_FIELDS = '_id,title'
const DEFAULT_FIELDS = '_id,name'
const LIST_ID = 'all'
const PER_PAGE = '9999'
const TEMPLATE_FIELDS = '_id,name,type,createdAt'
const USER_FIELDS = '_id,firstName,fullName,lastName,search,user'

class ReportsMain extends Component {
  componentWillMount() {
    const { fetchAudits, fetchRoles, fetchTemplates, fetchUsers } = this.props

    const perPage = PER_PAGE

    const promises = [
      fetchAudits({ fields: AUDIT_FIELDS, perPage }),
      fetchRoles({ fields: DEFAULT_FIELDS, perPage }),
      fetchTemplates({ fields: TEMPLATE_FIELDS, perPage }),
      fetchUsers({ fields: USER_FIELDS, perPage }),
    ]

    Promise.all(promises).catch(error => logger.error(error))
  }

  render() {
    const { children, defaultRoute, navigation, pathParts, t } = this.props

    const section = pathParts[1]

    const navItems = {}

    map(navigation, (nav, key) => {
      navItems[key] = {
        ...nav,
        label: nav.labelT
          ? isArray(nav.labelT)
            ? t(...nav.labelT)
            : t(nav.labelT)
          : nav.label,
      }
    })

    return (
      <React.Fragment>
        <NavList type="secondary" active={section} items={navItems} />
        {children({ defaultRoute })}
      </React.Fragment>
    )
  }
}

export default compose(
  FlagsHOC,
  WithPermissions,
  connect(mapStateToProps, mapDispatchToProps),
  navigationBuilder({
    slice: 'reports',
  }),
  withTranslation(),
  withProps(getDefaultRoute)
)(ReportsMain)

function mapStateToProps(state) {
  return {
    applicationId: state.app.applicationId,
    authentication: state.authentication,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAudits: params => dispatch(auditsModule.query(LIST_ID, params)),
    fetchRoles: params => dispatch(rolesModule.query(LIST_ID, params)),
    fetchTemplates: params => dispatch(templatesModule.query(LIST_ID, params)),
    fetchUsers: params =>
      dispatch(applicationUsersModule.query(LIST_ID, params)),
  }
}

function getDefaultRoute(props) {
  const opts = pick(props, ['flags', 'permissions'])
  const reportRoutes = get(navigationConfig, 'reports.children', {})
  const matchedPermission = find(
    reportRoutes,
    ({ predicate }) => !!predicate(opts)
  )

  const defaultRoute = (matchedPermission && matchedPermission.link) || '/404'

  return {
    defaultRoute,
  }
}
