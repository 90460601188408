import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'

import { useFlags } from 'components/flags/hook'

import Reports from './components/main'
import { ProtectedRoute } from '../../components/protectedRoute'

import AthenaEventsRoute from './routes/athena-events'
import AuditsRoute from './routes/audits'
import DashboardRoute from './routes/dashboard'
import ExceptionsRoute from './routes/exceptions'
import ExportsRoute from './routes/exports'
import IssuesRoute from './routes/issues'
import LocationsRoute from './routes/locations'
import ShiftsRoute from './routes/shifts'
import MongoEventsRoute from './routes/events'
import TasksRoute from './routes/tasks'

export default function ReportsRoute() {
  const flags = useFlags()

  const EventsRoute =
    flags && flags.athenaEvents ? AthenaEventsRoute : MongoEventsRoute

  return (
    <Reports>
      {({ defaultRoute = '/404' }) => (
        <Switch>
          <ProtectedRoute
            permissions={{ action: 'read', module: 'auditEntry' }}
            path="/reports/audits"
          >
            <AuditsRoute />
          </ProtectedRoute>
          <Route component={DashboardRoute} path="/reports/dashboard" />
          <ProtectedRoute
            permissions={{ action: 'read', module: 'event' }}
            path="/reports/events"
          >
            <EventsRoute />
          </ProtectedRoute>
          <ProtectedRoute
            permissions={{ action: 'read', module: 'loopException' }}
            path="/reports/exceptions"
          >
            <ExceptionsRoute />
          </ProtectedRoute>
          <ProtectedRoute
            permissions={{ action: 'read', module: 'export' }}
            path="/reports/exports"
          >
            <ExportsRoute />
          </ProtectedRoute>
          <ProtectedRoute
            permissions={{ action: 'read', module: 'issue' }}
            path="/reports/issues"
          >
            <IssuesRoute />
          </ProtectedRoute>
          <Route component={LocationsRoute} path="/reports/locations" />
          <ProtectedRoute
            permissions={{ action: 'read', module: 'shift' }}
            path="/reports/shifts"
          >
            <ShiftsRoute />
          </ProtectedRoute>
          <ProtectedRoute
            permissions={{ action: 'read', module: 'taskEntry' }}
            path="/reports/tasks"
          >
            <TasksRoute />
          </ProtectedRoute>
          {/* NOTE default route */}
          <Redirect from="/reports" to={defaultRoute} />
        </Switch>
      )}
    </Reports>
  )
}
