import { find } from 'lodash'
import React from 'react'

import { Block } from 'components/common'
import Select from '../select'

const options = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Australia',
    value: 'AU',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    label: 'South Africa',
    value: 'SA',
  },
  {
    label: 'United Kingdom',
    value: 'UK',
  },
]

export default function CountrySelect(props) {
  const {
    className,
    disabled,
    error,
    multi,
    onBlur,
    onChange,
    placeholder,
    readOnly,
    required,
    simpleValue,
    value,
  } = props

  if (readOnly) {
    const option = find(options, ['value', value])

    return <Block padding={10}>{(option && option.label) || ''}</Block>
  }

  const classes = error ? `${className}, error` : className

  return (
    <Select
      className={classes}
      disabled={disabled}
      multi={multi}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      required={required}
      simpleValue={simpleValue}
      value={value}
    />
  )
}
