import { defaults, isFunction } from 'lodash'
import Promise from 'bluebird'

import {
  LIGHTHOUSE_LOGO_URL,
  DEFAULT_PAGE_MARGINS,
  DEFAULT_PAGE_ORIENTATION,
  DEFAULT_PAGE_SIZE,
} from '../../../constants'

import { defaultFooter, defaultHeader, defaultStyles } from '../'

export function generateDefinition(options) {
  const {
    content,
    fileTitle,
    footer = defaultFooter,
    sequenceId,
    header = defaultHeader,
    logoUrl = LIGHTHOUSE_LOGO_URL,
    pageMargins = DEFAULT_PAGE_MARGINS,
    pageOrientation = DEFAULT_PAGE_ORIENTATION,
    pageSize = DEFAULT_PAGE_SIZE,
    styles = {},
    timestamp,
    timezone,
    type,
  } = options

  const pdfStyles = defaults({}, styles, defaultStyles)
  const pdfInfo = { title: fileTitle }

  const headerPromise = pdfHeader(
    header({
      logoUrl,
      timestamp,
      timezone,
    })
  )

  const contentPromise = pdfContent(content)

  const footerPromise = pdfFooter(
    footer({
      sequenceId,
      logoUrl: LIGHTHOUSE_LOGO_URL,
      timestamp,
      timezone,
      type,
    })
  )

  const promises = [headerPromise, contentPromise, footerPromise]

  return Promise.all(promises)
    .then(([header, content, footer]) => ({
      header,
      content,
      footer,
      info: pdfInfo,
      pageMargins,
      pageOrientation,
      pageSize,
      styles: pdfStyles,
    }))
    .catch(err => {
      throw new Error(`GenerateDefinitionError: ${err.message}`)
    })
}

function pdfHeader(header) {
  if (isFunction(header)) {
    return Promise.resolve(header())
  }

  return Promise.resolve(header)
}

function pdfContent(content) {
  if (isFunction(content)) {
    return Promise.resolve(content())
  }

  return Promise.resolve(content)
}

function pdfFooter(footer) {
  if (isFunction(footer)) {
    return Promise.resolve(footer())
  }

  return Promise.resolve(footer)
}
