/**
 * A wrapper around react-routers Link component
 * so that it supports Radium styles. See:
 * https://github.com/FormidableLabs/radium/tree/master/docs/faq#why-doesnt-radium-work-on-somecomponent
 */

import React from 'react'
import Radium from 'radium'
import { Link } from 'react-router-dom'

const EnhancedComponent = Radium(LinkComponent)

export default function WrappedLink(props) {
  const style = props.logo ? { display: 'flex' } : {}

  return (
    <Link style={style} to={props.to}>
      <EnhancedComponent {...props}>{props.children}</EnhancedComponent>
    </Link>
  )
}

function LinkComponent(props) {
  return <span {...props} />
}
