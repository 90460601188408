import React from 'react'

import { Base } from '../'

export default function Flex(props) {
  const { children, onClick, ...componentProps } = props

  return (
    <Base
      display="flex"
      flexDirection="row"
      cursor={onClick ? 'pointer' : 'inherit'}
      onClick={onClick}
      {...componentProps}
    >
      {children}
    </Base>
  )
}
