import PropTypes from 'prop-types'
import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { compact, map } from 'lodash'
import { compose, pure, withHandlers, withProps, withState } from 'recompose'
import emitter from 'utils/emitter'
import colors from 'config/theme'
import {
  InputGroup,
  InputLabel,
  InputSelect,
  InputText,
  Image,
} from 'components/form'

import Button from 'components/button'
import Icon from 'components/icon'
import { Flex, Block } from 'components/common'
import { Upload } from 'components/buttons'
import { withTranslation } from 'react-i18next'
import { validation } from '@lighthouse/sdk'
import { TOOLTIPS } from 'config/constants'
import i18next from 'i18next'

const isRequired = validation.isRequired({
  errorMessage: i18next.t('validation.requiredField'),
})

export default compose(
  pure,
  withState('showExtras', 'setShowExtras', props => !!props.value),
  withTranslation(),
  withHandlers({
    toggleExtras: props => () => {
      const { showExtras, setShowExtras } = props
      const nextValue = !showExtras
      setShowExtras(nextValue)
    },
    renderFieldImages,
  }),
  withProps(({ scores, required }) => ({
    options: compact([
      ...scores.map(mapScore),
      !required && {
        label: 'N/A',
        value: -1,
      },
    ]),
  }))
)(AuditItemDeprecated)

function AuditItemDeprecated(props) {
  const {
    fieldPath,
    options,
    required,
    showExtras,
    title,
    toggleExtras,
    readOnly,
    renderFieldImages,
    t,
  } = props

  const icon = showExtras ? 'neutral' : 'arrow-down'

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Block width={350}>
          <Field
            component={InputSelect}
            label={title}
            handleExtrasPress={toggleExtras}
            name={`${fieldPath}.score`}
            options={options}
            readOnly={readOnly}
            required={required}
            validate={required ? [isRequired] : []}
            placeholder={t('placeholder.selectAnOption')}
            small
          />
        </Block>
        <Button
          height={40}
          marginBottom={23}
          marginRight={0}
          width={40}
          onClick={toggleExtras}
          backgroundColor="#ffffff"
        >
          <Icon fontSize={20} fontWeight={400} lineHeight={1} name={icon} />
        </Button>
      </Flex>
      {showExtras && (
        <>
          <Field
            component={InputText}
            multiline
            name={`${fieldPath}.comments`}
            label="Add comment"
            readOnly={readOnly}
            small
          />
          <FieldArray
            component={renderFieldImages}
            label="Attach photo"
            name={`${fieldPath}.assets`}
            readOnly={readOnly}
          />
        </>
      )}
    </>
  )
}

AuditItemDeprecated.propTypes = {
  fieldPath: PropTypes.string.isRequired,
  options: PropTypes.array,
  required: PropTypes.bool,
  showExtras: PropTypes.bool,
  title: PropTypes.string,
  toggleExtras: PropTypes.func,
}

function mapScore(score) {
  return {
    label: score.label,
    value: score._id,
  }
}

function handleError(error) {
  emitter.emit('notification:add', {
    message: error.message,
    theme: 'alert',
    title: 'Upload Error',
  })
}

function renderFieldImages({ t }) {
  return ({ fields, label, meta = {}, readOnly, required, small = true }) => {
    const { dirty, error } = meta
    const photoTooltip = [t(TOOLTIPS.photo.titleT), t(TOOLTIPS.photo.messageT)]

    const fieldImages = fields.map((imagePath, index) => (
      <Field
        component={Image}
        handleRemove={() => fields.remove(index)}
        key={index}
        marginBottom="10px"
        minHeight="150px"
        minWidth="270px"
        name={imagePath}
        readOnly={readOnly}
        width={270}
      />
    ))

    return (
      <Block marginBottom="15px">
        <InputGroup small={small} alignTop>
          <InputLabel
            label={label}
            required={required}
            small={small}
            alignTop
          />
          <Flex flexDirection="column" flexGrow={1}>
            {fieldImages}
            {!readOnly && (
              <Upload
                onError={handleError}
                onSuccess={results =>
                  map(results, file => fields.push(`${file.path}`))
                }
                tooltip={photoTooltip}
              />
            )}
            {dirty && error && !readOnly && (
              <Block color={colors.red.normal} fontSize="10px" marginTop="10px">
                {error}
              </Block>
            )}
          </Flex>
        </InputGroup>
      </Block>
    )
  }
}
