import { Interval } from '../scheduling.types'

import { intervalIntersectsEnd } from './intervalIntersectsEnd'
import { intervalIntersectsStart } from './intervalIntersectsStart'

/**
 * Check if interval only intersects another interval's start
 * Accepts two intervals and checks whether the first only intersects the second's start
 *
 * intervalOnlyIntersectsStart([2,10], [5,15])
 * returns true
 */
export function intervalOnlyIntersectsStart(
  firstInterval: Interval,
  secondInterval: Interval
): Boolean {
  return (
    intervalIntersectsStart(firstInterval, secondInterval) &&
    !intervalIntersectsEnd(firstInterval, secondInterval)
  )
}
