/*
 * Tag Module
 */

import { combineReducers } from 'redux'
import crud from '../../crud'
import buildActionCreators from './action-creators'
import {
  getRegionUrl,
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

const resource = 'tags'

export { default as schema } from './schema'

const actions = crud.actions(resource)

// assign custom actions
Object.assign(actions, {
  MARK_DOCUMENT_FOR_TAGGING: `lighthouse/${resource}/MARK_DOCUMENT_FOR_TAGGING`,
  UNMARK_DOCUMENT_FOR_TAGGING: `lighthouse/${resource}/UNMARK_DOCUMENT_FOR_TAGGING`,
  CLEAR_DOCUMENTS_MARKED_FOR_TAGGING: `lighthouse/${resource}/CLEAR_DOCUMENTS_MARKED_FOR_TAGGING`,
  TAG_DOCUMENTS_REQUEST: `lighthouse/${resource}/TAG_DOCUMENTS_REQUEST`,
  TAG_DOCUMENTS_SUCCESS: `lighthouse/${resource}/TAG_DOCUMENTS_SUCCESS`,
  TAG_DOCUMENTS_ERROR: `lighthouse/${resource}/TAG_DOCUMENTS_ERROR`,
  TAG_DOCUMENT_REQUEST: `lighthouse/${resource}/TAG_DOCUMENT_REQUEST`,
  TAG_DOCUMENT_SUCCESS: `lighthouse/${resource}/TAG_DOCUMENT_SUCCESS`,
  TAG_DOCUMENT_ERROR: `lighthouse/${resource}/TAG_DOCUMENT_ERROR`,
  REMOVE_DOCUMENT_TAG_REQUEST: `lighthouse/${resource}/REMOVE_DOCUMENT_TAG_REQUEST`,
  REMOVE_DOCUMENT_TAG_SUCCESS: `lighthouse/${resource}/REMOVE_DOCUMENT_TAG_SUCCESS`,
  REMOVE_DOCUMENT_TAG_ERROR: `lighthouse/${resource}/REMOVE_DOCUMENT_TAG_ERROR`,
})

const baseUrlFn = applicationResourceUrlFn(resource)
const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  customBaseUrlFn,
  authorizationHeadersFn,
)

const actionCreators = {}

Object.assign(
  actionCreators,
  crudActionCreators,
  customActionCreators,
)

export {
  actions,
  actionCreators,
}

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

// NOTE we need to handle this separate to the normal URL fn because the resouce
// portion of the url is dynamic
function customBaseUrlFn(params, state) {
  const regionUrl = getRegionUrl(state)
  return `${regionUrl}/applications/${params.applicationId}/${params.resource}`
}
