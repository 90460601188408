/*
 * Exception Module
 */

import { combineReducers } from 'redux'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import buildActionCreators from './action-creators'
import crud from '../../crud'
import hooks from './hooks'
import statsReducer from './reducers/stats'

const resource = 'exceptions'

export const actions = crud.actions(resource)

Object.assign(actions, {
  AVERAGE_DURATION_REQUEST: `lighthouse/${resource}/AVERAGE_DURATION_REQUEST`,
  AVERAGE_DURATION_SUCCESS: `lighthouse/${resource}/AVERAGE_DURATION_SUCCESS`,
  AVERAGE_DURATION_ERROR: `lighthouse/${resource}/AVERAGE_DURATION_ERROR`,
})

hooks(actions)

const baseUrlFn = applicationResourceUrlFn(resource)
const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  applicationParamsFn,
  authorizationHeadersFn,
)

export const actionCreators = Object.assign(
  {},
  crudActionCreators,
  customActionCreators,
)


export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
  stats: statsReducer,
})
