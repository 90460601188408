export default {
  root: {
    background: '#fff',
  },
  main: {
    position: 'absolute',
    top: 60,
    bottom: 0,
    width: '100%',
  },
}
