import { InputTypes, PluginOption } from './options.types'

const options: PluginOption[] = [
  {
    name: 'WinTeam',
    description: 'Enable the WinTeam Integration',
    fieldName: 'winteam.enabled',
    default: false,
    type: InputTypes.Select,
  },
  {
    name: 'TeamTime Integration',
    description: 'Enable the WinTeam TeamTime Integration',
    fieldName: 'winteam.options.teamtime.enabled',
    default: false,
    type: InputTypes.Select,
    disabled: false,
    isSubOption: true,
  },
  {
    name: 'WinTeam Schedule',
    description: 'Enable the WinTeam Schedule Integration',
    fieldName: 'winteam.options.schedule.enabled',
    default: false,
    type: InputTypes.Select,
    disabled: false,
    isSubOption: true,
  },
]

export default options
