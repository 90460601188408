import { chain } from 'lodash'
import { Resource } from '../../../../types/resource'
import { Option } from '../../../../types/option'

export function getTemplateOptions(
  templates: Resource[],
  type: string
): Option[] {
  return chain(templates)
    .filter(['entity.type', type])
    .map(template => ({
      label: template.entity.name,
      value: template.entity._id,
    }))
    .sortBy('label')
    .value()
}
