import { connect } from 'react-redux'
import { compose, getContext, lifecycle, withHandlers } from 'recompose'
import { filter, first, get, map, memoize, minBy } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import { withLeaflet } from 'react-leaflet'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'

import { geoJsonToMapBounds } from 'components/mapping/helpers'
import emitter from 'utils/emitter'

import {
  AREA_FIELDS,
  TYPE_BUILDING,
  TYPE_GEOFENCE,
} from '../../../lib/constants'

import * as logger from 'utils/logger'

const areaModule = getModule('areas')
const geoModule = getModule('geo')
const signalModule = getModule('signals')

const LOCATION_AREA_PARAMS = {
  type: [TYPE_BUILDING, TYPE_GEOFENCE],
  fields: AREA_FIELDS.join(','),
  perPage: 9999,
}
const SIGNAL_PARAMS = {
  perPage: 9999,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLeaflet,
  withRouter,
  withHandlers({ onSetBuilding, onSetLocation }),
  lifecycle({ componentDidMount, componentWillUnmount })
)(EventsHandlerLayer)

// NOTE the events handler layer is simply used to handle any
// events emitted for the map, we add and destroy the appropriate
// event handlers here therefore nothing is rendered
function EventsHandlerLayer() {
  return null
}

function componentDidMount() {
  emitter.on('map:set-building', this.props.onSetBuilding)
  emitter.on('map:set-location', this.props.onSetLocation)
  emitter.on('map:set-properties', this.props.setProperties)
}

function componentWillUnmount() {
  emitter.off('map:set-building', this.props.onSetBuilding)
  emitter.off('map:set-location', this.props.onSetLocation)
  emitter.off('map:set-properties', this.props.setProperties)
}

function mapDispatchToProps(dispatch) {
  return {
    // NOTE as long as mapDispatchToProps doesn't depend on props it won't be
    // called multiple times. If it did the memozie below would be re-called on
    // every re-render which wouldn't be good
    fetchAreasByArea: memoize(areaId =>
      dispatch(
        areaModule.areasByArea(
          areaId,
          {
            type: '!building',
            fields: AREA_FIELDS,
            perPage: 9999,
          },
          areaId
        )
      )
    ),
    fetchLocationAreas: locationId =>
      dispatch(areaModule.areasByLocation(locationId, LOCATION_AREA_PARAMS)),
    fetchLocationSignals: locationId =>
      dispatch(signalModule.signalsByLocation(locationId, SIGNAL_PARAMS)),
    setBuilding: opts => dispatch(geoModule.setBuilding(opts)),
    setProperties: opts => dispatch(geoModule.setProperties(opts)),
  }
}

function mapStateToProps(state) {
  const areaSelectors = areaModule.selectors(state)

  const areaCache = areaSelectors().cache()
  const getIndoorAreas = listId => areaSelectors(listId).list()

  return {
    areaCache,
    getIndoorAreas,
  }
}

function onSetBuilding(props) {
  const {
    areaCache,
    getIndoorAreas,
    fetchAreasByArea,
    history,
    leaflet,
    selectedBuilding = {},
    setBuilding,
    setLoading,
  } = props

  return properties => {
    const { id: buildingId, fitToBounds = true } = properties

    if (!buildingId) {
      logger.error('Missing buildingId for `onSetBuilding`', { buildingId })
      return
    }

    const currentBuildingId = selectedBuilding.id

    if (currentBuildingId === buildingId) {
      const nextSearch = queryString.stringify({
        id: buildingId,
        resource: 'area',
      })

      history.push({ search: `?${nextSearch}` })

      return
    }

    const buildingArea = areaCache[buildingId]

    if (!buildingArea) {
      logger.error('Building not found in area cache', { buildingId })
      return
    }

    // Get the default building floor
    const buildingFloors = get(buildingArea, 'entity.floors', [])
    const lowestFloor = minBy(buildingFloors, 'level')
    const floor = lowestFloor && lowestFloor.level

    const buildingGeometry = get(buildingArea, 'entity.geometry')

    // zoom to bounds of building
    if (fitToBounds) {
      const geo = buildingGeometry.asMutable
        ? buildingGeometry.asMutable({ deep: true })
        : buildingGeometry

      const bounds = geoJsonToMapBounds(geo)
      leaflet.map.fitBounds(bounds)
    }

    const indoorAreas = getIndoorAreas(buildingId)

    const buildingAreas = filter(indoorAreas, area => {
      if (!area || !area.entity) return false
      if (area.entity.type === 'point') return false
      return true
    })

    // Fetch building areas if not already in cache
    if (buildingAreas.length === 0) {
      // Fetching areas for indoors can be time consuming, so set loader
      setLoading(true)

      fetchAreasByArea(buildingId)
        .catch(error => logger.error(error))
        .finally(() => setLoading(false))
    }

    return setBuilding({
      id: buildingId,
      floor,
    })
  }
}

function onSetLocation(props) {
  const {
    fetchLocationAreas,
    fetchLocationSignals,
    history,
    leaflet,
    selectedBuilding = {},
    setBuilding,
  } = props

  return properties => {
    const { id, fitBounds, geometry, openSidebar } = properties

    if (openSidebar) {
      const nextSearch = queryString.stringify({ resource: 'area', id })
      history.push({ search: `?${nextSearch}` })
    } else {
      history.push({ search: '' })
    }

    const geo = geometry.asMutable
      ? geometry.asMutable({ deep: true })
      : geometry

    const bounds = geoJsonToMapBounds(geo)
    leaflet.map.fitBounds(bounds)

    fetchLocationAreas(id)
      .then(({ data }) => filter(data, ['type', 'building']))
      .then(buildings => map(buildings, '_id'))
      .then(buildingIds => {
        // NOTE reset building if more than one for location area
        if (buildingIds.length !== 1) return setBuilding({})

        const buildingId = first(buildingIds)
        // NOTE don't set building if current building
        if (selectedBuilding.id === buildingId) return
        emitter.emit('map:set-building', { id: buildingId })
      })

    fetchLocationSignals(id)
  }
}
