import { pure } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { Block, Flex } from 'components/common'
import { colors } from 'config/theme'
import DateComponent from '../date'

export default pure(GroupItem)

function GroupItem(props) {
  const { date, isActive, isUnread, name, onClick } = props

  const customStyles = {
    // Show item as bold when a message is unread
    ...(isUnread && { color: colors.blue.light, fontWeight: 500 }),

    // Show item as active/focused when it is currently selected
    ...(isActive && {
      backgroundColor: colors.blue.normal,
      color: colors.white,
    }),
  }

  return (
    <Flex
      backgroundColor={colors.white}
      borderBottom={`1px solid ${colors.gray.lighter}`}
      onClick={onClick}
      cursor="pointer"
      padding={16}
      {...customStyles}
    >
      <Block
        fontSize={13}
        overflow="hidden"
        textOverflow="ellipsis"
        width="75%"
        whiteSpace="noWrap"
      >
        {name || ''}
      </Block>
      <Block fontSize={12} textAlign="right" width="25%">
        <DateComponent date={date} recency />
      </Block>
    </Flex>
  )
}

GroupItem.propTypes = {
  date: PropTypes.instanceOf(Date),
  isActive: PropTypes.bool,
  isUnread: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}
