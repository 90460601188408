import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'
import Icon from 'components/icon'

export default function ListIcon(props) {
  const { children, dataTestId, name, styles } = props

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      dataTestId={dataTestId}
      width={44}
    >
      <Icon name={name} theme={styles} />
      {children}
    </Flex>
  )
}

ListIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  name: PropTypes.string.isRequired,
  styles: PropTypes.object,
}
