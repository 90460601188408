import {
  find,
  first,
  isEqual,
  map,
  merge,
  omit,
  take,
} from 'lodash'

export default function syncEntry(entry, template) {
  if (!entry) throw new Error('Entry must be specified!')
  if (!template) throw new Error('Template must be specified!')

  const { formGroups: entryFormGroups } = entry
  const { formGroups: templateFormGroups } = template

  const syncedFormGroups = syncFormGroups(entryFormGroups, templateFormGroups)

  return {
    formGroups: syncedFormGroups,
  }
}

function syncFormGroups(entryFormGroups, templateFormGroups) {
  return map(templateFormGroups, (templateFormGroup) => {
    const {
      _id,
    } = templateFormGroup

    // NOTE: remove template createdAt, updatedAt and all field _ids
    // as these will not be required when saving the new synced entry
    const cleanedTemplateFormGroup = cleanTemplateFormGroup(templateFormGroup)

    // NOTE: when template form groups are removed an index lookup
    // would not return correct group so find by id!
    const entryFormGroup = find(entryFormGroups, { _id })

    // NOTE: when new template form groups added the entry won't have a
    // matching id so we can simply return the cleaned template form group!
    if (!entryFormGroup) return cleanedTemplateFormGroup

    return syncFormGroup(entryFormGroup, cleanedTemplateFormGroup)
  })
}

function syncFormGroup(entryFormGroup, templateFormGroup) {
  const {
    fieldGroups: entryFieldGroups,
  } = entryFormGroup

  const {
    _id,
    description,
    fieldGroups: templateFieldGroups,
    label,
    repeatable,
  } = templateFormGroup

  // NOTE: we only store one instance of a fieldGroup on the template
  // object so we only need to pass the first instance here
  const templateFieldGroup = first(templateFieldGroups)
  const syncedFieldGroups = syncFieldGroups(entryFieldGroups, templateFieldGroup)

  const hasUnlimitedFieldGroups = repeatable === 0

  // NOTE: return field groups instances based on form groups repeatable value
  const fieldGroups = hasUnlimitedFieldGroups
    ? syncedFieldGroups
    : take(syncedFieldGroups, repeatable)

  return {
    _id,
    description,
    fieldGroups,
    label,
    repeatable,
  }
}

function syncFieldGroups(entryFieldGroups, templateFieldGroup) {
  return map(entryFieldGroups, (entryFieldGroup) => {
    const {
      _id,
      fields: entryFields,
    } = entryFieldGroup

    const {
      fields: templateFields,
    } = templateFieldGroup

    const syncedFields = syncFields(entryFields, templateFields)

    return {
      _id,
      fields: syncedFields,
    }
  })
}

function syncFields(entryFields, templateFields) {
  return map(templateFields, (templateField, index) => {
    // NOTE: we iterate over the fields using the template array index
    // so if entry fields have changed position we won't match!
    const entryField = entryFields[index]

    if (!entryField) return templateField

    const {
      fieldtype: templateFieldType,
      options: templateOptions,
    } = templateField

    const {
      fieldtype: entryFieldType,
      options: entryOptions,
      value: entryValue,
    } = entryField

    // NOTE: check if field type or options have changed, if so simply
    // return the template field so user can re-enter details!
    const hasFieldTypeChanged = !isEqual(templateFieldType, entryFieldType)
    const hasOptionsChanged = !isEqual(templateOptions, entryOptions)
    const skipSync = (hasFieldTypeChanged || hasOptionsChanged || !entryValue)

    if (skipSync) return templateField

    // NOTE: matching entry value so we can be confident that we've
    // synced the entry correctly therefore merge the entry value here!
    return merge({}, templateField, { value: entryValue })
  })
}

function cleanTemplateFormGroup(formGroup) {
  const { fieldGroups } = formGroup

  // NOTE: technically we only have one field group
  // but looping as an array anyhow - future proof!
  formGroup.fieldGroups = map(fieldGroups, (fieldGroup) => {
    fieldGroup.fields = map(fieldGroup.fields, field => omit(field, '_id'))
    return fieldGroup
  })

  return omit(formGroup, ['createdAt', 'updatedAt'])
}
