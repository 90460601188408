import { compose, withHandlers } from 'recompose'
import React from 'react'

import { Flex } from 'components/common'
import Background from 'components/background'
import Icon from 'components/icon'
import Logo from 'components/logo'

import colors from 'config/theme/colors'

import { Modal, ModalHeader, setEndpoints } from '../components'
import Form from './form'

export default compose(
  setEndpoints,
  withHandlers({ handleSubmit })
)(InviteSignup)

function InviteSignup(props) {
  const { handleSubmit, match } = props

  const inviteToken = match.params.inviteToken

  return (
    <Background>
      <Flex
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
      >
        <Logo />
        <Modal>
          <ModalHeader>Complete Signup</ModalHeader>
          <Form inviteToken={inviteToken} submitCallback={handleSubmit} />
        </Modal>
      </Flex>
    </Background>
  )
}

function handleSubmit({ history }) {
  return () => {
    setTimeout(() => history.push('/login'), 3000)
  }
}
