import { chain, find, get, groupBy, map, toLower } from 'lodash'
import React from 'react'
import { Popup as LeafletPopup } from 'react-leaflet'

import { Block } from 'components/common'
import { colors } from 'config/theme'
import emitter from 'utils/emitter'
import Label from './components/label'
import { useTranslation } from 'react-i18next'

const typeColorMap = {
  location: colors.blue.normal,
  auditentries: colors.red.normal,
  taskentries: colors.green.normal,
  issue: colors.orange.normal,
}

const typeLabelMap = {
  auditentries: {
    label: 'recent audits',
    labelT: 'labelRecentAudits',
  },
  location: {
    label: 'locations',
    labelT: 'labelLocations',
  },
  issue: {
    label: 'recent issues',
    labelT: 'labelRecentIssues',
  },
  signal: {
    label: 'devices',
    labelT: 'labelDevices',
  },
  taskentries: {
    label: 'recent tasks',
    labelT: 'labelRecentTasks',
  },
  user: {
    label: 'users',
    labelT: 'labelUsers',
  },
}

const typeIconMap = {
  auditentries: 'pencil-circle',
  issue: 'alert-thick',
  location: 'locations',
  signal: 'beacon',
  taskentries: 'check-checked',
  user: 'user',
}

const typeSortMap = {
  user: 0,
  signal: 1,
  issue: 2,
  taskentries: 3,
  location: 4,
}

export default function MapPopup(props) {
  const { activePopup, clusterInstance, clusters, loading, zoom } = props
  const { t } = useTranslation()
  if (!activePopup || loading) return null

  const cluster = find(clusters, activePopup)
  if (!cluster) {
    // NOTE remove popup as cluster no longer present
    emitter.emit('markers:closePopup')
    return null
  }

  const clusterId = cluster.id || get(cluster, 'properties.id')
  const coordinates = get(cluster, 'geometry.coordinates')
  const position = [coordinates[1], coordinates[0]]
  const leaves =
    cluster.properties.cluster && clusterInstance.getLeaves(clusterId, Infinity)

  const groupedFeatures = groupBy(leaves, ({ properties }) => properties.type)

  const sortedFeatures = leaves
    ? chain(groupedFeatures)
        .toPairs()
        .sortBy(arr => typeSortMap[arr[0]])
        .fromPairs()
        .value()
    : { [cluster.properties.type]: [cluster] }

  const labels = chain(sortedFeatures)
    .map((features, type) => {
      const iconType = typeIconMap[type] || 'location-pin'
      const iconColor = typeColorMap[type] || colors.gray.normal
      const isLocationType = type === 'location'
      const isUserType = type === 'user'
      const typeLabel = toLower(
        typeLabelMap[type].labelT
          ? t(typeLabelMap[type].labelT)
          : typeLabelMap[type].label
      )

      // NOTE: don't show location labels when we show geojson locations
      if (isLocationType && zoom > 14) return null

      if (
        (!isUserType && zoom < 18 && features.length > 1) ||
        (isUserType && zoom < 18 && features.length > 3)
      ) {
        return (
          <Label
            key={type}
            iconColor={iconColor}
            iconType={iconType}
            label={`${features.length} ${typeLabel}`}
          />
        )
      }

      return map(features, ({ geometry, id, properties }, currentIndex) => (
        <Label
          key={currentIndex}
          geometry={geometry}
          iconColor={iconColor}
          iconType={iconType}
          id={id}
          label={properties.label}
          type={properties.type}
        />
      ))
    })
    .compact()
    .value()

  if (!labels.length) return null

  return (
    <LeafletPopup
      autoClose={false}
      closeButton={false}
      closeOnClick={false}
      closeOnEscape={false}
      maxWidth={150}
      maxHeight={170}
      offset={[9, -7]}
      position={position}
    >
      <Block>{labels}</Block>
    </LeafletPopup>
  )
}
