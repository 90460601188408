import { find, get, includes } from 'lodash'
import { useSelector } from 'react-redux'
import { getModule } from '@lighthouse/sdk'

const userApplicationsModule = getModule('userApplications')

interface PermissionRequest {
  action: 'create' | 'read' | 'update' | 'delete'
  module: string
  canLock?: boolean
}

interface UsePermission {
  hasPermission: HasPermission
  checkOwnerAccess: CheckOwnerAccess
  checkLockAccess: CheckLockAccess
}

type HasPermission = (permission: PermissionRequest) => boolean
type CheckOwnerAccess = (permission: PermissionRequest) => boolean
type CheckLockAccess = (
  permission: Pick<PermissionRequest, 'module'>
) => boolean

function usePermission(): UsePermission {
  const hasPermission: HasPermission = useSelector(state =>
    userApplicationsModule.askPermission(state)
  )
  const permissions = useSelector(state =>
    userApplicationsModule.getPermissions(state)
  )
  const checkOwnerAccess: HasPermission = ({ module, action }) => {
    const ownerAccess = get(find(permissions, { module }), 'ownerAccess', [])
    return includes(ownerAccess, action)
  }

  const checkLockAccess = ({ module }) => {
    const lockAccess = get(find(permissions, { module }), 'canLock')
    return !!lockAccess
  }

  return { hasPermission, checkOwnerAccess, checkLockAccess }
}

export { PermissionRequest, usePermission }
