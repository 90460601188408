/*
 * Message Speakers Module
 */

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import crud from '../../../crud'

import {
  isArray,
  map,
  memoize,
  pick,
} from 'lodash'

import buildActionCreators from './action-creators'
import profileReducer from './reducers/profile'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../../app'

const resource = 'messages'
const actionNamespace = 'messages/speakers'
const statePath = `${resource}.speakers`

const actions = crud.actions(actionNamespace)

// assign custom actions
Object.assign(actions, {
  GET_PROFILE_REQUEST: `lighthouse/${actionNamespace}/GET_PROFILE_REQUEST`,
  GET_PROFILE_SUCCESS: `lighthouse/${actionNamespace}/GET_PROFILE_SUCCESS`,
  GET_PROFILE_ERROR: `lighthouse/${actionNamespace}/GET_PROFILE_ERROR`,
})

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  authorizationHeadersFn,
  applicationParamsFn,
)

const actionCreators = Object.assign(
  {},
  crudActionCreators,
  customActionCreators,
)

export {
  actions,
  actionCreators,
}

const crudSelectors = crud.selectors(statePath)

const aliases = createSelector(
  crudSelectors.cache,
  cache => memoize((speakerIdOrIds) => {
    const filteredSpeakers = pick(cache, speakerIdOrIds)
    return map(filteredSpeakers, speaker => speaker.entity.alias)
  }, speakerIdOrIds => (
    isArray(speakerIdOrIds) ? speakerIdOrIds.join('-') : speakerIdOrIds
  )),
)

export const selectors = Object.assign(
  {},
  crudSelectors,
  {
    aliases,
  },
)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
  profile: profileReducer,
})


function baseUrlFn(params) {
  const baseUrl = applicationResourceUrlFn(resource)(params)
  return `${baseUrl}/speakers`
}
