import { getModule } from '@lighthouse/sdk'
import { centerOfMass, featureCollection } from '@turf/turf'
import Leaflet from 'leaflet'
import {
  every,
  first,
  get,
  groupBy,
  includes,
  reduce,
  reject,
  some,
} from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import { compose, withHandlers } from 'recompose'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Marker, Tooltip, withLeaflet } from 'react-leaflet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { change, touch } from 'redux-form'

import { geoJsonToMapBounds } from 'components/mapping/helpers'
import emitter from 'utils/emitter'
import ClusterIcon from './icon'

import {
  activeBeaconIcon,
  activeNfcIcon,
  activeQrIcon,
  auditIcon,
  beaconIcon,
  nfcIcon,
  qrIcon,
  bicycleActiveIcon,
  bicycleInactiveIcon,
  bicycleRecentIcon,
  duressIcon,
  issueIcon,
  personActiveIcon,
  personInactiveIcon,
  personRecentIcon,
  taskIcon,
  vehicleActiveIcon,
  vehicleInactiveIcon,
  vehicleRecentIcon,
} from '../../../../../lib/icons'

import { MARKER_ZINDEX_OFFSET } from '../../../../../lib/constants'

const areaModule = getModule('areas')
const geoModule = getModule('geo')

const iconSets = {
  in_vehicle: {
    active: vehicleActiveIcon,
    inactive: vehicleInactiveIcon,
    recent: vehicleRecentIcon,
  },
  on_bicycle: {
    active: bicycleActiveIcon,
    inactive: bicycleInactiveIcon,
    recent: bicycleRecentIcon,
  },
  still: {
    active: personActiveIcon,
    inactive: personInactiveIcon,
    recent: personRecentIcon,
  },
}

const styles = {
  label: {
    fontSize: 12,
  },
  labelTitle: {
    display: 'block',
    fontWeight: 500,
  },
  labelExtra: {
    display: 'block',
    color: '#999',
    fontSize: 10,
    fontWeight: 400,
  },
}

const TIMEOUT_INACTIVE = 3600000 // 60 mins
const TIMEOUT_RECENT = 300000 // 5 mins
const DEFAULT_ICON_SET = 'still'

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLeaflet,
  withRouter,
  withHandlers({
    onClick,
    onClickCluster,
    onDoubleClickCluster,
    onDragEnd,
  })
)(MapClusters)

function MapClusters(props) {
  const {
    activeTooltip,
    clusterInstance,
    clusters,
    filters,
    loading,
    onClick,
    onClickCluster,
    onDoubleClickCluster,
    onDragEnd,
    showTooltips,
    timezone,
    urlId,
    zoom,
  } = props

  const isUserFilterOn = !!filters.types.user

  const markers = reduce(
    clusters,
    (accum, cluster) => {
      // turf used to always return a cluster object even for single features
      // Supercluster only returns a cluster object if there are two or more
      // features, otherwise sdk returns a single feature, however we treat
      // single features as a cluster in the code
      const clusterId = cluster.properties.id || cluster.id
      const leaves = cluster.properties.cluster
        ? clusterInstance.getLeaves(clusterId, Infinity)
        : false

      const hasLocationFeature = leaves
        ? some(leaves, { properties: { type: 'location' } })
        : cluster.properties.type === 'location'

      const isSingleLocationFeature =
        !cluster.properties.point_count && hasLocationFeature

      const hasAllLocationFeatures =
        leaves && every(leaves, ['properties.type', 'location'])

      const isSingleFeature =
        (!cluster.properties.point_count && !hasLocationFeature) ||
        (cluster.properties.point_count === 2 && !hasAllLocationFeatures)

      if (
        (zoom > 14 && isSingleLocationFeature) ||
        (zoom > 14 && hasAllLocationFeatures)
      ) {
        return accum
      }

      // NOTE we don't cluster above zoom level 17 and should show individual points
      if (isSingleFeature && zoom > 17) {
        const feature = first(
          reject(leaves, { properties: { type: 'location' } })
        )
        const singleFeature = feature || cluster
        const { id, properties } = singleFeature
        const { label, type } = properties

        const coordinates = get(singleFeature, 'geometry.coordinates', [])
        const lat = coordinates[1]
        const lng = coordinates[0]

        const isSelectedMarker = urlId === id && type !== 'user'
        const icon = getIcon(properties, isSelectedMarker)

        const isActiveTooltip = activeTooltip === id
        const showTooltip = showTooltips || isActiveTooltip || isSelectedMarker

        if (!properties) return accum

        // NOTE: user type markers aren't currently supported on temporary marker
        // layer so ignore for now
        if (isSelectedMarker) return accum

        if (!lat || !lng) {
          console.warn('Missing coordinates for marker: ', id)
          return accum
        }

        if (!icon) {
          console.warn('Missing icon for marker: ', id)
          return accum
        }

        // NOTE I did try importing the <DatetimeTimezone /> component to use instead
        // of this, but it doesn't seem to like being inside of the Tooltip. It's
        // probably because it's rendered outside of app structure and therefore
        // doesn't have access to the store
        const labelExtra =
          showTooltip &&
          properties.timestamp &&
          properties.type === 'user' &&
          `Since: ${moment(properties.timestamp)
            .tz(timezone)
            .format('ddd Do hh:mma')}`

        const marker = (
          <Marker
            key={`${id}`}
            draggable={false}
            label={label}
            icon={icon}
            position={[lat, lng]}
            onClick={onClick(singleFeature)}
            onDragEnd={onDragEnd(singleFeature)}
            zIndexOffset={MARKER_ZINDEX_OFFSET}
          >
            {showTooltip && (
              <Tooltip
                direction="top"
                permanent
                offset={[0, -15]}
                position={[lat, lng]}
              >
                <div>
                  <div style={styles.label}>
                    <span style={styles.labelTitle}>{label}</span>
                    {labelExtra && (
                      <span style={styles.labelExtra}>{labelExtra}</span>
                    )}
                  </div>
                </div>
              </Tooltip>
            )}
          </Marker>
        )

        accum.push(marker)
        return accum
      }

      const clusterCenter = centerOfMass(cluster)
      const coordinates = clusterCenter.geometry.coordinates

      const groupedFeatures = groupBy(
        leaves,
        ({ properties }) => properties.type
      )

      const userFeaturesLength = groupedFeatures.user
        ? groupedFeatures.user.length
        : 0

      const loadingDots = (
        <div className="loading-dots">
          <span className="dot dot-1" />
          <span className="dot dot-2" />
          <span className="dot dot-3" />
        </div>
      )

      const icon = new Leaflet.DivIcon({
        html: ReactDOMServer.renderToString(
          <ClusterIcon
            height={24}
            isLocation={zoom < 15 && hasLocationFeature}
            userCount={
              loading
                ? loadingDots
                : !isUserFilterOn
                ? ''
                : userFeaturesLength <= 99
                ? userFeaturesLength
                : '99+'
            }
            width={24}
          />
        ),
      })

      const marker = (
        <Marker
          key={`${clusterId}-${Date.now()}`}
          icon={icon}
          onClick={onClickCluster(cluster)}
          onDblClick={onDoubleClickCluster(cluster)}
          position={[coordinates[1], coordinates[0]]}
        />
      )

      accum.push(marker)
      return accum
    },
    []
  )

  return markers
}

function getIcon(properties, isSelectedMarker) {
  const { movementType = {}, type } = properties
  switch (type) {
    case 'issue': {
      const issueType = get(properties, 'meta.issueType')
      const isDuress = issueType === 'duress'

      if (isDuress) return duressIcon

      return issueIcon
    }
    case 'signal':
      const { subType } = properties
      if (isSelectedMarker) {
        const activeSignalIcon =
          subType === 'qrcode'
            ? activeQrIcon
            : subType === 'nfc'
            ? activeNfcIcon
            : activeBeaconIcon
        return activeSignalIcon
      }

      const signalIcon =
        subType === 'qrcode' ? qrIcon : subType === 'nfc' ? nfcIcon : beaconIcon
      return signalIcon

    case 'taskentries': {
      return taskIcon
    }
    case 'auditentries': {
      return auditIcon
    }
    case 'user': {
      const markerState = getActivityState(properties)
      const movement = movementType.type
      const iconSet = iconSets[movement] || iconSets[DEFAULT_ICON_SET]

      return iconSet[markerState]
    }
    default:
      return undefined
  }
}

function getActivityState(properties) {
  const now = new Date()
  const timestamp = new Date(properties.timestamp)
  const timeLapsed = now - timestamp

  if (timeLapsed > TIMEOUT_INACTIVE) {
    return 'inactive'
  } else if (timeLapsed > TIMEOUT_RECENT) {
    return 'recent'
  }

  return 'active'
}

function onClick(props) {
  const { history } = props

  return marker => () => {
    const { id, geometry, properties } = marker
    const { label, type, subType } = properties
    const {
      coordinates: [lng, lat],
    } = geometry

    emitter.emit('markers:openTooltip', id)

    const isClickableType = includes(
      ['auditentries', 'issue', 'signal', 'taskentries'],
      type
    )

    // NOTE return if invalid coordinates or not clickable type
    if (!lat || !lng || !isClickableType) return

    const nextSearch = queryString.stringify({
      id,
      lat,
      lng,
      icon: subType
        ? subType === 'beacon'
          ? 'activeBeaconIcon'
          : subType === 'nfc'
          ? 'activeNfcIcon'
          : 'activeQrIcon'
        : '',
      resource: type,
      showMarker: true,
      title: label,
    })

    history.push({ search: `?${nextSearch}` })
  }
}

function onClickCluster() {
  return cluster => () => emitter.emit('markers:openPopup', cluster)
}

function onDoubleClickCluster(props) {
  const { clusterInstance, leaflet } = props

  return cluster => () => {
    if (!cluster.properties.cluster) {
      const { properties } = cluster

      emitter.emit('map:set-location', {
        geometry: cluster.geometry,
        fitBounds: true,
        id: properties.id,
      })

      emitter.emit('search:set-location', {
        geometry: cluster.geometry,
        label: properties.label,
        value: properties.id,
      })

      return
    }

    const leaves = featureCollection(
      clusterInstance.getLeaves(cluster.id, Infinity)
    )

    const bounds = geoJsonToMapBounds(leaves)
    leaflet.map.fitBounds(bounds)
  }
}

function onDragEnd(props) {
  const {
    building = {},
    changeField,
    findEnclosing,
    setMarkers,
    touchField,
  } = props

  return marker => e => {
    const { id } = marker

    if (!id) {
      console.debug('Not setting marker position because of missing `id`')
    }

    const newLatLng = e.target.getLatLng()
    const coordinates = [newLatLng.lng, newLatLng.lat]

    const updatedMarker = {
      ...marker,
      geometry: {
        ...marker.geometry,
        coordinates,
      },
      properties: {
        ...marker.properties,
      },
    }

    const markerType = get(marker, 'properties.type')

    // update form fields on drag end
    const formName = `${markerType}-${id}`

    changeField(formName, 'geometry.coordinates.1', newLatLng.lat)
    changeField(formName, 'geometry.coordinates.0', newLatLng.lng)

    const enclosingBuilding = findEnclosing(updatedMarker.geometry, 'building')

    const isOutOfCurrentBuilding =
      enclosingBuilding && enclosingBuilding.id !== building && building.id

    // NOTE remove floorRef value if no enclosing building or the current
    // building does not match the current enclosing building
    if (!enclosingBuilding || isOutOfCurrentBuilding) {
      updatedMarker.properties.floorsRef = []
      changeField(formName, 'floorsRef', [])
    }

    // NOTE we need to touch the fields manually so we can show the warning in
    // the panel form that they're pending save
    touchField(formName, 'geometry.coordinates.1')
    touchField(formName, 'geometry.coordinates.0')
    touchField(formName, 'floorsRef')

    setMarkers([updatedMarker])
  }
}

function mapStateToProps(state) {
  const areaSelectors = areaModule.selectors(state)('default')
  const findEnclosing = areaSelectors.findEnclosing
  const filters = get(state, 'geo.markers.filters')

  return {
    building: state.geo.building,
    filters,
    findEnclosing,
    form: state.form,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeField: (form, field, value) => dispatch(change(form, field, value)),
    touchField: (form, field) => dispatch(touch(form, field)),
    setMarkers: markers => dispatch(geoModule.setMarkers(markers)),
  }
}
