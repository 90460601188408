import { getModule } from '@lighthouse/sdk'
import { assign, get, isEqual, noop, pick, toNumber } from 'lodash'
import { connect } from 'react-redux'
import { StickyContainer, Sticky } from 'react-sticky'
import { InfiniteLoader, Table } from 'react-virtualized'
import { compose, lifecycle, withHandlers, withProps } from 'recompose'
import { withRouter } from 'react-router-dom'
import React from 'react'

import { Wrapper } from 'components/common'
import Alert from 'components/alert'
import Button from 'components/button'
import DateTime from 'components/form/date-time'
import FilterMenu from 'components/filter-menu'
import Resolver from 'components/resolver'
import ScrollWrapper from 'components/table-next/scroll-wrapper'
import sharedStyles from 'components/table-next/styles'
import StickyInner from 'components/table-next/sticky-inner'
import TableSpinner from 'components/table-next/table-spinner'
import TitleBar from 'modules/title-bar'
import Icon from 'components/icon'
import ExportConfirmation from 'components/export-confirmation'
import Spinner from 'components/spinner/dots'

import {
  getResource,
  getSearchFilter,
  handleSearchFilter,
  isRowLoaded,
  setFilter,
} from 'helpers/crud/data-table'
import { useTranslation } from 'react-i18next'

const { rowStyle } = sharedStyles

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({ resetAndFetch }),
  Resolver(({ resetAndFetch }) => resetAndFetch()),
  withProps(props => ({
    searchFilter: getSearchFilter(props),
  })),
  withHandlers({
    fetchHistorical,
    getResource,
    handleClick,
    handleRowMouseOut,
    handleRowMouseOver,
    handleSearchFilter,
    isRowLoaded,
    setFilter,
  }),
  lifecycle({
    componentWillReceiveProps,
  })
)(BasicTable)

function BasicTable(props) {
  const {
    allCaches,
    addNewEnabled = true,
    addNewLabel,
    addNewLink,
    children,
    categories,
    dataTestId,
    enableDateFilter = false,
    enableExport = false,
    exportData,
    exportStatus = '',
    enableFilter = true,
    enableSearch = false,
    fetch,
    fetchHistorical,
    filterCategories = {},
    getResource,
    handleClearFilter,
    handleClearAllFilters,
    handleClick,
    handleRowMouseOut,
    handleRowMouseOver,
    handleSearchFilter,
    handleSetFilter,
    isResolving,
    isRowLoaded,
    list,
    listFilters,
    pageKey,
    searchFilter,
    selectedFilters = {},
    setFilter,
    setExportStatus,
    title,
    totalCount,
  } = props

  const { t } = useTranslation()

  const count = get(list, 'items.length', 0)
  const loadMoreRows = isResolving ? noop : () => fetchHistorical()
  const showSpinner = count === 0 && isResolving
  const virtualRowCount = count < totalCount ? count + 50 : totalCount

  // useEffect(() => {
  //   setExportStatus(exportStatus)
  // }, [exportStatus, setExportStatus])

  const networkError = get(list, 'error.name') === 'NetworkError'

  const noRowsRenderer = isResolving
    ? noop
    : networkError
    ? () => (
        <>
          <Alert messages={[t('alert.networkError')]} type="error" />
          <Button onClick={fetch} theme="primary">
            Retry
          </Button>
        </>
      )
    : () => (
        <Alert messages={[t('alert.noItemsCouldBeFound', { item: title })]} />
      )

  return (
    <StickyContainer>
      <Wrapper>
        <TitleBar title={title}>
          {enableDateFilter && (
            <DateTime
              minWidth={180}
              marginRight={10}
              onChange={setFilter}
              value={pick(listFilters, 'from', 'to')}
            />
          )}
          {addNewEnabled && addNewLabel && addNewLink && (
            <Button
              dataTestId={dataTestId}
              link={addNewLink}
              theme="primary noMargin"
            >
              {addNewLabel}
            </Button>
          )}
          {enableExport && exportData && (
            <Button
              dataTestId={dataTestId}
              onClick={() => setExportStatus('confirm')}
              theme="noMargin positive largeIcon"
              disabled={!totalCount}
            >
              {exportStatus === 'processing' ? (
                <Spinner size="small" theme="inverted" />
              ) : (
                <Icon name="download" />
              )}
            </Button>
          )}
        </TitleBar>
        <ExportConfirmation
          totalCount={totalCount}
          confirmHandler={exportData}
          state={exportStatus}
          t={t}
          cancelHandler={() => setExportStatus('')}
        />
        <Sticky topOffset={113}>
          {props =>
            StickyInner(props, () => (
              <FilterMenu
                allCaches={allCaches}
                categories={categories}
                enableFilter={enableFilter}
                enableSearch
                loading={isResolving}
                onClearFilter={handleClearFilter}
                onClearAllFilters={handleClearAllFilters}
                onSetFilter={handleSetFilter}
                onSearch={handleSearchFilter}
                pageKey={pageKey}
                resultsCount={count}
                selectedFilters={selectedFilters}
                search={searchFilter}
                totalCount={totalCount}
              />
            ))
          }
        </Sticky>
        {showSpinner && <TableSpinner />}
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={totalCount}
          threshold={25}
        >
          {props =>
            ScrollWrapper(
              props,
              ({
                height,
                isScrolling,
                onRowsRendered,
                onChildScroll,
                registerChild,
                scrollTop,
                width,
              }) => (
                <Table
                  autoHeight
                  className="clickable"
                  headerHeight={50}
                  height={height}
                  isScrolling={isScrolling}
                  onRowMouseOut={handleRowMouseOut}
                  onRowMouseOver={handleRowMouseOver}
                  noRowsRenderer={noRowsRenderer}
                  onRowClick={handleClick}
                  onRowsRendered={onRowsRendered}
                  onScroll={onChildScroll}
                  ref={registerChild}
                  rowCount={virtualRowCount}
                  rowGetter={getResource}
                  rowHeight={50}
                  rowStyle={rowStyle}
                  scrollTop={scrollTop}
                  width={width}
                >
                  {children}
                </Table>
              )
            )
          }
        </InfiniteLoader>
      </Wrapper>
    </StickyContainer>
  )
}

function handleClick({ addNewLink, history }) {
  return ({ rowData }) => {
    history.push(`${addNewLink}/${rowData.id}`)
  }
}

function handleRowMouseOut({ onRowMouseOut = noop }) {
  return data => onRowMouseOut(data)
}

function handleRowMouseOver({ onRowMouseOver = noop }) {
  return data => onRowMouseOver(data)
}

function componentWillReceiveProps(nextProps) {
  const { listFilters, resetAndFetch } = nextProps
  if (isEqual(this.props.listFilters, listFilters)) return

  resetAndFetch()
}

export function fetchHistorical(props) {
  const { fetch, list, paginate } = props
  return () => {
    const nextPage = get(list, 'pagination.links.next.page', '1')
    const page = toNumber(nextPage)
    paginate({ page })
    return fetch()
  }
}

function resetAndFetch(props) {
  const { clearList, fetch, list, listId, paginate, params } = props
  return () => {
    // NOTE check for existing items before attempting to clear list which can
    // cause state issues if list doesn't exist in cache
    if (list.items) {
      clearList([listId])
    }

    paginate(params)
    fetch()
  }
}

function mapStateToProps(state, props) {
  const { moduleKey, listId } = props
  const list = get(state, [...moduleKey, 'list', listId], {})
  const { filters = {}, items = {}, pagination = {} } = list
  const module = getModule(...moduleKey)
  const resources = get(state, [...moduleKey, 'cache'], {})
  const selectors = module.selectors(state)(listId)
  const totalCount = toNumber(pagination.totalCount || items.length || 0)

  return {
    isResolving: selectors.state === 'resolving',
    list,
    listFilters: filters,
    resources,
    totalCount,
  }
}

function mapDispatchToProps(dispatch, props) {
  const { listId, moduleKey, params } = props
  const module = getModule(...moduleKey)

  return {
    clearList: listIds => dispatch(module.clearListItems(listIds)),
    clearListFilters: listId => dispatch(module.clearListFilters(listId)),
    fetch: () =>
      dispatch(
        module.query(listId, assign({}, { appendToList: true }, params))
      ),
    paginate: opts => dispatch(module.setPaginationOpts(listId, opts)),
    setFilters: (listId, filters) =>
      dispatch(module.setListFilters(listId, filters)),
  }
}
