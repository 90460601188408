import { colors } from 'config/theme/brand'
import typography from 'config/theme/typography'

/**
 * Reset styles that are applied to the body. Note that these
 * should be used minimally to reduce cascading (as it's unpredictable).
 */
export default {
  body: {
    color: colors.text,
    margin: 0,
    fontFamily: typography.defaultFontFamily,
    fontSize: 14,
    fontWeight: 400,
    MozOsxFontSmoothing: 'grayscale',
    fontSmoothing: 'antialiased',
  },
  button: {
    fontFamily: typography.defaultFontFamily,
  },
  a: {
    textDecoration: 'none',
    color: colors.text,
  },
  // http://ianobermiller.com/blog/2015/06/24/styling-visited-links-with-radium-and-react/
  'a:visited': {
    color: colors.text,
  },
  'p > a': {
    textDecoration: 'underline',
    color: colors.primary,
    cursor: 'pointer',
  },
  'p > a:visited': {
    textDecoration: 'underline',
    color: colors.primary,
    cursor: 'pointer',
  },
  ':focus': {
    outline: 'none',
  },
  '.click-disabled': {
    pointerEvents: 'none !important',
  },
}
