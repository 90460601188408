/*
 * Message Rooms Module
 */

import {
  find,
  filter,
  memoize,
  reduce,
} from 'lodash'

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../../app'

import buildActionCreators from './action-creators'
import crud from '../../../crud'
import hooks from './hooks'
import customReducer from './reducers'
import schema from './schema'
import { speakerIdSelector } from '../../user-applications'

const resource = 'messages'
const actionNamespace = 'messages/rooms'
const statePath = `${resource}.rooms`

const actions = crud.actions(actionNamespace)

// assign custom actions
Object.assign(actions, {
  MARK_AS_READ_REQUEST: `lighthouse/${actionNamespace}/MARK_AS_READ_REQUEST`,
  MARK_AS_READ_SUCCESS: `lighthouse/${actionNamespace}/MARK_AS_READ_SUCCESS`,
  MARK_AS_READ_ERROR: `lighthouse/${actionNamespace}/MARK_AS_READ_ERROR`,
})

hooks(actions)

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn,
  statePath,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  headersFn,
  applicationParamsFn,
)

const actionCreators = Object.assign(
  {},
  crudActionCreators,
  customActionCreators,
)

export {
  actions,
  actionCreators,
  schema,
}

const crudSelectors = crud.selectors(statePath)

// NOTE we memoize the function that returns the selector here so we don't
// create a new selector for every call
const hasUnread = createSelector(
  crudSelectors.cache,
  cache => memoize((roomId, speakerId) => {
    const room = cache[roomId].entity
    return speakerHasUnreadInRoom(room, speakerId)
  }, (...args) => args.join('-')),
)

const unreadCount = createSelector(
  crudSelectors.cache,
  speakerIdSelector,
  (cache, speakerId) => {
    const count = reduce(cache, (accum, room) => {
      const hasUnreadInRoom = speakerHasUnreadInRoom(room.entity, speakerId)
      return hasUnreadInRoom ? ++accum : accum
    }, 0)
    return count
  },
)

// NOTE speakerIdSelector is handled within the userApplications module, because
// that's where the speakers Id is stored for the appropriate application
const speakerRooms = createSelector(
  crudSelectors.cache,
  speakerIdSelector,
  (cache, speakerId) => (
    filter(cache, {
      entity: {
        speakers: [{
          speaker: speakerId,
        }],
      },
    })
  ),
)

export const selectors = Object.assign(
  {},
  crudSelectors,
  {
    hasUnread,
    unreadCount,
    speakerRooms,
  },
)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
  customReducer,
})

function baseUrlFn(params) {
  const baseUrl = applicationResourceUrlFn(resource)(params)
  return `${baseUrl}/rooms`
}

export function headersFn(params, state) {
  const headers = authorizationHeadersFn(params, state)

  if (params.speakerId) {
    headers.speakerId = params.speakerId
  }

  return headers
}

function speakerHasUnreadInRoom(room, speakerId) {
  const {
    latestMessage,
    speakers,
  } = room
  const speaker = find(speakers, { speaker: speakerId })
  if (!speaker || !latestMessage) return false
  const speakerLastRead = speaker.lastRead
  const { created: latestMessageTimestamp } = latestMessage

  // if the speaker has no last read, but there
  // is a latest message, it should be unread
  if (!speakerLastRead && latestMessageTimestamp) return true

  const speakerHasUnread = (speakerLastRead < latestMessageTimestamp)
  return speakerHasUnread
}
