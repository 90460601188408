import { ContentState, convertToRaw } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

export default {
  transformToDraft,
}
/**
 * transformToDraft Transforms a HTML string to a content object for the
 * react-draft-wysiwyg
 *
 * NOTE: Ideally we would only pass either a string, or an object as a param,
 * however it causes issues with redux-form if we only support one or the other.
 *
 * The incoming value could either be a string (if it is coming from the template document),
 * or it could already be an object, if a field has been added or deleted in the same form group.
 *
 * @param  {Object or String} raw DraftJS Object, HTML string or null
 * @return {Object} Empty object or Draft JS content state object
 */
export function transformToDraft(value) {
  if (typeof value === 'string') {
    const { contentBlocks, entityMap } = htmlToDraft(value)
    const content = ContentState.createFromBlockArray(contentBlocks, entityMap)

    return convertToRaw(content)
  }

  return value
}
