import {
  array,
  number,
  object,
  string,
  NumberSchema,
  StringSchema,
} from 'yup'

import { filter, first, isEmpty, isNil, last, orderBy, values } from 'lodash/fp'
import { some } from 'lodash'

const MAX_OFFSET_MS = 604800000

export enum Behaviours {
  Open = 'OPEN',
  Close = 'CLOSE',
}

export enum Durations {
  Second = 1000,
  Minute = 60000,
  Hour = 3600000,
  Day = 86400000,
  Week = 604800000,
}

export enum Types {
  Default = 'DEFAULT',
  Override = 'OVERRIDE',
}

export enum Weekdays {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export interface DefaultHoursSchema {
  description?: string
  duration?: number
  end: number
  start: number
  type: Types
}

export interface OverrideHoursSchema extends DefaultHoursSchema {
  behaviour: Behaviours
}

export type HoursSchema = DefaultHoursSchema | OverrideHoursSchema

export interface Schema {
  hours: Array<HoursSchema>
  options?: object
  timezone: string
}

const hoursSchema = object({
  type: string()
    .oneOf([Types.Default, Types.Override])
    .required(),
  start: number()
    .min(0)
    .required(),
  end: number()
    .min(0)
    .required()
    .when('start', (start: number, endSchema: NumberSchema) => {
      return endSchema.min(start)
    }),
  duration: number()
    .integer()
    .when(['start', 'end'], (start: number, end: number, durationSchema: NumberSchema) => {
      // NOTE This is a bit of a hack to check the exact value, but I can't find
      // an API to check the exact value of a number
      const expectedDuration = end - start
      const message = '${path} must be equal to ' + expectedDuration
      return durationSchema
        .min(expectedDuration, message)
        .max(expectedDuration, message)
    }),
  description: string(),
  behaviour: string()
    .oneOf([Behaviours.Open, Behaviours.Close])
    .when('type', (type: Types, behaviourSchema: StringSchema) => {
      return type === Types.Override ? behaviourSchema.required() : behaviourSchema.strip(true)
    }),
})

export const schema = object({
  timezone: string()
    .required(),
  options: object({
    startWeekday: string()
      .oneOf(values(Weekdays))
  }),
  hours: array()
    .of(hoursSchema)
    .test('hasUndefinedHours', 'hours is a required field', value => !isNil(value))
    .test(
      'hasOverlappingDefaultHours',
      'default hours must not overlap',
      value => {
        const defaultHours = filter({ type: Types.Default }, value)
        const hasOverlappingHours = validateOverlappingHours({
          hoursArr: defaultHours,
        }) !== -1

        if (hasOverlappingHours) return false

        return true
      }
    )
    .test(
      'hasOverlappingOverrideHours',
      'override hours must not overlap',
      value => {
        const overrideHours = filter({ type: Types.Override }, value)
        const hasOverlappingHours = validateOverlappingHours({
          hoursArr: overrideHours,
          isUnix: true,
        }) !== -1

        if (hasOverlappingHours) return false

        return true
      }
    ),
})

interface ValidateOverlappingHours {
  hoursArr: Array<HoursSchema>
  isUnix?: Boolean
}

/**
 * validateOverlappingHours
 * Validates that any hours do not overlap each other 
 * Validates default hours are within a week range
 */
export function validateOverlappingHours(options: ValidateOverlappingHours): Number {
  const { hoursArr, isUnix } = options
  let failingIndex = -1

  if (isEmpty(hoursArr)) {
    return failingIndex
  }

  // NOTE: when default hours and not override hours
  if (!isUnix) {
    const sortedHoursArr = orderBy(['start', 'end'], ['asc', 'asc'], hoursArr)

    const { start: firstStart } = first(sortedHoursArr)
    const { end: lastEnd } = last(sortedHoursArr)

    // NOTE: default service hour values are invalid if the first start and
    // last end exceed the MAX_OFFSET_MS which is a week
    const isRangeWithinWeek = (lastEnd - firstStart) <= MAX_OFFSET_MS

    if (!isRangeWithinWeek) {
      failingIndex = 1
      return failingIndex
    }
  }

  some(hoursArr, (thisHours, thisIndex) => {
    const overlap = some(hoursArr, (otherHours, otherIndex) => {
      if (otherIndex === thisIndex) return false

      const { start: thisStart, end: thisEnd } = thisHours
      const { start: otherStart, end: otherEnd } = otherHours

      const isMatching = otherStart === thisStart && otherEnd === thisEnd
      const isWithin = thisStart > otherStart && thisEnd < otherEnd
      const overlappingStart = thisStart < otherStart && thisEnd > otherStart
      const overlappingEnd = thisStart < otherEnd && thisEnd > otherEnd

      return isMatching || isWithin || overlappingStart || overlappingEnd
    })

    if (overlap) {
      failingIndex = thisIndex
      return true
    }
  })

  return failingIndex
}

export default schema
