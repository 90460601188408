import { get } from 'lodash'
import { getErrorDataFn, getSetListFiltersDataFn, getSuccessDataFn } from '../../tracking/helpers'

const category = 'Reports (Tasks)'

export const findErrorDataFn = getErrorDataFn({ category, action: 'Find Error' })

export const findSuccessDataFn = getSuccessDataFn({ category, action: 'Find Success', label: labelFn })

export const setListFiltersDataFn = getSetListFiltersDataFn({ category })

function labelFn(state, action) {
  return get(action, 'data.task.name')
}
