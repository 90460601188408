import { registerPosthook } from 'redux-hook-middleware'

import { SEND_TRACKING } from '../../../constants'

import {
  removeErrorDataFn,
  removeSuccessDataFn,
  saveErrorDataFn,
  saveSuccessDataFn,
} from '../tracking'

export default function hooks(actions) {
  registerPosthook(actions.REMOVE_ERROR, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: removeErrorDataFn,
      hookedAction: action,
    })
  })

  registerPosthook(actions.REMOVE_SUCCESS, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: removeSuccessDataFn,
      hookedAction: action,
    })
  })

  registerPosthook(actions.SAVE_ERROR, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: saveErrorDataFn,
      hookedAction: action,
    })
  })

  registerPosthook(actions.SAVE_SUCCESS, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: saveSuccessDataFn,
      hookedAction: action,
    })
  })
}
