import moment from 'moment'
import { mapSchemaToForm } from 'components/service-hours-editor/helpers/formatData'
import {
  FormData,
  FormRepeatOptions,
  Schedule,
  Strategies,
  Repeat,
  Types,
  Unit,
} from '../work.types'

export const getInitialValues = (): FormData => {
  const mNow = moment()

  const mEndAtDate = mNow
    .startOf('day')
    .add(1, 'day')
    .toDate()

  const mStartAt = moment().startOf('day')
  const mStartAtDate = mStartAt.toDate()
  const mStartAtDay = mStartAt.isoWeekday()

  return {
    enabled: true,
    endAt: mEndAtDate,
    endAtTime: '17:00',
    name: undefined,
    monthRepeatType: 'exactDate',
    locations: undefined,
    repeat: Repeat.SinceCompletion,
    repeatDays: 1,
    repeatHours: 1,
    repeatMinutes: 5,
    repeatMonths: 1,
    repeatUnit: Unit.Hour,
    repeatWeeks: 1,
    repeatYears: 1,
    serviceHours: {
      hours: [],
      // NOTE: this is set but we don't use it yet
      timezone: 'utc',
    },
    startAt: mStartAtDate,
    startAtTime: '09:00',
    type: Types.Visit,
    weekdays: [mStartAtDay],
    useLocationServiceHours: true,
  }
}

export function mapPayloadToFormData(schedule: Schedule): FormData {
  const {
    areas,
    enabled,
    endAt,
    locations,
    name,
    reference,
    roles,
    serviceHours: scheduleServiceHours,
    strategy,
    startAt,
    template,
    type,
    users,
  } = schedule

  // NOTE: return all payload options regardless of schedule type as we'll want
  // some default values if the user changes the schedule options
  const repeatOptions: FormRepeatOptions = mapPayloadToRepeatOptions({
    endAt,
    startAt,
    strategy,
  })

  const useLocationServiceHours = !scheduleServiceHours

  const endAtTime = useLocationServiceHours
    ? undefined
    : moment(endAt).format('HH:mm')

  const startAtTime = useLocationServiceHours
    ? undefined
    : moment(startAt).format('HH:mm')

  const serviceHours = mapSchemaToForm(scheduleServiceHours)

  const formData: FormData = {
    ...repeatOptions,
    areas,
    endAt,
    endAtTime,
    enabled,
    locations,
    name,
    reference,
    repeatForever: endAt ? false : true,
    roles,
    serviceHours,
    startAt,
    startAtTime,
    template,
    type,
    users,
    useLocationServiceHours,
  }

  return formData
}

export function mapPayloadToRepeatOptions({
  endAt,
  startAt,
  strategy,
}): FormRepeatOptions {
  const { options, type } = strategy

  // NOTE: provide fallback values in case schedule does not have these options
  const frequencyUnit: Unit = options.frequency && options.frequency.unit
  const frequencyValue: number = options.frequency && options.frequency.value
  const weekdays: number[] = options.weekdays || [moment(startAt).isoWeekday()]

  const {
    repeatDays: initialRepeatDays,
    repeatHours: initialRepeatHours,
    repeatMinutes: initialRepeatMinutes,
    repeatMonths: initialRepeatMonths,
    repeatWeeks: inititalRepeatWeeks,
    repeatYears: initialRepeatYears,
    repeatUnit: initialRepeatUnit,
  } = getInitialValues()

  const repeatDays: number =
    frequencyUnit === Unit.Day ? frequencyValue : initialRepeatDays
  const repeatHours: number =
    frequencyUnit === Unit.Hour ? frequencyValue : initialRepeatHours
  const repeatMinutes: number =
    frequencyUnit === Unit.Minute ? frequencyValue : initialRepeatMinutes
  const repeatMonths: number =
    frequencyUnit === Unit.Month ? frequencyValue : initialRepeatMonths
  const repeatWeeks: number =
    frequencyUnit === Unit.Week ? frequencyValue : inititalRepeatWeeks
  const repeatYears: number =
    frequencyUnit === Unit.Year ? frequencyValue : initialRepeatYears
  const repeatUnit = frequencyUnit || initialRepeatUnit

  const monthRepeatType: string =
    // NOTE: when no exact date of month strategy set this as default
    type !== Strategies.ExactDateOfMonth
      ? 'exactDate'
      : frequencyUnit === Unit.Month && type === Strategies.ExactDateOfMonth
      ? 'exactDate'
      : 'setDay'

  const repeat =
    type === Strategies.Stopwatch
      ? Repeat.SinceCompletion
      : type === Strategies.NoRepeat
      ? Repeat.NoRepeat
      : Repeat.Interval

  return {
    endAt,
    monthRepeatType,
    repeat,
    repeatDays,
    repeatHours,
    repeatMinutes,
    repeatMonths,
    repeatUnit,
    repeatWeeks,
    repeatYears,
    startAt,
    weekdays,
  }
}
