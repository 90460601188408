import React from 'react'
import onClickOutside from 'react-onclickoutside'

import { Block, Flex } from 'components/common'
import colors from 'config/theme/colors'
import BabushkaRecursive from './babushka-recursive'

export interface Category {
  children: Category
  getItems?: Function
  label?: string
  selectable?: boolean
  multiSelect?: boolean
  type?: string
}

export interface Categories {
  children: Category
  label?: string
  type?: string
}

interface Props {
  active: []
  categories: Categories
  onSelect: Function
  position: number
  selectedItems: string[]
  setActive: Function
  setVisibility: Function
}

function Babushka(props: Props): JSX.Element {
  const {
    active,
    categories,
    onSelect,
    setActive,
    selectedItems,
    setVisibility,
  } = props

  // @ts-ignore
  Babushka.handleClickOutside = (): void => {
    setActive([])
    setVisibility(false)
  }

  const menuOffset = -275 * active.length

  return (
    <Block
      backgroundColor={colors.white}
      boxShadow="0 4px 14px rgba(0,0,0,0.2)"
      className="root"
      flexDirection="row"
      overflowY="hidden"
      position="absolute"
      zIndex={700}
    >
      <Flex
        left={menuOffset}
        position="relative"
        // transition="all .3s ease-in-out"
      >
        <BabushkaRecursive
          active={active}
          categories={categories}
          onSelect={onSelect}
          position={0}
          selectedItems={selectedItems}
          setActive={setActive}
          setVisibility={setVisibility}
        />
      </Flex>
    </Block>
  )
}

export default onClickOutside(Babushka, {
  //@ts-ignore
  handleClickOutside: (): void => Babushka.handleClickOutside,
})
