import {
  isEmail,
  isRequired,
} from '../../validation'

export default {
  firstName: {
    label: 'First Name',
    name: 'firstName',
  },
  lastName: {
    label: 'Last Name',
    name: 'lastName',
  },
  username: {
    label: 'Username',
    name: 'username',
    required: true,
    validate: [isRequired()],
  },
  password: {
    name: 'password',
    placeholder: 'password',
    required: true,
    type: 'password',
    validate: [isRequired()],
  },
  email: {
    label: 'Email',
    name: 'email',
    required: true,
    validate: [
      isRequired(),
      isEmail(),
    ],
  },
  mobile: {
    label: 'Mobile',
    name: 'mobile',
  },
  role: {
    label: 'Role',
    name: 'role',
    placeholder: 'Select Role',
    required: true,
    validate: [isRequired()],
  },
  locations: {
    label: 'Locations',
    multi: true,
    name: 'locations',
    placeholder: 'Select Location',
  },
  authStrategy: {
    label: 'Authentication',
    name: 'auth.strategy',
    required: true,
    validate: [isRequired()],
  },
  autoEndShift: {
    label: 'End Shift',
    name: 'preferences.autoEndShift',
    required: true,
    placeholder: 'Select Shift type',
    validate: [isRequired()],
  },
  autoStartShift: {
    label: 'Start Shift',
    name: 'preferences.autoStartShift',
    required: true,
    placeholder: 'Select Shift type',
    validate: [isRequired()],
  },
  winTeamEnabled: {
    label: 'WinTeam Enabled',
    name: 'plugins.winteam.enabled',
  },
  winTeamEmployeeNumber: {
    label: 'Employee Number',
    name: 'plugins.winteam.options.employeeNumber',
  },
}
