import { getModule } from '@lighthouse/sdk'
import { connect } from 'react-redux'
import { chain, every, get, includes, isFunction } from 'lodash'

const userApplicationsModule = getModule('userApplications')
const NON_FLAG_PROPS = [
  'children',
  'computedMatch',
  'dispatch',
  'flags',
  'isLighthouseAdmin',
  'location',
]

export default connect(mapStateToProps)(Flags)

function Flags(props) {
  const { children, flags, isLighthouseAdmin } = props

  const requiredFlags = chain(props)
    .omitBy(rejectNonFlagProps)
    .value()

  const hasFlags = every(requiredFlags, (requiredValue, flagKey) => {
    const applicationValue = flags[flagKey]
    const hasFlag = flagMatch(requiredValue, applicationValue)
    return hasFlag
  })

  const result = hasFlags || isLighthouseAdmin

  // NOTE support with and without render callback for convenience
  return isFunction(children) ? children(result) : result && children
}

function mapStateToProps(state) {
  const currentApplication = userApplicationsModule.getCurrentApplication(state)
  const email = get(state, 'user.data.email')
  const flags = get(currentApplication, 'application.flags', {})
  const isLighthouseAdmin = email === 'support@lighthouse.io'

  return {
    flags,
    isLighthouseAdmin,
  }
}

/**
 * NOTE For a flag to be enabled it must be `true` or a non-empty string
 */
function flagMatch(requiredValue, applicationValue) {
  // prop without value equates to `true`
  if (requiredValue === undefined) {
    return !!applicationValue
  }

  // For all all other scenarios ensure the prop value matches what the user has
  // stored on their flag. This means we can match against `false` flags or
  // flags that are a string, e.g. { flagName: 'specific' }
  return requiredValue === applicationValue
}

function rejectNonFlagProps(value, key) {
  return includes(NON_FLAG_PROPS, key)
}
