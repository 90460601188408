import { debounce, isEmpty } from 'lodash'
import { Editor } from 'react-draft-wysiwyg'
import { compose, onlyUpdateForKeys, withProps } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Block, Flex } from 'components/common'
import { transformToDraft } from './helpers'
import './styles.css'

const DEFAULT_DEBOUNCE_TIMEOUT = 750

// NOTE: Regex use cases https://regexr.com/3sh8s
const REGEX_STYLE_ATTRS = /(<[^>]+) style=".*?"/gi

const freezeProps = onlyUpdateForKeys([])

export default compose(
  // NOTE: we don't need to re-render here as the content is managed within the
  // editor state, and passed to redux-form via onChange.  We only need
  // transformToDraft on the initial mount.
  freezeProps,
  withProps(({ onChange, value }) => ({
    onChange: debounce(onChange, DEFAULT_DEBOUNCE_TIMEOUT),
    transformedValue: transformToDraft(value),
  }))
)(Wysiwyg)

const toolbarConfig = {
  blockType: {
    inDropdown: true,
    className: 'blockType',
    dropdownClassName: 'blockOptions',
    options: ['Normal', 'H1', 'H2'],
  },
  inline: {
    inDropdown: false,
    className: 'blockType',
    dropdownClassName: 'blockOptions',
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    inDropdown: false,
    className: 'blockType',
    dropdownClassName: 'blockOptions',
    options: ['unordered', 'ordered'],
  },
  link: {
    inDropdown: false,
    className: 'blockType',
    defaultTargetOption: '_blank',
    dropdownClassName: 'blockOptions',
    options: ['link'],
    showOpenOptionOnHover: true,
    popupClassName: 'popup-modal',
  },
  options: ['blockType', 'inline', 'list', 'link'],
}

function Wysiwyg(props) {
  const { onChange, readOnly, transformedValue = '', value = '' } = props

  if (readOnly) {
    const parsed = value.replace(REGEX_STYLE_ATTRS, '$1')
    const content = ReactHtmlParser(parsed)

    return (
      <Block padding={10} wordBreak="break-word">
        {content}
      </Block>
    )
  }

  return (
    <Flex>
      <Editor
        editorClassName="wysiwyg-editor"
        defaultContentState={transformedValue}
        onContentStateChange={onChange}
        stripPastedStyles
        toolbar={toolbarConfig}
        toolbarClassName="wysiwyg-toolbar"
      />
    </Flex>
  )
}

Wysiwyg.propTypes = {
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
}
