import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'

import Entry from './routes/entry'
import Messenger from './routes/messenger'

export default function MessagesRoute() {
  return (
    <div style={{ height: '80%' }}>
      <Switch>
        <Route
          component={Entry}
          exact
          path={['/messages/groups/:id', '/messages/groups/new']}
        />
        <Route component={Messenger} exact path="/messages" />
        <Redirect to="/404" />
      </Switch>
    </div>
  )
}
