import Amplify from 'aws-amplify'

const isProduction = process.env.NODE_ENV === 'production'
const developConfiguration = {
  Auth: {
    identityPoolId: 'us-east-1:39d7b5fd-9719-4f72-ac10-654eb61635e2',
    region: 'us-east-1',
  },
  Storage: {
    AWSS3: {
      bucket: 'uploads-develop-us-lighthouse-io',
      customPrefix: {
        protected: '',
        private: '',
        public: '',
      },
    },
  },
}

const productionConfigurationAu = {
  Auth: {
    identityPoolId: 'ap-southeast-2:3b5bc80b-b1b0-40e3-9d02-8bd98e2352e8',
    region: 'ap-southeast-2',
  },
  // TODO in future storage should be configured for region based uploads
  Storage: {
    AWSS3: {
      bucket: 'uploads-lighthouse-io',
      region: 'ap-southeast-2',
      customPrefix: {
        protected: '',
        private: '',
        public: '',
      },
    },
  },
}

const productionConfigurationUs = {
  Auth: {
    identityPoolId: 'us-east-1:06c992c7-0b25-4847-a681-a4508ef5c318',
    region: 'us-east-1',
  },
  // TODO in future storage should be configured for region based uploads
  Storage: {
    AWSS3: {
      bucket: 'uploads-lighthouse-io',
      region: 'us-east-1',
      customPrefix: {
        protected: '',
        private: '',
        public: '',
      },
    },
  },
}

const configurations = {
  develop: developConfiguration,
  au: productionConfigurationAu,
  us: productionConfigurationUs,
}

export function configure({ regionCode = 'us' }) {
  const configuration = isProduction
    ? configurations[regionCode]
    : configurations.develop

  Amplify.configure(configuration)
}
