import React, { Component } from 'react'
import moment, { isMoment } from 'moment'
import 'moment-timezone'

const styles = {
  timezoneLabel: {
    color: '#777',
    fontSize: 11,
  },
}

export default class ClockTicker extends Component {
  state = {
    interval: null,
  }

  componentWillMount() {
    this.setDate(this.props.datetime)
    this.setState({
      interval: setInterval(this.tick.bind(this), 1000),
    })
  }

  componentWillReceiveProps(newProps) {
    this.setDate(newProps.datetime)
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  setDate(datetime) {
    let newDatetime = isMoment(datetime) ? datetime : new Date(datetime)

    if (isMoment(datetime)) {
      newDatetime = datetime
    } else if (datetime) {
      newDatetime = moment(new Date(datetime))
    } else {
      newDatetime = moment()
    }

    this.setState({
      datetime: newDatetime,
    })
  }

  tick() {
    // only tick if option is set
    if (!this.props.tick) return

    this.setDate(this.state.datetime.add(1, 'second'))
  }

  render() {
    const { datetime } = this.state
    const { timezone, showDate, style = {} } = this.props

    let format = 'h:mm:ss a'

    if (showDate) format = `DD MMM YYYY - ${format}`

    if (timezone) datetime.tz(timezone)

    return (
      <span style={style}>
        {datetime.tz(timezone).format(format)}
        <span style={styles.timezoneLabel}>
          {timezone && ` (${datetime.zoneName()})`}
        </span>
      </span>
    )
  }
}
