import colors from 'config/theme/colors'

export default {
  tag: {
    display: 'flex',
    backgroundColor: '#fff',
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 6,
    marginRight: 6,
    color: colors.gray.light,
    height: 36,
    fontSize: '12px',
  },
  clearIcon: {
    borderRight: `1px solid ${colors.gray.lightest}`,
    cursor: 'pointer',
    height: 36,
    lineHeight: '36px',
    padding: '0 7px',
    textAlign: 'center',
    ':hover': {
      color: colors.gray.normal,
    },
  },
  label: {
    padding: '0 15px',
    height: 36,
    lineHeight: '36px',
  },
  type: {
    fontWeight: 600,
  },
  value: {
    fontWeight: 400,
    paddingLeft: 4,
  },
}
