import { createSelector } from 'reselect'

import {
  filter,
  find,
  get,
  orderBy,
  memoize,
  reduce,
} from 'lodash'

import { PENDING_LIST_ID } from '../reducers/list'
import { ROLLED_BACK, SAVE_FAILED } from '../../constants/states'

const DEFAULT_LIST_ID = 'default'

export default (statePath) => {
  const cachedSelector = state => get(state, statePath).cache
  const listSelector = state => get(state, statePath).list
  const currentSelector = state => get(state, statePath).current
  const listItemsSelector = createSelector(
    cachedSelector,
    listSelector,
    // NOTE opts for sorting are unsupported until we figure out a good way to memoize them
    (cache, list) => memoize((listId = DEFAULT_LIST_ID, opts = {}) => {
      const listIds = get(list, `${listId}.items`)
      const { sort } = opts

      if (!listIds) {
        return []
      }

      const filteredResources = reduce(listIds, (accum, id) => {
        const resource = cache[id]
        accum.push(resource)
        return accum
      }, [])

      let sortedResources

      // if a custom sort is provided, run it
      if (sort) {
        // convert sort options looking like:
        // [['name', 'age'], ['asc', 'desc']]
        // into:
        // [['entity.name', 'entity.age'], ['asc', 'desc']]
        const sortOptions = sort.map((sortItem, index) => {
          if (index === 0) {
            return sortItem.map(id => `entity.${id}`)
          }
          return sortItem
        })
        sortedResources = orderBy(filteredResources, ...sortOptions)
      } else {
        // http://stackoverflow.com/questions/8663163/sorting-objects-according-to-a-specific-rule
        // We need to ensure the filtered resources are re-sorted
        // according to the listIds. The sort of the cache will more
        // than likely be different to that of the list, so the filter
        // above will not respect the sort order of `listIds`
        sortedResources = filteredResources.sort((a, b) => listIds.indexOf(a.entity._id) - listIds.indexOf(b.entity._id))
      }
      return sortedResources
    }))

  return {
    cache: cachedSelector,
    list: listItemsSelector,
    state: createSelector(
      listSelector,
      list => (listId = DEFAULT_LIST_ID) => get(list, `${listId}.state`),
    ),
    current: createSelector(
      cachedSelector,
      currentSelector,
      (cache = {}, current = {}) => cache[current.id],
    ),
    failedOptimistic: createSelector(
      listItemsSelector,
      (listItemsFn) => {
        const pendingListItems = listItemsFn(PENDING_LIST_ID)
        return filter(pendingListItems, (item = {}) => {
          return item.optimistic && (
            item.state === ROLLED_BACK ||
            item.state === SAVE_FAILED
          )
        })
      },
    ),
    rolledBack: createSelector(
      listItemsSelector,
      (listItemsFn) => {
        const pendingListItems = listItemsFn(PENDING_LIST_ID)
        return filter(pendingListItems, {
          state: ROLLED_BACK,
        })
      },
    ),
  }
}
