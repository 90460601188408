import colors from 'config/theme/colors'
import typography from 'config/theme/typography'

const timelinePadding = 30

export default {
  timeline: {
    cursor: 'crosshair',
    width: '100%',
    paddingRight: timelinePadding,
    paddingLeft: timelinePadding,
    boxSizing: 'border-box',
  },
  timelineBtn: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    color: '#fff',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: timelinePadding,
    height: 60,
    marginRight: 0,
    padding: 0,
    fontSize: 1,
  },
  prevBtn: {
    left: 0,
  },
  nextBtn: {
    right: 0,
  },
  clock: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 4,
    color: colors.gray.dark,
    cursor: 'pointer',
    height: 40,
    bottom: 80,
    left: '50%',
    width: 400,
    marginLeft: -200,
    fontFamily: typography.defaultFontFamily,
    fontSize: 13,
    textAlign: 'center',
    zIndex: 500,
  },
  dateText: {
    position: 'absolute',
    left: 50,
    width: 300,
    alignItems: 'stretch',
    lineHeight: '40px',
  },
  clockBtnPosition: {
    marginLeft: 'auto',
    marginTop: 6,
    lineHeight: '40px',
  },
  clockBtn: {
    display: 'block',
    height: 28,
    lineHeight: '28px',
    marginRight: 6,
    padding: '0 10px',
    fontSize: '11px',
    textTransform: 'uppercase',
  },
  clockBtnLive: {
    backgroundColor: '#B3ECFF',
    color: '#0086B3',
  },
  calendarIcon: {
    display: 'inline-block',
    fontSize: 16,
    width: 18,
    lineHeight: '40px',
    margin: '0 15px',
  },
}
