import Fuse from 'fuse.js'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import Radium from 'radium'
import onClickOutside from 'react-onclickoutside'
import Link from 'components/link'
import Icon from 'components/icon'
import styles from './styles.js'
import './styles.css'
import { colors } from 'config/theme'
import Search from 'components/SearchBox'

const enhance = compose(onClickOutside, Radium)
const supportEmail = 'support@lighthouse.io'

export default ComposedComponent => {
  class Dropdown extends Component {
    static propTypes = {
      align: PropTypes.oneOf(['left', 'right', 'full']),
      options: PropTypes.array,
      navItemType: PropTypes.string,
      user: PropTypes.object,
    }

    state = {
      active: false,
      options: [],
    }

    componentDidUpdate(prevProps) {
      const { options, navItemType } = this.props
      const { options: prevOptions } = prevProps
      if (navItemType === 'timezone') {
        if (options !== prevOptions) {
          this.setState({ options })
        }
      }
    }

    componentWillMount() {
      const { options } = this.props
      this.setState({
        options,
      })
    }

    hide() {
      this.setState({
        active: false,
      })
    }

    handleClickOutside() {
      this.hide()
    }

    // click handler for the dropdown button
    clickHandler() {
      const { onClick } = this.props
      const isActive = this.state.active
      this.setState({
        active: !isActive,
      })

      // call the onClick handler for the dropdown element itself.
      // This can be useful for fetching data that is required in the
      // dropdown menu items
      if (onClick) onClick()
    }

    // click handler for dropdown items
    itemClickHandler(fn) {
      return e => {
        e.preventDefault()
        this.hide()
        if (fn) fn() // execute fn if one was passed
      }
    }

    onSearchChange(e) {
      const { options } = this.props

      if (e.target.value === '') {
        return this.setState({
          options,
        })
      }

      const fuse = new Fuse(options, {
        keys: ['label'],
        threshold: 0.3,
      })

      const results = fuse.search(e.target.value)
      const trimmed = results.map(result => {
        return { ...result.item }
      })

      this.setState({
        options: trimmed,
      })
    }

    render() {
      const { align, navItemType, t, user } = this.props
      // TODO remove this check if releasing to customers
      // fallback to empty string in case user doesn't have an email
      const isSupportUser = user && user.email && user.email === supportEmail
      const { active, options } = this.state
      const isAppSwitcher = navItemType === 'appSwitcher'
      const menuItems = options.map((item, index) => {
        const {
          dataTestId,
          label,
          selected,
          onClick,
          link,
          icon,
          hasDivider,
        } = item

        let dropdownButton
        const navIcon = icon && <Icon name={icon} theme={styles.icon} />

        if (onClick) {
          // NOTE: we use CSS here, limitation of inline
          // styles, pseudo classes and showing/hiding content
          dropdownButton = (
            <a
              data-testid={dataTestId}
              key={`dropdown-${navItemType}-option-${index}`}
              onClick={this.itemClickHandler.bind(this)(onClick)}
              style={[
                styles.menuItem,
                icon && styles.menuItemWithIcon,
                hasDivider && { borderBottomColor: colors.gray.light },
              ]}
              href="#"
              className="dropdownButton"
            >
              {navIcon}
              {label}
              {selected ? (
                <Icon name="check" theme={styles.selectedIcon} />
              ) : null}
            </a>
          )
        } else if (link) {
          dropdownButton = (
            <Link
              data-testid={dataTestId}
              className="dropdownButton"
              key={index}
              to={link}
              onClick={this.hide.bind(this)}
              style={styles.menuItem}
            >
              {label}
            </Link>
          )
        } else {
          console.warn('Invalid options supplied to Dropdown Component')
        }

        return dropdownButton
      })

      const container = {
        border: `1px solid ${colors.gray.lighter}`,
        borderTop: '0 none',
      }

      const menu = active ? (
        <div
          style={{
            ...styles.menu,
            ...styles[align],
            minWidth: isAppSwitcher && isSupportUser ? 350 : 180,
            backgroundColor: 'white',
          }}
        >
          {isAppSwitcher && isSupportUser ? (
            <Search
              onChange={this.onSearchChange.bind(this)}
              containerStyles={container}
            />
          ) : null}
          {menuItems}
        </div>
      ) : null

      return (
        <div style={[styles.root]}>
          <ComposedComponent
            {...this.props}
            onClick={this.clickHandler.bind(this)}
          />
          {menu}
        </div>
      )
    }
  }

  return enhance(Dropdown)
}
