import colors from 'config/theme/colors'

export default {
  root: {
    display: 'flex',
    flexShrink: 0,
    padding: '6px 15px',
    height: 36,
    lineHeight: '36px',
  },
  pagination: {
    color: colors.gray.light,
    fontSize: '12px',
  },
}
