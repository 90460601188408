import { Redirect, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import React from 'react'

import PageLoader from 'components/page-loader'

const Entry = Loadable({
  loader: () => import('./Entry'),
  loading: PageLoader,
})

const List = Loadable({
  loader: () => import('./List'),
  loading: PageLoader,
})

export default function LocationGroupsRoute() {
  return (
    <Switch>
      <Route
        component={Entry}
        exact
        path={[
          '/setup/location-groups/entry/:id',
          '/setup/location-groups/entry',
        ]}
      />
      <Route component={List} exact path="/setup/location-groups" />
      <Redirect to="/404" />
    </Switch>
  )
}
