import React from 'react'

import { Block } from 'components/common'
import Alert from 'components/alert'
import ConfirmationBox from 'components/confirmation-box'

const ExportConfirmation = ({
  cancelHandler,
  confirmHandler,
  customMessageText,
  state,
  t,
  totalCount,
}) => {
  const defaultMessages = {
    warningPdf: t('alert.message.exportConfirmationWarningPdf'),
    warning: t('alert.message.exportConfirmationWarning'),
    confirm: t('alert.message.exportConfirmationConfirm', {
      totalCount: totalCount > 0 ? totalCount : '',
    }),
    success: t('alert.message.exportConfirmationSuccess'),
    error: t('alert.message.exportConfirmationError'),
  }

  const messages = customMessageText ? customMessageText : defaultMessages

  const alerts = {
    warningPdf: <Alert type={'warning'} messages={[messages.warningPdf]} />,
    warning: <Alert type={'warning'} messages={[messages.warning]} />,
    confirm: (
      <Block marginBottom={10}>
        <ConfirmationBox
          message={messages.confirm}
          onConfirm={confirmHandler}
          onCancel={cancelHandler}
        />
      </Block>
    ),
    success: (
      <Block>
        <Alert
          i18nKey={messages.success}
          link={customMessageText ? null : '/reports/exports'}
          type={'success'}
          messages={[messages.success]}
        />
      </Block>
    ),
    error: (
      <Block>
        <Alert type={'error'} messages={[messages.error]} />
      </Block>
    ),
  }

  return alerts[state] || null
}

export default ExportConfirmation
