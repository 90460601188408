import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { memoize } from 'lodash'
import { getModule } from '@lighthouse/sdk'

const userApplicationsModule = getModule('userApplications')

export default compose(
  connect(mapStateToProps),
  // Only re-process when the permissions object changes. This works in tandem
  // with the memoized `hasLocationPermission` below so the cache is reset if
  // the permissions object has changed
  withPropsOnChange(['permissions'], props => ({
    // memoized module permission check. Only accepts module (.e.g. 'location')
    // and action (e.g. 'read') as arguments
    hasModulePermission: memoize((module, action) => (
      props.askPermission({ module, action })
    ), hasModulePermissionCacheResolver),
    // Memoized version of permission query for locations, as it's a
    // very common request. We can memoize it based on the locationId to reduce
    // need for traversing permissions each time
    hasLocationPermission: memoize(locationId => (
      props.askPermission({
        module: 'location',
        action: 'read',
        documentIds: {
          location: locationId,
        },
      })
    )),
  })),
)

function mapStateToProps(state) {
  // NOTE to take advantage of memoization when using this selector, make sure
  // to assign your opts object to a variable so it's treated as the same value
  // whenever passed into this method
  return {
    permissions: userApplicationsModule.getPermissions(state),
    askPermission: userApplicationsModule.askPermission(state),
  }
}

function hasModulePermissionCacheResolver(module, action) {
  return `${module}-${action}`
}
