import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

// import { useFlags } from 'components/flags/hook'

import { ProtectedRoute } from '../../components/protectedRoute'

import Schedules from './components/main'
import WorkRoute from '../work'
// NOTE flagging pattern
// import WorkDeprecatedRoute from '../work-deprecated'

export default function SchedulesRoute(): JSX.Element {
  // NOTE use the following pattern to flag schedules
  // const flags = useFlags()

  // const WorkRouteComponent =
  //   flags && flags.schedulesNext ? WorkRoute : WorkDeprecatedRoute

  const WorkRouteComponent = WorkRoute
  return (
    <Schedules>
      <Switch>
        <ProtectedRoute
          permissions={{ action: 'read', module: 'schedule' }}
          path="/schedules/work"
        >
          <WorkRouteComponent />
        </ProtectedRoute>
        {/* NOTE default route */}
        <Redirect from="/schedules" to="schedules/work" />
      </Switch>
    </Schedules>
  )
}
