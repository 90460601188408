import { connect } from 'react-redux'
import { get, find, isObject } from 'lodash'
import { getLocationReference, getTimezoneDatetime } from '@lighthouse/common'
import { getModule } from '@lighthouse/sdk'
import moment from 'moment'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import React from 'react'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import withState from 'recompose/withState'

import colors from 'config/theme/colors'
import * as logger from 'utils/logger'

import Form from '../form'
import Panel from '../../../panel'
import { withUsers } from 'components/useUsers'
import { withTranslation } from 'react-i18next'

const LIST_ID = 'all'

const applicationUserModule = getModule('applicationUsers')
const areaModule = getModule('areas')
const taskEntryModule = getModule('taskEntries')
const templateModule = getModule('templates')

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withUsers,
  withState('error', 'setError'),
  withState('isDeleting', 'setDeleting', false),
  withState('isEditing', 'setEditing', false),
  withState('isLoaded', 'setLoaded', false),
  withTranslation(),
  withProps(buildEntryProps),
  withProps(buildTitle),
  withHandlers({ handleCancel, handleEdit }),
  lifecycle({ componentDidMount })
)(TaskEntry)

function TaskEntry(props) {
  const {
    error,
    form,
    handleCancel,
    handleEdit,
    id,
    initialValues,
    isDeleting,
    isEditing,
    isLoaded,
    isTaskEntryResolving,
    isTemplateResolving,
    onClose: handleClose,
    setError,
    setDeleting,
    setEditing,
    templateEntity,
    toggleExpand,
    toggleIcon,
    title,
  } = props

  const isResolving = isTaskEntryResolving || isTemplateResolving

  return (
    <Panel
      color={colors.lime}
      error={error}
      isResolving={isResolving}
      onClose={handleClose}
      resource="Task"
      title={title}
      toggleExpand={toggleExpand}
      toggleIcon={toggleIcon}
    >
      {isLoaded && !isDeleting && (
        <Form
          form={form}
          handleCancel={handleCancel}
          handleEdit={handleEdit}
          id={id}
          initialValues={initialValues}
          isEditing={isEditing}
          onClose={handleClose}
          readOnly={!isEditing}
          setError={setError}
          setDeleting={setDeleting}
          setEditing={setEditing}
          templateEntity={templateEntity}
        />
      )}
    </Panel>
  )
}

async function componentDidMount() {
  const {
    fetchTaskEntry,
    fetchTemplate,
    fetchUser,
    hasLoadedTaskEntry,
    hasLoadedTemplate,
    id,
    setError,
    setLoaded,
    taskEntryEntity,
  } = this.props

  try {
    const taskEntry = hasLoadedTaskEntry
      ? { data: taskEntryEntity }
      : await fetchTaskEntry(id)

    const templateId = get(taskEntry, 'data.task._id')

    if (!templateId) {
      logger.warn('Missing form template on task for task entry')
    } else if (!hasLoadedTemplate) {
      await fetchTemplate(templateId)
    }

    const userId = get(taskEntry, 'data.user')
    await fetchUser({ userId })
  } catch (err) {
    logger.error('Error loading task entry', err)
    setError(err)
  }

  setLoaded(true)
}

function buildEntryProps(props) {
  const {
    getUserFullName,
    id,
    locations,
    taskEntryCache,
    templateCache,
    timezone,
    t,
  } = props

  const taskEntryResource = taskEntryCache[id] || {}
  const taskEntryEntity = taskEntryResource.entity || {}
  const hasTaskEntryEntityEntry = !!taskEntryEntity.entry

  const isTaskEntryResolved = taskEntryResource.state === 'resolved'
  const isTaskEntryResolving = taskEntryResource.state === 'resolving'
  const isTaskEntryError = !!taskEntryResource.error

  const templateId = isObject(taskEntryEntity.task)
    ? taskEntryEntity.task._id
    : taskEntryEntity.task

  const templateResource = templateCache[templateId] || {}
  const templateEntity = templateResource.entity || {}
  const hasTemplateEntityTemplate = !!templateEntity.template

  const isTemplateResolved = templateResource.state === 'resolved'
  const isTemplateResolving = templateResource.state === 'resolving'
  const isTemplateError = !!templateResource.error

  const form = `task-entry-${id}`
  let initialValues

  const hasLoadedTaskEntry =
    isTaskEntryResolved && !isTaskEntryError && hasTaskEntryEntityEntry
  const hasLoadedTemplate =
    isTemplateResolved && !isTemplateError && hasTemplateEntityTemplate

  //get location and created entry values
  const location = getLocationReference({
    entity: taskEntryEntity,
  })

  const userFullName = getUserFullName(taskEntryEntity.user)
  const dateTime = getTimezoneDatetime({
    timestamp: taskEntryEntity.createdAt,
    timezone,
  })

  const timezoneTime = moment(dateTime, 'ddd, MMM D h:mm:ssa').format(
    'MMM DD h:mma'
  )

  const created = `${timezoneTime} by ${userFullName}`

  // NOTE: only set initial values if we have values
  // as we want to prevent redux form fields registering
  // multiple times
  if (hasLoadedTaskEntry) {
    const { area, entry, gps, title } = taskEntryEntity.asMutable({
      deep: true,
    })

    initialValues = {
      created,
      formGroups: entry.formGroups,
      gps,
      location,
      title,
    }

    const locationId = get(area, 'location.id')
    const geocodedLabel = get(gps, 'reverseGeocoded.label')

    const formLocation = find(locations, ['id', locationId])

    const label =
      (formLocation && formLocation.entity.name) ||
      geocodedLabel ||
      t('labelUnknownLocation')
    const value = (formLocation && formLocation.id) || 'custom-value'

    initialValues.locationSelect = { label, value }
  }

  return {
    form,
    hasLoadedTaskEntry,
    hasLoadedTemplate,
    initialValues,
    isTaskEntryResolving,
    isTemplateResolving,
    taskEntryEntity,
    templateEntity,
  }
}

function buildTitle(props) {
  const {
    isDeleting,
    isEditing,
    isTaskEntryResolving,
    isTemplateResolving,
    taskEntryEntity,
  } = props

  let title = 'Unknown Task'

  if (isTaskEntryResolving && !isEditing) title = 'Loading Task'
  if (isTemplateResolving && !isEditing) title = 'Loading Template'
  if (isTaskEntryResolving && isDeleting && isEditing) title = 'Deleting Task'
  if (isTaskEntryResolving && !isDeleting && isEditing) title = 'Saving Task'
  if (!isTaskEntryResolving && taskEntryEntity.title)
    title = taskEntryEntity.title

  return {
    title,
  }
}

function handleCancel(props) {
  return () => props.setEditing(false)
}

function handleEdit(props) {
  return () => props.setEditing(true)
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTaskEntry: id => dispatch(taskEntryModule.findById(id)),
    fetchTemplate: id => dispatch(templateModule.findById(id)),
  }
}

function mapStateToProps(state) {
  const applicationUserSelectors = applicationUserModule.selectors(state)(
    'default'
  )
  const areaSelectors = areaModule.selectors(state)()
  const taskEntrySelectors = taskEntryModule.selectors(state)(LIST_ID)
  const templateSelectors = templateModule.selectors(state)(LIST_ID)

  const locations = areaSelectors.byType('location')
  const taskEntryCache = taskEntrySelectors.cache()
  const templateCache = templateSelectors.cache()

  return {
    getUserFullName: applicationUserSelectors.getUserFullName,
    locations,
    taskEntryCache,
    templateCache,
    timezone: state.app.timezone,
  }
}
