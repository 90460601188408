import { getModule } from '@lighthouse/sdk'
import colors from 'config/theme/colors'
import inputStyles from 'components/form/text/styles'
import { size } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import InputLabel from 'components/form/input-label'
import Select from 'components/form/select'
import { Crud } from 'components/controls'
import { Block } from 'components/common'
import { Form, Formik } from 'formik'
import InputRow from '../input-row'

import TitleDivider from '../../../title-divider'
import emitter from '../../../../../../../../utils/emitter'
import { Option } from '../../../../../../../../types/option'
import * as constants from '../../../../../../lib/constants'

interface AddFloorProps {
  areaId: string
  reset: boolean
  fetchAreasByArea: any
  floors: [
    {
      label: string
      labelShort: string
      level: number
    }
  ]
  floorOptions: Option[]
  isAddingFloorMode: boolean
  isLighthouseAdmin: boolean
  parentAreaLocationId: string
  selectedFloor: {
    label: string
    labelShort: string
    level: number
  }
  setBuilding: any
  values: any
  updateAreaView: ({ isAddingFloorMode }) => {}
}

interface Values {
  buildingId: string
  parentAreaLocationId: string
  sourceLevel: number
  targetLabel: string
  targetLabelShort: string
  targetLevel: number
}

const permittedTypes = [
  constants.TYPE_ROOM,
  constants.TYPE_CORRIDOR,
  constants.TYPE_WALL,
  constants.TYPE_OBJECT,
]

const areaModule = getModule('areas')

const AddFloorSchema = ({ sourceLevel, sourceLabel, sourceLabelShort }) => {
  return Yup.object().shape({
    sourceLevel: Yup.number().required(),
    targetLabel: Yup.string()
      .min(1, 'Target Label must be at least 1 character')
      .notOneOf([sourceLabel], 'Label must be new.')
      .required('Label is a required field.'),
    targetLabelShort: Yup.string()
      .min(1, 'Target Short Label must be at least 1 character')
      .max(4, 'Target Label must be at most 4 characters')
      .notOneOf([sourceLabelShort], 'Short Label must be new.')
      .required('Short Label is a required field.'),
    targetLevel: Yup.number()
      .notOneOf([sourceLevel], 'The floor level must change.')
      .required('Level is a required field.'),
  })
}

export default function AddFloorForm(props: AddFloorProps): {} {
  const {
    areaId: buildingId,
    fetchAreasByArea,
    floors,
    isAddingFloorMode,
    isLighthouseAdmin,
    parentAreaLocationId,
    selectedFloor = {
      label: 'Select a floor',
      labelShort: 'N/A',
      level: 0,
    },
    setBuilding,
    updateAreaView,
  } = props

  const {
    level: selectedFloorLevel,
    label: targetLabel,
    labelShort: targetLabelShort,
  } = selectedFloor

  const dispatch = useDispatch()

  const inputRowRadiumStyles = isAddingFloorMode
    ? null
    : {
        ':hover': {
          backgroundColor: colors.blue.lightest,
          cursor: 'pointer',
        },
      }

  return (
    <Formik
      initialValues={{
        buildingId,
        parentAreaLocationId,
        sourceLevel: selectedFloorLevel,
        targetLabel: '',
        targetLabelShort: '',
        targetLevel: undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={AddFloorSchema({
        sourceLevel: selectedFloorLevel,
        sourceLabel: targetLabel,
        sourceLabelShort: targetLabelShort,
      })}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }) => (
        <Form
          id="floorform"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 15px',
          }}
        >
          <TitleDivider title="Add New Floor Form: " />
          <InputRow name="targetLabel" radiumStyles={inputRowRadiumStyles}>
            <InputLabel label="Label" small={true} required={true} />
            <input
              type="text"
              name="targetLabel"
              onChange={handleChange}
              onBlur={handleBlur}
              style={inputStyles.root}
              value={values.targetLabel}
            />
          </InputRow>
          <InputRow name="targetLabelShort" radiumStyles={inputRowRadiumStyles}>
            <InputLabel label="Short Label" small={true} required={true} />
            <input
              type="text"
              name="targetLabelShort"
              onChange={handleChange}
              onBlur={handleBlur}
              style={inputStyles.root}
              value={values.targetLabelShort}
            />
          </InputRow>
          <InputRow name="sourceLevel" radiumStyles={inputRowRadiumStyles}>
            <InputLabel label="Copy Floor" small={true} required={true} />
            <Block flexGrow={1}>
              <Select
                clearable={false}
                onBlur={handleBlur}
                onChange={sourceLevel => {
                  setFieldValue('sourceLevel', sourceLevel)
                }}
                options={floors.map(floor => ({
                  label: floor.label,
                  value: floor.level,
                }))}
                placeholder="Select a floor"
                required={true}
                value={values.sourceLevel}
              />
            </Block>
          </InputRow>

          <InputRow name="targetLevel" radiumStyles={inputRowRadiumStyles}>
            <InputLabel label="Level" small={true} required={true} />
            <input
              type="number"
              name="targetLevel"
              onChange={handleChange}
              onBlur={handleBlur}
              style={inputStyles.root}
              value={values.targetLevel}
            />
          </InputRow>
          <Crud
            canDelete={false}
            canEdit={isLighthouseAdmin}
            dirty={dirty}
            errorCount={size(errors)}
            handleCancel={() =>
              updateAreaView({
                isAddingFloorMode: false,
              })
            }
            handleDelete={() => console.log('Deleting is not supported.')}
            handleEdit={() =>
              updateAreaView({
                isAddingFloorMode: true,
              })
            }
            handleSave={handleSubmit}
            id={buildingId}
            invalid={size(errors) > 0}
            isEditing={isAddingFloorMode}
            name={name}
            reset={() =>
              updateAreaView({
                isAddingFloorMode: false,
              })
            }
            small
            submitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  )

  async function onSubmit(values: Values, { resetForm }) {
    let result
    try {
      const {
        buildingId,
        parentAreaLocationId,
        sourceLevel,
        targetLabel,
        targetLabelShort,
        targetLevel,
      } = values
      result = await dispatch(
        areaModule.request('action/duplicate-floor', {
          method: 'PUT',
          payload: {
            buildingId,
            sourceLevel,
            targetLabel,
            targetLabelShort,
            targetLevel,
            areaWhitelist: permittedTypes,
          },
        })
      )

      if (result && result.data && result.data.message) {
        emitter.emit('notification:add', {
          message: result.data.message,
          theme: 'alert',
          tags: ['error'],
          title: 'New Floor Error',
        })
        return
      }

      await fetchAreasByArea(buildingId)

      setBuilding({
        id: buildingId,
        floor: values.targetLevel,
      })

      updateAreaView({
        isAddingFloorMode: false,
      })

      resetForm({ values })
    } catch (err) {
      if (!(result && result.data && result.data.message)) {
        emitter.emit('notification:add', {
          message: err.message || 'Unknown Error',
          theme: 'alert',
          tags: ['error'],
          title: 'New Floor Error',
        })
      }
    }
  }
}
