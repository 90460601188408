import React from 'react'
import { withRouter } from 'react-router-dom'
import { AutoSizer, Column, Table } from 'react-virtualized'

import { Application } from '../applications.types'
import { Wrapper } from 'components/common'
import Alert from 'components/alert'
import Block from '../../../../../components/common/block'
import Button from 'components/button'
import DateTimeTimezone from 'components/datetime-timezone'
import FilterNext from 'components/filter-next'
import TableSpinner from '../../../../../components/table-next/table-spinner'
import TitleBar from 'modules/title-bar'
import { useApplicationList } from '../helpers/use-fetch-applications'
import { useTranslation } from 'react-i18next'

const rowStyle = {
  borderBottom: '1px solid #eee',
  boxSizing: 'border-box',
  display: 'flex',
}

const headerContentStyle = {
  alignSelf: 'center',
  color: '#666',
  fontSize: 13,
  fontWeight: 500,
}

const rowContentStyle = { ...headerContentStyle, fontWeight: 400 }
const lastHeaderContentStyle = { ...headerContentStyle, textAlign: 'right' }
const lastRowContentStyle = { ...rowContentStyle, textAlign: 'right' }

export default withRouter(ApplicationList)

export function ApplicationList({ history }): JSX.Element {
  const {
    applications,
    error,
    filteredApplications,
    isLoading,
    setFilter,
  } = useApplicationList()

  const { t } = useTranslation()

  return (
    <Wrapper>
      <TitleBar title="Applications">
        <Button link={'/admin/applications/entry'} noMargin theme="primary">
          {t('button.newApplication')}
        </Button>
      </TitleBar>
      <FilterNext
        enableFilter={false}
        enableSearch
        isResolving={isLoading}
        onSearch={(value: string): void => setFilter(value)}
        resultsCount={filteredApplications.length}
        totalCount={applications.length}
      />
      <Block height="100%" position="relative" marginTop={24}>
        {isLoading && <TableSpinner />}
        {error && <Alert type="error" messages={[error.message]} />}
        <AutoSizer>
          {({ height, width }): Table => {
            return (
              <Table
                className="clickable"
                headerHeight={50}
                height={height}
                onRowClick={({ rowData }) => {
                  history.push(`/admin/applications/entry/${rowData._id}`)
                }}
                rowCount={filteredApplications.length}
                rowGetter={({ index }): Application =>
                  filteredApplications[index]
                }
                rowStyle={rowStyle}
                rowHeight={50}
                width={width}
              >
                <Column
                  dataKey="name"
                  flexGrow={1}
                  headerStyle={headerContentStyle}
                  label="Name"
                  style={rowContentStyle}
                  width={250}
                />
                <Column
                  dataKey="slug"
                  flexGrow={1}
                  headerStyle={headerContentStyle}
                  label="App ID"
                  style={rowContentStyle}
                  width={150}
                />
                <Column
                  dataKey="_id"
                  flexGrow={1}
                  headerStyle={headerContentStyle}
                  label="ID"
                  style={rowContentStyle}
                  width={250}
                />
                <Column
                  cellRenderer={renderDateTimeTimezoneCell}
                  dataKey="updatedAt"
                  flexGrow={1}
                  headerStyle={headerContentStyle}
                  label="Updated"
                  style={rowContentStyle}
                  width={200}
                />
                <Column
                  cellRenderer={renderDateTimeTimezoneCell}
                  dataKey="createdAt"
                  flexGrow={1}
                  headerStyle={lastHeaderContentStyle}
                  label="Created"
                  style={lastRowContentStyle}
                  width={200}
                />
              </Table>
            )
          }}
        </AutoSizer>
      </Block>
    </Wrapper>
  )
}

function renderDateTimeTimezoneCell({ cellData }): DateTimeTimezone {
  return cellData && <DateTimeTimezone datetime={cellData} />
}
