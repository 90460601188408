/*
 * Task Module
 */

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import crud from '../../crud'
import hooks from './hooks'

import {
  selectors as areaSelectors,
} from '../areas'

import {
  buildNearbySelectorFn,
} from '../../helpers/selectors'

const resource = 'tasks'

export { default as schema } from './schema'

export const actions = crud.actions(resource)
hooks(actions)

export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn(resource),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const crudSelectors = crud.selectors(resource)

/**
 * returns cache grouped by nearby/other status
 * @param {Number} maxDistance - meters to query nearby areas by
 */
const nearbySelector = createSelector(
  areaSelectors.sortByPosition,
  crudSelectors.cache,
  buildNearbySelectorFn('tasks'),
)

export const selectors = {
  ...crudSelectors,
  nearbySelector,
}

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})
