import { Redirect, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import React from 'react'

import PageLoader from 'components/page-loader'

const Entry = Loadable({
  loader: () => import('./components/entry'),
  loading: PageLoader,
})

export default function ProfileRoute() {
  return (
    <Switch>
      <Route component={Entry} exact path="/settings/profile" />
      <Redirect to="/404" />
    </Switch>
  )
}
