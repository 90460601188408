import React from 'react'

import Icon from 'components/icon'
import colors from 'config/theme/colors'
import typography from 'config/theme/typography'

interface SearchProps {
  onChange: () => {}
  containerStyles: React.CSSProperties
}

export default function SearchBox(props: SearchProps): JSX.Element {
  const { onChange, containerStyles } = props

  // TODO refactor these styles when generecising the component
  // maybe bring Fuse in here and pass in the options instead?
  const styles = {
    icon: {
      height: '48px',
      lineHeight: '48px',
      paddingLeft: '20px',
      fontSize: '16px',
      color: colors.gray.normal,
    },
    input: {
      background: '0 none',
      border: '0 none',
      outline: 'none',
      padding: '0 10px',
      fontFamily: typography.defaultFontFamily,
      fontSize: '12px',
      fontWeight: 500,
      color: colors.gray.normal,
    },
  }

  return (
    <div style={containerStyles}>
      <Icon name="search" theme={styles.icon} />
      <input
        autoFocus
        onChange={onChange}
        placeholder="Search..."
        style={styles.input}
      />
    </div>
  )
}
