import { actions } from '../'
import {
  parseState,
} from '../../../helpers'

const initialState = {
  cache: {},
  filters: {},
}

export default function reducer(state, action = {}) {
  state = parseState(state, initialState)

  const { data: response = {}, error, filters, retrying } = action
  const chartKey = action.chartKey || response.chartKey || null
  const chartPath = chartKey && ['cache', chartKey]
  const path = chartPath || []

  switch (action.type) {
    case actions.CLEAR_FILTERS:
      return state.setIn(
        [...path, 'filters'],
        {},
      )
    case actions.QUERY_REQUEST:
      state = state.setIn([...chartPath, 'state'], 'resolving')
      return state.setIn([...chartPath, 'data'], [])

    case actions.QUERY_SUCCESS:
      state = state.updateIn(chartPath, (pathState) => {
        const { data, dimensions, label, metric } = response
        return pathState.merge({
          data,
          dimensions,
          label,
          metric,
          state: 'resolved',
        })
      })

      return state

    case actions.QUERY_ERROR:
      state = state.setIn(
        [...chartPath, 'state'],
        retrying ? 'resolving' : 'resolved',
      )

      return state.setIn([...chartPath, 'error'], error)

    case actions.SET_FILTERS:
      return state.setIn(
        [...path, 'filters'],
        filters,
      )

    default:
      return state
  }
}
