import moment from 'moment-timezone'
import { some } from 'lodash/fp'

import { Schema as ServiceHoursSchema } from '..'
import { serviceIntervalsGenerator } from '../../scheduling/generators'
import { Unit } from '../../scheduling/scheduling.types'

interface IsOpen {
  serviceHours: ServiceHoursSchema
  timestamp: number
}

/**
 * Checks whether timestamp is within service hours for start and end
 */
export function isOpen({ serviceHours, timestamp }: IsOpen): boolean {
  if (!timestamp) return false

  const start = moment
    .utc(timestamp)
    .subtract(1, Unit.Day)
    .valueOf()
  const end = moment
    .utc(timestamp)
    .add(1, Unit.Day)
    .valueOf()

  const serviceIntervalSequence = serviceIntervalsGenerator({
    end,
    serviceHours,
    start,
  })

  const serviceIntervals = [...serviceIntervalSequence]
  const isOpen = some(([start, end]) => timestamp >= start && timestamp <= end)(
    serviceIntervals
  )

  return isOpen
}
