import { compose, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { getModule, validation } from '@lighthouse/sdk'
import { map, trim } from 'lodash'
import { withTranslation } from 'react-i18next'
import React from 'react'

import { BasicForm, FieldGroup, FieldSet, Text } from 'components/form'
import { Flex } from 'components/common'
import { LANGUAGES } from 'config/constants'
import Alert from 'components/alert'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'
import Select from 'components/form/select'
import { CONFIG_ID, withAuth } from 'components/useAuth'
import i18next from 'i18next'

const FORM_PROPS = { destroyOnUnmount: false, form: 'sign-in-slug' }

const appModule = getModule('app')
const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

export default compose(
  reduxForm(FORM_PROPS),
  connect(mapStateToProps, mapDispatchToProps),
  withAuth,
  withTranslation(),
  withHandlers({ handleContinue }),
  lifecycle({ componentWillMount })
)(Form)

function Form(props) {
  const {
    error,
    handleContinue,
    handleSubmit,
    invalid,
    locale,
    setLocale,
    submitting,
    t,
    i18n,
  } = props

  return (
    <Flex flexDirection="column" paddingLeft={40} paddingRight={40}>
      <BasicForm onSubmit={handleSubmit(handleContinue)} noValidate>
        {error && <Alert messages={[error]} marginBottom={0} type="error" />}
        <FieldGroup>
          <FieldSet>
            <Field
              component={Text}
              dataTestId="app-id-text"
              name="slug"
              placeholder={t('placeholder.enterApplicationId')}
              required
              validate={[isRequired]}
            />
          </FieldSet>
        </FieldGroup>

        <ButtonGroup align="right" border>
          <Flex justifyContent="space-between" width="100%">
            <Select
              clearable={false}
              onChange={value => {
                i18n.changeLanguage(value)
                setLocale(value)
              }}
              options={map(LANGUAGES, ({ shortLabel, shortLabelT, value }) => ({
                label: shortLabelT ? t(shortLabelT) : shortLabel,
                value,
              }))}
              placeholder={t('placeholder.selectLanguage')}
              value={locale || i18n.language}
            />
            <Flex justifyContent="flex-end">
              <Button
                disabled={invalid || submitting}
                loading={submitting}
                theme="positive noMargin"
                type="submit"
              >
                {t(
                  submitting ? 'button.fetchingApplication' : 'button.continue'
                )}
              </Button>
            </Flex>
          </Flex>
        </ButtonGroup>
      </BasicForm>
    </Flex>
  )
}

function componentWillMount() {
  const { removeCredentials, reset, revoke } = this.props
  // NOTE revoke and clear config (from useAuth) will ensure there's no stale oidc session in state
  revoke()
  removeCredentials(CONFIG_ID)

  // NOTE reset the redux form
  reset()
}

function handleContinue(props) {
  const { fetchAppConfig, setApplication, setCredentials, setRegion, t } = props

  return async ({ slug }) => {
    try {
      const { data } = await fetchAppConfig(trim(slug).toLowerCase())
      const { applicationId, authConfig, region } = data

      if (!applicationId || !region) {
        const _error = t('error.applicationNotFound')
        throw new SubmissionError({ _error })
      }

      setApplication(applicationId)

      setCredentials(CONFIG_ID, authConfig)

      return setRegion(region)
    } catch (err) {
      if (/NotFoundError/.test(err.name)) {
        const _error = t('error.applicationNotFound')
        throw new SubmissionError({ _error })
      } else {
        const _error = 'There was an unknown error, please contact support'
        throw new SubmissionError({ _error })
      }
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAppConfig: slug =>
      dispatch(appModule.getConfig(process.env.APPLICATION_CONFIG_URL, slug)),
    setApplication: (id, role) => dispatch(appModule.setApplication(id, role)),
    setRegion: regionCode => dispatch(appModule.setRegion(regionCode)),
    setLocale: params => dispatch(appModule.setLocale(params)),
  }
}

function mapStateToProps(state) {
  return {
    locale: state.app.locale,
  }
}
