/*
 * Zone Module
 */

import {
  find,
  get,
  isEmpty,
  mapValues,
  memoize,
  toString,
} from 'lodash'

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import buildActionCreators from './action-creators'
import crud from '../../crud'
import customReducers from './reducers'

const resource = 'zones'

const actions = crud.actions(resource)

Object.assign(actions, {
  NEARBY_REQUEST: `lighthouse/${resource}/NEARBY_REQUEST`,
  NEARBY_SUCCESS: `lighthouse/${resource}/NEARBY_SUCCESS`,
  NEARBY_ERROR: `lighthouse/${resource}/NEARBY_ERROR`,
  NEARBY_RESET: `lighthouse/${resource}/NEARBY_RESET`,
})

export { actions }
export { default as schema } from './schema'

const baseUrlFn = applicationResourceUrlFn(resource)
const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  authorizationHeadersFn,
  applicationParamsFn,
)

export const actionCreators = {
  ...crudActionCreators,
  ...customActionCreators,
}

const crudSelectors = crud.selectors(resource)
const getZoneByBeacon = createSelector(
  crudSelectors.cache,
  cache => memoize((beacon) => {
    if (!beacon) return null

    const beaconObj = mapValues(beacon, toString)

    if (!beaconObj.uuid) return false
    if (isEmpty(beaconObj)) return false

    const zone = find(cache, (zone) => {
      const { uuid, major, minor } = get(zone, 'entity.beacon', {})
      const zoneHasBeacon = (uuid && major && minor)

      if (!zoneHasBeacon) return false

      return (
        uuid.toLowerCase() === beaconObj.uuid.toLowerCase() &&
        major === beaconObj.major &&
        minor === beaconObj.minor
      )
    })
    return zone && zone.entity
  }, (beacon = {}) => {
    const { uuid, major, minor } = beacon
    const isValid = (uuid && major && minor)

    if (!isValid) return null

    const cacheKey = `${uuid}-${major}-${minor}`
    return cacheKey
  }),
)

const getZoneByNfc = createSelector(
  crudSelectors.cache,
  cache => memoize((nfcId) => {
    if (!nfcId) return null

    const zone = find(cache, (zone) => {
      const tagId = get(zone, 'entity.nfc.id')
      const isNfcId = tagId === nfcId

      if (!tagId || !isNfcId) return false

      return true
    })

    return zone && zone.entity
  }),
)

export const selectors = Object.assign(
  {},
  crudSelectors,
  {
    getZoneByBeacon,
    getZoneByNfc,
  },
)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
  ...customReducers,
})
