import { brandColors, colors, typography } from 'config/theme'

export default {
  // look into whether this should be some kind of
  // linkColor var instead. Might be unnecesarry
  root: {
    backgroundColor: colors.white,
    borderColor: colors.gray.lighter,
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxSizing: 'border-box',
    color: colors.gray.darker,
    display: 'block',
    fontFamily: typography.defaultFontFamily,
    fontSize: '14px',
    fontWeight: 300,
    outline: 'none',
    overflow: 'hidden',
    padding: '14px',
    transition: '0.3s',
    width: '100%',
    '::-webkit-input-placeholder': {
      color: 'red',
    },
    ':focus': {
      borderColor: brandColors.primary,
    },
  },
  input: {
    maxHeight: '48px',
  },
  inputContainer: {
    display: 'flex',
  },
  inputError: {
    borderColor: colors.red.normal,
    ':focus': {
      borderColor: colors.red.normal,
    },
  },
  disabled: {
    backgroundColor: colors.gray.lightest,
    borderColor: colors.gray.lightest,
    color: colors.gray.light,
    cursor: 'not-allowed',
    ':focus': {
      borderColor: colors.gray.lightest,
    },
  },
  smallInput: {
    fontSize: '12px',
  },
  textarea: {
    resize: 'vertical',
    overflow: 'auto',
  },
}
