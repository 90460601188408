import {
  compose,
  setPropTypes,
  withHandlers,
  withPropsOnChange,
} from 'recompose'
import { attempt, get, isError, toNumber } from 'lodash'
import { point } from '@turf/helpers'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Block } from 'components/common'
import withUrlQuery from 'components/with-url-query'
import colors from 'config/theme/colors'

import AreaView from './components/area-view'
import AuditView from './components/audit-view'
import IssueView from './components/issue-view'
import SignalView from './components/signal-view'
import TaskView from './components/task-view'
import useToggle from 'components/useToggle'

import { getModule } from '@lighthouse/sdk'
const geoModule = getModule('geo')

const PROP_TYPES = {
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  isArea: PropTypes.bool,
  isAudit: PropTypes.bool,
  isEditing: PropTypes.bool,
  isIssue: PropTypes.bool,
  isSignal: PropTypes.bool,
  isTask: PropTypes.bool,
  isVisible: PropTypes.bool,
  urlQuery: PropTypes.object.isRequired,
  urlGeometry: PropTypes.object,
}

export default compose(
  Radium,
  withRouter,
  withUrlQuery,
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['urlQuery'], buildUrlQueryProps),
  withHandlers({ handleClose }),
  setPropTypes(PROP_TYPES)
)(PanelLeft)

function PanelLeft(props) {
  const {
    areaCache,
    handleClose,
    id,
    isArea,
    isAudit,
    isEditing,
    isIssue,
    isSignal,
    isTask,
    isVisible,
    selectedLocation,
    setLocation,
    urlGeometry,
  } = props

  const defaultWidth = (isArea || isSignal) && 450
  const [expand, setExpand] = useToggle({ key: 'panel-left' })
  const [toggleIcon, setToggleIcon] = useState('expand-2')
  const [width, setWidth] = useState(450)

  useEffect(() => {
    setWidth(expand ? 600 : 450)
    setToggleIcon(expand ? 'contract' : 'expand-2')
  }, [expand])

  const toggleExpand = () => setExpand(!expand)

  const shouldDisplayAreaView = !!(isArea && id)
  const isLocation = get(areaCache, `${id}.entity.type`) === 'location'
  const selectedLocationId = get(selectedLocation, 'id')

  if (shouldDisplayAreaView && isLocation && selectedLocationId !== id) {
    setLocation({ id })
  } else if ((!shouldDisplayAreaView || !isLocation) && selectedLocationId) {
    setLocation({})
  }

  return (
    <Block
      dataTestId="form-panel"
      backgroundColor={colors.white}
      borderRight={`1px solid ${colors.gray.lighter}`}
      bottom={0}
      left={isVisible ? 0 : -(defaultWidth || width)}
      position="absolute"
      top={0}
      transition="0.5s"
      width={defaultWidth || width}
      zIndex={1100}
    >
      {shouldDisplayAreaView && (
        <AreaView
          areaId={id}
          isEditing={isEditing}
          key={id}
          onClose={handleClose}
        />
      )}
      {isAudit && (
        <AuditView
          id={id}
          key={id}
          onClose={handleClose}
          toggleExpand={toggleExpand}
          toggleIcon={toggleIcon}
          urlGeometry={urlGeometry}
        />
      )}
      {isIssue && (
        <IssueView
          id={id}
          key={id}
          onClose={handleClose}
          toggleExpand={toggleExpand}
          toggleIcon={toggleIcon}
          urlGeometry={urlGeometry}
        />
      )}
      {isSignal && (
        <SignalView
          id={id}
          isEditingSignal={isEditing}
          key={id}
          onClose={handleClose}
        />
      )}
      {isTask && (
        <TaskView
          id={id}
          key={id}
          onClose={handleClose}
          toggleExpand={toggleExpand}
          toggleIcon={toggleIcon}
          urlGeometry={urlGeometry}
        />
      )}
    </Block>
  )
}

function buildUrlQueryProps(props) {
  const { urlQuery } = props
  const { action, lat, lng, id, resource } = urlQuery

  const isEditing = action === 'edit'
  const isDeviceRelationships = action === 'device-relationships'
  const isEditBoundaries = action === 'edit-boundaries'

  const isArea = resource === 'area'
  const isAudit = resource === 'auditentries'
  const isIssue = resource === 'issue'
  const isSignal = resource === 'signal'
  const isTask = resource === 'taskentries'

  const isAreaVisible =
    isArea && !isDeviceRelationships && !isEditBoundaries && !!id

  const isVisible = isAreaVisible || isAudit || isIssue || isSignal || isTask

  const lngLat = [toNumber(lng), toNumber(lat)]
  const geoJsonPoint = attempt(point, lngLat)
  const validPoint = !isError(geoJsonPoint)
  const urlGeometry = validPoint ? geoJsonPoint.geometry : null

  return {
    id,
    isArea,
    isAudit,
    isEditing,
    isIssue,
    isSignal,
    isTask,
    isVisible,
    urlGeometry,
  }
}

function handleClose({ history }) {
  return () => history.push({ search: '' })
}

function mapStateToProps(state) {
  return {
    areaCache: state.areas.cache,
    selectedLocation: state.geo.location,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLocation: opts => dispatch(geoModule.setLocation(opts)),
  }
}
