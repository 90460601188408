import { getErrorDataFn, getSuccessDataFn } from '../../tracking/helpers'

const category = 'Users'

export const removeErrorDataFn = getErrorDataFn({ category, action: 'Remove Error' })

export const removeSuccessDataFn = getSuccessDataFn({ category, action: 'Remove Success' })

export const saveErrorDataFn = getErrorDataFn({ category, action: 'Save Error' })

export const saveSuccessDataFn = getSuccessDataFn({ category, action: 'Save Success' })
