import { validation } from '@lighthouse/sdk'
import React from 'react'
import { Field } from 'redux-form'

import { InputSelect } from 'components/form'
import SimpleTable, { Column } from '../../../../components/table-simple'
import i18next from 'i18next'

const booleanOptions = [
  { label: 'TRUE', value: true },
  { label: 'FALSE', value: false },
]
const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  color: '#666',
  fontSize: 13,
  borderBottom: '1px solid #eee',
}

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

const FlagFormTable = ({ flags }) => {
  return (
    <div style={{ height: '100%' }}>
      <SimpleTable
        data={flags}
        style={{ overflow: 'auto' }}
        headerStyle={{
          color: '#666',
          fontSize: 13,
          fontWeight: 500,
          padding: '15px 0',
          borderBottom: '1px solid #ebebeb',
        }}
      >
        <Column dataKey="name" label="Name" cellStyle={cellStyle} />
        <Column
          dataKey="description"
          label="Description"
          flex={3}
          cellStyle={cellStyle}
        />
        <Column
          dataKey="fieldName"
          label="Option"
          cellStyle={{ ...cellStyle, padding: '10px 0' }}
          cellRenderer={({ cellData }) => (
            <Field
              component={InputSelect}
              name={`flags.${cellData}`}
              options={booleanOptions}
              required
              validate={[isRequired]}
              noMargin
            />
          )}
        />
      </SimpleTable>
    </div>
  )
}

export default FlagFormTable
