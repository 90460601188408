import { compact, get, isArray, isPlainObject, reduce } from 'lodash'

export function getLocationReference(data = {}) {
  const {
    areaLabelPath = 'area.label',
    areaLocations = {},
    geocodedLabelPath = 'gps.reverseGeocoded.label',
    entity = {},
    locations = {},
    zones = {},
  } = data

  // 1. If we can construct `{point}, {location}` use that
  const areaPoint = get(entity, 'area.point.name')
  const areaLocation = get(entity, 'area.location.name')

  if (areaLocation && areaPoint) {
    return `${areaPoint} - ${areaLocation}`
  }

  // 2. Else, try and use area label
  const areaLabel = get(entity, areaLabelPath)

  if (areaLabel) {
    return areaLabel
  }

  // 3. Else, try and use zone, location
  const location = entity.location
  const zone = entity.zone

  // TODO This can be deprecated once we have removed visits as a feature. It's to account for
  // legacy location ids being referenced. This temporary workaround allows us to use the area
  // locations legacyId reference as a key which will match a legacy location id on the entity
  const areaLocationMap = reduce(
    areaLocations,
    (memo, location) => {
      const legacyId = location?.legacyId?.toString()
      if (!legacyId) return memo

      memo[legacyId] = location
      return memo
    },
    {}
  )

  // NOTE we expect locations/zones in object map format so convert array to
  // format before running through
  const locationMap = isArray(locations)
    ? reduce(
        locations,
        (memo, location) => {
          const locationId = location?._id?.toString()
          if (!locationId) return memo

          memo[locationId] = location
          return memo
        },
        {}
      )
    : locations

  const zoneMap = isArray(zones)
    ? reduce(
        zones,
        (memo, zone) => {
          const zoneId = zone?._id?.toString()
          if (!zoneId) return memo

          memo[zoneId] = zone
          return memo
        },
        {}
      )
    : zones

  // NOTE location/zone can be passed as an object, object id or plain string
  const locationName = isPlainObject(location)
    ? location.name
    : location
    ? get(areaLocationMap, `${location.toString()}.name`) ||
      get(locationMap, `${location.toString()}.name`)
    : undefined

  const zoneName = isPlainObject(zone)
    ? zone.name
    : zone
    ? get(zoneMap, `${zone.toString()}.name`)
    : undefined

  if (zoneName) {
    const parts = compact([zoneName, locationName])
    return parts.join(', ')
  }

  // 4. Else, try and use location only
  if (locationName) {
    return locationName
  }

  // 5. Else, try geocoded location
  const geocodedLabel = get(entity, geocodedLabelPath)

  if (geocodedLabel) {
    return geocodedLabel
  }

  return 'Unknown Location'
}
