import { Interval } from '../scheduling.types'

/**
 * Checks if interval covers another
 * Accepts two intervals and checks whether the first covers the second
 *
 * intervalCovers([0,20], [5,15])
 * returns true
 */
export function intervalCovers(
  firstInterval: Interval,
  secondInterval: Interval
): Boolean {
  return (
    firstInterval[0] <= secondInterval[0] && firstInterval[1] >= secondInterval[1]
  )
}
