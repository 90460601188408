import { getUserFullName } from '../'

export function getReferenceDetails(data) {
  const { entity, users } = data
  const { user } = entity

  let label = 'Reference'
  let value = 'Unknown Reference'

  if (user) {
    const userFullName = getUserFullName(users, user)
    label = 'User'
    value = userFullName
  }

  return {
    label,
    value,
  }
}
