import { Schema, ValidateOptions } from 'yup'
import { ValidationError } from '../errors'

export { ValidationError }

interface Validate {
  data: object
  options?: ValidateOptions
  schema: Schema<any>
}

const defaultValidateOptions = {
  strict: true,
  stripUnknown: true,
}

export async function validate({
  schema,
  data,
  options = defaultValidateOptions,
}: Validate) {
  try {
    return await schema.validate(data, options)
  } catch (err) {
    throw new ValidationError({
      data: err,
    })
  }
}

export default validate
