import { pick } from 'lodash'

/**
 * Errors
 * ------
 * NOTE: this was the only pattern I could find that sub-classed with babel.
 * Here's a link to the SO https://stackoverflow.com/a/35858868
 */

// 400
export class BadRequestError {
  constructor(payload = {}, status) {
    this.name = 'BadRequestError'
    this.message = payload.message
    this.response = payload.response
    this.stack = new Error().stack
    this.status = status
  }
}

BadRequestError.prototype = Object.create(Error.prototype)

// 401
export class AuthenticationError {
  constructor(payload = {}, status) {
    this.name = 'AuthenticationError'
    this.message = payload.message
    this.response = payload.response
    this.stack = new Error().stack
    this.status = status
  }
}

AuthenticationError.prototype = Object.create(Error.prototype)

// 403
export class AuthorizationError {
  constructor(payload = {}, status) {
    this.name = 'AuthorizationError'
    this.message = payload.message
    this.response = payload.response
    this.stack = new Error().stack
    this.status = status
  }
}

AuthorizationError.prototype = Object.create(Error.prototype)

// 404
export class NotFoundError {
  constructor(payload = {}, status) {
    this.name = 'NotFoundError'
    this.message = payload.message
    this.response = payload.response
    this.stack = new Error().stack
    this.status = status
  }
}

NotFoundError.prototype = Object.create(Error.prototype)

// DuplicateRequestError
export class DuplicateRequestError {
  constructor() {
    this.name = 'DuplicateRequestError'
    this.message = 'A request for this resource is already in progress. Please wait for previous request to complete.'
    this.stack = new Error().stack
  }
}

DuplicateRequestError.prototype = Object.create(Error.prototype)

// 500
export class ServerError {
  constructor(payload = {}, status) {
    this.name = 'ServerError'
    this.message = payload.message
    this.response = payload.response
    this.stack = new Error().stack
    this.status = status
  }
}

ServerError.prototype = Object.create(Error.prototype)

// NetworkError
export class NetworkError {
  constructor(payload = {}) {
    this.name = 'NetworkError'
    this.message = payload.message
    this.response = payload.response
    this.stack = new Error().stack
  }
}

NetworkError.prototype = Object.create(Error.prototype)

// XXX
export class UnknownError {
  constructor(payload = {}, status) {
    this.name = 'UnknownError'
    this.message = payload.message
    this.response = payload.response
    this.stack = new Error().stack
    this.status = status
  }
}

UnknownError.prototype = Object.create(Error.prototype)

const errorMap = {
  400: BadRequestError,
  401: AuthenticationError,
  403: AuthorizationError,
  404: NotFoundError,
  500: ServerError,
}

export function findError(code) {
  return errorMap[code] || UnknownError
}

export function serializeError(error) {
  return pick(error, [
    'message',
    'name',
    'response',
    'status',
  ])
}

