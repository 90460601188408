import { compose } from 'recompose'
import { Redirect, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import React from 'react'

import FlagsHOC from 'components/flags/hoc'

import PageLoader from 'components/page-loader'

const Entry = Loadable({
  loader: () => import('./components/entry'),
  loading: PageLoader,
})

const List = Loadable({
  loader: () => import('./components/list'),
  loading: PageLoader,
})

const QRCodes = Loadable({
  loader: () => import('./components/qr-codes'),
  loading: PageLoader,
})

export default compose(FlagsHOC)(SignalsRoute)

function SignalsRoute() {
  return (
    <Switch>
      <Route
        component={Entry}
        exact
        path={['/setup/signals/entry/:id', '/setup/signals/entry']}
      />
      <Route component={QRCodes} exact path="/setup/signals/qr-codes" />
      <Route component={List} exact path="/setup/signals" />
      <Redirect to="/404" />
    </Switch>
  )
}
