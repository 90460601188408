import { getModule } from '@lighthouse/sdk'
import qs from 'query-string'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

const appModule = getModule('app')

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentWillMount,
  })
)

function componentWillMount() {
  const { region, setEndpoints, setRegion } = this.props

  const { region: urlRegion } = qs.parse(window.location.search)

  if (!region || urlRegion) {
    if (!urlRegion) {
      console.warn('region not found on url query, defaulting to `us`')
    }

    setRegion(urlRegion || 'us')
  }

  setEndpoints({
    au: process.env.AU_API_URL,
    us: process.env.US_API_URL,
  })
}

function mapStateToProps(state) {
  return {
    region: state.app.region,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setEndpoints: endpoints => dispatch(appModule.setEndpoints(endpoints)),
    setRegion: region => dispatch(appModule.setRegion(region)),
  }
}
