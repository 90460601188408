import { pure } from 'recompose'
import React from 'react'

import { colors } from 'config/theme'
import { Block, Span } from 'components/common'

export default pure(ListTitle)

interface ListTitleProps {
  children: React.ReactNode | React.ReactNode[]
  color?: string
  customStyles?: React.CSSProperties
}

function ListTitle(props: ListTitleProps): JSX.Element {
  const { children, color = null, ...customStyles } = props

  return (
    <Block maxWidth={250} {...customStyles}>
      <Span
        color={color ? color : colors.gray.normal}
        fontSize={13}
        fontWeight={300}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width="100%"
      >
        {children}
      </Span>
    </Block>
  )
}
