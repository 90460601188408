import React from 'react'
import Backdrop from 'components/common/backdrop'
import Spinner from 'components/spinner'

export default function PageLoader() {
  return (
    <Backdrop zIndex={1300}>
      <Spinner type="dots" />
    </Backdrop>
  )
}
