/*
 * Audit Entries Module
 */

import { combineReducers } from 'redux'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import crud from '../../crud'
import hooks from './hooks'

const resource = 'auditEntries'

export const actions = crud.actions(resource)
hooks(actions)

export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

export function baseUrlFn(params) {
  const auditsEndpoint = applicationResourceUrlFn('audits')(params)
  return params.auditId ?
    `${auditsEndpoint}/${params.auditId}/entries` :
    `${auditsEndpoint}/entries`
}
