import { REQUEST } from '../../../../middleware/request'

export default function buildActionCreators(actions, baseUrlFn, headersFn) {
  return {
    getProfileRequest,
  }

  function getProfileRequest(params) {
    return (dispatch, getState) => {
      const state = getState()
      const endpoint = `${baseUrlFn(params, state)}/speakers/${params.speakerId}/profile`
      const headers = headersFn(params, state)
      return dispatch(getProfile(endpoint, headers))
    }
  }

  function getProfile(endpoint, headers) {
    return {
      [REQUEST]: {
        types: [actions.GET_PROFILE_REQUEST, actions.GET_PROFILE_SUCCESS, actions.GET_PROFILE_ERROR],
        endpoint,
        method: 'GET',
        headers,
      },
    }
  }
}
