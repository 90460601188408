import React from 'react'
import NavList from 'modules/nav-list'
import { isArray, map } from 'lodash'
import { useTranslation } from 'react-i18next'

export default function Nav({ navigation, section }) {
  const { t } = useTranslation()
  const navItems = {}

  map(navigation, (nav, key) => {
    navItems[key] = {
      ...nav,
      label: nav.labelT
        ? isArray(nav.labelT)
          ? t(...nav.labelT)
          : t(nav.labelT)
        : nav.label,
    }
  })

  return <NavList type="secondary" active={section} items={navItems} />
}
