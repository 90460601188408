import undoable from '@lighthouse/redux-undo'
import { some } from 'lodash'

export default function historyWrapper(namespace, reducerToWrap) {
  if (!namespace) throw new Error('`namespace` argument is required')
  if (!reducerToWrap) throw new Error('`reducerToWrap` argument is required')

  const actions = {
    CLEAR_HISTORY: `lighthouse/${namespace}/CLEAR_HISTORY`,
    JUMP: `lighthouse/${namespace}/JUMP`,
    JUMP_TO_FUTURE: `lighthouse/${namespace}/JUMP_TO_FUTURE`,
    JUMP_TO_PAST: `lighthouse/${namespace}/JUMP_TO_PAST`,
    REDO: `lighthouse/${namespace}/REDO`,
    RESET_HISTORY: `lighthouse/${namespace}/RESET_HISTORY`,
    SET_HISTORY: `lighthouse/${namespace}/SET_HISTORY`,
    UNDO: `lighthouse/${namespace}/UNDO`,
  }

  const actionCreators = {
    clearHistory: () => ({ type: actions.CLEAR_HISTORY }),
    jump: (index) => ({ type: actions.JUMP, index }),
    jumpToFuture: (index) => ({ type: actions.JUMP_TO_FUTURE, index }),
    jumpToPast: (index) => ({ type: actions.JUMP_TO_PAST, index }),
    redo: () => ({ type: actions.REDO }),
    resetHistory: () => ({ type: actions.RESET_HISTORY }),
    setHistory: (data) => ({ type: actions.SET_HISTORY, data }),
    undo: () => ({ type: actions.UNDO }),
  }

  const reducer = undoable(reducerToWrap, {
    clearHistoryType: [actions.CLEAR_HISTORY],
    resetHistoryType: [actions.RESET_HISTORY],
    debug: false,
    filter: (incomingAction) => {
      // NOTE only process reducer actions (defined above) for undoable. This
      // might need to support actions passed in as not all reducers will use
      // just the actions above
      const isReducerAction = some(actions, (action) => action === incomingAction.type)
      return isReducerAction
    },
    ignoreInitialState: true,
    initTypes: [
      '@@redux/INIT',
    ],
    jumpToFutureType: actions.JUMP_TO_FUTURE,
    jumpToPastType: actions.JUMP_TO_PAST,
    jumpType: actions.JUMP,
    redoType: actions.REDO,
    undoType: actions.UNDO,
  })

  return {
    actions,
    actionCreators,
    reducer,
  }
}
