import { find } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Block, Flex, Span } from 'components/common'
import { colors } from 'config/theme'
import Alert from 'components/alert'
import Chat from './components/chat'
import GroupList from './components/group-list'
import Toolbar from './components/toolbar'
import { useTranslation } from 'react-i18next'

export default MessengerNext

function MessengerNext(props) {
  const {
    groups,
    history,
    messages,
    onGroupClick,
    onSend,
    selectedGroup,
  } = props

  const { t } = useTranslation()

  if (!groups || groups.length === 0) {
    const message = (
      <Block>
        {t('textDoNotBelongToAnyMessageGroups')}
        <Span
          cursor="pointer"
          fontWeight={500}
          marginLeft={10}
          marginRight={5}
          onClick={() => history.push('/messages/groups/new')}
          textDecoration="underline"
        >
          {t('textCreateYourFirstGroup')}
        </Span>
        {t('textNow')}
      </Block>
    )

    return <Alert messages={[message]} />
  }

  const { members = [], name } = find(groups, ['id', selectedGroup]) || {}

  return (
    <Flex
      borderColor={colors.gray.lightest}
      borderStyle="solid"
      borderWidth={2}
      height="100%"
      marginBottom={60}
      width="100%"
    >
      <Block overflow="auto" width="30%">
        <GroupList
          groups={groups}
          selected={selectedGroup}
          onGroupClick={onGroupClick}
        />
      </Block>
      <Block
        borderLeftColor={colors.gray.lighter}
        borderLeftStyle="solid"
        borderLeftWidth={1}
        flexGrow={1}
        width="70%"
      >
        <Flex flexDirection="column" height="100%">
          <Toolbar
            members={members}
            name={name}
            selectedGroup={selectedGroup}
          />
          <Chat messages={messages} onSend={onSend} title="Chat Title Here" />
        </Flex>
      </Block>
    </Flex>
  )
}

MessengerNext.propTypes = {
  groups: PropTypes.array,
  messages: PropTypes.array,
  onGroupClick: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  selectedGroup: PropTypes.string,
}
