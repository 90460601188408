/*
 * Shifts Module
 */

import { combineReducers } from 'redux'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import buildActionCreators from './action-creators'
import crud from '../../crud'
import hooks from './hooks'

const resource = 'shifts'
const actions = crud.actions(resource)

Object.assign(actions, {
  END_REQUEST: `lighthouse/${resource}/END_REQUEST`,
  END_SUCCESS: `lighthouse/${resource}/END_SUCCESS`,
  END_ERROR: `lighthouse/${resource}/END_ERROR`,
})

hooks(actions)

export {
  actions,
}

const baseUrlFn = applicationResourceUrlFn(resource)
const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  authorizationHeadersFn,
  applicationParamsFn,
)

export const actionCreators = {
  ...crudActionCreators,
  ...customActionCreators,
}

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})
