import { get, isArray, map, some } from 'lodash'
import Radium from 'radium'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Block, Flex } from 'components/common'
import DatetimeTimezone from 'components/datetime-timezone'
import Image from 'components/image'
import { Cell, Table, RowHeader } from 'components/table-basic'
import { colors } from 'config/theme'
import Icon from 'components/icon'
import Thumbnail from 'components/thumbnail'
import cloudinary from 'utils/cloudinary'

import styles from './styles'

// NOTE: Regex use cases https://regexr.com/3sh8s
const REGEX_STYLE_ATTRS = /(<[^>]+) style=".*?"/gi

const s3BaseUrl = process.env.S3_UPLOADS_BASE_URL

const iconExtensionMap = {
  doc: 'file-doc',
  docx: 'file-doc',
  pdf: 'file-pdf',
  txt: 'file-txt',
}

const templateComponent = ({ data }) => (
  <div>
    {data.formGroups.map((formGroup, formGroupIndex) => {
      const { label, fieldGroups, skipped } = formGroup

      const showFormGroup = hasVisibleFieldGroups(fieldGroups)

      // NOTE: if no fields groups/fields are
      // visible then do not display form group at all
      if (!showFormGroup) return null

      return (
        <div style={[styles.formGroup]} key={formGroupIndex}>
          {fieldGroups.map((fieldGroup, fieldGroupNumber) => {
            // Title should be label with count if repeatable, otherwise
            // just label
            const title =
              formGroup.repeatable === 0
                ? `${label} #${fieldGroupNumber + 1}`
                : label

            return (
              <div key={`${formGroupIndex}-${fieldGroupNumber}`}>
                <h3 style={[styles.formGroupLabel]}>{title}</h3>
                {fieldGroup.fields.map((field, fieldIndex) => {
                  const { fieldtype, options = {}, value } = field

                  let fieldContent

                  const { format, showOnRead = true } = options

                  if (!showOnRead) return null

                  const isPhoto =
                    fieldtype === 'list' && options.type === 'media'

                  const isFile = fieldtype === 'file'

                  if (fieldtype === 'text') {
                    if (options.type === 'signature') {
                      fieldContent = value ? (
                        <img
                          alt="signature"
                          src={value}
                          style={styles.signature}
                        />
                      ) : (
                        ''
                      )
                    } else if (options.type === 'html') {
                      if (!value) {
                        fieldContent = ''
                      } else {
                        // strip out custom WYSIWYG styles
                        const parsed = value.replace(REGEX_STYLE_ATTRS, '$1')
                        fieldContent = ReactHtmlParser(parsed)
                      }
                    } else {
                      fieldContent = value
                    }
                  }

                  if (fieldtype === 'number') {
                    fieldContent = value
                  }

                  if (fieldtype === 'select') {
                    if (options.type === 'stars') {
                      if (value) {
                        fieldContent =
                          value === '1' ? `${value} star` : `${value} stars`
                      } else {
                        fieldContent = ''
                      }
                    } else {
                      fieldContent = isArray(value) ? value.join(', ') : value
                    }
                  }

                  if (fieldtype === 'switch') {
                    fieldContent = value ? 'Yes' : 'No'
                  }

                  if (fieldtype === 'date') {
                    fieldContent = value ? (
                      <DatetimeTimezone
                        appendTimezone
                        datetime={value}
                        format={format}
                      />
                    ) : (
                      ''
                    )
                  }

                  if (fieldtype === 'time') {
                    fieldContent = value ? (
                      <DatetimeTimezone
                        appendTimezone
                        datetime={value}
                        format={format}
                      />
                    ) : (
                      ''
                    )
                  }

                  if (fieldtype === 'image-display') {
                    fieldContent = value ? (
                      <>
                        <Image
                          alt="image"
                          marginBottom={20}
                          maxWidth={300}
                          src={`${s3BaseUrl}/${value}`}
                        />
                      </>
                    ) : (
                      ''
                    )
                  }

                  if (fieldtype === 'reference') {
                    fieldContent = isArray(value)
                      ? value && value.map(val => val.label).join(', ')
                      : value && value.label
                  }

                  if (isPhoto) {
                    fieldContent = (
                      <Flex flexWrap="wrap">
                        {value.map(asset => {
                          const linkUrl = `${s3BaseUrl}/${asset}`
                          const thumbnailUrl = cloudinary(asset, {
                            width: 100,
                          })
                          return (
                            <Thumbnail
                              alt={thumbnailUrl}
                              key={linkUrl}
                              link={linkUrl}
                              marginBottom="10px"
                              marginRight="10px"
                              src={thumbnailUrl}
                              width={100}
                            />
                          )
                        })}
                      </Flex>
                    )
                  }

                  if (isFile) {
                    fieldContent = (
                      <Flex flexDirection="column">
                        {value.map((file, index) => {
                          const icon =
                            iconExtensionMap[file.extension] || 'file'

                          const sizeInMbs = (file.size / 1000000).toFixed(1)
                          const sizeInKbs = (file.size / 1000).toFixed(1)

                          const fileSize =
                            sizeInMbs < 1 ? `${sizeInKbs}kb` : `${sizeInMbs}mb`

                          return (
                            <a
                              key={index}
                              href={`${s3BaseUrl}/${file.path}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <Flex
                                backgroundColor={colors.white}
                                hoverBackgroundColor={colors.gray.lightest}
                                padding="10px"
                              >
                                <Block alignSelf="center" paddingRight="10px">
                                  <Icon
                                    name={icon}
                                    fontSize={26}
                                    lineHeight={1}
                                  />
                                </Block>
                                <Flex flexDirection="column" flexGrow={1}>
                                  <span>{`${file.name}.${file.extension}`}</span>
                                  <small style={styles.small}>{fileSize}</small>
                                </Flex>
                              </Flex>
                            </a>
                          )
                        })}
                      </Flex>
                    )
                  }

                  const isHtmlType = options.type === 'html'
                  const cellProps =
                    isHtmlType && value
                      ? { paddingTop: 0, paddingBottom: 0 }
                      : {}

                  if (skipped) {
                    fieldContent = '-'
                  }

                  return (
                    <div
                      key={`${fieldGroupNumber}-${fieldGroupNumber}-${fieldIndex}`}
                    >
                      <Table>
                        <tbody>
                          <tr>
                            <RowHeader width={300}>
                              {fieldtype !== 'image-display' && field.label}
                            </RowHeader>
                            <Cell {...cellProps}>{fieldContent || ''}</Cell>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )
    })}
  </div>
)

function hasVisibleFieldGroups(fieldGroups) {
  return some(fieldGroups, ({ fields }) =>
    some(fields, ({ fieldtype, options }) => {
      const showField = get(options, 'showOnRead', true)
      return showField
    })
  )
}

export default Radium(templateComponent)
