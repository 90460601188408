import React from 'react'

export default function Visibility(props) {
  const { children, visible = true } = props

  const styles = {
    visibility: visible ? 'visible' : 'hidden',
  }

  return <div style={styles}>{children}</div>
}
