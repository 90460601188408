import React from 'react'

import { Image } from 'components/common'

const DEFAULT_HEIGHT = 45
const DEFAULT_ALT = 'Lighthouse.io'
const DEFAULT_SRC =
  'https://assets-lighthouse-io.s3.amazonaws.com/img/lighthouse-icon-text-horizontal-white.svg'
const DEFAULT_MARGIN = 45
const DEFAULT_WIDTH = 200

export default function Logo(props) {
  const imageAlt = props.alt || DEFAULT_ALT
  const imageSrc = props.src || DEFAULT_SRC

  return (
    <Image
      alt={imageAlt}
      height={DEFAULT_HEIGHT}
      margin={DEFAULT_MARGIN}
      src={imageSrc}
      width={DEFAULT_WIDTH}
    />
  )
}
