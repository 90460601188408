import PropTypes from 'prop-types'
import React from 'react'
import Button from 'components/button'

export default function ButtonIcon(props) {
  const { children, dataTestId, onClick: handleClick, ...styleProps } = props

  return (
    <Button
      borderBottomLeftRadius={20}
      borderBottomRightRadius={20}
      borderTopLeftRadius={20}
      borderTopRightRadius={20}
      dataTestId={dataTestId}
      display="flex"
      height="auto"
      hoverBackgroundColor="none"
      onClick={handleClick}
      padding={5}
      {...styleProps}
    >
      {children}
    </Button>
  )
}

ButtonIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
}
