/*
 * Content Entries Module
 */

import { combineReducers } from 'redux'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../../app'

import buildActionCreators from './action-creators'

import crud from '../../../crud'
import hooks from './hooks'

const resource = 'content'
const subresource = 'entries'
const actionNamespace = `${resource}/${subresource}`
const statePath = `${resource}.${subresource}`

export { default as schema } from './schema'
export { syncEntry } from './helpers'

export const actions = crud.actions(actionNamespace)
hooks(actions)

export const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  applicationResourceUrlFn,
  applicationParamsFn,
  authorizationHeadersFn,
)

export const actionCreators = Object.assign(
  {},
  crudActionCreators,
  customActionCreators,
)

export const selectors = crud.selectors(statePath)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

export function baseUrlFn(params) {
  const baseUrl = applicationResourceUrlFn(`${resource}/${subresource}`)(params)

  return params.contentEntryId ?
    `${baseUrl}/${params.contentEntryId}` :
    `${baseUrl}`
}
