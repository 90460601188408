export const defaultStyles = {
  horizontalLine: {
    font: 'Gotham',
    fontSize: 1,
  },
  small: {
    font: 'Gotham',
    fontSize: 8,
    bold: false,
    color: '#777',
    lineHeight: 1.2,
  },
  table: {
    font: 'Gotham',
    fontSize: 8,
    lineHeight: 1.1,
    color: '#083B57',
    margin: [0, 0, 0, 0],
  },
  activitiesTable: {
    bold: true,
    font: 'Gotham',
    fontSize: 10,
    lineHeight: 1.1,
    margin: [0, 10, 0, 0],
    color: '#333',
  },
  commentsHeader: {
    bold: true,
    font: 'Gotham',
    margin: [0, 10, 0, 0],
  },
  groupHeaderTable: {
    bold: true,
    font: 'Gotham',
    fontSize: 10,
    lineHeight: 1.1,
    margin: [0, 10, 0, 0],
    color: '#555',
  },
  metaTable: {
    fontSize: 10,
    font: 'Gotham',
    lineHeight: 1.1,
    margin: [0, 0, 0, 20],
    color: '#333',
  },
  serviceLevelAboveOrOn: {
    bold: false,
    color: '#347D39',
    font: 'Gotham',
    fontSize: 14,
  },
  serviceLevelBelow: {
    bold: false,
    color: '#FB1607',
    font: 'Gotham',
    fontSize: 14,
  },
  subTitle: {
    bold: false,
    font: 'Gotham',
    fontSize: 10,
    color: '#777',
  },
  subTitle2: {
    font: 'Gotham',
    fontSize: 8,
    bold: false,
    color: '#083B57',
  },
  summaryCount: {
    alignment: 'center',
    font: 'Gotham',
    bold: true,
    fontSize: 20,
  },
  summaryHeaderTable: {
    bold: true,
    font: 'Gotham',
    fontSize: 14,
    lineHeight: 1.1,
    margin: [0, 10, 0, 0],
    color: '#777',
  },
  summarySectionHeader: {
    bold: false,
    font: 'Gotham',
    fontSize: 16,
    lineHeight: 1.1,
    margin: [0, 10, 0, 0],
    color: '#333',
  },
  summaryText: {
    alignment: 'center',
    font: 'Gotham',
    bold: true,
    fontSize: 7,
    margin: [0, 5, 0, 0],
  },
  summaryWrapperTable: {
    font: 'Gotham',
    margin: [0, 10, 0, 10],
  },
  title: {
    font: 'Gotham',
    color: '#333',
    fontSize: 16,
  },
  timelineTitle: {
    bold: true,
    font: 'Gotham',
    fontSize: 10,
    margin: 0,
    color: '#333',
  },
  titleTable: {
    font: 'Gotham',
    lineHeight: 1.1,
    margin: [0, 0, 0, 10],
  },
  totalAuditScore: {
    bold: false,
    color: '#777',
    font: 'Gotham',
    fontSize: 16,
    lineHeight: 0.9,
  },
  totalScore: {
    bold: true,
    color: '#555',
    font: 'Gotham',
    fontSize: 10,
    lineHeight: 1.1,
  },
  // display styles
  ol: {
    bold: false,
    color: '#083B57',
    fontSize: 8,
    margin: [10, 0, 10, 10],
  },
  p: {
    bold: false,
    color: '#083B57',
    fontSize: 8,
  },
  ul: {
    bold: false,
    color: '#083B57',
    fontSize: 8,
    margin: [10, 0, 10, 10],
  },
}
