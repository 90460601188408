import colors from 'config/theme/colors'

export default {
  headerAlignCentre: {
    justifyContent: 'center',
  },
  headerStyle: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 20,
    color: colors.gray.normal,
  },
  lastColumn: {
    textAlign: 'right',
  },
  rowAlignCentre: {
    textAlign: 'center',
  },
  rowErrorStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.red.lightest,
    borderBottom: `1px solid ${colors.gray.lightest}`,
    fontSize: 13,
    boxSizing: 'border-box',
  },
  rowAdditionStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.green.lightest,
    borderBottom: `1px solid ${colors.gray.lightest}`,
    fontSize: 13,
    boxSizing: 'border-box',
  },
  rowRemovalStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.gray.lightest,
    borderBottom: `1px solid ${colors.gray.lightest}`,
    fontSize: 13,
    boxSizing: 'border-box',
  },
  rowRemoveButtonStyle: {
    backgroundColor: 'transparent',
    color: '#545454',
    cursor: 'pointer',
    justifyContent: 'end',
    width: '100%',
  },
  rowStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${colors.gray.lightest}`,
    fontSize: 13,
    boxSizing: 'border-box',
  },
  scoreStyle: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  stickyHeader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    maxWidth: 1200,
    zIndex: 1000,
  },
  targetStyle: {
    paddingLeft: 10,
  },
  titleStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}
