import { pure } from 'recompose'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { Block, Flex, Span } from 'components/common'
import { colors } from 'config/theme'

export default pure(Message)

function Message({ date, name, text }) {
  return (
    <Flex flexDirection="column" padding="16px 32px">
      <Block fontSize={13} marginBottom={6}>
        <Span fontWeight={600}>{name}</Span>
        {date && (
          <Span color={colors.gray.light} fontSize={12} marginLeft={6}>
            {moment(date).format('h:mm a')}
          </Span>
        )}
      </Block>
      <Block fontSize={12}>{text}</Block>
    </Flex>
  )
}

Message.propTypes = {
  date: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
}
