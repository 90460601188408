import { FieldArray } from 'redux-form'
import React from 'react'
import { serviceHours } from '@lighthouse/common'
import { findIndex } from 'lodash'

import { Block, Flex } from 'components/common'
import { Table, TableHeader } from 'components/table-basic'
import ServiceHourCell from './components/service-hour-cell'
import ServiceHourRow from './components/service-hour-row'
import TitleBar from 'modules/title-bar'
import { mapFormToSchema } from './helpers/formatData'
import { useTranslation } from 'react-i18next'
import { TOOLTIPS } from 'config/constants'

export default function ServiceHoursEditor(props) {
  const { timezone } = props

  const { t } = useTranslation()

  return (
    <Flex>
      <Block flexGrow={1}>
        <TitleBar
          title={t('labelServiceHours')}
          tooltip={[
            t(TOOLTIPS.locationServiceHours.titleT),
            t(TOOLTIPS.locationServiceHours.messageT),
          ]}
          anchorDirection="right"
          justifyContent="start"
        />
        <Table>
          <TableHeader>
            <ServiceHourCell isHeader width="25%">
              {t('labelDayDate')}
            </ServiceHourCell>
            <ServiceHourCell isHeader width="25%">
              {t('labelOptions')}
            </ServiceHourCell>
            <ServiceHourCell isHeader width="20%">
              {t('labelOpenTime')}
            </ServiceHourCell>
            <ServiceHourCell isHeader width="20%">
              {t('labelCloseTime')}
            </ServiceHourCell>
            <ServiceHourCell isHeader width="10%" />
          </TableHeader>
          <FieldArray
            component={ServiceHourRow}
            name="serviceHours.hours"
            rerenderOnEveryChange
            timezone={timezone}
            validate={validateFieldRow}
          />
        </Table>
      </Block>
    </Flex>
  )
}

function validateFieldRow(serviceHoursFormRows) {
  const errorIndexes = []
  const { validateOverlappingHours } = serviceHours
  const hours = mapFormToSchema({ hours: serviceHoursFormRows })
  const defaultHours = hours.filter(hour => hour.type === 'DEFAULT')
  const overrideHours = hours.filter(hour => hour.type === 'OVERRIDE')
  const defaultOverlapIndex = validateOverlappingHours({
    hoursArr: defaultHours,
  })
  const overrideOverlapIndex = validateOverlappingHours({
    hoursArr: overrideHours,
    isUnix: true,
  })

  if (defaultOverlapIndex !== -1) {
    errorIndexes.push(findIndex(hours, defaultHours[defaultOverlapIndex]))
  }

  if (overrideOverlapIndex !== -1) {
    errorIndexes.push(findIndex(hours, overrideHours[overrideOverlapIndex]))
  }

  return errorIndexes.length ? errorIndexes : false
}
