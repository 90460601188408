import colors from 'config/theme/colors'

export default {
  root: {
    display: 'block',
    width: '100%',
    fontSize: '13px',
    lineHeight: '28px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: colors.blue.lighter,
    color: colors.blue.dark,
    backgroundColor: colors.blue.lightest,
    outline: 'none',
    marginBottom: '20px',
    padding: '12px 20px 10px 20px',
  },

  small: {
    fontSize: '12px',
  },

  success: {
    borderColor: colors.green.lighter,
    color: colors.green.dark,
    backgroundColor: colors.green.lightest,
    marginTop: 0,
  },

  error: {
    borderColor: colors.red.lighter,
    color: colors.red.dark,
    backgroundColor: colors.red.lightest,
    marginTop: 0,
  },

  warning: {
    borderColor: colors.yellow.lighter,
    color: colors.yellow.dark,
    backgroundColor: colors.yellow.lightest,
    marginTop: 0,
  },

  item: {
    display: 'block',
  },

  italicFontStyle: {
    fontStyle: 'italic',
  },
}
