import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'

import Templates from './components/main'
import { ProtectedRoute } from '../../components/protectedRoute'

import AuditsRoute from './routes/audits'
import ContentRoute from './routes/content'
import EntriesRoute from './routes/entries'
import TasksRoute from './routes/tasks'
import IssuesRoute from './routes/issues'

export default function TemplatesRoute() {
  return (
    <Templates>
      <Switch>
        <ProtectedRoute
          permissions={{ action: 'read', module: 'template' }}
          path="/templates/audits"
        >
          <AuditsRoute />
        </ProtectedRoute>
        <ProtectedRoute
          permissions={{ action: 'read', module: 'template' }}
          path="/templates/issues"
        >
          <IssuesRoute />
        </ProtectedRoute>
        <ProtectedRoute
          permissions={{ action: 'read', module: 'template' }}
          path="/templates/tasks"
        >
          <TasksRoute />
        </ProtectedRoute>
        <Route component={ContentRoute} path="/templates/content" />
        <Route component={EntriesRoute} path="/templates/entries" />\{' '}
        {/* NOTE default route */}
        <Redirect from="/templates" to="/templates/issues" />
      </Switch>
    </Templates>
  )
}
