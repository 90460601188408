import PropTypes from 'prop-types'
import React from 'react'

import { Block } from 'components/common'
import { withTranslation } from 'react-i18next'

export default withTranslation()(SearchMenu)
export { SearchMenu as SearchMenuForTest }

function SearchMenu(props) {
  const { children, t } = props

  return (
    <Block>
      <Block
        borderBottom="1px solid #EEE"
        borderLeft="1px solid #EEE"
        borderRight="1px solid #EEE"
        maxHeight="290px"
        overflow="auto"
      >
        {children}
      </Block>
      <Block
        background="#F9F9F9"
        borderBottom="1px solid #EEE"
        borderLeft="1px solid #EEE"
        borderRight="1px solid #EEE"
        color="#AAA"
        paddingTop="10px"
        paddingRight="20px"
        paddingBottom="10px"
        paddingLeft="20px"
        fontSize="10px"
      >
        {t('searchMenu.footerMessage')}
      </Block>
    </Block>
  )
}

SearchMenu.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func,
}
