/*
 * Activity Module
 */
import {
  assign,
  filter,
  isEmpty,
  memoize,
  orderBy,
  values,
} from 'lodash'

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import moment from 'moment'
import pluralize from 'pluralize'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import crud from '../../crud'
import hooks from './hooks'

const resource = 'activity'

const permissionMappings = {
  auditentries: 'auditEntry',
  loopexceptions: 'loopException',
  taskentries: 'taskEntry',
}

/**
 * getPermissionMapping
 * Activity 'types' and permission 'module' references follow a slightly
 * different format, so we have this little helper to convert between the two.
 * Some need to be camelCased so we keep a mapping reference, but for anything
 * else we can use the pluralize module
 * @param {String} str the value to get mapping for
 */
export function getPermissionMapping(str) {
  const mapping = permissionMappings[str]
  return mapping || pluralize.singular(str)
}

export const actions = crud.actions(resource)

hooks(actions)

export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn(resource),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const crudReducers = crud.reducers(actions)

const crudSelectors = crud.selectors(resource)

const filterListByType = createSelector(
  [crudSelectors.list],
  activities => memoize(options => {
    const {
      before,
      listId,
      type,
    } = options

    const listActivities = activities(listId)

    const matchingActivities = filter(listActivities, ({ entity }) => {
      const {
        timestamp: activityTimestamp,
        type: activityType,
        whitelist: activityWhitelist,
      } = entity

      const isType = type ? activityType === type : true

      // only return activities that were sent to all users
      const isGlobalActivity = activityWhitelist
        ? isEmpty(activityWhitelist)
        : true

      // only return activities which are before or the same before timestamp
      const isTimestampSameOrBefore = before
        ? moment(activityTimestamp).isSameOrBefore(before)
        : true

      return isGlobalActivity && isTimestampSameOrBefore && isType
    })

    return orderBy(matchingActivities, ['entity.timestamp'], ['desc'])
  }, options => values(options).join('-'))
)

export const selectors = assign(
  {},
  crudSelectors,
  {
    filterListByType,
  },
)

export const reducer = combineReducers({
  ...crudReducers,
})
