import { pure } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { colors } from 'config/theme'
import { Block, Flex } from 'components/common'

export default pure(DropdownItem)

function DropdownItem(props) {
  const { height, left, onClick, position, text, top } = props

  return (
    <Flex
      alignItems="center"
      backgroundColor={colors.white}
      borderTopColor={colors.gray.lighter}
      borderTopStyle="solid"
      borderTopWidth={1}
      cursor={onClick ? 'cursor' : 'default'}
      height={height}
      hoverBackgroundColor={onClick ? colors.gray.lightest : 'inherit'}
      justifyContent="space-between"
      left={left}
      onClick={onClick}
      overflow="hidden"
      position={position}
      top={top}
      width="100%"
    >
      <Block
        overflow="hidden"
        paddingLeft={12}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {text}
      </Block>
    </Flex>
  )
}

DropdownItem.propTypes = {
  height: PropTypes.number,
  left: PropTypes.number,
  onClick: PropTypes.func,
  position: PropTypes.string,
  text: PropTypes.string.isRequired,
  top: PropTypes.number,
}
