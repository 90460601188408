import {
  find,
  reject,
} from 'lodash'

import {
  actions,
} from '../'
import { parseState } from '../../../../helpers'

export default function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case actions.MARK_AS_READ_REQUEST:
      const roomExists = state.cache && state.cache[action.roomId]
      if (!roomExists) return state

      // optimistically set the marked as read flag
      const newState = state.updateIn(
        ['cache', action.roomId, 'entity', 'speakers'],
        (speakers) => {
          if (!speakers) return []

          // TODO this feels very clunky, but I couldn't figure out a way to
          // update an item within an array using seamless immutable. So instead
          // we pull it out of the array, update it and push it back in. Not
          // ideal
          const speaker = find(speakers, { speaker: action.speakerId })
          const updatedSpeaker = speaker.set('lastRead', action.data.timestamp)
          let newSpeakers = reject(speakers, { speaker: action.speakerId })
          newSpeakers = [updatedSpeaker].concat(newSpeakers)
          return newSpeakers
        },
      )
      return newState
    default:
      return state
  }
}
