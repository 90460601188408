import colorsConfig from './colors'

// this should be configurable based on settings
// of the applciation

export const logoUrl = null
export const colors = {
  text: colorsConfig.gray.normal,
  primary: colorsConfig.blue.normal,
  secondary: colorsConfig.blue,
  success: colorsConfig.green,
  info: colorsConfig.blue,
  warning: colorsConfig.orange.normal,
  danger: colorsConfig.red,
}
