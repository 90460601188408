import { capitalize, get } from 'lodash'
import React from 'react'

import Block from 'components/common/block'
import Span from 'components/common/span'
import colors from 'config/theme/colors'
import styles from './styles'

interface Props {
  onClear: () => void
  tag: { label: string; typeLabel: string }
}

const Tag = (props: Props): JSX.Element => {
  const { onClear, tag } = props
  const { label, typeLabel } = tag

  return (
    <Block
      backgroundColor={colors.white}
      color={colors.gray.light}
      display="flex"
      fontSize={12}
      height={36}
      marginBottom={6}
      marginLeft={0}
      marginRight={6}
      marginTop={0}
    >
      <Span
        {...styles.clearIcon}
        borderRight={`1px solid ${colors.gray.lightest}`}
        cursor="pointer"
        lineHeight="36px"
        height={36}
        paddingLeft={7}
        paddingRight={7}
        textAlign="center"
        onClick={onClear}
      >
        x
      </Span>
      <Span height={36} lineHeight="36px" paddingLeft={15} paddingRight={15}>
        <Span fontWeight={600}>
          {capitalize(typeLabel)}:
          <Span fontWeight={400} paddingLeft={4}>
            {label}
          </Span>
        </Span>
      </Span>
    </Block>
  )
}

export default Tag
