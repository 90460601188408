import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { find, get, keys, map, reject, sortBy } from 'lodash'

import styles from './styles'

// Components
import NavItem from 'components/nav-item'
import Dropdown from 'components/dropdown'
import Caret from 'components/caret'
import compose from 'recompose/compose'

// NOTE: Do no compose this directly in the `map` iterator below, as this
// will instantiate a new component for this on each loop and break
// key'd components
const DropdownNavItem = Dropdown(NavItem)

class NavList extends Component {
  static propTypes = {
    // TODO shape the items
    items: PropTypes.object,
    active: PropTypes.string,
    borders: PropTypes.string,
    type: PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'timezone',
      'help',
    ]),
    children: PropTypes.element,
    user: PropTypes.object,
  }

  shouldComponentUpdate(nextProps) {
    // NOTE we're checking each conditional in turn to avoid any
    // unnecessary operations when a simpler conditional check has
    // already returned true
    const activeHasChanged = this.props.active !== nextProps.active

    if (activeHasChanged) {
      return true
    }

    const itemsLengthHasChanged =
      keys(this.props.items).length !== keys(nextProps.items).length

    if (itemsLengthHasChanged) {
      return true
    }

    const itemHasChanged = find(
      this.props.items,
      (item, key) => item !== nextProps.items[key]
    )

    if (itemHasChanged) {
      return true
    }

    return false
  }

  render() {
    const linkStyles = [styles.link]
    const { active, borders, children, items, type, user } = this.props

    if (borders) {
      linkStyles.push(styles.linkBordered.base)
      linkStyles.push(styles.linkBordered[borders])
    }

    if (type === 'help') {
      linkStyles.push(styles.linkNarrow)
      linkStyles.push(styles.linkBlue)
    }

    if (type === 'timezone') {
      linkStyles.push(styles.linkNarrow)
    }

    const navItems = map(items, (item, key) => {
      // Wrap nav item in dropdown if it has option
      const NavComponent = item.dropdown ? DropdownNavItem : NavItem
      const onClick = item.onClick || get(item, 'dropdown.onClick')
      const isIndexRoute = !active
      const isActive = isIndexRoute ? item.index : key === active
      const options = reject(get(item, 'dropdown.options'), {
        disabled: true,
      })

      return (
        <NavComponent
          dataTestId={item.testId || 'navItem-' + item.label}
          key={key}
          order={item.order}
          options={options}
          align={get(item, 'dropdown.align')}
          onClick={onClick}
          style={linkStyles}
          active={isActive}
          link={item.link}
          type={type}
          title={item.label}
          icon={item.icon}
          navItemType={key}
          user={user}
        >
          {children || item.label}
          {item.caret && <Caret down />}
        </NavComponent>
      )
    })

    return (
      <div id="subnav-bar" style={[styles[`${type}Wrapper`]]}>
        <nav style={[styles.root, styles[type]]}>
          {sortBy(navItems, 'props.order')}
        </nav>
      </div>
    )
  }
}

export default compose(connect(mapStateToProps), Radium)(NavList)

function mapStateToProps(state, props) {
  return {
    user: get(state, 'user.data'),
  }
}
