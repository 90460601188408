import { fetchImage, getTimezoneDatetime } from '../../../helpers'

export function defaultHeader({ logoUrl, timestamp, timezone }) {
  const datetime = getTimezoneDatetime({
    format: 'ddd, Do MMM YYYY',
    showTzAbbr: false,
    timestamp,
    timezone,
  })

  return fetchImage(logoUrl, { isHeader: true }).then(image => ({
    columns: [
      {
        fit: [130, 30],
        image,
        margin: [60, 60, 60, 0],
      },
      {
        alignment: 'right',
        color: '#999',
        font: 'Gotham',
        fontSize: 11,
        text: datetime,
        margin: [60, 70, 60, 0],
      },
    ],
  }))
}
