import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from 'recompose'
import {
  chain,
  filter,
  find,
  get,
  isEmpty,
  map,
  take,
  toLower,
  toNumber,
  trim,
} from 'lodash'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { withRouter } from 'react-router-dom'
import Promise from 'bluebird'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'

import { Block, Flex } from 'components/common'
import { colors, typography } from 'config/theme'
import emitter from 'utils/emitter'
import geocoder from 'utils/geocoder'
import Search from 'components/search'

import { SearchItem, SearchMenu } from './components'
import { useTranslation } from 'react-i18next'

const areaModule = getModule('areas')
const color = colors.gray.darker
const fontFamily = typography.defaultFontFamily
const groupOrdering = { location: 0, address: 1 }

export default compose(
  connect(mapStateToProps),
  withRouter,
  withState('isLoading', 'setIsLoading', false),
  withState('items', 'setItems', []),
  withState('noResults', 'setNoResults', false),
  withState('recentItems', 'setRecentItems', []),
  withState('selectedItem', 'setSelectedItem', null),
  withProps(buildProps),
  withHandlers({
    fetchLocations,
    itemToString,
    setNextItems,
    setNextRecentItems,
  }),
  withHandlers({
    handleClearSelection,
    handleDownshiftSelection,
    handleEmitterSelection,
    handleInputValueChange,
  }),
  lifecycle({ componentDidMount, componentWillUnmount })
)(LocationSearch)

export {
  LocationSearch as LocationSearchForTest,
  mapStateToProps as mapStateToPropsForTest,
}

function LocationSearch(props) {
  const {
    handleClearSelection,
    handleDownshiftSelection,
    handleInputValueChange,
    isLoading,
    items,
    itemToString,
    noResults,
    recentItems,
    selectedItem,
  } = props

  const { t } = useTranslation()

  return (
    <Flex
      borderRight="1px solid #EEE"
      color={color}
      flexDirection="column"
      fontFamily={fontFamily}
      width={500}
    >
      <Search
        isLoading={isLoading}
        itemToString={itemToString}
        onClearSelection={handleClearSelection}
        onInputValueChange={handleInputValueChange}
        onSelection={handleDownshiftSelection}
        placeholder={t('placeholder.searchLocations')}
        selectedItem={selectedItem}
        zIndex={1200}
      >
        {({
          getMenuProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          inputValue,
          selectedItem,
        }) => {
          if (!isOpen) return null

          const recentMenu = buildRecentMenu({
            getItemProps,
            highlightedIndex,
            inputValue,
            items: recentItems,
            selectedItem,
            t,
          })

          const resultsMenu =
            !recentMenu &&
            buildResultsMenu({
              getItemProps,
              highlightedIndex,
              inputValue,
              items,
              noResults,
              selectedItem,
              t,
            })

          return (
            <div {...getMenuProps()}>
              {recentMenu}
              {resultsMenu}
            </div>
          )
        }}
      </Search>
    </Flex>
  )
}

function buildRecentMenu(props) {
  const {
    getItemProps,
    highlightedIndex,
    inputValue,
    items,
    selectedItem,
    t,
  } = props

  const showMenu =
    (!inputValue &&
      (!selectedItem || (selectedItem && selectedItem.label !== inputValue))) ||
    (!!inputValue && !!selectedItem && selectedItem.label === inputValue)

  if (!showMenu) return null

  const searchItems = map(items, (item, index) => (
    <SearchItem
      getItemProps={getItemProps}
      highlightedIndex={highlightedIndex}
      index={index}
      item={item}
      key={index}
      selectedItem={selectedItem}
    />
  ))

  return (
    <SearchMenu>
      <Block
        background="#FFF"
        color="#AAA"
        fontSize="12px"
        paddingLeft="20px"
        paddingRight="20px"
      >
        <Block paddingBottom="5px" paddingTop="10px">
          {t('searchMenu.header.recent')}
        </Block>
        {!searchItems.length && (
          <Block paddingBottom="10px" paddingTop="5px">
            {t('searchMenu.noRecentLocations')}
          </Block>
        )}
      </Block>
      {searchItems}
    </SearchMenu>
  )
}

function buildResultsMenu(props) {
  const {
    getItemProps,
    inputValue,
    items,
    highlightedIndex,
    noResults,
    selectedItem,
    t,
  } = props

  const matchingItems = matchItemsBySearchOrLabel(items, inputValue)
  const showMenu = !isEmpty(matchingItems) || noResults

  if (!showMenu) return null

  const searchItems = map(matchingItems, (item, index) => (
    <SearchItem
      getItemProps={getItemProps}
      highlightedIndex={highlightedIndex}
      index={index}
      item={item}
      key={index}
      selectedItem={selectedItem}
    />
  ))

  return (
    <SearchMenu>
      <Block
        background="#FFF"
        color="#AAA"
        fontSize="12px"
        paddingLeft="20px"
        paddingRight="20px"
      >
        {noResults && (
          <Block paddingBottom="10px" paddingTop="10px">
            {t('searchMenu.noResultsFound')}
          </Block>
        )}
      </Block>
      {searchItems}
    </SearchMenu>
  )
}

function componentDidMount() {
  const { applicationId, handleEmitterSelection, setRecentItems } = this.props

  // emitter handlers
  emitter.on('search:set-location', handleEmitterSelection)

  // session storage
  const localRecentItems = sessionStorage.getItem(
    `lio:${applicationId}:recentItems`
  )

  if (!localRecentItems) return
  setRecentItems(JSON.parse(localRecentItems))
}

function componentWillUnmount() {
  const { applicationId, handleEmitterSelection, recentItems } = this.props

  // emitter handlers
  emitter.off('search:set-location', handleEmitterSelection)

  // session storage
  if (isEmpty(recentItems)) return
  sessionStorage.setItem(
    `lio:${applicationId}:recentItems`,
    JSON.stringify(recentItems)
  )
}

function buildProps(props) {
  const { locations, recentItems } = props
  return {
    recentItems: filter(recentItems, (item = {}) => {
      const { group, value } = item

      if (group !== 'location') return true

      // NOTE ensure area is in state (i.e hasn't been deleted)
      const matchingLocation = find(locations, { id: value })
      return matchingLocation
    }),
  }
}

function fetchAddressLocations(inputValue) {
  return geocoder
    .forward({
      addressdetails: 0,
      text: inputValue,
    })
    .then(response => map(response, mapAddressToItem))
    .then(items => take(items, 10))
}

function fetchLocations(props) {
  const { locations } = props

  return inputValue =>
    Promise.resolve(locations)
      .then(response => map(response, mapLocationToItem))
      .then(items => matchItemsBySearchOrLabel(items, inputValue))
      .then(items => take(items, 20))
}

function handleInputValueChange(props) {
  const {
    fetchLocations,
    isLoading,
    setIsLoading,
    setNextItems,
    setNoResults,
  } = props

  return (value, { selectedItem }) => {
    if (!value) return Promise.resolve()

    // NOTE: input value will change when a selected item
    // is set so check if the same and return if so
    if (selectedItem && selectedItem.label === value) {
      return Promise.resolve()
    }

    const trimmedValue = trim(value)

    setIsLoading(true)
    setNoResults(false)

    return Promise.all([
      fetchAddressLocations(trimmedValue),
      fetchLocations(trimmedValue),
    ])
      .then(setNextItems)
      .then(nextItems => {
        if (!isEmpty(nextItems)) return
        setNoResults(true)
      })
      .finally(() => setIsLoading(false))
  }
}

// NOTE: emitter event set selection handler
function handleEmitterSelection(props) {
  const { items, setNextItems, setNextRecentItems, setSelectedItem } = props

  return payload => {
    if (!payload) return

    const selectedItem = {
      group: 'location',
      icon: 'building',
      ...payload,
    }

    setNextItems([selectedItem, ...items])
    setNextRecentItems(selectedItem)
    setSelectedItem(selectedItem)
  }
}

function handleClearSelection(props) {
  const { history, setSelectedItem } = props

  return () => {
    history.push({ search: '' })
    setSelectedItem(null)
  }
}

// NOTE: downshift set selection handler
function handleDownshiftSelection(props) {
  const { history, setNextRecentItems, setSelectedItem } = props

  return selectedItem => {
    // NOTE: if we clear an item
    // reset the url search state
    if (!selectedItem) {
      return history.push({ search: '' })
    }

    setNextRecentItems(selectedItem)
    setSelectedItem(selectedItem)

    if (selectedItem.group === 'address') {
      const nextSearch = queryString.stringify({
        id: selectedItem.value,
        lat: selectedItem.lat,
        lng: selectedItem.lon,
        resource: 'address',
        showMarker: true,
        title: selectedItem.label,
      })

      // NOTE: update url search to show temp marker
      history.push({ search: `?${nextSearch}` })

      // TODO: the url query updates will also
      // set the map center position but having
      // to set her to prevent a race condition
      emitter.emit('map:set-properties', {
        center: {
          coordinates: [toNumber(selectedItem.lon), toNumber(selectedItem.lat)],
          type: 'Point',
        },
        zoom: 12,
      })
    }

    if (selectedItem.group === 'location') {
      emitter.emit('map:set-location', {
        id: selectedItem.value,
        geometry: selectedItem.geometry,
        openSidebar: true,
      })
    }
  }
}

function isMatch(string1, string2) {
  const lowerString1 = toLower(trim(string1))
  const lowerString2 = toLower(trim(string2))

  return lowerString1.includes(lowerString2)
}

function itemToString(props) {
  return item => (item ? item.label : '')
}

function mapAddressToItem(address) {
  return {
    group: 'address',
    icon: 'map-target',
    importance: address.importance,
    label: address.display_name,
    lat: address.lat,
    lon: address.lon,
    value: address.osm_id,
  }
}

function mapLocationToItem(location) {
  const { entity } = location
  const sin = get(entity, 'plugins.timegate.options.SiteSIN', null)
  const jobNumber = get(entity, 'plugins.winteam.options.jobNumber', null)
  const ref = get(entity, 'reference', null)
  const reference = sin
    ? `SIN: ${sin}`
    : jobNumber
    ? `Job #${jobNumber}`
    : ref
    ? `Ref: #${ref}`
    : null

  return {
    geometry: entity.geometry,
    group: 'location',
    icon: 'building',
    label: entity.name,
    search: entity.search,
    value: entity._id,
    reference: reference,
  }
}

function mapStateToProps(state) {
  const areaSelectors = areaModule.selectors(state)()

  return {
    applicationId: state.app.applicationId,
    locations: areaSelectors.byType('location'),
  }
}

function matchItemsBySearchOrLabel(items, inputValue) {
  return filter(items, ({ group, label, search }) => {
    if (group === 'address') return true
    return isMatch(search || label, inputValue)
  })
}

function setNextItems(props) {
  const { setItems } = props

  return items => {
    const nextItems = chain(items)
      .flatten()
      .uniqBy('value')
      .orderBy([sortItemByGroup, 'importance', 'label'], ['asc', 'desc', 'asc'])
      .value()

    setItems(nextItems)

    return nextItems
  }
}

function setNextRecentItems(props) {
  const { recentItems, setRecentItems } = props

  return selectedItem => {
    const nextItems = chain([selectedItem, ...recentItems])
      .flatten()
      .uniqBy('value')
      .take(10)
      .value()

    setRecentItems(nextItems)

    return nextItems
  }
}

function sortItemByGroup(item) {
  const order = groupOrdering[item.group]
  return order
}

LocationSearch.propTypes = {
  fetchLocations: PropTypes.func.isRequired,
  handleInputValueChange: PropTypes.func.isRequired,
  handleDownshiftSelection: PropTypes.func.isRequired,
  handleEmitterSelection: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func.isRequired,
  noResults: PropTypes.bool.isRequired,
  recentItems: PropTypes.array.isRequired,
  selectedItem: PropTypes.object,
  setIsLoading: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  setNoResults: PropTypes.func.isRequired,
  setRecentItems: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
}
