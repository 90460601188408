import { get, includes, set } from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers, withProps, withState } from 'recompose'
import { getModule } from '@lighthouse/sdk'
import React from 'react'

import { getDateInTimezone } from 'helpers/datetime'
import Button from 'components/button'
import Dropdown from 'components/dropdown'
import ExportConfirmation from 'components/export-confirmation'
import * as logger from 'utils/logger'
import Icon from 'components/icon'
import Spinner from 'components/spinner/dots'

const userApplicationsModule = getModule('userApplications')

export default compose(
  connect(mapStateToProps),
  withState('formatValue', 'setFormatValue'),
  withState('exportStatus', 'setExportStatus'),
  withHandlers({
    checkExport,
    exportData,
  }),
  withProps(props => ({
    exportButton: exportButton(props),
    exportConfirmation: exportConfirmation(props),
  }))
)

function checkExport(props) {
  const { list, setExportStatus } = props

  return format => {
    const items = get(list, 'items', [])
    const totalCount = items.length

    if (format && format === 'pdf' && totalCount > 1000) {
      return setExportStatus('warningPdf')
    }

    if (totalCount > 500000) {
      return setExportStatus('warning')
    }
    return setExportStatus('confirm')
  }
}

function exportButton(props) {
  const { checkExport, config, exportStatus, list, setFormatValue } = props

  const items = get(list, 'items', [])
  const totalCount = items.length
  const reportFormKeys = ['auditEntries', 'issues', 'taskEntries']
  const isFormReport = includes(reportFormKeys, config.key)

  const formatOptions = [
    {
      dataTestId: 'pdf-button',
      label: 'PDF',
      onClick: () => {
        setFormatValue('pdf')
        checkExport('pdf')
      },
    },
    {
      dataTestId: 'excel-button',
      label: 'Excel',
      onClick: () => {
        setFormatValue('xlsx')
        checkExport('xlsx')
      },
    },
  ]

  const DropdownButton = props => (
    <Button
      {...props}
      theme="noMargin positive largeIcon"
      disabled={!totalCount}
    >
      {exportStatus === 'processing' ? (
        <Spinner size="small" theme="inverted" />
      ) : (
        <Icon name="download" />
      )}
    </Button>
  )

  const ExportDropdown = Dropdown(DropdownButton)

  return (
    <>
      {isFormReport ? (
        <ExportDropdown align={'right'} options={formatOptions} />
      ) : (
        <Button
          disabled={!totalCount}
          theme="noMargin positive largeIcon"
          onClick={checkExport}
        >
          {exportStatus === 'processing' ? (
            <Spinner size="small" theme="inverted" />
          ) : (
            <Icon name="download" />
          )}
        </Button>
      )}
    </>
  )
}

function exportConfirmation(props) {
  const { exportData, exportStatus, list, setExportStatus, t } = props

  const items = get(list, 'items', [])
  const totalCount = items.length

  return ExportConfirmation({
    state: exportStatus,
    totalCount,
    confirmHandler: exportData,
    cancelHandler: () => setExportStatus(''),
    t,
  })
}

function exportData(props) {
  const {
    exportDateFormat,
    exportConfig,
    listId,
    listFilters,
    createDataExport,
    setExportStatus,
    timezone,
    title: reportTitle,
    formatValue = 'xlsx',
    flags,
  } = props

  const { model, dateField, title, excludeDeleted } = exportConfig

  return () => {
    // get filter conditions without from/to dates. Seamless Immutable does not
    // handle dates well in methods like asMutable, so this guards against that by
    // exluding them which is fine as they are handled manually anyway
    const conditions =
      model === 'shifts' && listFilters.status
        ? listFilters.without('from', 'to', 'status').asMutable()
        : listFilters.without('from', 'to').asMutable()
    const params = {
      dateFormat: exportDateFormat,
      format: formatValue,
      model,
      title,
      description: reportTitle,
      timezone,
    }

    if (excludeDeleted) {
      conditions.deleted = { $ne: true }
    }

    if (model === 'shifts' && listFilters.status) {
      const { from, status } = listFilters
      if (status && status.includes('open') && !status.includes('closed')) {
        conditions['end.time'] = { $exists: false }
      }

      if (
        from &&
        status &&
        !status.includes('open') &&
        status.includes('closed')
      ) {
        conditions['end.time'] = { from: new Date(from) }
      }
    }

    if (model === 'events' && !flags.exitEvents) {
      conditions.type =
        conditions.type && conditions.type.length > 0
          ? conditions.type.filter(type => type !== 'exit')
          : ['enter', 'geo']
    }

    if (model === 'auditEntries' && listFilters.targetServiceLevel) {
      const { targetServiceLevel } = listFilters
      if (
        targetServiceLevel === 'above' ||
        targetServiceLevel === 'on' ||
        targetServiceLevel === 'below'
      ) {
        conditions['targetServiceLevel'] = targetServiceLevel
      }
    }

    if (listFilters.from) {
      const tzFrom = getDateInTimezone(listFilters.from, timezone)
      const from = tzFrom.toDate()

      // NOTE don't use set here because of potential dot-notation in dateField
      conditions[dateField] = conditions[dateField] || {}
      conditions[dateField].from = from
    }

    if (listFilters.to) {
      const tzTo = getDateInTimezone(listFilters.to, timezone)
      const to = tzTo.toDate()

      // NOTE don't use set here because of potential dot-notation in dateField
      conditions[dateField] = conditions[dateField] || {}
      conditions[dateField].to = to
    }
    // set the conditions for export based on the filters set
    // NOTE we omit from/to as these are handled manually
    set(params, 'query.conditions', conditions)

    setExportStatus('processing')

    return createDataExport(listId, params)
      .then(response => {
        if (response.error) {
          logger.error(response.error)
          return setExportStatus('error')
        }

        return setExportStatus('success')
      })
      .catch(error => {
        logger.error(error)
        return setExportStatus('error')
      })
  }
}

function mapStateToProps(state) {
  const currentApplication = userApplicationsModule.getCurrentApplication(state)
  const exportDateFormat = get(
    currentApplication,
    'application.settings.exportDateFormat'
  )
  return {
    exportDateFormat,
    timezone: state.app.timezone,
  }
}
