import { get, noop } from 'lodash'
import React from 'react'

import { Block, Flex } from 'components/common'
import Chevron from 'components/chevron'
import MaterialCheckbox from 'components/form/material-check-box'
import colors from 'config/theme/colors'

export interface Item {
  label: string
  selectable?: boolean
  multiSelect?: boolean
  search?: string
  type: string
  typeLabel?: string
  value: string
  hasChildren: boolean
}

interface Props {
  item: Item
  onDrillDown: Function
  onSelect: Function
  selected: boolean
  styles: object
}

function BabushkaMenuItem(props: Props): JSX.Element {
  const { item, onDrillDown, onSelect, selected, styles } = props
  const { typeLabel } = item
  const hasChildren = get(item, 'hasChildren', true)
  const multiSelect = get(item, 'multiSelect', true)
  const label = get(item, 'label', '')
  const dataTestId = label.toLowerCase()

  return (
    <Flex
      {...styles}
      alignItems="center"
      borderTop={0}
      color={colors.gray.light}
      cursor="pointer"
      dataTestId={`${dataTestId}-filter`}
      flexDirection="row"
      fontSize={12}
      hoverBackgroundColor={colors.gray.lightest}
      outline="none"
      overflow="hidden"
      width="100%"
    >
      <Flex cursor="pointer" flexGrow={1} height="100%" title={label}>
        <Flex
          alignItems="center"
          borderBottomColor={colors.gray.lighter}
          borderBottomStyle="solid"
          borderBottomWidth={1}
          flex={1}
          onClick={(): void => (onSelect ? onSelect(item) : onDrillDown(item))}
          paddingLeft={16}
        >
          {onSelect && multiSelect && (
            <MaterialCheckbox
              customStyles={{ pointerEvents: 'none' }}
              checked={selected}
              onChange={noop}
            />
          )}
          <Block
            whiteSpace="nowrap"
            width={170}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {typeLabel && `${typeLabel}: `}
            {label}
          </Block>
        </Flex>
        {onDrillDown && !selected && hasChildren && (
          <Flex
            alignItems="center"
            borderBottomColor={colors.gray.lighter}
            borderBottomStyle="solid"
            borderBottomWidth={1}
            borderLeftColor={colors.gray.lighter}
            borderLeftStyle="solid"
            borderLeftWidth={1}
            justifyContent="center"
            onClick={(): void => onDrillDown(item)}
            width="14%"
          >
            <Chevron right thin />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default BabushkaMenuItem
