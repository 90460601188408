import { get } from 'lodash'

import {
  isLength,
  isRequired,
  validate,
} from '../../validation'

const latitudePredicate = (value, allValues) => !!get(allValues, 'geo.lon')
const longitudePredicate = (value, allValues) => !!get(allValues, 'geo.lat')

const latitudeError = 'This is a required field when Longitude defined'
const longitudeError = 'This is a required field when Latitude defined'

const isLatitudeRequired = validate(isRequired(), latitudeError, latitudePredicate)
const isLongitudeRequired = validate(isRequired(), longitudeError, longitudePredicate)

export default {
  name: {
    label: 'Name',
    name: 'name',
    placeholder: 'Name',
    required: true,
    validate: [isRequired()],
  },
  addressStreet: {
    label: 'Street',
    name: 'address.street',
    placeholder: 'Street Address',
    validate: [isLength({
      min: 0,
      max: 40,
    })],
  },
  addressCity: {
    label: 'City',
    name: 'address.city',
    placeholder: 'City',
  },
  addressState: {
    label: 'State',
    name: 'address.state',
    placeholder: 'State',
    required: true,
    validate: [isRequired()],
  },
  addressPostalCode: {
    label: 'Post Code',
    name: 'address.postalCode',
    placeholder: 'Post code',
  },
  addressCountry: {
    label: 'Country',
    name: 'address.country',
    placeholder: 'Select Country',
    required: true,
    validate: [isRequired()],
  },
  timezone: {
    label: 'Timezone',
    name: 'timezone',
    placeholder: 'Select Timezone',
    required: true,
    validate: [isRequired()],
  },
  longitude: {
    label: 'Longitude',
    name: 'geo.lon',
    placeholder: 'Longitude',
    validate: [isLongitudeRequired],
  },
  latitude: {
    label: 'Latitude',
    name: 'geo.lat',
    placeholder: 'Latitude',
    validate: [isLatitudeRequired],
  },
  settingsMapZoomLevel: {
    label: 'Map Zoom Level',
    name: 'settings.map.zoomLevel',
    placeholder: 'Zoom',
    type: 'number',
  },
}
