/**
 * A module that check permissions for you.
 * @module ask
 */
import { isEmpty, filter, includes, every, find, map } from 'lodash'
import logger from '../logger'

// Check fields for `create`, `read` and `update`. `delete` dose not make any sense for `fields`.
const validators = {
  create: checkWrite,
  read: checkRead,
  update: checkWrite
}

/**
 * Check if the given module and its filters have permissions for an action on `field`.
 * @param  {Array<Object>}   permissions An array of permission objects.
 * @param  {String}   action   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   options Options for permission check.
 * @return permissions or false if access denied
 * @see module:check-permissions~ask
 */
export default function checkFields(permissions, action, options) {
  const fieldPermissions = filter(permissions, {
    module: options.module,
    type: 'field'
  })

  const validator = validators[action]
  if (validator instanceof Function) {
    return validator(action, options, fieldPermissions)
  }

  // Ignore if no validator is found
  return ['*']
}

/**
 * Check `read` permission for this action
 * @param  {String}     action            Action type, it's always 'read' here.
 * @param  {Object}     options           Options for permission check.
 * @param  {Array<Object>}   fieldPermissions  An array of permission objects, only for `field`.
 * @param  {Function}   next              A Callback function for the next step.
 * @return None
 */
function checkRead(action, options, fieldPermissions) {
  const omitList = filter(fieldPermissions, permission => !includes(permission.access, 'read'))

  if (isEmpty(omitList)) {
    return ['*']
  }

  const fields = map(omitList, permission => `-${permission.value}`)

  return fields
}

/**
 * Check `create` or `update` permission for this action
 * @param  {String}   action            Action type.
 * @param  {Object}   options           Options for permission check.
 * @param  {Array<Object>} fieldPermissions  An array of permission objects, only for `field`.
 * @return None
 */
function checkWrite(action, options, fieldPermissions) {
  options.body = options.body || {}

  if (isEmpty(fieldPermissions) || hasPermission(options.body, fieldPermissions, action)) {
    // Return fields for `read` permissions so that the controller can just return modified results with permitted fields.
    return checkRead(action, options, fieldPermissions)
  }

  logger('Access Denied')
  return false
}

/**
 * Check if the body has field permissions for this action
 * @param  {Object}   body             The data to be checked.
 * @param  {Array<Object>} fieldPermissions An array of permission objects, only for `field`.
 * @param  {String}   action           Action type.
 * @return {Boolean}                   Return true if all the fields of the body have permission for this action, otherwise return false.
 */
function hasPermission(body, fieldPermissions, action) {
  return every(body, (value, name) => {
    const permission = find(fieldPermissions, {
      value: name
    })

    if (!permission || includes(permission.access, action)) {
      return true
    }
  })
}
