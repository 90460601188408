import measurements from 'config/theme/measurements'

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    maxWidth: measurements.fixedWidth,
    margin: '0 auto',
    paddingTop: 15,
    paddingRight: 0,
    paddingBottom: 15,
    paddingLeft: 0,
    clear: 'both',
    height: '100%',
    flexGrow: 1,
    boxSizing: 'border-box',
  },
}
