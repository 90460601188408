import { registerPosthook } from 'redux-hook-middleware'

import { SEND_TRACKING } from '../../../constants'

import {
  setListFiltersDataFn,
} from '../tracking'

export default function hooks(actions) {
  registerPosthook(actions.SET_LIST_FILTERS, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: setListFiltersDataFn,
      hookedAction: action,
    })
  })
}
