import PropTypes from 'prop-types'
/*
 * Spinner
 * =======
 */

import Radium from 'radium'
import React from 'react'
import CircleSpinner from './circle'
import DotsSpinner from './dots'

export default Radium(Spinner)

function Spinner(props) {
  return props.type === 'dots' ? (
    <DotsSpinner {...props} />
  ) : (
    <CircleSpinner {...props} />
  )
}

Spinner.propTypes = {
  type: PropTypes.oneOf(['circle', 'dots']),
}
