import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getModule, validation } from '@lighthouse/sdk'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Field, getFormSyncErrors, reduxForm } from 'redux-form'

import { get } from 'lodash'

import colors from 'config/theme/colors'

import Alert from 'components/alert'
import ButtonGroup from 'components/button-group'

import { Crud } from 'components/controls'

import { BasicForm, InputText } from 'components/form'

import {
  getErrorCount,
  handleFormError,
  handleSaveResponse,
} from 'components/form/helpers'

import { Block } from 'components/common'
import i18next from 'i18next'

const contentEntries = getModule('content', 'entries')
const areas = getModule('areas')

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

class NewContentEntryForm extends Component {
  static propTypes = {
    id: PropTypes.string,
    submitCallback: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  render() {
    const {
      children,
      dirty,
      error,
      errorCount,
      handleSubmit,
      id,
      invalid,
      readOnly,
      reset,
      small,
      submitFailed,
      submitting,
      updateWizard,
    } = this.props

    return (
      <Block>
        <BasicForm noValidate>
          <Block
            bottom={61}
            fontSize={12}
            overflow="auto"
            paddingTop={20}
            paddingRight={15}
            paddingBottom={20}
            paddingLeft={15}
            position="absolute"
            top={156}
            width={420}
          >
            {error && submitFailed && <Alert type="error" messages={[error]} />}
            <Field
              required
              component={InputText}
              name="name"
              label="Entry Name"
              readOnly={readOnly}
              small={small}
              validate={[isRequired]}
            />
            {children}
          </Block>
          <Block
            borderTop={`1px solid ${colors.gray.lightest}`}
            bottom={0}
            height={60}
            paddingTop={0}
            paddingRight={15}
            paddingBottom={0}
            paddingLeft={15}
            position="absolute"
            width={420}
          >
            <ButtonGroup align="left">
              <Crud
                dirty={dirty}
                errorCount={errorCount}
                handleCancel={() => updateWizard({ selectedTemplate: null })}
                handleDelete={this.delete}
                handleSave={handleSubmit(this.saveForm.bind(this))}
                id={id}
                invalid={invalid}
                name={name}
                submitting={submitting}
                reset={reset}
              />
            </ButtonGroup>
          </Block>
        </BasicForm>
      </Block>
    )
  }

  saveForm(formValues) {
    const {
      area,
      saveArea,
      saveEntry,
      submitCallback,
      templateId,
      updateAreaView,
      userId,
    } = this.props

    const areaEntity = area.entity

    const entryPayload = {
      entry: formValues,
      name: formValues.name,
      template: templateId,
      user: userId,
    }

    updateAreaView({ isLoading: true })

    return saveEntry(entryPayload)
      .then(handleSaveResponse('entry'))
      .then(entry => {
        const entryId = entry._id
        const id = areaEntity._id
        const entries = areaEntity.entries.concat(entryId)

        return saveArea(id, { entries })
          .then(handleSaveResponse('area'))
          .then(() => submitCallback(entryId))
      })
      .catch(handleFormError)
      .finally(() => updateAreaView({ isLoading: false }))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm()
)(NewContentEntryForm)

function mapStateToProps(state, props) {
  const { form } = props

  const errors = getFormSyncErrors(form)(state) || {}
  const errorCount = getErrorCount(errors)
  const userId = get(state, 'user.data._id')

  return {
    errorCount,
    userId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveArea: (id, payload) => dispatch(areas.save({}, payload, id)),
    saveEntry: payload => dispatch(contentEntries.save({}, payload)),
  }
}
