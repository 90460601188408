import colors from 'config/theme/colors'
import typography from 'config/theme/typography'

export default {
  position: 'relative',
  borderColor: colors.yellow.lighter,
  color: colors.yellow.dark,
  backgroundColor: colors.yellow.lightest,
  boxSizing: 'border-box',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '4px',
  fontSize: typography.fontSizes.small,
  fontWeight: 300,
  padding: '12px 15px',
  flexGrow: 1,
  flexBasis: 'auto',
  alignSelf: 'center',
  marginRight: 10,
  paddingLeft: 44,
}
