import colors from 'config/theme/colors'
import typography from 'config/theme/typography'

export default {
  root: {
    color: colors.gray.light,
    display: 'flex',
    fontSize: typography.fontSizes.small,
    flexShrink: 0,
    lineHeight: '1em',
    padding: '0 10px',
    width: '45%',
    alignItems: 'center',
  },
  icon: {
    position: 'absolute',
    left: 10,
    top: 5,
    fontSize: '24px',
    marginRight: 10,
  },
}
