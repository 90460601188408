import React from 'react'
import ButtonPrompt from 'components/button-prompt'
import { useTranslation } from 'react-i18next'

export default function UnsavedPrompt(props) {
  const { t } = useTranslation()

  const { text = t('prompts.unsaved.youHaveUnsavedChanges') } = props

  return <ButtonPrompt key="buttonPrompt" text={text} />
}
