import { compose, withHandlers, withState, setPropTypes } from 'recompose'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import { WithPermissions } from '@lighthouse/react-components'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import { colors } from 'config/theme'
import Alert from 'components/alert'
import { Anchor, Flex } from 'components/common'

import { Modal, ModalHeader } from '../../../components'
import Form from './components/form'
import OidcForm from './components/oidc-form'
import { withTranslation } from 'react-i18next'

const appModule = getModule('app')
const authenticationModule = getModule('authentication')

const AUTH_DEFAULT = 'default'
const AUTH_OIDC = 'oidc'

const PROP_TYPES = {
  location: PropTypes.object.isRequired,
  handleSwitchApplication: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default compose(
  WithPermissions,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({ handleSwitchApplication }),
  setPropTypes(PROP_TYPES),
  withTranslation()
)(SignIn)

export { SignIn as SignInForTest }

function SignIn(props) {
  const {
    authStrategy,
    error,
    handleSubmit,
    handleSwitchApplication,
    location,
    t,
    permissionError,
  } = props

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <Modal>
        <ModalHeader>{t('login.header.signIn')}</ModalHeader>
        {error && !permissionError && (
          <Alert messages={[error]} marginBottom={0} type="error" />
        )}
        {permissionError && (
          <Alert messages={[permissionError]} marginBottom={0} type="error" />
        )}
        {authStrategy === AUTH_OIDC ? (
          <OidcForm />
        ) : (
          <Form location={location} submitCallback={handleSubmit} />
        )}
      </Modal>
      <Anchor
        color={colors.white}
        cursor="pointer"
        fontSize={14}
        marginTop={35}
        onClick={handleSwitchApplication}
        textDecoration="underline"
      >
        {t('switchApplication')}
      </Anchor>
    </Flex>
  )
}

function handleSwitchApplication(props) {
  const { setApplication } = props

  return () => setApplication(null)
}

function mapStateToProps(state) {
  const applicationId = get(state, 'app.applicationId')
  const currentApplication = applicationId
    ? state.app.applications[applicationId]
    : undefined
  const authStrategy = get(currentApplication, 'authStrategy', AUTH_DEFAULT)

  return {
    authStrategy,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setApplication: id => dispatch(appModule.setApplication(id)),
  }
}
