import { Marker, Tooltip } from 'react-leaflet'
import React from 'react'

import { Block } from 'components/common'
import { RESOURCE_ICON_MAP } from './constants'

interface Coordinates {
  lat: number
  lng: number
}

interface TemporaryMarkerLayerProps {
  coordinates: Coordinates[] | Coordinates
  draggable: boolean
  icon: string
  onDragEnd: () => void
  onSet: () => void
  resource: string
  title: string
}

export default function TemporaryMarkerLayer(
  props: TemporaryMarkerLayerProps
): JSX.Element {
  const {
    coordinates,
    draggable,
    icon,
    onDragEnd: handleDragEnd,
    onSet: handleSet,
    resource,
    title = 'Unknown',
  } = props

  const markerIcon = icon
    ? RESOURCE_ICON_MAP[icon]
    : RESOURCE_ICON_MAP[resource]

  if (!coordinates || !markerIcon) return null

  return (
    <Marker
      draggable={draggable}
      icon={markerIcon}
      onClick={handleSet}
      onDragEnd={handleDragEnd}
      pane="popupPane"
      position={coordinates}
      zIndexOffset={1000}
    >
      <Tooltip direction="top" offset={[0, -15]} pane="popupPane" permanent>
        <Block fontWeight={500}>{title}</Block>
      </Tooltip>
    </Marker>
  )
}
