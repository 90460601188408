export default [
  {
    name: 'Athena Events',
    description: 'Enable events to be queried from Athena',
    fieldName: 'athenaEvents',
    default: true,
  },
  {
    name: 'Daily Activity Report',
    description: 'Enable the Daily Activity Report',
    fieldName: 'activityReport',
    default: true,
  },
  {
    name: 'Daily Shift Report',
    description: 'Enable the Daily Shift Report',
    fieldName: 'shiftReport',
    default: true,
  },
  {
    name: 'Bluetooth',
    description: 'Enable Bluetooth Beacon tracking',
    fieldName: 'bluetooth',
    default: true,
  },
  {
    name: 'Content',
    description: 'Enable content management module',
    fieldName: 'content',
    default: false,
  },
  {
    name: 'Alternate Daily Activity Report',
    description:
      'Enable alternative dailty activity report (primarily for indoor beacon sites)',
    fieldName: 'darAlternateSummary',
    default: false,
  },
  {
    name: 'Disable Editing on Closed Issues',
    description: 'Do not allow users to modify issues that have been closed',
    fieldName: 'lockClosedIssues',
    default: false,
  },
  {
    name: 'Disable Photo Library',
    description:
      'Do not allow mobile users to select photos from their existing library',
    fieldName: 'disableAppPhotoLibrarySelection',
    default: false,
  },
  {
    name: 'Disable Custom Location',
    description:
      'Do not allow mobile users to modify the location selection in forms',
    fieldName: 'disableCustomLocationSelection',
    default: true,
  },
  {
    name: 'Duress',
    description: 'Enable Duress button on the mobile app',
    fieldName: 'duress',
    default: false,
  },
  {
    name: 'Geo',
    description: "Enable 'New' maps (all new customers)",
    fieldName: 'geo',
    default: true,
  },
  {
    name: 'Graph',
    description: 'Enable console users to edit the indoor signal relationships',
    fieldName: 'graph',
    default: false,
  },
  {
    name: 'Hours',
    description: 'Enable the hours timekeeping report',
    fieldName: 'hours',
    default: false,
  },
  {
    name: 'Map Fab Menu',
    description: 'Enable the fab menu for adding new items on the console map',
    fieldName: 'mapFabMenu',
    default: true,
  },
  {
    name: 'Add Location Menu Fab',
    description: "Enable the 'Add Location' option on the console map",
    fieldName: 'mapFabMenuAddLocation',
    default: true,
  },
  {
    name: 'Location List',
    description: 'Enable the location list sidebar on the console map',
    fieldName: 'mapLocationList',
    default: true,
  },
  {
    name: 'Map Satellite Tiles',
    description: 'Enable the Satellite Tiles option on the console map',
    fieldName: 'mapSatelliteTiles',
    default: true,
  },
  {
    name: 'Notifications Disabled',
    description: 'Disable notification preferences on console',
    fieldName: 'notificationsDisabled',
    default: false,
  },
  {
    name: 'SMS Notifications',
    description: 'Enable SMS notifications',
    fieldName: 'notificationsSms',
    default: false,
  },
  {
    name: 'QR Code',
    description: 'Enable QR Code scanning on mobile',
    fieldName: 'qrcodes',
    default: true,
  },
  {
    name: 'NFC Tag',
    description: 'Enable NFC tag scanning on mobile',
    fieldName: 'nfc',
    default: true,
  },
  {
    name: 'Summary Fields',
    description: 'Enable the summary fields capability on form templates',
    fieldName: 'summaryFields',
    default: true,
  },
  {
    name: 'Daily Location Reports',
    description: 'Enable the section location report tab in the console',
    fieldName: 'dailyLocationReports',
    default: false,
  },
  {
    name: 'Edit Form Titles',
    description:
      "Enable users to edit the title within their forms if the user's role has edit permissions",
    fieldName: 'editFormTitles',
    default: true,
  },
  {
    name: 'Exit Events',
    description: `Display exit events on web (deprecating)`,
    fieldName: 'exitEvents',
    default: false,
  },
  {
    name: 'Mobile Device Permissions',
    description:
      '(Beta) Enable device permissions to be recorded on shift start and end',
    fieldName: 'devicePermissions',
    default: false,
  },
  {
    name: 'Location Groups',
    description: '(Beta) Enable the new location groups feature ',
    fieldName: 'rolloutLocationGroups',
    default: false,
  },
  {
    name: 'Mobile Issues UX',
    description: '(Beta) Enable the new user experience for issues on mobile',
    fieldName: 'enableIssuesUXChange',
    default: false,
  },
  {
    name: 'Mobile Tasks UX',
    description: '(Beta) Enable the new user experience for tasks on mobile',
    fieldName: 'enableTasksUXChange',
    default: false,
  },
]
