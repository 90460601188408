import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { get } from 'lodash'

const userApplicationsModule = getModule('userApplications')

export default connect(mapStateToProps)

function mapStateToProps(state) {
  const email = get(state, 'user.data.email')
  const flags = userApplicationsModule.getFlags(state)
  const isLighthouseAdmin = email === 'support@lighthouse.io'

  return {
    flags,
    isLighthouseAdmin,
  }
}
