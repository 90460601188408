import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'
import { compose } from 'recompose'
import styles from './styles'
import Button from 'components/button'
import { withTranslation } from 'react-i18next'

class ConfirmationBox extends Component {
  static propTypes = {
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.onCancel = this.onCancel.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
  }

  render() {
    const { message, showAbove, size, submitting, t } = this.props

    styles.root.flexDirection = showAbove ? 'column' : 'row'

    return (
      <div style={styles.root}>
        {message && showAbove && (
          <div style={styles.largeMessage}>{message}</div>
        )}
        <div style={styles.buttons}>
          <Button
            dataTestId="cancel-button"
            onClick={this.onCancel}
            size={size}
          >
            {t('button.cancel')}
          </Button>
          <Button
            dataTestId="confirm-button"
            disabled={submitting}
            loading={submitting}
            onClick={this.onConfirm}
            size={size}
            theme="positive noMargin"
          >
            {t('button.confirm')}
          </Button>
          {message && !showAbove && <div style={styles.message}>{message}</div>}
        </div>
      </div>
    )
  }

  onCancel(event) {
    event.stopPropagation()
    this.props.onCancel()
  }

  onConfirm(event) {
    event.stopPropagation()
    this.props.onConfirm()
  }
}

export default compose(withTranslation(), Radium)(ConfirmationBox)
