import React from 'react'

import TableRow from './table-row'

export default function TableHeader({ children }) {
  return (
    <thead>
      <TableRow isHeader>{children}</TableRow>
    </thead>
  )
}
