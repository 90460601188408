import PropTypes from 'prop-types'
import React from 'react'
import { Trans } from 'react-i18next'
import { Block, Flex } from 'components/common'
import colors from 'config/theme/colors'

export default function Tooltip(props) {
  const { anchorDirection = 'bottom', title, message, noArrow } = props

  const tooltipStyles = {
    position: 'absolute',
    background: 'white',
    zIndex: '10',
    right: anchorDirection === 'right' ? 'auto' : '38px',
    width: 'auto',
    minWidth: '250px',
    maxWidth: '300px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: colors.gray.lighter,
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.1)',
    onHover: { color: colors.gray.normal },
  }

  const topTooltipStyles = { ...tooltipStyles, bottom: '0px', right: '-320px' }

  const bottomTooltipStyles = { ...tooltipStyles, top: '-5px' }

  const updatedTooltipStyles =
    anchorDirection === 'bottom' ? bottomTooltipStyles : topTooltipStyles

  const tooltipArrowStyles = {
    position: 'absolute',
    width: 0,
    height: 0,
    right: '-7px',
    borderTop: '7px solid',
    borderBottom: '7px solid',
    borderLeft: '7px solid',
    borderColor: colors.gray.lighter,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
  }

  const updatedTooltipArrowStyles = {
    ...tooltipArrowStyles,

    left:
      anchorDirection === 'right' || anchorDirection === 'top' ? '-7px' : null,
    borderRight:
      anchorDirection === 'right' || anchorDirection === 'top'
        ? '7px solid'
        : null,
    borderLeft:
      anchorDirection === 'right' || anchorDirection === 'top'
        ? null
        : '7px solid',
    top:
      anchorDirection === 'right'
        ? '220px'
        : anchorDirection === 'top'
        ? '120px'
        : '12px',
    borderRightColor:
      'right' || anchorDirection === 'top' ? colors.gray.lighter : null,
  }

  return (
    <Block {...updatedTooltipStyles} {...props}>
      <Block
        position="relative"
        background={colors.gray.lightest}
        fontSize={13}
        color={colors.gray.normal}
        padding="10px 15px"
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
      >
        <Trans>{title}</Trans>
        {noArrow ? null : <Flex {...updatedTooltipArrowStyles} />}
      </Block>
      <Block
        background="white"
        fontSize={11}
        lineHeight="1.5em"
        padding="10px 15px"
        borderBottomLeftRadius="4px"
        borderBottomRightRadius="4px"
        color={colors.gray.normal}
        whiteSpace="pre-line"
      >
        <Trans>{message}</Trans>
      </Block>
    </Block>
  )
}

Tooltip.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  anchorDirection: PropTypes.string,
}
