import colors from 'config/theme/colors'

export default {
  headerCell: {
    background: colors.gray.lightest,
    border: '0 none',
    fontSize: 12,
    padding: 15,
  },
  bodyCell: {
    padding: 10,
    borderBottom: '1px solid #eee',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
}
