import { Schema as ServiceHoursSchema } from '../../service-hours'
import {
  generateNonRepeatingSchedule,
  generateRepeatingSchedule,
} from '../helpers'
import { Strategy, StrategyTypes } from '../scheduling.types'

interface ScheduleIntervalsGenerator {
  readonly end: number
  readonly isInitial: boolean
  readonly strategy: Strategy
  readonly serviceHours: ServiceHoursSchema
  readonly start: number
}

/**
 * Generates schedule service and occurrence intervals
 */
export function* scheduleIntervalsGenerator(props: ScheduleIntervalsGenerator) {
  const {
    strategy: { type },
  } = props
  const isRepeating = type !== StrategyTypes.NoRepeat

  if (isRepeating) {
    return yield* generateRepeatingSchedule(props)
  }

  return yield* generateNonRepeatingSchedule(props)
}
