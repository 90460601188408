import colors from 'config/theme/colors'

export default {
  root: {
    display: 'block',
    paddingTop: '30px',
  },

  title: {
    display: 'block',
    fontSize: '22',
    lineHeight: '40px',
    fontWeight: '200',
    color: colors.gray.light,
    borderBottom: `1px solid ${colors.gray.lighter}`,
    paddingBottom: '15px',
  },
}
