import moment from 'moment'
import { DATE_FORMATS } from '../constants'
/**
 * Return a string representing the recency of the date provided
 * Possible return values:  day, week, month, year
 */
export function getDateRecency(date, timezone) {
  const mDate = moment.tz(date, timezone)
  const now = moment().tz(timezone)

  const startOfToday = now.startOf('day')
  const wasToday = mDate.isAfter(startOfToday)

  if (wasToday) return 'day'

  const startOfWeek = now.startOf('week')
  const wasThisWeek = mDate.isAfter(startOfWeek)

  if (wasThisWeek) return 'week'

  const startOfYear = now.startOf('year')
  const wasThisYear = mDate.isAfter(startOfYear)

  if (wasThisYear) return 'month'

  return 'year'
}

export function formatDateWithRecency(date, timezone) {
  const recency = getDateRecency(date, timezone)
  const format = DATE_FORMATS[recency]
  return moment(date)
    .tz(timezone)
    .format(format)
}
