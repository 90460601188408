import { render } from 'react-dom'
import React from 'react'
import { StyleRoot } from 'radium'

import { App } from './App'
import { AuthProvider } from 'components/useAuth'

function Root() {
  return (
    <StyleRoot>
      <AuthProvider>
        <App />
      </AuthProvider>
    </StyleRoot>
  )
}

render(<Root />, document.getElementById('root'))
