import React from 'react'

import styles from './styles'

export default StickyInner

function StickyInner({ isSticky }, childComponent) {
  const style = isSticky ? styles.stickyHeader : null
  return <div style={style}>{childComponent()}</div>
}
