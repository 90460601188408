import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

import { Span } from 'components/common'

import { formatDateWithRecency } from '../../lib/helpers'

export default compose(connect(mapStateToProps), pure)(DateComponent)

function DateComponent({ date, recency, timezone, ...styles }) {
  if (!(date instanceof Date)) return null

  const displayDate = recency
    ? formatDateWithRecency(date, timezone)
    : moment(date)
        .tz(timezone)
        .format('YYYY/MM/YY hh:mm')

  return <Span {...styles}>{displayDate}</Span>
}

DateComponent.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  recency: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    timezone: state.app.timezone,
  }
}
