import { colors, typography } from 'config/theme'

export default {
  input: {
    cursor: 'pointer',
    display: 'flex',
    backgroundColor: colors.white,
    borderColor: colors.gray.lighter,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxSizing: 'border-box',
    color: colors.gray.darker,
    fontFamily: typography.defaultFontFamily,
    fontSize: '14px',
    fontWeight: 300,
    maxHeight: '40px',
    outline: 'none',
    overflow: 'hidden',
    paddingTop: '12px',
    paddingBottom: '10px',
    paddingLeft: '14px',
    paddingRight: '14px',
    width: '100%',
  },
  inputLabel: {
    paddingRight: 40,
  },
  icon: {
    marginLeft: 'auto',
  },
}
