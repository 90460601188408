import { get } from 'lodash'

import {
  getLocationReference,
  getReferenceDetails,
  getTimezoneDatetime,
} from '../'

export function getTaskEntryDetails(data) {
  const { entity = {}, timezone } = data

  const { createdAt, gps, sequenceId } = entity

  const gpsText = get(gps, 'reverseGeocoded.label', 'Unknown Location')
  const locationText = getLocationReference(data)
  const referenceDetails = getReferenceDetails(data)
  const timezoneHourTime = getTimezoneDatetime({
    format: 'h:mm a',
    timestamp: createdAt,
    timezone,
  })
  const timezoneDatetime = getTimezoneDatetime({
    timestamp: createdAt,
    timezone,
  })

  return {
    locationText,
    gpsText,
    referenceLabel: referenceDetails.label,
    referenceValue: referenceDetails.value,
    sequenceId,
    timezoneHourTime,
    timezoneDatetime,
  }
}
