import { colors } from 'config/theme/brand'

export default {
  // look into whether this should be some kind of
  // linkColor var instead. Might be unnecesarry
  root: {
    width: 'auto',
    lineHeight: 'inherit',
  },
  primary: {
    color: colors.primary,
  },
  secondary: {
    color: colors.secondary,
  },
  success: {
    color: colors.success,
  },
  info: {
    color: colors.info,
  },
  warning: {
    color: colors.warning,
  },
  error: {
    color: colors.danger,
  },
}
