export const MODE_DEPLOYMENT = 'deployment'
export const MODE_EDIT = 'edit'
export const MODE_HISTORICAL = 'historical'
export const MODE_LIVE = 'live'

export const MODES = [
  MODE_DEPLOYMENT,
  MODE_EDIT,
  MODE_HISTORICAL,
  MODE_LIVE,
]

export const MARKER_ACTIVITY = 'activity'
export const MARKER_ASSET = 'asset'
export const MARKER_AUDIT = 'auditentries'
export const MARKER_EXCEPTION = 'exception'
export const MARKER_ISSUE = 'issue'
export const MARKER_SHIFT = 'shift'
export const MARKER_SIGNAL = 'signal'
export const MARKER_TASK = 'taskentries'
export const MARKER_USER = 'user'

export const ACTIVITY_MARKERS = [
  MARKER_ASSET,
  MARKER_AUDIT,
  MARKER_EXCEPTION,
  MARKER_ISSUE,
  MARKER_SHIFT,
  MARKER_TASK,
]

export const MARKERS = [
  MARKER_ACTIVITY,
  MARKER_ASSET,
  MARKER_AUDIT,
  MARKER_EXCEPTION,
  MARKER_ISSUE,
  MARKER_SHIFT,
  MARKER_SIGNAL,
  MARKER_TASK,
  MARKER_USER,
]

export const VISIBLE_OUTDOOR_MARKERS = [
  MARKER_USER,
  ...ACTIVITY_MARKERS,
]
