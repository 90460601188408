import {
  get,
} from 'lodash'
import { parseState } from '../../../helpers'

export default actions => function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case actions.SET_CURRENT:
      return state.merge({ id: action.id })
    // TODO this is pretty pointless because we can just pass a null value to
    // SET_CURRENT. Remove this once legacy usage is removed
    case actions.UNSET_CURRENT:
      return state.without('id')
    case actions.ADD_TO_CACHE:
    case actions.SAVE_SUCCESS: {
      const { id } = action
      const matchesCurrent = id === state.id

      // NOTE redux offline supplies success object with a payload object which
      // is a different structure to our custom payload. In future it might be
      // good to bring these in line for consistency
      const persistedId = action.payload
            ? get(action, 'payload.json._id')
            : get(action, 'data._id')

      const persistedChange = matchesCurrent && id !== persistedId

      if (!persistedChange) return state

      // NOTE When the saving item was an optimistic item but has not persisted,
      // we need to update the current reference to match the persisted id from
      // the database
      return state.set('id', persistedId)
    }
    default:
      return state
  }
}
