import React from 'react'

import { Flex } from 'components/common'

export default function InputGroup(props) {
  return (
    <Flex alignItems={props.alignTop ? 'top' : 'center'} {...props.style}>
      {props.children}
    </Flex>
  )
}
