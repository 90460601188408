import PropTypes from 'prop-types'
import React from 'react'

import { Block } from 'components/common'
import { colors } from 'config/theme'

import ButtonIcon from 'components/button-icon'
import Icon from 'components/icon'
import Thumbnail from 'components/thumbnail'
import cloudinary from 'utils/cloudinary'

const s3BaseUrl = process.env.S3_UPLOADS_BASE_URL

export default function Image(props) {
  const { input, handleRemove, readOnly, width = 250, ...styleProps } = props

  const value = input && input.value

  const src = cloudinary(value, {
    fit: true,
    width,
  })

  const link = `${s3BaseUrl}/${value}`

  return (
    <Block alignSelf="flex-start" position="relative">
      {!readOnly && (
        <Block
          onClick={handleRemove}
          marginRight="-10px"
          marginTop="-5px"
          position="absolute"
          right={0}
        >
          <ButtonIcon backgroundColor={colors.red.normal} marginRight="0px">
            <Icon
              name="neutral"
              color={colors.white}
              fontSize={12}
              fontWeight={600}
              lineHeight={1}
            />
          </ButtonIcon>
        </Block>
      )}
      <Thumbnail
        alt={src}
        link={link}
        src={src}
        width={width}
        {...styleProps}
      />
    </Block>
  )
}

Image.propTypes = {
  input: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
  imageWidth: PropTypes.number,
  readOnly: PropTypes.bool,
  width: PropTypes.number,
}
