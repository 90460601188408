/*
 * Task Entries Module
 */

import { combineReducers } from 'redux'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import crud from '../../crud'
import hooks from './hooks'
import buildActionCreators from '../winteam/action-creators'

const resource = 'winteam'

export const actions = crud.actions(resource)
hooks(actions)

const baseUrlFn = applicationResourceUrlFn(resource)
const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  authorizationHeadersFn,
  applicationParamsFn,
)

export const actionCreators = {
  ...crudActionCreators,
  ...customActionCreators,
}

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})
