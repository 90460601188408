import { REQUEST } from '../../../middleware/request'

export default function buildActionCreators(actions, baseUrlFn, paramsFn, headersFn) {
  return {
    averageDuration,
  }

  function averageDuration(slug = 'unknown-slug', params) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/analytics/average-duration`
      const headers = headersFn(params, state)
      return dispatch(averageDurationRequest(slug, endpoint, headers, params))
    }
  }

  function averageDurationRequest(slug, endpoint, headers, params) {
    return {
      [REQUEST]: {
        types: [actions.AVERAGE_DURATION_REQUEST, actions.AVERAGE_DURATION_SUCCESS, actions.AVERAGE_DURATION_ERROR],
        endpoint,
        headers,
        query: params,
        attachToActions: { slug },
      },
    }
  }
}
