import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { withRouter } from 'react-router-dom'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import queryString from 'query-string'

// components
import { Anchor, Block, Flex, Text } from 'components/common'
import setEndpoints from '../components/set-endpoints'
import { InputText, BasicForm, FieldGroup, FieldSet } from 'components/form'
import Alert from 'components/alert'
import Background from 'components/background'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'
import colors from 'config/theme/colors'
import Logo from 'components/logo'
import { Modal, ModalHeader } from '../components'
import { useTranslation } from 'react-i18next'

const userModule = getModule('user')

function ResetPasswordRequest(props) {
  const [username, setUsername] = useState('')
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { resetPasswordRequest, history } = props

  function changeUsername(e) {
    const value = e.target.value
    setUsername(value)
  }

  async function submit(e) {
    e.preventDefault()
    setError('')
    setLoading(true)

    if (!username) {
      return setError(t('login.error.noEmailAddress'))
    }

    const params = queryString.parse(location.search)

    const applicationId = params.applicationId || props.applicationId

    const payload = {
      username: username.toLowerCase(),
    }

    if (applicationId) {
      payload.applicationId = applicationId
    }

    try {
      await resetPasswordRequest(payload)
      setSuccess(t('login.success.resetPassword'))
      setUsername(username)
    } catch (error) {
      setError(handleErrorResponse(error))
    }
    setLoading(false)
    return null
  }

  const errorAlert = error && (
    <Alert marginBottom={0} messages={[error]} type="error" />
  )

  const successAlert = success && (
    <Alert marginBottom={0} messages={[success]} type="success" />
  )

  return (
    <Background>
      <Flex
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
        flexShrink={0}
        justifyContent="center"
        width={isMobile ? '90%' : 560}
      >
        <Logo />
        <Modal width="100%">
          {successAlert}
          {!success && (
            <Block>
              <ModalHeader>{t('login.header.forgotPassword')}</ModalHeader>
              {errorAlert}
              <BasicForm onSubmit={submit} noValidate>
                <FieldGroup>
                  <FieldSet>
                    {isMobile ? <Text>{t('labelUsername')}</Text> : null}
                    <InputText
                      label={isMobile ? '' : t('labelUsername')}
                      required
                      value={username}
                      onChange={changeUsername}
                    />
                  </FieldSet>
                </FieldGroup>
                <ButtonGroup align="right" border>
                  <Button link="/login">{t('button.cancel')}</Button>
                  <Button
                    loading={loading}
                    type="submit"
                    theme="positive noMargin"
                  >
                    {t('button.reset')}
                  </Button>
                </ButtonGroup>
              </BasicForm>
            </Block>
          )}
        </Modal>
        <Anchor
          color={colors.white}
          cursor="pointer"
          fontSize={14}
          onClick={() => history.push('/login')}
          marginTop={35}
          textDecoration="underline"
        >
          {t('backToSignIn')}
        </Anchor>
      </Flex>
    </Background>
  )
}

export default compose(
  withRouter,
  setEndpoints,
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPasswordRequest)

function mapStateToProps(state) {
  return {
    applicationId: state.app.applicationId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetPasswordRequest: payload =>
      dispatch(userModule.startResetPasswordRequest(payload)),
  }
}

function handleErrorResponse(error) {
  let errorMessage
  switch (error.name) {
    case 'NotFoundError':
      errorMessage = 'The username specified could not be found'
      break
    default:
      errorMessage =
        error.message ||
        'There was an issue with the request. Please contact your administrator'
  }

  return errorMessage
}
