/**
 * Example State
 * =============
 *
 * {
 *   items: [1,2,3,4,5],
 *   state: 'resolved',
 *   filter: {
 *     key: 'value'
 *   },
 *   sort: [['user'], ['asc']],
 *   pagination: {
 *     opts: {
 *       perPage: '20',
 *       page: '2'
 *     }
 *     links: {
 *       next: {
 *         page: '2',
 *         rel: 'next',
 *         url: 'http://api.lighthouse.io/zones?page=2'
 *       },
 *       last: {
 *         page: '7',
 *         rel: 'last',
 *         url: 'http://api.lighthouse.io/zones?page=7'
 *       }
 *     }
 *   },
 * }
 * }
 */

import Immutable from 'seamless-immutable'

import {
  each,
  get,
  map,
  without,
  union,
} from 'lodash'

import { parseState } from '../../../helpers'

const EMPTY_SORT = [[], []]
const initialListState = {
  filters: {},
  items: [],
  pagination: {},
  sort: EMPTY_SORT,
  state: 'invalidated', // resolved, resolving
}

export const PENDING_LIST_ID = 'offline-pending'

export default actions => function reducer(state, action = {}) {
  // store listId if specified or fallback to default
  const { listId = 'default', appendToList } = action

  state = parseState(state, {
    default: initialListState,
    [listId]: initialListState,
  })


  // NOTE we run `initListState` in every case to ensure that the correct
  // store object is setup for this particular slice. Previously we did this
  // outside of the switch/case, but that didn't work too well because it
  // would run for all actions, even those not intended for this reducer

  switch (action.type) {
    case actions.QUERY_REQUEST:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'state'],
        'resolving',
      )
    case actions.QUERY_SUCCESS:
      state = initListState(state, listId)
      if (state[listId].retrying) {
        state = state.setIn([listId, 'retrying'], null)
      }
      if (state[listId].error) {
        state = state.setIn([listId, 'error'], null)
      }
      state = state.setIn([listId, 'state'], 'resolved')

      const newItems = map(action.data, (item) => {
        const id = item._id || item.id || item.Id || item.ID
        return id
      })
      state = state.setIn([listId, 'items'], appendToList ? union(get(state, [listId, 'items']), newItems) : newItems)

      return state
    case actions.QUERY_ERROR:
      state = initListState(state, listId)
      state = state.setIn([listId, 'state'], action.retrying ? 'resolving' : 'resolved')
      state = state.setIn([listId, 'error'], action.error)
      if (action.retrying) {
        state = state.setIn([listId, 'retrying'], action.retrying)
      }
      return state

    case actions.CLEAR_LIST_ITEMS:
      state = initListState(state, listId)

      each(action.listIds, (id) => {
        state = state.setIn([id, 'items'], [])
        state = state.setIn([id, 'pagination'], {})
      })

      return state
    case actions.ADD_TO_LIST:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'items'],
        union(
          state[listId].items,
          action.ids,
        ),
      )
    case actions.REMOVE_FROM_LIST:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'items'],
        without(
          state[listId].items,
          ...action.ids,
        ),
      )
    case actions.DISCARD_OPTIMISTIC:
      state = initListState(state, PENDING_LIST_ID)
      return state.setIn(
        [PENDING_LIST_ID, 'items'],
        without(
          state[PENDING_LIST_ID].items,
          action.id,
        ),
      )
    case actions.INVALIDATE_LIST:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'state'],
        'invalidated',
      )
    case actions.SET_LIST_FILTERS:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'filters'],
        state[listId].filters.merge(action.filters),
      )
    case actions.CLEAR_LIST_FILTERS:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'filters'],
        {},
      )
    case actions.CLEAR_LIST_SORT:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'sort'],
        EMPTY_SORT,
      )
    case actions.SET_LIST_SORT:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'sort'],
        action.sort,
      )
    case actions.SET_PAGINATION_OPTS:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'pagination', 'opts'],
        action.opts,
      )
    case actions.SET_PAGINATION_LINKS:
      state = initListState(state, listId)
      return state.merge({
        [listId]: {
          pagination: {
            links: action.links,
            totalCount: action.totalCount,
            lastKey: action.lastKey,
          },
        },
      }, { deep: true })
    case actions.CLEAR_PAGINATION:
      state = initListState(state, listId)
      return state.setIn(
        [listId, 'pagination'],
        {},
      )
    default:
      return state
  }
}

function initListState(state, listId) {
  if (!state[listId]) {
    return state.set(listId, Immutable(initialListState))
  }
  return state
}
