import React from 'react'

import Button from 'components/button'
import { Flex } from 'components/common'
import Icon from 'components/icon'
import { colors } from 'config/theme'

export default DropdownClear

function DropdownClear({ handleClear }) {
  return (
    <Flex alignItems="center" height={0} paddingLeft={12} paddingRight={0}>
      <Button
        backgroundColor={colors.white}
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        dataTestId="clear"
        height={40}
        hoverBackgroundColor={colors.white}
        marginRight={0}
        width={30}
        onClick={e => {
          if (e !== undefined) e.stopPropagation()
          handleClear()
        }}
      >
        <Icon name="close" theme={{ fontSize: 10 }} />
      </Button>
    </Flex>
  )
}
