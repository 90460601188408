import Radium from 'radium'
import React from 'react'
import { isNull } from 'lodash'

import { Block } from 'components/common'

import styles from './styles'

function FormNumber(props) {
  const {
    dataTestId,
    disabled,
    error,
    placeholder,
    max,
    min,
    onBlur,
    onChange,
    required,
    readOnly,
    small,
    step,
    value,
    // redux-form props
    input,
    meta,
  } = props

  if (readOnly) {
    return <Block padding={10}>{isNull(value) ? '' : value}</Block>
  }

  const inputBlurHandler = input ? input.onBlur : onBlur
  const inputChangeHandler = input ? input.onChange : onChange
  const inputValue = input ? input.value : value
  const inputError = meta ? meta.error : error

  const errorStyles = inputError ? styles.inputError : null
  const smallStyles = small ? styles.smallInput : null

  // NOTE its useful to know the difference between readonly and
  // disabled http://stackoverflow.com/a/7730719
  const disabledStyles = disabled ? styles.disabled : null
  const baseStyles = [styles.root, errorStyles, disabledStyles]

  return (
    <input
      data-testid={dataTestId}
      disabled={disabled}
      onBlur={inputBlurHandler}
      onChange={inputChangeHandler}
      placeholder={placeholder}
      max={max}
      min={min}
      required={required}
      step={step}
      style={[...baseStyles, styles.input, smallStyles]}
      value={inputValue}
    />
  )
}

export default Radium(FormNumber)
