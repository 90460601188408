import { connect } from 'react-redux'
import { get } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import compose from 'recompose/compose'
import React from 'react'
import withProps from 'recompose/withProps'

import Form from '../../../form'

const LIST_ID = 'all'

const templateModule = getModule('templates')

export default compose(
  connect(mapStateToProps),
  withProps(buildEntryProps)
)(Entry)

function Entry(props) {
  const {
    error,
    form,
    initialValues,
    onCancel: handleCancel,
    onClose: handleClose,
    templateEntity,
  } = props

  return (
    <Form
      error={error}
      form={form}
      handleCancel={handleCancel}
      initialValues={initialValues}
      isEditing
      onClose={handleClose}
      templateEntity={templateEntity}
    />
  )
}

function buildEntryProps(props) {
  const { templateCache, templateId, urlGeometry } = props

  const templateResource = templateCache[templateId] || {}
  const templateEntity = templateResource.entity || {}

  const form = 'issue-new'

  const assignees = []
  const floorsRef = []
  const formGroups = get(templateEntity, 'template.formGroups', [])
  const geometry = urlGeometry
  const template = templateId
  const title = templateEntity.name

  const initialValues = {
    assignees,
    floorsRef,
    formGroups,
    geometry,
    template,
    title,
  }

  return {
    form,
    initialValues,
    templateEntity,
  }
}

function mapStateToProps(state) {
  const templateSelectors = templateModule.selectors(state)(LIST_ID)
  const templateCache = templateSelectors.cache()

  return {
    templateCache,
  }
}
