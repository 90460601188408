import PropTypes from 'prop-types'
import React from 'react'

import Absolute from 'components/common/absolute'
import Spinner from 'components/spinner'

export default function TableSpinner(props) {
  const { top = 265 } = props
  return (
    <Absolute center dataTestId="table-spinner" top={top}>
      <Spinner type="dots" />
    </Absolute>
  )
}

TableSpinner.propTypes = {
  top: PropTypes.number,
}
