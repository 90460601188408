import {
  ColumnProps,
  HeaderRendererParams,
  CellRendererParams,
} from './simpleTable.types'

const defaultCellRenderer = ({
  cellData,
}: CellRendererParams): JSX.Element | string => {
  return cellData ? String(cellData) : ''
}

const defaultHeaderRenderer = ({
  label,
}: HeaderRendererParams): JSX.Element | string => {
  return label ? label : ''
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function Column(props: ColumnProps) {}

Column.defaultProps = {
  cellRenderer: defaultCellRenderer,
  headerRenderer: defaultHeaderRenderer,
  flex: 1,
}

export default Column
