import React from 'react'
import { Flex } from 'components/common'

export default function MapActionToolbar(props) {
  const { children } = props

  return (
    <Flex
      backgroundColor="rgba(255,255,255,0.8)"
      bottom={0}
      left="50%"
      marginBottom={20}
      padding={10}
      borderRadius={4}
      position="absolute"
      transform="translate(-50%, 0)"
      zIndex={1000}
    >
      {children}
    </Flex>
  )
}
