import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Alert from 'components/alert'
import emitter from 'utils/emitter'
import * as logger from 'utils/logger'
import compose from 'recompose/compose'
import { withTranslation } from 'react-i18next'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error)

    emitter.emit('notification:add', {
      message: error.message,
      title: 'Error',
      theme: 'alert',
    })
  }

  componentDidMount() {
    const { history } = this.props

    this.unlisten = history.listen(() => {
      const { hasError } = this.state

      if (hasError) {
        this.setState({ hasError: false })
      }
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  componentWillUnmount() {
    this.setState({ hasError: false })
  }

  render() {
    const { children, t } = this.props
    const { hasError } = this.state

    if (hasError) {
      const messages = [t('prompts.error.applicationError')]

      return (
        <Alert
          marginLeft="100px"
          marginRight="100px"
          marginTop="20px"
          messages={messages}
          type="error"
          width="auto"
        />
      )
    }

    return children
  }
}

export default compose(withTranslation(), withRouter)(ErrorBoundary)
