import {
  every,
  filter,
  find,
  get,
  includes,
  isArray,
  map,
  omit,
  reduce,
} from 'lodash'
import moment from 'moment-timezone'

export const getDefaultChannels = (timezone = 'UTC') => [
  {
    name: 'daily-location-report',
    endpoints: [],
    enabled: false,
    options: {
      formSubmissions: true,
      hours: 9,
      timezone,
      utc: 0,
    },
    mapOptions: options => {
      const { formSubmissions, hours, timezone, skipNoActivity } = options
      return {
        formSubmissions,
        hours,
        skipNoActivity,
        timezone,
        utc: moment
          .tz(timezone)
          .hours(hours)
          .utc()
          .hours(),
      }
    },
  },
  {
    name: 'daily-shift-report',
    endpoints: [],
    enabled: false,
    options: {
      formSubmissions: true,
      hours: 9,
      timezone,
      utc: 0,
    },
    mapOptions: options => {
      const { formSubmissions, hours, timezone } = options
      return {
        formSubmissions,
        hours,
        timezone,
        utc: moment
          .tz(timezone)
          .hours(hours)
          .utc()
          .hours(),
      }
    },
  },
  {
    name: 'exception-opened',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'loopException',
        value: ['read'],
      },
    ],
  },
  {
    name: 'exception-resolved',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'loopException',
        value: ['read'],
      },
    ],
  },
  {
    name: 'shift-start',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'shift',
        value: ['read'],
      },
    ],
  },
  {
    name: 'shift-end',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'shift',
        value: ['read'],
      },
    ],
  },
  {
    name: 'audit-entry-new',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'auditEntry',
        value: ['read'],
      },
    ],
    options: {
      auditTargets: 'all',
      templates: [],
    },
  },
  {
    name: 'audit-entry-update',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'auditEntry',
        value: ['read'],
      },
    ],
    options: {
      auditTargets: 'all',
      templates: [],
    },
  },
  {
    name: 'issue-assignee',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'issue',
        value: ['read'],
      },
    ],
    options: {
      templates: [],
    },
  },
  {
    name: 'issue-new',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'issue',
        value: ['read'],
      },
    ],
    options: {
      templates: [],
    },
  },
  {
    name: 'issue-update',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'issue',
        value: ['read'],
      },
    ],
    options: {
      templates: [],
    },
  },
  {
    name: 'duress',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'flags',
        key: 'duress',
        value: true,
      },
      {
        category: 'permissions',
        key: 'issue',
        value: ['read'],
      },
    ],
  },
  {
    name: 'task-entry-new',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'taskEntry',
        value: ['read'],
      },
    ],
    options: {
      templates: [],
    },
  },
  {
    name: 'task-entry-update',
    endpoints: [],
    enabled: false,
    requires: [
      {
        category: 'permissions',
        key: 'taskEntry',
        value: ['read'],
      },
    ],
    options: {
      templates: [],
    },
  },
]

function parseMatchers(props) {
  const { flags, isLighthouseAdmin, permissions } = props

  const mappedPermissions = reduce(
    permissions,
    (accum, permission) => {
      const { access, module } = permission

      accum[module] = access

      return accum
    },
    {}
  )

  return {
    flags: {
      ...flags,
      isLighthouseAdmin,
    },
    permissions: mappedPermissions,
  }
}

function permissionValidator(matchers, condition) {
  const { category, key, value } = condition

  const current = get(matchers, [category, key])

  if (!current) {
    return false
  }

  return isArray(value)
    ? every(value, item => includes(current, item))
    : current === value
}

export function mergeNotificationPreferences(props) {
  const { isLighthouseAdmin } = props

  const matchers = parseMatchers(props)

  return (preferences = {}, userTimezone) => {
    const { channels: userChannels } = preferences

    const defaultChannels = getDefaultChannels(userTimezone)

    const filteredChannnels = isLighthouseAdmin
      ? defaultChannels
      : filter(defaultChannels, channel => {
          const { requires = [] } = channel

          if (requires.length === 0) return true

          return every(requires, condition =>
            permissionValidator(matchers, condition)
          )
        })

    const channels = map(filteredChannnels, defaultChannel => {
      const userChannel = find(userChannels, {
        name: defaultChannel.name,
      })

      // NOTE: required for DAR so that options are attached if not present in user channels
      const channel = {
        ...defaultChannel,
        ...userChannel,
      }

      /**
       * Removing flags from channel so that it is not included with form payload.
       *
       * API falls back and strips out flags on save
       */
      return omit(channel, ['requires'])
    })

    return { channels }
  }
}

export function updateNotificationsPayload(notifications) {
  const channels = map(notifications.channels, channel => {
    const { options } = channel

    if (!options) return channel

    return {
      ...channel,
      options: channel.mapOptions ? channel.mapOptions(options) : options,
    }
  })

  return { channels }
}
