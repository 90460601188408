import React from 'react'

export const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-0.125 -0.125 14.25 14.25"
      height="15px"
      width="30px"
      strokeWidth="1.25"
    >
      <g>
        <rect
          x="2"
          y="5.5"
          width="10"
          height="8"
          rx="1"
          fill="none"
          stroke="#00aae4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5,5.5V4a3.5,3.5,0,0,0-7,0V5.5"
          fill="none"
          stroke="#00aae4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="7"
          cy="9.5"
          r="0.5"
          fill="none"
          stroke="#00aae4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
