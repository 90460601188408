import React from 'react'

import { Flex, Text } from 'components/common'
import colors from 'config/theme/colors'

interface ClusterIconProps {
  height: number
  isLocation: boolean
  userCount: string
  width: number
}

function ClusterIcon(props: ClusterIconProps): JSX.Element {
  const { height, isLocation, userCount, width } = props
  return (
    <Flex
      alignItems="center"
      background={isLocation ? 'rgba(0, 170, 228, 1)' : 'rgba(46, 204, 113, 1)'}
      borderRadius="50%"
      boxShadow={`0px 0px 0px 4px ${
        isLocation ? 'rgba(0, 170, 228, 0.2)' : 'rgba(46, 204, 113, 0.2)'
      }`}
      height={height}
      justifyContent="center"
      width={width}
      zIndex={9999}
    >
      <Text color={colors.white} fontWeight={700} xsmall>
        {userCount}
      </Text>
    </Flex>
  )
}

export default ClusterIcon
