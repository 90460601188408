export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    textDecoration: 'none',
    fontWeight: '500',
    height: 60,
    justifyContent: 'center',
  },
  icon: {
    alignSelf: 'center',
    fontSize: '26px',
    marginTop: 6,
    marginBottom: 2,
    paddingLeft: 3,
    paddingRight: 3,
  },
  zone: {
    alignSelf: 'center',
    fontSize: '8px',
  },
}
