import { getModule } from '@lighthouse/sdk'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { get } from 'lodash'

import colors from 'config/theme/colors'
import { Block, Flex } from 'components/common'
import Button from 'components/button'
import Icon from 'components/icon'
import Resolver from 'components/resolver'
import TemplateEntry from 'components/templates/entry'

import Form from './form'

const contentEntries = getModule('content', 'entries')
const contentTemplates = getModule('content', 'templates')

class ContentEntry extends Component {
  static propTypes = {
    data: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.back = this.back.bind(this)
  }

  render() {
    const { area, contentEntry, contentTemplate, updateAreaView } = this.props

    const entry = contentEntry.entry
    const template = contentTemplate.template

    const syncedEntry = contentEntries.syncEntry(entry, template)

    const id = get(contentEntry, '_id')
    const name = get(contentEntry, 'name')
    const formGroups = get(syncedEntry, 'formGroups')

    const formName = `content-entry-sync-${id}`
    const initialValues = { name, formGroups }

    return (
      <Block>
        <Flex
          alignItems="center"
          background={colors.gray.white}
          borderBottom={`1px solid ${colors.gray.lightest}`}
          fontSize={12}
          height={55}
          lineHeight="55px"
          paddingTop={0}
          paddingRight={15}
          paddingBottom={0}
          paddingLeft={15}
          position="absolute"
          top={100}
          width={420}
        >
          <Button
            onClick={this.back}
            backgroundColor="transparent"
            padding="0px 10px"
          >
            <Icon fontSize={20} name="arrow-left" />
          </Button>
          <span>{name}</span>
        </Flex>
        <Form
          area={area}
          enableReinitialize
          entryId={id}
          form={formName}
          handleCancel={this.back}
          initialValues={initialValues}
          showErrorOnUnTouched
          small
          templateId={contentTemplate._id}
          updateAreaView={updateAreaView}
        >
          <TemplateEntry
            form={formName}
            initialValues={initialValues}
            showErrorOnUnTouched
            small
            templateEntity={contentTemplate}
          />
        </Form>
      </Block>
    )
  }

  back() {
    this.props.updateAreaView({
      isSyncEntryMode: false,
    })
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Resolver(resolver)
)(ContentEntry)

function mapStateToProps(state, props) {
  const { entryId } = props

  const entrySelectors = contentEntries.selectors(state)('all')
  const templateSelectors = contentTemplates.selectors(state)('all')

  const entryResources = entrySelectors.cache()
  const templateResources = templateSelectors.cache()

  const selectedEntry = entryResources[entryId]
  const entry = selectedEntry.entity.asMutable({ deep: true })

  const selectedTemplate = templateResources[entry.template]
  const template = selectedTemplate.entity.asMutable({ deep: true })

  return {
    contentEntry: entry,
    contentTemplate: template,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: id => dispatch(contentTemplates.findById(id)),
  }
}

function resolver(props) {
  const templateId = props.contentEntry.template

  return props.fetch(templateId).then(handleResponse)
}

function handleResponse(response) {
  const { data, error } = response

  if (error) return Promise.reject(new Error(error))

  return data
}
