import produce, { Immutable } from 'immer'
import { isPlainObject } from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { DraftFunction, Updater, useImmer } from 'use-immer'

const CACHE_KEY = 'app-cache'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Cache = Record<string, any>

type RehydrationState = 'idle' | 'rehydrating' | 'rehydrated'

type CacheContextValue = {
  cache: Immutable<Cache>
  clearAllCache: () => void
  rehydrationState: RehydrationState
  setCache: Updater<Cache>
}

const CacheContext = createContext<CacheContextValue>({
  cache: {},
  clearAllCache: () => null,
  rehydrationState: 'idle',
  setCache: () => null,
})

interface UseCacheOptions {
  namespace?: string
}

interface UseCacheResult<T> {
  cache?: Immutable<T>
  clearAllCache?: () => void
  rehydrationState?: RehydrationState
  setCache?: (draftFn: DraftFunction<T>) => void
}

export function useCache<T>(options: UseCacheOptions): UseCacheResult<T> {
  const namespace = options?.namespace
  const { cache, clearAllCache, rehydrationState, setCache } = useContext(
    CacheContext
  )

  if (!namespace) {
    return {
      clearAllCache,
    }
  }

  const namespacedCache = cache[namespace]

  return {
    cache: namespacedCache,
    rehydrationState,
    setCache: (draftFn): void => {
      const nextNamespacedCache = produce(draftFn)(namespacedCache)
      // NOTE setCache from the provider is setup using useImmer so is already
      // wrapped in produce
      setCache(cache => {
        // NOTE this has to spread as a new object for the cache to update correctly
        cache[namespace] = {
          ...nextNamespacedCache,
        }
      })
    },
  }
}

/* eslint-disable react/display-name */
export const withCache = (Component: React.FC) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (props: any): JSX.Element => {
    return (
      <CacheContext.Consumer>
        {(value): JSX.Element => {
          return <Component {...props} {...value} />
        }}
      </CacheContext.Consumer>
    )
  }
}
/* eslint-enable */

interface CacheProviderProps {
  children: React.ReactNode
}

export function CacheProvider(props: CacheProviderProps): JSX.Element {
  const { children } = props

  const [rehydrationState, setRehydrationState] = useState<RehydrationState>(
    'idle'
  )
  const [cache, setCache] = useImmer<Cache>({})

  useEffect(() => {
    rehydrateCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    writeCacheToLocalStorage({ data: cache })
  }, [cache])

  async function rehydrateCache(): Promise<void> {
    console.debug('Cache - ️rehydrating...')
    setRehydrationState('rehydrating')
    const localStorageCache = (await readCacheFromLocalStorage()) as Cache
    setCache(localStorageCache)
    setRehydrationState('rehydrated')
    console.debug('Cache - rehydrated!')
  }

  function handleSetCache(data: Cache): void {
    if (rehydrationState !== 'rehydrated') {
      console.error('CacheError: Can not update until rehydrated!')
      return
    }

    return setCache(data)
  }

  function clearAllCache(): void {
    setCache({})
  }

  return (
    <CacheContext.Provider
      value={{
        cache,
        clearAllCache,
        rehydrationState,
        setCache: handleSetCache,
      }}
    >
      {children}
    </CacheContext.Provider>
  )
}

async function writeCacheToLocalStorage({
  data,
}: {
  data: Cache
}): Promise<void> {
  if (!data) return

  const cacheJson = JSON.stringify(data)
  await window.localStorage.setItem(CACHE_KEY, cacheJson)
}

async function readCacheFromLocalStorage(): Promise<unknown> {
  const cacheJson = await window.localStorage.getItem(CACHE_KEY)

  if (!cacheJson) return {}

  const parsedCache = JSON.parse(cacheJson)

  if (!isPlainObject(parsedCache)) {
    console.warn(
      'Cache is not plain object, not returning to avoid strange side effects'
    )
    return {}
  }

  return parsedCache
}
