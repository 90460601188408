import { getModule, store } from '@lighthouse/sdk'
import moment from 'moment'

import { initialize } from '../tracking'
import emitter from '../utils/emitter'

const logsModule = getModule('logs')

// moment locale
moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mth',
    MM: '%dmth',
    y: '1y',
    yy: '%dy',
  },
})

// analytics tracking
const isProduction = process.env.NODE_ENV === 'production'
const gaTrackingId = isProduction ? 'UA-68932802-3' : 'UA-68932802-4'

initialize({ gaTrackingId, debug: !isProduction })

// handle unhandled errors
const _onerror = window.onerror

window.onerror = (message, url, line, column) => {
  const { dispatch } = store

  dispatch(
    logsModule.record('error', {
      message: 'Uncaught browser error',
      data: { message, url, line, column },
    })
  )
}

if (_onerror && typeof _onerror === 'function') {
  _onerror.apply(window, arguments)
}

// handle uncaught promises
// http://bluebirdjs.com/docs/api/error-management-configuration.html
// NOTE: event name is all lower case as per DOM convention
window.addEventListener('unhandledrejection', e => {
  // NOTE: e.preventDefault() must be manually called to prevent the default
  // action which is currently to log the stack trace to console.warn
  e.preventDefault()

  // NOTE we've seen error instances where `detail` is undefined so adding a fallback
  const { detail = {} } = e

  // NOTE: parameters are properties of the event detail property
  const { promise, reason = {} } = detail

  // Network errors
  // NOTE Usually errors like this are handled within the logic, but that
  // pattern doesn't make as much sense for our state based pattern, where all
  // errors are written to state. Because of that, these errors go unhandled and
  // this is an easy way to catch those and display a notice to the user
  if (/request failed|failed to fetch|network\s?error/i.test(reason.name)) {
    emitter.emit('notification:add', {
      duration: 5000,
      message:
        'There was a problem with the request because of a Network Error',
      messageT: 'alert.networkError',
      title: 'Network Error',
      theme: 'alert',
    })
    return
  }

  if (process.env.NODE_ENV === 'development') {
    emitter.emit('notification:add', {
      message: reason.message || 'Unknown message',
      title: 'Development Error - Unhandled Promise Rejection',
      theme: 'alert',
    })
  }

  console.error('Unhandled Promise Rejection', e)
})

// NOTE: event name is all lower case as per DOM convention
window.addEventListener('rejectionhandled', e => {
  // NOTE: e.preventDefault() must be manually called prevent the default
  // action which is currently unset (but might be set to something in the future)
  e.preventDefault()

  // NOTE we've seen error instances where `detail` is undefined so adding a fallback
  const { detail = {} } = e

  // NOTE: parameters are properties of the event detail property
  const { promise, reason } = detail

  console.error('Promise Rejection Handled', e)
  // See Promise.onUnhandledRejectionHandled for parameter documentation
})
