import { chain, includes, set, some } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common'
import colors from 'config/theme/colors'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'

import ControlWrapper from '../../control-wrapper'
import FieldWrapper from '../../field-wrapper'
import ListItem from '../../list-item/location-group-list-item'
import emitter from 'utils/emitter'

const areaModule = getModule('areas')
const MAX_LOCATIONS_PER_GROUP = 1000
const MAX_GROUPS_PER_LOCATION = 10

export default function LocationGroupList(props) {
  const { updateAreaView, groupsAssignedToLocation, area } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedLocationGroup, setSelectedLocationGroup] = useState(
    groupsAssignedToLocation
  )
  const [disableList, setDisableList] = useState(false)

  const errorMessageMap = {
    GroupAtLocationLimit: t('locationGroup.error.groupAtMaxLocations', {
      maxLocationsPerGroup: MAX_LOCATIONS_PER_GROUP,
    }),
    LocationAtGroupLimit: t('locationGroup.error.locationAtMaxGroups', {
      maxGroupsPerLocation: MAX_GROUPS_PER_LOCATION,
    }),
  }

  const locationGroups = useSelector(state => {
    const areaSelectors = areaModule.selectors(state)()
    return areaSelectors.byType('location-group')
  })

  const [isLoading, setIsLoading] = useState({})
  const [hasError, setHasError] = useState({})

  const onBack = async () => {
    await dispatch(areaModule.findById(area.id))
    updateAreaView({ isAddLocationGroupMode: false })
  }

  const onLocationGroupSelect = async id => {
    setDisableList(true)
    setIsLoading(set(isLoading, id, true))
    const body = await dispatch(areaModule.addLocationsToGroup(id, [area.id]))
    const { data } = body
    const { results } = data
    const { result } = results[0]

    if (result === 'Added') {
      setSelectedLocationGroup([...selectedLocationGroup, id])
      setIsLoading({ [id]: false })
      setDisableList(false)
    } else {
      emitter.emit('notification:add', {
        message:
          errorMessageMap[results[0].result] ||
          'Error adding location group ' + results[0].result,
        title: 'Error',
        theme: 'alert',
      })
      setDisableList(false)
      setIsLoading({ [id]: false })
      setHasError({
        [id]: {
          hasErrored: true,
          error: errorMessageMap[results[0].result],
        },
      })
    }
  }

  const locationGroupItems = chain(locationGroups)
    .sortBy(['entity.name'])
    .map(({ entity }, index) => (
      <ListItem
        active={includes(selectedLocationGroup, entity._id)}
        item={entity}
        key={index}
        display="flex"
        alignItems="center"
        onClick={() => {
          onLocationGroupSelect(entity._id)
        }}
        disabled={disableList}
        isLoading={isLoading[entity._id]}
        hasError={hasError[entity._id]}
      />
    ))
    .value()

  return (
    <Flex>
      <Flex
        background={colors.gray.white}
        borderBottom={`1px solid ${colors.gray.lightest}`}
        fontSize={12}
        paddingTop={20}
        paddingRight={15}
        paddingBottom={20}
        paddingLeft={15}
        width="100%"
      >
        {t('leftPanel.locationGroupView.title')}
      </Flex>
      <FieldWrapper
        backgroundColor={disableList ? colors.gray.lightest : 'transparent'}
        top={156}
      >
        {locationGroupItems}
      </FieldWrapper>
      <ControlWrapper>
        <ButtonGroup>
          <Button onClick={onBack}>{t('button.back')}</Button>
        </ButtonGroup>
      </ControlWrapper>
    </Flex>
  )
}
