import React from 'react'

import { Block } from 'components/common'

export default function FieldWrapper(props) {
  const { children, ...styleProps } = props

  return (
    <Block
      display="block"
      bottom={60}
      fontSize={12}
      overflow="auto"
      position="absolute"
      paddingRight={15}
      paddingLeft={15}
      top={100}
      width="calc(100% - 30px)"
      transition="0.5s"
      {...styleProps}
    >
      {children}
    </Block>
  )
}
