export const dateTime = {
  default: 'ddd, MMM D h:mm:ssa z', // Mon, Jun 01 00:00:00am AEST
  date: 'D MMM YYYY', // 6 June 2017
  datetime: 'D/M/YY H:mma', // 1/6/17 00:00am
  full: 'MM/DD/YYYY HH:mm:ss Z', // 06/01/2017 00:00:00 +10:00
  noAttr: 'ddd, MMM D h:mm:ssa', // Mon, Jun 01 00:00:00am
  timedate: 'H:mma D/M/YY', // 0:00am 01/6/17
}

export default dateTime
