import colors from 'config/theme/colors'

export default {
  root: {
    padding: '20px 0',
    borderStyle: 'solid',
    borderColor: colors.gray.lighter,
    borderWidth: 0,
  },

  bordered: {
    borderTopWidth: 0,
  },

  boxed: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    padding: 30,
    marginBottom: 30,
  },

  description: {
    color: colors.gray.light,
    marginBottom: 30,
    marginTop: -5,
  },

  title: {
    fontSize: 26,
    fontWeight: '100',
    color: colors.gray.normal,
    padding: '0 0 20px 0',
    margin: 0,
    borderBottom: `1px solid ${colors.gray.lighter}`,
  },
}
