import Promise from 'bluebird'
import {
  each,
  filter,
  find,
  groupBy,
  isEmpty,
  map,
  size,
  sortBy,
  sum,
  toString,
  trim,
} from 'lodash'
import moment, { duration } from 'moment-timezone'

import {
  getLocationReference,
  getTimezoneAbbr,
  getTimezoneDatetime,
} from '../../helpers'
import { launchIcon } from '../../images'
import {
  generateDefinition,
  horizontalLine,
  summaryFieldsTable,
  summaryStatTable,
  summaryWrapperTable,
  table,
  text,
  threeColumnTable,
  zebraFillColor,
} from '../helpers'

const LIGHTHOUSE_BASE_URL = 'https://app.lighthouse.io'

/**
 * buildActivityPdf
 * @param {object} pdfOptions - the pdf options
 * @param {string} pdfOptions.fileTitle - pdf file title
 * @param {function} pdfOptions.footer - function executed to generate footer
 * @param {function} pdfOptions.header - function executed to generate header
 * @param {string} pdfOptions.logoUrl - pdf logo url
 * @param {array} pdfOptions.pageMargins - pdf page margins
 * @param {string} pdfOptions.pageOrientation - pdf page orientation
 * @param {string} pdfOptions.pageSize - pdf page size
 * @param {object} pdfOptions.styles - pdf styles
 * @param {object} pdfOptions.title - pdf title
 * @param {object} data - pdf data
 * @param {array} data.audits - audit documents
 * @param {array} data.events - event documents
 * @param {array} data.locations - location documents
 * @param {array} data.issues - issue documents
 * @param {array} data.audits - audit documents
 * @param {object} data.shift - shift document
 * @param {object} data.settings - settings properties
 * @param {string} data.settings.awsS3BaseUrl - aws S3 base url
 * @param {string} data.settings.cloudinaryBaseUrl - cloudinary base url
 * @param {array} data.tasks - task documents
 * @param {string} data.timestamp - timestamp string
 * @param {string} data.timezone - timezone string
 * @param {array} data.zones - zone documents
 * @param {array} data.user - user document
 * @returns {Promise} returns pdfmake definition object
 */

export async function buildShiftPdf(pdfOptions, data) {
  const { timestamp, timezone } = data

  const title = 'Daily Shift Report'
  const content = await generateContent(data)

  return generateDefinition({
    content,
    fileTitle: title,
    timestamp,
    timezone,
    type: 'Shift Report',
    ...pdfOptions,
  })
}

async function generateContent(data) {
  const activitySection = await buildActivitySection(data)
  const summarySection = buildSummarySection(data)
  const titleTable = buildTitleTable(data)

  return [titleTable, summarySection, activitySection]
}

async function buildActivitySection(data) {
  const timeline = await buildTimelineTable(data)
  const activitySummary = await buildActivitySummarySection(data)

  return [timeline, activitySummary]
}

function buildTitleTable(data) {
  const { shift, timezone, user } = data
  const { breaks, duration, end, start } = shift

  const headerTitle = text('Daily Shift Report', { style: 'title' })
  const username = `${user.firstName} ${user.lastName}` || 'Unknown User'

  const headerSubTitle = text(`${username}`, {
    style: 'subTitle',
  })

  const mTimezoneStart = moment(start.time).tz(timezone)
  const mTimezoneEnd = moment(end.time).tz(timezone)

  const rangeStart = moment(mTimezoneStart).format('MMM DD h:mma')
  const rangeEnd = moment(mTimezoneEnd).format('MMM DD h:mma')

  const shiftDuration = getFineDuration(duration)
  const breakDuration = getBreakDuration(breaks)

  const header = !breakDuration
    ? `${rangeStart} - ${rangeEnd} | Duration: ${shiftDuration}`
    : `${rangeStart} - ${rangeEnd} | Duration: ${shiftDuration}, Break: ${breakDuration}`
  const headerRange = text(header, {
    style: 'subTitle2',
  })

  return table({
    body: [[headerTitle], [headerSubTitle], [headerRange]],
    layout: 'noBorders',
    style: 'titleTable',
  })
}

function buildSummarySection(data) {
  const { audits, events, issues, tasks } = data

  const eventsByType = groupBy(events, 'type')

  const enterEventsCount = size(eventsByType.enter)
  const issuesCount = size(issues)
  const tasksCount = size(tasks)
  const auditsCount = size(audits)

  const scansText = text('SCANS', { style: 'summaryText' })
  const issuesText = text('ISSUES', { style: 'summaryText' })
  const tasksText = text('TASKS', { style: 'summaryText' })
  const auditsText = text('AUDITS', { style: 'summaryText' })

  const scansCountText = text(toString(enterEventsCount), {
    style: 'summaryCount',
  })
  const issuesCountText = text(toString(issuesCount), { style: 'summaryCount' })
  const tasksCountText = text(toString(tasksCount), { style: 'summaryCount' })
  const auditsCountText = text(toString(auditsCount), { style: 'summaryCount' })

  const scanTable = summaryStatTable({ body: [[scansText], [scansCountText]] })
  const issueTable = summaryStatTable({
    body: [[issuesText], [issuesCountText]],
  })
  const taskTables = summaryStatTable({ body: [[tasksText], [tasksCountText]] })
  const auditTable = summaryStatTable({
    body: [[auditsText], [auditsCountText]],
  })

  const wrapperTable = summaryWrapperTable({
    body: [[scanTable, issueTable, taskTables, auditTable]],
  })

  return wrapperTable
}

async function buildActivitySummarySection(data) {
  const { audits, events, issues, settings, tasks, timezone } = data

  const enterEvents = filter(events, event => event.type === 'enter')

  const auditRows = map(audits, getFormRowData('audits', data))
  const issueRows = map(issues, getFormRowData('issues', data))
  const scanRows = map(enterEvents, getScanRowData(data))
  const taskRows = map(tasks, getFormRowData('tasks', data))

  const rows = [...auditRows, ...issueRows, ...scanRows, ...taskRows]
  const sortedRows = sortBy(rows, ['timestamp'], ['asc'])
  const title = text('Activity Summary', { style: 'summarySectionHeader' })

  const header = table({
    body: [[title]],
    layout: 'noBorders',
    style: 'summaryHeaderTable',
  })

  const hLine = horizontalLine()
  const activityTable = await buildActivityTable({
    rows: sortedRows,
    settings,
    timezone,
  })
  const activitySummarySection = [header, hLine, activityTable]

  return activitySummarySection
}

async function buildActivityTable({ rows, settings, timezone }) {
  const timezoneAbbr = getTimezoneAbbr(timezone)

  const activityTableHeader = [
    text('Activity', { bold: true }),
    { text: '' },
    text('Location', { bold: true }),
    text(`Timestamp (${timezoneAbbr})`, { alignment: 'right', bold: true }),
  ]

  const activityTableRows = await Promise.map(rows, async function(data) {
    const { link, location, icon, summaryFields, timestamp, title } = data

    const timezoneHour = getTimezoneDatetime({
      format: 'ddd DD h:mm a',
      showTzAbbr: false,
      timestamp,
      timezone,
    })

    const iconCell = icon
      ? { alignment: 'center', fit: [8, 8], image: icon, link }
      : { text: '' }

    const rows = [
      [
        text(title, { link }),
        iconCell,
        text(location, { link }),
        text(timezoneHour, { alignment: 'right', link }),
      ],
    ]

    const hasSummaryFields = !isEmpty(summaryFields)

    if (hasSummaryFields) {
      const fieldsTable = await summaryFieldsTable({
        fields: summaryFields,
        settings,
        timezone,
      })
      rows.push([fieldsTable])
    }

    const activityTableRow = [
      table({
        body: [...rows],
        colSpan: 5,
        dontBreakRows: true,
        headerRow: 0,
        layout: {
          hLineWidth: () => 0,
          paddingLeft: () => 0,
          paddingRight: () => 0,
          paddingTop: () => 5,
          paddingBottom: () => 5,
          vLineWidth: () => 0,
        },
        widths: [120, 25, 200, 110, '*'],
      }),
    ]

    return activityTableRow
  })

  const activityTableBody = isEmpty(activityTableRows)
    ? [[text('No other activity', { colSpan: 4 })]]
    : activityTableRows

  const activityTable = table({
    body: [activityTableHeader, ...activityTableBody],
    dontBreakRows: true,
    layout: {
      hLineWidth: () => 0,
      fillColor: zebraFillColor,
      paddingLeft: () => 5,
      paddingRight: () => 5,
      paddingTop: () => 5,
      paddingBottom: () => 5,
      vLineWidth: () => 0,
    },
    widths: [110, 15, 230, 80, '*'],
  })

  return activityTable
}

function getFormRowData(collection, { locations, zones }) {
  return document => {
    const { _id, createdAt, entry = {}, title = 'Unknown' } = document

    const location = getLocationReference({
      entity: document,
      locations,
      zones,
    })

    const link = `${LIGHTHOUSE_BASE_URL}/reports/${collection}/${_id}`
    const timestamp = createdAt

    const summaryFields = entry.summaryFields || []

    return {
      icon: launchIcon,
      location,
      link,
      summaryFields,
      timestamp,
      title,
    }
  }
}

function getScanRowData({ zones }) {
  return document => {
    const { timestamp, zone = '' } = document

    const zoneDoc =
      zone && find(zones, doc => doc._id.toString() === zone.toString())

    const zoneName = zoneDoc && zoneDoc.name
    const location = zoneName || 'Unknown Location'

    const title = 'Location Scan'

    return {
      location,
      timestamp,
      title,
    }
  }
}

async function buildTimelineTable(data) {
  const { timezone } = data
  const timezoneAbbr = getTimezoneAbbr(timezone)

  const shiftRows = getShiftRowData(data)

  const sortedRows = sortBy(shiftRows, ['timestamp'], ['asc'])
  const timeline = await Promise.map(sortedRows, async function(data) {
    const { location, timestamp, title } = data

    const timezoneHour = getTimezoneDatetime({
      format: 'ddd DD h:mm a',
      showTzAbbr: false,
      timestamp,
      timezone,
    })

    const rows = [
      text(title),
      text(location),
      text(timezoneHour, { alignment: 'right' }),
    ]

    return rows
  })

  const timelineHeaderRow = [
    text('Activity', { bold: true }),
    text('Location', { bold: true }),
    text(`Timestamp (${timezoneAbbr})`, { alignment: 'right', bold: true }),
  ]

  const timelineTable = threeColumnTable({
    body: [timelineHeaderRow, ...timeline],
  })

  const title = text('Timeline', { style: 'timelineTitle' })

  const header = table({
    body: [[title]],
    layout: 'noBorders',
    style: 'summaryHeaderTable',
  })

  const hLine = horizontalLine()

  const timelineSection = [header, hLine, timelineTable]

  return timelineSection
}

function getShiftRowData({ locations, shift, zones }) {
  const { breaks, end, start } = shift

  const startValues = getShiftValues(start, { locations, shift, zones })
  const startShift = { title: 'Shift Started', ...startValues }
  const endValues = getShiftValues(end, { locations, shift, zones })
  const endShift = { title: 'Shift Ended', ...endValues }

  const shiftData = [startShift, endShift]

  each(breaks, function(data) {
    const { end, start } = data
    const startValues = getShiftValues(start, { locations, shift, zones })
    const startBreak = { title: 'Break Started', ...startValues }
    const endValues = getShiftValues(end, { locations, shift, zones })
    const endBreak = { title: 'Break Ended', ...endValues }

    shiftData.push(startBreak)
    shiftData.push(endBreak)
  })

  return shiftData
}

function getShiftValues(value, { locations, shift, zones }) {
  const locationName = getLocationReference({
    entity: value,
    locations,
    zones,
  })

  const location =
    locationName === 'Unknown Location'
      ? getLocationReference({
          entity: shift,
          locations,
          zones,
        })
      : locationName

  return {
    timestamp: value.time,
    location,
  }
}

export function getFineDuration(durationValue) {
  const measurement = 'milliseconds'
  const durationByMeasurement = duration(durationValue, measurement)
  const days = durationByMeasurement.get('days')
  const hours = durationByMeasurement.get('hours')
  const minutes = durationByMeasurement.get('minutes')
  let fineDuration = ''
  fineDuration += days !== 0 ? days + 'd ' : ''
  fineDuration += hours !== 0 ? hours + 'h ' : ''
  fineDuration += minutes !== 0 ? minutes + 'm' : ''

  return trim(fineDuration)
}

function getBreakDuration(breaks) {
  const duration = sum(
    map(breaks, function(data) {
      const { end, start } = data
      const endBreak = moment(end.time)
      const startBreak = moment(start.time)
      const breakDuration = endBreak.diff(startBreak)

      return breakDuration
    })
  )

  const breakDuration = getFineDuration(duration)

  return breakDuration
}
