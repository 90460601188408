import { combineReducers } from 'redux'
import crud from '../../crud'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

const resource = 'schedules'

export const actions = crud.actions(resource)

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn(resource),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

export const actionCreators = Object.assign(
  {},
  crudActionCreators,
)

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})
