import {
  isRequired,
} from '../../validation'

export default {
  beaconUuid: {
    label: 'UUID',
    name: 'beacon.uuid',
  },
  beaconMajor: {
    label: 'Major',
    name: 'beacon.major',
  },
  beaconMinor: {
    label: 'Minor',
    name: 'beacon.minor',
  },
  label: {
    label: 'Label',
    name: 'label',
    required: true,
    validate: [isRequired()],
  },
  metaId: {
    label: 'Id',
    name: 'meta.id',
  },
  metaNotes: {
    label: 'Notes',
    name: 'meta.notes',
  },
  tags: {
    label: 'Label',
    name: 'tags',
  },
}
