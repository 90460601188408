import { colors as brandColors } from 'config/theme/brand'
import colors from 'config/theme/colors'

export default {
  wrapper: {
    position: 'relative',
  },
  navItem: {
    borderColor: 'transparent',
    color: colors.gray.dark,
    cursor: 'pointer',
    textDecoration: 'none',
    transition: '0.3s',
    ':hover': {
      backgroundColor: colors.gray.lighter,
      color: colors.gray.darker,
    },
  },
  primaryActive: {
    color: colors.blue.normal,
    backgroundColor: colors.gray.white,
  },
  secondary: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.white,
    ':hover': {
      borderBottomColor: colors.gray.lighter,
      backgroundColor: colors.white,
      color: colors.black,
    },
  },
  secondaryActive: {
    color: brandColors.primary,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: brandColors.primary,
    ':hover': {
      borderBottomColor: brandColors.primary,
      backgroundColor: colors.white,
      color: brandColors.primary,
    },
  },
  icon: {
    fontSize: '32px',
  },
}
