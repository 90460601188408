import { upperCase } from 'lodash'
import moment from 'moment-timezone'

import {
  getAssigneesFullNames,
  getGeometryGeocodedString,
  getStatusDetails,
  getUserFullName,
  getTimezoneDatetime,
} from '../'

export function getJobDetails(data) {
  const { entity = {}, timezone, users } = data

  const {
    assignees,
    author,
    createdAt,
    description = '',
    due,
    geometryReverseGeocoded,
    reference = '',
    status,
  } = entity

  const assigneeNames = getAssigneesFullNames(users, assignees)
  const authorFullName = getUserFullName(users, author)
  const locationText = getGeometryGeocodedString(geometryReverseGeocoded)

  const statusProps = getStatusDetails(status)
  const statusStyle = statusProps.style
  const statusColor = statusStyle.color
  const statusText = upperCase(statusProps.text)

  const createdTimezoneDateTime = getTimezoneDatetime({
    timestamp: createdAt,
    timezone,
  })
  const dueDateTimezoneDateTime = getTimezoneDatetime({
    timestamp: due,
    timezone,
  })
  const timezoneAbbrText = moment.tz(timezone).format('z')

  return {
    assigneeNames,
    authorFullName,
    createdTimezoneDateTime,
    description,
    dueDateTimezoneDateTime,
    locationText,
    statusStyle,
    statusColor,
    statusText,
    reference,
    timezoneAbbrText,
  }
}
