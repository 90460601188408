export function horizontalLine(options = {}) {
  const { margin = [0, 0, 0, 0] } = options

  return {
    layout: {
      hLineWidth: (i, node) =>
        i === 0 || i === node.table.body.length ? 0 : 0.5,
      vLineWidth: () => 0,
      hLineColor: () => '#ddd',
    },
    style: 'horizontalLine',
    table: {
      widths: ['*'],
      body: [[' '], [' ']],
    },
    margin,
  }
}
