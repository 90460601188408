import { map, take } from 'lodash'
import compose from 'recompose/compose'
import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'
import React from 'react'

import { Block } from 'components/common'
import Button from 'components/button'

import Item from './components/item'

export default compose(
  withState('isCollapsed', 'setIsCollapsed', false),
  withHandlers({ setCollapsed })
)(Timeline)

function Timeline(props) {
  if (!props.timeline.length) {
    return (
      <Block paddingBottom={20} paddingTop={20}>
        No Timeline data could be found
      </Block>
    )
  }

  const itemCount = props.timeline.length
  const itemEntries = map(props.timeline, Item)

  const data =
    itemCount > 3 && !props.isCollapsed ? take(itemEntries, 3) : itemEntries

  return (
    <Block paddingBottom={20} paddingTop={20}>
      <Block paddingBottom={10}>{data}</Block>
      {itemCount > 3 && (
        <Button onClick={props.setCollapsed} size="small">
          {props.isCollapsed ? 'Show less' : 'Show all'}
        </Button>
      )}
    </Block>
  )
}

function setCollapsed(props) {
  return () => {
    props.setIsCollapsed(!props.isCollapsed)
  }
}
