/**
 * A module that check permissions for you.
 * @module ask
 */
import { isArray, every, isEmpty, includes, reduce, filter, find, isUndefined, intersection, difference, union } from 'lodash'
import logger from '../logger'

/**
 * Check if the given module and its filters have permissions for an action on `document`
 * @param  {Array<Object>}   permissions An array of permission objects.
 * @param  {String}   action   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   options Options for permission check.
 * @return None
 * @see module:check-permissions~ask
 */
export default function checkDocuments(permissions, action, options) {
  const documentIds = options.documentIds
    // Array type will have an impact on `hasPermission` check, so check here first
  if (isArray(documentIds)) {
    logger('`documentIds` should not be an array')
    return false
  }

  // Check `documentIds` for `delete` and `update`. For `read` it's optional because we may not need an id for `index` operation
  // Commenting out this block as it is not necessary for now to check permissions on a individual document level
  // if ((action === 'update' || action === 'delete') && !isModuleDocumentIdProvided(documentIds, options.module)) {
  //   logger('Try to validate document permissions for `delete` or `update` without module document id provided')
  //   return false
  // }
  let filters = options.filters || []
  if (action !== 'create') {
    filters = union(filters, [options.module]) // The module itself should be considered as a filter when action is not `create`
  }

  const permissionFilters = generateFilters(filters, permissions, action, options)

  if (!hasPermission(documentIds, permissionFilters)) {
    logger(options.err || 'Access Denied')
    return false
  }

  // Re-generate filters for 'read' action, this is what we need for the query plugins
  const readFilters = generateFilters(filters, permissions, 'read', options)
  return readFilters
}

/**
 * Check if id(s) of this module is contained in documentIds.
 * @param  {Object}  documentIds Object of ids that you want to ask permission for.
 * @param  {String}  moduleName  Name of the module.
 * @return {Boolean}  Return true if id(s) of this module is contained in documentIds, otherwise return false.
 */
function isModuleDocumentIdProvided(documentIds, moduleName) {
  return documentIds && documentIds[moduleName]
}

/**
 * Check if it has document permission for all the filters
 * @param  {Object}  documentIds Object of ids that you want to ask permission for.
 * @param  {Array<String>}  filters   An array of filters including permitted or rejected ids.
 * @return {Boolean}   Return true if it has document permission for all the filters, otherwise return false.
 */
function hasPermission(documentIds, filters) {
  return every(filters, (dependence, name) => {
    let filterDocumentIds = documentIds ? documentIds[name] : []
    const isIncludes = !isUndefined(dependence.includes)
    const filterType = isIncludes ? 'includes' : 'excludes'
    const filterIds = dependence[filterType]
    if (filterDocumentIds && !isArray(filterDocumentIds)) {
      filterDocumentIds = [filterDocumentIds]
    }

    // `difference` and `intersection` both work fine even if `filterDocumentIds` is undefined
    if (isIncludes) {
      return !isEmpty(filterIds) && isEmpty(difference(filterDocumentIds, filterIds))
    }

    return isEmpty(intersection(filterDocumentIds, filterIds))
  })
}

/**
 * Generate filters of some modules for an action
 * @param  {Array<String>} modules     An array of module names
 * @param  {Array<Object>}   permissions An array of permission objects.
 * @param  {String}   action   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   options Options for permission check.
 * @return {Object}  An Object with an array of permitted of rejected ids.
 *
 *    Example:
 *    {
 *      location: {
 *        includes: ['1','2'] // or excludes: ['1','2']
 *      }
 *    }
 */
function generateFilters(modules, permissions, action, options) {
  const isWriteAction = action !== 'read'

  return reduce(modules, (idFilter, moduleName) => {
    const isModulePermission = moduleName === options.module
    const skipDependencyCheck = isWriteAction && !isModulePermission
    const dependenceFilter = !skipDependencyCheck &&
          createFilter(moduleName, permissions, action, options)
    if (!isEmpty(dependenceFilter)) {
      idFilter[moduleName] = dependenceFilter
    }
    return idFilter
  }, {})
}

/**
 * Create a filter with permitted or rejected ids.
 * @param  {String} moduleName Module name
 * @param  {Array<Object>}   permissions An array of permission objects.
 * @param  {String}   action   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   options Options for permission check.
 * @return {Object}  An Object with an array of permitted of rejected ids.
 *
 *    Example:
 *    { includes: ['1','2'] } or { excludes: ['1','2'] }
 */
function createFilter(moduleName, permissions, action, options) {
  const result = {}
  const isItself = moduleName === options.module
  const strategy = isItself ? options.strategy : getStrategy(permissions, moduleName)
  const type = isItself ? action : 'read'
  const filteredPermissions = filter(permissions, {
    module: moduleName,
    type: 'document'
  })

  if (!isEmpty(filteredPermissions)) {
    const isInclude = strategy === 'include'
    const label = isInclude ? 'includes' : 'excludes'
    result[label] = reduce(filteredPermissions, (values, permission) => {
      const accessible = includes(permission.access, type)
      if ((isInclude && accessible) || (!isInclude && !accessible)) {
        values = union(values, [permission.value])
      }
      return values
    }, [])
  }

  return result
}

function getStrategy(permissions, moduleName) {
  const permission = find(permissions, {
    module: moduleName
  })
  return (permission && permission.strategy) ? permission.strategy : 'exclude'
}
