import { includes } from 'lodash'

import {
  MARKER_ASSET,
  MARKER_AUDIT,
  MARKER_EXCEPTION,
  MARKER_ISSUE,
  MARKER_SHIFT,
  MARKER_TASK,
} from './constants'

export default function isActivityMarker(type) {
  return includes([
    MARKER_ASSET,
    MARKER_AUDIT,
    MARKER_EXCEPTION,
    MARKER_ISSUE,
    MARKER_SHIFT,
    MARKER_TASK,
  ], type)
}
