import { REQUEST } from '../../../middleware/request'
import { omit } from 'lodash'

export default function buildActionCreators(actions, baseUrlFn, headersFn, paramsFn) {
  return {
    endBreak,
    endShift,
    postMessagesResponsesToWinTeam,
    startBreak,
  }

  function endBreak(payload, params = {}) {
    return (dispatch, getState) => {
      const state = getState()
      const { breakId, shiftId } = payload

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/${shiftId}/breaks/${breakId}`
      const headers = headersFn(params, state)
      const sanitizedParams = omit(params, 'applicationId', 'locationId')
      return dispatch(endRequest(endpoint, headers, sanitizedParams, payload))
    }
  }

  function endShift(payload, params = {}) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/action/end`
      const headers = headersFn(params, state)
      const sanitizedParams = omit(params, 'applicationId', 'locationId')
      return dispatch(endRequest(endpoint, headers, sanitizedParams, payload))
    }
  }

  function endRequest(endpoint, headers, params, payload) {
    return {
      [REQUEST]: {
        types: [actions.END_REQUEST, actions.END_SUCCESS, actions.END_ERROR],
        method: 'PUT',
        endpoint,
        headers,
        body: payload,
        query: params,
      },
    }
  }

  function postMessagesResponsesToWinTeam(shiftId, messageId, payload, params = {}) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/${shiftId}/messages/${messageId}`
      const headers = headersFn(params, state)
      const sanitizedParams = omit(params, 'applicationId', 'locationId')
      return dispatch(endRequest(endpoint, headers, sanitizedParams, payload))
    }
  }

  function saveRequest(endpoint, headers, params, payload) {
    return {
      [REQUEST]: {
        types: [actions.SAVE_REQUEST, actions.SAVE_SUCCESS, actions.SAVE_ERROR],
        method: 'POST',
        endpoint,
        headers,
        body: payload,
        query: params,
      },
    }
  }

  function startBreak(payload, params = {}) {
    return (dispatch, getState) => {
      const state = getState()
      const { shiftId } = payload

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/${shiftId}/breaks`
      const headers = headersFn(params, state)
      const sanitizedParams = omit(params, 'applicationId', 'locationId')
      return dispatch(saveRequest(endpoint, headers, sanitizedParams, payload))
    }
  }
}
