import { get } from 'lodash'
import { getSetListFiltersDataFn } from '../../tracking/helpers'

const category = 'Maps'

const typeMappings = {
  auditentries: 'audits',
  loopexceptions: 'exceptions',
  taskentries: 'tasks',
}

export const setListFiltersFn = getSetListFiltersDataFn({ category, label: labelFn })

function labelFn(state, action) {
  const filterType = get(action, 'filters.type', 'All')
  return typeMappings[filterType] || filterType
}
