import { AUTHENTICATION_UNSET, UNAUTHENTICATE_SUCCESS } from '../modules/authentication'
import { SET_APPLICATION } from '../modules/app'

export const version = require('../../package.json').version
// recycle actions are ones that should reset the state tree. We use
// the unset authentication action as when a user logs out we want to
// clear their data. We might go for a more fine grained approach in
// the future instead of wrapping the whole reducer
export const defaultRecycleActions = [
  UNAUTHENTICATE_SUCCESS,
  AUTHENTICATION_UNSET,
  SET_APPLICATION,
]

export const SEND_TRACKING = 'lighthouse/SEND_TRACKING'
