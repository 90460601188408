import { isBoolean, isEmpty, isObjectLike } from 'lodash'

export function parseValue(value) {
  // map switches to text
  if (isBoolean(value)) {
    return value ? 'Yes' : 'No'
  }
  // handle empty arrays & objects
  if (isObjectLike(value) && isEmpty(value)) {
    return ''
  }

  return value || ''
}
