import {
  defaults,
  get,
  isFunction,
  join,
  keys,
  map,
  mapValues,
  isDate,
} from 'lodash'

export { getErrorDataFn, getSetListFiltersDataFn, getSuccessDataFn }

function getErrorDataFn(options) {
  const opts = defaults(options, {
    action: 'Error',
    label: onErrorLabelFn,
  })

  return getTrackingDataFn(opts)
}

function getSetListFiltersDataFn(options) {
  const opts = defaults(options, {
    action: onSetListFiltersActionFn,
    label: onSetListFiltersLabelFn,
  })

  return getTrackingDataFn(opts)
}

function getSuccessDataFn(options) {
  const opts = defaults(options, { action: 'Success' })

  return getTrackingDataFn(opts)
}

function buildProps(options, state, action) {
  return mapValues(options, value => (
    isFunction(value) ? value(state, action) : value
  ))
}

function getTrackingDataFn(options) {
  return function analyticFn(state, action) {
    const opts = defaults(options, { hitType: 'event' })
    const props = buildProps(opts, state, action)

    return { trackType: 'send', props }
  }
}

function getCacheValue(state, value, key) {
  if (!value) {
    return 'All'
  }

  if (key === 'user') {
    return value
  }

  if ((key === 'from' || key === 'to') && isDate(value)) {
    return value.toDateString()
  }

  return get(state, `${key}s.cache.${value}.entity.name`)
}

function getCacheValues(state, filters) {
  return join(map(filters, (value, key) => getCacheValue(state, value, key)), '/')
}

function onErrorLabelFn(state, action) {
  const code = String(get(action, 'error.code', 'Unknown Error Code'))
  const name = String(get(action, 'error.name', 'Unknown Error Name'))

  return `${name} (${code})`
}

function onSetListFiltersActionFn(state, action) {
  const { filters } = action
  const filterKeys = join(keys(filters), '/')

  return `Filtered by ${filterKeys}`
}

function onSetListFiltersLabelFn(state, action) {
  const { filters } = action

  return getCacheValues(state, filters)
}
