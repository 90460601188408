import constants from 'config/constants'

export const ISSUE_STATUS_OPTIONS = [
  { label: constants.STATUS_OPEN_TEXT, value: constants.STATUS_OPEN },
  {
    label: constants.STATUS_IN_PROGRESS_TEXT,
    value: constants.STATUS_IN_PROGRESS,
  },
  { label: constants.STATUS_CLOSED_TEXT, value: constants.STATUS_CLOSED },
]

export const SERVICE_LEVEL_OPTIONS = [
  {
    label: constants.SERVICE_LEVEL_ABOVE,
    value: constants.SERVICE_LEVEL_ABOVE,
  },
  {
    label: constants.SERVICE_LEVEL_ON,
    value: constants.SERVICE_LEVEL_ON,
  },
  {
    label: constants.SERVICE_LEVEL_BELOW,
    value: constants.SERVICE_LEVEL_BELOW,
  },
]

export const SHIFT_STATUS_OPTIONS = [
  { label: constants.STATUS_OPEN_TEXT, value: constants.STATUS_OPEN },
  { label: constants.STATUS_CLOSED_TEXT, value: constants.STATUS_CLOSED },
]

export const ROLE_FILTER_MESSAGE =
  'It looks like you have a role filter applied. Please note that only reports generated since Aug 2020 will appear using this filter.'

export default {
  ISSUE_STATUS_OPTIONS,
  ROLE_FILTER_MESSAGE,
  SERVICE_LEVEL_OPTIONS,
}
