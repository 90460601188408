import { WithPermissions } from '@lighthouse/react-components'
import { getModule } from '@lighthouse/sdk'
import { feature, featureCollection } from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'

import {
  attempt,
  chain,
  get,
  filter,
  find,
  includes,
  isEmpty,
  isError,
  isUndefined,
  map,
  noop,
  pick,
} from 'lodash'

import { compose, onlyUpdateForKeys, withHandlers, withState } from 'recompose'

import React, { useState, useEffect } from 'react'
import { GeoJSON, Pane, withLeaflet, Popup } from 'react-leaflet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Chevron from 'components/chevron'
import { Block, Flex } from 'components/common'
import queryString from 'query-string'

import MapLabel from '../../../components/maps/label'
import colors from '../../../../../config/theme/colors'
import {
  TYPE_BUILDING,
  TYPE_GEOFENCE,
  TYPE_LOCATION,
} from '../../../lib/constants'
import { geoStyles } from '../../../lib/geo-styles'

import emitter from 'utils/emitter'

const layerOrdering = {
  location: 0,
  geofence: 1,
  building: 2,
  floor: 3,
  corridor: 4,
  transition: 5,
  room: 6,
  wall: 7,
  way: 8,
  object: 9,
  point: 10,
}

const areaModule = getModule('areas')

const areaClickHandlers = {
  building: selectBuildingHandler,
  location: selectLocationHandler,
  default: selectAreaHandler,
}

export default compose(
  withRouter,
  WithPermissions,
  withLeaflet,
  connect(mapStateToProps, mapDispatchToProps),
  onlyUpdateForKeys([
    'buildingHasImage',
    'filters',
    'isEditingLayerEnabled',
    'mapOptions',
    'outdoorAreas',
    'selectedBuilding',
    'urlId',
    'urlResource',
    'zoom',
  ]),
  withState('positionLabelMode', 'setPositionLabelMode', false),
  withHandlers({ onAreaClick }),
  withHandlers({
    onEachFeature,
    getAreasAsGeoJson,
  })
)(Geo)

function Geo(props) {
  const { buildingHasImage, getAreasAsGeoJson, onEachFeature, zoom } = props

  // CLEANUP #117829
  const featureCollection = getAreasAsGeoJson()

  // NOTE labels like this might not feasible for larger locations
  const labels = map(featureCollection.features, mapLabel(zoom))

  // NOTE we're using key to force a re-render of geojson layer. We keep this
  // under control with onlyUpdateForKeys
  return (
    <Pane style={{ zIndex: buildingHasImage ? 399 : 400 }}>
      <MapClickableLayer {...props} />
      <GeoJSON
        key={+Date.now()}
        data={featureCollection}
        onEachFeature={onEachFeature}
      />
      {labels}
    </Pane>
  )
}

function MapClickableLayer({
  areasByType,
  leaflet,
  selectedBuilding,
  urlId,
  isGraphLayerEnabled,
}) {
  const [locations, setLocations] = useState([])
  const [openPopup, setOpenPopup] = useState(false)
  const [position, setPosition] = useState({})

  useEffect(() => {
    leaflet.map.on('click', onMapClick)

    emitter.on('map:set-building', () => {
      leaflet.map.on('click', noop)
    })

    return () => {
      leaflet.map.off('click')
      emitter.off('map:set-building')
    }
  })

  function onMapClick(event) {
    // NOTE Disables click handlers firing when the graph layer is enabled
    if (isGraphLayerEnabled) return

    const {
      latlng: { lat, lng },
    } = event

    const point = { coordinates: [lng, lat], type: 'Point' }
    const featurePoint = attempt(feature, point)

    if (isError(featurePoint)) return

    const areaLocations = areasByType(TYPE_LOCATION)
    const intersectedLocations = filter(
      areaLocations,
      isIntersectingPoint(featurePoint)
    )

    const areaBuildings = areasByType(TYPE_BUILDING)
    const intersectedBuilding = find(
      areaBuildings,
      isIntersectingPoint(featurePoint)
    )

    const areaGeofences = areasByType(TYPE_GEOFENCE)
    const intersectedGeofence = find(
      areaGeofences,
      isIntersectingPoint(featurePoint)
    )

    const singleLocation = intersectedLocations.length === 1

    if (singleLocation && !intersectedBuilding && !intersectedGeofence) {
      const { entity } = intersectedLocations[0]
      selectLocationHandler({}, entity)
      setLocations([])

      return
    }

    if (intersectedBuilding || intersectedGeofence) {
      const intersectedBuildingId = get(intersectedBuilding, 'entity._id')
      const intersectedGeofenceId = get(intersectedGeofence, 'entity._id')
      const selectedBuildingId = selectedBuilding.id

      setOpenPopup(false)

      if (intersectedBuildingId && intersectedBuildingId === selectedBuildingId)
        return

      if (intersectedGeofenceId && intersectedGeofenceId === urlId) return
    }

    if (singleLocation && (intersectedBuilding || intersectedGeofence)) {
      const parentLocationId =
        get(intersectedBuilding, 'entity.childOf.0') ||
        get(intersectedGeofence, 'entity.childOf.0')

      const { entity } = find(intersectedLocations, ['id', parentLocationId])
      selectLocationHandler({}, entity)
      setLocations([])

      return
    }

    setPosition({ lat, lng })
    setLocations(intersectedLocations)
    setOpenPopup(true)
  }

  function isIntersectingPoint(featurePoint) {
    return area => {
      const geometry = get(area, 'entity.geometry')
      const polygon = attempt(feature, geometry)

      if (isError(polygon)) {
        return false
      }

      return booleanPointInPolygon(featurePoint, polygon)
    }
  }

  function onLocationClick(id) {
    const location = locations.find(location => location.id === id)
    const { entity } = location
    selectLocationHandler({}, entity)
    setOpenPopup(false)
  }

  const hasLocations = !!locations.length

  if (!hasLocations || !openPopup) {
    return null
  }

  const optionsList = map(locations, ({ entity: { _id: id, name } }, index) => {
    const isLastOption = locations.length === index + 1
    return (
      <Flex
        color={colors.black}
        cursor="pointer"
        flexGrow={1}
        key={id}
        height="100%"
        fontSize={12}
        hoverBackgroundColor={colors.gray.lightest}
        title={name}
        onClick={() => onLocationClick(id)}
      >
        <Flex
          alignItems="center"
          borderBottomColor={colors.gray.lighter}
          borderBottomStyle="solid"
          borderBottomWidth={isLastOption ? 0 : 1}
          flex={1}
          padding={15}
        >
          <Block
            whiteSpace="nowrap"
            width={170}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {name}
          </Block>
        </Flex>
        <Flex
          alignItems="center"
          borderBottomColor={colors.gray.lighter}
          borderBottomStyle="solid"
          borderBottomWidth={isLastOption ? 0 : 1}
          justifyContent="center"
          width="14%"
          paddingRight={15}
        >
          <Chevron right thin />
        </Flex>
      </Flex>
    )
  })

  return (
    <Popup
      autoClose={true}
      className="leaflet-popup-map"
      closeButton={true}
      closeOnClick={false}
      closeOnEscape={false}
      offset={[0, -7]}
      position={position}
    >
      {optionsList}
    </Popup>
  )
}

function onMouseover({ style }) {
  return e => {
    e.target.setStyle(style)
  }
}

function onMouseout({ style }) {
  return e => {
    e.target.setStyle(style)
  }
}

function getAreasAsGeoJson(props) {
  const {
    areaCache,
    filters,
    getIndoorAreas,
    mapOptions,
    outdoorAreas,
    selectedBuilding: { id: buildingId, floor },
    urlId,
  } = props

  const areaId = buildingId || urlId
  const area = areaId && get(areaCache[areaId], 'entity')
  const isLocation = area && area.type === TYPE_LOCATION
  const parentLocationId =
    areaId && isLocation ? areaId : get(area, 'childOf.0')

  const isLocationFilterOn = !!filters.types.location

  const buildingAreas = buildingId
    ? filter(getIndoorAreas(buildingId), area => {
        if (!area || !area.entity) return false
        if (area.entity.type === 'point') return false

        if (isUndefined(floor)) return true

        return includes(area.entity.floorsRef, floor)
      })
    : []

  const filteredOutdoorAreas = filter(outdoorAreas, area => {
    if (!area || !area.entity) return false
    if (area.entity.type === 'point') return false

    const childOf = get(area, 'entity.childOf.0')
    const type = get(area, 'entity.type')
    const isLocation = type === TYPE_LOCATION
    const isGeofence = type === TYPE_GEOFENCE
    const isGeofencesVisible = mapOptions.geofences

    if (isGeofence)
      return parentLocationId
        ? isGeofencesVisible && childOf === parentLocationId
        : isGeofencesVisible

    const hasFloor = !isEmpty(area.entity.floorsRef)

    if (hasFloor) return false

    if (isLocation || !parentLocationId) return true

    return childOf === parentLocationId
  })

  return () => {
    const features = isLocationFilterOn
      ? chain(buildingAreas)
          .concat(filteredOutdoorAreas)
          .uniqBy('id')
          .compact()
          .sortBy(sortAreaByType)
          .map(mapAreaToGeoJSON)
          .value()
      : []

    return featureCollection(features)
  }
}

function onEachFeature(props) {
  const {
    buildingHasImage,
    isEditingLayerEnabled = false,
    onAreaClick,
    positionLabelMode,
    selectedBuilding = {},
    urlId,
    urlResource,
  } = props

  return (feature, layer) => {
    const { _id, type } = feature.properties

    const layerStyles =
      !!buildingHasImage && type === 'building'
        ? geoStyles.buildingHasImage
        : geoStyles[type] || {}

    const layerActiveStyle = layerStyles.active || {}
    const layerDefaultStyle = layerStyles.default
    const layerHoverStyle = layerStyles.hover || {}

    const isBuilding = type === 'building'
    const isLocation = type === TYPE_LOCATION
    const isSelected = urlId === _id && urlResource === 'area'
    const isTargeted = positionLabelMode === _id

    layer.setStyle(geoStyles.default)
    layer.setStyle(layerDefaultStyle)

    const mouseOver = !isSelected
      ? onMouseover({ style: layerHoverStyle })
      : noop

    const mouseOut = !isSelected
      ? onMouseout({ style: layerDefaultStyle })
      : noop

    const onClick =
      isLocation || (!isBuilding && isEditingLayerEnabled) ? noop : onAreaClick

    layer.on({
      click: onClick,
      mouseover: mouseOver,
      mouseout: mouseOut,
    })

    if (isSelected) {
      layer.setStyle(layerActiveStyle)
    }

    if (isTargeted) {
      layer.setStyle({
        className: 'leaflet-area-targeted',
      })
    }

    layer.on('remove', ({ target }) => {
      target.off()
    })
  }
}

function sortAreaByType(area) {
  const { type } = area.entity
  const order = layerOrdering[type]
  return order
}

function mapAreaToGeoJSON({ entity }) {
  const properties = pick(entity, [
    '_id',
    'areaSize',
    'center',
    'geometry',
    'lineDistance',
    'labelAnchor',
    'name',
    'type',
  ])
  return feature(entity.geometry, properties)
}

function selectAreaHandler(props, properties) {
  const { hasModulePermission, history } = props
  const { _id } = properties

  const hasTemplateReadPermission = !!hasModulePermission('template', 'read')

  if (!hasTemplateReadPermission) return

  const nextSearch = queryString.stringify({
    resource: 'area',
    id: _id,
  })

  history.push({ search: `?${nextSearch}` })
}

function selectBuildingHandler(props, geojsonProperties) {
  const { _id: buildingId } = geojsonProperties

  emitter.emit('map:set-building', {
    id: buildingId,
    fitToBounds: !props.isEditingLayerEnabled,
  })
}

function selectLocationHandler(props, geojsonProperties) {
  const { _id: id, name, geometry } = geojsonProperties

  emitter.emit('map:set-location', {
    id,
    geometry,
    openSidebar: true,
  })

  emitter.emit('search:set-location', {
    geometry,
    label: name,
    value: id,
  })
}

function onAreaClick(props) {
  return e => {
    const properties = get(e, 'target.feature.properties', {})
    const { latlng } = e

    if (props.positionLabelMode) {
      const areaId = properties._id
      const labelAnchor = {
        type: 'Point',
        coordinates: [latlng.lng, latlng.lat],
      }
      props.setPositionLabelMode(false)
      return props.saveArea(areaId, {
        labelAnchor,
      })
    }

    const handler =
      areaClickHandlers[properties.type] || areaClickHandlers.default

    handler(props, properties)
  }
}

function mapLabel(zoom) {
  return item => {
    const { center, labelAnchor, lineDistance, name, type } = item.properties
    const isBuilding = type === 'building'
    const isRoom = type === 'room'
    const isFloor = type === 'floor'

    if (!isRoom && !isBuilding) return null

    if (!name || !lineDistance || (!center && !labelAnchor)) {
      return null
    }

    const smallArea = lineDistance < 0.05
    const mediumArea = lineDistance < 0.1
    const largeArea = lineDistance < 0.2
    const size = 'small'

    // NOTE some rudimentary logic to show/hide labels at certain zoom levels
    if (smallArea && zoom < 21) {
      return null
    }

    if (mediumArea && zoom < 19) {
      return null
    }

    if (largeArea && zoom < 19) {
      return null
    }

    const offset = isFloor && '20px'
    // Use labelAnchor prop for position or fall back to center value
    const position =
      (labelAnchor && labelAnchor.coordinates) || center.coordinates
    const latlng = {
      lat: position[1],
      lng: position[0],
    }
    const key = item.properties._id

    return (
      <MapLabel
        key={key}
        latlng={latlng}
        offset={offset}
        size={size}
        showMarker={isRoom}
      >
        {item.properties.name}
      </MapLabel>
    )
  }
}

function mapStateToProps(state) {
  const areaSelectors = areaModule.selectors(state)
  const filters = get(state, 'geo.markers.filters')

  const getIndoorAreas = listId => areaSelectors(listId).list()
  const areasByType = areaSelectors().byType
  const outdoorAreas = areaSelectors().outdoors
  const nearestByType = areaSelectors().nearestByType
  const areaCache = state.areas.cache

  return {
    areaCache,
    areasByType,
    filters,
    getIndoorAreas,
    nearestByType,
    outdoorAreas,
    properties: state.geo.properties,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveArea: (id, payload) => dispatch(areaModule.save({}, payload, id)),
  }
}
