import { get } from 'lodash'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'

import { Block } from 'components/common'
import colors from 'config/theme/colors'
import TemplateEntry from 'components/templates/entry'

import Form from './form'

class Entry extends Component {
  static propTypes = {
    data: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  render() {
    const {
      area,
      contentTemplate,
      readOnly,
      updateAreaView,
      updateWizard,
    } = this.props

    const formName = 'content-entry-new'
    const formGroups = get(contentTemplate, 'template.formGroups')
    const name = get(contentTemplate, 'name')

    const initialValues = { name: '', formGroups }
    const title = `New ${name} Entry`

    return (
      <Block>
        <Block
          borderBottom={`1px solid ${colors.gray.lightest}`}
          fontSize={12}
          paddingTop={20}
          paddingRight={15}
          paddingBottom={20}
          paddingLeft={15}
        >
          {title}
        </Block>
        <Form
          area={area}
          form={formName}
          initialValues={initialValues}
          readOnly={readOnly}
          small
          submitCallback={this.handleSubmit}
          templateId={contentTemplate._id}
          updateAreaView={updateAreaView}
          updateWizard={updateWizard}
        >
          <TemplateEntry
            form={formName}
            initialValues={initialValues}
            small
            templateEntity={contentTemplate}
          />
        </Form>
      </Block>
    )
  }

  handleSubmit(entryId) {
    this.props.updateAreaView({
      isNewEntryMode: false,
      selectedEntryId: entryId,
    })
  }
}

export default Radium(Entry)
