/**
 * Logs Middleware
 * Simple piece of middleware to trigger the sending of any logs in the logger
 * queue. This doesn't do anything with the action in particular, we just use it
 * as a nudge to process errors instead of having to rely on timers
 */

import {
  get,
  isFunction,
  map,
  throttle,
} from 'lodash'

import {
  remove,
  setStatus,
  STATUS_IDLE,
} from '../'

const THROTTLE_DELAY = 15000 // 15s

const throttledProcessLogs = throttle(processLogs, THROTTLE_DELAY, {
  leading: true,
  trailing: true,
})

export default options => store => next => (action) => {
  const { logger } = options

  throttledProcessLogs(logger, store)
  return next(action)
}

export function processLogs(logger, store) {
  const { getState, dispatch } = store

  const state = getState()
  const isOnline = get(state, 'offline.online')

  if (!isOnline) {
    console.info('Skipping sending logs because network is offline')
    return Promise.resolve()
  }

  const logQueue = get(state, 'logs.queue')
  const applicationId = get(state, 'app.applicationId')
  const locationId = get(state, 'app.locationId')
  const userId = get(state, 'user.data._id')
  const sessionId = get(state, 'app.sessionId')
  const version = get(state, 'version')

  // NOTE we never reject any of the promises so not to
  // skip any remaining items in array
  return Promise.all(map(logQueue, (record, id) => {
    const logFn = logger[record.level]

    if (!isFunction(logFn)) {
      console.info('Removing log record as no matching level function')
      return Promise.resolve(dispatch(remove([id])))
    }

    const log = {
      message: record.message,
      data: record.data,
      timestamp: record.timestamp,
      applicationId,
      locationId,
      sessionId,
      userId,
      version,
    }

    return logFn.call(logger, log)
      .then(() => Promise.resolve(dispatch(remove([id]))))
      .catch((error) => {
        console.error(`Error sending data: ${error.message}`)
        return Promise.resolve(dispatch(setStatus([id], STATUS_IDLE)))
      })
  }))
}
