import { filter, flow, forEach, map, reduce } from 'lodash/fp'
import moment from 'moment-timezone'

import {
  Behaviours,
  Durations,
  Schema as ServiceHoursSchema,
  Types,
} from '../../service-hours'
import { Interval, Unit } from '../scheduling.types'
import {
  convertToTimezone,
  intervalCovers,
  intervalOnlyIntersectsEnd,
  intervalOnlyIntersectsStart,
  intervalWithin,
  mergeIntervals,
  splitIntervals,
} from '../helpers'

interface ServiceIntervalsGenerator {
  readonly end: number
  readonly serviceHours: ServiceHoursSchema
  readonly start: number
}

/**
 * Generates service intervals between start and end range for service hours
 */
export function* serviceIntervalsGenerator(props: ServiceIntervalsGenerator) {
  const { end, serviceHours, start } = props
  const { timezone } = serviceHours

  const hasValidStartAndEnd = end > start && start < end

  if (!hasValidStartAndEnd) return []

  const mStart = moment.tz(start, timezone)
  const mEnd = moment.tz(end, timezone)
  const mStartValid = mStart.isValid()
  const mEndValid = mEnd.isValid()

  if (!mStartValid || !mEndValid) return []

  const { hours } = serviceHours
  const rangeInterval = [start, end]

  const closeOverrideHours = filter(
    { behaviour: Behaviours.Close, type: Types.Override },
    hours
  )
  const defaultHours = filter({ type: Types.Default }, hours)
  const openOverrideHours = filter(
    { behaviour: Behaviours.Open, type: Types.Override },
    hours
  )

  // NOTE: we must apply the timezone to overrides as they are stored in UTC
  // and must be converted to timestamps in the timezone before processing
  const closeIntervals = reduce(
    (memo, override) => {
      const mIntervalStart = moment.tz(override.start, timezone)
      const mIntervalEnd = moment.tz(override.end, timezone)

      const isInPast = mIntervalEnd.isBefore(mStart)
      const isInFuture = mIntervalStart.isAfter(mEnd)

      // NOTE: filter out any interval not relevant
      if (isInPast || isInFuture) {
        return memo
      }

      const start = convertToTimezone(override.start, timezone)
      const end = convertToTimezone(override.end, timezone)

      memo.push([start, end])

      return memo
    },
    [],
    closeOverrideHours
  )

  const openIntervals = reduce(
    (memo, override) => {
      const mIntervalStart = moment.tz(override.start, timezone)
      const mIntervalEnd = moment.tz(override.end, timezone)

      const isInPast = mIntervalEnd.isBefore(mStart)
      const isInFuture = mIntervalStart.isAfter(mEnd)

      // NOTE: filter out any interval not relevant
      if (isInPast || isInFuture) {
        return memo
      }

      const start = convertToTimezone(override.start, timezone)
      const end = convertToTimezone(override.end, timezone)

      memo.push([start, end])

      return memo
    },
    [],
    openOverrideHours
  )

  let weekStart = mStart.startOf(Unit.Week).valueOf()

  const defaultIntervals = []

  while (weekStart < end) {
    forEach(hour => {
      // NOTE: hour start and end values are the number of minutes from the
      // start of the week so are simply offsets
      const { end: endOffset, start: startOffset } = hour
      const hourStart = weekStart + startOffset
      const hourEnd = weekStart + endOffset

      const interval = [hourStart, hourEnd]

      const isIntervalIntersectingRangeEnd = intervalOnlyIntersectsEnd(
        interval,
        rangeInterval
      )
      const isIntervalIntersectingRangeStart = intervalOnlyIntersectsStart(
        interval,
        rangeInterval
      )
      const isIntervalInsideRange = intervalWithin(interval, rangeInterval)
      const isRangeInsideInterval = intervalWithin(rangeInterval, interval)

      const shouldSkip =
        !isIntervalInsideRange &&
        !isRangeInsideInterval &&
        !isIntervalIntersectingRangeStart &&
        !isIntervalIntersectingRangeEnd

      if (shouldSkip) return

      const nextStart =
        isRangeInsideInterval || isIntervalIntersectingRangeStart
          ? start
          : hourStart

      const nextEnd =
        isRangeInsideInterval || isIntervalIntersectingRangeEnd ? end : hourEnd

      defaultIntervals.push([nextStart, nextEnd])
    }, defaultHours)

    weekStart = weekStart + Durations.Week
  }

  // NOTE: only include intervals which intersect our range and then map to
  // ensure the intervals conform to the range interval
  const getOverrideIntervalsForRange = flow(
    filter(
      (interval: Interval): boolean =>
        interval[0] >= rangeInterval[0] || interval[0] <= rangeInterval[1]
    ),
    map(
      (interval: Interval): Interval => {
        const intervalCoversRange = intervalCovers(interval, rangeInterval)
        const intervalIntersectsRangeEnd = intervalOnlyIntersectsEnd(
          interval,
          rangeInterval
        )
        const intervalIntersectsRangeStart = intervalOnlyIntersectsStart(
          interval,
          rangeInterval
        )

        const intervalStart = intervalIntersectsRangeStart
          ? rangeInterval[0]
          : interval[0]
        const intervalEnd = intervalIntersectsRangeEnd
          ? rangeInterval[1]
          : interval[1]

        return intervalCoversRange
          ? rangeInterval
          : [intervalStart, intervalEnd]
      }
    )
  )

  // NOTE: for simplicity we calculate all default service intervals and then
  // apply the open and closed overrides, we can't do this as we iterate
  // through the weeks as open and close overrides can potentially intersect
  // across weeks
  const rangeOpenIntervals = getOverrideIntervalsForRange(openIntervals)
  const rangeCloseIntervals = getOverrideIntervalsForRange(closeIntervals)

  const mergedIntervals = mergeIntervals([
    ...defaultIntervals,
    ...rangeOpenIntervals,
  ])
  const serviceIntervals = splitIntervals(mergedIntervals, rangeCloseIntervals)

  for (let interval of serviceIntervals) yield interval
}
