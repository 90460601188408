import { useState } from 'react'

interface Input {
  key: string
}

type setToggle = (value: boolean) => boolean
type Output = [boolean, setToggle]

export default function useToggle({ key }: Input): Output {
  if (!key) {
    throw new Error('key option is required for useToggle')
  }

  const namespacedKey = `lio:${key}`

  const persistedValue = sessionStorage.getItem(namespacedKey)

  const [toggle, setToggle] = useState(
    namespacedKey ? persistedValue === 'true' : true
  )

  function handleToggleChange(value: boolean): boolean {
    setToggle(value)
    sessionStorage.setItem(namespacedKey, String(value))
    return value
  }

  return [toggle, handleToggleChange]
}
