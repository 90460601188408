import PropTypes from 'prop-types'
import React from 'react'

import { colors } from 'config/theme'
import { Flex } from 'components/common'
import Button from 'components/button'
import Icon from 'components/icon'

export default function Header(props) {
  const { children, handleClose } = props

  return (
    <Flex
      borderBottom={`1px solid ${colors.gray.lightest}`}
      borderTop={`1px solid ${colors.gray.lightest}`}
      justifyContent="space-between"
      height={60}
      width="100%"
    >
      {children}
      <Button
        backgroundColor={colors.white}
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        height={60}
        hoverBackgroundColor={colors.gray.lightest}
        marginRight={0}
        width={60}
        onClick={handleClose}
      >
        <Icon name="close" theme={{ fontSize: 20 }} />
      </Button>
    </Flex>
  )
}

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleClose: PropTypes.func.isRequired,
}
