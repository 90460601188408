import { getModule } from '@lighthouse/sdk'
import { chain, find, get, has, map, noop, toNumber } from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import {
  Field,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
} from 'redux-form'
import Promise from 'bluebird'
import PropTypes from 'prop-types'
import React from 'react'

import { Wrapper } from 'components/common'
import { Crud } from 'components/controls'
import {
  BasicForm,
  FieldGroup,
  FieldSet,
  InputSelect,
  InputText,
} from 'components/form'
import {
  getErrorCount,
  handleFormError,
  handleSaveResponse,
} from 'components/form/helpers'
import Resolver from 'components/resolver'
import TitleBar from 'modules/title-bar'
import { useTranslation } from 'react-i18next'

const applicationUsersModule = getModule('applicationUsers')
const roomsModule = getModule('messages', 'rooms')

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Resolver(resolve),
  withProps(getInitialProps),
  withHandlers({
    deleteRoom,
    fetchMore,
    isRowLoaded,
    saveRoom,
  }),
  reduxForm()
)(MessagesEntry)

function MessagesEntry({
  applicationUsers,
  deleteRoom,
  dirty,
  errorCount,
  fetchMore,
  formName,
  handleSubmit,
  history,
  invalid,
  isRowLoaded,
  refreshing,
  reset,
  roomId,
  saveRoom,
  speakerOptions,
  submitting,
  userOptions,
}) {
  const { t } = useTranslation()

  const title =
    roomId === 'new' ? t('labelCreateNewGroup') : t('labelEditGroup')
  const loadMoreRows = refreshing ? noop : () => fetchMore()

  return (
    <Wrapper>
      <BasicForm noValidate>
        <TitleBar title={title} />
        <FieldGroup>
          <FieldSet>
            <Field
              {...roomsModule.schema.name}
              label={t('labelGroupName')}
              placeholder={t('placeholder.chooseAGroupName')}
              component={InputText}
              dataTestId="group-name-field"
            />
            <Field
              {...roomsModule.schema.participants}
              label={t('labelParticipants')}
              placeholder={t('placeholder.selectParticipants')}
              component={InputSelect}
              multi
              options={userOptions}
              refreshing={refreshing}
              loadMoreRows={loadMoreRows}
              isRowLoaded={isRowLoaded}
              infiniteScrolling
              showSelectAll={true}
              selectAllLimit={500}
              showRemoveItems={true}
              type="participant"
            />
          </FieldSet>
        </FieldGroup>
        <Crud
          deleteModalMessage={t('modal.delete.messageGroup.message')}
          deleteModalTitle={t('modal.delete.messageGroup.title')}
          dirty={dirty}
          errorCount={errorCount}
          handleCancel={history.goBack}
          handleDelete={deleteRoom}
          handleSave={handleSubmit(saveRoom)}
          /**
           * NOTE: setting id for existing rooms
           * shows the delete crud action
           */
          id={roomId !== 'new' && roomId}
          invalid={invalid}
          name={formName}
          reset={reset}
          saveText={t('button.saveGroup')}
          submitting={submitting}
        />
      </BasicForm>
    </Wrapper>
  )
}

MessagesEntry.propTypes = {
  dirty: PropTypes.bool.isRequired,
  errorCount: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  userOptions: PropTypes.array.isRequired,
}

function mapStateToProps(state, props) {
  const roomId = props.match.params.id || 'new'
  const form = `form-groups-${roomId}`

  const errors = getFormSyncErrors(form)(state)
  const errorCount = getErrorCount(errors)
  const selectors = applicationUsersModule.selectors(state)('all')
  const formSelector = formValueSelector(form)
  const formName = formSelector(state, 'name') || ''

  const getUserFullName = selectors.getUserFullName
  const list = state['applicationUsers'].list['default']

  return {
    applicationUsers: selectors.list(),
    errors,
    errorCount,
    form,
    formName,
    getUserFullName,
    list,
    refreshing: selectors.state === 'resolving',
    roomCache: state.messages.rooms.cache,
    roomId,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationUsers: () =>
      dispatch(
        applicationUsersModule.query('all', {
          perPage: 9999,
          sort: 'firstName',
        })
      ),
    fetchRoom: id => dispatch(roomsModule.findById(id)),
    paginate: opts =>
      dispatch(applicationUsersModule.setPaginationOpts('default', opts)),
    removeFromCache: id => dispatch(roomsModule.removeFromCache(id)),
    removeRoomRequest: id => dispatch(roomsModule.remove(id)),
    saveRoomRequest: (id, data) => dispatch(roomsModule.save({}, data, id)),
    setRoom: id => dispatch(roomsModule.setCurrent(id)),
  }
}

function deleteRoom(props) {
  const { history, removeFromCache, removeRoomRequest, roomId, setRoom } = props
  setRoom(null)

  return () =>
    removeRoomRequest(roomId)
      .then(() => history.push('/messages'))
      .catch(err => console.error(err))
}

function getInitialProps(props) {
  const { applicationUsers, getUserFullName, roomId, roomCache = {} } = props

  const existingRoom = roomCache[roomId]
  const initialValues = existingRoom ? getRoomValues(existingRoom) : {}

  return {
    initialValues,
    userOptions: parseUserOptions({
      applicationUsers,
      getUserFullName,
    }),
  }
}

function getRoomValues({ entity = {} }) {
  const { name, speakers = [] } = entity || {}

  return {
    name,
    speakers: map(speakers, 'speaker'),
  }
}

function parseSpeakers({ currentRoom = {}, inputSpeakers }) {
  const existingSpeakers = get(currentRoom, 'entity.speakers', null)

  return map(inputSpeakers, speaker => {
    const matching = find(existingSpeakers, ['speaker', speaker])

    return (
      matching || {
        active: true,
        notify: true,
        speaker,
      }
    )
  })
}

function parseUserOptions({ applicationUsers = [] }) {
  return chain(applicationUsers)
    .filter(user => has(user, 'entity.speakerbox.speakerId'))
    .map(user => {
      const { fullName, username } = get(user, 'entity.user', {})

      // NOTE: we tried passing <UserFullName /> here, but it breaks
      // the dropdown menu search
      const label = `${fullName} (${username})`
      const value = get(user, 'entity.speakerbox.speakerId')

      return { label, value }
    })
    .value()
}

function resolve(props) {
  const { fetchApplicationUsers, fetchRoom, roomId } = props
  const isNew = roomId === 'new'

  const promises = {
    users: fetchApplicationUsers(),
    ...(!isNew && { room: fetchRoom(roomId) }),
  }

  return Promise.props(promises)
}

function saveRoom(props) {
  const { history, roomCache = {}, roomId, saveRoomRequest, setRoom } = props

  return formValues => {
    const { name, speakers: inputSpeakers } = formValues
    const id = roomId === 'new' ? null : roomId

    const currentRoom = roomCache[roomId] || {}
    const speakers = parseSpeakers({ currentRoom, inputSpeakers })

    const payload = {
      name,
      speakers,
    }

    return saveRoomRequest(id, payload)
      .then(handleSaveResponse('room'))
      .then(({ data = {} }) => {
        const roomId = data._id
        setRoom(roomId)
        history.push('/messages')
      })
      .catch(handleFormError)
  }
}

function fetchMore(props) {
  const { fetchApplicationUsers, list, paginate } = props

  return () => {
    const nextPage = get(list, 'pagination.links.next.page', '1')
    const pageOffset = toNumber(nextPage)
    paginate({ page: pageOffset })
    return fetchApplicationUsers({ page: pageOffset })
  }
}

function isRowLoaded({ userOptions }) {
  return ({ index }) => !!userOptions[index]
}
