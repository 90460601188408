import {
  isRequired,
} from '../../validation'

export default {
  name: {
    label: 'Author',
    name: 'author',
    placeholder: 'Select Author',
  },
  assignees: {
    label: 'Assignees',
    name: 'assignees',
    placeholder: 'Select Assignees',
  },
  status: {
    label: 'Status',
    name: 'status',
    placeholder: 'Select Status',
    required: true,
    validate: [isRequired()],
  },
  title: {
    label: 'Title',
    name: 'title',
    placeholder: 'Title',
    required: true,
    validate: [isRequired()],
  },
  description: {
    label: 'Description',
    name: 'description',
    placeholder: 'Description',
  },
  due: {
    label: 'Date Due',
    name: 'due',
    placeholder: 'Select Date',
  },
}
