import { get, isEmpty, map } from 'lodash'
import moment from 'moment-timezone'

import {
  generateDefinition,
  horizontalLine,
  table,
  text,
  twoColumnTable,
} from '../helpers'

import {
  getJobDetails,
  getPrettyDuration,
  getTimezoneDatetime,
  getUserFullName,
} from '../../helpers'

const ACTIVITY_COLUMN_HEADERS = [
  { text: 'Type', bold: true },
  { text: 'User', bold: true },
  { text: 'Start Time', bold: true },
  { text: 'Start Location', bold: true },
  { text: 'End Time', bold: true },
  { text: 'End Location', bold: true },
  { text: 'Duration', bold: true },
]

/**
 * buildJobPdf
 *
 * @param {object} pdfOptions - the pdf options
 * @param {string} pdfOptions.fileTitle - pdf file title
 * @param {function} pdfOptions.footer - function executed to generate footer
 * @param {function} pdfOptions.header - function executed to generate header
 * @param {string} pdfOptions.logoUrl - pdf logo url
 * @param {array} pdfOptions.pageMargins - pdf page margins
 * @param {string} pdfOptions.pageOrientation - pdf page orientation
 * @param {string} pdfOptions.pageSize - pdf page size
 * @param {object} pdfOptions.styles - pdf styles
 * @param {object} pdfOptions.title - pdf title
 * @param {object} data - pdf data
 * @param {object} data.entity - job document
 * @param {array} data.jobActivities - job activities documents
 * @param {object} data.settings - settings properties
 * @param {string} data.settings.awsS3BaseUrl - aws S3 base url
 * @param {string} data.settings.cloudinaryBaseUrl - cloudinary base url
 * @param {string} data.timezone - timezone string
 * @param {object} data.users - application user documents
 * @returns {Promise} returns pdfmake definition object
 */
export function buildJobPdf(pdfOptions, data) {
  const { entity, timezone } = data

  const timestamp = entity.createdAt
  const title = entity.title || 'Unknown'

  const fileTitle = `Job Report - ${title}`

  const content = generateContent(data)

  return generateDefinition({
    content,
    fileTitle,
    pageOrientation: 'landscape',
    timestamp,
    timezone,
    type: 'Job',
    ...pdfOptions,
  })
}

function generateContent(data) {
  const { entity } = data
  const { title } = entity

  const entityDetails = getJobDetails(data)

  const metaTableData = [
    [{ text: 'Location', bold: true }, entityDetails.locationText],
    [{ text: 'Author', bold: true }, entityDetails.authorFullName],
    [{ text: 'Reference', bold: true }, entityDetails.reference],
    [{ text: 'Description', bold: true }, entityDetails.description],
    [{ text: 'Due Date', bold: true }, entityDetails.dueDateTimezoneDateTime],
    [{ text: 'Assignees', bold: true }, entityDetails.assigneeNames],
    [
      { text: 'Status', bold: true },
      { text: entityDetails.statusText, color: entityDetails.statusColor },
    ],
    [{ text: 'Created', bold: true }, entityDetails.createdTimezoneDateTime],
    [{ text: 'Timezone', bold: true }, entityDetails.timezoneAbbrText],
  ]

  const headerTitle = text(`Job Report: ${title}`, { style: 'title' })

  const titleTable = table({
    body: [[headerTitle]],
    layout: 'noBorders',
    style: 'titleTable',
  })

  const metaDataTable = twoColumnTable({
    body: metaTableData,
    widths: [150, '*'],
  })

  const activitiesTitleTable = table({
    body: [['Job Activities']],
    layout: 'noBorders',
    style: 'activitiesTable',
  })

  const activityTableData = buildActivitiesContent(data)
  const activityTable = table(activityTableData)
  const hLine = horizontalLine()

  return [
    titleTable,
    metaDataTable,
    activitiesTitleTable,
    hLine,
    activityTable,
    hLine,
  ]
}

function buildActivitiesContent(data) {
  const { jobActivities, timezone, users } = data

  if (isEmpty(jobActivities)) {
    return {
      body: [['No Job Activities']],
      widths: '*',
    }
  }

  const activityRows = map(jobActivities, activity => {
    const { entity } = activity

    const { type, duration, user, start = {}, end = {} } = entity

    const authorText = getUserFullName(users, user)

    const mDuration = moment.duration(duration, 'minutes')
    const durationText = duration ? getPrettyDuration(mDuration) : ''

    const startTime = start.time
    const startLocation = get(start, 'geometryReverseGeocoded.label', '')

    const endTime = end.time
    const endLocation = get(end, 'geometryReverseGeocoded.label', '')

    const startTimeText = startTime
      ? getTimezoneDatetime({
          showTzAbbr: false,
          timestamp: startTime,
          timezone,
        })
      : ''

    const endTimeText = endTime
      ? getTimezoneDatetime({
          showTzAbbr: false,
          timestamp: endTime,
          timezone,
        })
      : ''

    return [
      type,
      authorText,
      startTimeText,
      startLocation,
      endTimeText,
      endLocation,
      durationText,
    ]
  })

  return {
    body: [ACTIVITY_COLUMN_HEADERS, ...activityRows],
    widths: [70, 80, 60, '*', 60, '*', 50],
  }
}
