import { assign, each, get, map, noop } from 'lodash'
import { request } from '@lighthouse/sdk'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import React from 'react'
import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'

import Button from 'components/button'
import FlagsHOC from 'components/flags/hoc'
import Icon from 'components/icon'
import Spinner from 'components/spinner'
import { withRequest } from 'components/useRequest'

import geojson2areas from './lib/geojson2areas'

const buttonStyle = { width: 42 }

const inputStyle = {
  height: 40,
  opacity: 0,
  position: 'absolute',
  width: 42,
}

export default compose(
  connect(mapStateToProps),
  withRequest,
  FlagsHOC,
  withState('loading', 'setLoading', false),
  withHandlers({ saveAreas }),
  withHandlers({ handleChange })
)(GeojsonUploader)

function GeojsonUploader(props) {
  if (!props.isLighthouseAdmin) {
    return null
  }

  const { location } = props

  return (
    <div>
      {location ? (
        <Button noPadding theme="simple" style={buttonStyle}>
          {props.loading ? (
            <Spinner size="small" type="dots" />
          ) : (
            <div>
              <input
                multiple
                onChange={props.handleChange}
                style={inputStyle}
                type="file"
              />
              <Icon fontSize={24} name="upload" />
            </div>
          )}
        </Button>
      ) : (
        noop
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    applicationId: state.app.applicationId,
    location: get(state, 'geo.location.id'),
  }
}

function handleChange(props) {
  const { applicationId, location, saveAreas, setLoading } = props

  return event => {
    const files = event.target.files

    each(files, file => {
      const reader = new FileReader()

      reader.onload = (f => e => {
        const newGeoJSON = e.target.result

        if (!newGeoJSON) return

        let parsedGeoJSON

        try {
          parsedGeoJSON = JSON.parse(newGeoJSON)
        } catch (err) {
          console.error(err)
          window.alert('Invalid GeoJSON')
        }

        const parsedAreas = geojson2areas(parsedGeoJSON)
        const areaCount = parsedAreas.length

        const didConfirm = window.confirm(`
          Upload ${f.name} with ${areaCount} areas?
        `)

        if (!didConfirm) {
          return console.info('Aborting upload of areas')
        }

        setLoading(true)

        const mappedAreas = map(parsedAreas, area => {
          switch (get(area, 'type')) {
            case 'building':
            case 'geofence':
              assign(area, { childOf: location })
              break
          }

          return assign({}, area, { application: [applicationId] })
        })

        saveAreas(null, mappedAreas)
          .then(() => window.alert(`${f.name}: Areas successfully uploaded`))
          .catch(err => {
            console.error(err)
            window.alert(`Filename: ${f.name}\nError: ${err}`)
          })
          .finally(() => setLoading(false))
      })(file)

      reader.readAsText(file)
    })
  }
}

function saveAreas(props) {
  const { request } = props

  return (id, areas) => {
    const body = areas
    const opts = {
      body,
      method: 'POST',
    }

    const path = '/areas'

    return request(path, opts)
  }
}
