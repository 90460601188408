import { noop } from 'lodash'
import * as ga from './ga'

const trackFns = { set, send }

export function initialize(options) {
  ga.initialize(options)
}

export function set(props) {
  ga.set(props)
}

export function setUserId(id) {
  ga.setUserId(id)
}

export function send(props) {
  ga.send(props)
}

export function track(trackType, props) {
  const trackFn = trackFns[trackType] || noop
  return trackFn(props)
}

export default track
