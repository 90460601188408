/*
 * Loop Servicing Module
 */

import { combineReducers } from 'redux'
import crud from '../../crud'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

const resource = 'loopServicing'

export const actions = crud.actions(resource)
export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn('loop-servicing'),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})
export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})
