/**
 * A module that check permissions for you.
 * @module ask
 */
import checkModule from './check-module'
import checkDocuments from './check-documents'
import checkFields from './check-fields'
import validate from './validate'
import logger from '../logger'

/**
 * Ask permissions for an action with some options
 *
 * @param  {Array<Object>}   permissions An array of permission objects.
 *
 *     Example:
 *     [{
 *       module: 'location',  // Name of the module
 *       type: 'field', // Enum of 'module', 'document' and 'field'
 *       value: String, // String, (optional if type is module, otherwise required) either document id or property path of field
 *       access: ['create','read'], // An array of 'create', 'read', 'update' and 'delete'
 *       strategy: 'exclude' // only make sense when type is module, either 'include' or 'exclude', if it's not given, then by default it's 'exclude'
 *     }]
 *
 *    The property `strategy` has an impact on the way you check `document` permissions.
 *    If you do not set a strategy, by default it is 'exclude', which means if you do not define a permission for a document, then it has full permissions.
 *    And "include" means if you do not define a permission for a document, then it has no permission for all request types.
 * @param  {String}   forAction   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   withOpts Options for permission check.
 *
 *      Example
 *      {
 *        module: 'zone',  // Required, name of the module
 *        documentIds: {location: [...], zone: [...]}, // Object of ids that you want to ask permission for
 *        filters: ['location'], // An array of modules that are depended on by this module.
 *        body: body, // Required if you want to ask permission for fields when you want to `create` or `update`
 *        err: err // A user defined error
 *     }
 * @param  {Function} callback A Callback function for the next step.
 *     The callback function may have two arguments.
 *     The first one is Error:  `InvalidParameterError` if input is invalid, null if permission check passed, otherwise it is the user defined error from the options or `ForbiddenError` error by default.
 *     The second one is permissionOptions, only available when permission check passed.
 *
 *     Example
 *     {
 *       filters: {
 *         location: {
 *           includes: [...], // array of ids, also it can be excludes: []
 *         }
 *       },
 *       fields: ['-name', '-geo'] // omitted fields for `read`, ['*'] when there is no limits
 *     }
 * @return None
 * @see InvalidParameterError
 * @see ForbiddenError
 */
export default function ask(permissions, forAction, withOpts) {
  const validateResult = validate(permissions)

  if (!validateResult) {
    logger('Permission validation failed')
    return false
  }

  const moduleResult = checkModule(permissions, forAction, withOpts)

  if (!moduleResult) {
    logger('Module validation failed')
    return false
  }

  const documentResult = checkDocuments(permissions, forAction, withOpts)

  if (!documentResult) {
    logger('Document validation failed')
    return false
  }

  const fieldsResult = checkFields(permissions, forAction, withOpts)

  if (!fieldsResult) {
    logger('Field validation failed')
    return false
  }

  return {
    filters: documentResult,
    fields: fieldsResult
  }
}
