import {
  isRequired,
} from '../../validation'

export default {
  label: {
    label: 'Label',
    name: 'label',
    required: true,
    validate: [isRequired()],
  },
  group: {
    label: 'Group',
    name: 'group',
  },
}
