import { REQUEST } from '../../../middleware/request'
import { omit } from 'lodash'

export default function buildActionCreators(actions, baseUrlFn, headersFn, paramsFn) {
  return {
    nearby,
    resetNearby,
  }

  function nearby(params) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/${params.zoneId}/nearby`
      const headers = headersFn(params, state)
      const sanitizedParams = omit(params, 'applicationId', 'zoneId')
      return dispatch(nearbyRequest(endpoint, headers, sanitizedParams))
    }
  }

  function nearbyRequest(endpoint, headers, params) {
    return {
      [REQUEST]: {
        types: [actions.NEARBY_REQUEST, actions.NEARBY_SUCCESS, actions.NEARBY_ERROR],
        endpoint,
        headers,
        query: params,
      },
    }
  }

  function resetNearby() {
    return {
      type: actions.NEARBY_RESET,
    }
  }
}
