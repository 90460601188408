import { join } from 'lodash'
import { REQUEST } from '../../../middleware/request'
import {
  getChartArr,
  queryParamsFn,
} from '../utils'

export default function buildActionCreators(actions, baseUrlFn, headersFn, paramsFn) {
  return {
    clearFilters,
    query,
    setFilters,
  }

  function clearFilters(chartKey = null) {
    return { type: actions.CLEAR_FILTERS, chartKey }
  }

  function getData(endpoint, headers, queryParams) {
    const { chartKey } = queryParams
    return {
      [REQUEST]: {
        types: [
          actions.QUERY_REQUEST,
          actions.QUERY_SUCCESS,
          actions.QUERY_ERROR,
        ],
        endpoint,
        headers,
        method: 'GET',
        query: queryParams,
        respectTimezone: false,
        attachToActions: { chartKey },
      },
    }
  }

  function query(params) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = getUrl(params, state)
      const headers = headersFn(params, state)
      const queryParams = queryParamsFn(params, state)

      return dispatch(getData(endpoint, headers, queryParams))
    }
  }

  function setFilters(filters, key = null) {
    return { type: actions.SET_FILTERS, chartKey: key, filters }
  }

  function getUrl(params, state) {
    const baseUrl = baseUrlFn(params, state)
    const chartArr = getChartArr(params)
    const chartPath = join(chartArr, '/')
    return `${baseUrl}/${chartPath}`
  }

}
