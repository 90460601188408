import { getModule } from '@lighthouse/sdk'
import {
  assign,
  capitalize,
  filter,
  get,
  isEmpty,
  map,
  noop,
  reduce,
  reject,
  toNumber,
} from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import React from 'react'
import {
  ISSUE_STATUS_OPTIONS,
  ROLE_FILTER_MESSAGE,
  SERVICE_LEVEL_OPTIONS,
  SHIFT_STATUS_OPTIONS,
} from './constants'
import { DEFAULT_FILTER_EVENTS } from '../routes/events/constants'
import {
  AREA_FIELDS,
  TYPE_BUILDING,
  TYPE_GEOFENCE,
  TYPE_POINT,
} from '../../maps/lib/constants'
import {
  getFilter,
  handleClearAllFilters,
  parseDates,
  setFilter,
} from 'helpers/crud/data-table'
import {
  fetchHistorical,
  getFilterUser,
  getTitle,
  isRowLoaded,
} from './helpers'
import Button from 'components/button'
import Alert from 'components/alert'
import { withTranslation } from 'react-i18next'

const areaModule = getModule('areas')

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withHandlers({
    getAuditOptions,
    getAssigneesOptions,
    getIssueStatusOptions,
    getIssueTemplateOptions,
    getEventOptions,
    getLocationChildrenOptions,
    getLocationOptions,
    getLocationGroupOptions,
    getRoleOptions,
    getShiftStatusOptions,
    getTargetServiceLevelOptions,
    getTaskOptions,
    getUserOptions,
    fetchHistorical,
    handleClearAllFilters,
    handleClearFilter,
    handleSetFilter,
    isRowLoaded,
    resetList,
    setFilter,
  }),
  withProps(getInitialProps)
)

// TODO Remove this helper once all legacy filters (in setup) have been migrated
// to the babushka
export function getCategories(props) {
  const {
    areaLocations,
    locationGroups,
    auditCache,
    config,
    roleCache,
    templateCache,
    userCache,
    t,
  } = props

  const { filters } = config

  return reduce(
    filters,
    (accum, val) => {
      const label = capitalize(val)

      if (val === 'assignees') {
        accum[val] = {
          label: 'Assignee',
          options: map(userCache, getFilterUser),
        }
      }

      if (val === 'audit') {
        accum[val] = {
          label,
          options: map(auditCache, i => getFilter(i, label)),
        }
      }

      if (val === 'eventType') {
        accum.type = { label: 'Event', options: DEFAULT_FILTER_EVENTS }
      }

      if (val === 'signalType') {
        accum.type = {
          label: 'Type',
          options: {
            beacon: {
              label: t('labelBeacon'),
              value: 'beacon',
            },
            qrcode: {
              label: t('labelQrCode'),
              value: 'qrcode',
            },
            nfc: {
              label: t('labelNfc'),
              value: 'nfc',
            },
          },
        }
      }

      if (val === 'issueStatus') {
        accum.status = { label: 'Status', options: ISSUE_STATUS_OPTIONS }
      }

      if (val === 'issueTemplate') {
        const cache = filter(templateCache, ['entity.type', 'issue'])
        accum.template = {
          label: 'Issue',
          options: map(cache, i => getFilter(i, label)),
        }
      }

      if (val === 'task') {
        const cache = filter(templateCache, ['entity.type', 'task'])
        accum.task = {
          label: 'Task',
          options: map(cache, i => getFilter(i, label)),
        }
      }

      if (val === 'location') {
        accum.area = {
          label,
          options: map(areaLocations, i => getFilter(i, label)),
        }
      }

      if (val === 'locationGroup') {
        accum.locationGroup = {
          label: t('labelLocationGroup'),
          options: map(locationGroups, i => getFilter(i, label)),
        }
      }

      if (val === 'role') {
        accum.role = {
          label: 'Role',
          options: map(roleCache, i => getFilter(i, label)),
        }
      }

      if (val === 'status') {
        accum.status = { label: 'Status', options: SHIFT_STATUS_OPTIONS }
      }

      if (val === 'targetServiceLevel') {
        accum.targetServiceLevel = {
          label: 'Target Service Level',
          options: SERVICE_LEVEL_OPTIONS,
        }
      }

      if (val === 'user') {
        accum[val] = { label, options: map(userCache, getFilterUser) }
      }

      return accum
    },
    {}
  )
}

function getAuditOptions(props) {
  const { auditCache } = props

  return type => {
    const auditOptions = map(auditCache, audit => {
      const { label, value } = getFilter(audit, 'Audit')
      return {
        label,
        value,
        type,
        hasChildren: false,
      }
    })
    return (/* audit */) => auditOptions
  }
}

function getIssueTemplateOptions(props) {
  const { templateCache } = props
  return type => {
    const cache = filter(templateCache, ['entity.type', 'issue'])
    const issueTemplateOptions = map(cache, issue => {
      const { label, value } = getFilter(issue, 'Issue')

      return {
        label,
        value,
        type,
        hasChildren: false,
      }
    })

    return (/* issueTemplate */) => issueTemplateOptions
  }
}

function getShiftStatusOptions(props) {
  const { t } = props
  return type => {
    const shiftStatusOptions = map(SHIFT_STATUS_OPTIONS, status => {
      const { label, value } = status
      const translatedLabel = t(`label${capitalize(label)}`)
      return {
        label: translatedLabel,
        value,
        type,
        hasChildren: false,
      }
    })

    return () => shiftStatusOptions
  }
}

function getTargetServiceLevelOptions(props) {
  const { t } = props
  return type => {
    const serviceLevelOptions = map(SERVICE_LEVEL_OPTIONS, level => {
      const { label, value } = level
      const translatedLabel = t(`label${capitalize(label)}Target`)
      return {
        label: translatedLabel,
        value,
        type,
        hasChildren: false,
      }
    })

    return () => serviceLevelOptions
  }
}

function getIssueStatusOptions(props) {
  const { t } = props
  return type => {
    const issueStatusOptions = map(ISSUE_STATUS_OPTIONS, status => {
      const { label, value } = status
      const formattedLabel = label
        .split(' ')
        .map(capitalize)
        .join('')
      const translatedLabel = t(`label${formattedLabel}`)

      return {
        label: translatedLabel,
        value,
        type,
        hasChildren: false,
      }
    })

    return (/* issueStatus */) => issueStatusOptions
  }
}

function getRoleOptions(props) {
  const { roleCache } = props
  return type => {
    const roleOptions = map(roleCache, role => {
      const { label, value } = getFilter(role, 'Role')

      return {
        label,
        value,
        type,
        hasChildren: false,
      }
    })

    return (/* role */) => roleOptions
  }
}

function getAssigneesOptions(props) {
  const { userCache } = props
  return type => {
    const userOptions = map(userCache, user => {
      const { label, search, value } = getFilterUser(user)

      return {
        hasChildren: false,
        label,
        search,
        type,
        value,
      }
    })

    return (/* assignees */) => userOptions
  }
}

function getEventOptions(props) {
  const { t } = props

  const flaggedEventFilters = props.flags.exitEvents
    ? DEFAULT_FILTER_EVENTS
    : DEFAULT_FILTER_EVENTS.filter(event => event.value != 'exit')

  return type => {
    const eventOptions = map(flaggedEventFilters, event => {
      const { label, labelT, value } = event

      return {
        label: labelT ? t(labelT) : label,
        value,
        type,
        hasChildren: false,
      }
    })

    return (/* event */) => eventOptions
  }
}

function getLocationOptions(props) {
  const { areaLocations } = props

  return type => {
    const locationOptions = map(areaLocations, area => {
      const { label, search, value } = getFilter(area, 'Location')

      return {
        label,
        search,
        type,
        value,
      }
    })

    return (/* type */) => locationOptions
  }
}

function getLocationGroupOptions(props) {
  const { locationGroups } = props

  return () => {
    const locationGroupOptions = locationGroups.map(locationGroup => ({
      label: locationGroup.entity.name,
      type: 'area',
      value: locationGroup.entity._id,
      hasChildren: false,
    }))

    return locationGroupOptions
  }
}

function getLocationChildrenOptions(props) {
  const { fetchAreasByLocation } = props

  return async (type, area) => {
    const { value: areaId } = area
    const { data: areas } = await fetchAreasByLocation(areaId)

    const areaOptions = map(areas, area => ({
      label: area.name,
      type,
      typeLabel: area.type === 'point' ? 'Zone' : capitalize(area.type),
      value: area._id,
      hasChildren: false,
    }))

    return areaOptions
  }
}

function getTaskOptions(props) {
  const { templateCache } = props
  return type => {
    const cache = filter(templateCache, ['entity.type', 'task'])
    const taskTemplateOptions = map(cache, task => {
      const { label, value } = getFilter(task, 'Task')

      return {
        label,
        value,
        type,
        typeLabel: 'Task',
        hasChildren: false,
      }
    })

    return (/* taskTemplate */) => taskTemplateOptions
  }
}

function getUserOptions(props) {
  const { userCache } = props
  return type => {
    const userOptions = map(userCache, user => {
      const { label, search, value } = getFilterUser(user)

      return {
        hasChildren: false,
        label,
        search,
        type,
        value,
      }
    })
    return (/* user */) => userOptions
  }
}

function handleClearFilter({ listFilters, listId, setFilters }) {
  return tag => {
    const { type, value } = tag
    const parsed = parseDates(listFilters)
    const nextFilters = {
      ...parsed,
      [type]: reject(listFilters[type], i => i === value),
    }
    return setFilters(listId, nextFilters)
  }
}

function handleSetFilter({ listFilters = {}, listId, setFilters }) {
  return obj => {
    const { type, value } = obj
    const categoryValues = listFilters[type] || []
    const nextCategoryValues = [...categoryValues, value]
    const parsed = parseDates(listFilters)
    const nextFilters = {
      ...parsed,
      [type]: nextCategoryValues,
    }

    return setFilters(listId, nextFilters)
  }
}

export function getInitialProps(props) {
  const {
    fetch,
    fetchHistorical,
    isFilteringByRole,
    isResolving,
    list,
    listFilters,
    config,
    totalCount,
    t,
  } = props

  const { label, queryParams } = config
  const { from, to } = listFilters
  const rowCount = get(list, 'items.length', 0)
  const showSpinner = rowCount === 0 && isResolving
  const loadMoreRows = isResolving ? noop : () => fetchHistorical(queryParams)

  const roleMessage = isFilteringByRole ? ROLE_FILTER_MESSAGE : ''

  const networkError = get(list, 'error.name') === 'NetworkError'

  const noRowsRenderer = isResolving
    ? noop
    : networkError
    ? () => (
        <>
          <Alert messages={[t('alert.networkError')]} type="error" />
          <Button onClick={fetch} theme="primary">
            Retry
          </Button>
        </>
      )
    : () => (
        <Alert
          italicFontStyle={isFilteringByRole}
          messages={[
            t('alert.noItemsCouldBeFound', { item: label }),
            roleMessage,
          ]}
        />
      )

  const virtualRowCount = rowCount < totalCount ? rowCount + 50 : totalCount

  return {
    categories: getCategories(props),
    loadMoreRows,
    noRowsRenderer,
    rowCount,
    selectedFilters: getSelectedFilters(props),
    showSpinner,
    title: getTitle({
      from,
      label,
      to,
      t,
    }),
    virtualRowCount,
  }
}

function getSelectedFilters(props) {
  const current = props.listFilters
  const available = {
    area: [],
    audit: [],
    location: [],
    role: [],
    status: [],
    targetServiceLevel: [],
    task: [],
    template: [],
    type: [],
    user: [],
  }

  return assign({}, available, current)
}

function resetList(props) {
  const { clearList, config, paginate, setShouldQuery = noop } = props

  return () => {
    clearList([config.listId])
    paginate(config.listId, { page: 1 })
    setShouldQuery(true)
  }
}

function mapStateToProps(state, props) {
  const { config = {} } = props
  const { key, listId } = config

  const areaSelectors = areaModule.selectors(state)()
  const findEnclosing = areaSelectors.findEnclosing
  const module = getModule(key)
  const selector = module.selectors(state)(listId)
  const isResolving = selector.state === 'resolving'
  const list = state[key].list[listId]
  const listFilters = list.filters
  const totalCount = toNumber(get(list, 'pagination.totalCount', 0))
  const locationGroups = areaSelectors.byType('location-group')

  const userApplicationsModule = getModule('userApplications')
  const flags = userApplicationsModule.getFlags(state)
  const isFilteringByRole = !isEmpty(get(listFilters, 'role', []))

  const allCaches = {
    areas: { label: 'locations', options: state.areas.cache },
    assignees: { label: 'Assignee', options: state.applicationUsers.cache },
    audits: { label: 'Audit', options: state.audits.cache },
    locationGroups: { label: 'Location Groups', options: locationGroups },
    roles: { label: 'Role', options: state.roles.cache },
    status: { label: 'Status' },
    templates: { label: 'Issue', options: state.templates.cache },
    types: {
      label: 'Event',
      options: {
        enter: {
          type: 'type',
          label: 'Enter',
          value: 'enter',
        },
        exit: {
          type: 'type',
          label: 'Exit',
          value: 'exit',
        },
        geo: {
          type: 'type',
          label: 'GPS',
          value: 'geo',
        },
      },
    },
    users: { label: 'User', options: state.applicationUsers.cache },
  }

  const areaLocations = areaSelectors.byType('location')

  return {
    allCaches,
    areaLocations,
    areaCache: state.areas.cache,
    applicationId: state.app.applicationId,
    findEnclosing,
    flags,
    auditCache: state.audits.cache,
    auditEntryCache: state.auditEntries.cache,
    eventCache: state.events.cache,
    exceptionCache: state.exceptions.cache,
    exportCache: state.dataExports.cache,
    isFilteringByRole,
    isResolving,
    issueCache: state.issues.cache,
    list,
    listFilters,
    listId,
    locationGroups,
    roleCache: state.roles.cache,
    shiftCache: state.shifts.cache,
    taskEntryCache: state.taskEntries.cache,
    templateCache: state.templates.cache,
    totalCount,
    userCache: state.applicationUsers.cache,
    zoneCache: state.zones.cache,
  }
}

function mapDispatchToProps(dispatch, props) {
  const { config = {} } = props
  const { key, listId, queryParams } = config

  const module = getModule(key)
  const dataExportModule = getModule('dataExports')

  return {
    clearList: listIds => dispatch(module.clearListItems(listIds)),
    clearListFilters: listId => dispatch(module.clearListFilters(listId)),
    createDataExport: (id, payload) =>
      dispatch(dataExportModule.save({}, payload)),
    fetch: (opts = {}) => {
      const { appendToList = true, ...customParams } = opts
      return dispatch(
        module.query(
          listId,
          assign({}, queryParams, customParams, { appendToList })
        )
      )
    },
    fetchAreasByLocation: id =>
      dispatch(
        areaModule.areasByLocation(id, {
          fields: AREA_FIELDS,
          perPage: 9999,
          type: [TYPE_BUILDING, TYPE_GEOFENCE, TYPE_POINT],
        })
      ),
    invalidateList: listId => dispatch(module.invalidateList(listId)),
    paginate: (listId, opts) =>
      dispatch(module.setPaginationOpts(listId, opts)),
    setFilters: (listId, filters) =>
      dispatch(module.setListFilters(listId, filters)),
  }
}
