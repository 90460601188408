import React, { useState } from 'react'
import { isNil, noop } from 'lodash'

import Button from 'components/button'
import { Save } from 'components/buttons'
import { Error, Unsaved } from 'components/prompts'
import Alert from 'components/alert'
import Confirmable from 'components/confirmable'
import { usePermission } from 'components/permissions'
import { Flex, Block } from 'components/common'
import { ModalPopup } from '../../modal-popup'
import { useModal } from '@ebay/nice-modal-react'
import Anchor from 'components/anchor'
import Icon from 'components/icon'

import { useTranslation } from 'react-i18next'

export default function CrudControls(props) {
  const { t } = useTranslation()

  const {
    dirty,
    canEdit = true,
    canDelete = true,
    confirmDiscardMessage = t('button.discardConfirm'),
    confirmSave,
    confirmSaveMessage = t('button.saveConfirm'),
    deleteModalMessage,
    deleteModalTitle,
    error,
    errorCount,
    handleCancel,
    handleDelete,
    handleDeleteAttempt = noop,
    handleEdit,
    handleSave,
    id,
    invalid,
    isEditing,
    isLocked = false,
    name,
    permissionModule,
    reset,
    saveText,
    showErrorPrompt = true,
    showConfirmMessageAbove,
    small,
    submitting,
    disabled,
    tooltipMessage,
    tooltipTitle,
  } = props

  const [isConfirmingDiscard, setConfirmingDiscard] = useState(false)
  const [isConfirmingSave, setConfirmingSave] = useState(false)
  const deleteModal = useModal(ModalPopup)

  const isNewAndClean = !id && !dirty
  const isExistingAndClean = id && !dirty

  const discardMessage = small
    ? t('button.discardConfirm_small')
    : confirmDiscardMessage
  const saveMessage = small ? t('button.saveConfirm_small') : confirmSaveMessage

  const { hasPermission } = usePermission()

  const hasEditPermission = permissionModule
    ? hasPermission({
        action: 'update',
        module: permissionModule,
      })
    : true
  const hasDeletePermission = permissionModule
    ? hasPermission({
        action: 'delete',
        module: permissionModule,
      })
    : true

  function handleDiscardClick(callback) {
    return () => {
      setConfirmingDiscard(true)
      callback()
    }
  }

  function handleSaveClick(callback) {
    return () => {
      setConfirmingSave(true)
      callback()
    }
  }

  function handleDiscardConfirm() {
    setConfirmingDiscard(false)
    reset()
  }

  async function handleSaveConfirm() {
    await handleSave()
    setConfirmingSave(false)
  }

  function handleDiscardCancel() {
    setConfirmingDiscard(false)
  }

  function handleSaveCancel() {
    setConfirmingSave(false)
  }

  function handleDeleteConfirm() {
    handleDelete()
    handleDeleteAttempt(false)
  }

  if (!canEdit || isNewAndClean) {
    return (
      <Button dataTestId="cancel-button" onClick={handleCancel}>
        {t('button.cancel')}
      </Button>
    )
  }

  if (isExistingAndClean) {
    if (!hasEditPermission) return null
    // NOTE: when the user has ability to edit, not used on
    // all forms so first check if any values actually passed
    const showEdit = !isNil(isEditing) && !isNil(handleEdit)

    if (showEdit && !isEditing && !isLocked) {
      return (
        <Button dataTestId="edit-button" onClick={handleEdit}>
          {t('button.edit')}
        </Button>
      )
    }

    return (
      <>
        <Block
          width="100%"
          justifyContent="space-between"
          flexDirection="row"
          pointerEvents="auto"
        >
          {error && <Alert type="error" messages={[error]} />}
          <Flex>
            <Flex
              justifyContent="space-between"
              flexDirection="row"
              width="100%"
              paddingBottom="15px"
            >
              {!isLocked && (
                <Button dataTestId="cancel-button" onClick={handleCancel}>
                  {t('button.cancel')}
                </Button>
              )}
              {canDelete && hasDeletePermission && !isLocked && (
                <Flex>
                  <Button
                    disabled={disabled}
                    justifyContent="flex-end"
                    theme="negative"
                    style={{ marginRight: '0px' }}
                    dataTestId="delete-button"
                    onClick={() => {
                      deleteModal.show({
                        deleteModalMessage,
                        deleteModalTitle,
                        handleDelete: handleDeleteConfirm,
                      })
                      handleDeleteAttempt(true)
                    }}
                  >
                    {t('button.delete')}
                  </Button>
                  <>
                    <ModalPopup id="deleteModal" />
                  </>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Block>
      </>
    )
  }

  if (canEdit) {
    const prompt =
      invalid && showErrorPrompt ? (
        <Error errorCount={errorCount} id={id} error={error} />
      ) : (
        <Unsaved />
      )

    const showPrompt = !isConfirmingDiscard && !isConfirmingSave && !submitting
    return (
      <Flex alignItems="center" paddingBottom="15px">
        <Confirmable
          message={discardMessage}
          onCancel={handleDiscardCancel}
          onConfirmed={handleDiscardConfirm}
        >
          {({ handleConfirm, isConfirming, confirmEl }) => {
            if (isConfirming) return confirmEl
            if (isConfirmingSave) return null

            return (
              <Button
                dataTestId="discard-button"
                onClick={handleDiscardClick(handleConfirm)}
              >
                {t('button.discard')}
              </Button>
            )
          }}
        </Confirmable>
        <Confirmable
          message={saveMessage}
          onCancel={handleSaveCancel}
          onConfirmed={handleSaveConfirm}
        >
          {({ handleConfirm, isConfirming, confirmEl }) => {
            if (isConfirming) return confirmEl
            if (isConfirmingDiscard) return null

            // NOTE: in some instances we to skip confirming the save e.g when
            // creating an entity versus updating
            const handleOnClick = id
              ? handleSaveClick(handleConfirm)
              : handleSaveConfirm

            return (
              <Save
                invalid={invalid}
                onClick={handleOnClick}
                submitting={submitting}
                text={saveText}
              />
            )
          }}
        </Confirmable>
        {showPrompt && prompt}
        {tooltipMessage && (
          <Anchor
            tooltip={[tooltipTitle, tooltipMessage]}
            style={{
              fontSize: 20,
              marginLeft: 5,
              textAlign: 'left',
            }}
            anchorDirection="top"
          >
            <Icon name="help" />
          </Anchor>
        )}
      </Flex>
    )
  }

  return null
}
