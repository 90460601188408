import { getModule } from '@lighthouse/sdk'
import turfDistance from '@turf/distance'
import { point } from '@turf/turf'
import { attempt, get, isError, map, round } from 'lodash'
import { connect } from 'react-redux'
import { compose, pure, withProps } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { Block, Flex } from 'components/common'
import Icon from 'components/icon'

const DEFAULT_THRESHOLD = 200

const userApplicationsModule = getModule('userApplications')

const styles = {
  iconWrapper: {
    zIndex: 100,
  },
}

export default compose(
  connect(mapStateToProps),
  withProps(props => ({
    distanceLabel: getDistanceLabel(props),
  })),
  pure
)(CellLocation)

function CellLocation(props) {
  const { distanceLabel, label } = props

  return (
    <Flex alignItems="center">
      <Block flexGrow={1}>{label}</Block>
      <Block
        minWidth="16px"
        paddingRight="4px"
        title={distanceLabel}
        zIndex={100}
      >
        {distanceLabel && <Icon fontSize="16px" name="location-error" />}
      </Block>
    </Flex>
  )
}

CellLocation.propTypes = {
  distanceLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  const currentApplication = userApplicationsModule.getCurrentApplication(state)

  const distanceThreshold = get(
    currentApplication,
    'application.settings.gps.global.distanceThreshold',
    DEFAULT_THRESHOLD
  )

  return {
    distanceThreshold,
    region: state.app.region,
  }
}

function formatLatLng(int) {
  return round(int, 6)
}

function getDistanceLabel(props) {
  const { actualGps, distanceThreshold = 200, entityGps, region } = props
  const rawGeometry = validateGeoPoint(actualGps)
  const formGeometry = validateGeoPoint(entityGps)

  // If either point is invalid or missing, return
  if (!rawGeometry || !formGeometry) {
    return
  }

  // turf returns km
  const distanceInKm = turfDistance(rawGeometry, formGeometry)
  const distanceInMetres = distanceInKm * 1000

  // If the distance is less than the threshold, skip
  if (distanceThreshold > distanceInMetres) return

  const rawCoordinates = get(rawGeometry, 'geometry.coordinates')
  const formCoordinates = get(formGeometry, 'geometry.coordinates')

  const [rawLng, rawLat] = map(rawCoordinates, formatLatLng)
  const [formLng, formLat] = map(formCoordinates, formatLatLng)

  const submittedText = `Submitted location: ${formLng}, ${formLat}`
  const actualText = `GPS location: ${rawLng}, ${rawLat}`

  // Humanize the distance based on application region
  const humanizedDistance = humanizeDistance({ distanceInKm, region })
  const distanceText = `Approx distance: ${humanizedDistance}`

  // Return the distance message
  return [submittedText, actualText, distanceText].join('\n')
}

function humanizeDistance({ distanceInKm, region }) {
  if (!distanceInKm) return ''

  // Convert to miles for US clients
  if (region === 'us') {
    const distanceInMiles = distanceInKm / 1.609
    const distanceInFeet = distanceInKm * 3280.84

    return distanceInFeet < 1000
      ? `${Math.round(distanceInFeet)} ft`
      : `${distanceInMiles.toFixed(2)} mi`
  }

  const distanceInMetres = distanceInKm * 1000

  return distanceInKm < 1
    ? `${Math.round(distanceInMetres)} m`
    : `${distanceInKm.toFixed(2)} km`
}

function validateGeoPoint(geo) {
  const coordinates = get(geo, 'coordinates')

  if (!coordinates) return

  const geoJsonPoint = attempt(point, coordinates)
  const validPoint = !isError(geoJsonPoint)

  return validPoint && geoJsonPoint
}
