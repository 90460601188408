import colors from 'config/theme/colors'

export default {
  nav: {
    background: 'white',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    position: 'absolute',
  },
  sidePanelButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  menuButton: {
    width: 64,
    ':hover': {
      backgroundColor: 'none',
    },
  },
  blueMenuButton: {
    backgroundColor: colors.blue.lightest,
  },
}
