import { Cell } from 'fixed-data-table'
import PropTypes from 'prop-types'
import React from 'react'

import Label from 'components/label'

export default function CellLabel({ type, text, ...cellProps }) {
  return (
    <Cell {...cellProps}>
      <Label type={type}>{text}</Label>
    </Cell>
  )
}

CellLabel.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
}
