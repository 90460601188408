import { typography } from 'config/theme'

const { defaultFontFamily, fontSizes } = typography

export default {
  bold: {
    fontWeight: 500,
  },
  defaultFont: {
    fontFamily: defaultFontFamily,
  },
  xsmall: {
    fontSize: fontSizes.xsmall,
  },
  small: {
    fontSize: fontSizes.small,
  },
  normal: {
    fontSize: fontSizes.normal,
  },
  large: {
    fontSize: fontSizes.large,
  },
}
