import colors from 'config/theme/colors'

export default {
  root: {
    marginBottom: 20,
  },
  bodyCell: {
    paddingRight: 8,
    paddingTop: 20,
    borderBottom: '1px solid #eee',
    verticalAlign: 'top',
  },
  bodyCellCheckbox: {
    paddingTop: 35,
    textAlign: 'center',
    borderBottom: '1px solid #eee',
    verticalAlign: 'top',
  },
  bodyCellOption: {
    paddingRight: 10,
    paddingTop: 0,
    textAlign: 'center',
    borderBottom: '1px solid #eee',
    verticalAlign: 'middle',
  },
  center: {
    textAlign: 'center',
  },
  editor: {
    minHeight: 50,
  },
  fieldsTable: {
    width: '100%',
    borderSpacing: 0,
    marginTop: 10,
    tableLayout: 'fixed',
  },
  first: {
    paddingLeft: 20,
  },
  headerCell: {
    background: colors.gray.lightest,
    border: '0 none',
    padding: 15,
    fontSize: 14,
  },
  smallMarginBottom: {
    marginBottom: 10,
  },
  smallPaddingBottom: {
    paddingBottom: 10,
  },
  selectOptions: {
    marginBottom: 20,
  },
  noBorder: {
    border: '0 none',
  },
  noMarginBottom: {
    marginBottom: 0,
  },
  titleWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    fontSize: 20,
    marginLeft: 5,
    textAlign: 'left',
  },
  trashIcon: {
    fontSize: 20,
  },
}
