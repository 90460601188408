import { connect } from 'react-redux'
import { compose, lifecycle, withProps } from 'recompose'
import { getModule, validation } from '@lighthouse/sdk'
import { point } from '@turf/helpers'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import React, { Component, Fragment } from 'react'

import {
  Field,
  getFormMeta,
  getFormSyncErrors,
  getFormValues,
} from 'redux-form'

import {
  attempt,
  chain,
  cloneDeep,
  find,
  get,
  isArray,
  isEmpty,
  isError,
  toNumber,
} from 'lodash'

import {
  BasicForm,
  FieldGroup,
  FieldSet,
  InputMaterialCheckbox,
  InputNumber,
  InputSelect,
  InputText,
  InputUUIDSelect,
} from 'components/form'

import {
  getErrorCount,
  handleFormError,
  handleSaveResponse,
  handleDeleteResponse,
} from 'components/form/helpers'

import { Block } from 'components/common'
import { Crud } from 'components/controls'
import Alert from 'components/alert'
import ButtonGroup from 'components/button-group'
import FlagsHOC from 'components/flags/hoc'
import withUrlQuery from 'components/with-url-query'

import emitter from 'utils/emitter'

import ControlWrapper from '../../../control-wrapper'
import FieldWrapper from '../../../field-wrapper'
import TitleDivider from '../../../title-divider'
import { TOOLTIPS } from 'config/constants'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

const areaModule = getModule('areas')
const signalModule = getModule('signals')
const zoneModule = getModule('zones')

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

class EditSignalForm extends Component {
  constructor(props) {
    super(props)
    this.delete = this.delete.bind(this)
    this.saveForm = this.saveForm.bind(this)
    this.getZoneIdBySignalId = this.getZoneIdBySignalId.bind(this)
  }

  render() {
    const {
      autoAssignValue,
      availableFloors,
      dirty,
      error,
      errorCount,
      fieldsMeta,
      flags,
      handleCancel,
      handleEdit,
      handleReset,
      handleSubmit,
      id,
      invalid,
      isEditing,
      readOnly,
      signalType,
      submitFailed,
      submitting,
      t,
    } = this.props

    const typeOptions = [
      {
        label: 'Beacon',
        value: 'beacon',
      },
    ]

    if (flags.nfc)
      typeOptions.push({
        label: 'NFC Tag',
        value: 'nfc',
      })
    if (flags.qrcodes)
      typeOptions.push({
        label: 'QR Code',
        value: 'qrcode',
      })

    const hasGeoUpdate =
      get(fieldsMeta, 'geometry.coordinates.0.touched') ||
      get(fieldsMeta, 'geometry.coordinates.1.touched')

    const floorOptions = chain(availableFloors)
      .map(floor => ({
        value: floor.level,
        label: floor.label,
      }))
      .sortBy('value')
      .value()

    const hasFloorOptions = floorOptions.length > 0

    const showBeaconSettings = signalType === 'beacon'
    const showNfcSettings = signalType === 'nfc'
    const showSignalDetails = !isEditing || (isEditing && !autoAssignValue)

    return (
      <BasicForm noValidate>
        <FieldWrapper>
          {error && submitFailed && (
            <Block paddingTop={10}>
              <Alert type="error" messages={[error]} />
            </Block>
          )}
          <FieldGroup>
            <FieldSet>
              <Field
                component={InputText}
                label={t('labelLabel')}
                name="label"
                dataTestId="signal-label"
                placeholder={t('placeholder.signalLabel')}
                readOnly={readOnly}
                required
                small
                validate={[isRequired]}
              />
              <Field
                component={InputText}
                label={t('labelReference')}
                placeholder={t('placeholder.reference')}
                name="reference"
                readOnly={readOnly}
                small
                tooltip={[
                  t('tooltip.reference.title'),
                  t('tooltip.reference.message'),
                ]}
              />
              <Field
                component={InputSelect}
                disabled={!hasFloorOptions}
                label={t('labelFloors')}
                multi
                name="floorsRef"
                normalize={value => (isArray(value) ? value : [])}
                options={floorOptions}
                placeholder={t('placeholder.floors')}
                readOnly={readOnly}
                required={hasFloorOptions}
                small
              />
              <Field
                component={InputSelect}
                label={t('labelType')}
                name="type"
                options={typeOptions}
                placeholder={t('placeholder.signalType')}
                readOnly={readOnly}
                required
                small
                validate={[isRequired]}
              />
              <Field
                component={InputMaterialCheckbox}
                label="Auto Assign Settings"
                name="properties.beacon.autoAssign"
                readOnly={readOnly}
                required
                small
              />
              {showSignalDetails && (
                <Fragment>
                  <Field
                    component={InputUUIDSelect}
                    label={t('labelBeaconUuid')}
                    name="properties.beacon.uuid"
                    placeholder={t('placeholder.uuid')}
                    readOnly={readOnly}
                    required
                    small
                    tooltip={[
                      t(TOOLTIPS.uuid.titleT),
                      t(TOOLTIPS.uuid.messageT),
                    ]}
                  />
                  <Field
                    component={InputNumber}
                    label={t('labelBeaconMajor')}
                    name="properties.beacon.major"
                    placeholder={t('placeholder.beaconMajor')}
                    readOnly={readOnly}
                    required
                    small
                    tooltip={[
                      t(TOOLTIPS.beaconMajor.titleT),
                      t(TOOLTIPS.beaconMajorAlt.messageT),
                    ]}
                  />
                  <Field
                    component={InputNumber}
                    label={t('labelBeaconMinor')}
                    name="properties.beacon.minor"
                    placeholder={t('placeholder.beaconMinor')}
                    readOnly={readOnly}
                    required
                    small
                    tooltip={[
                      t(TOOLTIPS.beaconMinor.titleT),
                      t(TOOLTIPS.beaconMinorAlt.messageT),
                    ]}
                  />
                </Fragment>
              )}
              {showBeaconSettings && (
                <Fragment>
                  <Field
                    component={InputNumber}
                    label="RSSI"
                    name="properties.beacon.rssi"
                    placeholder={t('placeholder.rssi')}
                    readOnly={readOnly}
                    small
                    tooltip={[
                      t(TOOLTIPS.signalStrengthIndicator.titleT),
                      t(TOOLTIPS.signalStrengthIndicator.messageT),
                    ]}
                  />
                  <Field
                    component={InputNumber}
                    label="tx"
                    name="properties.beacon.tx"
                    placeholder={t('placeholder.transmittingPower')}
                    readOnly={readOnly}
                    small
                    tooltip={[
                      t(TOOLTIPS.transmittingPower.titleT),
                      t(TOOLTIPS.transmittingPower.messageT),
                    ]}
                  />
                  <Field
                    component={InputNumber}
                    label="Ad. Interval"
                    name="properties.beacon.advertisingInterval"
                    placeholder={t('placeholder.advertisingInterval')}
                    readOnly={readOnly}
                    small
                    tooltip={[
                      t(TOOLTIPS.advertisingInterval.titleT),
                      t(TOOLTIPS.advertisingInterval.messageT),
                    ]}
                  />
                </Fragment>
              )}
              {showNfcSettings && (
                <Field
                  component={InputText}
                  label="NFC ID"
                  name="properties.nfc.id"
                  placeholder={t('placeholder.NfcTagId')}
                  readOnly={readOnly}
                  required
                  small
                  tooltip={[
                    t(TOOLTIPS.nfcTagId.titleT),
                    t(TOOLTIPS.nfcTagId.messageT),
                  ]}
                />
              )}
            </FieldSet>
          </FieldGroup>
          <TitleDivider title="Geo Coordinates" />
          <FieldGroup>
            {hasGeoUpdate && !submitFailed && !submitting && (
              <Alert
                type="warning"
                messages={[
                  "The Signal's position has changed. Please Save to confirm new position.",
                ]}
                small
              />
            )}
            <FieldSet>
              <Field
                component={InputNumber}
                disabled
                label={t('labelLatitude')}
                name="geometry.coordinates.1"
                placeholder={t('placeholder.latitude')}
                readOnly={readOnly}
                required
                small
              />
              <Field
                component={InputNumber}
                disabled
                label={t('labelLongitude')}
                name="geometry.coordinates.0"
                placeholder={t('placeholder.longitude')}
                readOnly={readOnly}
                required
                small
              />
            </FieldSet>
          </FieldGroup>
        </FieldWrapper>
        <ControlWrapper>
          <ButtonGroup align="left">
            <Crud
              deleteModalMessage={t('modal.delete.signal.message')}
              deleteModalTitle={t('modal.delete.signal.title')}
              dirty={dirty}
              errorCount={errorCount}
              handleCancel={handleCancel}
              handleDelete={this.delete}
              handleEdit={handleEdit}
              handleSave={handleSubmit(this.saveForm)}
              id={id}
              invalid={invalid}
              isEditing={isEditing}
              reset={handleReset}
              small
              submitting={submitting}
            />
          </ButtonGroup>
        </ControlWrapper>
      </BasicForm>
    )
  }

  getZoneIdBySignalId(id) {
    const { zoneCache } = this.props
    const zone = find(zoneCache, zone => {
      return get(zone, 'entity.properties.signal') === id
    })
    return get(zone, 'id')
  }

  saveForm(formValues) {
    const {
      fetchZones,
      history,
      id,
      onClose,
      saveSignal,
      urlQuery,
    } = this.props

    const payload = cloneDeep(formValues)

    return saveSignal(id, payload)
      .then(async response => {
        // NOTE fetch the equivalent zone for the signal. This is because all
        // zones are loaded at login, so without doing this the zone will never
        // be available locally
        const zonesParams = {
          'beacon.uuid': get(response, 'data.properties.beacon.uuid'),
          'beacon.major': get(response, 'data.properties.beacon.major'),
          'beacon.minor': get(response, 'data.properties.beacon.minor'),
          // NOTE this is important to receive the correct entity structure
          disablePopulation: 1,
        }

        await fetchZones(zonesParams)

        return handleSaveResponse('signal')(response)
      })
      .then(onClose)
      .then(() => {
        const { lastParentId } = urlQuery

        if (lastParentId) {
          history.push({
            search: `?${queryString.stringify({ lastParentId })}`,
          })
        }
      })
      .catch(err => {
        emitter.emit('notification:add', {
          message:
            'Please retry, or contact Lighthouse.io support for further assistance',
          title: 'Signal could not be created',
          theme: 'alert',
        })

        return handleFormError(err)
      })
  }

  delete() {
    const { id, deleteSignal, onClose, removeZone } = this.props

    return deleteSignal(id)
      .then(handleDeleteResponse('signal'))
      .then(onClose)
      .then(() => {
        const zoneId = this.getZoneIdBySignalId(id)
        return zoneId && removeZone(zoneId)
      })
      .catch(handleFormError)
  }
}

export default compose(
  FlagsHOC,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUrlQuery,
  withProps(buildFormProps),
  withTranslation(),
  lifecycle({ componentDidUpdate })
)(EditSignalForm)

function buildFormProps(props) {
  const { findEnclosing, formSyncErrors, formValues = {} } = props

  let availableFloors = []
  let enclosingLocation = null
  let enclosingBuilding = null
  const formGeometry = formValues.geometry

  if (formGeometry) {
    enclosingBuilding = findEnclosing(formGeometry, 'building')
    // NOTE signal should always have an enclosing location
    enclosingLocation = findEnclosing(formGeometry, 'location')
    availableFloors = get(enclosingBuilding, 'entity.floors', [])
  }

  const errorCount = getErrorCount(formSyncErrors)
  const autoAssignValue = !!get(formValues, 'properties.beacon.autoAssign')
  const signalType = formValues.type

  return {
    autoAssignValue,
    availableFloors,
    enclosingBuilding,
    enclosingLocation,
    errorCount,
    signalType,
  }
}

function componentDidUpdate(prevProps) {
  const {
    change,
    findEnclosing,
    formValues,
    history,
    pristine,
    touch,
    urlQuery: { lat, lng },
  } = this.props

  const {
    availableFloors: prevAvailableFloors,
    enclosingBuilding: prevEnclosingBuilding,
    enclosingLocation: prevEnclosingLocation,
    pristine: prevPristine,
    urlQuery: prevUrlQuery,
  } = prevProps

  if (!lat || !lng) return

  const {
    geometry: { coordinates },
  } = formValues
  const [formLng, formLat] = coordinates

  const hasLatChanged = formLat !== toNumber(lat)
  const hasLngChanged = formLng !== toNumber(lng)

  if (!hasLatChanged && !hasLngChanged) return

  const lngLat = [toNumber(lng), toNumber(lat)]
  const geoJsonPoint = attempt(point, lngLat)
  const validPoint = !isError(geoJsonPoint)

  const geometry = validPoint ? geoJsonPoint.geometry : null

  if (!geometry) return

  const enclosingBuilding = findEnclosing(geometry, 'building')
  const enclosingLocation = findEnclosing(geometry, 'location')
  const availableFloors = get(enclosingBuilding, 'entity.floors', [])

  const hasChangedLocation = prevEnclosingLocation !== enclosingLocation
  const hasChangedBuilding = prevEnclosingBuilding !== enclosingBuilding

  // NOTE: signal must not change from its original location
  if (hasChangedLocation) {
    const nextSearch = queryString.stringify({ ...prevUrlQuery })

    emitter.emit('notification:add', {
      message: 'A Signal must be remain within its original location boundary',
      theme: 'alert',
      title: 'Error',
    })

    return history.push({ search: nextSearch })
  }

  // NOTE: remove floorsRef value if no available floors or building has
  // changed, this will occur when the marker has been moved
  if (hasChangedBuilding || isEmpty(availableFloors)) {
    change('floorsRef', [])
  }

  touch('geometry.coordinates.1')
  touch('geometry.coordinates.0')
  change('geometry', geometry)
}

function mapStateToProps(state, props) {
  const { form } = props

  const areaSelectors = areaModule.selectors(state)('default')
  const findEnclosing = areaSelectors.findEnclosing
  const fieldsMeta = getFormMeta(form)(state)
  const formSyncErrors = getFormSyncErrors(form)(state)
  const formValues = getFormValues(form)(state)

  return {
    fieldsMeta,
    findEnclosing,
    formSyncErrors,
    formValues,
    zoneCache: state.zones.cache,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteSignal: id => {
      return dispatch(signalModule.remove(id))
    },
    fetchZones: params => dispatch(zoneModule.query('default', params)),
    removeZone: zoneId => {
      dispatch(zoneModule.removeFromCache(zoneId))
      dispatch(zoneModule.removeFromList('default', [zoneId]))
      dispatch(zoneModule.invalidateList('default'))
    },
    saveSignal: (id, payload) => dispatch(signalModule.save({}, payload, id)),
  }
}
