import { inRange } from 'lodash'
import { connect } from 'react-redux'
import React from 'react'
import moment from 'moment'

import { dateTime } from 'config/theme/formats'
import Icon from 'components/icon'

export default connect(mapStateToProps)(DatetimeTimezone)

const DIFF_THRESHOLD = 120000 // milliseconds
const TIMEZONE_REGEX = /z$/
const styles = {
  icon: {
    marginRight: 4,
  },
}

function DatetimeTimezone(props) {
  const {
    appendTimezone,
    calendar = false,
    datetime,
    diff,
    format = dateTime.default,
    timezone,
  } = props

  // Before appending timezone to format, check that the supplied format does
  // not already include it
  const formatIncludesTimezone = TIMEZONE_REGEX.test(format)
  const displayFormat =
    appendTimezone && !formatIncludesTimezone ? `${format} z` : format

  const mTimezoneTime = moment(datetime).tz(timezone)

  const timezoneTimeText = calendar
    ? mTimezoneTime.calendar()
    : mTimezoneTime.format(displayFormat)

  const outsideThreshold =
    diff && !inRange(diff, -DIFF_THRESHOLD, DIFF_THRESHOLD)
  const title = buildTitle(props, outsideThreshold)

  return (
    <span title={title}>
      {outsideThreshold && <Icon name="activity" theme={styles.icon} />}
      {timezoneTimeText}
    </span>
  )
}

function buildTitle(props, outsideThreshold) {
  const { createdAt, datetime, timezone, diff, titleLabel = 'Time' } = props
  const { full } = dateTime

  const datetimeMoment = moment(datetime).tz(timezone)
  const datetimeText = datetimeMoment.format(full)

  let displayTitle = `${titleLabel}: ${datetimeText}`

  if (createdAt && outsideThreshold) {
    const createdMoment = moment(createdAt).tz(timezone)
    const createdText = createdMoment.format(full)
    const diffText = moment.duration(diff).humanize()
    displayTitle += `\nReceived Time: ${createdText}`
    displayTitle += `\nTime to Receive: ${diffText}`
  }

  return displayTitle
}

function mapStateToProps(state, props) {
  const timezone = props.timezone || state.app.timezone

  return {
    timezone,
  }
}
