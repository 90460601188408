import { compose, setPropTypes, pure } from 'recompose'
import { compact } from 'lodash'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'

import Icon from 'components/icon'
import Button from 'components/button'

import styles from './styles'
import { withTranslation } from 'react-i18next'

const PROP_TYPES = {
  onHide: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  messageT: PropTypes.string,
  title: PropTypes.string.isRequired,
  theme: PropTypes.string,
}

export default compose(
  pure,
  setPropTypes(PROP_TYPES),
  withTranslation(),
  Radium
)(Notification)

function Notification(props) {
  const { onHide: handleHide, message, messageT, theme, title, t } = props

  let icon
  let style

  switch (theme) {
    case 'alert':
      icon = 'circle-cross'
      style = 'negative'
      break
    case 'download':
      icon = 'download'
      style = 'neutral'
      break
    case 'info':
      icon = 'info'
      style = 'neutral'
      break
    default:
      icon = 'alert'
      style = 'warning'
  }

  return (
    <div style={[styles.root, styles[theme]]}>
      <div style={styles.iconContainer}>
        <Icon theme={styles.icon} name={icon} />
      </div>
      <div style={styles.content}>
        <p style={styles.text}>
          <span style={styles.title}>{title} </span>
          <span>{messageT ? t(messageT) : message}</span>
        </p>
      </div>
      <div style={styles.options}>
        <Button
          onClick={handleHide}
          theme="transparent"
          style={styles.buttonSecondary}
        >
          {t('button.hide')}
        </Button>
      </div>
    </div>
  )
}
