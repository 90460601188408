import React from 'react'
import { Block, Flex } from 'components/common'
import Icon from 'components/icon'
import ListAction from 'components/list-next/components/action'
import ListContent from 'components/list-next/components/content'
import ListDescription from 'components/list-next/components/description'
import ListIcon from 'components/list-next/components/icon'
import ListItem from 'components/list-next'
import ListSubtitle from 'components/list-next/components/subtitle'
import ListTitle from 'components/list-next/components/title'
import TimeAgo from 'components/time-ago'
import { colors } from 'config/theme'

interface AuditEntryProps {
  hasZone?: boolean
  handleGoToZone?: (zone: string) => void
  item: {
    description: string
    meta: {
      score: string
      target: number
      targetServiceLevel: string
      title: string
    }
  }
  onClick: () => void
  style: React.CSSProperties
  timeAgo: {
    timestamp: string
    timezone: string
  }
  userAndTimestamp: string
  zone: string
}

export default function AuditEntry(props: AuditEntryProps): JSX.Element {
  const {
    hasZone = false,
    handleGoToZone = null,
    item,
    onClick,
    style,
    timeAgo,
    userAndTimestamp,
    zone,
  } = props
  const { timestamp, timezone } = timeAgo
  const { description, meta } = item

  const styles = {
    listIcon: {
      fontSize: 24,
    },
    timeAgo: {
      color: colors.gray.normal,
      fontSize: 11,
    },
  }

  return (
    <Block>
      <ListItem onClick={onClick} {...style}>
        <Block>
          <ListIcon name="pencil-circle" styles={styles.listIcon}>
            <TimeAgo
              fromNow
              timestamp={timestamp}
              timezone={timezone}
              style={styles.timeAgo}
            />
          </ListIcon>
        </Block>
        <ListContent>
          <Flex>
            <ListTitle>{`${meta.title} -`}</ListTitle>
            {meta?.target ? (
              <Flex>
                <Block paddingLeft={5} paddingRight={5}>
                  <Icon
                    color={
                      meta?.targetServiceLevel === 'below'
                        ? colors.red.normal
                        : colors.green.normal
                    }
                    name={
                      meta?.targetServiceLevel === 'below'
                        ? 'circle-cross-bold'
                        : 'circle-check-bold'
                    }
                  />
                </Block>
                <ListTitle
                  color={
                    meta?.targetServiceLevel === 'below'
                      ? colors.red.normal
                      : colors.green.normal
                  }
                >
                  {meta?.score}
                </ListTitle>
              </Flex>
            ) : (
              <ListTitle>&nbsp;{meta?.score}</ListTitle>
            )}
          </Flex>
          <ListSubtitle>{description}</ListSubtitle>
          <ListDescription>{userAndTimestamp}</ListDescription>
        </ListContent>
        {hasZone && handleGoToZone && (
          <ListAction icon="map-target" onClick={handleGoToZone(zone)} />
        )}
      </ListItem>
    </Block>
  )
}
