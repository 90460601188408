import { omit } from 'lodash'
import { REQUEST } from '../../../middleware/request'

export default function buildActionCreators(actions, baseUrlFn, headersFn, paramsFn) {
  return {
    getSchedule,
  }

  function getSchedule(params = {}) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/Lighthouse-Integrations/v4/api/GetSchedule`
      const headers = headersFn(params, state)
      const sanitizedParams = omit(params, 'applicationId', 'locationId')
      return dispatch(getRequest(endpoint, headers, sanitizedParams))
    }
  }

  function getRequest(endpoint, headers, params) {
    return {
      [REQUEST]: {
        types: [actions.QUERY_REQUEST, actions.QUERY_SUCCESS, actions.QUERY_ERROR],
        method: 'GET',
        endpoint,
        headers,
        query: params,
      },
    }
  }
}
