import { getModule } from '@lighthouse/sdk'
import React, { useCallback, useState } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withTranslation } from 'react-i18next'
import { useMount } from 'react-use'

import { CONFIG_ID, useAuth } from 'components/useAuth'
import { BasicForm } from 'components/form'
import { Flex } from 'components/common'
import Alert from 'components/alert'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'

const appModule = getModule('app')

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(OidcForm)

function OidcForm(props) {
  const {
    error,
    setApplication,
    setAuthenticationLoadingTrue,
    submitting,
    t,
  } = props
  const { authorize, getCredentials } = useAuth()
  const [loginLabel, setLoginLabel] = useState('Login')

  useMount(() => {
    ;(async () => {
      const config = await getCredentials(CONFIG_ID)

      if (!config || !config.loginLabel) {
        console.debug(
          'Cannot signin with oidc, missing config. Unsetting application...'
        )
        return setApplication(null)
      }

      setLoginLabel(config.loginLabel)
    })()
  })

  // Create handleSubmit dynamically (based on application authConfig).
  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      // Fire the loading spinner for authentication.
      setAuthenticationLoadingTrue()
      setTimeout(() => {
        authorize()
      }, 75) // @NOTE: longer than 50ms to see loading spinner and redux persisting.
    },
    [authorize, setAuthenticationLoadingTrue]
  )

  return (
    <Flex flexDirection="column">
      <BasicForm onSubmit={handleSubmit} noValidate>
        {error && (
          <Alert messages={[error.message]} marginBottom={0} type="error" />
        )}
        <ButtonGroup align="center" border>
          <Button
            dataTestId="submit-button"
            disabled={submitting}
            loading={submitting}
            theme="positive noMargin"
            type="submit"
          >
            {loginLabel}
          </Button>
        </ButtonGroup>
      </BasicForm>
    </Flex>
  )
}

function mapStateToProps(state) {
  const error = get(state, 'authentication.error')

  return {
    error,
    submitting: state.authentication.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setApplication: id => dispatch(appModule.setApplication(id)),
    setAuthenticationLoadingTrue: () => {
      dispatch({
        type: 'lighthouse/authentication/AUTHENTICATE_REQUEST',
      })
    },
  }
}
