import Promise from 'bluebird'
import { SubmissionError } from 'redux-form'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

import { isArray, isNil, isObject, reduce, trim } from 'lodash'

const defaultMessage = 'Application error. Please contact support'

export function getErrorCount(object) {
  return reduce(
    object,
    (sum, value) => {
      if (isObject(value) || isArray(value)) return sum + getErrorCount(value)
      if (isNil(value)) return sum
      return sum + 1
    },
    0
  )
}

export function handleApiError(error) {
  console.error(error.message || defaultMessage)
}

export function handleFormError(error) {
  const message = error.message || defaultMessage
  const response = error.response

  const _error = response ? `${message}. ${response}.` : `${message}.`
  throw new SubmissionError({ _error })
}

export function handleDeleteResponse(entity) {
  const errorMesssage = `Unable to delete ${entity}. Please contact support`
  return response => handleResponse(response, errorMesssage)
}

export function handleResponse(response, errorMessage) {
  const { data, error } = response

  if (error) return Promise.reject(new Error(errorMessage || defaultMessage))

  return data
}

export function handleSaveResponse(entity) {
  const errorMessage = `Unable to save ${entity}. Please contact support`
  return response => handleResponse(response, errorMessage)
}

export function required(value) {
  return trim(value) ? undefined : 'This is a required field'
}

export function validatePhoneNumber(phoneNumber) {
  if (!phoneNumber) return
  if (isPossiblePhoneNumber(phoneNumber)) return
  return 'This field must be a valid phone number'
}
