import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { get } from 'lodash'

const userApplicationsModule = getModule('userApplications')

export default connect(mapStateToProps)

function mapStateToProps(state) {
  const userApplication = userApplicationsModule.getCurrentApplication(state)
  const plugins = get(userApplication, 'application.plugins', {})

  return {
    plugins,
  }
}
