/**
 * A module that modify query for you.
 * @module mongooseQuery
 */
import { isEmpty, each, includes, map, some, isArray, omit } from 'lodash'
import { InvalidParameterError } from '../../errors/index'

export default function attachStaticMethod(schema) {
  schema.statics.permissionsQuery = permissionsQuery
}


/**
 * Modify the query to enforce a user accessing permitted data only
 * @param {object} defaultQuery - a mongoose query object which is going to be modified by PermissionsQuery function
 * @param {Object} permissionOptions - a object, which is generated by `ask` function, contains permission information
 * @param {array} permissionOptions.fields - the fields those user doesn't have permission to read. It looks like: fields: ['-title', '-name']
 * @param {Object.<string, object>} permissionOptions.filters - permission information for module dependency. e.g. filters: {location: {includes: [ids]}}
 * @callback {callback} callback - a callback function that contains error and modified query
 * @returns {object} query - the modified query that match user's permissions
 */
export function permissionsQuery(defaultQuery, permissionOptions) {
  const thisModule = this.modelName
  const {
    fields,
    filters,
    exactMatch,
  } = permissionOptions

  if (!fields || !filters || invalidFilters(filters)) {
    throw new InvalidParameterError()
  }

  // for filters
  const conditions = {}
  if (!isEmpty(filters)) {
    each(filters, (content, module) => {
      const isModuleFilter = module === thisModule.toLowerCase()
      const name = isModuleFilter ? '_id' : module

      // Explicity doesn't include...
      if (content.excludes) {
        conditions[name] = {
          $nin: content.excludes
        }
        return
      }

      // Exact match...
      if (isModuleFilter || exactMatch) {
        conditions[name] = {
          $in: content.includes
        }
        return
      }

      // Exact match or unreferenced...
      conditions.$or = [
        {
          [name]: {
            $in: content.includes,
          }
        }, {
          [name]: {
            $exists: false,
          },
        },
        {
          [name]: {
            $size: 0,
          },
        }
      ]
    })
  }

  // for fields
  const query = defaultQuery.where(conditions)

  if (fields[0] === '*') {
    // got all the access
    return query
  }

  const defaultFields = defaultQuery._fields

  if (defaultFields && includes(defaultFields, 1)) {
    // condition: the original query contains inclusive select, e.g. _fields: { name: 1, dob: 1 }

    // strip the fields input. original `fields` looks like this: fields: ['-title', '-time']
    const bannedFields = map(fields, field => field.replace(/^-/, ''))
    query._fields = omit(defaultFields, bannedFields)

    // make sure _fields is not empty, because when _field:[], it means read all fields
    if (!isEmpty(query._fields)) {
      return query
    }
  }

  // safe to do exclusive select now, e.g. select('-name -dob')
  const selectFields = fields.join(' ')

  return query.select(selectFields)
}

function invalidFilters(filters) {
  return some(filters, (content) => {
    // contains includes and excludes in one module
    if (content.includes && content.excludes) {
      return true
    } else if (isEmpty(content.includes) && isArray(content.includes)) {
      //  contains `includes: []`, i.e. empty include
      return true
    }
  })
}
