import React from 'react'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import FloorNavigation from 'components/mapping/floor-navigation'
import Absolute from 'components/common/absolute'
import { get } from 'lodash'

const geoModule = getModule('geo')
const areaModule = getModule('areas')

export default compose(
  connect(null, mapDispatchToProps),
  withHandlers({
    handleSelectLevel,
  })
)(IndoorNavigation)

function IndoorNavigation(props) {
  const { building, handleSelectLevel } = props

  const floors = building && get(building, 'entity.floors')
  const hasFloors = floors && floors.length

  return (
    <div>
      {hasFloors && (
        <Absolute right={10} bottom={100} zIndex={400}>
          <FloorNavigation
            floors={floors}
            selectedLevel={building.floor}
            handleSelectLevel={handleSelectLevel}
          />
        </Absolute>
      )}
    </div>
  )
}

function handleSelectLevel(props) {
  const { setBuilding, building } = props
  return floorRef => {
    setBuilding({
      id: building.id,
      floor: floorRef,
    })
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setBuilding: opts => dispatch(geoModule.setBuilding(opts)),
  }
}
