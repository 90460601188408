import { chain } from 'lodash'
import { useSelector } from 'react-redux'
import React, { useState, useMemo } from 'react'
import Fuse from 'fuse.js'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'components/flags/hook'
import colors from 'config/theme/colors'

import { Block } from 'components/common'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'
import Search from 'components/SearchBox'

import ControlWrapper from '../../../../../control-wrapper'
import FieldWrapper from '../../../../../field-wrapper'
import ListItem from '../../../../../list-item'

export default function TaskSelect(props) {
  const {
    onCancel: handleCancel,
    onNext: handleNext,
    templateId,
    setTemplateId,
  } = props

  const { t } = useTranslation()
  const templatesCache = useSelector(state => state.templates.cache)
  const [searchText, setSearchText] = useState('')
  const { isLighthouseAdmin } = useFlags()

  const template = templatesCache[templateId] || {}
  const templateResolving = template.state === 'resolving'
  const templateError = template.error
  const isDisabled = !templateId || templateResolving || templateError

  const taskItemsArr = chain(templatesCache)
    .filter(['entity.type', 'task'])
    .sortBy(['entity.name'])
    .value()
  const fuse = useMemo(() => {
    return new Fuse(taskItemsArr, {
      keys: ['entity.name'],
      threshold: 0.3,
    })
  }, [taskItemsArr])

  const searchResults = searchText
    ? fuse.search(searchText).map(result => {
        return { entity: result.item.entity }
      })
    : fuse.getIndex().docs

  const onSearchChange = e => {
    setSearchText(e.target.value)
  }

  const container = {
    borderBottom: '1px solid',
    borderColor: colors.gray.lightest,
  }

  const taskItems = searchResults.map(({ entity }, index) => {
    const isActive = templateId === entity._id
    const isLoading = isActive && templateResolving
    const hasError = isActive && templateError && !templateResolving

    return (
      <ListItem
        active={isActive}
        item={entity}
        key={index}
        hasError={hasError}
        isLoading={isLoading}
        onClick={() => setTemplateId(entity._id)}
      />
    )
  })

  return (
    <Block>
      <FieldWrapper width="100%" paddingRight="0px" paddingLeft="0px">
        {isLighthouseAdmin ? (
          <Search onChange={onSearchChange} containerStyles={container} />
        ) : null}
        {taskItems}
      </FieldWrapper>
      <ControlWrapper>
        <ButtonGroup>
          <Button dataTestId="cancel-button" onClick={handleCancel}>
            {t('button.cancel')}
          </Button>
          <Button
            dataTestId="next-button"
            disabled={isDisabled}
            theme="positive"
            onClick={handleNext}
          >
            {t('button.next')}
          </Button>
        </ButtonGroup>
      </ControlWrapper>
    </Block>
  )
}
