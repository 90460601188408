import PropTypes from 'prop-types'
import React from 'react'

import { Block } from 'components/common'

export default SelectPlaceholder

function SelectPlaceholder({
  color,
  disableSelectedList,
  isLoading,
  multi,
  placeholder = 'Select an option...',
  readOnly,
  selectedItems,
  widthText,
}) {
  const placeholderLabel = isLoading
    ? 'Loading...'
    : disableSelectedList || (selectedItems.length === 0 && !readOnly)
    ? placeholder
    : multi
    ? null
    : selectedItems.length === 1
    ? selectedItems[0].label
    : ''

  return (
    <Block
      flexGrow={1}
      color={color}
      marginBottom={10}
      marginLeft={12}
      marginRight={10}
      marginTop={10}
      width={widthText}
    >
      {placeholderLabel}
    </Block>
  )
}

SelectPlaceholder.propTypes = {
  color: PropTypes.string,
  disableSelectedList: PropTypes.bool,
  isLoading: PropTypes.bool,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
}
