import { map } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Tag from 'components/filter-menu/tag'
import Block from 'components/common/block'
import Flex from 'components/common/flex'
import colors from 'config/theme/colors'
import styles from './styles'

interface Props {
  enableTags: boolean
  onClearAllFilters: Function
  onSelect: Function
  tags: object[]
}

const Tags = (props: Props): JSX.Element => {
  const { t } = useTranslation()

  const { enableTags, onClearAllFilters, onSelect, tags } = props
  return (
    <Flex
      borderLeft={`1px solid ${colors.gray.lighter}`}
      dataTestId="filters-applied"
      display="flex"
      flexWrap="wrap"
      paddingLeft={6}
      paddingRight={6}
      paddingTop={6}
      width="100%"
    >
      {enableTags ? (
        map(
          tags,
          (tag: { label: string; typeLabel: string }, index): JSX.Element => (
            <Tag
              key={index}
              onClear={(): void => onSelect(tag)}
              tag={{
                label: tag.label,
                typeLabel: tag.typeLabel,
              }}
            />
          )
        )
      ) : (
        <Flex>
          <Block
            color={colors.gray.light}
            fontSize={12}
            height={36}
            lineHeight="36px"
            marginBottom={6}
            marginLeft={6}
            paddingTop={2}
          >
            {tags.length} Filters applied ...
          </Block>
        </Flex>
      )}
      {tags.length > 0 && (
        <Block
          {...styles.clearFilters}
          color={colors.gray.light}
          cursor="pointer"
          dataTestId="clear-filters-option"
          fontSize={12}
          height={36}
          lineHeight="36px"
          marginBottom={6}
          marginRight={6}
          onClick={(): void => onClearAllFilters()}
          paddingLeft={10}
          paddingRight={10}
          paddingTop={2}
        >
          {t('button.clearFilters')}
        </Block>
      )}
    </Flex>
  )
}

export default Tags
