import { get } from 'lodash'

import {
  isRequired,
  isUUID,
  validate,
} from '../../validation'

const beaconPredicate = (value, allValues) => !!get(allValues, 'beacon.uuid')
const latitudePredicate = (value, allValues) => !!get(allValues, 'geo.lon')
const longitudePredicate = (value, allValues) => !!get(allValues, 'geo.lat')

const beaconUUIDError = 'This is a required field when UUID defined'
const latitudeError = 'This is a required field when Longitude defined'
const longitudeError = 'This is a required field when Latitude defined'

const isBeaconMajorRequired = validate(isRequired(), beaconUUIDError, beaconPredicate)
const isBeaconMinorRequired = validate(isRequired(), beaconUUIDError, beaconPredicate)
const isLatitudeRequired = validate(isRequired(), latitudeError, latitudePredicate)
const isLongitudeRequired = validate(isRequired(), longitudeError, longitudePredicate)

export default {
  name: {
    label: 'Name',
    name: 'name',
    placeholder: 'Name',
    required: true,
    validate: [isRequired()],
  },
  beaconUuid: {
    label: 'Beacon UUID',
    name: 'beacon.uuid',
    placeholder: 'Select UUID',
    validate: [isUUID()],
  },
  beaconMajor: {
    label: 'Beacon Major',
    name: 'beacon.major',
    placeholder: 'Beacon Major',
    validate: [isBeaconMajorRequired],
  },
  beaconMinor: {
    label: 'Beacon Minor',
    name: 'beacon.minor',
    placeholder: 'Beacon Minor',
    validate: [isBeaconMinorRequired],
  },
  location: {
    label: 'Location',
    name: 'location',
    placeholder: 'Select Location',
    required: true,
    validate: [isRequired()],
  },
  latitude: {
    label: 'Latitude',
    name: 'geo.lat',
    placeholder: 'Latitude',
    validate: [isLatitudeRequired],
  },
  longitude: {
    label: 'Longitude',
    name: 'geo.lon',
    placeholder: 'Longitude',
    validate: [isLongitudeRequired],
  },
}
