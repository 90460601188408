import LighthouseLoggly from '@lighthouse/loggly'
import Promise from 'bluebird'
import fetchPonyfill from 'fetch-ponyfill'

const environment = process.env.NODE_ENV

const { fetch } = fetchPonyfill({ Promise })
const defaultProps = { environment }
const key = '50fc6105-7d10-4797-ba77-5278757fb5dd'
const tags = ['lighthouse-console', 'lighthouse-console-client', environment]

export default new LighthouseLoggly({ defaultProps, fetch, key, tags })
