import { connect } from 'react-redux'
import { compose, withHandlers, withState } from 'recompose'
import {
  compact,
  delay,
  each,
  every,
  includes,
  isEmpty,
  join,
  map,
  omit,
  pick,
  reduce,
  sum,
} from 'lodash'
import { getModule } from '@lighthouse/sdk'
import { Storage } from 'aws-amplify'
import cuid from 'cuid'
import fileType from 'file-type'
import Promise from 'bluebird'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import colors from 'config/theme/colors'

import Anchor from 'components/anchor'
import Button from 'components/button'
import Icon from 'components/icon'
import { Flex, Block } from 'components/common'

import * as logger from 'utils/logger'

const applicationUserModule = getModule('applicationUsers')

export const ConnectedUploadButton = connect(mapStateToProps)

export default compose(
  ConnectedUploadButton,
  withState('files', 'setFiles', {}),
  withHandlers({ handleProgress }),
  withHandlers({ handleChange }),
  Radium
)(UploadButton)

// NOTE All files are uploaded to the us-east-1 region. We can remove this
// hardcoded region once per region bucket support is added
const AWS_UPLOAD_BUCKET_REGION = 'us-east-1'
const DEFAULT_CONTENT_TYPES = ['image/jpg', 'image/jpeg', 'image/png']
const DEFAULT_ACCEPT = join(DEFAULT_CONTENT_TYPES, ',')
const DEFAULT_MINIMUM_PROGRESS_VALUE = 10
const DEFAULT_MAX_FILE_SIZE = 20000000
const DEFAULT_MAX_FILES = 0

const inputStyle = {
  height: '100%',
  position: 'absolute',
  right: 0,
  opacity: 0,
  top: 0,
  width: '100%',
}

const anchorStyle = {
  color: colors.gray.light,
  fontSize: '28px',
  fontWeight: 200,
  paddingLeft: '10px',
}

export function UploadButton(props) {
  const {
    accept = DEFAULT_ACCEPT,
    files,
    handleChange,
    minimumProgressValue = DEFAULT_MINIMUM_PROGRESS_VALUE,
    multiple = true,
    onError: handleError,
    onSuccess: handleSuccess,
    text = 'Drag & Drop or Browse',
    tooltip,
  } = props

  const isUploading = !isEmpty(files)
  const uploaded = sum(map(files, 'uploaded'))
  const total = sum(map(files, 'total'))
  const complete = Math.round((uploaded / total) * 100)
  const finished = complete === 100

  const background = isUploading
    ? `linear-gradient(90deg, #B3ECFF ${complete}%, #EBEBEB ${complete}%)`
    : null

  const tooltipEl = tooltip ? (
    <Anchor tooltip={tooltip}>
      <Icon name="help" theme={anchorStyle} />
    </Anchor>
  ) : null

  const label = !isUploading
    ? text
    : finished
    ? 'Uploaded'
    : isNaN(complete) || complete < minimumProgressValue
    ? 'Uploading'
    : `Uploading ${complete}%`

  const cursor = isUploading ? 'not-allowed' : 'pointer'

  return (
    <Flex>
      <Button
        backgroundImage={background}
        cursor={cursor}
        flexGrow={1}
        lineHeight="0"
        height="auto"
        marginRight="0px"
        padding="20px"
        position="relative"
      >
        {label}
        {!isUploading && (
          <input
            accept={accept}
            multiple={multiple}
            onChange={handleChange}
            style={inputStyle}
            type="file"
          />
        )}
      </Button>
      <Block paddingTop="5px">{tooltipEl}</Block>
    </Flex>
  )
}

function handleChange(props) {
  const {
    contentTypes = DEFAULT_CONTENT_TYPES,
    files,
    getUserFullName,
    handleProgress,
    maxFiles = DEFAULT_MAX_FILES,
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
    onError: handleError,
    onSuccess: handleSuccess,
    prefix,
    setFiles,
    user,
  } = props

  return event => {
    const maxFilesExceeded = maxFiles && maxFiles < event.target.files.length

    if (maxFilesExceeded) {
      handleError(
        new Error(
          `Unable to upload. Maximum number of files (${maxFiles}) exceeded`
        )
      )
      logger.error(
        `Unable to upload files, the maximum quantity is ${maxFiles}`
      )
      return
    }

    // NOTE: filter out invalid files for processing
    const filterValidFiles = Promise.filter(
      event.target.files,
      file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()

          reader.onload = function readerOnLoad() {
            // NOTE: we check the file headers here as users can easily dupe the
            // browser file api by changing the file extension
            const type = fileType(new Uint8Array(this.result))
            const isValidType = type && includes(contentTypes, type.mime)
            const isValidSize = file.size <= maxFileSize

            // NOTE: alert invalid files but continue to upload valid files
            if (!isValidType || !isValidSize) {
              const message = `Unable to upload ${file.name} as invalid type or exceeds max file size`
              delay(handleError, '100', new Error(message))
              return resolve(false)
            }

            resolve(true)
          }

          reader.onerror = reject
          reader.readAsArrayBuffer(file)
        })
    )

    return filterValidFiles
      .then(validFiles => {
        // NOTE: if no valid files then return
        if (isEmpty(validFiles)) return

        // NOTE: set initial file upload state with total file size
        const initialFiles = reduce(
          validFiles,
          (memo, file, index) => {
            memo[index] = { uploaded: 0, total: file.size }
            return memo
          },
          {}
        )

        setFiles(initialFiles)

        // NOTE: following current implementation of storing all data under the
        // current application id so overwriting default prefixes set by amplify
        // storage
        const customPrefix = { private: '', protected: '', public: '' }

        return Promise.map(validFiles, (file, index) => {
          const { lastModified, name = '', size, type: contentType } = file

          const fileName = name.replace(/\.[^/.]+$/, '')
          const extension = name.split('.').pop()
          const uuid = cuid()
          const key = `${prefix}/${uuid}.${extension}`
          const progressCallback = handleProgress(index)
          const options = {
            region: AWS_UPLOAD_BUCKET_REGION,
            contentType,
            customPrefix,
            progressCallback,
          }
          const userFullName = getUserFullName(user)

          return Storage.put(key, file, options)
            .then(({ key }) => ({
              name: fileName,
              extension,
              lastModified,
              path: `${key}`,
              size,
              uploadedAt: new Date(),
              uploadedBy: {
                id: user,
                label: userFullName,
                type: 'user',
              },
            }))
            .catch(error => {
              // NOTE: remove file from files map so we can
              // show the correct uploaded and total calculations
              setFiles(previousFiles => omit(previousFiles, [index]))
              handleError(new Error(`Unable to upload file ${name}`))
              logger.error(`Unable to upload file ${name}`, error)
            })
        })
      })
      .then(handleSuccess)
      .finally(() => setFiles({}))
      .catch(error => {
        handleError(new Error('Unable to upload. Please contact support.'))
        logger.error(`Unable to upload file ${name}`, error)
      })
  }
}

function handleProgress(props) {
  const { setFiles } = props
  return index => ({ loaded, total }) => {
    const updatedFile = {
      [index]: { uploaded: loaded, total },
    }

    setFiles(previousFiles => ({
      ...previousFiles,
      ...updatedFile,
    }))
  }
}

function mapStateToProps(state, ownProps) {
  const applicationId = state.app.applicationId
  const data = (state.user && state.user.data) || {}
  const user = data._id

  const applicationUserSelectors = applicationUserModule.selectors(state)(
    'default'
  )
  const getUserFullName = applicationUserSelectors.getUserFullName

  // NOTE: allow override otherwise fallback to application id
  const prefix = ownProps.prefix || applicationId

  return {
    prefix,
    getUserFullName,
    user,
  }
}

ConnectedUploadButton.PropTypes = {
  prefix: PropTypes.string,
  getUserFullName: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
}

UploadButton.propTypes = {
  accept: PropTypes.string,
  files: PropTypes.object.isRequired,
  getUserFullName: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleProgress: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.number,
  minimumProgressValue: PropTypes.number,
  multiple: PropTypes.bool,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  setFiles: PropTypes.func.isRequired,
  text: PropTypes.string,
  tooltip: PropTypes.array,
  user: PropTypes.string.isRequired,
}
