import {
  chain,
  concat,
  get,
  map,
  trim,
} from 'lodash'

import { registerPosthooks } from 'redux-hook-middleware'
import { batchActions } from 'redux-batched-actions'

import { actions as activityActions } from '../activity'
import { actions as signalActions } from '../signals'

import {
  setMarkers,
  removeMarkers,
  removeMarkersByTypes,
} from './'

import {
  ACTIVITY_MARKERS,
  MARKER_ACTIVITY,
  MARKER_AUDIT,
  // MARKER_EXCEPTION,
  MARKER_ISSUE,
  // MARKER_SHIFT,
  MARKER_SIGNAL,
  MARKER_TASK,
} from './constants'

const BUILD_ACTIVITY_MARKER_STRATEGIES = {
  auditentries: buildAuditMarker,
  issues: buildIssueMarker,
  // loopexceptions: buildLoopExceptionMarker,
  // shifts: buildShiftMarker,
  taskentries: buildTaskMarker,
}

const addFn = markerType => (store, action) => (
  dispatchSetMarkers(markerType, store, action)
)
const removeFn = (store, action) => store.dispatch(removeMarkers([action.id]))

// FIND_SUCCESS
registerPosthooks({
  [activityActions.FIND_SUCCESS]: addFn(MARKER_ACTIVITY),
  [signalActions.FIND_SUCCESS]: addFn(MARKER_SIGNAL),
})

// ADD_TO_CACHE
registerPosthooks({
  [activityActions.ADD_TO_CACHE]: addFn(MARKER_ACTIVITY),
  [signalActions.ADD_TO_CACHE]: addFn(MARKER_SIGNAL),
})

// SAVE_SUCCESS
registerPosthooks({
  [activityActions.SAVE_SUCCESS]: addFn(MARKER_ACTIVITY),
  [signalActions.SAVE_SUCCESS]: addFn(MARKER_SIGNAL),
})

// QUERY_SUCCESS
registerPosthooks({
  [activityActions.QUERY_SUCCESS]: addFn(MARKER_ACTIVITY),
  [signalActions.QUERY_SUCCESS]: addFn(MARKER_SIGNAL),
})

// REMOVE_SUCCESS
registerPosthooks({
  [activityActions.REMOVE_SUCCESS]: removeFn,
  [signalActions.REMOVE_SUCCESS]: removeFn,
})

// REMOVE_FROM_CACHE
registerPosthooks({
  [activityActions.REMOVE_FROM_CACHE]: removeFn,
  [signalActions.REMOVE_FROM_CACHE]: removeFn,
})

/**
 * dispatchSetMarkers
 * dispatches action for setting marker based on array of data
 * @param {String} markerType - type of marker
 * @param {Object} store - redux store object
 * @param {Array} data - array of values to convert to markers
 */
export default function dispatchSetMarkers(markerType, store, action) {
  const markers = buildMarkers(markerType, action)
  const setMarkersAction = setMarkers(markers)

  const batchedActions = [setMarkersAction]

  // NOTE: Clear all activity markers on query success when append to list is false
  if (action.type === activityActions.QUERY_SUCCESS && !action.appendToList) {
    batchedActions.unshift(removeMarkersByTypes(ACTIVITY_MARKERS))
  }

  return store.dispatch(batchActions(batchedActions))
}

function buildMarkers(type, action) {
  const data = concat([], action.data)

  if (type === MARKER_ACTIVITY) {
    return chain(data)
      .map(buildActivityMarker)
      .compact()
      .value()
  }

  if (type === MARKER_SIGNAL) {
    return map(data, buildSignalMarker)
  }

  return []
}

function buildActivityMarker(entity) {
  const typeFn = BUILD_ACTIVITY_MARKER_STRATEGIES[entity.type]

  if (!typeFn) {
    return null
  }

  return typeFn(entity)
}

export function buildIssueMarker(entity) {
  return {
    // NOTE: documentId must be used as id in order
    // to merge changes for document across activities
    id: entity.documentId,
    geometry: entity.geo,
    properties: {
      expiresAt: entity.expiresAt,
      floorsRef: get(entity, 'area.floorsRef'),
      icon: get(entity, 'meta.status'),
      label: entity.title,
      meta: entity.meta,
      search: entity.title,
      timestamp: entity.timestamp,
      type: MARKER_ISSUE,
    },
    type: 'Feature',
  }
}

export function buildSignalMarker(entity) {
  const {
    _id: id,
    childOf = [],
    label = '',
    reference = '',
    geometry,
    floorsRef,
    type: signalType, // beacon, qrcode or nfc
  } = entity

  const search = trim(`${label} ${reference}`)

  const marker = {
    id,
    geometry,
    properties: {
      childOf,
      floorsRef,
      label,
      search,
      type: MARKER_SIGNAL,
    },
    type: 'Feature',
  }

  if (signalType) marker.properties.subType = signalType

  return marker
}

export function buildTaskMarker(entity) {
  return {
    // NOTE: documentId must be used as id in order
    // to merge changes for document across activities
    id: entity.documentId,
    geometry: entity.geo,
    properties: {
      expiresAt: entity.expiresAt,
      floorsRef: get(entity, 'area.floorsRef'),
      label: entity.title,
      meta: entity.meta,
      search: entity.title,
      timestamp: entity.timestamp,
      type: MARKER_TASK,
    },
    type: 'Feature',
  }
}

export function buildAuditMarker(entity) {
  return {
    // NOTE: documentId must be used as id in order
    // to merge changes for document across activities
    id: entity.documentId,
    geometry: entity.geo,
    properties: {
      expiresAt: entity.expiresAt,
      floorsRef: get(entity, 'area.floorsRef'),
      label: entity.title,
      meta: entity.meta,
      search: entity.title,
      timestamp: entity.timestamp,
      type: MARKER_AUDIT,
    },
    type: 'Feature',
  }
}
