export function getPrettyDuration(duration) {
  const days = Math.floor(duration.asDays())
  const hours = Math.floor(duration.asHours())

  const hoursInDay = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  if (days > 0) {
    return `${days}d ${hoursInDay}h`
  }
  if (hours > 0) {
    return `${hours}h ${minutes}m`
  }
  if (minutes > 0) {
    return `${minutes}m`
  }

  return `${seconds}s`
}
