import { useEffect, useState, useMemo } from 'react'
import { useRequest } from 'components/useRequest'
import { Application, UseApplicationList } from '../applications.types'

function filterApplications(applications, filter): Application[] {
  return !filter
    ? applications
    : applications.filter((item): Application[] => {
        return (
          item.name.toLowerCase().includes(filter.toLowerCase()) ||
          (item.slug && item.slug.toLowerCase().includes(filter.toLowerCase()))
        )
      })
}

export function useApplicationList(): UseApplicationList {
  const { request } = useRequest()
  const [applications, setApplications] = useState([])
  const [error, setError] = useState()
  const [filter, setFilter] = useState()
  const [isLoading, setLoading] = useState(false)

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      try {
        setError(null)
        setLoading(true)
        const response = await request<Application[]>(
          '/applications',
          {},
          { scopeByApplication: false }
        )
        setApplications(response.json)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const filteredApplications = useMemo(
    (): Application[] => filterApplications(applications, filter),
    [applications, filter]
  )

  return {
    applications,
    error,
    filteredApplications,
    isLoading,
    setFilter,
  }
}

export default useApplicationList
