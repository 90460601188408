import { pure } from 'recompose'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { isString } from 'lodash'

import { Block, Flex } from 'components/common'
import { colors } from 'config/theme'
import Icon from 'components/icon'
import Tooltip from 'components/tooltip'

import { useFloating, offset, shift, flip } from '@floating-ui/react-dom'
import { FloatingPortal } from '@floating-ui/react-dom-interactions'

export default pure(SelectTag)

function SelectTag(props) {
  const { disabled, item = {}, onClick, readOnly, style = {} } = props
  const { height, left, position, top } = style
  const { label = '', locationCount } = item

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'top-start',
    strategy: 'absolute',
    middleware: [offset({ mainAxis: 89, crossAxis: 290 })],
  })

  const [isHovered, setIsHovered] = useState()

  const unknownTag = isString(label) ? label.includes('Unknown') : false

  const entity = unknownTag ? item.label.replace('Unknown ', '') : ''

  const itemLabel =
    locationCount !== undefined ? `${label} (${locationCount})` : label

  const isReadOnly = disabled || readOnly
  const colour = isReadOnly ? colors.gray : colors.blue
  return (
    <Flex
      alignItems="center"
      backgroundColor={unknownTag ? colors.red.lightest : colour.lightest}
      borderColor={unknownTag ? colors.red.lighter : colour.lighter}
      borderRadius={2}
      borderStyle="solid"
      borderWidth={1}
      boxSizing="border-box"
      color={unknownTag ? colors.red.normal : colour.dark}
      fontWeight={400}
      height={height}
      left={left}
      marginBottom={2}
      marginLeft={4}
      marginTop={2}
      position={position}
      top={top}
      zIndex={10}
      onMouseEnter={() => unknownTag && setIsHovered(true)}
      onMouseLeave={() => unknownTag && setIsHovered(false)}
      reference={reference}
    >
      {!isReadOnly && (
        <Block
          borderRightColor={unknownTag ? colors.red.lighter : colour.lighter}
          borderRightStyle="solid"
          borderRightWidth={1}
          onClick={onClick}
          paddingLeft={6}
          paddingRight={6}
          paddingTop={10}
          paddingBottom={10}
        >
          x
        </Block>
      )}
      <Block
        fontSize={12}
        paddingLeft={6}
        paddingRight={6}
        paddingTop={10}
        paddingBottom={10}
      >
        {itemLabel}
        {unknownTag && (
          <Icon paddingLeft="0.25rem" name="help" fontSize="0.75rem" />
        )}
        <FloatingPortal>
          {isHovered && unknownTag && (
            <div
              ref={floating}
              style={{
                position: strategy,
                top: y || 0,
                left: x || 0,
              }}
            >
              <Tooltip
                zIndex={11}
                title={`Unknown ${entity}`}
                message={`This ${entity.toLowerCase()} does not exist or you do not have permission to view it.`}
                dataTest={'unknown-location-tooltip'}
                noArrow={unknownTag}
              />
            </div>
          )}
        </FloatingPortal>
      </Block>
    </Flex>
  )
}

SelectTag.propTypes = {
  disabled: PropTypes.bool,
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    locationCount: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  setIsHovered: PropTypes.func,
  isHovered: PropTypes.bool,
}
