import React from 'react'

import Flex from '../flex'

export default function Backdrop(props) {
  const { children, ...otherProps } = props

  return (
    <Flex
      alignItems="center"
      backgroundColor="rgba(255, 255, 255, 0.75)"
      height="100%"
      justifyContent="center"
      position="fixed"
      width="100%"
      {...otherProps}
    >
      {children}
    </Flex>
  )
}
