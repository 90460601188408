import colors from 'config/theme/colors'

export default {
  root: {
    position: 'relative',
    display: 'flex',
    height: 60,
    width: 60,
    padding: 0,
    lineHeight: '60px',
    borderWidth: '0 0 0 1px',
    borderStyle: 'solid',
    borderColor: colors.gray.lighter,
    justifyContent: 'center',
    backgroundColor: colors.white,
    transition: '0.2s',
    ':hover': {
      backgroundColor: colors.gray.lightest,
      cursor: 'pointer',
    },
    ':focus': {
      outline: 'none',
    },
  },
  selected: {
    backgroundColor: colors.blue.lightest,
    color: colors.blue.dark,
    ':hover': {
      backgroundColor: colors.blue.lightest,
    },
  },
  arrowOuter: {
    position: 'absolute',
    bottom: -8,
    left: 22,
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTopWidth: 8,
    borderTopStyle: 'solid',
    borderTopColor: colors.gray.lighter,
  },
  arrowInner: {
    position: 'absolute',
    bottom: 1,
    left: -7,
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderTopWidth: 7,
    borderTopStyle: 'solid',
    borderTopColor: colors.blue.lightest,
  },
  count: {
    position: 'absolute',
    top: 9,
    right: 9,
    height: 14,
    fontSize: 8,
    lineHeight: '14px',
    borderRadius: 7,
    backgroundColor: colors.blue.normal,
    color: colors.white,
    padding: '0 5px',
  },
  icon: {
    fontSize: 24,
  },
}
