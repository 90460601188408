import { registerPosthook, registerPrehook } from 'redux-hook-middleware'
import {
  get,
  pick,
} from 'lodash'

import { record } from '../../modules/logs'
import { PENDING_LIST_ID } from '../reducers/list'

const DEFAULT_LOG_MESSAGE = 'FailedRequestError'

export default function hooks(actions, namespace) {
  const statePath = namespace.replace('/', '.')

  // Pre Hooks
  registerPrehook(actions.SAVE_SUCCESS, (store, action) => {
    const state = store.getState()
    const resource = get(state, `${statePath}.cache.${action.id}`, {})

    /*
    * NOTE Here's some background to why we need to check optimistic on the
    * state and the action...
    *
    * When we save an item optimistically, we attach optimistic to the action.
    * However, when we retry we don't specify optimistic because we want the
    * retry to be more robust, so in the hook middleware we previously checked
    * the cache for the optimistic state and not the action. However, that can
    * trigger a race condition on socket events, where the following happens:
    *
    * 1. User saves optimistically (actions dispatched with optimistic = true)
    * 2. Request is successful, but websocket success action fires first, which
    *    won't have optimistic flag on the action and therefore won't remove
    *    pending list item
    * 3. Cached resource optimistic flag is removed, as we know it's up to date
    * 4. HTTP request finally resolves and fires the REMOVE_FROM_LIST hook
    * 5. Without checking for optimistic on the action, the hook will never fire
    *    and therefore the resource get's 'stuck' in a pending state
    *
    * Hence the need for the optimistic check on the resource AND action
    */
    if (!resource.optimistic && !action.optimistic) return

    const hookAction = {
      ids: [action.id],
      listId: PENDING_LIST_ID,
      type: actions.REMOVE_FROM_LIST,
    }

    store.dispatch(hookAction)
  })

  // Post Hooks
  registerPosthook(actions.QUERY_ERROR, recordLog)
  registerPosthook(actions.FIND_ERROR, recordLog)
  registerPosthook(actions.SAVE_ERROR, recordLog)
  registerPosthook(actions.REMOVE_ERROR, recordLog)
  registerPosthook(actions.SAVE_ROLLBACK, (store, action) => {
    // In a rollback action, the payload prop is the error object
    const message = get(action, 'payload.message')
    const data = pick(action, 'id', 'payload', 'type')
    return store.dispatch(record('error', {
      message,
      data,
    }))
  })
  registerPosthook(actions.SAVE_REQUEST, (store, action) => {
    if (!action.optimistic) return

    const hookAction = {
      ids: [action.id],
      listId: PENDING_LIST_ID,
      type: actions.ADD_TO_LIST,
    }

    store.dispatch(hookAction)
  })
}

function recordLog(store, action) {
  const message = get(action, 'error.message', DEFAULT_LOG_MESSAGE)
  return store.dispatch(record('error', {
    message,
    data: action,
  }))
}
