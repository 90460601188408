import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { compose } from 'recompose'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { WithPermissions } from '@lighthouse/react-components'

import { assign, first, get, last, values } from 'lodash'

import { formatDateInTimezone } from 'helpers/datetime'

import { Banner as ListBanner } from 'components/list-next/components'
import { Block, Flex } from 'components/common'
import FilterList from './components/filter-list'
import InfiniteLoaderList from './components/infinite-loader-list'
import ListPlaceholder from 'components/list/list-placeholder'
import RowContent from './components/row-content'

const PER_PAGE = 20

const STORE_TYPE_LISTS = {
  undefined: 'all',
  auditentries: 'auditentries',
  issues: 'issues',
  taskentries: 'taskentries',
  loopexceptions: 'loopexceptions',
  shifts: 'shifts',
}

const activityModule = getModule('activity')

class ActivityList extends Component {
  constructor(props) {
    super(props)

    this.state = { hasAllHistoricalData: false }

    this.isRowLoaded = this.isRowLoaded.bind(this)
    this.loadMoreRows = this.loadMoreRows.bind(this)
    this.rowRenderer = this.rowRenderer.bind(this)
  }

  componentWillMount() {
    this.getLatestActivity()
  }

  componentWillUnmount() {
    this.clearListItems()
  }

  componentWillReceiveProps(nextProps) {
    const { filterType, location, setFilters, timeline } = this.props

    const {
      filterType: nextFilterType,
      location: nextLocation,
      timeline: nextTimeline,
    } = nextProps

    // NOTE: Next maps passes currentTime and Legacy maps passes
    // current within their timeline props so we must check here
    const currentTime = timeline.currentTime || timeline.current
    const nextCurrentTime = nextTimeline.currentTime || nextTimeline.current

    const isLive = timeline.isLive
    const nextIsLive = nextTimeline.isLive

    const currentTimeWillChange = nextCurrentTime !== currentTime
    const filterWillChange = nextFilterType !== filterType
    const historicTimeWillChange = currentTimeWillChange && !isLive
    const locationWillChange = nextLocation !== location
    const modeWillChange = nextIsLive !== isLive

    if (filterWillChange) {
      this.setState({ hasAllHistoricalData: false })
    }

    if (locationWillChange) {
      this.setState({ hasAllHistoricalData: false })
      setFilters({ type: undefined })
    }

    // NOTE: Location may change in Legacy Maps
    if (historicTimeWillChange || modeWillChange || locationWillChange) {
      this.clearListItems()
    }
  }

  componentDidUpdate(prevProps) {
    const { filterType, location, timeline } = this.props

    const {
      filterType: prevFilterType,
      location: prevLocation,
      timeline: prevTimeline,
    } = prevProps

    // NOTE: Next maps passes currentTime and Legacy maps passes
    // current within their timeline props so we must check here
    const prevCurrentTime = prevTimeline.currentTime || prevTimeline.current
    const currentTime = timeline.currentTime || timeline.current

    const prevIsLive = prevTimeline.isLive
    const isLive = timeline.isLive

    const hasCurrentTimeChanged = prevCurrentTime !== currentTime
    const hasFilterChanged = prevFilterType !== filterType
    const hasHistoricTimeChanged = hasCurrentTimeChanged && !isLive
    const hasLocationChanged = prevLocation !== location
    const hasModeChanged = prevIsLive !== isLive

    if (hasFilterChanged) {
      this.getLatestActivity()
    }

    // NOTE: Location may change in Legacy Maps
    if (hasHistoricTimeChanged || hasModeChanged || hasLocationChanged) {
      this.getActivity()
    }
  }

  render() {
    const {
      activities,
      filterType,
      handleClose,
      hasModulePermission,
      timeline = {},
      timezone,
      setFilters,
      refreshing,
    } = this.props

    const { hasAllHistoricalData } = this.state

    const { current, currentTime, isLive = true } = timeline

    const loaderRowCount = hasAllHistoricalData ? activities.length : 9999
    const listRowCount = refreshing
      ? activities.length + PER_PAGE
      : activities.length
    const timelineDatetime = formatDateInTimezone(
      currentTime || current,
      timezone
    )

    return (
      <Flex flexDirection="column" height="100%">
        <Block>
          <FilterList
            filterType={filterType}
            handleClose={handleClose}
            hasModulePermission={hasModulePermission}
            setFilters={setFilters}
          />
          {!isLive && (
            <ListBanner>Activity before {timelineDatetime}</ListBanner>
          )}
        </Block>
        <Block flexGrow={1} height="100%">
          <InfiniteLoaderList
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowRenderer={this.rowRenderer}
            loaderRowCount={loaderRowCount}
            listRowCount={listRowCount}
            refreshing={refreshing}
          />
        </Block>
      </Flex>
    )
  }

  clearListItems() {
    this.props.clearListItems(values(STORE_TYPE_LISTS))
  }

  getActivity() {
    const { fetchActivity, filterType } = this.props

    return fetchActivity({
      beforeId: undefined,
      afterId: undefined,
      type: filterType,
    })
  }

  getLatestActivity() {
    const { activities, fetchActivity, filterType } = this.props
    const firstActivityTimestamp = get(first(activities), 'entity.timestamp')

    return fetchActivity({
      after: firstActivityTimestamp || null,
      before: null,
      type: filterType,
    })
  }

  getHistoricalActivity() {
    const { activities, fetchActivity, filterType } = this.props
    const lastActivityTimestamp = get(last(activities), 'entity.timestamp')

    return fetchActivity({
      after: null,
      before: lastActivityTimestamp,
      type: filterType,
    }).then(response => {
      if (response.data && response.data.length < PER_PAGE) {
        this.setState({ hasAllHistoricalData: true })
      }
    })
  }

  fetchingContent(props2) {
    const { key, style } = props2
    return <ListPlaceholder key={key} style={style} />
  }

  isRowLoaded({ index }) {
    const { activities } = this.props
    return !!activities[index]
  }

  loadMoreRows() {
    if (this.props.refreshing) return

    return this.getHistoricalActivity()
  }

  rowContent({ index, style }) {
    const { activities, handleGoToZone, timezone } = this.props

    const item = get(activities, `${index}.entity`, {})

    return (
      <RowContent
        key={index}
        item={item}
        handleGoToZone={handleGoToZone}
        style={style}
        timezone={timezone}
      />
    )
  }

  rowRenderer(options) {
    const hasLoadedRow = this.isRowLoaded(options)

    if (hasLoadedRow) return this.rowContent(options)

    return this.fetchingContent(options)
  }
}

export default compose(
  WithPermissions,
  connect(mapStateToProps, mapDispatchToProps)
)(ActivityList)

function mapStateToProps(state, props) {
  const type = get(state, 'activity.list.default.filters.type')
  const listId = STORE_TYPE_LISTS[type]

  const activitySelectors = activityModule.selectors(state)(listId)

  const timeline = props.timeline || {}
  const currentTime = timeline.currentTime || timeline.current
  const isLive = timeline.isLive

  const before = isLive ? undefined : currentTime

  return {
    activities: activitySelectors.filterListByType({ before, listId, type }),
    filterType: type,
    refreshing: activitySelectors.state === 'resolving',
  }
}

function mapDispatchToProps(dispatch, props) {
  const clearListItems = listIds =>
    dispatch(activityModule.clearListItems(listIds))

  const fetchActivity = (params = {}) => {
    // NOTE: Set defaults for timeline as some applications
    // (e.g. Lassie) can have the timeline disabled so need
    // to have some fallback values to mimic real time!
    const {
      location,
      timeline: { end = new Date(), current, currentTime, isLive = true },
    } = props

    const { type } = params
    const listId = STORE_TYPE_LISTS[type]
    const to = new Date(isLive ? end : currentTime || current)

    const queryParams = assign(
      {},
      {
        location,
        sort: '-timestamp',
        to,
      },
      params,
      {
        appendToList: true,
        respectTimezone: false,
      }
    )

    return dispatch(activityModule.query(listId, queryParams))
  }

  const setFilters = filters =>
    dispatch(activityModule.setListFilters('default', filters))

  return {
    clearListItems,
    fetchActivity,
    setFilters,
  }
}

ActivityList.propTypes = {
  activities: PropTypes.array,
  clearListItems: PropTypes.func,
  fetchActivity: PropTypes.func,
  filterType: PropTypes.string,
  handleClose: PropTypes.func,
  handleGoToZone: PropTypes.func,
  hasModulePermission: PropTypes.func,
  location: PropTypes.string,
  setFilters: PropTypes.func,
  refreshing: PropTypes.bool,
  timeline: PropTypes.object,
  timezone: PropTypes.string,
}
