import React from 'react'
import DivIcon from '@lighthouse/react-leaflet-div-icon'
import { assign } from 'lodash'

import { typography } from 'config/theme'

const styles = {
  label: {
    color: '#534e4a',
    fontFamily: typography.defaultFontFamily,
    fontSize: typography.fontSizes.xsmall,
    left: 12,
    minWidth: 200,
    pointerEvents: 'none',
    position: 'absolute',
    textAlign: 'left',
    top: -4,
  },
  labelMedium: {
    fontSize: typography.fontSizes.small,
  },
  labelLarge: {
    fontSize: typography.fontSizes.normal,
  },
  marker: {
    backgroundColor: '#666',
    borderRadius: 16,
    width: 8,
    height: 8,
  },
}

export default function MapLabel(props) {
  const { children, latlng, offset, size, showMarker } = props

  let labelStyles = styles.label

  if (size === 'medium') {
    labelStyles = assign({}, styles.label, styles.labelMedium)
  }

  if (size === 'large') {
    labelStyles = assign({}, styles.label, styles.labelLarge)
  }

  if (offset) {
    labelStyles.marginTop = offset
  }

  return (
    <DivIcon position={latlng}>
      <div>
        {showMarker && <div style={styles.marker} />}
        <div style={labelStyles}>{children}</div>
      </div>
    </DivIcon>
  )
}
