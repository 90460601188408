import { assign, toUpper } from 'lodash'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { colors, typography } from 'config/theme'
import { Block, Flex } from 'components/common'
import { Text as InputText } from 'components/form'
import Button from 'components/button'

export default compose(
  withState('editing', 'setEditing'),
  withState('inputValue', 'setInputValue'),
  withHandlers({
    handleBlur,
    handleChange,
  }),
  lifecycle({
    componentWillMount,
    componentWillReceiveProps,
  })
)(TimePickerField)

const CUSTOM_INPUT_STYLES = {
  fontSize: 14,
  height: 46,
  marginBottom: 0,
  maxHeight: 55,
  padding: 0,
  textAlign: 'center',
  width: 46,
}

function TimePickerField(props) {
  const {
    handleBlur,
    handleChange,
    hasError,
    inputValue = '',
    onChange,
    type,
  } = props
  const borderColor = hasError ? colors.red.normal : colors.gray.lighter

  return (
    <Block width={46} marginRight={12}>
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <Block>
          <Button
            marginRight={0}
            onClick={() => onChange(type, 'increase')}
            tabIndex={-1}
            theme="transparent xsmall"
          >
            &#9650;
          </Button>
        </Block>
        <Block
          color={colors.gray.normal}
          fontFamily={typography.defaultFontFamily}
          fontSize={10}
          textAlign="center"
        >
          <InputText
            marginBottom={0}
            maxLength={2}
            onBlur={handleBlur}
            onChange={handleChange}
            style={assign({}, CUSTOM_INPUT_STYLES, { borderColor })}
            textAlignCenter
            value={inputValue}
          />
        </Block>
        <Block>
          <Button
            marginRight={0}
            onClick={() => onChange(type, 'decrease')}
            tabIndex={-1}
            theme="transparent xsmall"
          >
            &#9660;
          </Button>
        </Block>
      </Flex>
    </Block>
  )
}

TimePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

function componentWillMount() {
  const { setInputValue, value } = this.props

  setInputValue(value)
}

function componentWillReceiveProps(nextProps) {
  const { editing, inputValue, setInputValue } = this.props

  if (!editing && nextProps.value !== inputValue) {
    setInputValue(nextProps.value)
  }
}

function handleBlur(props) {
  const {
    inputValue,
    onChange,
    setEditing,
    setInputValue,
    type,
    validationRule,
    value,
  } = props

  return () => {
    setEditing(false)
    if (validationRule.test(inputValue)) {
      return onChange(type, 'text', toUpper(inputValue))
    }

    setInputValue(value)
  }
}

function handleChange(props) {
  const { setEditing, setInputValue } = props

  return ({ target }) => {
    const { value } = target

    // Validate that input value is two or less characters
    if (value.length < 3) {
      setEditing(true)
      setInputValue(toUpper(value))
    }
  }
}
