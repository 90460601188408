import { map, kebabCase, upperCase } from 'lodash'
import { getStatusDetails } from '@lighthouse/common'
import React from 'react'

import { Block } from 'components/common'
import { InputGroup, InputLabel, InputSelect } from 'components/form'
import {
  STATUS_CLOSED_TEXT,
  STATUS_OPEN_TEXT,
  STATUS_IN_PROGRESS_TEXT,
} from 'config/constants'

const STATUS_OPTIONS = [
  STATUS_CLOSED_TEXT,
  STATUS_IN_PROGRESS_TEXT,
  STATUS_OPEN_TEXT,
]

const statusOptions = map(STATUS_OPTIONS, status => ({
  label: status,
  value: kebabCase(status),
}))

export default function StatusSelect(props) {
  const statusProps = getStatusDetails(props.input.value)

  const statusStyle = statusProps.style
  const statusText = upperCase(statusProps.text)
  const dataTestId = 'statusSelect'

  if (props.readOnly) {
    return (
      <InputGroup small>
        <InputLabel label={props.label} readOnly small />
        <Block padding={10} {...statusStyle}>
          {statusText}
        </Block>
      </InputGroup>
    )
  }

  return (
    <InputSelect dataTestId={dataTestId} options={statusOptions} {...props} />
  )
}
