import React from 'react'
import TimePicker from 'rc-time-picker'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import 'rc-time-picker/assets/index.css'

import Button from 'components/button'
import { Block, Flex, Span } from 'components/common'
import DatetimeTimezone from 'components/datetime-timezone'
import Icon from 'components/icon'
import { colors } from 'config/theme'
import './styles.css'
import { useTranslation } from 'react-i18next'

export default connect(mapStateToProps)(Time)

function Time(props) {
  const {
    ignoreTimezone,
    onChange,
    readOnly,
    showPlusOneSign,
    showRedBorder,
    showTimePickerButton = true,
    timezone,
    timeFormat,
    value,
  } = props

  const { t } = useTranslation()

  const timePickerStyles = {
    alignItems: 'center',
    color: colors.gray.darker,
    border: `1px solid ${showRedBorder ? 'red' : colors.gray.lighter}`,
    borderRadius: '4px',
    display: 'flex',
    marginRight: '10px',
    padding: '14px',
    width: '100%',
  }

  const timePickerValue =
    value === ''
      ? null
      : ignoreTimezone
      ? moment(value)
      : moment.tz(value, timezone)

  const timePickerFormat = ignoreTimezone ? timeFormat : `${timeFormat} z`

  return readOnly ? (
    <Block padding={10} wordBreak="break-word">
      {isEmpty(value) ? (
        ''
      ) : (
        <DatetimeTimezone
          appendTimezone
          datetime={value}
          format={timeFormat}
          timezone={timezone}
        />
      )}
    </Block>
  ) : (
    <Flex alignItems="center" position="relative">
      {showPlusOneSign && (
        <Span
          position="absolute"
          left={72}
          top={10}
          zIndex={1000}
          fontSize="11px"
          color={colors.green.normal}
        >
          +1
        </Span>
      )}
      <TimePicker
        allowEmpty={false}
        format={timePickerFormat}
        inputIcon={<Icon name="clock" />}
        onChange={onChange}
        placeholder={t('placeholder.selectTime')}
        showSecond={false}
        style={timePickerStyles}
        use12Hours={timeFormat === 'h:mm a'}
        value={timePickerValue}
      />
      {showTimePickerButton && (
        <Button
          minWidth="80px"
          onClick={value ? () => onChange('') : () => onChange(new Date())}
        >
          {value ? t('button.clear') : t('button.now')}
        </Button>
      )}
    </Flex>
  )
}

function mapStateToProps(state, props) {
  const timezone = props.timezone || state.app.timezone

  return {
    timezone,
  }
}
