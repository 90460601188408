import {
  compact,
  includes,
  isArray,
  isEmpty,
  isPlainObject,
  isString,
  map,
  reject,
} from 'lodash'
import { htmlparser2 } from 'react-html-parser'

const LIST_KEY_MATCHER = ['ol', 'ul']
const NEW_LINE_MATCHER = /.*\n.*/
// NOTE: this will remove all whitespace except spaces
const WHITSPACE_MATCHER = /[\t\r\n\f]/g

export function convertToPdfMake(obj) {
  if (!isPlainObject(obj)) {
    return ''
  }

  const { children = [], data = '', name = '', type } = obj

  if (type === 'text' && data) {
    const trimmed = data.replace(WHITSPACE_MATCHER, '')

    // NOTE: Originally tried to achieve this by using inline styles, however PDFMake
    // does not support adding margins or padding when applying inline styles
    // (bold/italics/underline).  The easiest way to get around this was to add
    // an extra space after text.
    return trimmed
  }

  // NOTE: Reject any children that are WYSIWYG carriage returns
  const filtered = reject(children, ['data', NEW_LINE_MATCHER])
  const parsed = compact(map(filtered, convertToPdfMake))

  const key = includes(LIST_KEY_MATCHER, name) ? name : 'text'
  // NOTE: If the child is an array with a single text string, we just need to return
  // the string, so that the styles can be applied
  const text =
    isArray(parsed) && parsed.length === 1 && isString(parsed[0])
      ? parsed[0]
      : parsed

  const link = type === 'tag' && name === 'a' ? obj.attribs.href : undefined

  return {
    ...getStyleAttributes(obj),
    [key]: text,
    link,
    preserveLeadingSpaces: true,
  }
}

export function getStyleAttributes({ name = '' }) {
  const result = {}
  if (name === 'em') result.italics = true
  if (name === 'ins') result.decoration = 'underline'
  if (name === 'strong') result.bold = true
  if (name === 'a') {
    result.decoration = 'underline'
    result.color = 'blue'
  }

  if (!result.bold && !result.decoration && !result.italics) {
    result.style = name
  }

  return result
}

export function parseHtml(html) {
  if (isEmpty(html) || !isString(html)) {
    return ''
  }

  return htmlparser2.parseDOM(html, { decodeEntities: true })
}

export function toPdf(htmlString) {
  if (isEmpty(htmlString) || !isString(htmlString)) {
    return []
  }

  const arr = parseHtml(htmlString)
  return compact(map(arr, convertToPdfMake))
}
