/*
 * User Module
 *
 * Example State:
 * ==============
 *
 * {
 *   state: 'resolved',
 *   data: {
 *     firstName: 'Joe',
 *     lastName: 'Bloggs',
 *     email: 'joe.bloggs@example.com'
 *   },
 *   error: null
 * }
 */

import { REQUEST } from '../../middleware/request'
import { get } from 'lodash'

import { parseState } from '../../helpers'

import { AUTHENTICATE_SUCCESS } from '../authentication'

import { getRegionUrl, authorizationHeadersFn } from '../app'

export { default as schema } from './schema'

export const GET_REQUEST = 'lighthouse/user/GET_REQUEST'
export const GET_SUCCESS = 'lighthouse/user/GET_SUCCESS'
export const GET_ERROR = 'lighthouse/user/GET_ERROR'
export const UPDATE_REQUEST = 'lighthouse/user/UPDATE_REQUEST'
export const UPDATE_SUCCESS = 'lighthouse/user/UPDATE_SUCCESS'
export const UPDATE_ERROR = 'lighthouse/user/UPDATE_ERROR'
export const START_RESET_PASSWORD_REQUEST =
  'lighthouse/user/START_RESET_PASSWORD_REQUEST'
export const START_RESET_PASSWORD_SUCCESS =
  'lighthouse/user/START_RESET_PASSWORD_SUCCESS'
export const START_RESET_PASSWORD_ERROR =
  'lighthouse/user/START_RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_STATE = 'lighthouse/user/RESET_PASSWORD_STATE'
export const RESET_PASSWORD_REQUEST = 'lighthouse/user/RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'lighthouse/user/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'lighthouse/user/RESET_PASSWORD_ERROR'
export const ACCEPT_INVITE_REQUEST = 'lighthouse/user/ACCEPT_INVITE_REQUEST'
export const ACCEPT_INVITE_SUCCESS = 'lighthouse/user/ACCEPT_INVITE_SUCCESS'
export const ACCEPT_INVITE_ERROR = 'lighthouse/user/ACCEPT_INVITE_ERROR'
export const ROLE_REQUEST = 'lighthouse/user/ROLE_REQUEST'
export const ROLE_SUCCESS = 'lighthouse/user/ROLE_SUCCESS'
export const ROLE_ERROR = 'lighthouse/user/ROLE_ERROR'

export function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case GET_REQUEST:
    case UPDATE_REQUEST:
    case START_RESET_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return state.set('state', 'resolving')
    case GET_SUCCESS:
    case UPDATE_SUCCESS:
      return state.merge({
        state: 'resolved',
        data: action.data,
      })
    // Authenitcation requests send down user data for convenience. This hook
    // stores that data to save doing a separate user request
    case AUTHENTICATE_SUCCESS:
      if (!get(action, 'data.user')) return state
      return state.merge({
        state: 'resolved',
        data: action.data.user,
      })
    case START_RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return state.merge({
        state: 'resolved',
      })
    case GET_ERROR:
    case UPDATE_ERROR:
    case START_RESET_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
      return state.merge({
        state: 'resolved',
        error: action.error,
      })
    case RESET_PASSWORD_STATE:
      return state.merge({
        state: null,
        error: null,
      })
    default:
      return state
  }
}

export function roleRequest() {
  return (dispatch, getState) => {
    const state = getState()
    const { applicationId } = state.app
    const headers = authorizationHeadersFn(null, state)
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/applications/${applicationId}/users/role`
    return dispatch(getRole(endpoint, headers, applicationId))
  }
}

function getRole(endpoint, headers, applicationId) {
  return {
    [REQUEST]: {
      types: [ROLE_REQUEST, ROLE_SUCCESS, ROLE_ERROR],
      method: 'GET',
      headers,
      endpoint,
      attachToActions: { applicationId },
    },
  }
}

export function acceptInviteRequest(payload) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/users/action/accept-invite`
    return dispatch(acceptInvite(endpoint, payload))
  }
}

function acceptInvite(endpoint, payload) {
  return {
    [REQUEST]: {
      types: [
        ACCEPT_INVITE_REQUEST,
        ACCEPT_INVITE_SUCCESS,
        ACCEPT_INVITE_ERROR,
      ],
      method: 'POST',
      endpoint,
      body: payload,
    },
  }
}

export function getRequest() {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/user`
    const headers = authorizationHeadersFn(null, state)
    return dispatch(getUser(endpoint, headers))
  }
}

function getUser(endpoint, headers) {
  return {
    [REQUEST]: {
      types: [GET_REQUEST, GET_SUCCESS, GET_ERROR],
      method: 'GET',
      endpoint,
      headers,
    },
  }
}

export function updateRequest(payload) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/user`
    const headers = authorizationHeadersFn(null, state)
    return dispatch(updateUser(endpoint, headers, payload))
  }
}

function updateUser(endpoint, headers, payload) {
  return {
    [REQUEST]: {
      types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_ERROR],
      method: 'PUT',
      endpoint,
      headers,
      body: payload,
    },
  }
}

export function startResetPasswordRequest(payload) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/users/action/reset-password-request`
    return dispatch(startResetPassword(endpoint, payload))
  }
}

export function resetPasswordState() {
  return {
    type: RESET_PASSWORD_STATE,
  }
}

function startResetPassword(endpoint, payload) {
  return {
    [REQUEST]: {
      types: [
        START_RESET_PASSWORD_REQUEST,
        START_RESET_PASSWORD_SUCCESS,
        START_RESET_PASSWORD_ERROR,
      ],
      method: 'POST',
      endpoint,
      body: payload,
    },
  }
}

export function resetPasswordRequest(payload) {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/users/action/reset-password`
    return dispatch(resetPassword(endpoint, payload))
  }
}

function resetPassword(endpoint, payload) {
  return {
    [REQUEST]: {
      types: [
        RESET_PASSWORD_REQUEST,
        RESET_PASSWORD_SUCCESS,
        RESET_PASSWORD_ERROR,
      ],
      method: 'POST',
      endpoint,
      body: payload,
    },
  }
}
