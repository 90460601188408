export const DATE_REGEX = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

export const DAY_OF_WEEK_REGEX = /(mon|tues|wednes|thurs|fri|satur|sun)(day)/
export const DAY_OF_WEEK = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
}

export const DEFAULT_DATE_TEMPLATE = {
  dateReference: false,
  reference: 'default',
  close: { hour: 0, minute: 0 },
  open: { hour: 0, minute: 0 },
  status: 'closed',
}

export const EMPTY_SERVICE_HOURS = {
  definition: [DEFAULT_DATE_TEMPLATE],
  overrides: [],
}

export const STATUS_OPTIONS = [
  { label: 'Closed', value: 'closed' },
  { label: 'Open between times', value: 'open-between' },
  { label: 'Open 24h', value: 'open-always' },
]

export const BEHAVIOUR_OPTIONS = [
  { label: 'Closed', value: 'close' },
  { label: 'Open', value: 'open' },
]

export const DAY_DATE_OPTIONS = [
  { label: 'Date', value: 'date' },
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
]
