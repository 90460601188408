import { getModule } from '@lighthouse/sdk'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { get } from 'lodash'

import FlagsHOC from 'components/flags/hoc'
import { generate } from 'config/navigation'
import { getPathParts } from 'utils/location-helper'

const userApplications = getModule('userApplications')

export default (opts = {}) => ComposedComponent => {
  class Navigation extends Component {
    componentWillMount() {
      const { applicationId, flags, permissions, user } = this.props

      const navigation = generate({
        applicationId,
        flags,
        permissions,
        slice: opts.slice,
        user,
      })

      this.setState({ navigation })
    }

    render() {
      const pathParts = getPathParts(window.location.pathname)
      return (
        <ComposedComponent
          navigation={this.state.navigation}
          pathParts={pathParts}
          {...this.props}
        />
      )
    }
  }

  return compose(FlagsHOC, connect(mapStateToProps))(Navigation)
}

function mapStateToProps(state) {
  const permissions = userApplications.getPermissions(state)

  return {
    applicationId: state.app.applicationId,
    permissions,
    user: get(state, 'user.data'),
  }
}
