import { getModule } from '@lighthouse/sdk'
import React, { useEffect } from 'react'
import { destroy, getFormNames } from 'redux-form'
import ReactTimeout from 'react-timeout'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'

import { AuthWrapper, Modal, ModalHeader } from '../components'
import { Flex } from 'components/common'
import Spinner from 'components/spinner'
import { useAccessToken } from 'components/useAccessToken'
import { useAuth } from 'components/useAuth'
import { useCache } from 'components/useCache'
import * as logger from 'utils/logger'
import { useTranslation } from 'react-i18next'

const authenticationModule = getModule('authentication')

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  ReactTimeout
)(LogoutHandler)

function LogoutHandler(props) {
  const {
    applicationId,
    clearAuthentication,
    destroyForms,
    formNames,
    history,
    unauthenticateRequest,
  } = props

  const { getAccessToken } = useAccessToken()
  const { logout } = useAuth()
  const { clearAllCache } = useCache()
  const { t } = useTranslation()

  useEffect(() => {
    handleLogout()
  }, [])

  async function handleLogout() {
    try {
      const accessToken = await getAccessToken()

      if (accessToken && applicationId) {
        await unauthenticateRequest({ applicationId })
      }

      console.debug('destroyForms...')
      destroyForms(formNames)

      // legacy authentication
      console.debug('clearAuthentication (legacy)...')
      await clearAuthentication()

      // modern authentication
      console.debug('auth logout...')
      const logoutResult = await logout()

      console.debug('Clearing cache...')
      clearAllCache()

      // NOTE The oidc logout can trigger it's own redirect, in which case we
      // want to skip the redirect we do as part of other logout flows
      if (!logoutResult || !logoutResult.redirected) {
        console.debug('redirect...')
        return history.push('/')
      }
    } catch (err) {
      logger.error(err)
      history.push('/')
    }
  }

  return (
    <AuthWrapper>
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <Modal>
          <ModalHeader>{t('logout.header.loggingOut')}</ModalHeader>
          <Spinner />
        </Modal>
      </Flex>
    </AuthWrapper>
  )
}

function mapStateToProps(state) {
  return {
    applicationId: state.app.applicationId,
    formNames: getFormNames()(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearAuthentication: () =>
      dispatch(authenticationModule.clearAuthentication()),
    destroyForms: names => dispatch(destroy(...names)),
    unauthenticateRequest: params =>
      dispatch(authenticationModule.unauthenticateRequest(params)),
  }
}
