export const FLOATING_BUTTON_ROTATION_DEFAULT = 0
export const FLOATING_BUTTON_ROTATION_MAX = 315
export const FLOATING_BUTTON_ZINDEX = 2
export const LIST_ITEM_ZINDEX = 1
export const MENU_ZINDEX = 1000

export default {
  FLOATING_BUTTON_ROTATION_DEFAULT,
  FLOATING_BUTTON_ROTATION_MAX,
  FLOATING_BUTTON_ZINDEX,
  LIST_ITEM_ZINDEX,
  MENU_ZINDEX,
}
