import {
  isRequired,
} from '../../validation'

export default {
  username: {
    label: 'Email or Username',
    name: 'username',
    placeholder: 'you@example.com',
    required: true,
    validate: [isRequired()],
  },
  password: {
    label: 'Password',
    name: 'password',
    placeholder: '•••••••••',
    required: true,
    validate: [isRequired()],
    type: 'password',
  },
}
