import { flatten } from 'lodash'

/**
 * A simple util for merging permission arrays into one flattened array. Simple
 * for now, but might be expanded on in future to handle more complex scenarios
 * @param {Array} permissionArrs - arrays of permission to merge
 */
export default function mergePermissions(...permissionArrs) {
  return flatten(permissionArrs)
}
