import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'

import Settings from './components/main'
import { ProtectedRoute } from '../../components/protectedRoute'

import ProfileRoute from './routes/profile'
import RolesRoute from './routes/roles'
import UsersRoute from './routes/users'

export default function SettingsRoute() {
  return (
    <Settings>
      <Switch>
        <Route component={ProfileRoute} path="/settings/profile" />
        <ProtectedRoute
          permissions={{ action: 'read', module: 'role' }}
          path="/settings/roles"
        >
          <RolesRoute />
        </ProtectedRoute>
        <ProtectedRoute
          permissions={{ action: 'read', module: 'applicationUser' }}
          path="/settings/users"
        >
          <UsersRoute />
        </ProtectedRoute>
        {/* NOTE default route */}
        <Redirect from="/settings" to="/settings/users" />
      </Switch>
    </Settings>
  )
}
