import { getModule } from '@lighthouse/sdk'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { DEFAULT_LANGUAGE, LANGUAGES } from 'config/constants'

const appModule = getModule('app')

export default function LanguageProvider(props) {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const stateLocale = useSelector(state => state.app.locale)

  const [locale, setLocale] = useState(stateLocale || navigator.language)

  const supportedLanguages = LANGUAGES.map(({ value }) => value)

  useEffect(() => {
    function changeLocale() {
      const localeWithRegion = locale.replace('_', '-')
      const [localeWithoutRegion] = locale.split('_')

      if (supportedLanguages.includes(localeWithRegion)) {
        setLocale(localeWithRegion)
      } else if (supportedLanguages.includes(localeWithoutRegion)) {
        setLocale(localeWithoutRegion)
      } else {
        setLocale(DEFAULT_LANGUAGE)
      }
    }
    changeLocale()
  }, [locale, supportedLanguages])

  useEffect(() => {
    if (!locale) return

    if (i18n.language === locale) return

    i18n.changeLanguage(locale)
    dispatch(appModule.setLocale(locale))
  }, [dispatch, i18n, locale])

  return props.children
}
