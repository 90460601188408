import React from 'react'
import PropTypes from 'prop-types'
import colors from 'config/theme/colors'
import { pure } from 'recompose'

import { Span } from 'components/common'

export default pure(Chevron)

function Chevron(props) {
  const {
    down,
    left,
    right,
    // up, // default
    size = 8,
    thick,
    thin,
  } = props

  const borderWidth = thin ? 1 : thick ? 3 : 2

  const borderStyle = `${borderWidth}px solid ${colors.gray.light}`

  const rotation = right ? 45 : down ? 135 : left ? 225 : 315

  const margin = right
    ? '0px 4px 0px 0px'
    : down
    ? '0px 0px 8px 0px'
    : left
    ? '0px 0px 0px 4px'
    : '8px 0px 0px 0px'

  return (
    <Span
      borderTop={borderStyle}
      borderRight={borderStyle}
      height={size}
      transform={`rotate(${rotation}deg)`}
      width={size}
      margin={margin}
    />
  )
}

Chevron.propTypes = {
  down: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  up: PropTypes.bool,
  thick: PropTypes.bool,
  thin: PropTypes.bool,
}
