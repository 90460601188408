/**
 * A module that check permissions for you.
 * @module ask
 */
import { find, includes, every, isArray } from 'lodash'
import logger from '../logger'

/**
 * Check if the given module and its filters have permissions for an action on `module`.
 * @param  {Array<String>}   permissions  An array of permission objects.
 * @param  {String}   action   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   options  Options for permission check.
 * @return Boolean
 * @see module:check-permissions~ask
 */
export default function checkModule(permissions, action, options) {
  const moduleResult = checkModulePermission(permissions, action, options)

  if (!moduleResult) return false

  const filterResult = checkFilterPermission(permissions, action, options)

  if (!filterResult) return false

  return true
}

/**
 * Check permission for its own module
 * @param  {Array<String>}   permissions  An array of permission objects.
 * @param  {String}   action   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   options  Options for permission check.
 * @param  {Function}   next Callback function for error handling.
 * @return None
 * @see checkModule
 * @see ForbiddenError
 */
function checkModulePermission(permissions, action, options) {
  const permission = findPermission(permissions, options.module)
  if (permission && includes(permission.access, action)) {
    options.strategy = permission.strategy || 'exclude'
    return true
  }


  logger(options.err || 'Access Denied')
  return false
}

/**
 * Check permission for its depended modules
 * @param  {Array<String>}   permissions An array of permission objects.
 * @param  {String}   action   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   options Options for permission check.
 * @param  {Function}   next Callback function for error handling.
 * @return None
 * @see checkModule
 * @see ForbiddenError
 */
function checkFilterPermission(permissions, action, options) {
  if (options.filters && !isArray(options.filters)) {
    logger('`filters` should be an array')
    return false
  }

  if (!options.filters || checkFilters(permissions, action, options)) {
    return true
  }

  logger(options.err || 'Access Denied')
  return false
}

/**
 * Check permission for its depended modules
 * @param  {Array<String>}   permissions  An array of permission objects.
 * @param  {String}   action   Action type including 'create', 'read', 'update' and 'delete'.
 * @param  {Object}   options  Options for permission check.
 * @return {Boolean}   Return true if every dependency has permission on `module`, otherwise return false.
 */
function checkFilters(permissions, action, options) {
  return every(options.filters, filter => (
    filter === options.module || hasReadPermission(filter, permissions)
  ))
}

/**
 * Check if a depended module has `read` permission on `module`
 * @param  {String}  filter   Name of a depended module.
 * @param  {Array<String>} permissions   An array of permission objects.
 * @return {Boolean}   Return true if the module has `read` permission on `module`, otherwise return false.
 */
function hasReadPermission(filter, permissions) {
  const permission = findPermission(permissions, filter)

  // Only check `read` permission for filters
  return permission && includes(permission.access, 'read')
}


/**
 * Find related permission object for a module
 * @param  {Array<String>} permissions   An array of permission objects.
 * @param  {String} name   Name of a module.
 * @return {Object}   Related permission object.
 */
function findPermission(permissions, name) {
  return find(permissions, {
    module: name,
    type: 'module'
  })
}
