/*
 * Signal Module
 */

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import {
  attempt,
  isError,
  get,
  filter,
  memoize,
} from 'lodash'
import {
  feature,
  point,
} from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import crud from '../../crud'
import {
  selectors as areaSelectors,
} from '../areas'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'
import buildActionCreators from './action-creators'

const resource = 'signals'

export const actions = crud.actions(resource)

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn(resource),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  applicationResourceUrlFn,
  applicationParamsFn,
  authorizationHeadersFn,
)

export const actionCreators = Object.assign(
  {},
  crudActionCreators,
  customActionCreators,
)

const crudSelectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

// NOTE These selectors will need more work once we bring in geohashing.
// Currently the datasets just aren't that big, so it's not such a worry, but
// once we get to a large number of areas/signals, it will be important

const insideArea = createSelector(
  areaSelectors.cache,
  crudSelectors.cache,
  (areaCache, signalCache) => memoize((areaId) => {
    const area = areaCache[areaId]
    const areaGeometry = get(area, 'entity.geometry')
    const polygon = attempt(feature, areaGeometry)

    if (isError(polygon)) {
      // TODO add some kind of error logging here
      return []
    }

    return filter(signalCache, (signal) => {
      const signalCoordinates = get(signal, 'entity.geometry.coordinates')
      const signalPoint = attempt(point, signalCoordinates)

      if (isError(signalPoint)) {
        return false
      }

      const hasAssignedFloors = hasFloors(signal)

      return hasAssignedFloors && booleanPointInPolygon(signalPoint, polygon)
    })
  }),
)

const outdoor = createSelector(
  crudSelectors.cache,
  signalCache => filter(signalCache, signal => !hasFloors(signal)),
)

export const selectors = Object.assign(
  {},
  crudSelectors,
  {
    insideArea,
    outdoor,
  },
)

function hasFloors(signal) {
  const { floorsRef = [] } = signal.entity
  return floorsRef.length !== 0
}
