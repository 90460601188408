import { find, map } from 'lodash'
import { compose, withProps } from 'recompose'
import React from 'react'

import ListHeader from 'components/list-next/components/header'
import NavList from 'modules/nav-list'
import { withTranslation } from 'react-i18next'

const LOCATION_ITEMS = [
  {
    label: 'All Locations',
    labelT: 'locationPanel.filter.allLocations',
    type: '',
  },
  {
    label: 'open',
    labelT: 'locationPanel.filter.open',
    type: 'open',
  },
  {
    label: 'closed',
    labelT: 'locationPanel.filter.closed',
    type: 'closed',
  },
  {
    label: 'Open / Active',
    labelT: 'locationPanel.filter.openActive',
    type: 'open-active',
  },
  {
    label: 'Open / Inactive',
    labelT: 'locationPanel.filter.openInactive',
    type: 'open-inactive',
  },
]

export default compose(
  withTranslation(),
  withProps(props => ({
    locationSelect: buildSelect(props),
  }))
)(FilterList)

function FilterList(props) {
  const { handleClose, locationSelect } = props

  // TODO refactor NavList for appropriate component
  return (
    <ListHeader handleClose={handleClose}>
      <NavList items={{ locationSelect }} />
    </ListHeader>
  )
}

function buildSelect(props) {
  const { selectedFilter, handleSetFilter, t } = props

  const current = find(LOCATION_ITEMS, ['type', selectedFilter])
  const options = map(LOCATION_ITEMS, ({ labelT, type }) => ({
    label: t(labelT),
    onClick: () => handleSetFilter(type),
  }))

  return {
    label: t(current.labelT),
    caret: true,
    dropdown: {
      align: 'full',
      options,
    },
  }
}
