import { Schema as ServiceHoursSchema } from '../service-hours'

export enum IntervalTypes {
  Occurrence = 'occurrence',
  Service = 'service',
}

export enum Unit {
  Millisecond = 'millisecond',
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'isoWeek',
  Month = 'month',
  Year = 'year',
}

export enum StrategyTypes {
  ExactDateOfMonth = 'exactDateOfMonth',
  LastDayOfMonth = 'lastDayOfMonth',
  LastWeekdayOfMonth = 'lastWeekdayOfMonth',
  NoRepeat = 'noRepeat',
  Stopwatch = 'stopwatch',
  Weekdays = 'weekdays',
  Window = 'window',
  XDayOfXWeekOfMonth = 'xDayOfxWeekOfMonth',
}

export interface GetNext {
  readonly end: number
  readonly isInitial?: boolean
  readonly options?: {
    date?: number
    dayOfWeek?: number
    duration: Period
    frequency: Period
    weekday?: number
    weekdays?: number[]
    weekOfMonth?: number
  }
  readonly start: number
  readonly timezone: string
}

export interface Interval {
  readonly [index: number]: number
}

export interface Period {
  readonly unit: Unit
  readonly value: number
}

export interface ScheduleIntervalsGenerator {
  readonly end: number
  readonly isInitial: boolean
  readonly strategy: Strategy
  readonly serviceHours: ServiceHoursSchema
  readonly start: number
}

export interface Strategy {
  readonly options: any
  readonly type: StrategyTypes
}
