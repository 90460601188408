import colors from 'config/theme/colors'
import typography from 'config/theme/typography'

const borderColor = colors.gray.lighter

export default {
  root: {
    position: 'relative',
  },
  menu: {
    borderColor,
    borderStyle: 'solid',
    borderWidth: '1px 0 0 0',
    boxShadow: '0 4px 14px rgba(0,0,0,0.2)',
    position: 'absolute',
    width: 250,
    zIndex: 1400,
  },
  left: {
    left: 0,
  },
  right: {
    right: 0,
  },
  full: {
    right: 0,
    left: 0,
    width: 'auto',
    maxHeight: 470,
    overflowY: 'scroll',
  },
  menuItem: {
    position: 'relative',
    backgroundColor: 'white',
    borderColor,
    borderStyle: 'solid',
    borderWidth: 1,
    borderTop: 0,
    color: colors.gray.normal,
    display: 'block',
    padding: '1em 1.5em',
    fontSize: '90%',
    overflow: 'hidden',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ':focus': {
      backgroundColor: colors.gray.lighter,
    },
  },
  menuItemWithIcon: {
    padding: '1em 1.5em 1em 50px',
  },
  icon: {
    position: 'absolute',
    top: 12,
    left: 20,
    fontSize: 20,
  },
  selectedIcon: {
    position: 'absolute',
    top: 12,
    right: 20,
    fontSize: 14,
    color: colors.blue.normal,
  },
  arrow: {
    left: '50%',
    marginLeft: -3,
    position: 'absolute',
    top: -6,
  },
  input: {
    background: '0 none',
    border: '0 none',
    outline: 'none',
    padding: '0 10px',
    fontFamily: typography.defaultFontFamily,
    fontSize: '12px',
    fontWeight: 500,
    color: colors.gray.normal,
    height: 40,
  },
}
