import PropTypes from 'prop-types'
import React from 'react'
import Radium from 'radium'
import { colors as brandColors } from 'config/theme/brand'

import { Block } from 'components/common'

export default function Toggle(props) {
  const { enabled = false } = props

  return (
    <Block
      backgroundColor={enabled ? '#5BD177' : '#CCC'}
      borderColor={enabled ? '#44AD5D' : '#999'}
      borderRadius={8}
      boxSizing="border-box"
      borderStyle="solid"
      borderWidth={1}
      height={16}
      position="relative"
      transition="0.2s"
      width={32}
    >
      <Block
        backgroundColor="#FFF"
        borderRadius={5}
        left={enabled ? 18 : 2}
        height={10}
        position="absolute"
        top={2}
        transition="0.2s"
        width={10}
      />
    </Block>
  )
}

Toggle.propTypes = {
  enabled: PropTypes.bool,
}
