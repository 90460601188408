import React, { useEffect } from 'react'
import { WithPermissions } from '@lighthouse/react-components'
import { compose, withHandlers, withState } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import AuthWrapper from '../components/auth-wrapper'
import SignIn from '../login/components/sign-in'
import { authenticationSuccessHandler } from '../lib'
import { withAuth } from 'components/useAuth'
import * as logger from 'utils/logger'

const authenticationModule = getModule('authentication')

const LoginCallback = props => {
  // If the user switches application, we don't let them stay on LoginCallback.
  const { applicationId, authorizeCallback, history, permissionError } = props

  if (!applicationId) {
    history.replace('/login')
  }
  useEffect(() => {
    const handleAuthentication = async () => {
      const { authenticate, authenticationSuccessHandler, handleError } = props

      try {
        const authUser = await authorizeCallback()
        const accessToken = authUser.access_token

        await authenticate({
          applicationId,
          accessToken,
        })

        // This shared function runs after login (either default or oidc) to check permissions and redirect.
        authenticationSuccessHandler()
      } catch (err) {
        logger.error('LoginCallbackError', {
          err: err.message,
          stack: err.stack,
        })

        // NOTE This error can happen when the OIDC flow gets in a tangled
        // state. The user can't do anything, apart from attempting login again,
        // so we redirect them to login instead of showing the error
        if (err.message === 'No matching state found in storage') {
          return history.push('/login')
        }

        handleError(err)
      }
    }

    handleAuthentication()
  }, [])

  return (
    <AuthWrapper>
      <SignIn
        handleSubmit={authenticationSuccessHandler}
        location={location}
        permissionError={permissionError}
      />
    </AuthWrapper>
  )
}

export default compose(
  WithPermissions,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withAuth,
  withState('permissionError', 'setPermissionError'),
  withHandlers({
    clearAuthentication: props => {
      const { revoke } = props

      // NOTE we need to revoke the auth tokens for OIDC, otherwise the user can
      // just manually navigate to a authenticated url and be logged in. The non-web
      // console permission is a faux front-end permission
      return () => revoke()
    },
  }),
  withHandlers({ authenticationSuccessHandler }),
  withTranslation()
)(LoginCallback)

function mapStateToProps(state) {
  const applicationId = state.app.applicationId

  return {
    applicationId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleError: error => {
      dispatch({
        type: 'lighthouse/authentication/AUTHENTICATE_ERROR',
        error,
      })
    },
    authenticate: payload =>
      dispatch(authenticationModule.authenticateRequest(payload)),
  }
}
