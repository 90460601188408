import Color from 'color'
import compose from 'recompose/compose'
import Radium from 'radium'
import React from 'react'
import withHandlers from 'recompose/withHandlers'

import { colors as brandColors } from 'config/theme/brand'
import colors from 'config/theme/colors'

const primaryColor = brandColors.primary
const warningColor = brandColors.warning

const DEFAULT_STYLES = {
  alignItems: 'center',
  backgroundColor: colors.gray.lighter,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  borderStyle: 'none',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderWidth: 0,
  color: colors.gray.normal,
  display: 'flex',
  fontSize: '12px',
  fontWeight: 500,
  height: '40px',
  justifyContent: 'center',
  lineHeight: '40px',
  outline: 'none',
  padding: '0 20px',
  textDecoration: 'none',
  transition: '0.2s',
  ':focus': {
    outline: 'none',
  },
}

const PRIMARY_STYLES = {
  backgroundColor: primaryColor,
  color: colors.white,
  ':hover': {
    backgroundColor: Color(primaryColor)
      .lightness(48)
      .hexString(),
  },
}

const WARNING_STYLES = {
  backgroundColor: warningColor,
  color: colors.white,
  ':hover': {
    backgroundColor: Color(warningColor)
      .lightness(48)
      .hexString(),
  },
}

const POSITIVE_STYLES = {
  backgroundColor: colors.green.normal,
  color: colors.white,
  ':hover': {
    backgroundColor: colors.green.light,
  },
}

const NEGATIVE_STYLES = {
  backgroundColor: colors.red.normal,
  color: colors.white,
  ':hover': {
    backgroundColor: colors.red.light,
  },
}

const SIMPLE_STYLES = {
  backgroundColor: colors.white,
  ':hover': {
    backgroundColor: colors.gray.lightest,
  },
}

const TRANSPARENT_STYLES = {
  backgroundColor: 'transparent',
  color: colors.gray.lighter,
  ':hover': {
    backgroundColor: 'transparent',
    color: colors.gray.light,
  },
}

const CIRCLE_STYLES = {
  borderBottomLeftRadius: 50,
  borderBottomRightRadius: 50,
  borderTopLeftRadius: 50,
  borderTopRightRadius: 50,
  height: 50,
  width: 50,
}

const DISABLED_STYLES = {
  cursor: 'not-allowed',
  opacity: 0.8,
}

const ENABLED_STYLES = {
  cursor: 'pointer',
  opacity: 1,
}

const SMALL_STYLES = {
  fontSize: 16,
}

const MEDIUM_STYLES = {
  fontSize: 20,
}

const LARGE_STYLES = {
  fontSize: 24,
}

export default compose(
  withHandlers({
    handleClick,
    handleMouseOver,
    handleMouseOut,
  }),
  Radium
)(ButtonNext)

function ButtonNext(props) {
  const {
    dataTestId,
    children,
    circle,
    disabled,
    handleClick,
    handleMouseOut,
    handleMouseOver,
    large,
    medium,
    negative,
    positive,
    primary,
    simple,
    small,
    transparent,
    type = 'button',
    warning,
    ...styleProps
  } = props

  const styles = [
    DEFAULT_STYLES,
    !disabled && ENABLED_STYLES,
    disabled && DISABLED_STYLES,
    circle && CIRCLE_STYLES,
    large && LARGE_STYLES,
    medium && MEDIUM_STYLES,
    negative && NEGATIVE_STYLES,
    positive && POSITIVE_STYLES,
    primary && PRIMARY_STYLES,
    simple && SIMPLE_STYLES,
    small && SMALL_STYLES,
    transparent && TRANSPARENT_STYLES,
    warning && WARNING_STYLES,
    { ...styleProps },
  ]

  return (
    <button
      data-testid={dataTestId}
      disabled={disabled}
      onClick={handleClick}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      type={type}
      style={styles}
    >
      {children}
    </button>
  )
}

function handleClick(props) {
  return event => {
    event.preventDefault()

    if (props.disabled || !props.onClick) {
      return
    }

    props.onClick()
  }
}

function handleMouseOut(props) {
  return event => {
    event.preventDefault()

    if (props.disabled || !props.onMouseOut) {
      return
    }

    props.onMouseOut()
  }
}

function handleMouseOver(props) {
  return event => {
    event.preventDefault()

    if (props.disabled || !props.onMouseOver) {
      return
    }

    props.onMouseOver()
  }
}
