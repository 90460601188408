/*
 * Message Messages Module
 */

import {
  filter,
  memoize,
} from 'lodash'

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../../app'

import buildActionCreators from './action-creators'
import crud from '../../../crud'
import hooks from './hooks'

const resource = 'messages'
const actionNamespace = 'messages/messages'
const statePath = `${resource}.messages`

const actions = crud.actions(actionNamespace)

// assign custom actions
Object.assign(actions, {})

hooks(actions)

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  authorizationHeadersFn,
  applicationParamsFn,
)

const actionCreators = Object.assign(
  {},
  crudActionCreators,
  customActionCreators,
)


export {
  actions,
  actionCreators,
}

const crudSelectors = crud.selectors(statePath)

const filterByRoom = createSelector(
  crudSelectors.cache,
  cache => memoize(roomId => (
    filter(cache, ['entity.room', roomId])
  ),
))

export const selectors = Object.assign(
  {},
  crudSelectors,
  {
    filterByRoom,
  },
)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
  // add custom reducers here
})

function baseUrlFn(params) {
  const baseUrl = applicationResourceUrlFn(resource)(params)
  return `${baseUrl}/rooms/${params.roomId}/messages`
}
