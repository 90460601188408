import { colors } from 'config/theme/brand'

export default {
  wrapper: {
    position: 'relative',
    userSelect: 'none',
  },
  anchor: {
    color: colors.text,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  active: {
    color: colors.primary,
    borderBottom: '1px solid',
  },
  underline: {
    textDecoration: 'underline',
  },
}
