import React from 'react'

import colors from 'config/theme/colors'
import { ListItem, Flex } from 'components/common'
import Icon from 'components/icon'
import Spinner from 'components/spinner'
import { LockIcon } from '../../../../../templates/components/LockIcon/LockIcon'

export default function LocationGroupListItem(props) {
  const {
    active,
    isLoading = false,
    hasError = { hasErrored: false, error: '' },
    item,
    onClick,
    locked = false,
    display,
    alignItems,
    disabled = false,
  } = props

  const { title, name } = item
  const { hasErrored, error } = hasError

  const label = name ? name : title
  const radiumStyles = {
    ':hover': {
      backgroundColor: disabled ? colors.gray.lightest : colors.blue.lightest,
    },
    backgroundColor: disabled ? colors.gray.lightest : 'transparent',
  }

  return (
    <ListItem
      onClick={disabled || active ? undefined : onClick}
      borderBottom={`1px solid ${colors.gray.lightest}`}
      cursor={disabled || active ? undefined : 'pointer'}
      display={display || 'flex'}
      fontSize={12}
      justifyContent="space-between"
      paddingTop={15}
      paddingRight={15}
      paddingBottom={hasErrored ? 25 : 15}
      paddingLeft={15}
      radiumStyles={radiumStyles}
      overflow="hidden"
      alignItems="flex-start"
    >
      {label}
      {active && !isLoading && !hasErrored && (
        <Icon float="right" name="check" />
      )}
      {isLoading && <Spinner type="dots" size="small" />}
      {locked && <LockIcon />}
      {!isLoading && hasErrored && (
        <Flex
          fontSize={10}
          color={colors.red.normal}
          float="left"
          position="absolute"
          paddingTop={15}
        >
          {error}
        </Flex>
      )}
    </ListItem>
  )
}
