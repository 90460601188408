import PropTypes from 'prop-types'
import React from 'react'

import DatetimeTimezone from 'components/datetime-timezone'

export default function DateTime(props) {
  const { cellData, columnData = {}, rowData } = props
  // NOTE: return null if data is still resolving.  Infinite list still
  // attempts to render the component even if the data hasn't resolved yet
  if (!rowData || !rowData.entity) return null

  const { createdAt, timestampDelta } = rowData.entity
  const { titleLabel } = columnData

  return cellData ? (
    <DatetimeTimezone
      createdAt={createdAt}
      datetime={cellData}
      diff={timestampDelta}
      titleLabel={titleLabel}
    />
  ) : null
}

DateTime.propTypes = {
  cellData: PropTypes.string,
  rowData: PropTypes.object,
}
