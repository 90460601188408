export default {
  defaultFontFamily:
    'Gotham SSm A, Gotham SSm B, Helvetica Neue, Helvetica, Arial, sans-serif',
  fontSizes: {
    xxsmall: 10,
    xsmall: 11,
    small: 12,
    normal: 14,
    large: 16,
    xLarge: 18,
    xxLarge: 20,
  },
}
