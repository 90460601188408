import React from 'react'

import { colors } from 'config/theme'
import { Cell } from 'components/table-basic'
import Icon from 'components/icon'

export default function ServiceHourCell({
  children,
  colSpan = 1,
  icon,
  isHeader,
  label,
  width,
}) {
  const customHeight = isHeader ? 'inherit' : 90
  const customPadding = isHeader ? 0 : 14
  const customPaddingLeft = isHeader ? 16 : 0

  return (
    <Cell
      colSpan={colSpan}
      borderBottom="none"
      borderTop={`1px solid ${colors.gray.lightest}`}
      height={customHeight}
      paddingBottom={customPadding}
      paddingLeft={customPaddingLeft}
      paddingRight={customPadding}
      paddingTop={customPadding}
      verticalAlign="middle"
      width={width}
    >
      {icon && <Icon name="trash" />}
      {label}
      {children}
    </Cell>
  )
}
