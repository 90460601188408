/*
 * App Module
 * Responsible for any global concerns relating to data
 */

import { attempt, filter, find, get, isError, map, memoize, omit } from 'lodash'

import { feature, featureCollection, point } from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import nearestpoint from '@turf/nearest-point'

import { createSelector } from 'reselect'
import { REHYDRATE } from 'redux-persist/constants'
import Immutable from 'seamless-immutable'
import cuid from 'cuid'

import { REQUEST } from '../../middleware/request'
import { parseState } from '../../helpers'

import {
  AUTHENTICATE_SUCCESS,
  AUTHENTICATION_UNSET,
  UNAUTHENTICATE_SUCCESS,
} from '../authentication'

export const GET_CONFIG_REQUEST = 'lighthouse/app/GET_CONFIG_REQUEST'
export const GET_CONFIG_SUCCESS = 'lighthouse/app/GET_CONFIG_SUCCESS'
export const GET_CONFIG_ERROR = 'lighthouse/app/GET_CONFIG_ERROR'
export const SET_APPLICATION = 'lighthouse/app/SET_APPLICATION'
export const SET_LOCATION = 'lighthouse/app/SET_LOCATION'
export const SET_ZONE = 'lighthouse/app/SET_ZONE'
export const SET_ENDPOINTS = 'lighthouse/app/SET_ENDPOINTS'
export const SET_REGION = 'lighthouse/app/SET_REGION'
export const SET_PROPERTIES = 'lighthouse/app/SET_PROPERTIES'
export const SET_TIMEZONE = 'lighthouse/app/SET_TIMEZONE'
export const SET_LOCALE = 'lighthouse/app/SET_LOCALE'
export const SET_POSITION = 'lighthouse/app/SET_POSITION'

export function reducer(state = Immutable({}), action = {}) {
  state = parseState(state)

  switch (action.type) {
    case REHYDRATE:
      // localstorage has initialised
      return state.set('initialised', true)

    case GET_CONFIG_SUCCESS:
      return state.setIn(
        ['applications', action.data.applicationId],
        omit(action.data, 'applicationId', 'authConfig')
      )

    case SET_APPLICATION:
      if (state.applicationId !== action.applicationId) {
        state = state.merge({
          locationId: null,
          zoneId: null,
        })
      }
      return state.set('applicationId', action.applicationId)

    case SET_LOCATION:
      return state.set('locationId', action.locationId)

    case SET_ZONE:
      return state.set('zoneId', action.zoneId)

    case AUTHENTICATE_SUCCESS:
      return state.set('sessionId', cuid())

    case AUTHENTICATION_UNSET:
    case UNAUTHENTICATE_SUCCESS:
      return state.without('sessionId', 'zoneId')

    case SET_ENDPOINTS:
      return state.set('endpoints', action.endpoints)

    case SET_REGION:
      return state.set('region', action.region)

    case SET_PROPERTIES:
      return state.update('properties', properties =>
        (properties ? properties.merge(action.properties) : action.properties),
      )

    case SET_TIMEZONE:
      return state.set('timezone', action.timezone)

    case SET_LOCALE:
      return state.set('locale', action.locale)

    case SET_POSITION:
      return state.set('position', action.geometry)

    default:
      return state
  }
}

const positionSelector = state => state.app.position
const areasCacheSelector = state => state.areas.cache

// NOTE this is copied from areas module. We can't import from there directly
// because it requires the crud module to be instantiated
const areasByTypeSelector = createSelector(areasCacheSelector, cache =>
  memoize(type => filter(cache, ['entity.type', type])),
)

export const getCurrentLocation = createSelector(
  positionSelector,
  areasByTypeSelector,
  (position, getAreasByType) => {
    if (!position) return null

    const positionFeature = attempt(feature, position)

    if (isError(positionFeature)) {
      return null
    }

    const locationAreas = getAreasByType('location')

    const areaResources = filter(locationAreas, (area) => {
      const areaGeometry = area.entity.geometry
      const areaFeature = attempt(feature, areaGeometry)

      if (isError(areaFeature)) {
        return false
      }

      return booleanPointInPolygon(positionFeature, areaFeature)
    })

    const isOverlappingLocations = areaResources.length > 1

    if (!isOverlappingLocations) {
      return areaResources[0] && areaResources[0].entity
    }

    const overlappingLocations = map(areaResources, 'id')
    const areaCenterPoints = featureCollection(
      map(areaResources, ({ entity }) =>
        point(entity.center.coordinates, { id: entity._id }),
      ),
    )

    const nearestPoint = nearestpoint(positionFeature, areaCenterPoints)
    const nearestAreaId = nearestPoint.properties.id
    const nearestResource = find(areaResources, ['id', nearestAreaId])

    return {
      ...nearestResource.entity,
      overlappingLocations,
    }
  },
)

export function getConfig(configHost, appSlug) {
  return (dispatch, getState) => {
    const endpoint = `${configHost}/applications/${appSlug}`
    return dispatch(getAppConfigRequest(endpoint))
  }
}

function getAppConfigRequest(endpoint) {
  return {
    [REQUEST]: {
      types: [GET_CONFIG_REQUEST, GET_CONFIG_SUCCESS, GET_CONFIG_ERROR],
      endpoint,
      requiresAuthorization: false,
    },
  }
}

export function setApplication(applicationId, role) {
  const action = {
    type: SET_APPLICATION,
    applicationId,
  }

  // attach role if provided
  if (role) action.role = role

  return action
}

export function setLocation(locationId) {
  return {
    type: SET_LOCATION,
    locationId,
  }
}

export function setZone(zoneId) {
  return {
    type: SET_ZONE,
    zoneId,
  }
}

/**
 * setEndpoints for api regions
 * @param {Object} endpoint - map of region codes and endpoints
 */
export function setEndpoints(endpoints) {
  return {
    type: SET_ENDPOINTS,
    endpoints,
  }
}

/**
 * setRegion for identifying endpoint to use
 * @param {String} region - au, us etc.
 */
export function setRegion(region) {
  return {
    type: SET_REGION,
    region,
  }
}

export function setProperties(properties) {
  return {
    type: SET_PROPERTIES,
    properties,
  }
}

export function setTimezone(timezone) {
  return {
    type: SET_TIMEZONE,
    timezone,
  }
}

export function setLocale(locale) {
  return {
    type: SET_LOCALE,
    locale,
  }
}

export function setPosition(geometry) {
  return {
    type: SET_POSITION,
    geometry,
  }
}

/**
 * Returns the current url for set region or empty string when missing required
 * config in state
 */
export function getRegionUrl(state) {
  const appState = state.app || {}
  const { region, endpoints } = appState
  return get(endpoints, region) || ''
}

/**
 * Generic params fn to use in modules. Will return params passed in, with
 * additional regionUrl and applicationId based on current state
 */
export function applicationParamsFn(state, params) {
  const { applicationId } = state.app
  return {
    regionUrl: getRegionUrl(state),
    applicationId,
    ...params,
  }
}

/**
 * Returns an application resource URL function, which in turn will accept
 * params to build the URL itself
 * @params {String} resourcePath - path to the resource
 */
export function applicationResourceUrlFn(resourcePath) {
  if (!resourcePath) {
    throw new Error('Missing required resourcePath')
  }

  return (params) => {
    const { regionUrl = '', applicationId } = params
    return `${regionUrl}/applications/${applicationId}/${resourcePath}`
  }
}

/**
 * Returns headers for requests
 */
export function authorizationHeadersFn(params, state) {
  const accessToken = get(state, 'authentication.accessToken')
  const authorization = accessToken

  const headers = {
    authorization,
  }

  const applicationId = get(state, 'app.applicationId')

  // NOTE Only attach application id header if it's in state. Unauthenticated
  // requests won't need this and it removes the confusion of an empty header
  // and potential cors issues
  if (applicationId) {
    headers['lio-application-id'] = applicationId
  }

  return headers
}
