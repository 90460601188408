import { get, omit } from 'lodash'
import { REQUEST } from '../../../middleware/request'

const PARAMS_BLACKLIST = ['applicationId', 'regionUrl']

export default function buildActionCreators(
  actions,
  applicationResourceUrlFn,
  paramsFn,
  headersFn
) {
  return {
    addLocationsToGroup,
    areasByArea,
    areasByLocation,
    deleteGroup,
    getGroups,
    getLocations,
    removeLocationsFromGroup,
    saveGroup,
    updateReferences,
  }

  function addLocationsToGroup(groupId, locations = [], params = {}) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/groups/${groupId}/locations`
      const headers = headersFn(params, state)
      const body = { locations }

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch({
        [REQUEST]: {
          method: 'PUT',
          types: [
            actions.ADD_LOCATIONS_TO_GROUP_REQUEST,
            actions.ADD_LOCATIONS_TO_GROUP_SUCCESS,
            actions.ADD_LOCATIONS_TO_GROUP_ERROR,
          ],
          endpoint,
          headers,
          body,
          query: params,
        },
      })
    }
  }

  function areasByArea(areaId, params = {}, listId) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/${areaId}/areas`
      const headers = headersFn(params, state)

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch(queryRequest(endpoint, headers, params, listId))
    }
  }

  function areasByLocation(locationId, params = {}) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/locations/${locationId}/areas`
      const headers = headersFn(params, state)

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch(queryRequest(endpoint, headers, params))
    }
  }

  function getGroups(listId, params = {}) {
    return (dispatch, getState) => {
      const state = getState()
      const filters = get(state, `areas.list.${listId}.filters`, {})
      const pagination = get(state, `areas.list.${listId}.pagination.opts`, {})

      params = Object.assign({}, filters, pagination, params)

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/groups`
      const headers = headersFn(params, state)

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch(queryRequest(endpoint, headers, params, listId))
    }
  }

  function getLocations(params, listId) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/locations`
      const headers = headersFn(params, state)

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch(queryRequest(endpoint, headers, params, listId))
    }
  }

  function removeLocationsFromGroup(groupId, locations = [], params = {}) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/groups/${groupId}/locations`
      const headers = headersFn(params, state)
      const body = { locations }

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch({
        [REQUEST]: {
          method: 'DELETE',
          types: [
            actions.REMOVE_LOCATIONS_FROM_GROUP_REQUEST,
            actions.REMOVE_LOCATIONS_FROM_GROUP_SUCCESS,
            actions.REMOVE_LOCATIONS_FROM_GROUP_ERROR,
          ],
          endpoint,
          headers,
          body,
          query: params,
        },
      })
    }
  }

  function updateReferences(
    areaId,
    reference,
    ids = [],
    shouldRemove = false,
    params = {}
  ) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const method = shouldRemove ? 'DELETE' : 'PUT'
      const endpoint = `${baseUrl}/${areaId}/${reference}`
      const headers = headersFn(params, state)
      const body = {
        ids,
      }

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch({
        [REQUEST]: {
          method,
          types: [
            actions.SAVE_REQUEST,
            actions.SAVE_SUCCESS,
            actions.SAVE_ERROR,
          ],
          endpoint,
          headers,
          body,
          query: params,
        },
      })
    }
  }

  function queryRequest(endpoint, headers, params, listId) {
    return {
      [REQUEST]: {
        types: [
          actions.QUERY_REQUEST,
          actions.QUERY_SUCCESS,
          actions.QUERY_ERROR,
          actions.SET_PAGINATION_LINKS,
        ],
        attachToActions: { listId },
        endpoint,
        headers,
        query: params,
      },
    }
  }

  function deleteGroup(groupId, params = {}) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/groups/${groupId}`
      const headers = headersFn(params, state)

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch({
        [REQUEST]: {
          method: 'DELETE',
          types: [
            actions.REMOVE_REQUEST,
            actions.REMOVE_SUCCESS,
            actions.REMOVE_ERROR,
          ],
          endpoint,
          headers,
          query: params,
        },
      })
    }
  }

  function saveGroup(params = {}, payload = {}) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const { id, name } = payload

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = id ? `${baseUrl}/groups/${id}` : `${baseUrl}/groups`
      const method = id ? 'PUT' : 'POST'
      const headers = headersFn(params, state)
      const body = {
        name,
      }

      params = omit(params, PARAMS_BLACKLIST)

      return dispatch({
        [REQUEST]: {
          method,
          types: [
            actions.SAVE_REQUEST,
            actions.SAVE_SUCCESS,
            actions.SAVE_ERROR,
          ],
          endpoint,
          headers,
          body,
          query: params,
        },
      })
    }
  }
}
