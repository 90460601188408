import { get } from 'lodash'

import {
  getAuditItemsData,
  getLocationReference,
  getReferenceDetails,
  getTimezoneDatetime,
} from '../'

export function getAuditEntryDetails(data) {
  const { entity, timezone } = data

  const {
    createdAt,
    gps,
    items,
    sequenceId,
    score,
    target,
    targetServiceLevel,
  } = entity

  const locationText = getLocationReference(data)
  const gpsText = get(gps, 'reverseGeocoded.label', 'Unknown Location')
  const groupedData = getAuditItemsData(items, data)
  const referenceDetails = getReferenceDetails(data)
  const scoreText =
    score && score.percentageResult !== undefined
      ? `${score.percentageResult}%`
      : ''
  const timezoneHourTime = getTimezoneDatetime({
    format: 'h:mm a',
    timestamp: createdAt,
    timezone,
  })
  const timezoneDatetime = getTimezoneDatetime({
    timestamp: createdAt,
    timezone,
  })

  return {
    gpsText,
    groupedData,
    locationText,
    referenceLabel: referenceDetails.label,
    referenceValue: referenceDetails.value,
    scoreText,
    sequenceId,
    target,
    targetServiceLevel,
    timezoneDatetime,
    timezoneHourTime,
  }
}
