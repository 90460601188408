import { isEmpty } from 'lodash'
import {
  buildTemplateContent,
  generateDefinition,
  getFormattedAddress,
  text,
  table,
} from '../helpers'

import { getTaskEntryDetails } from '../../helpers'

/**
 * buildTaskPdf
 *
 * @param {object} pdfOptions - the pdf options
 * @param {string} pdfOptions.fileTitle - pdf file title
 * @param {function} pdfOptions.footer - function executed to generate footer
 * @param {function} pdfOptions.header - function executed to generate header
 * @param {string} pdfOptions.logoUrl - pdf logo url
 * @param {array} pdfOptions.pageMargins - pdf page margins
 * @param {string} pdfOptions.pageOrientation - pdf page orientation
 * @param {string} pdfOptions.pageSize - pdf page size
 * @param {object} pdfOptions.styles - pdf styles
 * @param {object} pdfOptions.title - pdf title
 * @param {object} data - pdf data
 * @param {object} data.entity - task document
 * @param {object} data.locations - locations documents
 * @param {object} data.settings - settings properties
 * @param {string} data.settings.awsS3BaseUrl - aws S3 base url
 * @param {string} data.settings.cloudinaryBaseUrl - cloudinary base url
 * @param {string} data.timezone - timezone string
 * @param {object} data.users - application user documents
 * @param {object} data.zones - zone documents
 * @returns {Promise} returns pdfmake definition object
 */
export function buildTaskPdf(pdfOptions, data) {
  const { entity, timezone } = data

  const sequenceId = entity.sequenceId
  const timestamp = entity.createdAt
  const title = entity.title || 'Unknown'

  const fileTitle = `Task Report - ${title}`

  return generateContent(data).then(content =>
    generateDefinition({
      content,
      fileTitle,
      sequenceId,
      timestamp,
      timezone,
      type: 'Task',
      ...pdfOptions,
    })
  )
}

function generateContent(data) {
  const { entity } = data

  const { entry, gps = {}, title } = entity

  const entityDetails = getTaskEntryDetails(data)

  const {
    gpsText,
    locationText,
    referenceValue,
    timezoneHourTime,
  } = entityDetails

  const reverseGeocoded = gps.reverseGeocoded

  const subTitle = `${locationText ||
    gpsText} - ${timezoneHourTime} by ${referenceValue}`
  const headerSubTitle = text(subTitle, { style: 'subTitle' })
  const headerTitle = text(title, { style: 'title' })
  const address = !isEmpty(reverseGeocoded)
    ? getFormattedAddress(reverseGeocoded)
    : ''
  const headerAddress = text(address, { style: 'small' })

  const body = !isEmpty(reverseGeocoded)
    ? [[headerTitle], [headerSubTitle], [headerAddress]]
    : [[headerTitle], [headerSubTitle]]

  const titleTable = table({
    body,
    layout: 'noBorders',
    style: 'titleTable',
  })

  return buildTemplateContent(entry.formGroups, data).then(entry => [
    titleTable,
    ...entry,
  ])
}
