import {
  chain,
  get,
  isNil,
  omit,
  omitBy,
  toString,
} from 'lodash'

import cuid from 'cuid'
import * as states from '../../constants/states'
import { DuplicateRequestError } from '../../errors'
import { REQUEST } from '../../middleware/request'

// These are query params that usually don't belong in the query
// string. They might be used in the URL as params though
const queryStringBlacklist = [
  'applicationId',
  'appendToList',
  'regionUrl',
  'respectTimezone',
  'retryOpts',
  'timeout',
]

/**
 * Options:
 * - paramsFn: params transformer for working with other state
 * - baseUrlFn: function to build url which takes params as an
 *   argument
 * - statePath: string representation of the path to the relevant
 *   state. Useful for the context of getState()
 */

export default (actions, options = {}) => {
  const {
    paramsFn,
    baseUrlFn,
    headersFn,
    statePath,
  } = options

  if (!statePath) {
    throw new Error('Error generating action creators because of missing statePath option')
  }

  if (!baseUrlFn) {
    throw new Error('Error generating action creators because of missing baseUrlFn option')
  }

  if (!headersFn) {
    throw new Error('Error generating action creators because of missing headersFn option')
  }

  return {
    addToCache,
    addToList,
    clearListFilters,
    clearListItems,
    clearPagination,
    clearListSort,
    discardOptimistic,
    findById,
    invalidateItems,
    invalidateList,
    query,
    retry,
    remove,
    removeFromCache,
    removeFromList,
    request,
    save,
    setCurrent,
    setListFilters,
    setPaginationLinks,
    setPaginationOpts,
    setListSort,
    unsetCurrent,
  }

  function request(path, options = {}) {
    return (dispatch, getState) => {
      const {
        method = 'GET',
        payload,
      } = options

      let {
        params = {},
      } = options

      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = baseUrlFn(params, state)
      const endpoint = `${baseUrl}/${path}`
      const headers = headersFn(params, state)
      return dispatch(makeRequest(endpoint, headers, method, payload, params))
    }
  }

  function makeRequest(endpoint, headers, method, payload, params) {
    const queryParams = omit(params, queryStringBlacklist)
    return {
      [REQUEST]: {
        method,
        types: [actions.REQUEST, actions.REQUEST_SUCCESS, actions.REQUEST_ERROR],
        endpoint,
        headers,
        body: payload,
        query: queryParams,
      },
    }
  }

  function query(listId = 'default', params) {
    return (dispatch, getState) => {
      // get pagination opts from state here and attach as query
      // params. This is difficult because I don't know the state tree
      // at this point?? I could pass in a state path to the wrapper

      const state = getState()
      const stateInContext = get(state, statePath) || {}
      const filtersState = get(stateInContext, `list.${listId}.filters`) || {}
      const sortState = get(stateInContext, `list.${listId}.sort`) || []

      const sanitizedFilters = sanitizeObject(filtersState)
      const sanitizedSort = sanitizeSort(sortState)

      const pagination = get(stateInContext, `list.${listId}.pagination`) || {}

      // assign any pagination opts to params
      params = Object.assign({}, sanitizedFilters, sanitizedSort, pagination.opts, params)

      if (paramsFn) {
        params = paramsFn(getState(), params)
      }

      const endpoint = baseUrlFn(params, state)
      const headers = headersFn(params, state)
      return dispatch(queryRequest(endpoint, headers, listId, params))
    }
  }

  function queryRequest(endpoint, headers, listId, params) {
    const { appendToList = false, respectTimezone, retryOpts, timeout } = params
    const queryParams = omit(params, queryStringBlacklist)

    return {
      [REQUEST]: {
        types: [actions.QUERY_REQUEST, actions.QUERY_SUCCESS, actions.QUERY_ERROR, actions.SET_PAGINATION_LINKS],
        // we want the listId to be attached to all actions generated
        // by the api middleware so the actions have context
        attachToActions: { listId, appendToList },
        endpoint,
        headers,
        query: queryParams,
        respectTimezone,
        retryOpts,
        timeout,
      },
    }
  }

  function findById(id, params) {
    return (dispatch, getState) => {
      const state = getState()
      const cachedItem = get(state, [statePath, 'cache', id])

      if (cachedItem && cachedItem.optimistic) {
        return Promise.resolve(cachedItem)
      }

      if (paramsFn) {
        params = paramsFn(state, params)
      }
      const endpoint = `${baseUrlFn(params, state)}/${id}`
      const headers = headersFn(params, state)
      return dispatch(findByIdRequest(endpoint, headers, params, id))
    }
  }

  function findByIdRequest(endpoint, headers, params, id) {
    const action = {
      types: [actions.FIND_REQUEST, actions.FIND_SUCCESS, actions.FIND_ERROR],
      attachToActions: { id: toString(id) },
      endpoint,
      headers,
    }

    return {
      [REQUEST]: action,
    }
  }

  /**
   * Save a new or existing resource
   * @param {Object} params
   * @param {Object} payload
   * @param {String} id (optional) applicable if updating existing item
   *
   */
  function save(params = {}, payload, id) {
    return (dispatch, getState) => {
      const state = getState()
      const currentResource = get(state, `${statePath}.cache.${id}`, {})
      const { entity: currentEntity, state: resourceState } = currentResource
      const isResolving = resourceState === states.RESOLVING

      if (isResolving) {
        throw new DuplicateRequestError()
      }

      const hasPersisted = currentEntity && currentEntity._id
      const isUpdate = (id && hasPersisted)

      // NOTE when data has persisted (remotely) originalData is the current
      // entity. Otherwise, for optimistic requests we want to use the payload
      // passed in at this time. Optimistic items could have been rolled back,
      // in which case a new save should overwrite the current optimistic data
      // and should be used for rollback
      const originalData = hasPersisted
        ? currentEntity
        : payload

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      let endpoint = baseUrlFn(params, state)

      // NOTE Append id to endpoint for update (PUT) request as long as it's not
      // a new and optimistic request. An optimistic request for a new document
      // might have been rolled back into the cache, in which case we want to
      // retry at some point via a POST request
      if (isUpdate) {
        endpoint = `${endpoint}/${id}`
      }

      const parsedPayload = params.withProperties
        ? {
          ...payload,
          properties: get(state, 'app.properties')
        }
        : payload

      const headers = headersFn(params, state)
      return dispatch(saveRequest(endpoint, headers, params, parsedPayload, id, originalData))
    }
  }

  function saveRequest(endpoint, headers, params = {}, payload, id, originalData) {
    const attachToActions = { id }
    const isNew = !id
    const { optimistic } = params

    // optimistic data in the cache won't have an (entity) _id value
    const hasPersisted = originalData && originalData._id
    const isUpdate = (id && hasPersisted)
    const method = get(options, 'save.method') || (isUpdate ? 'PUT' : 'POST')

    // NOTE uid is used for optimistic resource ids and for passing to server so
    // that it can reject duplicate submissions. For previously attempted
    // optimistic resources the uid should resolve to the id value. This is to
    // ensure that requests which were marked as failed but eventually persisted
    // remotely (e.g. because of network instability) are sent with the same
    // original uid to prevent duplicates
    const uid = id || cuid()

    // NOTE Always omit the version number (__v) from persisted document
    // updates. We don't yet properly handle version conflicts, so this is a
    // temporary fix that can be removed once we do
    const parsedPayload = hasPersisted
      ? omit(payload, ['uid', '__v'])
      : {
        ...payload,
        uid,
      }

    if (optimistic) {
      // if this is a new item, then we need to track the data in cache so we
      // attach a uid to it that can be referenced. This is replaced by a
      // database uuid on successful response from the server.
      if (isNew) attachToActions.id = uid

      attachToActions.optimistic = true

      return {
        type: actions.SAVE_REQUEST,
        payload: parsedPayload,
        meta: {
          offline: {
            // the network action to execute:
            effect: { url: endpoint, method, headers },
            // action to dispatch when effect succeeds:
            commit: { type: actions.SAVE_SUCCESS, ...attachToActions },
            // action to dispatch when effect fails:
            rollback: { type: actions.SAVE_ROLLBACK, isNew, originalData, ...attachToActions },
          },
        },
        ...attachToActions,
        // NOTE we need to pass params to the action for reference in case of a
        // rollback & retry. We pass params such as the auditId to audit entries
        // so we can build up the correct endpoint to call, so we need these
        // again if we rollback and retry. The cache reducer handles persisting
        // these to the state. This does feel a bit weird though - a better
        // solution might be to use the payload for those params and skip
        // passing any params to a save request
        params,
      }
    }

    const action = {
      types: [actions.SAVE_REQUEST, actions.SAVE_SUCCESS, actions.SAVE_ERROR],
      attachToActions,
      method,
      body: parsedPayload,
      endpoint,
      headers,
    }

    return {
      [REQUEST]: action,
    }
  }

  function retry(id) {
    return (dispatch, getState) => {
      const state = getState()
      const currentResource = get(state, `${statePath}.cache.${id}`)

      if (!currentResource) {
        return Promise.reject(new Error(`Resource not found to retry. ID: ${id}`))
      }

      const { entity, params } = currentResource

      const newParams = {
        ...params,
        // Override optimistic flag for retry requests
        optimistic: false,
      }

      return dispatch(save(newParams, entity, id))
    }
  }


  function remove(id, params) {
    return (dispatch, getState) => {
      const state = getState()
      if (paramsFn) {
        params = paramsFn(state, params)
      }
      const endpoint = `${baseUrlFn(params, state)}/${id}`
      const headers = headersFn(params, state)
      return dispatch(removeRequest(endpoint, headers, params, id))
    }
  }

  function removeRequest(endpoint, headers, params, id) {
    const query = omit(params, queryStringBlacklist)
    const action = {
      types: [actions.REMOVE_REQUEST, actions.REMOVE_SUCCESS, actions.REMOVE_ERROR],
      attachToActions: { id: toString(id) },
      method: 'DELETE',
      endpoint,
      headers,
      query,
    }

    return {
      [REQUEST]: action,
    }
  }

  function addToCache(data) {
    return { type: actions.ADD_TO_CACHE, data }
  }

  function removeFromCache(id) {
    return { type: actions.REMOVE_FROM_CACHE, id }
  }

  function setCurrent(id) {
    return { type: actions.SET_CURRENT, id }
  }

  function unsetCurrent() {
    return { type: actions.UNSET_CURRENT }
  }

  function clearListItems(listIds) {
    return { type: actions.CLEAR_LIST_ITEMS, listIds }
  }

  function addToList(listId = 'default', ids) {
    return { type: actions.ADD_TO_LIST, listId, ids }
  }

  function removeFromList(listId = 'default', ids) {
    return { type: actions.REMOVE_FROM_LIST, listId, ids }
  }

  function invalidateList(listId = 'default') {
    return { type: actions.INVALIDATE_LIST, listId }
  }

  function invalidateItems(ids) {
    return { type: actions.INVALIDATE_ITEMS, ids }
  }

  function setListFilters(listId = 'default', filters) {
    return { type: actions.SET_LIST_FILTERS, listId, filters }
  }

  function clearListFilters(listId = 'default') {
    return { type: actions.CLEAR_LIST_FILTERS, listId }
  }

  function clearListSort(listId = 'default') {
    return { type: actions.CLEAR_LIST_SORT, listId }
  }

  function setListSort(listId = 'default', sort) {
    return { type: actions.SET_LIST_SORT, listId, sort }
  }

  function setPaginationOpts(listId = 'default', opts) {
    return { type: actions.SET_PAGINATION_OPTS, listId, opts }
  }

  function setPaginationLinks(listId = 'default', links, totalCount, lastKey) {
    return { type: actions.SET_PAGINATION_LINKS, listId, links, totalCount, lastKey }
  }

  function clearPagination(listId = 'default') {
    return { type: actions.CLEAR_PAGINATION, listId }
  }

  function discardOptimistic(id) {
    return { type: actions.DISCARD_OPTIMISTIC, id }
  }
}

/**
 * sanitizeSort
 * @param {Array} sortState [['field1', 'field2'], ['asc', 'desc']]
 * @returns {Array} ['field1', '-field2']
 */
function sanitizeSort(sortState) {
  const [
    sortBy,
    sortDirection,
  ] = sortState

  const sort = chain(sortBy)
    .map((param, index) => {
      const direction = sortDirection[index] || 'asc'
      const sortParam = direction.toLowerCase() === 'asc'
        ? param
        : `-${param}`

      return sortParam
    })
    .join(',')
    .value()

  return sanitizeObject({ sort })
}

function sanitizeObject(object) {
  return omitBy(object, value => (
    isNil(value) || value === ''
  ))
}
