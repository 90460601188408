import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import styles from './styles'

export default Radium(Loader)

function Loader(props) {
  const { time = 10000 } = props

  const timeSeconds = time / 1000
  const componentStyles = styles(timeSeconds)
  return (
    <div style={componentStyles.container}>
      <span style={componentStyles.progressBackground} />
      <span style={componentStyles.progress}>
        <span style={componentStyles.progressLeft}>
          <span style={componentStyles.progressBarLeft} />
        </span>
        <span style={componentStyles.progressRight}>
          <span style={componentStyles.progressBarRight} />
        </span>
      </span>
    </div>
  )
}

Loader.propTypes = {
  time: PropTypes.number,
}
