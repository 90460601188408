const countryCode = 'US'
const pageSize = 'LETTER'

const timezones = {
  Eastern: 'US/Eastern',
  Central: 'US/Central',
  Mountain: 'US/Mountain',
  Arizona: 'US/Arizona',
  Pacific: 'US/Pacific',
  'Puerto Rico': 'America/Puerto_Rico',
  Alaska: 'US/Alaska',
  Hawaii: 'US/Hawaii',
  Atlantic: 'Canada/Atlantic',
  Newfoundland: 'Canada/Newfoundland',
}

export default {
  countryCode,
  pageSize,
  timezones,
}
