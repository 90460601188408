import Radium from 'radium'
import React from 'react'

export default Radium(ListItem)

function ListItem(props) {
  const { children, onClick, radiumStyles, ...styleProps } = props

  const style = [styleProps, radiumStyles]

  return (
    <li onClick={onClick} style={style}>
      {children}
    </li>
  )
}
