import { find, groupBy, map, get, round } from 'lodash'

import { buildFetchUrl } from '../'

export function getAuditItemsData(items, data) {
  const {
    settings: { awsS3BaseUrl, cloudinaryBaseUrl } = {},
    entity: { groupScores } = {},
  } = data

  const groupedItems = groupBy(items, 'group.id')

  const auditData = {
    overallActualScore: 0,
    overallMaximumScore: 0,
  }

  auditData.items = map(groupedItems, (items, groupIndex) => {
    const key = groupIndex
    const skipped = !!items[0].group.skipped
    const title = items[0].group.label

    const groupData = {
      key,
      groupMaximumScore: 0,
      groupActualScore: 0,
      groupResultScore: 0,
      groupPercentageResultScore: 0,
      skipped,
      title,
    }

    const currentGroupScores = get(groupScores, key)

    if (currentGroupScores) {
      groupData.groupMaximumScore = round(currentGroupScores.max, 2)
      groupData.groupActualScore = round(currentGroupScores.actual, 2)
      groupData.groupResultScore = currentGroupScores.result
      groupData.groupPercentageResultScore = currentGroupScores.percentageResult
    }

    groupData.items = items.map((item, itemIndex) => {
      // NOTE: we handle scores of -1, 0 and 1
      // -1 scores are skipped from group max and actual score
      // 0 and 1 must be included in the final scores
      const hasScore = item.score !== null && item.score !== -1

      const assetCount = item.assets.length

      const assets = item.assets.map((asset, assetIndex) => {
        const assetUrl = buildFetchUrl(asset, {
          awsS3BaseUrl,
          cloudinaryBaseUrl,
          fit: true,
          height: 400,
          width: 600,
          quality: 50,
        })

        const link = `${awsS3BaseUrl}/${asset}`

        const thumbnailUrl = buildFetchUrl(asset, {
          awsS3BaseUrl,
          cloudinaryBaseUrl,
          width: 100,
          quality: 50,
        })

        const key = `${groupIndex}-item-asset-${assetIndex}`

        return {
          assetUrl,
          key,
          link,
          thumbnailUrl,
        }
      })

      const comments = item.comments
      const label = item.label
      const score = item.score

      const key = `${groupIndex}-item-${itemIndex}`
      const matchingScore = find(item.scores, { value: score })

      // NOTE: ensure we have a score otherwise fallback to - value
      const scoreLabel =
        hasScore && matchingScore && !skipped ? matchingScore.label : '-'
      const scoreWeight = hasScore && !skipped ? item.scoreWeight : '-'
      const weight = hasScore ? item.weight : '-'

      return {
        assetCount,
        assets,
        comments,
        key,
        label,
        score,
        scoreLabel,
        scoreWeight,
        weight,
      }
    })

    return groupData
  })

  return auditData
}
