import { chain } from 'lodash'

const DEFAULT_PROPERTIES = ['label', 'postalcode', 'postcode']

export function getGeometryGeocodedString(
  geometryReverseGeocoded,
  properties = DEFAULT_PROPERTIES
) {
  if (!geometryReverseGeocoded) return 'Unknown Location'

  return chain(geometryReverseGeocoded)
    .pick(properties)
    .values()
    .compact()
    .join(', ')
    .value()
}
