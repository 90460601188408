import {
  chain,
  each,
  get,
  isArray,
  isEmpty,
  map,
  noop,
  trim,
  toNumber,
} from 'lodash'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose'
import Promise from 'bluebird'
import React from 'react'

import { Block } from 'components/common'
import { InputGroup, InputLabel, InputSelect } from 'components/form'
import { withUsers } from 'components/useUsers'
import * as logger from 'utils/logger'

const applicationUserModule = getModule('applicationUsers')

const DEFAULT_LIST_ID = 'default'

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withUsers,
  withHandlers({ handleSearch, fetchMore, isRowLoaded }),
  withPropsOnChange(['users'], props => {
    const { users } = props

    const options = map(users, applicationUser => {
      if (!applicationUser) {
        return {
          label: 'Unknown User',
          search: '',
          value: '',
        }
      }

      return {
        label: applicationUser.entity.fullName,
        search: applicationUser.entity.search,
        value: applicationUser.entity.user._id,
      }
    })

    return {
      options,
    }
  }),
  withState('isLoading', 'setLoading', false),
  lifecycle({ componentDidMount })
)(UsersSelect)

function UsersSelect(props) {
  const {
    dataTestId = 'usersSelect',
    fetchMore,
    getUserFullName,
    handleSearch,
    input = {},
    isLoading,
    isRowLoaded,
    label,
    options,
    readOnly,
    refreshing,
    required,
    totalCount: totalRowCount,
    value = [],
    showRemoveItems,
    showSelectAll,
    selectAllLimit,
    type,
  } = props

  const isOptional = required !== null && !required && !readOnly

  // NOTE if this component is wrapped by a redux form field component
  // value will be passed within input.value otherwise via value prop
  const inputValue = input.value || value
  const userIds = isArray(inputValue) ? inputValue : [inputValue]

  const listRowCount = refreshing ? options.length + 100 : options.length

  const loadMoreRows = refreshing ? noop : fetchMore
  if (readOnly) {
    const assignedUsers = chain(userIds)
      .map(id => getUserFullName(id))
      .compact()
      .join(', ')
      .value()

    const assignedUsersText = isLoading
      ? 'Loading Users'
      : isEmpty(assignedUsers)
      ? 'No Users'
      : assignedUsers

    return (
      <InputGroup small>
        <InputLabel label={label} readOnly small />
        <Block padding={10}>{assignedUsersText}</Block>
        {isOptional && <span style={[styles.help]}>{t('labelOptional')}</span>}
      </InputGroup>
    )
  }

  return (
    <InputSelect
      dataTestId={dataTestId}
      infiniteScrolling
      isRowLoaded={isRowLoaded}
      listRowCount={listRowCount}
      loadMoreRows={loadMoreRows}
      onSearch={handleSearch}
      options={options}
      refreshing={refreshing}
      totalRowCount={totalRowCount}
      type={type}
      showRemoveItems={showRemoveItems}
      showSelectAll={showSelectAll}
      selectAllLimit={selectAllLimit}
      {...props}
    />
  )
}

function componentDidMount() {
  const {
    addToList,
    fetchApplicationUsers,
    setLoading,
    fetchUser,
    input = {},
    value = [],
  } = this.props

  setLoading(true)

  const inputValue = input.value || value
  const userIds = isArray(inputValue) ? inputValue : [inputValue]

  const assignedUsers = Promise.map(userIds, id =>
    fetchUser({ userId: id })
  ).then(users =>
    each(users, ({ _id }) => {
      if (_id) addToList([_id])
    })
  )

  return Promise.all([assignedUsers, fetchApplicationUsers()])
    .catch(err => logger.error(`User select error :: ${err.message}`, err))
    .finally(() => setLoading(false))
}

function handleSearch(props) {
  const { fetchApplicationUsers, clearList } = props

  return value => {
    if (value) clearList()

    const queryParams = value ? { search: `~${trim(value)}` } : {}

    return fetchApplicationUsers(queryParams).then(({ data }) =>
      map(data, applicationUser => ({
        label: applicationUser.fullName,
        search: applicationUser.search,
        value: applicationUser.user._id,
      }))
    )
  }
}

function fetchMore(props) {
  const { fetchApplicationUsers, list, paginate } = props

  return searchValue => {
    const nextPage = get(list, 'pagination.links.next.page', '1')
    const pageOffset = toNumber(nextPage)
    paginate({ page: pageOffset })

    if (!searchValue) return fetchApplicationUsers({ page: pageOffset })

    return fetchApplicationUsers({
      page: pageOffset,
      search: `~${trim(searchValue)}`,
    })
  }
}

function isRowLoaded({ users }) {
  return ({ index }) => !!users[index]
}

function mapDispatchToProps(dispatch) {
  const listId = DEFAULT_LIST_ID

  return {
    addToList: id => dispatch(applicationUserModule.addToList('default', id)),
    clearList: () => dispatch(applicationUserModule.clearListItems([listId])),
    paginate: opts =>
      dispatch(applicationUserModule.setPaginationOpts(listId, opts)),
    fetchApplicationUsers: params =>
      dispatch(
        applicationUserModule.query(listId, {
          ...params,
          perPage: 500,
          sort: 'firstName',
          appendToList: true,
        })
      ),
  }
}

function mapStateToProps(state) {
  const listId = DEFAULT_LIST_ID
  const applicationUserSelectors = applicationUserModule.selectors(state)(
    listId
  )
  const getUserFullName = applicationUserSelectors.getUserFullName
  const list = state['applicationUsers'].list[listId]
  const { items = {} } = list
  const users = applicationUserSelectors.list('default')
  const totalCount = items ? items.length : 0

  return {
    users,
    refreshing: applicationUserSelectors.state === 'resolving',
    getUserFullName,
    list,
    totalCount,
  }
}
