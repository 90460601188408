/*
 * Visit Module
 */

import { combineReducers } from 'redux'
import crud from '../../crud'
import hooks from './hooks'
import buildActionCreators from './action-creators'
import customReducers from './reducers'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

const resource = 'visits'

const actions = crud.actions(resource)

Object.assign(actions, {
  AGGREGATE_REQUEST: `lighthouse/${resource}/AGGREGATE_REQUEST`,
  AGGREGATE_SUCCESS: `lighthouse/${resource}/AGGREGATE_SUCCESS`,
  AGGREGATE_ERROR: `lighthouse/${resource}/AGGREGATE_ERROR`,
})

export {
  actions,
}

hooks(actions)

const baseUrlFn = applicationResourceUrlFn(resource)
const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  authorizationHeadersFn,
  applicationParamsFn,
)


export const actionCreators = {
  ...crudActionCreators,
  ...customActionCreators,
}

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
  ...customReducers,
})
