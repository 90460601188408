import { debounce } from 'lodash'
import React from 'react'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import withState from 'recompose/withState'
import Radium from 'radium'

import Icon from 'components/icon'
import Visibility from 'components/common/visibility'
import styles from './styles'

const DEFAULT_DEBOUNCE_TIMEOUT = 750

export default compose(
  withProps(props => ({
    onChange: debounce(props.onChange, DEFAULT_DEBOUNCE_TIMEOUT),
  })),
  withState('searchText', 'setSearchText', props => props.search),
  withHandlers({ onChangeHandler }),
  withHandlers({ onClearHandler }),
  Radium
)(Search)

function Search(props) {
  const {
    dataTestId,
    onChangeHandler,
    onClearHandler,
    placeholder,
    noBorder,
    searchText = '',
  } = props

  const containerStyles = [styles.root, noBorder && styles.noBorder]
  return (
    <div style={containerStyles}>
      <Icon theme={styles.icon} name="search" />
      <input
        data-testid={dataTestId}
        onChange={onChangeHandler}
        placeholder={placeholder}
        style={styles.input}
        type="text"
        value={searchText}
      />
      <Visibility visible={searchText}>
        <div onClick={onClearHandler} style={styles.clearBtn}>
          &times;
        </div>
      </Visibility>
    </div>
  )
}

function onChangeHandler(props) {
  const { onChange, setSearchText } = props

  return event => {
    const text = event.target.value

    setSearchText(text)
    onChange(text)
  }
}

function onClearHandler(props) {
  const { onChange, setSearchText } = props

  return () => {
    setSearchText('')
    onChange('')
  }
}
