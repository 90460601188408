import { chain, toLower } from 'lodash'
import { List } from 'react-virtualized'
import { compose, withHandlers, withProps, withState } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { colors } from 'config/theme'
import { Block, Flex } from 'components/common'
import DropdownItem from '../dropdown-item'
import { useTranslation } from 'react-i18next'

const MAX_HEIGHT = 288
const ROW_HEIGHT = 42

const styles = {
  search: {
    backgroundColor: colors.gray.lightest,
    border: 0,
    fontSize: 12,
    outline: 0,
    padding: 12,
    width: 156,
  },
}

export default compose(
  withState('scrollIndex', 'setScrollIndex', 0),
  withState('searchValue', 'setSearchValue', ''),
  withProps(sortItems),
  withHandlers({
    renderItem,
  })
)(Dropdown)

function Dropdown(props) {
  const {
    enableSearch,
    items = [],
    renderItem,
    searchValue,
    setSearchValue,
    ...wrapperStyles
  } = props

  const { t } = useTranslation()

  const totalHeight = items.length * ROW_HEIGHT
  const height = totalHeight > MAX_HEIGHT ? MAX_HEIGHT : totalHeight

  return (
    <Flex
      backgroundColor={colors.white}
      borderColor={colors.gray.lighter}
      borderStyle="solid"
      borderWidth={1}
      boxShadow="rgba(0, 0, 0, 0.2) 0px 4px 14px"
      flexDirection="column"
      position="absolute"
      width={180}
      zIndex={600}
      {...wrapperStyles}
    >
      {enableSearch && (
        <Block>
          <input
            autoFocus
            onChange={e => setSearchValue(e.target.value)}
            placeholder={t('placeholder.search')}
            style={styles.search}
            value={searchValue}
          />
        </Block>
      )}
      <List
        height={height}
        rowCount={items.length}
        rowHeight={ROW_HEIGHT}
        rowRenderer={renderItem}
        style={{ outline: 'none' }}
        width={180}
      />
    </Flex>
  )
}

Dropdown.propTypes = {
  enableSearch: PropTypes.bool,
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func.isRequired,
}

function renderItem({ items }) {
  return ({ index, key, style }) => {
    const { onClick, text } = items[index]

    return <DropdownItem key={key} onClick={onClick} text={text} {...style} />
  }
}

function sortItems({ items, searchValue }) {
  const sortedItems = chain(items)
    .filter(({ text }) => toLower(text).includes(toLower(searchValue)))
    .orderBy(['text'], ['asc'])
    .value()

  return {
    items: sortedItems,
  }
}
