import { find, filter, get, map } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ListHeader from 'components/list-next/components/header'
import NavList from 'modules/nav-list'
import { withTranslation } from 'react-i18next'

const activityModule = getModule('activity')

const ACTIVITY_ITEMS = [
  {
    label: 'All Activity',
    labelT: 'activityPanel.filter.allActivity',
    type: undefined,
  },
  {
    label: 'audits',
    labelT: 'activityPanel.filter.audits',
    type: 'auditentries',
    icon: 'pencil-circle',
  },
  {
    label: 'tasks',
    labelT: 'activityPanel.filter.tasks',
    type: 'taskentries',
    icon: 'check-box',
  },
  {
    label: 'issues',
    labelT: 'activityPanel.filter.issues',
    type: 'issues',
    icon: 'alert',
  },
  {
    label: 'exceptions',
    labelT: 'activityPanel.filter.exceptions',
    type: 'loopexceptions',
    icon: 'circle-cross',
  },
  {
    label: 'Shifts',
    labelT: 'activityPanel.filter.shifts',
    type: 'shifts',
    icon: 'id-card',
  },
]

class FilterList extends Component {
  render() {
    const { handleClose } = this.props

    const activitySelect = this.buildActivitySelect()
    const navItems = { activitySelect }

    return (
      <ListHeader handleClose={handleClose}>
        <NavList items={navItems} />
      </ListHeader>
    )
  }

  buildActivitySelect() {
    const { filterType, setFilters, t } = this.props

    const items = this.getActivitiesWithPermissions()
    const labelT = get(find(items, ['type', filterType]), 'labelT')

    const buildActivity = activity => ({
      label: t(activity.labelT),
      icon: activity.icon,
      onClick: () => setFilters({ type: activity.type }),
    })

    const options = map(items, buildActivity)

    return {
      label: t(labelT),
      caret: true,
      dropdown: {
        align: 'full',
        options,
      },
    }
  }

  getActivitiesWithPermissions() {
    const { hasModulePermission } = this.props

    return filter(ACTIVITY_ITEMS, item => {
      const type = item.type || 'activity'
      const permissionModule = activityModule.getPermissionMapping(type)

      return !!hasModulePermission(permissionModule, 'read')
    })
  }
}

export default withTranslation()(FilterList)

FilterList.propTypes = {
  filterType: PropTypes.string,
  handleClose: PropTypes.func,
  hasModulePermission: PropTypes.func,
  setFilters: PropTypes.func,
}
