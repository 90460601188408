import React from 'react'
import { assign } from 'lodash'

import Form from './form'

export default function AreaDetails(props) {
  const {
    area,
    areaId,
    areaEntries,
    areaFloors,
    areaTasks,
    areaAudits,
    areaIssues,
    groupsAssignedToLocation,
    isBuilding,
    isAddingFloorMode,
    isEditing,
    isEditingFloorMode,
    updateAreaView,
  } = props

  const initialValues = assign({ name: 'Unknown Area' }, area.entity)

  return (
    <div>
      <Form
        area={area}
        areaEntries={areaEntries}
        areaFloors={areaFloors}
        areaId={areaId}
        areaTasks={areaTasks}
        areaAudits={areaAudits}
        areaIssues={areaIssues}
        groupsAssignedToLocation={groupsAssignedToLocation}
        destroyOnUnmount={false}
        enableReinitialize
        form={'area-details'}
        initialValues={initialValues}
        isAddingFloorMode={isAddingFloorMode}
        isBuilding={isBuilding}
        isEditing={isEditing}
        isEditingFloorMode={isEditingFloorMode}
        updateAreaView={updateAreaView}
      />
    </div>
  )
}
