import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getModule, validation } from '@lighthouse/sdk'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Field, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form'

import { get, filter } from 'lodash'

import colors from 'config/theme/colors'

import Button from 'components/button'

import { BasicForm, InputText } from 'components/form'

import { Crud } from 'components/controls'

import Icon from 'components/icon'

import { Warning } from 'components/prompts'

import { Block, Flex } from 'components/common'

import {
  getErrorCount,
  handleApiError,
  handleDeleteResponse,
  handleFormError,
  handleSaveResponse,
} from 'components/form/helpers'

import Alert from 'components/alert'
import ButtonGroup from 'components/button-group'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

const areas = getModule('areas')
const contentEntries = getModule('content', 'entries')

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

class EditContentEntryForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    toggleEditMode: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.delete = this.delete.bind(this)
    this.toggleSyncMode = this.toggleSyncMode.bind(this)
  }

  render() {
    const {
      children,
      dirty,
      entryId,
      error,
      errorCount,
      handleSubmit,
      invalid,
      isEditingEntry,
      isTemplateInSync,
      readOnly,
      relations,
      reset,
      small,
      submitFailed,
      submitting,
      toggleEditMode,
      t,
    } = this.props

    const relationsCount = relations.length
    const relationText =
      relationsCount > 1 ? `${relationsCount} other entries` : '1 other entry'
    const fieldsBottom = relationsCount > 0 ? 121 : 61
    const isDisabled = invalid || submitting

    return (
      <Block>
        <BasicForm noValidate>
          <Block
            bottom={fieldsBottom}
            fontSize={12}
            overflow="auto"
            paddingTop={20}
            paddingRight={15}
            paddingBottom={20}
            paddingLeft={15}
            position="absolute"
            top={156}
            width={420}
          >
            {error && submitFailed && <Alert type="error" messages={[error]} />}
            <Field
              required
              component={InputText}
              name="name"
              label="Entry Name"
              readOnly={readOnly}
              small={small}
              validate={[isRequired]}
            />
            {children}
          </Block>
          {relationsCount && (
            <Flex
              alignItems="center"
              backgroundColor={colors.gray.white}
              borderTop={`1px solid ${colors.gray.lightest}`}
              bottom={60}
              height={40}
              paddingLeft={15}
              paddingRight={15}
              position="absolute"
              width={420}
            >
              <Icon
                color={colors.gray.light}
                fontSize={22}
                fontWeight={600}
                name="link"
                paddingRight={10}
              />
              <p style={{ fontSize: 12, margin: 0 }}>
                This entry is referenced by {relationText}
              </p>
            </Flex>
          )}
          <Block
            borderTop={`1px solid ${colors.gray.lightest}`}
            bottom={0}
            height={60}
            overflow="hidden"
            paddingTop={0}
            paddingRight={15}
            paddingBottom={0}
            paddingLeft={15}
            position="absolute"
            width={420}
          >
            <ButtonGroup align="left">
              {isTemplateInSync ? (
                <Crud
                  dirty={dirty}
                  errorCount={errorCount}
                  handleCancel={toggleEditMode}
                  handleDelete={this.delete}
                  handleEdit={toggleEditMode}
                  handleSave={handleSubmit(this.saveForm.bind(this))}
                  id={entryId}
                  invalid={invalid}
                  isEditing={isEditingEntry}
                  name={name}
                  small={small}
                  submitting={submitting}
                  reset={reset}
                />
              ) : (
                <Flex flex={1}>
                  <Warning text="Entry out of sync with template" />
                  <Button
                    disabled={isDisabled}
                    key="buttonSync"
                    loading={submitting}
                    onClick={this.toggleSyncMode}
                    theme="positive"
                    type="submit"
                    marginRight={0}
                  >
                    {submitting ? t('button.syncing') : t('button.update')}
                  </Button>
                </Flex>
              )}
            </ButtonGroup>
          </Block>
        </BasicForm>
      </Block>
    )
  }

  delete() {
    const { area, deleteEntry, entryId, saveArea, updateAreaView } = this.props

    const areaEntity = area.entity
    const areaId = areaEntity._id

    const entries = filter(areaEntity.entries, value => entryId !== value)

    updateAreaView({ isLoading: true })

    return saveArea(areaId, { entries })
      .then(handleSaveResponse('area'))
      .then(() => deleteEntry(entryId).then(handleDeleteResponse('entry')))
      .catch(handleApiError)
      .finally(() => updateAreaView({ isLoading: false }))
  }

  saveForm(formValues) {
    const {
      entryId,
      saveEntry,
      templateId,
      updateAreaView,
      userId,
    } = this.props

    const entryPayload = {
      entry: formValues,
      name: formValues.name,
      template: templateId,
      user: userId,
    }

    updateAreaView({ isLoading: true })

    return saveEntry(entryId, entryPayload)
      .then(handleSaveResponse('entry'))
      .catch(handleFormError)
      .finally(() => updateAreaView({ isLoading: false }))
  }

  toggleSyncMode() {
    const { updateAreaView } = this.props

    updateAreaView({ isSyncEntryMode: true })
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  reduxForm()
)(EditContentEntryForm)

function mapStateToProps(state, props) {
  const { form } = props

  const errors = getFormSyncErrors(form)(state) || {}
  const errorCount = getErrorCount(errors)
  const values = getFormValues(form)(state) || {}
  const userId = get(state, 'user.data._id')

  return {
    errorCount,
    name: values.name,
    userId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveArea: (id, payload) => dispatch(areas.save({}, payload, id)),
    saveEntry: (id, payload) => dispatch(contentEntries.save({}, payload, id)),
    deleteEntry: id => dispatch(contentEntries.remove(id)),
  }
}
