export default {
  root: {
    maxWidth: '720px',
    padding: 0,
    border: '0 none',
  },

  twoWrapper: {
    marginLeft: '-20px',
    maxWidth: '740px',
  },

  two: {
    float: 'left',
    width: '50%',
    paddingLeft: '20px',
    boxSizing: 'border-box',
  },
}
