import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/button'
import Icon from 'components/icon'

import styles from './styles'
import { useTranslation } from 'react-i18next'

function FilterMenuButton(props) {
  const { onClick } = props
  const { t } = useTranslation()

  return (
    <Button
      dataTestId={'filter-button'}
      style={styles.button}
      theme="square"
      onClick={onClick}
    >
      <Icon theme={styles.icon} name="filter" />
      <span>{t('button.filter')}</span>
    </Button>
  )
}

FilterMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default FilterMenuButton
