import colors from 'config/theme/colors'

export default {
  root: {
    display: 'flex',
    marginRight: 10,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  largeMessage: {
    backgroundColor: colors.gray.lightest,
    borderColor: colors.gray.lighter,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    boxSizing: 'border-box',
    color: colors.gray.dark,
    fontSize: 12,
    lineHeight: '28px',
    marginBottom: 10,
    paddingLeft: 10,
    overflow: 'hidden',
    width: '100%',
  },
  message: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '28px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: colors.gray.lighter,
    color: colors.gray.dark,
    backgroundColor: colors.gray.lightest,
    marginLeft: '10px',
    padding: '6px 20px 5px 20px',
  },
}
