import { get } from 'lodash'
import { getErrorDataFn, getSuccessDataFn } from '../../tracking/helpers'

const category = 'Templates (Forms)'

export const saveErrorDataFn = getErrorDataFn({ category, action: 'Save Error' })

export const saveSuccessDataFn = getSuccessDataFn({ category, action: 'Save Success', label: labelFn })

function labelFn(state, action) {
  const name = get(action, 'data.name')
  const type = get(action, 'data.type')

  return `${name} - (${type})`
}
