import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'
import { compose } from 'recompose'
import styles from './styles'
import Tooltip from 'components/tooltip'
import onClickOutside from 'react-onclickoutside'

class Anchor extends Component {
  static propTypes = {
    active: PropTypes.bool,
    underline: PropTypes.bool,
    tooltip: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.props.disableOnClickOutside()
  }

  handleClick() {
    this.props.enableOnClickOutside()
    this.setState({
      tooltipVisible: true,
    })
  }

  handleClickOutside() {
    this.props.disableOnClickOutside()
    this.setState({
      tooltipVisible: false,
    })
  }

  render() {
    const {
      active,
      style,
      underline,
      tooltip,
      children,
      anchorDirection = 'bottom',
    } = this.props

    const baseStyles = [
      styles.anchor,
      active && styles.active,
      underline && styles.underline,
    ]

    const anchorStyles = baseStyles.concat(style)

    return (
      <div style={styles.wrapper}>
        <a style={anchorStyles} onClick={tooltip && this.handleClick}>
          {children}
          {this.state.tooltipVisible && (
            <Tooltip
              title={tooltip[0]}
              message={tooltip[1]}
              anchorDirection={anchorDirection}
            />
          )}
        </a>
      </div>
    )
  }
}

export default compose(onClickOutside, Radium)(Anchor)
