import React from 'react'
import ButtonPrompt from 'components/button-prompt'
import { useTranslation } from 'react-i18next'

export default function ErrorPrompt(props) {
  const { errorCount, id, error = '' } = props

  const { t } = useTranslation()

  const hasId = !!id
  const errorPrompt = getErrorPromptText(errorCount, hasId, t, error)

  return <ButtonPrompt key="buttonErrorPrompt" text={errorPrompt} />
}

export function getErrorPromptText(errorCount, hasId, t, error) {
  if (errorCount === 0 && error !== '') return error

  if (errorCount === 0) return t('prompts.error.applicationError')
  const hasOneError = errorCount === 1

  if (hasId) {
    if (!errorCount) return t('prompts.error.pleaseCompleteAllFields')

    return hasOneError
      ? t('prompts.error.validation', { count: errorCount })
      : t('prompts.error.validation_plural', { count: errorCount })
  }

  if (!errorCount) return t('prompts.error.pleaseCompleteAllFields')

  return hasOneError
    ? t('prompts.error.fieldToComplete', { count: errorCount })
    : t('prompts.error.fieldToComplete_plural', { count: errorCount })
}
