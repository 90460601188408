import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'
import Caret from 'components/caret'
import Spinner from 'components/spinner'

export default SelectDropdownStatus

function SelectDropdownStatus({ isLoading, isOpen }) {
  return (
    <Flex alignItems="center" height={0} paddingRight={12}>
      {isLoading ? (
        <Spinner size="small" type="dots" />
      ) : (
        <Caret dataTestId="status" down={!isOpen} scale={1.25} up={isOpen} />
      )}
    </Flex>
  )
}

SelectDropdownStatus.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
}
