import { combineReducers } from 'redux'
import * as roomsModule from './rooms'
import * as speakersModule from './speakers'
import * as messagesModule from './messages'

export const reducer = combineReducers({
  rooms: roomsModule.reducer,
  speakers: speakersModule.reducer,
  messages: messagesModule.reducer,
})

// re-export the modules so we can access them easily
export const rooms = roomsModule
export const speakers = speakersModule
export const messages = messagesModule
