import React from 'react'

import ButtonIcon from 'components/button-icon'
import { Upload } from 'components/buttons'
import Icon from 'components/icon'
import Image from 'components/image'
import { colors } from 'config/theme'
import emitter from 'utils/emitter'
import { Block, Flex } from '../../common'
import { useTranslation } from 'react-i18next'
import { TOOLTIPS } from 'config/constants'

const PHOTO_TOOLTIP = [TOOLTIPS.photo.titleT, TOOLTIPS.photo.messageT]
const s3BaseUrl = process.env.S3_UPLOADS_BASE_URL

interface DisplayImageProps {
  onChange: (results?: []) => void
  readOnly: boolean
  value: string
}

export default function DisplayImage(props: DisplayImageProps): JSX.Element {
  const { onChange, readOnly, value = '' } = props

  const { t } = useTranslation()

  return (
    <Block alignSelf="flex-start" position="relative">
      {value && !readOnly && (
        <Block
          marginRight="-10px"
          marginTop="-5px"
          position="absolute"
          right={0}
        >
          <ButtonIcon
            backgroundColor={colors.red.normal}
            marginRight="0px"
            onClick={(): void => onChange(null)}
          >
            <Icon
              name="neutral"
              color={colors.white}
              fontSize={12}
              fontWeight={600}
              lineHeight={1}
            />
          </ButtonIcon>
        </Block>
      )}
      <Flex flexDirection="column">
        {value && (
          <>
            <Image
              alt="image"
              marginBottom={20}
              maxWidth={300}
              src={`${s3BaseUrl}/${value}`}
              width="100%"
            />
            <Flex height={10} />
          </>
        )}
        {!readOnly && (
          <Upload
            onError={handleError}
            onSuccess={(results): void => {
              onChange(results[0].path)
            }}
            tooltip={[t(PHOTO_TOOLTIP[0]), t(PHOTO_TOOLTIP[1])]}
          />
        )}
      </Flex>
    </Block>
  )
}

function handleError(error): void {
  emitter.emit('notification:add', {
    message: error.message,
    theme: 'alert',
    title: 'Upload Error',
  })
}
