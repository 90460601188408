import { get } from 'lodash'

import {
  isEmail,
  isLength,
  isRequired,
  validate,
} from '../../validation'

const passwordMatching = (value, allValues) => value !== get(allValues, 'newPassword')
const passwordMismatchError = 'This field must match password'
const isPasswordMatching = validate(passwordMatching, passwordMismatchError)

const confirmPasswordRequired = (value, allValues) => !!get(allValues, 'newPassword')
const confirmPasswordRequiredError = 'This is a required field when Password defined'
const isConfirmPasswordRequired = validate(
  isRequired(),
  confirmPasswordRequiredError,
  confirmPasswordRequired
)

export default {
  firstName: {
    label: 'First Name',
    name: 'firstName',
    required: true,
    validate: [isRequired()],
  },
  lastName: {
    label: 'Last Name',
    name: 'lastName',
    required: true,
    validate: [isRequired()],
  },
  username: {
    label: 'Username',
    name: 'username',
    required: true,
    validate: [isRequired()],
  },
  email: {
    label: 'Email',
    name: 'email',
    type: 'email',
    required: true,
    validate: [
      isRequired(),
      isEmail(),
    ],
  },
  mobile: {
    label: 'Mobile',
    name: 'mobile',
  },
  newPassword: {
    label: 'New Password',
    name: 'newPassword',
    validate: [
      isLength({ min: 4 }),
    ],
    type: 'password',
  },
  confirmNewPassword: {
    label: 'Confirm New Password',
    name: 'confirmNewPassword',
    validate: [
      isConfirmPasswordRequired,
      isPasswordMatching,
    ],
    type: 'password',
  },
}
