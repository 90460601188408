import React from 'react'
import Nav from '../nav'
import navigationBuilder from 'components/navigation'

const TemplatesMain = ({ children, navigation, pathParts }) => {
  const section = pathParts[1] || 'content'

  return (
    <React.Fragment>
      <Nav navigation={navigation} section={section} />
      {children}
    </React.Fragment>
  )
}

export default navigationBuilder({
  slice: 'templates',
})(TemplatesMain)
