import { Redirect, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import React from 'react'

import PageLoader from 'components/page-loader'

const Entry = Loadable({
  loader: () => import('./components/entry'),
  loading: PageLoader,
})

const List = Loadable({
  loader: () => import('./components/list'),
  loading: PageLoader,
})

export default function TasksRoute() {
  return (
    <Switch>
      <Route
        component={Entry}
        exact
        path={['/templates/tasks/entry/:id', '/templates/tasks/entry']}
      />
      <Route component={List} exact path="/templates/tasks" />
    </Switch>
  )
}
