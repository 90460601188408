import { includes } from 'lodash/fp'

import { generateScheduleEnd, hasValidStartAndEnd } from '.'
import {
  IntervalTypes,
  ScheduleIntervalsGenerator,
  Unit,
} from '../scheduling.types'
import {
  occurrenceIntervalsGenerator,
  serviceIntervalsGenerator,
} from '../generators'

export const complyingServiceIntervalUnits = [
  Unit.Second,
  Unit.Minute,
  Unit.Hour,
]

/**
 * Generates repeating schedule service and occurrence intervals
 */
export function* generateRepeatingSchedule(props: ScheduleIntervalsGenerator) {
  const { serviceHours, start, strategy } = props
  let { end, isInitial } = props

  // NOTE support never ending repeating schedule where no end date defined by
  // setting a custom end date using the frequency unit and value
  const {
    options: { frequency },
  } = strategy
  const { unit: frequencyUnit } = frequency
  const { timezone } = serviceHours

  end = end || generateScheduleEnd({ frequency, start, timezone })

  const isStartAndEndValid = hasValidStartAndEnd({ end, start })

  if (!isStartAndEndValid) return []

  const serviceIntervalSequence = serviceIntervalsGenerator({
    end,
    serviceHours,
    start,
  })

  // NOTE: when repeating and is complying to service hours we iterate through
  // all service intervals otherwise service interval is from the start and end
  const complyToServiceHours = includes(
    frequencyUnit,
    complyingServiceIntervalUnits
  )

  const serviceIntervals = complyToServiceHours
    ? [...serviceIntervalSequence]
    : [[start, end]]

  for (const serviceInterval of serviceIntervals) {
    yield { interval: serviceInterval, type: IntervalTypes.Service }

    const occurrenceIntervalsSequence = occurrenceIntervalsGenerator({
      isInitial,
      serviceInterval,
      strategy,
      timezone,
    })

    for (const occurrenceInterval of occurrenceIntervalsSequence) {
      yield { interval: occurrenceInterval, type: IntervalTypes.Occurrence }
    }

    // NOTE: we must reset isInitial to true follwing the first service
    // interval otherwise the occurrence will not start exactly on the next
    // service interval start time
    isInitial = true
  }
}
