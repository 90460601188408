import { compose, pure, setPropTypes } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'
import Button from 'components/button'
import Icon from 'components/icon'
import { useTranslation } from 'react-i18next'

const PROP_TYPES = {
  canDiscard: PropTypes.bool,
  canRedo: PropTypes.bool,
  canSave: PropTypes.bool,
  canUndo: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onRedo: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onUndo: PropTypes.func,
  saveText: PropTypes.string,
  submittingText: PropTypes.string,
}

export default compose(pure, setPropTypes(PROP_TYPES))(HistoryControls)

// NOTE: When we can position a Leaflet.Control to have a center
// position we should update this component to extend MapControl
function HistoryControls(props) {
  const { t } = useTranslation()

  const {
    canDiscard,
    canRedo,
    canSave,
    canUndo,
    isSubmitting,
    onDiscard,
    onExit,
    onRedo,
    onSave,
    onUndo,
    saveText = t('button.save'),
    submittingText = t('button.saving'),
  } = props

  const isSaveButtonLast = !onRedo

  return (
    <Flex>
      {onUndo && (
        <Flex>
          <Button
            disabled={isSubmitting || !canUndo}
            first
            key="buttonUndo"
            onClick={onUndo}
            size="small"
            theme="largeIcon"
          >
            <Icon name="undo" />
          </Button>
        </Flex>
      )}
      {canDiscard && (
        <Button
          disabled={isSubmitting}
          key="buttonDiscard"
          onClick={onDiscard}
          size="small"
        >
          {t('button.discard')}
        </Button>
      )}
      {!canDiscard && (
        <Button key="buttonExit" onClick={onExit} size="small">
          {t('button.exit')}
        </Button>
      )}
      <Button
        disabled={isSubmitting || !canSave}
        key="buttonSave"
        noMargin={isSaveButtonLast}
        onClick={onSave}
        size="small"
        theme="positive"
        type="submit"
      >
        {isSubmitting ? submittingText : saveText}
      </Button>
      {onRedo && (
        <Flex>
          <Button
            disabled={isSubmitting || !canRedo}
            key="buttonRedo"
            last
            noMargin
            onClick={onRedo}
            size="small"
            theme="largeIcon"
          >
            <Icon name="redo" />
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
