import {
  isRequired,
} from '../../../validation'

export default {
  name: {
    label: 'Group Name',
    name: 'name',
    required: false,
    placeholder: 'Choose a group name',
    validate: [],
  },
  participants: {
    label: 'Participants',
    name: 'speakers',
    required: true,
    placeholder: 'Select participants',
    validate: [isRequired()],
  },
}
