import React from 'react'
import Nav from '../nav'
import navigationBuilder from 'components/navigation'

const SettingsMain = ({ children, navigation, pathParts }) => {
  const section = pathParts[1] || 'profile'
  const styles = {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }

  return (
    <div style={styles.root}>
      <Nav navigation={navigation} section={section} />
      {children}
    </div>
  )
}

export default navigationBuilder({
  slice: 'settings',
})(SettingsMain)
