import { getModule, validation } from '@lighthouse/sdk'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Field, reduxForm } from 'redux-form'

import { InputText, BasicForm, FieldGroup, FieldSet } from 'components/form'

import { handleFormError, handleSaveResponse } from 'components/form/helpers'

import ButtonGroup from 'components/button-group'
import Button from 'components/button'
import Alert from 'components/alert'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

const userModule = getModule('user')
const schema = userModule.schema

const errorMessage = `Something isn't right! The invite might have expired or has already been used.
Please try again and if the problem persists contact support`
const successMessage = 'Sign up complete! Redirecting you to login now...'

const form = 'invite'

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined
const isLength = validation.isLength({ min: 4 })

class InviteSignupForm extends Component {
  constructor(props) {
    super(props)

    this.onSave = this.onSave.bind(this)
    this.saveForm = this.saveForm.bind(this)

    this.state = {
      success: false,
    }
  }

  componentWillMount() {
    const { reset } = this.props

    // NOTE reset the form when the component mounts. This is a bit of a hack
    // workaround to ensure that the loading state of the form does not persist
    // across page reloads. Without this, if a user attempts to login and the
    // form goes into a submitting state and then reloads the page, the
    // submitting stat will persist which is undesirable
    reset()
  }

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      submitFailed,
      submitting,
      t,
    } = this.props

    const { success } = this.state

    return (
      <div>
        {success && <Alert type="success" messages={[successMessage]} />}
        {error && submitFailed && (
          <Alert type="error" messages={[errorMessage]} />
        )}
        <BasicForm onSubmit={handleSubmit(this.saveForm)} noValidate>
          <FieldGroup>
            <FieldSet>
              <Field {...schema.firstName} component={InputText} />
              <Field {...schema.lastName} component={InputText} />
              <Field {...schema.email} component={InputText} />
              <Field
                {...schema.newPassword}
                component={InputText}
                required
                validate={[isRequired, isLength]}
              />
            </FieldSet>
          </FieldGroup>
          <ButtonGroup align="right" border>
            <Button
              disabled={invalid}
              type="submit"
              theme="positive noMargin"
              loading={submitting}
            >
              {submitting ? t('button.processing') : t('button.completeSignup')}
            </Button>
          </ButtonGroup>
        </BasicForm>
      </div>
    )
  }

  onSave() {
    const { submitCallback } = this.props

    this.setState({ success: true }, submitCallback)
  }

  saveForm(data) {
    const { acceptInviteRequest, inviteToken, submitCallback } = this.props

    const payload = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.newPassword,
      token: inviteToken,
    }

    return acceptInviteRequest(payload)
      .then(handleSaveResponse('invite'))
      .then(this.onSave)
      .then(submitCallback)
      .catch(handleFormError)
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(),
  reduxForm({ form })
)(InviteSignupForm)

function mapDispatchToProps(dispatch) {
  return {
    acceptInviteRequest: payload =>
      dispatch(userModule.acceptInviteRequest(payload)),
  }
}
