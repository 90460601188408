/*
 * User Applications Module
 *
 * Example State:
 * ==============
 *
 * {
 *   current: '1',
 *   list: [{
 *     _id: '1',
 *     name: 'Application1'
 *   }, {
 *     _id: '2',
 *     name: 'Application2'
 *   }]
 * }
 */

import { REQUEST } from '../../middleware/request'
import Immutable, { isImmutable } from 'seamless-immutable'
import { ask } from '@lighthouse/hall-pass'
import { createSelector } from 'reselect'
import { multiPolygon } from '@turf/helpers'

import {
  attempt,
  chain,
  find,
  get,
  isError,
  map,
  pick,
} from 'lodash'

import {
  AUTHENTICATE_SUCCESS,
} from '../authentication'

import {
  actions as applicationUserActions,
} from '../application-users'

import {
  ROLE_SUCCESS,
} from '../user'

import {
  getRegionUrl,
  authorizationHeadersFn,
} from '../app'

import { parseState } from '../../helpers'

export const QUERY_REQUEST = 'lighthouse/user-applications/QUERY_REQUEST'
export const QUERY_SUCCESS = 'lighthouse/user-applications/QUERY_SUCCESS'
export const QUERY_ERROR = 'lighthouse/user-applications/QUERY_ERROR'

export function reducer(state = Immutable({}), action = {}) {
  state = parseState(state)

  const {
    applicationId,
    data = {},
    error,
    type,
  } = action

  switch (type) {
    case QUERY_REQUEST:
      return state.set('state', 'resolving')
    // TODO API needs updating to match expected response
    case QUERY_SUCCESS:
      return state.merge({
        state: 'resolved',
        applications: data,
      })
    case QUERY_ERROR:
      return state.merge({
        state: 'resolved',
        error,
      })
    case AUTHENTICATE_SUCCESS:
      return state.merge({
        state: 'resolved',
        applications: data.applications,
      })
    case ROLE_SUCCESS:
      return state.set('applications', map(state.applications, (userApplication) => {
        // NOTE: applicationId attached to action so not part of action.data
        const matchesApplication = (get(userApplication, 'application._id', '') === applicationId)
        if (!matchesApplication) return userApplication

        return userApplication.setIn(['role', 'permissions'], data.permissions)
      }))
    /**
      NOTE
      We need to handle this here for when users have never logged into the app
      before. The speakerId is stored on a users' userApplication, but if
      they're a new user, when they first login then they won't have a speakerId
      in the place. So what we do here is use the speakerId returned by
      messagesRegistration and set that on the speakerbox configuration for that
      user
      */
    case applicationUserActions.MESSAGES_REGISTRATION_SUCCESS:
      action.data = action.data || {}
      return state.set('applications', map(state.applications, (userApplication) => {
        const matchesApplication = (get(userApplication, 'application._id', '') === data.applicationId)
        if (!matchesApplication) return userApplication

        return userApplication.setIn(['speakerbox', 'speakerId'], data.speakerId)
      }))
    default:
      return state
  }
}

export function queryRequest() {
  return (dispatch, getState) => {
    const state = getState()
    const regionUrl = getRegionUrl(state)
    const endpoint = `${regionUrl}/user/applications`
    const headers = authorizationHeadersFn(null, state)
    return dispatch(queryUserApplications(endpoint, headers))
  }
}

function queryUserApplications(endpoint, headers) {
  return {
    [REQUEST]: {
      types: [QUERY_REQUEST, QUERY_SUCCESS, QUERY_ERROR],
      endpoint,
      headers,
    },
  }
}

const cacheSelector = state => state.userApplications
const applicationIdSelector = state => state.app.applicationId
const areaSelector = state => state.areas.cache
export const getCurrentApplication = createSelector(
  applicationIdSelector,
  cacheSelector,
  (applicationId, cache) => find(cache.applications, ['application._id', applicationId]),
)

export const getFlags = createSelector(
  getCurrentApplication,
  (currentApplication = {}) => {
    const flags = get(currentApplication, 'application.flags', {})
    return flags
  },
)

export const getPermissions = createSelector(
  getCurrentApplication,
  (currentApplication = {}) => {
    const rolePermissions = get(currentApplication, 'role.permissions', [])
    const userPermissions = currentApplication.permissions || []
    return [
      ...rolePermissions,
      ...userPermissions,
    ]
  },
)

/**
 * askPermission
 * can be used to assert permissions or to attain a list of available
 * permissions for a certain module
 * TODO flesh out these docs
 */
export const askPermission = createSelector(
  getPermissions,
  permissions => (options) => {
    const {
      action,
      module,
      documentIds,
    } = options
    const permissionOpts = {
      module,
      documentIds,
    }
    const result = ask(permissions, action, permissionOpts)
    return result
  },
)

export const speakerIdSelector = createSelector(
  getCurrentApplication,
  currentApplication => get(currentApplication, 'speakerbox.speakerId'),
)

export const geometryPermissions = createSelector(
  areaSelector,
  getPermissions,
  (areaCache, permissions) => {
    const areaIds = chain(permissions)
          .filter({
            module: 'area',
            type: 'document',
          })
          .map('value')
          .value()

    if (areaIds.length === 0) {
      return null
    }

    const areas = pick(areaCache, areaIds)
    const coordinates = chain(areas)
          .map('entity.geometry.coordinates')
          .compact()
          .value()

    const geoJson = attempt(
      multiPolygon,
      coordinates,
    )

    // NOTE Ensure geoJson object is valid before returning
    return isError(geoJson)
      ? null
      : geoJson
  },
)

export function headersFn(params, state) {
  const authorization = get(state, 'authentication.accessToken')
  return {
    authorization,
  }
}
