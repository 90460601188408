import React from 'react'
import { HeaderRendererParams } from './simpleTable.types'

const HeaderRow = ({ columns, headerStyle }): JSX.Element => {
  return (
    <div style={{ display: 'flex' }}>
      {columns.map((column, columnIndex) => {
        const { label, columnStyle = {}, flex, headerRenderer } = column.props
        const headerOptions: HeaderRendererParams = {
          label,
          columnStyle,
          flex,
          columnIndex,
        }
        return (
          <div
            key={`Col${columnIndex}`}
            style={{ flex, ...headerStyle, ...columnStyle }}
          >
            {headerRenderer(headerOptions)}
          </div>
        )
      })}
    </div>
  )
}

export default HeaderRow
