import { registerPosthook } from 'redux-hook-middleware'

import { SEND_TRACKING } from '../../../constants'

import {
  setListFiltersDatafn,
} from '../tracking'

export default function hooks(actions) {
  registerPosthook(actions.SET_LIST_FILTERS, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: setListFiltersDatafn,
      hookedAction: action,
    })
  })
  registerPosthook(actions.END_REQUEST, (store, action) => store.dispatch({
    ...action,
    type: actions.SAVE_REQUEST,
  }))
  registerPosthook(actions.END_SUCCESS, (store, action) => store.dispatch({
    ...action,
    type: actions.SAVE_SUCCESS,
  }))
  registerPosthook(actions.END_ERROR, (store, action) => store.dispatch({
    ...action,
    type: actions.SAVE_ERROR,
  }))
}
