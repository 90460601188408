import {
  find,
  includes,
  isEmpty,
  memoize,
  reduce,
  values,
} from 'lodash'

/**
 * returns cache grouped by nearby/other status
 * @param {Number} maxDistance - meters to query nearby areas by
 */
export function buildNearbySelectorFn(referenceKey) {
  return (sortByPositionSelector, cache) => memoize((maxDistance) => {
    const nearbyAreas = sortByPositionSelector(maxDistance)

    // If there are no nearby areas, return all as 'others'
    if (isEmpty(nearbyAreas)) {
      return {
        nearby: [],
        others: values(cache),
      }
    }

    const referenceGroups = reduce(cache, (accum, reference) => {
      const isNearby = find(nearbyAreas, area => (
        includes(area[referenceKey], reference.id)
      ))

      // Add reference to nearby or other group
      if (isNearby) {
        accum.nearby = [
          ...accum.nearby,
          reference,
        ]
      } else {
        accum.others = [
          ...accum.others,
          reference,
        ]
      }

      return accum
    }, {
      nearby: [],
      others: [],
    })

    return referenceGroups
  })
}
