import PropTypes from 'prop-types'
import React from 'react'

import { Block } from 'components/common'
import ActivityList from 'components/activity-list'
import LocationList from 'components/location-list'
import colors from 'config/theme/colors'

import Search from './components/search'

export default function PanelRight(props) {
  const { handleClose, loading, timeline, timezone, urlId, view } = props

  const isVisible = !!view

  const isActivityView = view === 'activity'
  const isLocationView = view === 'location'
  const isSearchView = view === 'search'

  const right = isVisible ? 0 : -360

  return (
    <Block
      borderLeft={`1px solid ${colors.gray.lighter}`}
      backgroundColor={colors.white}
      bottom={0}
      position="absolute"
      right={right}
      top={0}
      width={359}
    >
      {isActivityView && (
        <ActivityList
          handleClose={handleClose}
          timeline={timeline}
          timezone={timezone}
        />
      )}
      {isLocationView && (
        <LocationList
          handleClose={handleClose}
          loading={loading}
          timeline={timeline}
          timezone={timezone}
        />
      )}
      {isSearchView && <Search handleClose={handleClose} urlId={urlId} />}
    </Block>
  )
}

PanelRight.propTypes = {
  handleClose: PropTypes.func.isRequired,
  timeline: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  urlId: PropTypes.string,
  view: PropTypes.string,
}
