import { connect } from 'react-redux'
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose'
import PropTypes from 'prop-types'
import { getModule } from '@lighthouse/sdk'
import { difference, filter, get, isEmpty, isEqual, isNil, map } from 'lodash'
import React from 'react'

import FlagsHOC from 'components/flags/hoc'
import Select from '../select'

export default compose(
  FlagsHOC,
  connect(mapStateToProps),
  withHandlers({ handleSearch }),
  withState('isLoading', 'setLoading', true),
  // NOTE this is only called on initial render
  withPropsOnChange([], props => {
    const {
      areaCache,
      getRejectedZones,
      location = [],
      setLoading,
      value = [],
      zoneCache,
    } = props

    // NOTE We store template location as area locations, which don't have a
    // direct reference to the zone. So we have to implement this go-between for
    // determining the relationship:
    // - get the legacyId form the area location
    // - match the zones by legacyId (location ID)
    const legacyLocationIds = location.reduce((acc, areaLocationId) => {
      const areaLocation = areaCache[areaLocationId]

      const legacyId = get(areaLocation, 'entity.legacyId')

      if (!legacyId) {
        console.warn('Missing Legacy ID on Area Location', { areaLocationId })
        return acc
      }

      acc.push(legacyId)

      return acc
    }, [])

    const filteredZones = filter(zoneCache, zone => {
      const zoneLocation = zone.entity.location

      return legacyLocationIds.includes(zoneLocation)
    })

    const initialZones = filteredZones.map(zone => ({
      label: zone.entity.name,
      value: zone.id,
    }))

    const zoneOptions = map(initialZones, option => option.value)
    const rejectedZones = difference(value, zoneOptions)

    if (!isNil(getRejectedZones)) getRejectedZones(rejectedZones)

    setLoading(false)

    return {
      initialOptions: initialZones,
    }
  }),
  withState('options', 'setOptions', props => props.initialOptions),
  lifecycle({ componentDidUpdate })
)(ZoneSelect)

function ZoneSelect(props) {
  const {
    disabled,
    className,
    error,
    handleSearch,
    isLoading,
    location,
    multi,
    onBlur,
    onChange,
    options,
    placeholder,
    required,
    simpleValue,
    value,
  } = props

  const newValue = isEmpty(location) ? [] : value

  const classes = error ? `${className}, error` : className

  return (
    <Select
      className={classes}
      disabled={disabled || isLoading}
      multi={multi}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      required={required}
      simpleValue={simpleValue}
      shouldSort
      value={newValue}
    />
  )
}

ZoneSelect.propTypes = {
  disabled: PropTypes.bool,
  fetchZones: PropTypes.func,
  getRejectedZones: PropTypes.func,
  isLoading: PropTypes.bool,
  location: PropTypes.array,
  multi: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.array,
}

function componentDidUpdate(prevProps) {
  const {
    areaCache,
    getRejectedZones,
    location,
    setLoading,
    setOptions,
    value = [],
    zoneCache,
  } = this.props

  const hasLocationsChanged = !isEqual(prevProps.location, location)

  if (hasLocationsChanged) {
    // NOTE We store template location as area locations, which don't have a
    // direct reference to the zone. So we have to implement this go-between for
    // determining the relationship:
    // - get the legacyId form the area location
    // - match the zones by legacyId (location ID)
    const legacyLocationIds = (location || []).reduce((acc, areaLocationId) => {
      const areaLocation = areaCache[areaLocationId]

      const legacyId = get(areaLocation, 'entity.legacyId')

      if (!legacyId) {
        console.warn('Missing Legacy ID on Area Location', { areaLocationId })
        return acc
      }

      acc.push(legacyId)

      return acc
    }, [])

    const filteredZones = filter(zoneCache, zone => {
      const zoneLocation = zone.entity.location

      return legacyLocationIds.includes(zoneLocation)
    })

    const initialZones = filteredZones.map(zone => ({
      label: zone.entity.name,
      value: zone.id,
    }))

    setLoading(true)

    const zoneOptions = map(initialZones, option => option.value)
    const rejectedZones = difference(value, zoneOptions)

    if (!isNil(getRejectedZones)) getRejectedZones(rejectedZones)

    setOptions(initialZones)
    setLoading(false)
  }
}

function handleSearch(props) {
  const { zoneCache } = props

  return value => {
    const filteredZones = filter(zoneCache, zone => {
      const zoneName = zone.entity.name
      return zoneName.toLowerCase().includes(value.toLowerCase())
    })

    return filteredZones.map(zone => ({
      label: zone.entity.name,
      value: zone.id,
    }))
  }
}

function mapStateToProps(state) {
  return {
    areaCache: state.areas.cache,
    zoneCache: state.zones.cache,
  }
}
