import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import styles from './styles'

export default Radium(FormSection)

function FormSection(props) {
  const title = props.title ? (
    <h2 style={[styles.title]}>{props.title}</h2>
  ) : null

  return <div style={[styles.root]}>{title}</div>
}

FormSection.propTypes = {
  title: PropTypes.string,
}
