import React from 'react'

import { Wrapper } from 'components/common'
import Alert from 'components/alert'
import { useTranslation } from 'react-i18next'

export default function NotFoundRoute() {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Alert messages={[t('alert.message.pageNotFound')]} type="error" />
    </Wrapper>
  )
}
