import React from 'react'
import navigationBuilder from 'components/navigation'
import NavList from 'modules/nav-list'
import { isArray, map } from 'lodash'
import { useTranslation } from 'react-i18next'

export default navigationBuilder({
  slice: 'schedules',
})(Schedules)

function Schedules(props) {
  const { children, navigation, pathParts } = props

  const section = pathParts[1]

  const { t } = useTranslation()
  const navItems = {}

  map(navigation, (nav, key) => {
    navItems[key] = {
      ...nav,
      label: nav.labelT
        ? isArray(nav.labelT)
          ? t(...nav.labelT)
          : t(nav.labelT)
        : nav.label,
    }
  })

  return (
    <React.Fragment>
      <NavList type="secondary" items={navItems} active={section} />
      {children}
    </React.Fragment>
  )
}
