import { connect } from 'react-redux'
import { get, find, isEmpty } from 'lodash'
import { getLocationReference, getTimezoneDatetime } from '@lighthouse/common'
import { getModule } from '@lighthouse/sdk'
import moment from 'moment'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import React from 'react'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import withState from 'recompose/withState'

import colors from 'config/theme/colors'

import Form from '../form'
import Panel from '../../../panel'
import { withUsers } from 'components/useUsers'
import { withTranslation } from 'react-i18next'

const LIST_ID = 'all'

const applicationUserModule = getModule('applicationUsers')
const areaModule = getModule('areas')
const issueModule = getModule('issues')
const templateModule = getModule('templates')

export default compose(
  withState('error', 'setError'),
  withState('isDeleting', 'setDeleting', false),
  withState('isEditing', 'setEditing', false),
  withState('isLoaded', 'setLoaded', false),
  connect(mapStateToProps, mapDispatchToProps),
  withUsers,
  withTranslation(),
  withProps(buildEntryProps),
  withProps(buildTitle),
  withHandlers({ handleCancel, handleEdit }),
  lifecycle({ componentWillMount })
)(IssueEntry)

function IssueEntry(props) {
  const {
    id,
    error,
    form,
    handleCancel,
    handleEdit,
    hasGeo,
    initialValues,
    isDeleting,
    isEditing,
    isLoaded,
    issueEntity = {},
    isIssueResolving,
    isTemplateResolving,
    onClose: handleClose,
    setError,
    setDeleting,
    setEditing,
    templateEntity,
    title,
    toggleExpand,
    toggleIcon,
  } = props

  const isDuress = issueEntity.type === 'duress'
  const isLocked = !!issueEntity.locked
  const panelColor = isDuress ? colors.red : colors.orange
  const resource = isDuress ? 'Duress Issue' : 'Issue'

  const isResolving = isIssueResolving || isTemplateResolving

  return (
    <Panel
      color={panelColor}
      error={error}
      isResolving={isResolving}
      onClose={handleClose}
      resource={resource}
      title={title}
      toggleExpand={toggleExpand}
      toggleIcon={toggleIcon}
    >
      {isLoaded && !isDeleting && (
        <Form
          form={form}
          handleCancel={handleCancel}
          handleEdit={handleEdit}
          hasGeo={hasGeo}
          id={id}
          initialValues={initialValues}
          isEditing={isEditing}
          isLocked={isLocked}
          onClose={handleClose}
          readOnly={!isEditing}
          setError={setError}
          setDeleting={setDeleting}
          setEditing={setEditing}
          templateEntity={templateEntity}
        />
      )}
    </Panel>
  )
}

function componentWillMount() {
  const {
    fetchIssue,
    fetchTemplate,
    fetchUser,
    hasLoadedIssue,
    hasLoadedTemplate,
    id,
    issueEntity,
    setError,
    setLoaded,
  } = this.props

  let issue
  const fetchIssuePromise = hasLoadedIssue
    ? Promise.resolve(issueEntity)
    : fetchIssue(id).then(handleResponse)

  return fetchIssuePromise
    .then(data => {
      issue = data
      if (hasLoadedTemplate) return
      return fetchTemplate(data.template)
    })
    .then(() => fetchUser({ userId: issue.user }))
    .catch(error => setError(error))
    .finally(() => setLoaded(true))
}

function buildEntryProps(props) {
  const {
    getUserFullName,
    id,
    issueCache,
    locations,
    templateCache,
    timezone,
    t,
  } = props

  const issueResource = issueCache[id] || {}
  const issueEntity = issueResource.entity || {}
  const hasIssueEntityEntry = !!issueEntity.entry

  const isIssueResolved = issueResource.state === 'resolved'
  const isIssueResolving = issueResource.state === 'resolving'
  const isIssueError = !!issueResource.error

  const templateResource = templateCache[issueEntity.template] || {}
  const templateEntity = templateResource.entity || {}
  const hasTemplateEntityTemplate = !!templateEntity.template

  const isTemplateResolved = templateResource.state === 'resolved'
  const isTemplateResolving = templateResource.state === 'resolving'
  const isTemplateError = !!templateResource.error

  const form = `issue-${id}`
  let initialValues
  let hasGeo

  // NOTE: If there is an error we should attempt to fetch again
  const hasLoadedIssue = isIssueResolved && !isIssueError && hasIssueEntityEntry
  const hasLoadedTemplate =
    isTemplateResolved && !isTemplateError && hasTemplateEntityTemplate

  //get location and created entry values
  const location = getLocationReference({
    entity: issueEntity,
  })

  const userFullName = getUserFullName(issueEntity.user)
  const dateTime = getTimezoneDatetime({
    timestamp: issueEntity.createdAt,
    timezone,
  })
  const timezoneTime = moment(dateTime, 'ddd, MMM D h:mm:ssa').format(
    'MMM DD h:mma'
  )

  const created = `${timezoneTime} by ${userFullName}`

  // NOTE: only set initial values if we have values
  // as we want to prevent redux form fields registering
  // multiple times
  const hasAllResources = hasLoadedIssue && hasLoadedTemplate

  if (hasAllResources) {
    const {
      area,
      assignees = [],
      floorsRef = [],
      geo = {},
      gps = {},
      status,
      timeline = [],
      title,
    } = issueEntity.asMutable({ deep: true })

    const formGroups = get(issueEntity, 'entry.formGroups', [])

    // NOTE must check if geo coordinates is empty as if the
    // geo property is not on the document it sets default values
    hasGeo = !isEmpty(geo.coordinates)

    // NOTE set correct geometry for the issue
    const geometry = hasGeo ? geo : gps.geometry

    initialValues = {
      // NOTE: created and location are used when form is read only
      assignees,
      created,
      floorsRef,
      formGroups,
      geometry,
      gps,
      location,
      status,
      timeline,
      title,
    }

    const locationId = get(area, 'location.id')
    const geocodedLabel = get(gps, 'reverseGeocoded.label')

    const formLocation = find(locations, ['id', locationId])

    const label =
      (formLocation && formLocation.entity.name) ||
      geocodedLabel ||
      t('labelUnknownLocation')
    const value = (formLocation && formLocation.id) || 'custom-value'

    initialValues.locationSelect = { label, value }
  }

  return {
    form,
    hasAllResources,
    hasLoadedIssue,
    hasLoadedTemplate,
    initialValues,
    hasGeo,
    isIssueResolving,
    issueEntity,
    isTemplateResolving,
    templateEntity,
  }
}

function buildTitle(props) {
  const {
    issueEntity,
    isDeleting,
    isEditing,
    isIssueResolving,
    isTemplateResolving,
  } = props

  let title = 'Unknown Issue'

  if (isIssueResolving && !isEditing) title = 'Loading Issue'
  if (isTemplateResolving && !isEditing) title = 'Loading Template'
  if (isIssueResolving && isDeleting && isEditing) title = 'Deleting Issue'
  if (isIssueResolving && !isDeleting && isEditing) title = 'Saving Issue'
  if (!isIssueResolving && issueEntity.title) title = issueEntity.title

  return {
    title,
  }
}

function handleCancel(props) {
  return () => props.setEditing(false)
}

function handleEdit(props) {
  return () => props.setEditing(true)
}

function handleResponse(response) {
  const { data, error } = response

  if (error) return Promise.reject(new Error(error))

  return data
}

function mapDispatchToProps(dispatch) {
  return {
    fetchIssue: id => dispatch(issueModule.findById(id)),
    fetchTemplate: id => dispatch(templateModule.findById(id)),
  }
}

function mapStateToProps(state) {
  const applicationUserSelectors = applicationUserModule.selectors(state)(
    'default'
  )
  const areaSelectors = areaModule.selectors(state)()
  const issueSelectors = issueModule.selectors(state)(LIST_ID)
  const templateSelectors = templateModule.selectors(state)(LIST_ID)

  const issueCache = issueSelectors.cache()
  const locations = areaSelectors.byType('location')
  const templateCache = templateSelectors.cache()

  return {
    getUserFullName: applicationUserSelectors.getUserFullName,
    issueCache,
    locations,
    templateCache,
    timezone: state.app.timezone,
  }
}
