import colors from 'config/theme/colors'
import Color from 'color'

export default {
  root: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.blue.normal,
    borderRadius: 4,
    boxShadow: '2px 2px 10px rgba(0,0,0,.25)',
    marginBottom: 10,
    zIndex: 9999,
  },
  iconContainer: {
    backgroundColor: 'rgba(0,0,0,.05)',
    padding: 15,
  },
  icon: {
    fontSize: '24px',
    color: colors.white,
  },
  content: {
    flexGrow: 1,
    margin: 15,
    display: 'table',
    minHeight: 30,
  },
  text: {
    fontSize: '12px',
    color: colors.white,
    margin: 0,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  title: {
    fontWeight: 'bold',
  },
  options: {
    display: 'flex',
    padding: '10px 0 10px 10px',
  },
  buttonSecondary: {
    color: 'rgba(255,255,255,0.6)',
    ':hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
      color: 'rgba(255,255,255,1)',
    },
  },
  buttonPrimary: {
    color: colors.blue.dark,
    backgroundColor: 'rgba(255,255,255,0.9)',
    ':hover': {
      backgroundColor: 'rgba(255,255,255,1)',
    },
  },
  neutral: {
    backgroundColor: colors.blue.normal,
  },
  buttonNeutral: {
    color: colors.blue.dark,
  },
  warning: {
    backgroundColor: colors.orange,
  },
  buttonWarning: {
    color: colors.orange,
  },
  alert: {
    backgroundColor: colors.red.dark,
  },
  buttonAlert: {
    color: colors.red.dark,
  },
}
