import { Interval } from '../scheduling.types'

/**
 * Check if interval intersects another interval's end
 * Accepts two intervals and checks whether the first intersects the second's end
 *
 * intervalIntersectsEnd([12,20], [5,15]))
 * returns true
 */
export function intervalIntersectsEnd(
  firstInterval: Interval,
  secondInterval: Interval
): Boolean {
  return (
    firstInterval[1] >= secondInterval[1] &&
    firstInterval[0] < secondInterval[1]
  )
}
