import { get } from 'lodash'
import { getStatusDetails } from '@lighthouse/common'
import React from 'react'

import { Block, Flex } from 'components/common'
import { dateTime } from 'config/theme/formats'
import DatetimeTimezone from 'components/datetime-timezone'

export default function Item(props) {
  const { _id, meta = {}, timestamp } = props
  const { field } = meta

  const text = field === 'status' ? getStatusText(props) : null

  return (
    <Flex key={_id} paddingBottom={10} paddingTop={10}>
      <Block minWidth={150}>
        <DatetimeTimezone datetime={timestamp} format={dateTime.noAttr} />
      </Block>
      <Block>{text}</Block>
    </Flex>
  )
}

function getStatusText(props) {
  const { meta, source, type } = props

  const next = meta.next || {}
  const firstName = get(source, 'meta.firstName')
  const lastName = get(source, 'meta.lastName')

  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : 'Unknown User'

  const isNew = type === 'issue-new'
  const isUpdate = type === 'issue-update'
  const isOpen = next === 'open'

  const statusProps = getStatusDetails(next)
  const nextStatusText = statusProps.text

  if (isNew && isOpen) {
    return `Issue opened by ${fullName}`
  } else if (isNew && !isOpen) {
    return `Issue opened with status ${nextStatusText} by ${fullName}`
  } else if (isUpdate) {
    return `Issue updated to ${nextStatusText} by ${fullName}`
  }

  return 'Unknown status timeline change'
}
