import { get } from 'lodash'
import { getErrorDataFn, getSuccessDataFn } from '../../../tracking/helpers'

const category = 'Content Entry'

export const saveErrorDataFn = getErrorDataFn({ category, action: 'Save Error' })

export const saveSuccessDataFn = getSuccessDataFn({ category, action: 'Save Success', label: labelFn })

function labelFn(state, action) {
  return get(action, 'data.name')
}
