import { registerPosthook } from 'redux-hook-middleware'

import { SEND_TRACKING } from '../../../constants'

import {
  findErrorDataFn,
  findSuccessDataFn,
  setListFiltersDataFn,
} from '../tracking'

export default function hooks(actions) {
  registerPosthook(actions.FIND_ERROR, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: findErrorDataFn,
      hookedAction: action,
    })
  })

  registerPosthook(actions.FIND_SUCCESS, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: findSuccessDataFn,
      hookedAction: action,
    })
  })

  registerPosthook(actions.SET_LIST_FILTERS, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: setListFiltersDataFn,
      hookedAction: action,
    })
  })
}
