import socket from 'utils/socket'
import moment from 'moment'
import { noop } from 'lodash'

export function init(opts) {
  const channel = getChannel(opts)
  const state = {}

  return {
    subscribe: handler => {
      if (!state.isConnected) {
        state.isConnected = true
        subscribe(channel, handler)
      }
    },
    unsubscribe: () => {
      if (state.isConnected) {
        state.isConnected = false
        unsubscribe(channel)
      }
    },
    publish: (message, callback) => publish(channel, message, callback),
    history: callback => history(channel, callback),
  }
}

function subscribe(channel, handler) {
  // NOTE Rudimentary cache logic to ensure events are not pushed more than
  // every 5 seconds
  let cache = []
  // TODO Make timeout configurable on-the-fly
  let timeout = moment().add(5, 'seconds')

  console.info('⚡ Subscribing to channel', channel)

  socket.subscribe({
    channel,
    restore: true,
    error: error => {
      console.error(error)
    },
    connect: subscribedChannel => {
      console.info('⚡ Socket connection opened on:', subscribedChannel)
    },
    callback: data => {
      cache.push(data)
      const now = new Date()

      if (timeout.isAfter(now)) return

      handler(cache)
      timeout = moment().add(5, 'seconds')
      cache = []
      return
    },
  })
}

function unsubscribe(channel) {
  console.info('⚡ Closing socket connection on:', channel)
  socket.unsubscribe({
    channel,
  })
}

function publish(channel, message, callback = noop) {
  socket.publish({
    channel,
    message,
    callback: result => {
      console.info('successfully published event')
      callback(null, result)
    },
    error: err => {
      console.error('Error publishing event', err)
      callback(err)
    },
  })
}

function history(channel, callback) {
  return socket.history({
    channel,
    callback,
  })
}

function getChannel(opts) {
  const { applicationId } = opts
  return [
    `application-${applicationId}.events`,
    `application-${applicationId}.events.*`,
  ]
}
