import { connect } from 'react-redux'
import compose from 'recompose/compose'
import React from 'react'
import withProps from 'recompose/withProps'

import Form from '../../../form'

export default compose(
  connect(mapStateToProps),
  withProps(buildEntryProps)
)(Entry)

function Entry(props) {
  const {
    error,
    form,
    initialValues,
    onCancel: handleCancel,
    onClose: handleClose,
    templateEntity,
  } = props

  return (
    <Form
      error={error}
      form={form}
      handleCancel={handleCancel}
      initialValues={initialValues}
      isEditing
      onClose={handleClose}
      templateEntity={templateEntity}
    />
  )
}

function buildEntryProps(props) {
  const { auditId, auditsCache } = props

  const auditResource = auditsCache[auditId]
  const auditEntity = auditResource.entity || {}

  const form = 'audit-entry-new'

  const { headerForm: headerFields } = auditEntity
  const { footerForm: footerFields } = auditEntity

  const audit = auditId

  const items = auditEntity.items
  const title = auditEntity.title

  const initialValues = {
    audit,
    items,
    footerFields,
    headerFields,
    title,
  }

  return {
    form,
    initialValues,
    templateEntity: auditEntity,
  }
}

function mapStateToProps(state) {
  return {
    auditsCache: state.audits.cache,
  }
}
