import { atob, btoa } from '@lighthouse/abab'
import fetchPonyfill from 'fetch-ponyfill'
import Promise from 'bluebird'
import { LIGHTHOUSE_LOGO_URL } from '../../constants'
import { imageNotFound } from '../../images'

// NOTE use the native fetch if it's available in the browser, because the
// ponyfill (which actually uses the github polyfill) does not support all the
// same options as native fetch
const fetch =
  (typeof self === 'object' && self.fetch) || fetchPonyfill({ Promise }).fetch

const contentTypes = {
  'image/png': 'png',
  'image/jpeg': 'jpeg',
}

const defaultOptions = {
  // NOTE The cache: no-cache option is important to avoid an issue with CORS
  // and caching on Chrome. Here's a good explanation of the issue:
  // https://stackoverflow.com/a/37455118
  // In our case, when loading the web version of a form, the signature image is
  // cached without the correct CORS headers. If the pdf is then generated,
  // there's a mismatch between the cached image headers and the CORS headers
  // sent from the fetch request, causing an error
  cache: 'no-cache',
}

export function fetchImage(url, options = {}) {
  const encodedUrl = encodeURI(url)
  const fetchOptions = {
    ...defaultOptions,
    ...options,
  }
  const { isHeader = false } = options

  return fetch(encodedUrl, fetchOptions)
    .then(response => {
      const contentHeader = response.headers.get('content-length')
      const contentType = response.headers.get('content-type')

      // NOTE: the response will be ok but we won't be able to render any
      // image meaning pdfmake will error. Raise error here and return early.
      if (contentHeader === '0') {
        return Promise.reject(
          new Error(`Failed to fetch image as no content length: ${encodedUrl}`)
        )
      }

      if (!response.ok) {
        return Promise.reject(new Error(`Failed to fetch image: ${encodedUrl}`))
      }

      const imageType = contentTypes[contentType]

      return response.arrayBuffer().then(buffer => ({
        buffer,
        imageType,
      }))
    })
    .then(({ buffer, imageType }) => {
      const base64Flag = `data:image/${imageType};base64,`
      const imageStr = arrayBufferToBase64(buffer)

      const base64 = `${base64Flag}${imageStr}`
      const isValid = validateBase64Image(base64)

      if (!isValid) {
        return Promise.reject(new Error('InvalidImageError'))
      }

      return base64
    })
    .catch(error => {
      if (isHeader) {
        // NOTE: Replace failed headers with LH logo
        console.error('FetchImageHeaderError', error)
        return fetchImage(LIGHTHOUSE_LOGO_URL, defaultOptions)
      }

      console.error(error)
      return imageNotFound
    })
}

function arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = [].slice.call(new Uint8Array(buffer))

  bytes.forEach(b => (binary += String.fromCharCode(b)))

  return btoa(binary)
}

export function validateBase64Image(base64String) {
  const isJpeg = base64String.startsWith('data:image/jpeg;base64,')

  if (isJpeg) return validateJpegImage(base64String)

  const isPng = base64String.startsWith('data:image/png;base64,')

  if (isPng) return validatePngImage(base64String)

  return false
}

// See SO for more info: https://stackoverflow.com/a/41635312
// Fiddle: https://jsfiddle.net/Lnyxuchw/
export function validateJpegImage(base64string) {
  const src = base64string
  const imageData = Uint8Array.from(
    atob(src.replace('data:image/jpeg;base64,', '')),
    c => c.charCodeAt(0)
  )
  const imageCorrupted =
    imageData[imageData.length - 1] === 217 &&
    imageData[imageData.length - 2] === 255

  return imageCorrupted
}

// See SO for more info: https://stackoverflow.com/a/41635312
// Fiddle: https://jsfiddle.net/Lnyxuchw/
export function validatePngImage(base64string) {
  const src = base64string
  const imageData = Uint8Array.from(
    atob(src.replace('data:image/png;base64,', '')),
    c => c.charCodeAt(0)
  )
  const sequence = [0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130] // in hex:

  //check last 12 elements of array so they contains needed values
  for (let i = 12; i > 0; i--) {
    if (imageData[imageData.length - i] !== sequence[12 - i]) {
      return false
    }
  }

  return true
}
