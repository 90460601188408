import { REQUEST } from '../../../../middleware/request'

export default function buildActionCreators(
  actions,
  applicationResourceUrlFn,
  paramsFn,
  headersFn,
) {
  return {
    entriesByArea,
    entriesByTemplate,
  }

  function entriesByArea(areaId, params) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/${areaId}/entries`
      const headers = headersFn(params, state)

      delete params.applicationId
      delete params.regionUrl

      return dispatch(entriesByAreaRequest(endpoint, headers, params))
    }
  }

  function entriesByTemplate(templateId, params) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('content/templates')(params)
      const endpoint = `${baseUrl}/${templateId}/entries`
      const headers = headersFn(params, state)

      delete params.applicationId
      delete params.regionUrl

      return dispatch(entriesByTemplateRequest(endpoint, headers, params))
    }
  }

  function entriesByAreaRequest(endpoint, headers, params) {
    return {
      [REQUEST]: {
        types: [actions.QUERY_REQUEST, actions.QUERY_SUCCESS, actions.QUERY_ERROR],
        endpoint,
        headers,
        query: params,
      },
    }
  }

  function entriesByTemplateRequest(endpoint, headers, params) {
    return {
      [REQUEST]: {
        types: [actions.QUERY_REQUEST, actions.QUERY_SUCCESS, actions.QUERY_ERROR],
        endpoint,
        headers,
        query: params,
      },
    }
  }
}
