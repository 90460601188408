import {
  isRequired,
} from '../../validation'

export default {
  job: {
    label: 'Job',
    name: 'job',
    placeholder: 'Select Job',
    required: true,
    validate: [isRequired()],
  },
  user: {
    label: 'User',
    name: 'user',
    placeholder: 'Select User',
    required: true,
    validate: [isRequired()],
  },
  startTime: {
    label: 'Start',
    name: 'start.time',
    placeholder: 'Start Time',
  },
  startGeometry: {
    label: 'Start',
    name: 'start.geometry',
    placeholder: 'Start Location',
  },
  endTime: {
    label: 'End Time',
    name: 'end.time',
    placeholder: 'End Time',
  },
  endGeometry: {
    label: 'End',
    name: 'end.geometry',
    placeholder: 'End Location',
  },
}
