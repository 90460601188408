import { Redirect, Switch } from 'react-router-dom'
import React from 'react'

import Setup from './components/main'

import { ProtectedRoute } from '../../components/protectedRoute'
import AreasRoute from '../areas'
import LocationGroupsRoute from '../location-groups'
import SignalsRoute from '../signals'

export default function SetupRoute() {
  return (
    <Setup>
      <Switch>
        <ProtectedRoute
          permissions={{ action: 'read', module: 'area' }}
          path="/setup/areas"
        >
          <AreasRoute />
        </ProtectedRoute>
        <ProtectedRoute
          permissions={{ action: 'read', module: 'area' }}
          path="/setup/location-groups"
        >
          <LocationGroupsRoute />
        </ProtectedRoute>
        <ProtectedRoute
          permissions={{ action: 'read', module: 'zone' }}
          path="/setup/signals"
        >
          <SignalsRoute />
        </ProtectedRoute>
        {/* NOTE default route */}
        <Redirect from="/setup" to="/setup/signals" />
      </Switch>
    </Setup>
  )
}
