import { assign, get, includes, pick, reduce, reject } from 'lodash'

export function getCellData({ dataKey, rowData }) {
  return get(rowData, `entity.${dataKey}`)
}

export function getFilter(item, description) {
  const {
    _id,
    name,
    search,
    title,
    label: signalLabel,
    reference = '',
    plugins = {},
  } = item.entity

  const sin = get(plugins, 'timegate.options.SiteSIN', null)
  const jobNumber = get(plugins, 'winteam.options.jobNumber', null)
  const ref = sin
    ? `SIN: ${sin}`
    : jobNumber
    ? `Job #${jobNumber}`
    : `Ref: #${reference}`

  const hasRef = sin || jobNumber || reference
  const labelOnly = title || name || signalLabel
  const label = hasRef
    ? `${labelOnly}, ${ref}`
    : labelOnly || `Unknown ${description}`

  return {
    label,
    search,
    value: _id,
  }
}

export function getResource({ list, resources }) {
  return ({ index }) => {
    const resourceId = list.items[index]
    return resources[resourceId]
  }
}

export function getRowData(props) {
  return ({ index }) =>
    props.list.items ? props.list.items[index] : props.list[index]
}

export function getSearchFilter(props) {
  const { listFilters, searchKey = 'search' } = props
  const search = listFilters[searchKey]

  return search && search.replace('~', '')
}

export function getSortByDirection(sortArr) {
  const sortDirections = sortArr[1]

  // NOTE: When returning sort direction for table
  // return only the first direction in state
  return sortDirections[0].toUpperCase()
}

export function getSortByField(sortArr) {
  const sortFields = sortArr[0]

  // NOTE: When returning sort by for table
  // return only the first sort in state
  return sortFields[0]
}

export function handleClearAllFilters(props) {
  const {
    clearListFilters,
    listFilters,
    listId,
    searchKey = 'search',
    setFilters,
  } = props

  return () => {
    // NOTE: We shouldn't clear search and unassigned location filters!
    const toPersist = pick(listFilters, [
      'from',
      searchKey,
      'to',
      'locationFilter',
    ])
    const nextFilters = assign({}, parseDates(toPersist))
    clearListFilters(listId)
    setFilters(listId, nextFilters)
  }
}

export function handleClearFilter({ listFilters, listId, setFilters }) {
  return tag => {
    const { category, value } = tag
    const parsed = parseDates(listFilters)
    const nextFilters = {
      ...parsed,
      [category]: reject(listFilters[category], i => i === value),
    }
    return setFilters(listId, nextFilters)
  }
}

export function handleSearchFilter(props) {
  const { listFilters, listId, searchKey = 'search', setFilters } = props
  return text => {
    const search = text === '' ? undefined : `~${text}`

    const nextFilters = {
      ...listFilters,
      [searchKey]: search,
    }

    return setFilters(listId, nextFilters)
  }
}

export function handleHasLocationFilter({ listFilters, listId, setFilters }) {
  // NOTE: A value of `1` is to fetch documents which specifically have no location
  const locationFilter = listFilters['locationFilter'] === 1 ? '' : 1
  const nextFilters = {
    ...listFilters,
    locationFilter,
  }

  return () => setFilters(listId, nextFilters)
}

export function handleSetFilter({ listFilters = {}, listId, setFilters }) {
  return obj => {
    const { value, category } = obj
    const categoryValues = listFilters[category] || []
    const nextCategoryValues = [...categoryValues, value]
    const parsed = parseDates(listFilters)
    const nextFilters = {
      ...parsed,
      [category]: nextCategoryValues,
    }
    return setFilters(listId, nextFilters)
  }
}

export function isRowLoaded({ list }) {
  return ({ index }) => (list.items ? !!list.items[index] : !!list[index])
}

export function paginate(props) {
  const { listId, fetch, setPaginationOpts } = props

  return opts => {
    setPaginationOpts(listId, opts)
    fetch(listId, opts)
  }
}

export function parseDates(filters) {
  return reduce(
    filters,
    (accum, curr, key) => {
      if (includes(['from', 'to'], key)) {
        accum[key] = new Date(curr)
      } else {
        accum[key] = curr
      }
      return accum
    },
    {}
  )
}

export function setFilter({ listId, paginate, setFilters }) {
  return (filter = {}) => setFilters(listId, filter)
}
