import {
  compact,
  each,
  find,
  findIndex,
  filter,
  get,
  isEmpty,
  map,
  throttle,
} from 'lodash'

import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { WithPermissions } from '@lighthouse/react-components'
import { withRouter } from 'react-router-dom'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import Loadable from 'react-loadable'
import Promise from 'bluebird'
import Radium from 'radium'
import React from 'react'
import { ImageOverlay } from 'react-leaflet'

import { KEY_ESCAPE } from '../lib/constants'

import { Absolute, Block } from 'components/common'
import FabMenu from 'components/mapping/fab-menu'
import PageLoader from 'components/page-loader'
import withUrlQuery from 'components/with-url-query'

import * as socket from '../lib/socket'
import Geo from './maps/geo'
import GeojsonUploader from './geojson-uploader'

import ConnectedMapControls from './maps/connected-map-controls'
import EventsHandlerLayer from './maps/events-handler-layer'
import IndoorNavigation from './indoor-navigation'
import Map from './maps/base'
import Markers from './maps/markers'
import PanelLeft from './panel-left'
import PanelRight from './panel-right'
import styles from './styles'
import TemporaryMarker from './maps/temporary-marker'
import Timeline from './timeline'
import Toolbar from './toolbar'
import emitter from '../../../utils/emitter'

const stubObject = {}

const applicationUserModule = getModule('applicationUsers')
const areaModule = getModule('areas')
const audits = getModule('audits')
const contentEntries = getModule('content', 'entries')
const contentTemplates = getModule('content', 'templates')
const geoModule = getModule('geo')
const templates = getModule('templates')
const userApplicationsModule = getModule('userApplications')

const DEFAULT_MAP_TILE = 'map'
const DEFAULT_MARKER_FILTER_TYPES = {
  auditentries: true,
  issue: true,
  location: true,
  taskentries: true,
  user: true,
  signal: true,
  'signal.beacon': true,
  'signal.nfc': true,
  'signal.qrcode': true,
}
const EXIT_BUILDING_ZOOM_THRESHOLD = 14
const SET_PROPERTIES_THROTTLE_OPTS = { leading: true, trailing: false }

const ConnectedEditGeoJsonLayer = Loadable({
  loader: () => import('./maps/connected-edit-geo-json-layer'),
  loading: PageLoader,
})

const ConnectedGraphLayer = Loadable({
  loader: () => import('./maps/connected-graph-layer'),
  loading: PageLoader,
})

export default compose(
  withRouter,
  WithPermissions,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['setProperties'], props => ({
    setProperties: throttle(
      props.setProperties,
      750,
      SET_PROPERTIES_THROTTLE_OPTS
    ),
  })),
  withState('fabMenuVisible', 'setFabMenuVisibility', false),
  withState('loading', 'setLoading', true),
  withState('mapOptions', 'setMapOptions', { geofences: false }),
  withState('rightPanelView', 'setRightPanelView', ''),
  withState('socketConnection', 'setSocketConnection'),
  withPropsOnChange(['building'], props => {
    const { areaCache, building = {} } = props
    const entity =
      building.id && get(areaCache, `${building.id}.entity`, stubObject)
    return {
      selectedBuilding: {
        ...building,
        entity,
      },
    }
  }),
  withUrlQuery,
  withPropsOnChange(['urlQuery'], buildUrlQueryProps),
  withHandlers({
    handleKeyDown,
    handleZoomChange,
    setMarkersFromEvents,
    toggleMapOption,
  }),
  withHandlers({
    onAreaDelete,
  }),
  lifecycle({
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount,
  }),
  Radium
)(MapIndex)

function MapIndex(props) {
  const {
    fabMenuVisible,
    handleKeyDown,
    hasModulePermission,
    loading,
    mapOptionsVisible,
    markerFilters,
    onAreaClick,
    onAreaDelete,
    mapOptions,
    positionLabelMode,
    properties = stubObject,
    rightPanelView,
    selectedBuilding,
    setFabMenuVisibility,
    setLoading,
    setMarkerFilters,
    setMarkersFromEvents,
    setPositionLabelMode,
    setProperties,
    setRightPanelView,
    showEditGeoJsonLayer,
    showGraphLayer,
    showTemporaryMarker,
    timeline,
    timezone,
    toggleMapOption,
    urlId,
    urlResource,
    user = {},
  } = props

  const { bounds, center, mapTile, zoom } = properties

  const isEditingLayerEnabled = showEditGeoJsonLayer || showGraphLayer
  const doubleClickZoom = !isEditingLayerEnabled
  const showFabMenu = !loading && !showEditGeoJsonLayer && !showGraphLayer
  // NOTE this is temporary, we'll need to be smarter about this
  const showGeoJSON = zoom > 14 && !showEditGeoJsonLayer
  const showMarkers = !isEditingLayerEnabled
  const showMarkerTooltips = get(markerFilters, 'types.tooltip')
  const rightPanelOpen = !!rightPanelView
  const mapPositionStyle = rightPanelOpen ? styles.sideBarOpen : {}
  const timelineDisabled =
    isEditingLayerEnabled || !hasModulePermission('mapsHistorical', 'read')

  const mapContainerStyle = {
    ...styles.mapContainer,
    ...mapPositionStyle,
  }

  const { floor: selectedFloor = 0 } = selectedBuilding
  const floors = get(selectedBuilding, 'entity.floors', [])
  const floorIndex = findIndex(floors, floor => {
    return floor.level === selectedFloor
  })
  const image = get(selectedBuilding, `entity.floors[${floorIndex}].image`)
  const imageBounds = get(
    selectedBuilding,
    `entity.floors[${floorIndex}].bounds`
  )
  function imageOverlayClick() {
    emitter.emit('map:set-location', {
      id: selectedBuilding.id,
      geometry: selectedBuilding.entity.geometry,
      openSidebar: true,
    })
  }

  return (
    <Block onKeyDown={handleKeyDown}>
      <Toolbar
        selectedBuilding={selectedBuilding}
        onSelectSidePanel={setRightPanelView}
        position={center}
        rightPanelView={rightPanelView}
        setProperties={setProperties}
      />
      <Block
        dataTestId="maps-screen"
        position="absolute"
        top={60}
        right={0}
        bottom={timelineDisabled ? 0 : 60}
        left={0}
        zIndex={1}
        overflow="hidden"
      >
        <PanelLeft />
        <PanelRight
          handleClose={() => setRightPanelView('')}
          loading={loading}
          timeline={timeline}
          timezone={timezone}
          urlId={urlId}
          view={rightPanelView}
        />
        <div style={mapContainerStyle}>
          {!isEditingLayerEnabled && (
            <ConnectedMapControls
              mapTile={mapTile}
              mapOptions={mapOptions}
              onMapOptionChange={toggleMapOption}
            />
          )}
          <Map
            center={center}
            doubleClickZoom={doubleClickZoom}
            mapTile={mapTile}
            setProperties={setProperties}
            zoom={zoom}
          >
            <EventsHandlerLayer
              selectedBuilding={selectedBuilding}
              setLoading={setLoading}
            />
            {showGeoJSON && (
              <Geo
                buildingHasImage={image}
                isEditingLayerEnabled={isEditingLayerEnabled}
                isGraphLayerEnabled={showGraphLayer}
                mapOptions={mapOptions}
                onAreaClick={onAreaClick}
                onRemoveArea={onAreaDelete}
                positionLabelMode={positionLabelMode}
                selectedBuilding={selectedBuilding}
                setPositionLabelMode={setPositionLabelMode}
                setProperties={setProperties}
                urlId={urlId}
                urlResource={urlResource}
                zoom={zoom}
              />
            )}
            {image && imageBounds && (
              <ImageOverlay
                interactive={true}
                onClick={imageOverlayClick}
                bounds={[imageBounds.sw, imageBounds.ne]}
                key={selectedFloor}
                url={image}
              />
            )}
            {showMarkers && (
              <Markers
                bounds={bounds}
                center={center}
                loading={loading}
                showTooltips={showMarkerTooltips}
                timeline={timeline}
                urlId={urlId}
                urlResource={urlResource}
                zoom={zoom}
              />
            )}
            {showEditGeoJsonLayer && (
              <ConnectedEditGeoJsonLayer urlId={urlId} />
            )}
            {showGraphLayer && <ConnectedGraphLayer urlId={urlId} />}
            <TemporaryMarker />
          </Map>
          <IndoorNavigation building={selectedBuilding} />
          {!isEditingLayerEnabled && (
            <Absolute top={10} right={10} zIndex={400}>
              <GeojsonUploader />
            </Absolute>
          )}
          {showFabMenu && (
            <FabMenu
              enabled={showFabMenu}
              listVisible={fabMenuVisible}
              marginBottom={5}
              marginRight={45}
              setListVisibility={setFabMenuVisibility}
            />
          )}
        </div>
      </Block>
      {!timelineDisabled && (
        <Absolute bottom left right zIndex={1000}>
          <Timeline
            datepickerDisplay={'clock'}
            setLoading={setLoading}
            setMarkersFromEvents={setMarkersFromEvents}
            timezone={timezone}
          />
        </Absolute>
      )}
      {loading && <PageLoader />}
    </Block>
  )
}

function buildUrlQueryProps(props) {
  const {
    urlQuery: { action, id, resource },
  } = props

  const showEditGeoJsonLayer =
    resource === 'area' && action === 'edit-boundaries'
  const showGraphLayer =
    resource === 'area' && action === 'device-relationships'

  return {
    showEditGeoJsonLayer,
    showGraphLayer,
    // TODO: use connect to url query component and move these down into the
    // relevant components so these don't have to be passed in at the top level
    urlId: id,
    urlResource: resource,
  }
}

function componentDidMount() {
  const {
    areasLoading,
    applicationId,
    fetchAudits,
    fetchContentEntries,
    fetchContentTemplates,
    fetchTemplates,
    hasModulePermission,
    history,
    markerFilters,
    properties = {},
    setLoading,
    setMarkerFilters,
    setProperties,
    setSocketConnection,
  } = this.props

  const { mapTile = DEFAULT_MAP_TILE } = properties

  if (!hasModulePermission('maps', 'read')) {
    history.push('/reports')
    return
  }

  // init sockets
  const connection = socket.init({ applicationId })
  setSocketConnection(connection)
  setProperties({ mapTile })

  const perPage = 9999

  // TODO: We fetch all resources required for the
  // left panel area view but should remove this
  // when we move to a smarter caching/fetching strategy
  fetchAudits({ perPage })
  fetchContentTemplates({ perPage })
  fetchContentEntries({ perPage })
  fetchTemplates({ perPage })

  const types = markerFilters.types || DEFAULT_MARKER_FILTER_TYPES

  setMarkerFilters({ types })

  if (areasLoading === 'resolved') {
    setLoading(false)
  }
}

function componentWillUnmount() {
  const { socketConnection } = this.props

  if (socketConnection) {
    socketConnection.unsubscribe()
  }
}

function componentDidUpdate(prevProps) {
  const {
    areasLoading,
    handleZoomChange,
    properties = {},
    setMarkersFromEvents,
    setLoading,
    showEditGeoJsonLayer,
    socketConnection,
    timeline,
    toggleMapOption,
  } = this.props

  const { zoom } = properties

  const prevZoom = get(prevProps, 'properties.zoom')
  const hasZoomChanged = zoom !== prevZoom

  if (!prevProps.showEditGeoJsonLayer && showEditGeoJsonLayer) {
    toggleMapOption('geofences', true)
  }

  if (hasZoomChanged) {
    handleZoomChange()
  }

  if (timeline.isLive) {
    socketConnection.subscribe(setMarkersFromEvents)
  } else {
    socketConnection.unsubscribe()
  }

  if (prevProps.areasLoading === 'resolving' && areasLoading !== 'resolving') {
    setLoading(false)
  }
}

function handleKeyDown(props) {
  const { history } = props

  return event => {
    const key = event.key

    if (!key) return

    if (key === KEY_ESCAPE) {
      history.push({ search: '' })
    }
  }
}

function handleZoomChange(props) {
  const {
    selectedBuilding = {},
    setBuilding,
    properties: { zoom },
  } = props

  return () => {
    const shouldExitBuilding =
      selectedBuilding.entity && zoom < EXIT_BUILDING_ZOOM_THRESHOLD

    if (shouldExitBuilding) {
      setBuilding({})
    }
  }
}

// NOTE This is shared by both Locations and Geo module. We should rethink the
// structure to not require this at the top-level
function onAreaDelete(props) {
  return (areaId, includeSubAreas) => {
    const confirmationMessage = `Area you sure you want to remove this area ${
      includeSubAreas ? " AND all of it's sub areas?" : '?'
    }`

    if (!window.confirm(confirmationMessage)) {
      return console.error('Reject removal of areas')
    }

    return props
      .removeArea(areaId, {
        includeSubAreas,
      })
      .then(response => {
        const { data } = response

        if (!includeSubAreas || !data) return

        // NOTE because the API handles deleting all the sub-areas for us, we need
        // to clean up our cache with the response the server gives. We can do
        // that using `removeFromCache`
        each(data, deletedAreaId => {
          props.removeAreaFromCache(deletedAreaId)
        })
      })
  }
}

function setMarkersFromEvents(props) {
  const {
    fetchApplicationUsers,
    geometryPermissions,
    getUserFullName,
    setMarkers,
  } = props

  return events =>
    Promise.filter(
      events,
      event => event.type === 'enter' || event.type === 'geo'
    ).then(filteredEvents => {
      const userIds = map(filteredEvents, 'user')

      const userIdsToFetch = filter(userIds, userId => {
        const fullName = getUserFullName(userId)
        return !fullName || fullName === 'Unknown User'
      })

      const userPromise = !isEmpty(userIdsToFetch)
        ? fetchApplicationUsers({ perPage: 9999, user: userIdsToFetch })
        : Promise.resolve()

      return userPromise
        .then((response = {}) =>
          map(filteredEvents, event => {
            const {
              data,
              expiresAt,
              floorsRef,
              geo,
              timestamp,
              type,
              user: id,
            } = event

            const hasGeometryPermission = geometryPermissions
              ? booleanPointInPolygon(geo, geometryPermissions)
              : true

            if (!hasGeometryPermission) {
              console.debug('Event failed user geometry permissions', {
                geo,
                geometryPermissions,
              })
              return null
            }

            const responseData = response.data || []
            const user = find(responseData, ['user._id', id])
            // NOTE: as the state may not have been updated in the selector as of
            // yet we must check the response first and then fallback
            const label = (user && user.fullName) || getUserFullName(id)

            return {
              id,
              type: 'Feature',
              geometry: geo,
              properties: {
                expiresAt,
                floorsRef,
                label,
                movementType: data.movementType || {},
                search: label,
                timestamp,
                type: 'user',
              },
            }
          })
        )
        .then(markersResults => setMarkers(compact(markersResults)))
    })
}

function toggleMapOption(props) {
  const { mapOptions, setMapOptions } = props

  return (option, value) => {
    const newValue = value || !mapOptions[option]
    return setMapOptions({
      ...mapOptions,
      [option]: newValue,
    })
  }
}

function mapStateToProps(state) {
  const applicationUserSelectors = applicationUserModule.selectors(state)(
    'default'
  )
  const geometryPermissions = userApplicationsModule.geometryPermissions(state)
  const getUserFullName = applicationUserSelectors.getUserFullName

  return {
    applicationId: state.app.applicationId,
    areaCache: state.areas.cache,
    building: state.geo.building,
    geometryPermissions,
    getUserFullName,
    areasLoading: get(state, 'areas.list.all-locations.state'),
    markerFilters: get(state.geo, 'markers.filters', stubObject),
    properties: state.geo.properties,
    timeline: state.geo.timeline || stubObject,
    timezone: state.app.timezone,
    user: get(state, 'user.data'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationUsers: params =>
      dispatch(applicationUserModule.query('all', params)),
    fetchAudits: params => dispatch(audits.query('all', params)),
    fetchContentEntries: params =>
      dispatch(contentEntries.query('all', params)),
    fetchContentTemplates: params =>
      dispatch(contentTemplates.query('all', params)),
    fetchTemplates: params => dispatch(templates.query('all', params)),
    removeArea: (id, params) => dispatch(areaModule.remove(id, params)),
    removeAreaFromCache: id => dispatch(areaModule.removeFromCache(id)),
    setBuilding: opts => dispatch(geoModule.setBuilding(opts)),
    setMarkerFilters: filters => dispatch(geoModule.setMarkerFilters(filters)),
    setMarkers: markers => dispatch(geoModule.setMarkers(markers)),
    setProperties: options => dispatch(geoModule.setProperties(options)),
  }
}
