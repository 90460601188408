import { combineReducers } from 'redux'
import crud from '../../crud'
import hooks from './hooks'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

const resource = 'reports/locations'

export const actions = crud.actions(resource)
hooks(actions)

export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: 'reports-locations',
  paramsFn: applicationParamsFn,
})

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

export function baseUrlFn(queryParams = {}, state) {
  const { locationId } = queryParams
  const path = `${resource}/${locationId}`
  const params = applicationParamsFn(state, queryParams)
  return applicationResourceUrlFn(path)(params)
}
