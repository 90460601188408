import geocoder from '@lighthouse/geocoder'
import fetchPonyfill from 'fetch-ponyfill'
import Promise from 'bluebird'

const GEOCODER_HOST = 'https://nominatim.lighthouse.io/'
// TODO updated @Lighthouse/geocoder package at
// present we must pass an api key when not required
const GEOCODER_API_KEY = 'API_KEY'

const { fetch } = fetchPonyfill({ Promise })

const gc = geocoder({
  host: GEOCODER_HOST,
  apiKey: GEOCODER_API_KEY,
  fetch,
})

export default gc
