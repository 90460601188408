import PropTypes from 'prop-types'
import React from 'react'
import colors from 'config/theme/colors'

import { Block, Span } from 'components/common'

export default function ListBanner(props) {
  const { children } = props

  return (
    <Block
      backgroundColor={colors.blue.lightest}
      borderBottom={`1px solid ${colors.gray.lightest}`}
      height={30}
      lineHeight="30px"
      paddingLeft={10}
      width="100%"
    >
      <Span color={colors.blue.dark} fontSize={10}>
        {children}
      </Span>
    </Block>
  )
}

ListBanner.propTypes = {
  children: PropTypes.node,
}
