/*
 * Content Templates Module
 */

import { combineReducers } from 'redux'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../../app'

import crud from '../../../crud'
import hooks from './hooks'

const resource = 'content'
const subresource = 'templates'
const actionNamespace = `${resource}/${subresource}`
const statePath = `${resource}.${subresource}`

export { default as schema } from './schema'

export const actions = crud.actions(actionNamespace)
hooks(actions)

export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath,
  paramsFn: applicationParamsFn,
})

export const selectors = crud.selectors(statePath)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

export function baseUrlFn(params) {
  const baseUrl = applicationResourceUrlFn(`${resource}/${subresource}`)(params)

  return params.contentTemplateId ?
    `${baseUrl}/${params.contentTemplateId}` :
    `${baseUrl}`
}
