export function buildFetchUrl(url, options) {
  const {
    awsS3BaseUrl,
    cloudinaryBaseUrl,
    fit,
    height,
    width,
    quality,
  } = options

  const transformations = []
  let transformationsString = ''

  if (width) transformations.push(`w_${width.toString()}`)
  if (height) transformations.push(`h_${height.toString()}`)
  if (quality) transformations.push(`q_${quality.toString()}`)
  if (fit) transformations.push('c_fit')

  transformationsString = `${transformations.join(',')}/`

  const fetchUrl = `${cloudinaryBaseUrl}/${transformationsString}${awsS3BaseUrl}/${url}`

  return fetchUrl
}
