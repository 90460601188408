import { pure } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { Block } from 'components/common'

export default pure(ListContent)

function ListContent(props) {
  const { children } = props

  return <Block flexGrow={1}>{children}</Block>
}

ListContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
