import { Interval, Strategy, StrategyTypes } from '../scheduling.types'

import {
  getNext,
  getNextExactDateOfMonth,
  getNextLastDayOfMonth,
  getNextLastWeekdayOfMonth,
  getNextNoRepeat,
  getNextWeekday,
  getNextXDayOfXWeekOfMonth,
} from '../strategies'

export const strategyTypeMap = {
  [StrategyTypes.ExactDateOfMonth]: getNextExactDateOfMonth,
  [StrategyTypes.LastDayOfMonth]: getNextLastDayOfMonth,
  [StrategyTypes.LastWeekdayOfMonth]: getNextLastWeekdayOfMonth,
  [StrategyTypes.NoRepeat]: getNextNoRepeat,
  [StrategyTypes.Stopwatch]: getNext,
  [StrategyTypes.Weekdays]: getNextWeekday,
  [StrategyTypes.Window]: getNext,
  [StrategyTypes.XDayOfXWeekOfMonth]: getNextXDayOfXWeekOfMonth,
}

interface OccurrenceIntervalsGenerator {
  readonly isInitial: boolean
  readonly serviceInterval: Interval
  readonly strategy: Strategy
  readonly timezone: string
}

/**
 * Generates occurrence intervals for service intervals using strategy
 */
export function* occurrenceIntervalsGenerator(
  props: OccurrenceIntervalsGenerator
): IterableIterator<Interval> {
  const { isInitial, serviceInterval, strategy, timezone } = props
  const { options, type } = strategy

  const start: number = serviceInterval[0]
  const end: number = serviceInterval[1]

  const getNextFn = strategyTypeMap[type]

  if (!getNextFn) return

  yield* getNextFn({
    end,
    isInitial,
    start,
    options,
    timezone,
  })
}
