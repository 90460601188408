import { isEmpty, first, last } from 'lodash/fp'

import { hasValidStartAndEnd } from '.'
import { IntervalTypes, ScheduleIntervalsGenerator } from '../scheduling.types'
import {
  occurrenceIntervalsGenerator,
  serviceIntervalsGenerator,
} from '../generators'

/**
 * Generates non repeating schedule service and occurrence intervals
 */
export function* generateNonRepeatingSchedule(
  props: ScheduleIntervalsGenerator
) {
  const { end, serviceHours, start, strategy } = props

  const isStartAndEndValid = hasValidStartAndEnd({ end, start })
  if (!isStartAndEndValid) return []

  const serviceIntervalSequence = serviceIntervalsGenerator({
    end,
    serviceHours,
    start,
  })

  const { hours, timezone } = serviceHours
  const hasServiceHours = !isEmpty(hours)
  const intervals = [...serviceIntervalSequence]

  // NOTE: when non repeating and has service hours the service interval is
  // from the first and last service interval otherwise from the start and end
  const serviceIntervals = !hasServiceHours
    ? [[start, end]]
    : !isEmpty(intervals)
    ? [[first(intervals)[0], last(intervals)[1]]]
    : []

  for (const serviceInterval of serviceIntervals) {
    yield { interval: serviceInterval, type: IntervalTypes.Service }

    const occurrenceIntervalsSequence = occurrenceIntervalsGenerator({
      // NOTE: as we only have one interval we should always set isInitial to
      // true
      isInitial: true,
      serviceInterval,
      strategy,
      timezone,
    })

    for (const occurrenceInterval of occurrenceIntervalsSequence) {
      yield { interval: occurrenceInterval, type: IntervalTypes.Occurrence }
    }
  }
}
