import { compose, pure, withState } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'
import Spinner from 'components/spinner'
import { withTranslation } from 'react-i18next'

const styles = {
  border: 'none',
  flex: 1,
  fontSize: 14,
  minHeight: 40,
  outline: 'none',
  paddingLeft: '12px',
  width: '100%',
}

export default compose(
  pure,
  withTranslation()
  // withState('previousHighlightedIndex', 'setPreviousHighlightedIndex'),
)(SelectDropdownSearch)

function SelectDropdownSearch({
  color,
  getInputProps,
  handleBlur,
  highlightedIndex,
  inputValue = '',
  isLoading,
  itemCount,
  isSearching,
  placeholder,
  // previousHighlightedIndex,
  removeItem,
  selectedItems,
  setHighlightedIndex,
  // setPreviousHighlightedIndex,
  value,
  t,
}) {
  const inputProps = getInputProps({
    onBlur: () => handleBlur(value),
    onKeyDown({ key }) {
      if (key === 'Backspace' && !inputValue) {
        if (selectedItems.length > 0) {
          removeItem({ item: selectedItems[selectedItems.length - 1] })
        }
      }

      // NOTE it would be good to revive this at some point, but it broker with
      // the downshift v3 upgrade. I think to handle it we would need to store
      // our own state for highlightedIndex
      // if (key === 'Enter') {
      //   // NOTE we're using this to hold the currently highlighted index so we
      //   // can keep it selected after hitting enter. Downshift default behaviour
      //   // is to set the highlighted index to null but in the case of
      //   // multi-select we want to persist the highlighted index as the dropdown
      //   // doesn't close
      //   setPreviousHighlightedIndex(highlightedIndex)
      // }
    },
    onKeyUp({ key }) {
      // if (key === 'Enter') {
      //   // NOTE see onKeyUp above
      //   console.log({
      //     'previousHighlightedIndex': previousHighlightedIndex,
      //   })
      //   setHighlightedIndex(previousHighlightedIndex)
      // }

      if (highlightedIndex && highlightedIndex > itemCount) {
        setHighlightedIndex(0)
      }
    },
  })

  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      color={color}
      width="100%"
    >
      <input
        {...inputProps}
        autoFocus
        data-testid="dropdown-input-field"
        placeholder={placeholder || t('placeholder.searchOrSelect')}
        style={styles}
        value={inputValue}
      />
      {isLoading ||
        (isSearching && (
          <Flex paddingLeft="10px" paddingRight="10px">
            <Spinner size="small" type="dots" />
          </Flex>
        ))}
    </Flex>
  )
}

SelectDropdownSearch.propTypes = {
  color: PropTypes.string.isRequired,
  getInputProps: PropTypes.func.isRequired,
  inputValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  itemCount: PropTypes.number,
  removeItem: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  width: PropTypes.number,
}
