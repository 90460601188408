import Promise from 'bluebird'
import fetchPonyfill from 'fetch-ponyfill'
import { isBoolean } from 'lodash'
import parseLinkHeader from 'parse-link-header'

import 'moment-timezone'

import { findError, NetworkError } from '../errors'

const { fetch } = fetchPonyfill({ Promise })
const UNRESTRICTED_TIMEOUT_MS = 60000 // 1m
const RESTRICTED_TIMEOUT_MS = 30000 // 30s

export default async function request(url, opts = {}, helpers = {}) {
  // NOTE we want a longer timeout for optimistic requests and the redux-offline
  // module has it's own logic for retrying that we don't want to interfere
  // with. We use a long timeout that should be more than enough time for any
  // POST request to complete

  const timeout = opts.optimistic
    ? UNRESTRICTED_TIMEOUT_MS
    : opts.timeout
      ? opts.timeout
      : RESTRICTED_TIMEOUT_MS;

  // we always use json, so use default here
  opts.headers = opts.headers || {};
  opts.headers['Content-Type'] = 'application/json'

  const requiresAuthorization = isBoolean(helpers.requiresAuthorization)
    ? helpers.requiresAuthorization
    : true

  // NOTE Important: This request function is used in a few different places, so if the api changes here it's important to update everything upstream. Places to check:
  // - the request middleware in this repo
  // - the offlineEffectFn in store/configure in this repo
  // - any ad hoc request using this function in web/mobile clients
  const authorizationHeader = requiresAuthorization
        && helpers.getAuthorization
        && await helpers.getAuthorization()

  if (authorizationHeader) {
    opts.headers.authorization = authorizationHeader
  }

  return fetch(url, opts)
    .timeout(timeout)
    .catch(err => Promise.reject(new NetworkError(err)))
    .then((response) => {
      const contentType = response.headers.get('Content-Type');

      // handle json and non-json request, e.g. 204 no-content
      // https://github.com/agraboso/redux-api-middleware/blob/master/src/util.js#L14
      if (contentType && ~contentType.indexOf('json')) {
        return response.json().then(json => ({ json, response }))
      }

      // non json response
      return Promise.resolve({ response })
    })
    .then(({ json = {}, response }) => {
      // NOTE network errors will be thrown by the fetch api as a 'TypeError' and
      // can be handled by a `catch` in the consuming logic
      if (!response.ok) {
        const ResponseError = findError(response.status)
        const error = new ResponseError(json.error, response.status)
        return Promise.reject(error)
      }

      const totalCount = response.headers.get('Total-Count')
      const lastKey = response.headers.get('Last-Key')
      const linkHeader = response.headers.get('Link')
      const links = parseLinkHeader(linkHeader)

      return Promise.resolve({
        json,
        links,
        totalCount,
        lastKey,
      })
    })
}
