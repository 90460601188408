export default function fetch(url, { fit, height, width }) {
  const transformations = []
  let transformationsString = ''

  if (height) transformations.push(`h_${height.toString()}`)
  if (width) transformations.push(`w_${width.toString()}`)
  if (fit) transformations.push('c_fit')

  if (transformations.length > 0) {
    transformationsString = `${transformations.join(',')}/`
  }

  return `${process.env.CLOUDINARY_BASE_URL}/${transformationsString}${process.env.S3_UPLOADS_BASE_URL}/${url}`
}
