import { getModule } from '@lighthouse/sdk'
import { debounce } from 'lodash'
import { useEffect } from 'react'
import { Dispatch, useDispatch, useSelector } from 'react-redux'
import { Resource } from '../../../types/resource'

const templatesModule = getModule('templates')

const defaultFields = ['_id', 'name', 'type']

const debouncedQuery = debounce(
  (dispatch: Dispatch): Promise<object[]> =>
    dispatch(
      templatesModule.query('all', { fields: defaultFields, perPage: 9999 })
    ),
  2000,
  { leading: true }
)

export default function useTemplates(): Resource[] {
  const dispatch = useDispatch()

  useEffect(() => {
    debouncedQuery(dispatch)
  }, [dispatch])

  const templates: Resource[] = useSelector((state): Resource[] =>
    templatesModule
      .selectors(state)('all')
      .list()
  )

  return templates
}
