import { REQUEST } from '../../../middleware/request'
import { getRegionUrl, authorizationHeadersFn } from '../../app'

export const SAVE_REQUEST = 'lighthouse/template/SAVE_REQUEST'
export const SAVE_SUCCESS = 'lighthouse/template/SAVE_SUCCESS'
export const SAVE_ERROR = 'lighthouse/template/SAVE_ERROR'

export default function buildActionCreators(
  actions,
  applicationResourceUrlFn,
  paramsFn,
  headersFn
) {
  return {
    templatesByArea,
    lockRequest,
    unlockRequest,
  }

  function templatesByArea(areaId, params) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const baseUrl = applicationResourceUrlFn('areas')(params)
      const endpoint = `${baseUrl}/${areaId}/templates`
      const headers = headersFn(params, state)

      delete params.applicationId
      delete params.regionUrl

      return dispatch(templatesByAreaRequest(endpoint, headers, params))
    }
  }

  function templatesByAreaRequest(endpoint, headers, params) {
    return {
      [REQUEST]: {
        types: [
          actions.QUERY_REQUEST,
          actions.QUERY_SUCCESS,
          actions.QUERY_ERROR,
        ],
        endpoint,
        headers,
        query: params,
      },
    }
  }
  function lockRequest({ application, id }) {
    return (dispatch, getState) => {
      const state = getState()
      const regionUrl = getRegionUrl(state)
      const endpoint = `${regionUrl}/applications/${application}/templates/${id}/actions/lock`
      const headers = authorizationHeadersFn(null, state)
      return dispatch({
        [REQUEST]: {
          types: [SAVE_REQUEST, SAVE_SUCCESS, SAVE_ERROR],
          method: 'PUT',
          headers,
          endpoint,
        },
      })
    }
  }
  function unlockRequest({ application, id }) {
    return (dispatch, getState) => {
      const state = getState()
      const regionUrl = getRegionUrl(state)
      const endpoint = `${regionUrl}/applications/${application}/templates/${id}/actions/unlock`
      const headers = authorizationHeadersFn(null, state)
      return dispatch({
        [REQUEST]: {
          types: [SAVE_REQUEST, SAVE_SUCCESS, SAVE_ERROR],
          method: 'PUT',
          headers,
          endpoint,
        },
      })
    }
  }
}
