import moment from 'moment'

const minInMs = 60 * 1000
const hoursInMs = minInMs * 60
const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
const DATE_FORMAT = 'YYYY-MM-DD HH:mm'

export function mapSchemaToForm(serviceHours) {
  if (!serviceHours || !serviceHours.hours) {
    return { hours: [], timezone: 'utc' }
  }

  const { hours } = serviceHours

  const mStartOfWeek = moment().startOf('isoWeek')

  const parsedHours = hours.map(hour => {
    const { behaviour, end, start, type } = hour

    if (type === 'DEFAULT') {
      const open = moment(mStartOfWeek).add(start, 'milliseconds')
      const close = moment(mStartOfWeek).add(end, 'milliseconds')
      const reference = open.format('dddd').toLowerCase()

      return {
        close: close.toDate(),
        open: open.toDate(),
        reference,
      }
    }

    const endDatetimeString = moment.utc(end).format(DATE_FORMAT)
    const startDatetimeString = moment.utc(start).format(DATE_FORMAT)
    const open = new Date(startDatetimeString)
    const close = new Date(endDatetimeString)

    return {
      close,
      dateReference: open,
      open,
      reference: 'date',
      status: behaviour.toLowerCase(),
    }
  })

  return {
    ...serviceHours,
    hours: parsedHours,
  }
}

export function mapFormToSchema(serviceHours) {
  if (!serviceHours || !serviceHours.hours) return []

  const { hours } = serviceHours

  const definitions = hours.map(record => {
    const { dateReference, reference, status = 'open', open, close } = record
    const mOpen = moment(open)
    const mClose = moment(close)

    const isMatchingTime =
      mOpen.hours() === mClose.hours() && mOpen.minutes() === mClose.minutes()
    const isOpenAfterClose =
      mOpen.hours() > mClose.hours() ||
      (mOpen.hours() === mClose.hours() && mOpen.minutes() > mClose.minutes())
    const isOverHours = isMatchingTime || isOpenAfterClose

    if (reference === 'date') {
      const datetimeString = moment(dateReference).format(DATE_FORMAT)
      const utcDate = moment.tz(datetimeString, 'UTC')
      const start = moment(utcDate)
        .startOf('day')
        .add(mOpen.hours(), 'hours')
        .add(mOpen.minutes(), 'minutes')
      const end = moment(utcDate)
        .startOf('day')
        .add(mClose.hours(), 'hours')
        .add(mClose.minutes(), 'minutes')

      if (isOverHours) {
        end.add(1, 'day')
      }

      return {
        behaviour: status.toUpperCase(),
        description: `${formatDescription(start)} - ${formatDescription(end)}`,
        duration: end.valueOf() - start.valueOf(),
        end: end.valueOf(),
        start: start.valueOf(),
        type: 'OVERRIDE',
      }
    }

    const dayNumber = daysOfWeek.indexOf(reference)
    const dayOffset = dayNumber * 24 * hoursInMs
    const closeDay = daysOfWeek[(dayNumber + (isOverHours ? 1 : 0)) % 7]

    return {
      description: `${formatDescription(
        mOpen,
        daysOfWeek[dayNumber]
      )} - ${formatDescription(mClose, closeDay)}`,
      end:
        dayOffset +
        (isOverHours ? 24 * hoursInMs : 0) +
        mClose.hours() * hoursInMs +
        mClose.minutes() * minInMs,
      start: dayOffset + mOpen.hours() * hoursInMs + mOpen.minutes() * minInMs,
      type: 'DEFAULT',
    }
  })
  return definitions
}

export const formatDescription = (mDate, weekday) => {
  return `${
    weekday
      ? weekday.slice(0, 3).toUpperCase()
      : mDate.format('ddd').toUpperCase()
  } ${mDate.format('HH:mm')}`
}
