import { getModule } from '@lighthouse/sdk'
import { get } from 'lodash'
import {
  compose,
  lifecycle,
  onlyUpdateForKeys,
  withHandlers,
  withProps,
  withState,
} from 'recompose'
import React, { Fragment } from 'react'
import { withLeaflet } from 'react-leaflet'
import { connect } from 'react-redux'

import emitter from 'utils/emitter'
import Clusters from './components/clusters'
import Popup from './components/popup'

const geoModule = getModule('geo')

const MIN_ZOOM = 3

export default compose(
  connect(mapStateToProps),
  withLeaflet,
  withProps(props => {
    const { bounds = [-180, -90, 180, 90], clustersSelector, zoom } = props
    const { clusters, bounds: initialBounds } = clustersSelector()

    return {
      clusterInstance: clusters,
      clusters: clusters && clusters.getClusters(bounds, zoom),
      initialBounds,
    }
  }),
  withState('activePopup', 'setActivePopup', null),
  withState('activeTooltip', 'setActiveTooltip', null),
  onlyUpdateForKeys([
    'activePopup',
    'activeTooltip',
    'clusters',
    'loading',
    'showTooltips',
    'timezone',
    'urlId',
    'zoom',
  ]),
  withHandlers({
    handleClosePopup,
    handleOpenPopup,
    handleOpenTooltip,
    handleMapClick,
    handleMapZoom,
  }),
  lifecycle({ componentDidMount, componentDidUpdate })
)(Markers)

function Markers(props) {
  const {
    activePopup,
    activeTooltip,
    clusterInstance,
    clusters,
    form,
    filters,
    loading,
    setActiveTooltip,
    setActivePopup,
    showTooltips,
    timezone,
    urlId,
    zoom = MIN_ZOOM,
  } = props

  return (
    <Fragment>
      <Clusters
        activeTooltip={activeTooltip}
        clusterInstance={clusterInstance}
        clusters={clusters}
        loading={loading}
        showTooltips={showTooltips}
        timezone={timezone}
        urlId={urlId}
        zoom={zoom}
      />
      <Popup
        activePopup={activePopup}
        clusterInstance={clusterInstance}
        clusters={clusters}
        loading={loading}
        timezone={timezone}
        zoom={zoom}
      />
    </Fragment>
  )
}

/**
 * Lifecycle
 */

function componentDidMount() {
  const {
    leaflet,
    handleMapClick,
    handleMapZoom,
    handleClosePopup,
    handleOpenPopup,
    handleOpenTooltip,
  } = this.props

  emitter.on('markers:closePopup', handleClosePopup)
  emitter.on('markers:openPopup', handleOpenPopup)
  emitter.on('markers:openTooltip', handleOpenTooltip)

  leaflet.map.on('click', handleMapClick)
  leaflet.map.on('zoom', handleMapZoom)
}

function componentDidUpdate(prevProps) {
  const { center, clusters = [], initialBounds, leaflet, loading } = this.props

  if (!center && clusters.length && !loading && prevProps.loading) {
    const ne = [initialBounds[1], initialBounds[0]]
    const sw = [initialBounds[3], initialBounds[2]]
    leaflet.map.fitBounds([ne, sw], {
      maxZoom: 16,
    })
  }
}

function handleOpenTooltip(props) {
  const { activeTooltip, setActivePopup, setActiveTooltip } = props

  return markerId => {
    const isTooltipEnabled = activeTooltip === markerId

    const nextActiveTooltip = isTooltipEnabled ? null : markerId

    setActivePopup(null)
    setActiveTooltip(nextActiveTooltip)
  }
}

function handleOpenPopup(props) {
  const { activePopup, setActivePopup, setActiveTooltip } = props

  return cluster => {
    const clusterId = cluster.properties.id || cluster.id
    const isPopupEnabled = activePopup === clusterId

    const nextActivePopup = isPopupEnabled ? null : cluster

    setActivePopup(nextActivePopup)
    setActiveTooltip(null)
  }
}

function handleClosePopup(props) {
  const { setActivePopup } = props
  return () => setActivePopup(null)
}

function handleMapClick(props) {
  const { setActivePopup, setActiveTooltip } = props

  return () => {
    setActivePopup(null)
    setActiveTooltip(null)
  }
}

function handleMapZoom(props) {
  const { setActivePopup, setActiveTooltip } = props

  return () => {
    setActivePopup(null)
    setActiveTooltip(null)
  }
}

function mapStateToProps(state) {
  const { timezone } = state.app

  // NOTE: fallback to highest zoom level when no value defined
  // so that we cluster with the greatest max distance initially
  const zoom = get(state, 'geo.properties.zoom', 0)
  const clustersSelector = geoModule.selectors.clusters(state)

  return {
    clustersSelector,
    timezone,
    zoom,
  }
}
