import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import { Block, Flex } from 'components/common'
import { colors } from 'config/theme'

import ButtonIcon from 'components/button-icon'
import Icon from 'components/icon'

const s3BaseUrl = process.env.S3_UPLOADS_BASE_URL

const iconExtensionMap = {
  doc: 'file-doc',
  docx: 'file-doc',
  pdf: 'file-pdf',
  txt: 'file-txt',
}

const styles = {
  small: {
    color: colors.gray.light,
  },
}

export default function File(props) {
  const { input = {}, handleRemove, readOnly, ...styleProps } = props

  const value = input.value || {}

  const { extension, name, path, size = 0 } = value

  const border = !readOnly ? '1px solid #eee' : null

  const hoverBackgroundColor = readOnly ? colors.gray.lightest : null

  const icon = iconExtensionMap[extension] || 'file'

  const sizeInMbs = (size / 1000000).toFixed(1)
  const sizeInKbs = (size / 1000).toFixed(1)

  const fileSize = sizeInMbs < 1 ? `${sizeInKbs}kb` : `${sizeInMbs}mb`

  const marginBottom = readOnly ? '0px' : '10px'

  const file = (
    <Flex
      backgroundColor={colors.white}
      border={border}
      borderRadius="4px"
      hoverBackgroundColor={hoverBackgroundColor}
      marginBottom={marginBottom}
      padding="10px"
      {...styleProps}
    >
      {readOnly && (
        <Block alignSelf="center" paddingRight="10px">
          <Icon name={icon} fontSize={26} lineHeight={1} />
        </Block>
      )}
      <Flex flexDirection="column" flexGrow={1}>
        {readOnly ? (
          <Fragment>
            {name}
            <small style={styles.small}>{fileSize}</small>
          </Fragment>
        ) : (
          `${name}.${extension}`
        )}
      </Flex>
    </Flex>
  )

  const href = readOnly ? `${s3BaseUrl}/${path}` : null

  return (
    <Block position="relative">
      {!readOnly && (
        <Block
          onClick={handleRemove}
          marginRight="-10px"
          marginTop="-5px"
          position="absolute"
          right={0}
        >
          <ButtonIcon backgroundColor={colors.red.normal} marginRight="0px">
            <Icon
              name="neutral"
              color={colors.white}
              fontSize={12}
              fontWeight={600}
              lineHeight={1}
            />
          </ButtonIcon>
        </Block>
      )}
      <a href={href} target="_blank" rel="noopener noreferrer">
        {file}
      </a>
    </Block>
  )
}

File.propTypes = {
  input: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}
