import moment from 'moment-timezone'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm'

/**
 * Accepts a timestamp and returns the same timestamp but in the timezone
 * provided
 *
 * convertToTimezone(1561248000000, 'Australia/Melbourne') // 2019-06-23T00:00:00+00:00
 * returns 1561212000000 // 2019-06-23T00:00:00+10:00
 */
export function convertToTimezone(timestamp: number, timezone: string): moment {
  const datestring: string = moment.utc(timestamp).format(DATE_FORMAT)
  const mTimestamp: number = moment.tz(datestring, timezone).valueOf()
  return mTimestamp
}
