import { find, get } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getModule } from '@lighthouse/sdk'
import { Span } from 'components/common'

const applicationUserModule = getModule('applicationUsers')

export default compose(connect(mapStateToProps))(UserFullName)

function UserFullName(props) {
  const { cache, getUserFullName, showIdentifier = false, userId } = props

  if (!userId) return null

  const applicationUser = find(cache, ['entity.user._id', userId])

  const email = get(applicationUser, 'entity.email')
  const fullName = getUserFullName(userId)
  const username = get(applicationUser, 'entity.username')

  const identifier = showIdentifier ? ` (${username || email})` : ''

  return <Span>{`${fullName || 'Unknown User'}${identifier}`}</Span>
}

UserFullName.propTypes = {
  cache: PropTypes.object,
  getUserFullName: PropTypes.func.isRequired,
  showIdentifier: PropTypes.bool,
  userId: PropTypes.string,
}

function mapStateToProps(state) {
  const applicationUserSelectors = applicationUserModule.selectors(state)(
    'default'
  )

  return {
    cache: applicationUserSelectors.cache(),
    getUserFullName: applicationUserSelectors.getUserFullName,
  }
}
