// import compose from 'recompose/compose'
import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'
import { every, map, isEmpty } from 'lodash'

import FilterTag from 'components/filter-next/filter-tag'

import styles from './styles'
import { withTranslation } from 'react-i18next'

export default compose(
  withHandlers({
    handleClear,
  }),
  withTranslation(),
  Radium
)(FilterTags)

function FilterTags(props) {
  const { handleClear, onClearAll, tags, t } = props

  const noFiltersSet = every(tags, isEmpty)

  return (
    <div style={styles.root}>
      {map(tags, (tag, i) => (
        <FilterTag key={i} onClear={() => handleClear(tag)} tag={tag} />
      ))}
      {!noFiltersSet && (
        <div style={styles.clearFilters} onClick={onClearAll}>
          {t('button.clearFilters')}
        </div>
      )}
    </div>
  )
}

FilterTags.propTypes = {
  handleClear: PropTypes.func.isRequired,
  handleClearAll: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
}

function handleClear(props) {
  return tag => {
    props.onClear({ category: tag.category.id, value: tag.value.id })
  }
}
