import { forEach, isEmpty, reduce, sortBy } from 'lodash/fp'

import { Interval } from '../scheduling.types'
import {
  intervalCovers,
  intervalIntersectsEnd,
  intervalIntersectsStart,
  intervalOutside,
  intervalWithin,
} from '.'

/**
 * Applies closed intervals to intervals
 * Accepts two sets of intervals and splits the first set by the second set
 *
 * splitIntervals([[1,10], [11,20], [21,29]], [[8,13]])
 * returns [[1,8], [13,20], [21,29]]
 */
export function splitIntervals(
  intervals: Array<Interval> = [],
  closeIntervals: Array<Interval> = []
): Array<Interval> {
  if (isEmpty(closeIntervals)) return intervals

  const sortedIntervals = sortBy(a => a[0])(intervals)

  const stack = reduce(
    (accum, openInterval) => {
      accum.push(openInterval)

      forEach(
        (closeInterval: Interval): void => {
          const topInterval = accum[accum.length - 1]

          const closeCoversInterval = intervalCovers(closeInterval, topInterval)
          const closeIntersectsIntervalEnd = intervalIntersectsEnd(
            closeInterval,
            topInterval
          )
          const closeIntersectsIntervalStart = intervalIntersectsStart(
            closeInterval,
            topInterval
          )
          const closeOutsideInterval = intervalOutside(
            closeInterval,
            topInterval
          )
          const closeWithinInterval = intervalWithin(closeInterval, topInterval)

          if (closeOutsideInterval) return

          accum.pop()

          if (closeCoversInterval) return
          if (closeWithinInterval)
            accum.push(
              [topInterval[0], closeInterval[0]],
              [closeInterval[1], topInterval[1]]
            )
          if (closeIntersectsIntervalStart)
            accum.push([closeInterval[1], topInterval[1]])
          if (closeIntersectsIntervalEnd)
            accum.push([topInterval[0], closeInterval[0]])
        }
      )(closeIntervals)

      return accum
    },
    [],
    sortedIntervals
  )

  return stack
}
