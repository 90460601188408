import { compose, withState } from 'recompose'
import { imageNotFound } from '@lighthouse/common'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'
import Spinner from 'components/spinner'

export default compose(
  withState('error', 'setError'),
  withState('isLoading', 'setLoading', true)
)(Image)

function Image(props) {
  const {
    alt,
    isLoading,
    error,
    maxWidth,
    src,
    setError,
    setLoading,
    width,
  } = props

  if (!isLoading && !error) {
    return <img alt={alt} src={src} style={{ maxWidth }} width={width} />
  }

  if (error) {
    return <img alt="Unable to load" src={imageNotFound} width={width} />
  }

  return (
    <Flex>
      <img
        alt={alt}
        height={0}
        onError={error => setError(error)}
        onLoad={() => setLoading(false)}
        src={src}
        width={0}
      />
      <Spinner type="dots" size="small" />
    </Flex>
  )
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  maxWidth: PropTypes.number,
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  src: PropTypes.string,
  width: PropTypes.number.isRequired,
}
