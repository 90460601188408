import {
  compose,
  lifecycle,
  pure,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose'
import {
  filter,
  find,
  forEach,
  includes,
  isFunction,
  reject,
  slice,
  take,
} from 'lodash'
import { withSafeTimeout } from '@hocs/safe-timers'
import cuid from 'cuid'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'

const PROP_TYPES = {
  children: PropTypes.func.isRequired,
  emitter: PropTypes.object.isRequired,
}

export default compose(
  pure,
  setPropTypes(PROP_TYPES),
  withSafeTimeout,
  withState('notifications', 'setNotifications', []),
  withHandlers({ removeNotification, removeNotificationsByTag }),
  withHandlers({ addNotification }),
  lifecycle({ componentDidMount, componentWillUnmount })
)(Notifications)

function Notifications(props) {
  const {
    addNotification,
    children,
    notifications,
    removeNotification,
    removeNotificationsByTag,
  } = props

  return (
    <Flex>
      {children({
        addNotification,
        notifications,
        removeNotification,
        removeNotificationsByTag,
      })}
    </Flex>
  )
}

function addNotification(props) {
  const {
    notifications,
    setSafeTimeout,
    setNotifications,
    removeNotification,
  } = props

  return notification => {
    const notificationsToKeep = take(notifications, 4)
    const notificationsToRemove = slice(notifications, 4)

    // NOTE clear timeout for notifications we are removing
    forEach(notificationsToRemove, notification => {
      if (isFunction(notification.clearTimeout)) {
        notification.clearTimeout()
      }
    })

    const newNotification = {
      createdAt: moment.now(),
      duration: 10000,
      priority: 0,
      ...notification,
      id: cuid(),
    }

    // NOTE add clearTimeout to notification so
    // we can remove the timeout if needed
    newNotification.clearTimeout = setSafeTimeout(
      () => removeNotification(newNotification.id),
      newNotification.duration
    )

    setNotifications([newNotification, ...notificationsToKeep])
  }
}

function componentDidMount() {
  const {
    addNotification,
    emitter,
    removeNotification,
    removeNotificationsByTag,
  } = this.props

  emitter.on('notification:add', addNotification)
  emitter.on('notification:remove', removeNotification)
  emitter.on('notification:removeByTag', removeNotificationsByTag)
}

function componentWillUnmount() {
  const {
    addNotification,
    emitter,
    removeNotification,
    removeNotificationsByTag,
  } = this.props

  emitter.off('notification:add', addNotification)
  emitter.off('notification:remove', removeNotification)
  emitter.off('notification:removeByTag', removeNotificationsByTag)
}

function removeNotification(props) {
  const { notifications, setNotifications } = props

  return id => {
    const notification = find(notifications, { id })
    const nextNotifications = reject(notifications, { id })

    if (isFunction(notification.clearTimeout)) notification.clearTimeout()

    setNotifications(nextNotifications)
  }
}

function removeNotificationsByTag(props) {
  const { notifications, setNotifications } = props

  return tag => {
    const nextNotifications = reject(notifications, notification => {
      const hasTag = includes(notification.tags, tag)

      if (hasTag && isFunction(notification.clearTimeout)) {
        notification.clearTimeout()
      }

      return hasTag
    })

    setNotifications(nextNotifications)
  }
}
