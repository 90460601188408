import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'
import moment from 'moment'
import styles from './styles'
import DateTimePicker from '../../date-time-picker-deprecated'
import Icon from 'components/icon'

import { isPlainObject } from 'lodash'
import compose from 'recompose/compose'
import { withTranslation } from 'react-i18next'

class DateTime extends Component {
  static propTypes = {
    minWidth: PropTypes.number,
  }

  isRange() {
    return isPlainObject(this.props.value)
  }

  render() {
    const {
      allowAllDates,
      autoClose,
      customInputStyles = {},
      customLabelStyles = {},
      dateFormat,
      disabled,
      disablePastDates,
      disableTimepicker,
      input = {},
      minWidth,
      marginRight,
      maxDate,
      maxRange,
      minDate,
      numberOfMonths = 2,
      onChange,
      selectableRange,
      value,
      t,
    } = this.props

    // input.onChange is required for redux-form
    const handleChange = input.onChange || onChange
    const inputValue = input.value || value
    const rootStyles = {}
    const inputStyles = {}

    if (marginRight) {
      rootStyles.marginRight = marginRight
    }

    if (minWidth) {
      inputStyles.minWidth = minWidth
    }

    const label = this.isRange.bind(this)()
      ? formatRangeAsText(inputValue, t)
      : formatDateAsText(inputValue, null, null, dateFormat)

    return (
      <div style={rootStyles}>
        <DateTimePicker
          allowAllDates={allowAllDates}
          autoClose={autoClose}
          disabled={disabled}
          disablePastDates={disablePastDates}
          disableTimepicker={disableTimepicker}
          numberOfMonths={numberOfMonths}
          maxDate={maxDate}
          maxRange={maxRange}
          minDate={minDate}
          onChange={handleChange}
          position={'br'}
          selectableRange={selectableRange}
          value={inputValue}
        >
          <div style={[styles.input, inputStyles, customInputStyles]}>
            <span style={[styles.inputLabel, customLabelStyles]}>{label}</span>
            <Icon name="calendar" theme={styles.icon} />
          </div>
        </DateTimePicker>
      </div>
    )
  }
}

export default compose(withTranslation(), Radium)(DateTime)

function formatRangeAsText(range, t) {
  const { from, to } = range
  let showTime = true

  if (!from && !to) {
    return t('placeholder.selectDates')
  }

  if (
    moment(from).hour() === 0 &&
    moment(from).minute() === 0 &&
    moment(to).hour() === 23 &&
    moment(to).minute() === 59
  ) {
    showTime = false
  }

  const fromLabel = formatDateAsText(from, 'Beginning of Time', showTime)
  const toLabel = formatDateAsText(to, 'Now', showTime)
  return `${fromLabel} - ${toLabel}`
}

function formatDateAsText(date, fallbackText, showTime, customFormat) {
  let format = customFormat || 'MMM D'

  if (showTime) {
    format = 'h:mmA MMM D'
  }

  return date ? moment(date).format(format) : fallbackText
}
