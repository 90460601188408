import React from 'react'

export default function Anchor(props) {
  const { children, href, onClick, tabIndex, target, ...style } = props

  return (
    <a
      href={href}
      onClick={onClick}
      tabIndex={tabIndex}
      style={style}
      target={target}
    >
      {children}
    </a>
  )
}
