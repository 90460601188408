import Color from 'color'
import { mapValues } from 'lodash'

const green = {
  normal: Color('#56AD56'),
  lightest: Color('#56AD56')
    .clone()
    .lightness(97),
  lighter: Color('#56AD56')
    .clone()
    .lightness(85),
  light: Color('#56AD56')
    .clone()
    .lightness(48),
  dark: Color('#56AD56')
    .clone()
    .lightness(35),
}

const blue = {
  normal: Color('#00AAE4'),
  lightest: Color('#00AAE4')
    .clone()
    .lightness(97),
  lighter: Color('#00AAE4')
    .clone()
    .lightness(85),
  light: Color('#00AAE4')
    .clone()
    .lightness(48),
  dark: Color('#00AAE4')
    .clone()
    .lightness(35),
}

const yellow = {
  normal: Color('#FFD347'),
  lightest: Color('#FFD347')
    .clone()
    .lightness(97),
  lighter: Color('#FFD347')
    .clone()
    .lightness(85),
  light: Color('#FFD347')
    .clone()
    .lightness(48),
  dark: Color('#FFD347')
    .clone()
    .lightness(35),
}

const red = {
  normal: Color('#D0021B'),
  lightest: Color('#D0021B')
    .clone()
    .lightness(98),
  lighter: Color('#D0021B')
    .clone()
    .lightness(85),
  light: Color('#D0021B')
    .clone()
    .lightness(48),
  dark: Color('#D0021B')
    .clone()
    .lightness(35),
}

const orange = {
  normal: Color('#FF8C2F'),
  lightest: Color('#FF8C2F')
    .clone()
    .lightness(97),
  lighter: Color('#FF8C2F')
    .clone()
    .lightness(85),
  lighterText: Color('#FF8C2F')
    .clone()
    .lightness(65),
  light: Color('#FF8C2F')
    .clone()
    .lightness(48),
  dark: Color('#FF8C2F')
    .clone()
    .lightness(35),
}

const lime = {
  normal: Color('#A1D370'),
  lightest: Color('#A1D370')
    .clone()
    .lightness(97),
  lighter: Color('#A1D370')
    .clone()
    .lightness(85),
  light: Color('#A1D370')
    .clone()
    .lightness(48),
  dark: Color('#A1D370')
    .clone()
    .lightness(35),
}

const pink = {
  normal: Color('#F6627E'),
  lightest: Color('#F6627E')
    .clone()
    .lightness(97),
  lighter: Color('#F6627E')
    .clone()
    .lightness(85),
  light: Color('#F6627E')
    .clone()
    .lightness(48),
  dark: Color('#F6627E')
    .clone()
    .lightness(35),
}

const black = Color('#000')
const white = Color('#FFF')

// These gray ratios are stolen from TWBS
// https://github.com/twbs/bootstrap/blob/master/less/variables.less
// Also not we are using clone as to not mutate the color
// object. The API for the color module is changing to support
// immutable objects by default however, after which this can
// be updated: https://github.com/harthur/color/issues/56
const gray = {
  darker: Color('#222'),
  dark: Color('#444'),
  normal: Color('#545454'),
  light: Color('#AAA'),
  lighter: Color('#EBEBEB'),
  lightest: Color('#F6F6F6'),
  white: Color('#FBFBFB'),
}

export default processColors({
  black,
  blue,
  gray,
  green,
  lime,
  orange,
  pink,
  red,
  yellow,
  white,
})

/**
 * Small helper function to convert all our color objects
 * into strings for the style attr. We could leave them
 * as color objects, but I think it's more convenient to
 * have a usable string instead of having to use .hexString()
 * or hslString() in components. To convert the color again
 * you can re-wrap in the color object which is preferable
 * anyway as it follows an immutable pattern
 */
function processColors(obj) {
  return mapValues(obj, colorObj => {
    // if sub object, iterate through wrapped fn
    if (!colorObj.values) {
      return processColors(colorObj)
    }
    // not sure hex string is what we want to return so we could
    // change this in future to HSL, RGB
    return colorObj.hexString()
  })
}
