import 'moment-timezone'
import { isEmpty, map } from 'lodash'
import React, { Component } from 'react'
import moment from 'moment'

import { Block } from 'components/common'

import Select from '../select'

// eslint-disable-next-line react/prefer-stateless-function
export default class TimeZoneSelect extends Component {
  render() {
    const {
      className,
      clearable,
      disabled,
      error,
      multi,
      onBlur,
      onChange,
      placeholder,
      simpleValue,
      readOnly,
      required,
      value,
    } = this.props

    const zones = moment.tz.names()

    if (readOnly) {
      return <Block padding={10}>{isEmpty(value) ? '' : value}</Block>
    }

    const classes = error ? `${className}, error` : className
    const options = map(zones, zone => ({
      label: zone,
      value: zone,
    }))

    return (
      <Select
        className={classes}
        clearable={clearable}
        disabled={disabled}
        multi={multi}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        simpleValue={simpleValue}
        required={required}
        value={value}
      />
    )
  }
}
