import React from 'react'
import { CellRendererParams } from './simpleTable.types'

const BodyRow = ({ columns, rowData, rowIndex, rowStyle }): JSX.Element => {
  return (
    <div style={{ display: 'flex', ...rowStyle }}>
      {columns.map((column, columnIndex) => {
        const { flex, cellStyle = {}, cellRenderer, dataKey } = column.props
        const cellParams: CellRendererParams = {
          rowData,
          rowIndex,
          cellData: rowData[dataKey],
        }
        return (
          <div
            style={{ flex, ...cellStyle }}
            key={`Row-${rowIndex}-Column-${columnIndex}`}
          >
            {cellRenderer(cellParams)}
          </div>
        )
      })}
    </div>
  )
}

export default BodyRow
