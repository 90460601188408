import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'

import ResetPassword from './reset-password'
import ResetPasswordRequest from './reset-password-request'

export default function ResetPasswordRoute() {
  return (
    <Switch>
      <Route
        component={ResetPasswordRequest}
        exact
        path="/reset-password/request"
      />
      <Route
        component={ResetPassword}
        exact
        path="/reset-password/:resetPasswordToken"
      />
      <Redirect to="/login" />
    </Switch>
  )
}
