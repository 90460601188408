import { registerPosthook } from 'redux-hook-middleware'

import { SEND_TRACKING } from '../../../../constants'

import {
  markAsReadErrorDataFn,
  markAsReadSuccessDataFn,
} from '../tracking'

export default function hooks(actions) {
  registerPosthook(actions.MARK_AS_READ_ERROR, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: markAsReadErrorDataFn,
      hookedAction: action,
    })
  })

  registerPosthook(actions.MARK_AS_READ_SUCCESS, (store, action) => {
    store.dispatch({
      type: SEND_TRACKING,
      trackingFn: markAsReadSuccessDataFn,
      hookedAction: action,
    })
  })
}
