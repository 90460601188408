import { get } from 'lodash'

// Functions re-used between login and login-callback.

const PERMISSION_ERROR =
  'You do not have permission to access the web console. Please contact your administrator'

// withHandlers pass in props and returns a handler that closures over props.
function authenticationSuccessHandler(props) {
  const {
    applicationId,
    clearAuthentication,
    hasModulePermission,
    history,
    location,
    setPermissionError,
    unauthenticate,
  } = props

  const getLandingPagePath = () => {
    if (hasModulePermission('maps', 'read')) {
      return '/'
    }

    if (hasModulePermission('event', 'read')) {
      return '/reports/events'
    }

    // NOTE default landing page that all users can access regardless
    return '/settings/profile'
  }

  return async () => {
    const hasWebPermission = hasModulePermission('web', 'read')

    if (!hasWebPermission) {
      setPermissionError(PERMISSION_ERROR)

      // NOTE With legacy auth we clear authentication, but with OIDC we want to
      // maintain the session, even if they don't have web console access
      if (clearAuthentication) {
        await clearAuthentication()
      }

      return
    }

    const nextPathname = getLandingPagePath()

    return history.push(nextPathname)
  }
}

module.exports = {
  authenticationSuccessHandler,
}
