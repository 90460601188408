import colors from 'config/theme/colors'

export default {
  root: {
    display: 'flex',
    background: colors.gray.lightest,
    height: '60px',
    width: '100%',
    WebkitAppRegion: 'drag',
    position: 'absolute',
  },
  logo: {
    borderRight: `1px solid ${colors.gray.lighter}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoCustom: {
    padding: '0 10px',
    minWidth: '50px',
    maxWidth: '150px',
  },
  logoHelp: {
    display: 'inline',
    fontSize: '32px',
    lineHeight: '60px',
    padding: '0 20px',
  },
  logoImg: {
    maxWidth: 150,
    width: '100%',
    height: 'auto',
  },
  lighthouse: {
    display: 'inline',
    lineHeight: '60px',
    fontSize: '40px',
    color: colors.blue.normal,
    padding: '0 12px',
  },
  nav: {
    display: 'flex',
  },
  navRight: {
    marginLeft: 'auto',
  },
  navItem: {
    borderLeft: `1px solid ${colors.gray.lighter}`,
    color: colors.blue.normal,
    ':hover': {
      backgroundColor: colors.gray.lighter,
      color: colors.gray.dark,
    },
  },
  pendoNavItem: {
    borderLeft: `1px solid ${colors.gray.lighter}`,
    color: colors.blue.normal,
    ':hover': {
      backgroundColor: colors.gray.lighter,
      color: colors.gray.dark,
    },
    position: 'relative',
  },
}
