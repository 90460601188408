import { includes } from 'lodash'
import { getModule } from '@lighthouse/sdk'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { change, Field } from 'redux-form'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { InputTime } from 'components/form'

import { colors, typography } from 'config/theme'
import { DAY_DATE_OPTIONS, BEHAVIOUR_OPTIONS } from '../../constants'

import { Block, Flex, Span } from 'components/common'
import { DateTime, Select } from 'components/form'
import { TableBody, TableRow } from 'components/table-basic'
import Button from 'components/button'
import ServiceHourCell from '../service-hour-cell'
import Icon from 'components/icon'
import { useTranslation } from 'react-i18next'

const { schema } = getModule('serviceHours')

const CUSTOM_INPUT_STYLE = {
  maxHeight: 'inherit',
  paddingBottom: 12,
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 12,
}
const CUSTOM_LABEL_STYLE = { paddingRight: 0 }

export default compose(connect(null, mapDispatchToProps))(ServiceHourRow)

function ServiceHourRow({ fields, meta, timezone }) {
  const { error = [] } = meta
  const { t } = useTranslation()

  return (
    <TableBody>
      {fields.map((row, index) => {
        const { reference, open, close } = fields.get(index)
        const showDateComponent = reference === 'date'
        const showTimeError = includes(error, index)
        const mOpen = moment(open)
        const mClose = moment(close)

        const isMatchingTime =
          mOpen.hours() === mClose.hours() &&
          mOpen.minutes() === mClose.minutes()
        const isOpenAfterClose =
          mOpen.hours() > mClose.hours() ||
          (mOpen.hours() === mClose.hours() &&
            mOpen.minutes() > mClose.minutes())
        const showPlusOne = isMatchingTime || isOpenAfterClose

        return (
          <TableRow key={index}>
            <ServiceHourCell height={80}>
              <Flex>
                <Block flexGrow={1}>
                  <Field
                    dataTestId={`date-row:${index}`}
                    {...schema.reference}
                    clearable={false}
                    component={Select}
                    options={DAY_DATE_OPTIONS}
                    name={`${row}.reference`}
                    style={{ marginBottom: 0 }}
                  />
                </Block>
                {showDateComponent && (
                  <Block paddingLeft={14} width="50%">
                    <Field
                      {...schema.date}
                      allowAllDates
                      autoClose
                      clearable={false}
                      component={DateTime}
                      customInputStyles={CUSTOM_INPUT_STYLE}
                      customLabelStyles={CUSTOM_LABEL_STYLE}
                      dateFormat="MM/DD"
                      disableTimepicker
                      numberOfMonths={1}
                      options={DAY_DATE_OPTIONS}
                      name={`${row}.dateReference`}
                      validate={validateDate}
                    />
                  </Block>
                )}
              </Flex>
            </ServiceHourCell>
            <ServiceHourCell>
              {showDateComponent && (
                <Field
                  {...schema.status}
                  clearable={false}
                  component={Select}
                  options={BEHAVIOUR_OPTIONS}
                  name={`${row}.status`}
                  style={{ marginBottom: 0 }}
                />
              )}
            </ServiceHourCell>
            <ServiceHourCell>
              <Field
                component={InputTime}
                ignoreTimezone
                name={`${row}.open`}
                timeFormat="h:mm a"
                showRedBorder={showTimeError}
                showTimePickerButton={false}
                noMargin
                timezone={timezone}
              />
            </ServiceHourCell>
            <ServiceHourCell>
              <Field
                component={InputTime}
                ignoreTimezone
                name={`${row}.close`}
                showRedBorder={showTimeError}
                showTimePickerButton={false}
                timeFormat="h:mm a"
                showPlusOneSign={showPlusOne}
                noMargin
                timezone={timezone}
              />
            </ServiceHourCell>
            <ServiceHourCell textAlign="right">
              <Button
                color={colors.gray.normal}
                float="right"
                fontSize={typography.fontSizes.xlarge}
                onClick={() => fields.remove(index)}
                theme="largeIcon transparent"
              >
                <Icon name="trash" fontSize={typography.fontSizes.xLarge} />
              </Button>
            </ServiceHourCell>
          </TableRow>
        )
      })}
      <TableRow>
        <ServiceHourCell colSpan={5}>
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="flex-start"
          >
            <Block>
              <Button
                dataTestId="add-service-hours-button"
                marginBottom={10}
                marginTop={10}
                onClick={() => fields.push(generateDateReference())}
                theme="primary"
              >
                {t('button.addServiceHours')}
              </Button>
            </Block>
            {error.length > 0 && (
              <Block>
                <Span
                  color={colors.red.normal}
                  fontSize={typography.fontSizes.small}
                >
                  Service Hours cannot overlap. Please update your service
                  hours.
                </Span>
              </Block>
            )}
          </Flex>
        </ServiceHourCell>
      </TableRow>
    </TableBody>
  )
}

ServiceHourRow.propTypes = {
  fields: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    updateField: (form, path, value) => dispatch(change(form, path, value)),
  }
}

function validateDate(date) {
  return !moment(date).isValid()
}

interface Time {
  hour: number
  minute: number
}

interface HoursOverride {
  dateReference: Date
  reference: string
  close: Time
  open: Time
  status: string
}

function generateDateReference(): HoursOverride {
  const dateReference = moment()
    .startOf('day')
    .toDate()
  return {
    dateReference,
    reference: 'date',
    close: { hour: 0, minute: 0 },
    open: { hour: 0, minute: 0 },
    status: 'open',
  }
}
