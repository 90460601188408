import { colors, typography } from 'config/theme'

export default {
  menuList: {
    maxHeight: 288,
    outline: 'none',
  },
  searchInput: {
    backgroundColor: 'transparent',
    border: 0,
    color: colors.gray.light,
    fontFamily: typography.defaultFontFamily,
    fontSize: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 12,
    paddingTop: 12,
    outline: 'none',
    width: '100%',
  },
}
