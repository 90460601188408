import React from 'react'

import { compose, setPropTypes } from 'recompose'
import { Flex } from 'components/common'
import colors from 'config/theme/colors'
import PropTypes from 'prop-types'

export { Modal as ModalForTests }

export default compose(
  setPropTypes({
    children: PropTypes.node.isRequired,
  })
)(Modal)

function Modal(props) {
  const { width = 560, children } = props
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      width={'100%'}
    >
      <Flex
        background={colors.white}
        borderRadius={5}
        boxSizing="border-box"
        flexDirection="column"
        padding={30}
        width={width}
      >
        {children}
      </Flex>
    </Flex>
  )
}
