import moment from 'moment-timezone'

import { GetNext, Interval, Unit } from '../scheduling.types'

/**
 * Generates next occurrence interval for weekdays strategy
 */
export function* getNextWeekday({
  end,
  isInitial,
  options,
  start,
  timezone,
}: GetNext): IterableIterator<Interval> {
  const { duration, frequency, weekdays } = options
  const { unit: durationUnit, value: durationValue } = duration
  const { unit: frequencyUnit, value: frequencyValue } = frequency

  let initial: boolean = isInitial

  let dateCursor: number = initial
    ? moment
        .tz(start, timezone)
        .startOf(Unit.Week)
        .valueOf()
    : moment
        .tz(start, timezone)
        .add(frequencyValue, frequencyUnit)
        .startOf(Unit.Week)
        .valueOf()

  while (dateCursor < end) {
    for (const weekday of weekdays) {
      const nextOccurrenceEnd: number = moment
        .tz(dateCursor, timezone)
        .isoWeekday(weekday)
        .add(1, Unit.Day)
        .startOf(Unit.Day)
        .valueOf()

      const nextOccurrenceStart: number = moment
        .tz(nextOccurrenceEnd, timezone)
        .subtract(durationValue, durationUnit)
        .valueOf()

      if (nextOccurrenceEnd <= nextOccurrenceStart || nextOccurrenceEnd > end) {
        return
      }

      if (nextOccurrenceStart >= start) {
        const nextOccurrence: Interval = [
          nextOccurrenceStart,
          nextOccurrenceEnd - 1,
        ]

        yield nextOccurrence

        initial = false
      }
    }

    const nextDateCursor: number = moment
      .tz(dateCursor, timezone)
      .add(1, Unit.Week)
      .valueOf()

    dateCursor = nextDateCursor
  }
}
