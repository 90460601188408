/*
 * Version Module
 * Responsible for storing app version information
 */

export const VERSION_SET = 'lighthouse/version/SET'

export function reducer(state = null, action = {}) {
  switch (action.type) {
    case VERSION_SET:
      return action.version
    default:
      return state
  }
}

export function setVersion(version) {
  return {
    type: VERSION_SET,
    version,
  }
}
