import { getModule } from '@lighthouse/sdk'
import colors from 'config/theme/colors'
import inputStyles from 'components/form/text/styles'
import { size } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import InputLabel from 'components/form/input-label'
import { Form, Formik } from 'formik'
import { Crud } from 'components/controls'
import InputRow from '../input-row'

import TitleDivider from '../../../title-divider'
import emitter from '../../../../../../../../utils/emitter'

const signalModule = getModule('signals')

interface EditFloorProps {
  areaId: string
  fetchAreasByArea: Function
  floors: [
    {
      label: string
      labelShort: string
      level: number
    }
  ]
  isEditFloorMode: boolean
  isLighthouseAdmin: boolean
  parentAreaLocationId: string
  selectedFloor: {
    label: string
    labelShort: string
    level: number
  }
  setBuilding: Function
  updateAreaView: ({ isEditFloorMode }) => {}
}

interface Values {
  buildingId: string
  parentAreaLocationId: string
  sourceLevel: number
  targetLabel: string
  targetLabelShort: string
  targetLevel: number
}

const areaModule = getModule('areas')

const EditFloorSchema = ({ sourceLevel, sourceLabel, sourceLabelShort }) => {
  return Yup.object().shape({
    sourceLevel: Yup.number().required(),
    targetLabel: Yup.string()
      .min(1, 'Target Label must be at least 1 character')
      .required('Label is a required field.'),
    targetLabelShort: Yup.string()
      .min(1, 'Target Short Label must be at least 1 character')
      .max(4, 'Target Label must be at most 4 characters')
      .required('Short Label is a required field.'),
    targetLevel: Yup.number().required('Level is a required field.'),
  })
}

export default function EditFloorForm(props: EditFloorProps): {} {
  const {
    areaId: buildingId,
    fetchAreasByArea,
    isEditFloorMode,
    isLighthouseAdmin,
    parentAreaLocationId,
    selectedFloor,
    setBuilding,
    updateAreaView,
  } = props

  const {
    level: selectedFloorLevel,
    label: targetLabel,
    labelShort: targetLabelShort,
  } = selectedFloor

  const dispatch = useDispatch()

  const inputRowRadiumStyles = isEditFloorMode
    ? null
    : {
        ':hover': {
          backgroundColor: colors.blue.lightest,
          cursor: 'pointer',
        },
      }

  return (
    <Formik
      initialValues={{
        buildingId,
        parentAreaLocationId,
        sourceLevel: selectedFloorLevel,
        targetLevel: undefined,
        targetLabel: '',
        targetLabelShort: '',
      }}
      onSubmit={onSubmit}
      validationSchema={EditFloorSchema({
        sourceLevel: selectedFloorLevel,
        sourceLabel: targetLabel,
        sourceLabelShort: targetLabelShort,
      })}
    >
      {({
        dirty,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form
          id="floorform"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 15px',
          }}
        >
          <TitleDivider title="Move Floor Form: " />
          <InputRow name="targetLabel" radiumStyles={inputRowRadiumStyles}>
            <InputLabel label="Label" small={true} required={true} />
            <input
              type="text"
              name="targetLabel"
              onChange={handleChange}
              onBlur={handleBlur}
              style={inputStyles.root}
              value={values.targetLabel}
            />
          </InputRow>
          <InputRow name="targetLabelShort" radiumStyles={inputRowRadiumStyles}>
            <InputLabel label="Short Label" small={true} required={true} />
            <input
              type="text"
              name="targetLabelShort"
              onChange={handleChange}
              onBlur={handleBlur}
              style={inputStyles.root}
              value={values.targetLabelShort}
            />
          </InputRow>
          <InputRow name="targetLevel" radiumStyles={inputRowRadiumStyles}>
            <InputLabel label="Level" small={true} required={true} />
            <input
              type="number"
              name="targetLevel"
              onChange={handleChange}
              onBlur={handleBlur}
              style={inputStyles.root}
              value={values.targetLevel}
            />
          </InputRow>
          <Crud
            canDelete={false}
            canEdit={isLighthouseAdmin}
            dirty={dirty}
            errorCount={size(errors)}
            handleCancel={() =>
              updateAreaView({
                isEditFloorMode: false,
              })
            }
            handleEdit={() =>
              updateAreaView({
                isEditFloorMode: true,
              })
            }
            handleDelete={() => {}}
            handleSave={handleSubmit}
            id={buildingId}
            invalid={size(errors) > 0}
            isEditing={isEditFloorMode}
            name={name}
            reset={() =>
              updateAreaView({
                isEditFloorMode: false,
              })
            }
            small
            submitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  )

  async function onSubmit(values: Values, { resetForm }) {
    try {
      const {
        buildingId,
        sourceLevel,
        parentAreaLocationId,
        targetLabel,
        targetLabelShort,
        targetLevel,
      } = values
      await dispatch(
        areaModule.request('action/move-floor', {
          method: 'PUT',
          payload: {
            buildingId,
            sourceLevel,
            targetLabel,
            targetLabelShort,
            targetLevel,
          },
        })
      )

      await fetchAreasByArea(buildingId)

      setBuilding({
        id: buildingId,
        floor: values.targetLevel,
      })

      updateAreaView({
        isEditFloorMode: false,
      })

      resetForm({ values })

      // NOTE: We reload signals so that their floorRef change is shown correctly
      await dispatch(
        signalModule.signalsByLocation(parentAreaLocationId, {
          perPage: 9999,
        })
      )
    } catch (err) {
      emitter.emit('notification:add', {
        message: err.message || 'Unknown Error',
        theme: 'alert',
        tags: ['error'],
        title: 'Move Floor Error',
      })
    }
  }
}
