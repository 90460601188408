import { getModule } from '@lighthouse/sdk'
import moment from 'moment'
import 'moment-timezone'
import { connect } from 'react-redux'
import React from 'react'
import { map, toUpper } from 'lodash'
import { compose, pure } from 'recompose'

import Icon from 'components/icon'
import NavList from 'modules/nav-list'
import { LANGUAGES } from 'config/constants'

import getRegionSettings from '../../lib/region'
import styles from './styles'
import { useTranslation } from 'react-i18next'

const appModule = getModule('app')

const getShortAbbr = timezone => {
  const zoneAbbr = moment.tz(timezone).zoneAbbr()

  if (zoneAbbr === 'UTC') return zoneAbbr

  const characterToRemove = zoneAbbr.length - 2

  const shortName = zoneAbbr.slice(0, characterToRemove) + zoneAbbr.substr(-1)

  return shortName || zoneAbbr
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  pure
)(TimezoneBar)

function TimezoneBar(props) {
  const { setTimezone, setLocale, region, timezone } = props

  const { t, i18n } = useTranslation()

  const zoneAbbr = getShortAbbr(timezone)

  const languages = map(LANGUAGES, ({ value, label, labelT }, index) => ({
    dataTestId: value,
    label: labelT ? t(labelT) : label,
    hasDivider: index === LANGUAGES.length - 1,
    onClick: () => {
      i18n.changeLanguage(value)
      setLocale(value)
    },
    selected: i18n.language === value,
  }))

  const defaultTimezone = {
    label: 'Local Timezone',
    onClick: () => setTimezone(moment.tz.guess()),
    selected: timezone === moment.tz.guess(),
  }

  const utcTimezone = {
    label: 'UTC Timezone',
    dataTestId: 'utc',
    onClick: () => setTimezone('UTC'),
    selected: timezone === 'UTC',
  }

  const { timezones } = getRegionSettings(region)

  const currentTimezone = timezone

  const options = map(timezones, (timezone, location) => ({
    label: location,
    onClick: () => setTimezone(timezone),
    selected: currentTimezone === timezone,
  }))

  const timezoneOptions = [
    ...languages,
    defaultTimezone,
    utcTimezone,
    ...options,
  ]

  const timezoneNavigation = {
    timezone: {
      dropdown: {
        align: 'right',
        options: timezoneOptions,
      },
      testId: 'timezone-switcher',
    },
  }

  return (
    <NavList borders="left" type="timezone" items={timezoneNavigation}>
      <div style={styles.root}>
        <Icon name="timezone" theme={styles.icon} />
        <div style={styles.zone}>
          {toUpper(i18n.language)} / {zoneAbbr}
        </div>
      </div>
    </NavList>
  )
}

function mapStateToProps(state) {
  return {
    region: state.app.region,
    timezone: state.app.timezone,
    locale: state.app.locale,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setTimezone: value => dispatch(appModule.setTimezone(value)),
    setLocale: value => dispatch(appModule.setLocale(value)),
  }
}
