import { version as nextVersion } from '../../../constants'

export function majorVersionChange(state) {
  const prevVersion = state.version

  if (!prevVersion) {
    return true
  }

  if (!nextVersion) {
    return false
  }

  const prevMajorVersion = prevVersion.split('.')[0]
  const nextMajorVersion = nextVersion.split('.')[0]
  const isBetaVersion = nextMajorVersion === '0'
  const majorVersionHasChanged = (
    !isBetaVersion &&
    nextMajorVersion !== prevMajorVersion
  )

  return majorVersionHasChanged
}
