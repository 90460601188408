import { fetchImage, getTimezoneDatetime } from '../../../helpers'

export function defaultFooter({
  logoUrl,
  timestamp,
  timezone,
  type,
  sequenceId,
}) {
  const datetime = getTimezoneDatetime({
    format: 'Do MMM YYYY',
    showTzAbbr: false,
    timestamp,
    timezone,
  })

  const footerText = sequenceId ? `${type} ${sequenceId}` : `${type}`

  // eslint-disable-next-line no-unused-vars
  return fetchImage(logoUrl, { isHeader: true }).then(image => _page => ({
    columns: [
      {
        fit: [80, 30],
        image,
        margin: [60, 20, 0, 0],
      },
      {
        alignment: 'right',
        font: 'Gotham',
        text: `${footerText} | ${datetime}`,
        style: 'small',
        margin: [0, 25, 60, 0],
      },
    ],
  }))
}
