import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export default function noResults(props) {
  const { message } = props

  return <div style={styles.root}>{message}</div>
}

noResults.propTypes = {
  message: PropTypes.string,
}
