/*
 * File Module
 * Responsible for managing file upload state. It is the clients responsibility
 * to handle uploads, but all state should be persisted using this module
 */

import { createSelector } from 'reselect'
import {
  filter,
  memoize,
} from 'lodash'
import { parseState } from '../../helpers'

export const FILE_UPLOADS_ADD = 'lighthouse/files/FILE_UPLOADS_ADD'
export const FILE_UPLOADS_REMOVE = 'lighthouse/files/FILE_UPLOADS_REMOVE'
export const FILE_UPLOADS_PROGRESS = 'lighthouse/files/FILE_UPLOADS_PROGRESS'
export const FILE_UPLOADS_DONE = 'lighthouse/files/FILE_UPLOADS_DONE'
export const FILE_UPLOADS_ERROR = 'lighthouse/files/FILE_UPLOADS_ERROR'
export const STATUS_PENDING = 'pending'
export const STATUS_UPLOADING = 'uploading'
export const STATUS_DONE = 'done'

export function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case FILE_UPLOADS_ADD:
      return state.setIn([action.id], {
        ...action.file,
        id: action.id,
        status: STATUS_PENDING,
      })
    case FILE_UPLOADS_REMOVE:
      return state.without(action.id)
    case FILE_UPLOADS_PROGRESS:
      state = state.setIn([action.id, 'progress'], action.progress)
      state = state.setIn([action.id, 'status'], STATUS_UPLOADING)
      return state
    case FILE_UPLOADS_DONE:
      state = state.setIn([action.id, 'progress'], 1)
      state = state.setIn([action.id, 'status'], STATUS_DONE)
      return state
    case FILE_UPLOADS_ERROR:
      state = state.setIn([action.id, 'error'], action.error)
      state = state.setIn([action.id, 'progress'], undefined)
      // Set errored files to pending for retry
      state = state.setIn([action.id, 'status'], STATUS_PENDING)
      return state
    default:
      return state
  }
}

export function addFile(id, file) {
  return {
    type: FILE_UPLOADS_ADD,
    id,
    file,
  }
}

export function removeFile(id) {
  return {
    type: FILE_UPLOADS_REMOVE,
    id,
  }
}

export function updateProgress(id, progress) {
  return {
    type: FILE_UPLOADS_PROGRESS,
    id,
    progress,
  }
}

export function updateDone(id) {
  return {
    type: FILE_UPLOADS_DONE,
    id,
  }
}

export function updateError(id, error) {
  return {
    type: FILE_UPLOADS_ERROR,
    id,
    error,
  }
}

const filesSelector = state => state.files

export const getFilesByStatus = createSelector(
  filesSelector,
  files => memoize(status => (
    filter(files, { status })
  )),
)
