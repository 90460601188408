import React from 'react'
import { Flex } from 'components/common'
import { colors } from 'config/theme'
import { Text } from 'components/common'
interface SelectedCountProps {
  rowsSelected: string
  totalCount: number
}

const SelectedCount: React.FC<SelectedCountProps> = ({
  rowsSelected,
  totalCount,
}) => {
  return (
    <Flex
      display="flex"
      alignItems="center"
      width="10%"
      color={colors.gray.light}
      dataTestId={'SelectedCount'}
    >
      <Text fontSize={'12px'}>
        {rowsSelected} of {totalCount}
      </Text>
    </Flex>
  )
}

export default SelectedCount
