import compose from 'recompose/compose'
import React from 'react'
import withHandlers from 'recompose/withHandlers'

import { Block, Span } from 'components/common'
import Spinner from 'components/spinner'

import Header from './components/header'

export default compose(withHandlers({ getErrorMessage, getErrorTitle }))(Panel)

function Panel(props) {
  const title = props.error ? props.getErrorTitle() : props.title

  return (
    <Block>
      <Header
        color={props.color}
        onClose={props.onClose}
        resource={props.resource}
        title={title}
        toggleExpand={props.toggleExpand}
        toggleIcon={props.toggleIcon}
      />

      {props.isResolving && (
        <Block marginBottom={55} marginTop={55}>
          <Spinner />
        </Block>
      )}
      {props.error && (
        <Span fontSize="12px" padding="20px">
          {props.getErrorMessage()}
        </Span>
      )}
      {!props.isResolving && !props.error && props.children}
    </Block>
  )
}

function getErrorMessage(props) {
  return () => {
    const { error, resource } = props
    const { message, name } = error

    switch (name) {
      case 'NotFoundError':
        return `The ${resource} you were looking for has been removed and can no longer be viewed.`
      default:
        return `The ${resource} could not be loaded. Please contact support.`
    }
  }
}

function getErrorTitle(props) {
  return () => {
    const { error, resource } = props
    const { name } = error

    switch (name) {
      case 'NotFoundError':
        return `${resource} not found`
      case 'BadRequestError':
        return `${resource} bad request`
      default:
        return `${resource} error`
    }
  }
}
