import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enUS from './enUS'
import es419 from './es419'
import frCA from './frCA'

export type LocaleResource = Record<string, string>

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: ['en-US', 'fr-CA', 'es-419'],
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    resources: {
      'en-US': {
        translation: {
          ...enUS,
        },
      },
      'es-419': {
        translation: {
          ...es419,
        },
      },
      'fr-CA': {
        translation: {
          ...frCA,
        },
      },
    },
  })
