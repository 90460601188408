/*
 * Geo Module
 * ===========
 * Note: Example state at bottom of file
 */

import {
  attempt,
  compact,
  each,
  filter,
  find,
  get,
  includes,
  isError,
  isNil,
  map,
  memoize,
  reduce,
} from 'lodash'

import { bbox } from '@turf/turf'
// TODO: must import from dist otherwise we pull in ES6 code which can't be
// transpiled with the current version of parcel
import Supercluster from 'supercluster/dist/supercluster'
import { createSelector } from 'reselect'
import { feature, featureCollection, point } from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import moment from 'moment'
import Immutable from 'seamless-immutable'

import * as constants from './constants'
import './hooks'

import { default as isActivityMarker } from './utils'
import { selectors as areaSelectors } from '../areas'
import { parseState } from '../../helpers'

export const SET_PROPERTIES = 'lighthouse/geo/SET_PROPERTIES'
export const SET_MODE = 'lighthouse/geo/SET_MODE'
export const SET_LOADING = 'lighthouse/geo/SET_LOADING'
export const SET_MARKERS = 'lighthouse/geo/SET_MARKERS'
export const SET_MARKER_FILTERS = 'lighthouse/geo/SET_FILTERS'
export const REMOVE_MARKERS = 'lighthouse/geo/REMOVE_MARKERS'
export const REMOVE_MARKERS_BY_TYPES = 'lighthouse/geo/REMOVE_MARKERS_BY_TYPES'
export const CLEAN_MARKERS = 'lighthouse/geo/CLEAN_MARKERS'
export const CLEAR_MARKERS = 'lighthouse/geo/CLEAR_MARKERS'
export const SET_BUILDING = 'lighthouse/geo/SET_BUILDING'
export const SET_LOCATION = 'lighthouse/geo/SET_LOCATION'
export const SET_TIMELINE = 'lighthouse/geo/SET_TIMELINE'
// export const ADD_TIMELINE_EXCEPTIONS = 'lighthouse/geo/ADD_TIMELINE_EXCEPTIONS'
// export const CLEAR_TIMELINE_EXCEPTIONS = 'lighthouse/geo/CLEAR_TIMELINE_EXCEPTIONS'
export const RESET = 'lighthouse/geo/RESET'

export function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case SET_PROPERTIES:
      return state.merge({ properties: action.properties }, { deep: true })
    case SET_MODE: {
      const validMode = includes(constants.MODES, action.mode)

      if (!validMode) {
        throw new Error(`Invalid geo mode: '${action.mode}'`)
      }

      return state.set('mode', action.mode)
    }
    case SET_LOADING:
      return state.merge({
        loading: action.value,
      })
    case SET_MARKER_FILTERS:
      return state.merge({
        markers: {
          filters: action.filters,
        },
      }, { deep: true })
    case SET_MARKERS: {
      return state.updateIn(['markers', 'collection'], (collection = Immutable({})) => {
        const timeline = state.timeline || {}

        each(action.markers, (marker) => {
          const currentProperties = collection.getIn([marker.id, 'properties'], {})
          const nextProperties = marker.properties || {}

          const coordinates = get(marker, 'geometry.coordinates')
          const geoJsonPoint = attempt(point, coordinates)

          if (isError(geoJsonPoint)) {
            console.error('Invalid geometry for marker', marker)
            return
          }

          const isActivity = isActivityMarker(nextProperties.type)

          if (isActivity) {
            const withinVisibleWindow = isWithinVisibleWindow(nextProperties, timeline)
            const latestTimestamp = isLatestTimestamp(currentProperties, nextProperties)

            if (!withinVisibleWindow || !latestTimestamp) return
          }

          collection = collection.set(marker.id, {
            id: marker.id,
            type: 'Feature',
            geometry: marker.geometry,
            properties: {
              ...currentProperties,
              ...nextProperties,
            },
          })
        })

        return collection
      })
    }
    case REMOVE_MARKERS:
      return state.updateIn(['markers', 'collection'], (collection) => {
        each(action.ids, (id) => {
          collection = collection.without(id)
        })
        return collection
      })
    case REMOVE_MARKERS_BY_TYPES:
      return state.updateIn(['markers', 'collection'], (collection = Immutable({})) => {
        each(action.types, (type) => {
          collection = collection.without(marker => (
            marker.properties.type === type
          ))
        })
        return collection
      })
    case CLEAN_MARKERS: return state.updateIn(['markers', 'collection'], (collection = Immutable({})) => {
      const timeline = state.timeline || {}

      return collection.without((marker) => {
        const markerProperties = marker.properties || {}
        return !isWithinVisibleWindow(markerProperties, timeline)
      })
    })
    case CLEAR_MARKERS:
      return state.setIn(['markers', 'collection'], {})
    case SET_BUILDING:
      return state.set('building', {
        id: action.id,
        floor: action.floor,
      })
    case SET_LOCATION:
      return state.set('location', {
        id: action.id,
      })
    case SET_TIMELINE:
      return state.merge({
        timeline: action.props,
      }, { deep: true })
    // TODO historical timeline exceptions integration
    // case ADD_TIMELINE_EXCEPTIONS:
    //   existingTimelineState = get(state, `${action.locationId}.timeline.exceptions`) || Immutable([])
    //   return state.setIn([
    //     action.locationId,
    //     'timeline',
    //     'exceptions'
    //   ], existingTimelineState.concat(action.exceptions))
    // case CLEAR_TIMELINE_EXCEPTIONS:
    //   return state.setIn([
    //     action.locationId,
    //     'timeline',
    //     'exceptions'
    //   ], [])
    case RESET:
      return Immutable({})
    default:
      return state
  }
}

export function setProperties(properties) {
  return { type: SET_PROPERTIES, properties }
}

export function setMode(mode) {
  return { type: SET_MODE, mode }
}

export function setLoading(value) {
  return { type: SET_LOADING, value }
}

export function setMarkerFilters(filters) {
  return { type: SET_MARKER_FILTERS, filters }
}

export function setMarkers(markers) {
  return { type: SET_MARKERS, markers }
}

export function removeMarkers(ids) {
  return { type: REMOVE_MARKERS, ids }
}

export function removeMarkersByTypes(types) {
  return { type: REMOVE_MARKERS_BY_TYPES, types }
}

export function cleanMarkers() {
  return { type: CLEAN_MARKERS }
}

export function clearMarkers() {
  return { type: CLEAR_MARKERS }
}

export function setBuilding(opts) {
  return {
    type: SET_BUILDING,
    id: opts.id,
    floor: opts.floor,
  }
}

export function setLocation(opts) {
  return {
    type: SET_LOCATION,
    id: opts.id,
  }
}

export function setTimeline(props) {
  return { type: SET_TIMELINE, props }
}

export function reset() {
  return { type: RESET }
}

const areaCacheSelector = state => state.areas.cache
const buildingSelector = state => state.geo.building || {}
const locationSelector = state => state.geo.location || {}
const markersCollectionSelector = state => get(state, 'geo.markers.collection', {})
const markersFiltersSelector = state => get(state, 'geo.markers.filters', {})
const timelineSelector = state => state.geo.timeline || {}
const mapBoundsSelector = state => get(state, 'geo.properties.bounds', [-180, -90, 180, 90])

const filterMarkersByGeometrySelector = createSelector(
  markersCollectionSelector,
  markers => (geometry) => {
    const polygonFeature = attempt(feature, geometry)

    if (isError(polygonFeature)) {
      console.warn('Invalid polygon supplied to `filterMarkersByGeometry` selector')
      return []
    }

    return filter(markers, (marker) => {
      const markerCoordinates = get(marker, 'geometry.coordinates')
      const markerPoint = attempt(point, markerCoordinates)

      if (isError(markerPoint)) {
        return false
      }

      return booleanPointInPolygon(markerPoint, polygonFeature)
    })
  },
)

const filterMarkersByTypeSelector = createSelector(
  markersCollectionSelector,
  markers => memoize(type => (
    filter(markers, ['properties.type', type])
  )),
)

const countMarkersByLocationSelector = createSelector(
  areaSelectors.byType,
  filterMarkersByTypeSelector,
  (areasByType, filterMarkers) => memoize((type) => {
    if (!type) {
      console.error('Missing type to filter markers')
      return {}
    }

    const locationAreas = areasByType('location')
    const locationPolygons = map(locationAreas, (location) => {
      const polygon = attempt(feature, location.entity.geometry, {
        id: location.id,
      })

      if (isError(polygon)) {
        console.error('Invalid polygon for building')
        return null
      }

      return polygon
    })

    const markers = filterMarkers(type)

    return reduce(markers, (accum, marker) => {
      const markerPoint = attempt(point, marker.geometry.coordinates)

      if (isError(markerPoint)) {
        console.error('Invalid marker point')
        return accum
      }

      const matchedLocation = find(locationPolygons, polygon => (
        booleanPointInPolygon(markerPoint, polygon)
      ))

      if (!matchedLocation) {
        return accum
      }

      const locationId = get(matchedLocation, 'properties.id')

      accum[locationId] = accum[locationId] || 0
      accum[locationId] += 1
      return accum
    }, {})
  }),
)

const visibleMarkersSelector = createSelector(
  areaCacheSelector,
  buildingSelector,
  locationSelector,
  markersCollectionSelector,
  markersFiltersSelector,
  timelineSelector,
  (areaCache, selectedBuilding, selectedLocation, markers, filters) => {


    const selectedLocationId = get(selectedLocation,'id')
    const buildingEntity = get(areaCache, [selectedBuilding.id, 'entity'], {})
    const buildingGeometry = buildingEntity.geometry
    const buildingPolygon = attempt(feature, buildingGeometry)

    const filteredMarkers = filter(markers, (marker = {}) => {
      const markerGeometry = marker.geometry || {}
      const markerProperties = marker.properties || {}
      const { types = {} } = filters

      const isVisible = types[markerProperties.type]
      if (!isVisible) return false

      const subTypeMatch = markerProperties.subType
        ? types[`${markerProperties.type}.${markerProperties.subType}`]
        : true

      if (!subTypeMatch) return false

      const hasFloor = hasFloorsRef(markerProperties)
      
      if(markerProperties.type === 'signal'){
        const childOf = get(markerProperties, 'childOf', []).map(String)
        const childOfSelectedLocation = selectedLocationId ? includes(childOf, selectedLocationId) : false

        if(!childOf.length){
          return false
        }

        // show only if part of currently selected location
        if(childOf.length && !hasFloor){
          return !selectedLocationId || childOfSelectedLocation
        }
      }

      // NOTE: when a non-signal marker has no floors ref, it is always visible
      if (!hasFloor) return true

      // NOTE: when no building is selected, markers are visible if:
      // * they're an outdoor marker type
      if (!selectedBuilding.id) return isVisibleOutdoors(markerProperties)

      // NOTE: when building is selected markers are visible if:
      // * they appear on the selected building's floor and within its geometry

      // NOTE: check floor independently first as its quicker than geometry check
      const onFloor = isOnFloor(markerProperties, selectedBuilding)
      if (!onFloor) return false

      const withinGeometry = isWithinGeometry(areaCache, markerGeometry, buildingPolygon)
      if (!withinGeometry) return false

      return true
    })

    return Immutable(filteredMarkers)
  },
)

const clustersSelector = createSelector(
  areaSelectors.byType,
  visibleMarkersSelector,
  markersFiltersSelector,
  (areasByType, markerFeatures, filters) => () => {
    const { types = {} } = filters
    const locations = types.location
      ? areasByType('location')
      : []

    const locationFeatures = Immutable(locations).map(({ entity }) => {
      const { _id: id, center, geometry, name, type } = entity
      const properties = { id, geometry, label: name, type }
      const pointFeature = attempt(point, center.coordinates, properties)

      return pointFeature
    })

    const mutableLocations = locationFeatures.asMutable({ deep: true })
    const mutableMarkers = markerFeatures.asMutable({ deep: true })

    const features = compact([...mutableLocations, ...mutableMarkers])
    const collection = featureCollection(features)

    if (collection.features.length > 0) {
      const superCluster = new Supercluster({
        radius: 50,
        extent: 256,
        maxZoom: 17,
      })

      const clusters = superCluster.load(collection.features)
      return { clusters, bounds: bbox(collection) }
    }

    return { collection, bounds: bbox(collection) }
  },
)

function hasFloorsRef(markerProperties) {
  const floorsRef = markerProperties.floorsRef || []

  return !!floorsRef.length
}

function isLatestTimestamp(currentProperties, nextProperties) {
  const { timestamp: currentTimestamp } = currentProperties
  const { timestamp: nextTimestamp } = nextProperties

  if (!currentTimestamp || !nextTimestamp) return true

  return moment(nextTimestamp).isAfter(currentTimestamp)
}

function isOnFloor(markerProperties, selectedBuilding) {
  const floor = selectedBuilding.floor
  const floorsRef = markerProperties.floorsRef

  if (isNil(floor)) return true

  return includes(floorsRef, floor)
}

function isWithinGeometry(areaCache, markerGeometry, buildingPolygon) {
  const markerCoordinates = markerGeometry.coordinates
  const markerPoint = attempt(point, markerCoordinates)

  if (isError(buildingPolygon)) {
    console.warn('Invalid building polygon supplied to `visibleMarkers` selector')
    return true
  }

  if (isError(markerPoint)) {
    console.warn('Invalid marker point supplied to `visibleMarkers` selector')
    return false
  }

  return booleanPointInPolygon(markerPoint, buildingPolygon)
}

function isWithinVisibleWindow(markerProperties, timeline) {
  const { currentTime, isLive } = timeline
  const { expiresAt, timestamp } = markerProperties

  if (!currentTime || !expiresAt || !timestamp) return true

  // NOTE: when marker has timestamp and expires at, marker visible if
  // later than current time and in live mode or when current time is
  // within timestamp and expires at
  const currentTimeMoment = moment(currentTime)

  // NOTE: the timeline current time only refreshes every 60 seconds
  // meaning sockets events will most likely be in the future compared
  // to the current time. We should only add when in live mode as any
  // replayed events could get added to the map without this check.
  const isRealTime = isLive && moment(timestamp)
    .isAfter(currentTimeMoment)

  const isNotExpired = currentTimeMoment
    .isBetween(timestamp, expiresAt, null, '[]')

  return isRealTime || isNotExpired
}

function isVisibleOutdoors(markerProperties) {
  const markerType = markerProperties.type
  return includes(constants.VISIBLE_OUTDOOR_MARKERS, markerType)
}

export const selectors = {
  clusters: clustersSelector,
  countMarkersByLocation: countMarkersByLocationSelector,
  filterMarkersByGeometry: filterMarkersByGeometrySelector,
  filterMarkersByType: filterMarkersByTypeSelector,
  visibleMarkers: visibleMarkersSelector,
}

/**
{
  mode: 'live', // historical, edit, deployment
  timeline: {}, // TBC
  properties: {
    zoom: 19,
    bounds: null,
  },
  building: {
    id: 'buildingId',
    floor: 2,
  },
  location: {
    id: 'location1',
  }
  markers: {
    filters: {
      types: {
        signals: true,
        users: false,
        assets: true,
      },
    },
    collection: {
      [id]: {
        id: 'id',
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [80, -80]
        },
        properties: {
          type: 'signal',
          label: 'Marker Label',
          popup: 'Popup Content', // html template
          floorsRef: [0],
        },
      },
  }
}
**/
