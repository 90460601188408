export const DEFAULT_FILTER_EVENTS = [
  { label: 'Enter', labelT: 'labelEnter', value: 'enter', type: 'eventType' },
  { label: 'Exit', labelT: 'labelExit', value: 'exit', type: 'eventType' },
  { label: 'GPS', labelT: 'labelGPS', value: 'geo', type: 'eventType' },
]
export const CELL_TEXT_LABELS = {
  enter: {
    label: 'enter',
    labelT: 'labelEnter',
  },
  exit: {
    label: 'exit',
    labelT: 'labelExit',
  },
  geo: {
    label: 'gps',
    labelT: 'labelGPS',
  },
}
export const CELL_TEXT_TYPES = {
  enter: 'positive',
  exit: 'negative',
  geo: 'primary',
}
