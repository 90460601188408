import { colors } from 'config/theme'

export default {
  formGroup: {
    marginBottom: '3em',
  },
  formGroupLabel: {
    fontWeight: 300,
  },
  imageLink: {
    textDecoration: 'underline',
  },
  signature: {
    width: 200,
  },
  small: {
    color: colors.gray.light,
  },
}
