import React from 'react'

import Button from 'components/button'
import { Save } from 'components/buttons'
import { Flex } from 'components/common'

import { Error, Unsaved } from 'components/prompts'
import { useTranslation } from 'react-i18next'

export default function SyncControls(props) {
  const {
    errorCount,
    handleCancel,
    id,
    invalid,
    pristine,
    reset,
    submitting,
  } = props

  const { t } = useTranslation()

  const prompt =
    invalid && !pristine ? (
      <Error errorCount={errorCount} id={id} />
    ) : (
      <Unsaved />
    )

  return (
    <Flex>
      {pristine ? (
        <Button onClick={handleCancel}>{t('button.cancel')}</Button>
      ) : (
        <Button onClick={reset}>{t('button.discard')}</Button>
      )}
      <Save invalid={invalid} submitting={submitting} />
      {prompt}
    </Flex>
  )
}
