import reactGa from 'react-ga'
import { noop } from 'lodash'

const hitTypeStrategies = {
  event: reactGa.event,
}

export function initialize(options) {
  const { gaTrackingId, debug } = options
  reactGa.initialize(gaTrackingId, { debug })
}

export function set(props) {
  reactGa.set(props)
}

export function setUserId(id) {
  set({ userId: id })
}

export function send(props) {
  const { hitType, category, action, label, value } = props

  const data = {
    category,
    action,
    label,
    value,
  }

  const hitTypeFn = hitTypeStrategies[hitType] || noop

  return hitTypeFn.call(reactGa, data)
}
