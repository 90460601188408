/*
 * Template Module
 */

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { filter, memoize } from 'lodash'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import buildActionCreators from './action-creators'
import crud from '../../crud'
import hooks from './hooks'

import { selectors as areaSelectors } from '../areas'

import { buildNearbySelectorFn } from '../../helpers/selectors'

const resource = 'templates'

export const actions = crud.actions(resource)
hooks(actions)

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn(resource),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  applicationResourceUrlFn,
  applicationParamsFn,
  authorizationHeadersFn
)

export const actionCreators = Object.assign(
  {},
  crudActionCreators,
  customActionCreators
)

const crudSelectors = crud.selectors(resource)

const templatesByTypeSelector = (templateType) =>
  createSelector(crudSelectors.cache, (cache) =>
    filter(cache, (template) => {
      if (template.error) return false
      return template.entity && template.entity.type === templateType
    })
  )

const nearbySelector = memoize((type, referenceKey) =>
  createSelector(
    areaSelectors.sortByPosition,
    templatesByTypeSelector(type),
    buildNearbySelectorFn(referenceKey)
  )
)

export const selectors = {
  ...crudSelectors,
  issuesList: templatesByTypeSelector('issue'),
  issuesNearbySelector: nearbySelector('issue', 'issues'),
  tasksList: templatesByTypeSelector('task'),
  tasksNearbySelector: nearbySelector('task', 'tasks'),
}

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})
