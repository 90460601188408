import { colors as brandColors } from 'config/theme/brand'
import Radium from 'radium'

const spinner = Radium.keyframes({
  '0%': {
    webKitTransform: 'rotate(0deg)',
    transform: 'rotate(0deg)',
  },
  '100%': {
    webKitTransform: 'rotate(180deg)',
    transform: 'rotate(180deg)',
  },
})

export default function styles(time) {
  const halfTime = time / 2
  const quarterTime = time / 4
  return {
    container: {
      display: 'inline-block',
      position: 'relative',
      width: 16,
      height: 16,
    },
    progressBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 16,
      height: 16,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#DDD',
      borderRadius: '50%',
      boxSizing: 'border-box',
    },
    progress: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 16,
      height: 16,
      lineHeight: '16px',
      background: 'none',
      margin: '0 auto',
      boxShadow: 'none',
    },
    progressLeft: {
      width: '50%',
      height: '100%',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      zIndex: 1,
      left: 0,
    },
    progressBarLeft: {
      position: 'absolute',
      top: 0,
      left: '100%',
      width: '100%',
      height: '100%',
      background: 'none',
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: brandColors.primary,
      webKitTransformOrigin: 'center left',
      transformOrigin: 'center left',
      boxSizing: 'border-box',
      borderLeft: 0,
      animation: `${quarterTime}s linear forwards ${halfTime}s`,
      animationName: spinner,
    },
    progressRight: {
      width: '50%',
      height: '100%',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      zIndex: 1,
      right: 0,
    },
    progressBarRight: {
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '100%',
      height: '100%',
      background: 'none',
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: brandColors.primary,
      webKitTransformOrigin: 'center right',
      transformOrigin: 'center right',
      boxSizing: 'border-box',
      borderRight: 0,
      animation: `${halfTime}s linear forwards`,
      animationName: spinner,
    },
  }
}
