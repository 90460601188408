import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'

const DEFAULT_REFRESH_TIME = 60000

export default class RefreshTimeAgo extends Component {
  state = { interval: null }

  componentWillMount() {
    const { refreshInterval } = this.props

    this.setState({
      interval: setInterval(
        this.render.bind(this),
        refreshInterval || DEFAULT_REFRESH_TIME
      ),
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  render() {
    const { fromNow, timestamp, timezone, style } = this.props

    const timeAgo = moment(new Date(timestamp))
      .tz(timezone)
      .fromNow(fromNow)

    return (
      <div>
        <span style={style}>{timeAgo}</span>
      </div>
    )
  }
}

RefreshTimeAgo.propTypes = {
  fromNow: PropTypes.bool,
  refreshInterval: PropTypes.number,
  style: PropTypes.object,
  timestamp: PropTypes.string,
  timezone: PropTypes.string,
}
