import { change } from 'redux-form'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import React from 'react'

import { Block, Flex } from 'components/common'
import { colors } from 'config/theme'
import { InputGroup, InputLabel } from 'components/form'
import ButtonIcon from 'components/button-icon'
import Icon from 'components/icon'
import Thumbnail from 'components/thumbnail'

export default compose(
  connect(null, mapDispatchToProps),
  withHandlers({ handleRemove })
)(Signature)

function Signature(props) {
  const {
    handleRemove,
    input = {},
    label,
    meta = {},
    readOnly,
    required,
    small,
    ...styleProps
  } = props

  const { value } = input
  const { error } = meta

  return (
    <Block marginBottom="15px">
      <InputGroup small={small}>
        <InputLabel label={label} required={required} small={small} />
        <Flex flexDirection="column">
          {/* NOTE don't allow users to remove signatures as of yet */}
          {/*!readOnly && value && (
            <Block
              flexGrow={1}
              position="relative"
            >
              <Block
                onClick={handleRemove}
                marginRight="-20px"
                marginTop="-10px"
                position="absolute"
                right={0}
              >
                <ButtonIcon
                  backgroundColor={colors.red.normal}
                >
                  <Icon
                    name="neutral"
                    color={colors.white}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={1}
                  />
                </ButtonIcon>
              </Block>
            </Block>
          )*/}
          {value && (
            <Thumbnail
              alt="Signature"
              background={colors.white}
              src={value}
              {...styleProps}
            />
          )}
          {!readOnly && error && (
            <Block color={colors.red.normal} fontSize="10px">
              {error}
            </Block>
          )}
        </Flex>
      </InputGroup>
    </Block>
  )
}

function handleRemove(props) {
  const { change, fieldPath } = props
  return () => change(`${fieldPath}.value`, null)
}

function mapDispatchToProps(dispatch, props) {
  const {
    meta: { form },
  } = props

  return {
    change: (field, value) => dispatch(change(form, field, value)),
  }
}
