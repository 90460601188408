import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import styles from './styles'
import Link from 'components/link'
import Icon from 'components/icon'
import Anchor from 'components/anchor'

export default Radium(TitleBar)

function TitleBar(props) {
  const {
    anchorDirection,
    breadcrumbs,
    bold,
    border,
    fontSize,
    title,
    children,
    noMargin,
    tooltip,
  } = props
  const breadcrumbParts = breadcrumbs
    ? breadcrumbs.map((item, index) => (
        <span key={index}>
          <Link data-testid="title-link" style={[styles.link]} to={item.link}>
            {item.title}
          </Link>
          &nbsp;&raquo;&nbsp;
        </span>
      ))
    : null

  const titleStyles = [styles.title]

  const tooltipEl = tooltip ? (
    <Anchor tooltip={tooltip} anchorDirection={anchorDirection}>
      <Icon name="help" theme={styles.help} />
    </Anchor>
  ) : null

  if (bold) {
    titleStyles.push({ fontWeight: '400' })
  }

  if (fontSize) {
    titleStyles.push({ fontSize })
  }

  const titleEl = title ? (
    <h2 style={[titleStyles]}>
      {breadcrumbParts}
      {title}
    </h2>
  ) : null

  const options = children ? (
    <div style={[styles.options]}>{props.children}</div>
  ) : null

  const rootStyles = [styles.root]

  if (noMargin) {
    rootStyles.push(styles.noMargin)
  }

  if (border) {
    rootStyles.push(styles.border)
  }

  if (tooltip) {
    titleStyles.push({ paddingRight: '5px' })
    rootStyles.push({ justifyContent: 'start' })
  }

  return (
    <div id="title-bar" style={rootStyles}>
      {titleEl}
      {tooltipEl}
      {options}
    </div>
  )
}

TitleBar.propTypes = {
  title: PropTypes.string,
  border: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  noMargin: PropTypes.bool,
  tooltip: PropTypes.array,
}
