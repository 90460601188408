import React from 'react'
import { colors } from 'config/theme'
import { assign } from 'lodash'

export default function Caret({
  up,
  down,
  left,
  right,
  color,
  scale = 1,
  dataTestId,
}) {
  let style = {
    margin: '0 .5em',
    height: 0,
    width: 0,
  }

  const borderColor = color || colors.gray.light
  const borderShort = `${4 * scale}px solid transparent`
  const borderLong = `${5 * scale}px solid ${borderColor}`

  if (up) {
    style = assign({}, style, {
      borderBottom: borderLong,
      borderLeft: borderShort,
      borderRight: borderShort,
    })
  }

  if (down) {
    style = assign({}, style, {
      borderTop: borderLong,
      borderLeft: borderShort,
      borderRight: borderShort,
    })
  }

  if (right) {
    style = assign({}, style, {
      borderLeft: borderLong,
      borderTop: borderShort,
      borderBottom: borderShort,
    })
  }

  if (left) {
    style = assign({}, style, {
      borderRight: borderLong,
      borderTop: borderShort,
      borderBottom: borderShort,
    })
  }

  return <div data-testid={dataTestId} style={style} />
}
