import React from 'react'

import { compose, setPropTypes } from 'recompose'
import { Block } from 'components/common'
import colors from 'config/theme/colors'
import PropTypes from 'prop-types'

export { ModalHeader as ModalHeaderForTests }

export default compose(
  setPropTypes({
    children: PropTypes.node.isRequired,
  })
)(ModalHeader)

function ModalHeader(props) {
  return (
    <Block
      color={colors.gray.dark}
      fontSize="22px"
      fontWeight={600}
      letterSpacing={-1}
      marginBottom={20}
      marginTop={20}
      textAlign="center"
      width="100%"
    >
      {props.children}
    </Block>
  )
}
