import colors from 'config/theme/colors'

export default {
  root: {
    cursor: 'pointer',
  },

  error: {
    borderColor: colors.red.normal,
  },

  disabled: {
    cursor: 'not-allowed',
    borderColor: colors.gray.lightest,
    ':focus': {
      borderColor: colors.gray.lightest,
    },
  },
}
