import { assign, camelCase, compact, get } from 'lodash'

export default {
  getChartArr,
  queryParamsFn,
}

export function queryParamsFn(params, state) {
  const { query } = params
  const chartArr = getChartArr(params)
  const chartKey = camelCase(chartArr)
  const chartFilters = get(state, ['analytics', 'cache', chartKey, 'filters'], {})
  const globalFilters = state.analytics.filters || {}
  const queryParams = assign({}, { chartKey }, globalFilters, chartFilters, query)

  return queryParams
}

export function getChartArr(obj) {
  const { module, metric, dimension1, dimension2 } = obj
  return compact([module, metric, dimension1, dimension2])
}
