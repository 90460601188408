/*
 * Maps Module
 * ===========
 * Note: Example state at bottom of file
 */

import Immutable from 'seamless-immutable'

import {
  assign,
  get,
  omit,
  pick,
} from 'lodash'

import { parseState } from '../../helpers'

// SET_LOCATION can be used for setting/updating the location and it's
// attributes, such as the currently selected floor
export const SET_LOCATION = 'lighthouse/maps/SET_LOCATION'
export const SET_MODE = 'lighthouse/maps/SET_MODE'
export const SET_LOADING = 'lighthouse/maps/SET_LOADING'
export const SET_DATES = 'lighthouse/maps/SET_DATES'
export const SET_DEVICE = 'lighthouse/maps/SET_DEVICE'
export const SET_DEVICES = 'lighthouse/maps/SET_DEVICES'
export const REMOVE_DEVICE = 'lighthouse/maps/REMOVE_DEVICE'
export const REMOVE_DEVICES = 'lighthouse/maps/REMOVE_DEVICES'
export const CLEAR_DEVICES = 'lighthouse/maps/CLEAR_DEVICES'
export const SET_ASSET = 'lighthouse/maps/SET_ASSET'
export const SET_ASSETS = 'lighthouse/maps/SET_ASSETS'
export const REMOVE_ASSET = 'lighthouse/maps/REMOVE_ASSET'
export const REMOVE_ASSETS = 'lighthouse/maps/REMOVE_ASSETS'
export const CLEAR_ASSETS = 'lighthouse/maps/CLEAR_ASSETS'
export const SET_MARKER = 'lighthouse/maps/SET_MARKER'
export const REMOVE_MARKER = 'lighthouse/maps/REMOVE_MARKER'
export const CLEAR_MARKERS = 'lighthouse/maps/CLEAR_MARKERS'
export const SET_HEATMAP = 'lighthouse/maps/SET_HEATMAP'
export const REMOVE_HEATMAP = 'lighthouse/maps/REMOVE_HEATMAP'
export const SET_TIMELINE_PROPS = 'lighthouse/maps/SET_TIMELINE_PROPS'
export const ADD_TIMELINE_EXCEPTIONS = 'lighthouse/maps/ADD_TIMELINE_EXCEPTIONS'
export const CLEAR_TIMELINE_EXCEPTIONS = 'lighthouse/maps/CLEAR_TIMELINE_EXCEPTIONS'
export const RESET = 'lighthouse/maps/RESET'

// TODO HEATMAP

// The properties to pick for map objects (assets, devices)
const mapObjectProps = ['icon', 'zoneId', 'popup', 'geoJSON', 'timestamp']
const validDateProps = ['from', 'to', 'editing']
const validMarkerProps = ['type', 'icon', 'data', 'geoJSON']
const validTimelineProps = ['start', 'end', 'current', 'isLive', 'exceptionsStart', 'exceptionsEnd']
const validModes = ['live', 'heat']
const blankObject = {}

export function reducer(state, action = {}) {
  state = parseState(state)

  let existingTimelineState

  switch (action.type) {
    case SET_LOCATION:
      const end = new Date()
      end.setHours(23, 59, 59, 999)

      state = state.set('current', action.locationId)
      state = state.merge({
        [action.locationId]: assign(
          {},
          {
            timeline: { isLive: true, end },
          },
          state[action.locationId],
          action.options,
        ),
      })
      return state
    case SET_MODE:
      if (validModes.indexOf(action.mode) === -1) {
        console.warn('Invalid mode set for maps module', { mode: action.mode })
        return state
      }
      return state.set('mode', action.mode)
    case SET_DATES:

      if (state.dates) {
        const existingData = state.dates
        return state.merge({
          dates: existingData.merge(pick(action.props, validDateProps)),
        })
      }
      return state.merge({
        dates: pick(action.props, validDateProps),
      })


    case SET_LOADING:
      return state.merge({
        loading: action.value,
      })
    case SET_DEVICE:
      return state.setIn([
        action.locationId,
        'devices',
        action.deviceId,
      ], pick(action.data, mapObjectProps))
    case SET_DEVICES:
      action.data.forEach((item) => {
        state = state.setIn([
          action.locationId,
          'devices',
          item.id,
        ], pick(item, mapObjectProps))
      })
      return state
    case REMOVE_DEVICES:
      return state.setIn(
        [action.locationId, 'devices'],
        omit(state[action.locationId].devices, ...action.deviceIds),
      )
    case CLEAR_DEVICES:
      return state.setIn(
        [action.locationId, 'devices'],
        blankObject,
      )
    case SET_ASSETS:
      action.data.forEach((item) => {
        state = state.setIn([
          action.locationId,
          'assets',
          item.id,
        ], pick(item, mapObjectProps))
      })
      return state
    case REMOVE_ASSETS:
      return state.setIn(
        [action.locationId, 'assets'],
        omit(state[action.locationId].assets, ...action.assetIds),
      )
    case CLEAR_ASSETS:
      return state.setIn(
        [action.locationId, 'assets'],
        blankObject,
      )
    case SET_MARKER:
      const newMarkerProps = pick(action.props, validMarkerProps)
      const currentMarkerProps = get(state, `${action.locationId}.markers.${action.markerId}`) || Immutable({})
      return state.setIn([
        action.locationId,
        'markers',
        action.markerId,
      ], currentMarkerProps.merge(newMarkerProps, { deep: true }))
    case REMOVE_MARKER:
      return state.setIn(
        [action.locationId, 'markers'],
        omit(state[action.locationId].markers, action.markerId),
      )
    case CLEAR_MARKERS:
      return state.setIn(
        [action.locationId, 'markers'],
        blankObject,
      )
    case SET_HEATMAP:
      return state.setIn([
        action.locationId,
        'heatmap',
      ], { description: action.description, points: action.points })
    case REMOVE_HEATMAP:
      return state.setIn([
        action.locationId,
        'heatmap',
      ], null)
    case SET_TIMELINE_PROPS:
      existingTimelineState = get(state, `${action.locationId}.timeline`) || Immutable({})
      return state.setIn([
        action.locationId,
        'timeline',
      ], existingTimelineState.merge(pick(action.props, validTimelineProps)))
    case ADD_TIMELINE_EXCEPTIONS:
      existingTimelineState = get(state, `${action.locationId}.timeline.exceptions`) || Immutable([])
      return state.setIn([
        action.locationId,
        'timeline',
        'exceptions',
      ], existingTimelineState.concat(action.exceptions))
    case CLEAR_TIMELINE_EXCEPTIONS:
      return state.setIn([
        action.locationId,
        'timeline',
        'exceptions',
      ], [])
    case RESET:
      return Immutable({})
    default:
      return state
  }
}

export function setLocation(locationId, options) {
  return { type: SET_LOCATION, locationId, options }
}

export function setMode(mode) {
  return { type: SET_MODE, mode }
}

export function setLoading(value) {
  return { type: SET_LOADING, value }
}

export function setDates(props) {
  return { type: SET_DATES, props }
}

export function setDevice(locationId, deviceId, data) {
  return { type: SET_DEVICE, locationId, deviceId, data }
}

export function setDevices(locationId, data) {
  return { type: SET_DEVICES, locationId, data }
}
export function removeDevices(locationId, deviceIds) {
  return { type: REMOVE_DEVICES, locationId, deviceIds }
}

export function clearDevices(locationId) {
  return { type: CLEAR_DEVICES, locationId }
}

export function setAssets(locationId, data) {
  return { type: SET_ASSETS, locationId, data }
}

export function removeAssets(locationId, assetIds) {
  return { type: REMOVE_ASSETS, locationId, assetIds }
}

export function clearAssets(locationId) {
  return { type: CLEAR_ASSETS, locationId }
}

export function setMarker(locationId, markerId, props) {
  return { type: SET_MARKER, locationId, markerId, props }
}

export function removeMarker(locationId, markerId) {
  return { type: REMOVE_MARKER, locationId, markerId }
}

export function clearMarkers(locationId) {
  return { type: CLEAR_MARKERS, locationId }
}

export function setHeatmap(locationId, description, points) {
  return { type: SET_HEATMAP, locationId, description, points }
}

export function removeHeatmap(locationId) {
  return { type: REMOVE_HEATMAP, locationId }
}

export function setTimelineProps(locationId, props) {
  return { type: SET_TIMELINE_PROPS, locationId, props }
}

export function addTimelineExceptions(locationId, exceptions) {
  return { type: ADD_TIMELINE_EXCEPTIONS, locationId, exceptions }
}

export function clearTimelineExceptions(locationId) {
  return { type: CLEAR_TIMELINE_EXCEPTIONS, locationId }
}

export function reset() {
  return { type: RESET }
}

/*
 * Example State:
 * {
 *   maps: {
 *     current: {locationId},
 *     mode: 'live', // heat
 *     dates: {
 *       from: Date(),
 *       to: Date()
 *     },
 *     filters: {
 *       zones: true,
 *       devices: false
 *     },
 *     [locationId]: {
 *       floor: 'floor-id', // floors available from location cache
 *       // possible add a zones array, but we don't necessarily need
 *       // this as these are stored elsewhere in the state. The
 *       // question is whether we benefit from storing them here too
 *       zones: [{}],
 *       // markers are generic markers that can be used in arbitary
 *       // ways on the map. They hold data that should be interpreted
 *       // by UI logic to display/edit map data in whatever way
 *       markers: {
 *         [id]: {
 *           type: 'zone',
 *           icon: '',
 *           geoJSON: {},
 *           data: {}, // arbitary data associated with marker
 *         }
 *       },
 *       devices: {
 *         [deviceId]: {
   *         icon: 'person', // or wifi-hub
   *         // zoneId used to determine whether to show/hide the asset
   *         // based on the selected floor (component will handle that)
   *         zoneId: 'zoneId123',
   *         popup: 'Joe Bloggs', // or asset name 'Defibulator'
   *         geoJSON: {}
 *         }
 *       }],
 *       assets: {
 *         [assetId]: {
   *         icon: 'defibulator', // or patient
   *         // zoneId used to determine whether to show/hide the asset
   *         // based on the selected floor (component will handle that)
   *         zoneId: 'zoneId123',
   *         popup: 'Joe Bloggs', // or asset name 'Defibulator'
   *         geoJSON: {}
 *         }
 *       }],
 *       // For heat maps we need to think about handling differnt types
 *       // of heat maps. We could store each one in separate objects or
 *       // just hold a reference to the current type of heat map
 *       heatmap: {
 *         description: 'visits', // or servicing
 *         points: []
 *       },
 *       timeline: {
 *         start: Date(),
 *         end: Date(),
 *         current: Date(),
 *         exceptions: [{ start, end, weight }],
 *       }
 *     }
 *   }
 * }
 */
