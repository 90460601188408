import { Interval } from '../scheduling.types'

/**
 * Check if interval outside another interval
 * Accepts two intervals and checks whether other interval is in the past or future
 *
 * intervalOutside([1, 4], [5,15])
 * returns true
 */
export function intervalOutside(
  firstInterval: Interval,
  secondInterval: Interval
): Boolean {
  const future =
    firstInterval[0] > secondInterval[1] && firstInterval[1] > secondInterval[1]
  const past =
    firstInterval[0] < secondInterval[0] && firstInterval[1] < secondInterval[0]

  return future || past
}
