import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import styles from './styles'

function FilterTag(props) {
  const { onClear, tag } = props

  return (
    <div style={styles.tag}>
      <span onClick={onClear} style={styles.clearIcon}>
        x
      </span>
      <span style={styles.label}>
        <span style={styles.type}>
          {tag.category.label}:
          <span style={styles.value}>{tag.value.label}</span>
        </span>
      </span>
    </div>
  )
}

FilterTag.propTypes = {
  onClear: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
}

export default Radium(FilterTag)
