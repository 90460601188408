export interface PluginOption {
  name: string
  description: string
  fieldName: string
  default: any
  type: InputTypes
  disabled?: boolean
  isSubOption?: boolean
}

export enum InputTypes {
  Select,
  Text,
}
