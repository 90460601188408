import {
  actions,
} from '../'

import {
  parseState,
} from '../../../helpers'

export default function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case actions.AGGREGATE_REQUEST:
      return state.setIn(
        ['aggregate', 'state'],
        'resolving',
      )
    case actions.AGGREGATE_SUCCESS:
      state = state.setIn(['aggregate', 'state'], 'resolved')
      state = state.setIn(['aggregate', 'data'], action.data)
      state = state.setIn(['aggregate', 'error'], null)
      return state
    case actions.AGGREGATE_ERROR:
      state = state.setIn(['aggregate', 'state'], 'resolved')
      state = state.setIn(['aggregate', 'error'], action.error)
      return state
    default:
      return state
  }
}
