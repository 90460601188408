import { filter } from 'lodash'
import moment from 'moment-timezone'

export const buildLocationScansBoundaries = (start, timezone) => {
  const firstBoundary = moment.tz(start, timezone)
  const boundaries = []
  // add 9 boundaries (3 hours apart) to an array
  // these will mark the edges of 8 3-hour buckets of scan events
  // ex: [start, start+3hrs, start+6hrs, ..., start+24hrs]
  boundaries.push(firstBoundary)
  for (let i = 1; i < 9; i++) {
    const intervalSize = 3 * i
    const nextBoundary = moment(firstBoundary).add(intervalSize, 'hours')
    boundaries.push(nextBoundary)
  }
  return boundaries
}

export const buildLocationScansContent = (boundaries, events, timezone) => {
  // loop through boundaries filtering for events between the current boundary and the next one
  // skip the last one as it's the end and has no "next one"
  // ex: [interval1Events, interval2Events, ..., interval8Events] where interval1Events is an array
  // of events that start on or after boundary 1 and before boundary 2
  const eventsArray = []
  for (let i = 0; i < boundaries.length - 1; i++) {
    const eventsInThisInterval = filter(events, function(event) {
      const eventMoment = moment.tz(event.timestamp, timezone)
      return (
        eventMoment.isSameOrAfter(boundaries[i]) &&
        eventMoment.isBefore(boundaries[i + 1])
      )
    })
    // add events to interval
    eventsArray.push(eventsInThisInterval)
  }
  return eventsArray
}
