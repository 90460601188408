import React from 'react'
import { Field } from 'redux-form'
import { Block, Flex, Span, Text } from 'components/common'
import MaterialCheckbox from 'components/form/material-check-box'

import colors from 'config/theme/colors'

export default function GroupHeader(props) {
  const {
    canSkip,
    children,
    fieldGroupNumber,
    fieldGroupsLength,
    groupScoreString = '',
    hasScore = false,
    currSkipped,
    isEditing,
    label,
    formPath,
    readOnly,
    showDescription,
    prevSkipped,
  } = props

  const bottomColor = showDescription ? colors.gray.white : colors.gray.lightest

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        backgroundColor={colors.gray.white}
        borderBottom={`1px solid ${bottomColor}`}
        borderTop={`1px solid ${colors.gray.lightest}`}
        marginLeft={-10}
        marginRight={-10}
        paddingBottom={10}
        paddingLeft={10}
        paddingRight={10}
        paddingTop={10}
      >
        <Block flexGrow={1}>
          {fieldGroupsLength > 1 ? (
            <Span>
              <Text small inline>
                {label}
              </Text>
              <Span color={colors.gray.light} marginLeft={5}>
                <Text small inline>
                  {` (${fieldGroupNumber} of ${fieldGroupsLength})`}
                </Text>
              </Span>
            </Span>
          ) : (
            <Text small inline>
              {label}
            </Text>
          )}
        </Block>
        {canSkip && isEditing && (
          <Flex alignItems="center">
            <Title>SKIP</Title>
            <Field
              component={MaterialCheckbox}
              name={`${formPath}.skipped`}
              checked={currSkipped}
            />
          </Flex>
        )}
        {canSkip && readOnly && prevSkipped && <Title>SKIPPED</Title>}
        {readOnly && hasScore && !prevSkipped && (
          <Title>{groupScoreString}</Title>
        )}
      </Flex>
      {!currSkipped && children}
    </>
  )
}

function Title({ children: title }) {
  return (
    <Block alignItems="center" display="flex" paddingRight="8px">
      <Text small inline>
        {title}
      </Text>
    </Block>
  )
}
