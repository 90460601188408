export default {
  mapContainer: {
    position: 'absolute',
    height: '100%',
    left: 0,
    right: 0,
    transition: '0.5s',
  },
  sideBarOpen: {
    right: 360,
  },
}
