import { colors, typography } from 'config/theme'

export default {
  menu: {
    backgroundColor: '#fff',
    borderColor: colors.gray.lighter,
    borderStyle: 'solid',
    borderWidth: '1px 0 0 0',
    boxShadow: '0 4px 14px rgba(0,0,0,0.2)',
    position: 'absolute',
    minWidth: 180,
    zIndex: 600,
    marginTop: '0',
  },
  menuList: {
    overflowY: 'auto',
    maxHeight: 288,
  },
  searchWrapper: {
    backgroundColor: colors.gray.lightest,
    borderColor: colors.gray.lighter,
    borderStyle: 'solid',
    borderWidth: 1,
    borderTop: 0,
    color: colors.gray.light,
  },
  searchInput: {
    backgroundColor: 'transparent',
    border: 0,
    color: colors.gray.light,
    fontFamily: typography.defaultFontFamily,
    fontSize: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 12,
    paddingTop: 12,
  },
  spinner: {
    marginTop: 55,
    marginBottom: 55,
    textAlign: 'center',
  },
}
