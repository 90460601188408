import { sortBy } from 'lodash'
import { useSelector } from 'react-redux'
import React, { useState, useMemo } from 'react'
import Fuse from 'fuse.js'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'components/flags/hook'
import colors from 'config/theme/colors'

import { Block } from 'components/common'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'

import ControlWrapper from '../../../../../control-wrapper'
import FieldWrapper from '../../../../../field-wrapper'
import ListItem from '../../../../../list-item'
import Search from 'components/SearchBox'

export default function AuditSelect(props) {
  const {
    auditId,
    onCancel: handleCancel,
    onNext: handleNext,
    setAuditId,
  } = props

  const { t } = useTranslation()
  const auditsCache = useSelector(state => state.audits.cache)
  const [searchText, setSearchText] = useState('')
  const { isLighthouseAdmin } = useFlags()

  const auditTemplate = auditsCache[auditId] || {}
  const auditResolving = auditTemplate.state === 'resolving'
  const auditError = auditTemplate.error
  const isDisabled = !auditId || auditResolving || auditError

  const auditItemsArr = sortBy(auditsCache, ['entity.title'])
  const fuse = useMemo(() => {
    return new Fuse(auditItemsArr, {
      keys: ['entity.title'],
      threshold: 0.3,
    })
  }, [auditItemsArr])

  const searchResults = searchText
    ? fuse.search(searchText).map(result => {
        return { entity: result.item.entity }
      })
    : fuse.getIndex().docs

  const handleClick = entity => {
    setAuditId(entity._id)
  }

  const onSearchChange = e => {
    setSearchText(e.target.value)
  }

  const container = {
    borderBottom: '1px solid',
    borderColor: colors.gray.lightest,
  }

  const auditItems = searchResults.map(({ entity }, index) => {
    const isActive = auditId === entity._id
    const isLoading = isActive && auditResolving
    const hasError = isActive && auditError && !auditResolving

    return (
      <ListItem
        active={isActive}
        item={entity}
        key={index}
        hasError={hasError}
        isLoading={isLoading}
        onClick={() => handleClick(entity)}
      />
    )
  })

  return (
    <Block>
      <FieldWrapper width="100%" paddingRight="0px" paddingLeft="0px">
        {isLighthouseAdmin ? (
          <Search onChange={onSearchChange} containerStyles={container} />
        ) : null}
        {auditItems}
      </FieldWrapper>
      <ControlWrapper>
        <ButtonGroup>
          <Button dataTestId="cancel-button" onClick={handleCancel}>
            {t('button.cancel')}
          </Button>
          <Button
            dataTestId="next-button"
            disabled={isDisabled}
            theme="positive"
            onClick={handleNext}
          >
            {t('button.next')}
          </Button>
        </ButtonGroup>
      </ControlWrapper>
    </Block>
  )
}
