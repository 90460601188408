/**
 * A module that check permissions for you.
 * @module ask
 */
import { isArray, reduce, every, isEmpty, isString, includes, difference } from 'lodash'
import logger from '../logger'

// Enum for valid types
const types = ['module', 'document', 'field']

// Enum for valid actions
const actions = ['create', 'read', 'update', 'delete']

// Enum for valid strategies
const strategies = ['include', 'exclude']

// An array of validators in a particular order. The validation will stop at the first failure for each permission.
const validators = [
  validateModule,
  validateType,
  validateValue,
  validateAccess,
  validateStrategy
]

/**
 * Validate each field of the permission objects.
 * @param  {Array<Object>}   permissions An array of permission objects to validate.
 * @return Boolean
 * @see InvalidParameterError
 */
export default function validate(permissions) {
  if (!isArray(permissions)) {
    logger('`permissions` is required and it should be an array')
    return false
  }

  const permissionErrors = reduce(permissions, (errors, permission) => {
    // For each permission, break at the first failure
    every(validators, validator => validator(permission, errMessage => {
      if (errMessage) errors.push(errMessage)
    }))

    return errors
  }, [])

  if (!isEmpty(permissionErrors)) {
    logger(permissionErrors)
    return false
  }

  return true
}

/**
 * Validate `module` field of the permission object.
 * @param  {Object} permission    The permission object to validate.
 * @param  {Function} createAnError A callback function to create an error with a specified message.
 * @return {Boolean}  Return true if `type` field is valid, otherwise return false.
 */
function validateModule(permission, createAnError) {
  if (!isString(permission.module)) {
    createAnError('`module` is required and it should be a string')
    return false
  }

  if (permission.module.length === 0) {
    createAnError('`module` should not be empty')
    return false
  }

  return true
}

/**
 * Validate `type` field of the permission object.
 * @param  {Object} permission    The permission object to validate.
 * @param  {Function} createAnError  A callback function to create an error with a specified message.
 * @return {Boolean}  Return true if `type` field is valid, otherwise return false.
 */
function validateType(permission, createAnError) {
  if (!permission.type) {
    createAnError('`type` is required')
    return false
  }

  if (!includes(types, permission.type)) {
    createAnError('`type` should be `module`, `document`, or `field`')
    return false
  }

  return true
}

/**
 * Validate `value` field of the permission object.
 * @param  {Object} permission    The permission object to validate.
 * @param  {Function} createAnError A callback function to create an error with a specified message.
 * @return {Boolean}  Return true if `value` field is valid, otherwise return false.
 */
function validateValue(permission, createAnError) {
  if (permission.type !== 'module') {
    if (!isString(permission.value)) {
      createAnError('`value` is required when `type` is not `module` and it should be a string')
      return false
    }

    if (permission.value.length === 0) {
      createAnError('`value` should not be empty')
      return false
    }
  }

  return true
}

/**
 * Validate `access` field of the permission object.
 * @param  {Object} permission    The permission object to validate.
 * @param  {Function} createAnError A callback function to create an error with a specified message.
 * @return {Boolean}  Return true if `access` field is valid, otherwise return false.
 */
function validateAccess(permission, createAnError) {
  if (permission.access) {
    if (!isArray(permission.access)) {
      createAnError('`access` should be an array')
      return false
    }

    if (!isEmpty(difference(permission.access, actions))) {
      createAnError('`access` should be a set of `create`, `read`, `update` and `delete`')
      return false
    }
  }

  return true
}

/**
 * Validate `strategy` field of the permission object.
 * @param  {Object} permission    The permission object to validate.
 * @param  {Function} createAnError A callback function to create an error with a specified message.
 * @return {Boolean}  Return true if `strategy` field is valid, otherwise return false.
 */
function validateStrategy(permission, createAnError) {
  if (permission.type === 'module' && permission.strategy && !includes(strategies, permission.strategy)) {
    createAnError('`strategy` should be `include` or `exclude`')
    return false
  }

  return true
}
