import { constant, times } from 'lodash'
import { StaggeredMotion, spring } from 'react-motion'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import React from 'react'
import setPropTypes from 'recompose/setPropTypes'
import withHandlers from 'recompose/withHandlers'
import withPropsOnChange from 'recompose/withPropsOnChange'

import { Block } from 'components/common'

import Item from './components/item'

const PROP_TYPES = {
  defaultStyles: PropTypes.array.isRequired,
  getStyles: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  screenPosition: PropTypes.string.isRequired,
  setListVisibility: PropTypes.func.isRequired,
}

export default compose(
  withPropsOnChange(['items'], getDefaultStyles),
  withHandlers({ getStyles }),
  setPropTypes(PROP_TYPES)
)(List)

function List(props) {
  const {
    defaultStyles,
    getStyles,
    items,
    screenPosition,
    setListVisibility,
  } = props

  return (
    <StaggeredMotion defaultStyles={defaultStyles} styles={getStyles}>
      {interpolatingStyles => (
        <Block overflow="hidden">
          {interpolatingStyles.map((nextStyle, index) => {
            const item = items[index]

            return (
              <Item
                item={item}
                key={index}
                screenPosition={screenPosition}
                setListVisibility={setListVisibility}
                {...nextStyle}
              />
            )
          })}
        </Block>
      )}
    </StaggeredMotion>
  )
}

function getDefaultStyles(props) {
  const { items, positionProperty } = props

  const defaultValue = items.length * 100
  const defaultStyles = times(
    items.length,
    constant({ [positionProperty]: -defaultValue })
  )

  return {
    defaultStyles,
    defaultValue,
    positionProperty,
  }
}

function getStyles(props) {
  const {
    damping = 20,
    defaultValue,
    listVisible,
    positionProperty,
    stiffness = 150,
  } = props

  return previousStyles =>
    previousStyles.map((_, i) => {
      const value = listVisible ? 0 : -defaultValue

      if (i === 0) {
        return {
          [positionProperty]: spring(value, { stiffness, damping }),
        }
      }

      const nextStyles = previousStyles[i - 1]

      return {
        [positionProperty]: nextStyles[positionProperty],
      }
    })
}
