import Loadable from 'react-loadable'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PageLoader from 'components/page-loader'

const Entry = Loadable({
  loader: () => import('./components/entry/workEntry'),
  loading: PageLoader,
})

const List = Loadable({
  loader: () => import('./components/list'),
  loading: PageLoader,
})

export default function WorkRoute(): Switch {
  return (
    <Switch>
      <Route
        component={Entry}
        exact
        path={['/schedules/work/entry/:id', '/schedules/work/entry']}
      />
      <Route component={List} exact path="/schedules/work" />
      <Redirect to="/404" />
    </Switch>
  )
}
