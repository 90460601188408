import { get, filter, isEmpty, map } from 'lodash'

import {
  buildTemplateContent,
  generateDefinition,
  getFormattedAddress,
  horizontalLine,
  table,
  text,
  threeColumnTable,
} from '../helpers'

import {
  getIssueDetails,
  getStatusDetails,
  getTimezoneAbbr,
  getTimezoneDatetime,
} from '../../helpers'

/**
 * buildIssuePdf
 *
 * @param {object} pdfOptions - the pdf options
 * @param {string} pdfOptions.fileTitle - pdf file title
 * @param {function} pdfOptions.footer - function executed to generate footer
 * @param {function} pdfOptions.header - function executed to generate header
 * @param {string} pdfOptions.logoUrl - pdf logo url
 * @param {array} pdfOptions.pageMargins - pdf page margins
 * @param {string} pdfOptions.pageOrientation - pdf page orientation
 * @param {string} pdfOptions.pageSize - pdf page size
 * @param {object} pdfOptions.styles - pdf styles
 * @param {object} pdfOptions.title - pdf title
 * @param {object} data - pdf data
 * @param {object} data.entity - issue document
 * @param {object} data.locations - locations documents
 * @param {object} data.settings - settings properties
 * @param {string} data.settings.awsS3BaseUrl - aws S3 base url
 * @param {string} data.settings.cloudinaryBaseUrl - cloudinary base url
 * @param {string} data.timezone - timezone string
 * @param {object} data.users - application user documents
 * @param {object} data.zones - zone documents
 * @returns {Promise} returns pdfmake definition object
 */
export function buildIssuePdf(pdfOptions, data) {
  const { entity, timezone } = data

  const sequenceId = entity.sequenceId
  const timestamp = entity.createdAt
  const title = entity.title || 'Unknown'

  const fileTitle = `Issue Report - ${title}`

  return generateContent(data).then(content =>
    generateDefinition({
      content,
      fileTitle,
      sequenceId,
      timestamp,
      timezone,
      type: 'Issue',
      ...pdfOptions,
    })
  )
}

function generateContent(data) {
  const { entity, timezone } = data
  const { entry, gps = {}, timeline = [], title } = entity

  const entityDetails = getIssueDetails(data)

  const {
    assigneeNames,
    durationText,
    gpsText,
    locationText,
    statusColor,
    statusText,
  } = entityDetails

  const reverseGeocoded = gps.reverseGeocoded

  const subTitle = `${locationText || gpsText}`
  const headerTitle = text(title, { style: 'title' })
  const headerSubTitle = text(subTitle, { style: 'subTitle' })
  const address = !isEmpty(reverseGeocoded)
    ? getFormattedAddress(reverseGeocoded)
    : ''
  const headerAddress = text(address, { style: 'small' })

  const body = !isEmpty(reverseGeocoded)
    ? [[headerTitle], [headerSubTitle], [headerAddress]]
    : [[headerTitle], [headerSubTitle]]

  const titleTable = table({
    body,
    layout: 'noBorders',
    style: 'titleTable',
  })

  const statusLabelText = text('Status', { bold: true })
  const statusValueText = text(statusText, { color: statusColor })

  const metaTable = table({
    body: [[statusLabelText, statusValueText]],
    layout: 'noBorders',
    style: 'metaTable',
  })

  if (statusText === 'CLOSED') {
    const durationLabelText = text('Duration', { bold: true })
    const durationValueText = text(durationText)
    metaTable.table.body.push([durationLabelText, durationValueText])
  }

  const assigneeLabelText = text('Assignees', { bold: true })
  const assigneeValueText = text(assigneeNames)
  metaTable.table.body.push([assigneeLabelText, assigneeValueText])

  const timelineTitle = text('Timeline', { style: 'timelineTitle' })
  const timelineHorizontalLine = horizontalLine()
  const timelineTable = createTimelineTable(timeline, timezone)

  return buildTemplateContent(entry.formGroups, data).then(entry => [
    titleTable,
    metaTable,
    timelineTitle,
    timelineHorizontalLine,
    timelineTable,
    ...entry,
  ])
}

function createTimelineTable(timeline, timezone) {
  const timezoneAbbr = getTimezoneAbbr(timezone)

  const timelineHeaderRow = [
    text('Activity', { bold: true }),
    text('User', { bold: true }),
    text(`Date & Timestamp (${timezoneAbbr})`, {
      alignment: 'right',
      bold: true,
    }),
  ]

  const statusUpdates = filter(timeline, ['meta.field', 'status'])

  const timelineBodyRows = isEmpty(statusUpdates)
    ? [text('No Timeline activity', { colSpan: 3 })]
    : map(statusUpdates, item => {
        const { meta = {}, source, timestamp, type } = item

        const next = get(meta, 'next')
        const firstName = get(source, 'meta.firstName')
        const lastName = get(source, 'meta.lastName')

        const fullName =
          firstName && lastName ? `${firstName} ${lastName}` : 'Unknown User'

        const isNew = type === 'issue-new'
        const isUpdate = type === 'issue-update'
        const isOpen = next === 'open'

        const statusDetails = getStatusDetails(next)
        const nextStatusText = statusDetails.text

        const activity =
          isNew && isOpen
            ? 'Issue opened'
            : isNew && !isOpen
            ? `Issue opened with status ${nextStatusText}`
            : isUpdate
            ? `Issue updated to ${nextStatusText}`
            : 'Unknown status timeline change'

        const datetime = getTimezoneDatetime({
          format: 'ddd, MMM D h:mm:ssa z',
          showTzAbbr: false,
          timestamp,
          timezone,
        })

        return [
          text(activity),
          text(fullName),
          text(datetime, { alignment: 'right' }),
        ]
      })

  const timelineTable = threeColumnTable({
    body: [timelineHeaderRow, ...timelineBodyRows],
  })

  return timelineTable
}
