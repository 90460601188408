/*
 * Redux middleware to communicate with API
 * https://github.com/rackt/redux/blob/master/examples/real-world/middleware/api.js
 */

import Symbol from 'es6-symbol'
import retry from 'bluebird-retry'
import queryString from 'query-string'

import moment from 'moment-timezone'

import {
  assign,
  chain,
  get,
  includes,
  isEmpty,
} from 'lodash'

import { AUTHENTICATE_ERROR } from '../modules/authentication'
import request from '../request'
import * as errors from '../errors'

const datetimeFilters = ['from', 'to']

// ~1.5s max retry time
const defaultRetryOpts = {
  max_tries: 2,
  interval: 1000,
  predicate: (err) => {
    const shouldRetry = !err.status || err.status >= 500
    return shouldRetry
  },
}

// Action key that carries API call info interpreted by this Redux middleware.
export const REQUEST = Symbol('REQUEST')

export default function createRequestMiddleware(baseUrl = '/', opts = {}) {
  // A Redux middleware that interprets actions with REQUEST info specified.
  // Performs the call and promises when such actions are dispatched.
  return store => next => async (action) => {
    const requestAction = action[REQUEST]
    const state = store.getState()
    const skipRequest = typeof requestAction === 'undefined'
    const isOffline = get(state, 'offline.online') === false

    if (skipRequest) {
      return next(action)
    }

    let {
      endpoint,
    } = requestAction

    const {
      attachToActions = {},
      body,
      headers = {},
      method = 'GET',
      query = {},
      respectTimezone = true,
      timeout,
      types,
    } = requestAction

    const isRead = method === 'GET'

    if (isOffline && isRead) {
      return
    }

    const [requestType, successType, failureType, paginationType] = types

    // attach application session id
    headers['X-Session-Id'] = get(state, 'app.sessionId')

    const timezone = get(state, 'app.timezone')

    const hasQueryParams = query && Object.keys(query).length > 0

    if (typeof endpoint === 'function') {
      endpoint = endpoint(state)
    }
    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.')
    }
    if (!Array.isArray(types) || types.length < 3) {
      throw new Error('Expected an array of at least three action types.')
    }
    if (!types.every(type => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.')
    }

    const requestObj = { type: requestType }

    const fetchOpts = {
      method,
      headers,
      timeout,
    }

    if (hasQueryParams) {
      // remove any params which are empty strings and transform date filters
      const sanitizedQuery = buildSanitizedQuery(query, timezone, respectTimezone)

      const queryStringified = queryString.stringify(sanitizedQuery, { arrayFormat: 'bracket' })
      endpoint = `${endpoint}?${queryStringified}`

      // if there are query params, attch to the requestObj for
      // convenience. Useful for reducers and tests
      requestObj.query = query
    }

    if (body) {
      // our reducer expects a `data` property
      requestObj.data = body
      fetchOpts.body = JSON.stringify(body)
    }

    const retryOpts = assign(
      {},
      defaultRetryOpts,
      opts.retryOpts,
      requestAction.retryOpts,
    )

    // NOTE Attach retryOpts for testing purposes. We override retryOpts in our
    // mock store so it's difficult to assert behaviour, so here we're attaching
    // the opts which is easy to hook into for tests
    requestObj.retryOpts = retryOpts

    next(actionWith(requestObj))

    // if endpoint contains a baseUrl, use that, othrwise prepend config baseUrl
    const fullUrl = (endpoint.indexOf('://') === -1) ? baseUrl + endpoint : endpoint

    const requestHelpers = {
      requiresAuthorization: requestAction.requiresAuthorization,
      getAuthorization: opts.getAuthorization,
    }

    let attempts = 0

    try {
      const response = await retry(async () => {
        ++attempts

        try {
         return await request(fullUrl, fetchOpts, requestHelpers)
        } catch (error) {
          // Notify reducer of error + retry
          if (retryOpts.max_tries > 1) {
            const actionObj = {
              type: failureType,
              error,
              retrying: {
                attempt: attempts,
              },
              url: fullUrl,
            }

            next(actionWith(actionObj))
          }

          throw error
        }
      }, retryOpts)

      // Successful response
      const {
        json,
        links,
        totalCount,
        lastKey,
      } = response

      const successObj = { type: successType }

      if (json) {
        successObj.data = json
      }

      // Note that we want to call the success action and then the
      // successful server response, but what we want to return is
      // the result of the success action, so it's useful
      const result = next(actionWith(successObj))

      if (paginationType) {
        next(actionWith({
          type: paginationType,
          links,
          totalCount,
          lastKey,
        }))
      }

      return result
    } catch (retryError) {
      // NOTE We're catching a retry error here, which gives us the context of
      // the original error under the `failure` key. The retryError is useful
      // for logging, but for state we're only interested in the original
      // error
      const error = retryError.failure || retryError
      const isAuthenticationError = (error instanceof errors.AuthenticationError)

      if (isAuthenticationError) {
        next(actionWith({
          type: AUTHENTICATE_ERROR,
          error,
        }))
      }

      const actionObj = {
        type: failureType,
        error,
        url: fullUrl,
      }

      // Dispatch failed request
      next(actionWith(actionObj))

      throw error
    }

    function actionWith(response) {
      const finalAction = Object.assign(
        {},
        action,
        attachToActions,
        response,
      )

      delete finalAction[REQUEST]
      return finalAction
    }
  }
}

function buildSanitizedQuery(query, timezone, respectTimezone) {
  return chain(query)
    .omit(isEmpty)
    .transform((result, value, key) => {
      if (!includes(datetimeFilters, key)) {
        result[key] = value
        return
      }

      const localTimeText = moment(value).format('YYYY-MM-DD HH:mm:ss.SSS')

      const isoDateTime = respectTimezone
        ? moment.tz(localTimeText, timezone)
        : moment(localTimeText)

      result[key] = isoDateTime.toISOString()
    }, {})
    .value()
}
