import { InputTypes, PluginOption } from './options.types'

const options: PluginOption[] = [
  {
    name: 'Data Lake',
    description: 'Enable Data Lake',
    fieldName: 'dataLake.enabled',
    default: false,
    type: InputTypes.Select,
  },
  {
    name: 'AWS Customer Account Id',
    description: 'Id for the customer account',
    fieldName: 'dataLake.secrets.awsCustomerAccountId',
    default: '',
    type: InputTypes.Text,
  },
]

export default options
