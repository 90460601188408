import colors from 'config/theme/colors'
import measurements from 'config/theme/measurements'

export default {
  root: {
    display: 'flex',
  },
  link: {
    alignItems: 'center',
    alignSelf: 'stretch',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '0 30px',
    textDecoration: 'none',
    fontSize: '12px',
    fontWeight: '500',
    height: 60,
  },
  linkBordered: {
    base: {
      borderStyle: 'solid',
      borderColor: colors.gray.lighter,
    },
    left: {
      borderWidth: '0 0 0 1px',
    },
    right: {
      borderWidth: '0 1px 0 0',
    },
  },
  linkNarrow: {
    padding: '0 20px',
  },
  linkBlue: {
    color: colors.blue.normal,
  },
  primary: {
    height: 60,
  },
  secondary: {
    height: 60,
    position: 'relative',
    maxWidth: measurements.fixedWidth,
    margin: '0 auto',
    padding: '0 30px',
  },
  secondaryWrapper: {
    borderStyle: 'solid',
    borderColor: colors.gray.lightest,
    borderWidth: 0,
    borderBottomWidth: 1,
  },
}
