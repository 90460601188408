import { compose } from 'recompose'
import Promise from 'bluebird'
import Radium from 'radium'
import React, { Component } from 'react'
import ReactTimeout from 'react-timeout'

import Spinner from 'components/spinner'
import * as logger from 'utils/logger'

import styles from './styles'

const DEFAULT_WAIT_TIME = 400 // ms
const defaultOptions = {}
const enhance = compose(ReactTimeout, Radium)

Promise.config({
  // enable bluebird cancelation
  cancellation: true,
})

/*
 * States
 * ------
 *  init: component loaded
 *  resolving: dependency is loading
 *  waiting: dependency is taking longer than specified wait time
 *  resolved: dependecy request has completed (data available)
 *  error: dependency could not be resolved

/**
 * Simple Resolver HOC to ensure data (promises) are loaded before
 * rendering a component
 * @param {Promise} resolver
 */
export default (resolver, options = defaultOptions) => ComposedComponent => {
  class ResolverHoc extends Component {
    // NOTE It's important that we call cancel on the promise supplied when the
    // component unmounts, because it's perfectly acceptable for the user to navigate
    // away from a page before the promise (ususally an API request) has resolved.
    // This stops the promise chain calling setState operations on the unmounted
    // component
    cancelableResolver = null

    state = {
      state: 'init',
    }

    componentWillMount() {
      const { wait = DEFAULT_WAIT_TIME } = options

      this.setState({ state: 'resolving' })

      this.props.setTimeout(() => {
        // if data is still resolving, set 'waiting'
        if (this.state.state === 'resolving') {
          this.setState({ state: 'waiting' })
        }
      }, wait)

      // see NOTE above
      this.cancelableResolver = Promise.resolve(resolver(this.props))

      this.cancelableResolver
        .then(() => this.setState({ state: 'resolved' }))
        .catch(error => {
          logger.error(error)
          this.setState({ state: 'error' })
          throw error
        })
    }

    componentWillUnmount() {
      if (this.cancelableResolver) {
        // see NOTE above
        this.cancelableResolver.cancel()
      }
    }

    render() {
      // resolving should show nothing
      // waiting should show loader
      // resolved should show component
      // error should show message

      const { state } = this.state
      const isWaiting = state === 'waiting'
      const isResolved = state === 'resolved'
      const isErrored = state === 'error'

      let content

      if (isWaiting) {
        content = (
          <div style={[styles.spinner]}>
            <Spinner />
          </div>
        )
      } else {
        if (isResolved) {
          content = <ComposedComponent {...this.props} />
        } else if (isErrored) {
          content = null // TODO handle error
        }
      }

      return <div style={[styles.root]}>{content}</div>
    }
  }

  return enhance(ResolverHoc)
}
