import { compose, lifecycle, withState } from 'recompose'
import { stubObject } from 'lodash'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

export default compose(
  withRouter,
  withState('urlQuery', 'setUrlQuery', () =>
    queryString.parse(location.search)
  ),
  lifecycle({ componentDidMount, componentWillUnmount })
)

function componentDidMount() {
  const { history, setUrlQuery } = this.props

  this.historyUnlisten = history.listen(location => {
    const nextUrlQuery = queryString.parse(location.search)
    setUrlQuery(nextUrlQuery)
  })
}

function componentWillUnmount() {
  this.historyUnlisten()
}
