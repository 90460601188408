import { WithPermissions } from '@lighthouse/react-components'
import { compose, pure } from 'recompose'
import { map } from 'lodash'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import { Block, Flex } from 'components/common'
import { colors } from 'config/theme'
import MenuItem from './components/menu-item'
import { useTranslation } from 'react-i18next'

export default compose(pure, WithPermissions, withRouter)(Toolbar)

function Toolbar({
  hasModulePermission,
  history,
  members,
  name,
  selectedGroup,
}) {
  const groupMembers = map(members, member => ({ text: member.fullName }))

  const { t } = useTranslation()

  const groupActions = [
    {
      onClick: () => history.push(`/messages/groups/${selectedGroup}`),
      text: t('labelEditGroupDetails'),
    },
  ]

  const hasMessageGroupUpdatePermission = hasModulePermission(
    'messageGroups',
    'update'
  )

  return (
    <Block>
      <Flex backgroundColor={colors.gray.lightest} height={48}>
        <MenuItem bold content={name} flexGrow={1} />
        <MenuItem
          content={groupMembers.length}
          dropdownItems={groupMembers}
          enableSearch
          icon="users"
        />
        {hasMessageGroupUpdatePermission ? (
          <MenuItem dropdownItems={groupActions} icon="hamburger" />
        ) : null}
      </Flex>
    </Block>
  )
}

Toolbar.propTypes = {
  members: PropTypes.array,
  name: PropTypes.string,
  selectedGroup: PropTypes.string,
}
