/*
 * Logs Module
 * Responsible for logging errors/warnings to be sent to external services E.g.
 * handling connection/server errors, usage logs. We handle withing redux to
 * take advantage of persisted state while offline NOTE: unhandled exceptions
 * would not come via here, only caught exceptions. Unhandled should be handled
 * seperately with stack trace etc.
 *
 * Example State:
 * ==============
 *
 * {
 *   queue: [
 *     {
 *       message: 'Some error occurred',
 *       data: {
 *         foo: 'bar',
 *       },
 *       timestamp: <Date>,
 *     }
 *   ]
 * }
 */

import cuid from 'cuid'

import {
  each,
  includes,
  pick,
} from 'lodash'

import { parseState } from '../../helpers'

export const LEVEL_DEBUG = 'debug'
export const LEVEL_INFO = 'info'
export const LEVEL_WARN = 'warn'
export const LEVEL_ERROR = 'error'
export const LEVEL_CRITICAL = 'critical'

export const STATUS_IDLE = 'idle'
export const STATUS_PROCESSING = 'processing'

export const VALID_STATUSES = [STATUS_IDLE, STATUS_PROCESSING]
export const VALID_LEVELS = [LEVEL_DEBUG, LEVEL_INFO, LEVEL_WARN, LEVEL_ERROR, LEVEL_CRITICAL]
export const RECORD = 'lighthouse/logs/RECORD'
export const SET_STATUS = 'lighthouse/logs/SET_STATUS'
export const REMOVE = 'lighthouse/logs/REMOVE'

const defaultState = {
  queue: {},
}

export function reducer(state, action = {}) {
  state = parseState(state, defaultState)

  switch (action.type) {
    case RECORD:
      return state.setIn(['queue', action.id], {
        ...action.log,
        status: STATUS_IDLE,
      })
    case SET_STATUS:
      each(action.ids, id => (
        state = state.setIn(['queue', id, 'status'], action.status)
      ))
      return state
    case REMOVE:
      return state.set('queue', state.queue.without(action.ids))
    default:
      return state
  }
}

export function record(level, log) {
  const id = cuid()
  const isValidLevel = includes(VALID_LEVELS, level)
  const logLevel = isValidLevel ?
                  level :
                  LEVEL_INFO

  const logFields = pick(log, 'message', 'data')
  const timestamp = log.timestamp || new Date()

  return {
    type: RECORD,
    id,
    log: {
      ...logFields,
      level: logLevel,
      timestamp,
    },
  }
}

export function setStatus(ids, logStatus) {
  const isValidStatus = includes(VALID_STATUSES, logStatus)
  const status = isValidStatus ?
                    logStatus :
                    STATUS_IDLE
  return {
    type: SET_STATUS,
    ids,
    status,
  }
}

export function remove(ids) {
  return {
    type: REMOVE,
    ids,
  }
}
