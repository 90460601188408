import { assign } from 'lodash'
import { AutoSizer, WindowScroller } from 'react-virtualized'
import React from 'react'

export default ScrollWrapper

function ScrollWrapper({ onRowsRendered, registerChild }, childComponent) {
  return (
    <WindowScroller>
      {props =>
        AutoSizerWrapper(
          assign(props, { onRowsRendered, registerChild }),
          childComponent
        )
      }
    </WindowScroller>
  )
}

function AutoSizerWrapper(props, childComponent) {
  return (
    <AutoSizer disableHeight>
      {({ width }) => childComponent(assign(props, { width }))}
    </AutoSizer>
  )
}
