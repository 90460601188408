import React from 'react'
import { Span } from 'components/common'
import { CheckBox, MaterialCheckbox } from 'components/form'

export default function CellCheckbox(props) {
  const {
    checked,
    disabled,
    isMaterial,
    label,
    onChange,
    styles,
    value,
    testId,
  } = props

  return (
    <Span {...styles}>
      {isMaterial ? (
        <MaterialCheckbox
          checked={checked}
          dataTestId={testId}
          disabled={disabled}
          onChange={onChange}
          value={value}
        />
      ) : (
        <CheckBox disabled={disabled} onChange={onChange} value={value} />
      )}
      {label && <span>{label}</span>}
    </Span>
  )
}
