import { InputTypes, PluginOption } from './options.types'

const options: PluginOption[] = [
  {
    name: 'Dayforce',
    description: 'Enable the Dayforce Integration',
    fieldName: 'dayforce.enabled',
    default: false,
    type: InputTypes.Select,
  },
]

export default options
