export default {
  root: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  spinner: {
    marginTop: 55,
    marginBottom: 55,
  },
}
