import React, { Fragment } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'

import Button from 'components/button'
import { Block, Flex, Span } from 'components/common'
import DateTimePicker from 'components/date-time-picker-deprecated'
import DatetimeTimezone from 'components/datetime-timezone'
import Icon from 'components/icon'
import { colors } from 'config/theme'
import { useTranslation } from 'react-i18next'

function DatePicker(props) {
  const { dateFormat, onChange, readOnly, value } = props

  const { t } = useTranslation()

  return readOnly ? (
    <Block padding={10} wordBreak="break-word">
      {isEmpty(value) ? (
        ''
      ) : (
        <DatetimeTimezone appendTimezone datetime={value} format={dateFormat} />
      )}
    </Block>
  ) : (
    <Flex alignItems="center" width="100%">
      <DateTimePicker
        allowAllDates
        disableTimepicker
        onChange={onChange}
        numberOfMonths={1}
        position={'bl'}
        width="100%"
        value={value || moment().format()}
      >
        <Block
          borderColor={colors.gray.lighter}
          borderRadius="4px"
          borderStyle="solid"
          borderWidth="1px"
          boxSizing="border-box"
          color={colors.gray.darker}
          fontSize="12px"
          maxHeight="48px"
          marginRight="10px"
          outline="none"
          padding="14px"
          width="100%"
        >
          <Flex justifyContent="space-between" width="100%">
            <Span
              color={value ? colors.gray.darker : colors.gray.light}
              fontWeight={300}
            >
              {value ? (
                <DatetimeTimezone
                  appendTimezone
                  datetime={value}
                  format={dateFormat}
                />
              ) : (
                'Select date'
              )}
            </Span>
            <Fragment>
              <Icon name="calendar" />
            </Fragment>
          </Flex>
        </Block>
      </DateTimePicker>
      <Button
        minWidth="80px"
        onClick={value ? () => onChange('') : () => onChange(new Date())}
      >
        {value ? t('button.clear') : t('button.today')}
      </Button>
    </Flex>
  )
}

export default DatePicker
