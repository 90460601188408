import moment from 'moment-timezone'

export function getTimezoneDatetime(options) {
  const {
    format = 'ddd, MMM D h:mm:ssa', // Mon, Jan 21 12:59am
    showTzAbbr = true,
    timestamp = moment(),
    timezone,
  } = options

  const mTimezoneTime = moment.tz(timestamp, timezone)
  const datetimeText = mTimezoneTime.format(format)
  const timezoneText = mTimezoneTime.format('z')

  return showTzAbbr ? `${datetimeText} ${timezoneText}` : datetimeText
}
