import { map, orderBy, values } from 'lodash'
import { compose, pure, withProps } from 'recompose'
import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'components/common'
import GroupItem from '../group-item'

export default compose(
  withProps(({ groups }) => ({
    orderedGroups: orderBy(groups, ['date'], ['desc']),
  }))
)(GroupList)

function GroupList(props) {
  const { onGroupClick, orderedGroups, selected } = props

  return (
    <Flex flexDirection="column">
      {map(orderedGroups, ({ date, id, name, unread }) => (
        <GroupItem
          date={date}
          isActive={selected === id}
          isUnread={unread}
          key={id}
          name={name}
          onClick={() => onGroupClick(id)}
        />
      ))}
    </Flex>
  )
}

GroupList.propTypes = {
  onGroupClick: PropTypes.func.isRequired,
  orderedGroups: PropTypes.array,
  selected: PropTypes.string,
}
