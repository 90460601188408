import { compact, isEmpty } from 'lodash'
import Promise from 'bluebird'

import {
  buildTemplateFieldRow,
  horizontalLine,
  table,
  text,
  twoColumnTable,
} from '../'

export const buildTemplateContent = Promise.method((formGroups = [], data) => {
  const { settings, timezone } = data

  return Promise.map(formGroups, ({ label, fieldGroups, skipped = false }) =>
    Promise.map(fieldGroups, ({ fields }) =>
      Promise.map(fields, field =>
        buildTemplateFieldRow({ field, settings, timezone, skipped })
      ).then(fieldGroupRows => {
        const rows = compact(fieldGroupRows)

        // NOTE if empty return null so we can
        // prevent this field group section from
        // being created in the pdf. This can occur
        // when we have a display text field
        if (isEmpty(rows)) {
          return null
        }

        return twoColumnTable({
          headerRows: 0,
          body: [...rows],
        })
      })
    ).then(fieldGroupTables => {
      const fieldGroups = compact(fieldGroupTables)

      if (isEmpty(fieldGroups)) {
        return []
      }

      const groupHeaderTable = table({
        body: [[text(label)]],
        layout: 'noBorders',
        style: 'groupHeaderTable',
        widths: ['*'],
      })

      const hLine = horizontalLine()

      return [groupHeaderTable, hLine, ...fieldGroups]
    })
  )
})
