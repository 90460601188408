import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'

import Icon from 'components/icon'

import styles from './styles'

function ToolbarButton(props) {
  const {
    count,
    dataTestId,
    icon,
    isSelected,
    style,
    onClick: handleClick,
  } = props

  const tabStyles = [styles.root, style, isSelected && styles.selected]

  return (
    <button data-testid={dataTestId} style={tabStyles} onClick={handleClick}>
      <Icon name={icon} theme={styles.icon} />
      {count && <span style={styles.count}>{count}</span>}
      {isSelected && (
        <span style={styles.arrowOuter}>
          <span style={styles.arrowInner} />
        </span>
      )}
    </button>
  )
}

export default Radium(ToolbarButton)

ToolbarButton.propTypes = {
  count: PropTypes.number,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}
