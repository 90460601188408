import { colors, typography } from 'config/theme'
import { assign } from 'lodash'

const verticalOffset = 55
const position = {
  top: {
    bottom: verticalOffset,
  },
  bottom: {
    top: verticalOffset,
  },
  left: {
    left: 0,
  },
  right: {
    right: 0,
  },
}

export default {
  root: {
    position: 'relative',
    display: 'flex',
  },
  dateSelect: {
    backgroundColor: colors.white,
    borderColor: colors.gray.lighter,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxSizing: 'border-box',
    color: colors.gray.darker,
    display: 'block',
    fontFamily: typography.defaultFontFamily,
    fontSize: '14px',
    fontWeight: 300,
    maxHeight: '48px',
    outline: 'none',
    overflow: 'hidden',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '14px',
    paddingRight: '14px',
    width: '100%',
  },
  datepicker: {
    position: 'absolute',
    backgroundColor: colors.white,
    zIndex: 100,
    borderWidth: 1,
    borderColor: colors.gray.lighter,
    borderStyle: 'solid',
    outline: 'none',
  },
  timesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  select: {
    width: '100%',
  },
  to: {
    lineHeight: '45px',
    marginLeft: 20,
    marginRight: 20,
  },
  actionButton: {
    marginLeft: 10,
    marginRight: 0,
    height: 46,
  },
  position: {
    tr: assign({}, position.top, position.right),
    tl: assign({}, position.top, position.left),
    bl: assign({}, position.bottom, position.left),
    br: assign({}, position.bottom, position.right),
  },
}
