import { REQUEST } from '../../../middleware/request'

export default function buildActionCreators(actions, baseUrlFn, headersFn) {
  return {
    tagDocuments,
    tagDocument,
    removeDocumentTag,
    markDocumentForTagging,
    unmarkDocumentForTagging,
    clearDocumentsMarkedForTagging,
  }

  function tagDocuments(params) {
    return (dispatch, getState) => {
      const state = getState()
      const endpoint = `${baseUrlFn(params, state)}/tags/${params.label}`
      const payload = { documents: params.documents }
      const headers = headersFn(params, state)
      return dispatch(tagDocumentsRequest(endpoint, headers, payload))
    }
  }

  function tagDocumentsRequest(endpoint, headers, payload) {
    return {
      [REQUEST]: {
        types: [actions.TAG_DOCUMENTS_REQUEST, actions.TAG_DOCUMENTS_SUCCESS, actions.TAG_DOCUMENTS_ERROR],
        method: 'POST',
        endpoint,
        headers,
        body: payload,
      },
    }
  }

  function tagDocument(params) {
    return (dispatch, getState) => {
      const state = getState()
      const endpoint = `${baseUrlFn(params, state)}/${params.id}/tags`
      const headers = headersFn(params, state)
      const payload = { tags: params.tags }
      return dispatch(tagDocumentRequest(endpoint, headers, payload))
    }
  }

  function tagDocumentRequest(endpoint, headers, payload) {
    return {
      [REQUEST]: {
        types: [actions.TAG_DOCUMENT_REQUEST, actions.TAG_DOCUMENT_SUCCESS, actions.TAG_DOCUMENT_ERROR],
        method: 'POST',
        endpoint,
        headers,
        body: payload,
      },
    }
  }

  function removeDocumentTag(params) {
    return (dispatch, getState) => {
      const state = getState()
      const endpoint = `${baseUrlFn(params, state)}/${params.id}/tags/${params.label}`
      const headers = headersFn(params, state)
      return dispatch(removeDocumentTagRequest(endpoint, headers, params))
    }
  }

  function removeDocumentTagRequest(endpoint, headers) {
    return {
      [REQUEST]: {
        types: [actions.REMOVE_DOCUMENT_TAG_REQUEST, actions.REMOVE_DOCUMENT_TAG_SUCCESS, actions.REMOVE_DOCUMENT_TAG_ERROR],
        method: 'DELETE',
        endpoint,
        headers,
      },
    }
  }

  function markDocumentForTagging(resource, documentId) {
    return {
      type: actions.MARK_DOCUMENT_FOR_TAGGING,
      resource,
      documentId,
    }
  }

  function unmarkDocumentForTagging(resource, documentId) {
    return {
      type: actions.UNMARK_DOCUMENT_FOR_TAGGING,
      resource,
      documentId,
    }
  }

  function clearDocumentsMarkedForTagging(resource) {
    return {
      type: actions.CLEAR_DOCUMENTS_MARKED_FOR_TAGGING,
      resource,
    }
  }
}
