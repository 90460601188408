import PropTypes from 'prop-types'
import React from 'react'

import { Block, Flex } from 'components/common'

export default function LegendItem(props) {
  const { icon, label } = props

  return (
    <Flex alignItems="center">
      <Block>{icon}</Block>
      <Block fontSize="10px" fontWeight={500} color="#666">
        {label}
      </Block>
    </Flex>
  )
}

LegendItem.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
}
