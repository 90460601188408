import Radium from 'radium'
import React from 'react'

const defaultStyle = {
  borderBottom: '1px solid #eee',
  color: '#888',
  lineHeight: '1.5em',
  verticalAlign: 'top',
  paddingTop: '1em',
  paddingBottom: '1em',
  whiteSpace: 'pre-line',
}

export default Radium(Cell)

function Cell({ children, colSpan, style, ...customStyles }) {
  return (
    <td colSpan={colSpan} style={[defaultStyle, style, customStyles]}>
      {children}
    </td>
  )
}
