import colors from 'config/theme/colors'

export default {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 10,
  },

  center: {
    justifyContent: 'center',
  },

  right: {
    justifyContent: 'flex-end',
  },

  stacked: {
    flexDirection: 'column',
    overflow: 'hidden',
  },

  border: {
    borderTop: `1px solid ${colors.gray.lighter}`,
  },
}
