import React from 'react'
import colors from 'config/theme/colors'
import styles from 'components/form/input/styles.js'
import { isEmpty } from 'lodash'

import { Block, Flex, Text } from 'components/common'
import { ErrorMessage } from 'formik'

export default function InputRow(props) {
  const { name, radiumStyles } = props
  return (
    <Flex
      flexDirection={'column'}
      borderBottom={`1px solid ${colors.gray.lightest}`}
    >
      <Flex
        alignItems="center"
        display="flex"
        fontSize={12}
        justifyContent="space-between"
        marginLeft={-15}
        marginRight={-15}
        paddingBottom={12}
        paddingLeft={15}
        paddingRight={15}
        paddingTop={12}
        radiumStyles={radiumStyles}
      >
        {props.children}
      </Flex>
      <ErrorMessage name={name}>
        {msg => {
          if (isEmpty(msg)) {
            return
          } else if (typeof msg === 'object') {
            return msg && msg[name] ? (
              <Block padding={'5px'}>
                <Text>{msg[name]}</Text>
              </Block>
            ) : null
          } else if (typeof msg === 'string') {
            return (
              <Block marginBottom={'4px'}>
                <span
                  style={{
                    ...styles.message,
                    ...styles.errorMessage,
                  }}
                >
                  {msg}
                </span>
              </Block>
            )
          }
        }}
      </ErrorMessage>
    </Flex>
  )
}
