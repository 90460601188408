import colors from 'config/theme/colors'

export default {
  row: {
    display: 'flex',
    borderBottom: `1px solid ${colors.gray.lighter}`,
    alignItems: 'center',
    padding: 0,
  },
  rowDuress: {
    borderTop: `3px solid ${colors.red.normal}`,
    backgroundColor: `${colors.red.lightest}`,
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    width: 44,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  activityIcon: {
    fontSize: '24px',
  },
  duressIcon: {
    fontSize: '24px',
    color: colors.red.normal,
  },
  iconPositive: {
    color: colors.green.normal,
  },
  iconNegative: {
    color: colors.red.normal,
  },
  time: {
    fontSize: '11px',
    color: colors.gray.normal,
  },
  details: {
    flexGrow: 1,
  },
  row1: {
    fontSize: '13px',
    fontWeight: 300,
    margin: 0,
    color: colors.gray.normal,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 250,
  },
  row2: {
    fontSize: '11px',
    fontWeight: 300,
    margin: 0,
    color: colors.gray.normal,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 250,
  },
  row3: {
    fontSize: '11px',
    fontWeight: 300,
    margin: 0,
    color: colors.gray.light,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 250,
  },
  findButton: {
    padding: '0 10px',
    marginLeft: 'auto',
    marginRight: 10,
  },
  targetButton: {
    padding: '0 10px',
    marginLeft: 'auto',
    marginRight: 10,
  },
  targetIcon: {
    fontSize: 20,
    color: colors.gray.dark,
  },
}
