import { serviceHours as serviceHoursHelper } from '@lighthouse/common'
import { getModule } from '@lighthouse/sdk'
import { chain, indexOf, map, without } from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers, withProps, withState } from 'recompose'
import moment from 'moment'
import React from 'react'

import { colors } from 'config/theme'
import { formatDateInTimezone } from 'helpers/datetime'

import { Block, Flex, Span } from 'components/common'
import { Banner as ListBanner } from 'components/list-next/components'
import FilterList from './components/filter-list'
import ListAction from 'components/list-next/components/action'
import ListContent from 'components/list-next/components/content'
import ListDescription from 'components/list-next/components/description'
import ListItem from 'components/list-next'
import ListTitle from 'components/list-next/components/title'
import Caret from 'components/caret'
import Icon from 'components/icon'
import Loader from 'components/loader'
import throttle from 'components/throttle'

import emitter from '../../utils/emitter'
import { useTranslation } from 'react-i18next'

const areaModule = getModule('areas')
const geoModule = getModule('geo')

const sortObj = {
  label: {
    label: 'Location Name',
    labelT: 'locationPanel.sort.locationName',
  },
  userCount: {
    label: 'User Count',
    labelT: 'locationPanel.sort.userCount',
  },
}
const timeout = 30000

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props => ({
    showLoader: props.timeline.isLive && !props.isResolving && !props.loading,
  })),
  throttle(timeout, props => props.showLoader),
  withState('filter', 'setFilter', ''),
  withState('sort', 'setSort', {
    order: ['label', 'userCount'],
    direction: ['asc', 'desc'],
  }),
  withHandlers({
    getSortDirection,
    handleSetCenter: props => center => props.setMapProperties({ center }),
  }),
  withHandlers({
    toggleSort,
  })
)(LocationList)

function LocationList(props) {
  const {
    expiredAt,
    filter,
    getSortDirection,
    handleClose,
    handleSetCenter,
    loading,
    locations,
    serviceHoursCache,
    setFilter,
    showLoader,
    sort,
    timeline: { currentTime, isLive },
    timezone,
    toggleSort,
    userCountByLocation,
  } = props

  const { t } = useTranslation()

  const locationList = buildLocations({
    filter,
    locations,
    serviceHoursCache,
    sort,
    userCountByLocation,
  })

  const timelineDatetime = formatDateInTimezone(currentTime, timezone)

  return (
    <Flex flexDirection="column" height="100%" overflow="hidden">
      <Block>
        <FilterList
          selectedFilter={filter}
          handleClose={handleClose}
          handleSetFilter={setFilter}
        />
      </Block>
      <Flex
        alignItems="center"
        backgroundColor="#f9f9f9"
        justifyContent="space-between"
        height="34px"
        flexShrink={0}
      >
        <Flex alignItems="center" flexDirection="row" paddingLeft="10px">
          <Block color="#CCC">
            <Icon name="sort" />
          </Block>
          {map(sortObj, ({ labelT }, key) => {
            return (
              <SortButton
                active={sort.order[0] === key}
                key={key}
                direction={getSortDirection(key)}
                label={t(labelT)}
                onClick={() => toggleSort(key)}
              />
            )
          })}
        </Flex>
        {showLoader && (
          <Block marginRight={22}>
            <Loader key={expiredAt} time={timeout} />
          </Block>
        )}
      </Flex>
      {!isLive && <ListBanner>As of {timelineDatetime}</ListBanner>}
      <Block flexGrow={1} height="100%" overflow="scroll">
        {map(locationList, location => {
          const { geometry, id, isOpen, label, userCount } = location

          const color = isOpen ? colors.green.light : colors.red.light

          // isOpen of null means we couldn't find service hours for that location
          const openLabel =
            isOpen === null
              ? ''
              : isOpen
              ? t('locationPanel.status.open')
              : t('locationPanel.status.closed')

          const userLabel =
            userCount === 1
              ? t('locationPanel.userCount', {
                  count: userCount,
                })
              : t('locationPanel.userCount_plural', {
                  count: userCount,
                })

          return (
            <ListItem
              key={id}
              maxWidth={330}
              onClick={() =>
                handleSetLocation({
                  id,
                  label,
                  geometry,
                })
              }
            >
              <ListContent>
                <ListTitle maxWidth={330}>{label}</ListTitle>
                <ListDescription>
                  {openLabel && (
                    <Span
                      borderRight={`1px solid ${colors.gray.lighter}`}
                      color={color}
                      fontWeight={400}
                      marginRight={6}
                      paddingRight={6}
                    >
                      {openLabel}
                    </Span>
                  )}
                  {!loading && (
                    <Span color={colors.gray.normal}>{userLabel}</Span>
                  )}
                </ListDescription>
              </ListContent>
            </ListItem>
          )
        })}
      </Block>
    </Flex>
  )
}

function buildLocations(props) {
  const {
    filter,
    locations,
    serviceHoursCache,
    sort,
    userCountByLocation,
  } = props

  return chain(locations)
    .map(location => {
      const { entity, id } = location
      const { center, geometry, name: label, serviceHours } = entity

      const timestamp = moment.utc().valueOf()
      const isOpen = serviceHoursHelper.isOpen({ serviceHours, timestamp })
      const userCount = userCountByLocation[id] || 0

      return {
        center,
        geometry,
        id,
        isOpen,
        label,
        userCount,
      }
    })
    .filter(({ isOpen, userCount }) =>
      filter === 'open'
        ? isOpen
        : filter === 'closed'
        ? !isOpen
        : filter === 'open-active'
        ? isOpen && userCount > 0
        : filter === 'open-inactive'
        ? isOpen && userCount === 0
        : true
    )
    .orderBy(sort.order, sort.direction)
    .value()
}

function getInverseSortDirection(sort) {
  return sort === 'asc' ? 'desc' : 'asc'
}

function getSortDirection(props) {
  const { sort } = props
  return order => {
    const directionIndex = indexOf(sort.order, order)
    return sort.direction[directionIndex]
  }
}

function handleSetLocation(props) {
  const { geometry, id, label } = props

  emitter.emit('map:set-location', {
    id,
    geometry,
  })

  emitter.emit('search:set-location', {
    geometry,
    label,
    value: id,
  })
}

function toggleSort(props) {
  const { getSortDirection, sort, setSort } = props

  const { order: currentOrders } = sort

  const primaryOrder = currentOrders[0]

  return key => {
    const otherOrders = without(currentOrders, key)
    const nextOrders = [key, ...otherOrders]

    const nextDirections = map(nextOrders, order => {
      const currentDirection = getSortDirection(order)
      const shouldReverseDirection = key === order && key === primaryOrder

      const nextDirection = shouldReverseDirection
        ? getInverseSortDirection(currentDirection)
        : currentDirection

      return nextDirection
    })

    return setSort({
      order: nextOrders,
      direction: nextDirections,
    })
  }
}

// TODO Move to external compenent?
function SortButton(props) {
  const { active, direction = 'asc', label, onClick } = props

  const directionIcon = direction === 'asc' ? <Caret up /> : <Caret down />

  return (
    <Flex
      alignItems="center"
      cursor="pointer"
      onClick={onClick}
      padding="10px 0px 10px 5px"
    >
      <Span
        display="flex"
        alignItems="center"
        fontSize={11}
        marginRight={!active && 19}
        textAlign="center"
      >
        {label}
        {active && directionIcon}
      </Span>
    </Flex>
  )
}

function mapStateToProps(state) {
  const areaSelectors = areaModule.selectors(state)()
  const isResolving = areaSelectors.state === 'resolving'
  const locations = areaSelectors.byType('location')
  const userCountByLocation = geoModule.selectors.countMarkersByLocation(state)(
    'user'
  )
  const serviceHoursCache = state.serviceHours.cache

  return {
    isResolving,
    locations,
    serviceHoursCache,
    userCountByLocation,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setMapProperties: properties =>
      dispatch(geoModule.setProperties(properties)),
  }
}
