import colors from 'config/theme/colors'

export default {
  root: {
    display: 'flex',
    borderBottom: `1px solid ${colors.gray.lighter}`,
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    width: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  details: {
    flexGrow: 1,
  },
  placeholderIcon: {
    height: 28,
    width: '50%',
    marginBottom: 5,
    backgroundColor: colors.gray.lightest,
  },
  placeholderTitle: {
    height: 12,
    backgroundColor: colors.gray.lightest,
    width: '40%',
    marginBottom: 5,
  },
  placeholderLine: {
    height: 10,
    backgroundColor: colors.gray.lightest,
    width: '50%',
    marginBottom: 5,
  },
}
