import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { getModule, validation } from '@lighthouse/sdk'
import { LANGUAGES } from 'config/constants'
import { useTranslation } from 'react-i18next'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import withState from 'recompose/withState'
import React from 'react'
import withHandlers from 'recompose/withHandlers'
import { BasicForm, FieldGroup, FieldSet, InputText } from 'components/form'
import { Block, Flex } from 'components/common'
import { handleFormError, handleResponse } from 'components/form/helpers'
import Alert from 'components/alert'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'
import Select from 'components/form/select'
import { map } from 'lodash'
import i18next from 'i18next'
import Icon from 'components/icon'
import colors from 'config/theme/colors'

const authenticationModule = getModule('authentication')
const appModule = getModule('app')
const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined
const FORM_PROPS = { form: 'sign-in-credentials' }

export default compose(
  reduxForm(FORM_PROPS),
  connect(mapStateToProps, mapDispatchToProps),
  withState('visible', 'setVisible', false),
  withHandlers({ handleError }),
  withHandlers({ handleSave }),
  lifecycle({ componentWillMount })
)(Form)

function Form(props) {
  const {
    error,
    handleSave,
    handleSubmit,
    invalid,
    locale,
    setLocale,
    submitFailed,
    submitting,
    visible,
    setVisible,
  } = props

  const styles = {
    buttonStyle: {
      color: colors.gray.light,
    },
  }

  const { t, i18n } = useTranslation()

  return (
    <Flex flexDirection="column">
      <BasicForm onSubmit={handleSubmit(handleSave)} noValidate>
        {error && <Alert messages={[error]} marginBottom={0} type="error" />}
        <FieldGroup>
          <FieldSet>
            <Field
              autoFocus
              component={InputText}
              dataTestId="username-text"
              label={t('labelEmailOrUsername')}
              name="username"
              required
              validate={[isRequired]}
            />
            <Block position="relative">
              <Field
                component={InputText}
                dataTestId="password-text"
                label={t('labelPassword')}
                name="password"
                required
                type={visible ? 'text' : 'password'}
                validate={[isRequired]}
              />
              <Block
                position="absolute"
                right={10}
                top={12}
                onClick={() => setVisible(!visible)}
              >
                {visible ? (
                  <Icon name="eye" fontSize={22} theme={styles.buttonStyle} />
                ) : (
                  <Icon
                    name="eye-slash"
                    fontSize={22}
                    theme={styles.buttonStyle}
                  />
                )}
              </Block>
            </Block>
          </FieldSet>
        </FieldGroup>
        <ButtonGroup align="right" border>
          <Flex justifyContent="space-between" width="100%">
            <Select
              clearable={false}
              onChange={value => {
                i18n.changeLanguage(value)
                setLocale(value)
              }}
              options={map(LANGUAGES, ({ shortLabel, shortLabelT, value }) => ({
                label: shortLabelT ? t(shortLabelT) : shortLabel,
                value,
              }))}
              placeholder={t('placeholder.selectLanguage')}
              value={locale || i18n.language}
            />
            <Flex justifyContent="flex-end">
              <Button link="/reset-password/request">
                {t('button.forgotPassword')}
              </Button>
              <Button
                dataTestId="submit-button"
                disabled={invalid || submitting}
                loading={submitting}
                theme="positive noMargin"
                type="submit"
              >
                {t('button.signIn')}
              </Button>
            </Flex>
          </Flex>
        </ButtonGroup>
      </BasicForm>
    </Flex>
  )
}

function componentWillMount() {
  // NOTE reset the form when the component mounts. This is a bit of a hack
  // workaround to ensure that the loading state of the form does not persist
  // across page reloads. Without this, if a user attempts to login and the
  // form goes into a submitting state and then reloads the page, the
  // submitting stat will persist which is undesirable
  this.props.reset()
}

function handleError(props) {
  const { applicationId, unauthenticate } = props

  return error => {
    // NOTE user maybe have been authenticated but not have permission, in
    // which case they should be unauthenticated as well
    unauthenticate({ applicationId })
    handleFormError(error)
  }
}

function handleSave(props) {
  const { applicationId, authenticate, handleError, submitCallback } = props

  return data => {
    const payload = {
      applicationId,
      ...data,
    }

    return authenticate(payload)
      .then(handleResponse)
      .then(submitCallback)
      .catch(handleError)
  }
}

function mapStateToProps(state) {
  return {
    applicationId: state.app.applicationId,
    locale: state.app.locale,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticate: payload =>
      dispatch(authenticationModule.authenticateRequest(payload)),
    unauthenticate: params =>
      dispatch(authenticationModule.unauthenticateRequest(params)),
    setLocale: params => dispatch(appModule.setLocale(params)),
  }
}
