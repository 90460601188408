import React from 'react'

import Entry from './components/entry'
import New from './components/new'

interface AuditViewProps {
  id: string
  onClose: () => void
  toggleExpand: () => void
  toggleIcon: string
}

export default function AuditView(props: AuditViewProps): JSX.Element {
  const { id, onClose: handleClose, toggleExpand, toggleIcon } = props

  const isExistingAudit = !!id

  if (isExistingAudit) {
    return (
      <Entry
        id={id}
        onClose={handleClose}
        toggleExpand={toggleExpand}
        toggleIcon={toggleIcon}
      />
    )
  }

  return (
    <New
      onClose={handleClose}
      toggleExpand={toggleExpand}
      toggleIcon={toggleIcon}
    />
  )
}
