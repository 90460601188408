import { padStart, toInteger, toString, toUpper } from 'lodash'
import { compose, withHandlers, withProps } from 'recompose'

export default compose(
  withHandlers({
    handleChange,
  }),
  withProps(props => ({ ...parseValue(props.value) }))
)(TimePicker)

function TimePicker(props) {
  const { children, hour, handleChange, meridiem, minute } = props

  return children({
    handleChange,
    hour,
    meridiem,
    minute: getMinuteString(minute),
  })
}

function buildResponseObject(hour, minute, meridiem) {
  const hour24 =
    hour < 12 && meridiem === 'PM'
      ? hour + 12
      : hour === 12 && meridiem === 'AM'
      ? 0
      : hour

  return { hour: hour24, minute }
}

function buildResponseString(hour, minute, meridiem) {
  return `${hour}:${getMinuteString(minute)} ${toUpper(meridiem)}`
}

function getMinuteString(value) {
  return padStart(toString(value), 2, '0')
}

function getNextHourValue({ action, hour, minute, type, value }) {
  if (type === 'hour' && action === 'text') {
    return toInteger(value)
  }

  const toDecrease =
    (action === 'decrease' && type === 'hour') ||
    (action === 'decrease' && minute === 0 && type === 'minute')
  const toIncrease =
    (action === 'increase' && type === 'hour') ||
    (action === 'increase' && minute === 59 && type === 'minute')

  if (toDecrease) {
    return hour === 1 ? 12 : hour - 1 // reset to 12 if below 1
  }

  if (toIncrease) {
    return hour === 12 ? 1 : hour + 1 // reset to 1 if above 12
  }

  return hour
}

function getNextMeridiemValue({ action, hour, meridiem, minute, type, value }) {
  if (action === 'text' && type === 'meridiem') {
    return value
  }

  const hoursRequireChange =
    (action === 'increase' && hour === 11 && type === 'hour') ||
    (action === 'decrease' && hour === 12 && type === 'hour')

  const minuteRequiresChange =
    (action === 'increase' &&
      hour === 11 &&
      minute === 59 &&
      type === 'minute') ||
    (action === 'decrease' && hour === 12 && minute === 0 && type === 'minute')

  if (type === 'meridiem' || hoursRequireChange || minuteRequiresChange) {
    return meridiem === 'AM' ? 'PM' : 'AM'
  }

  return meridiem
}

function getNextMinuteValue({ action, minute, type, value }) {
  if (type === 'minute' && action === 'text') {
    return toInteger(value)
  }

  if (type === 'minute' && action === 'increase') {
    return minute < 59 ? minute + 1 : 0 // reset to 0 if above 59
  }

  if (type === 'minute' && action === 'decrease') {
    return minute > 0 ? minute - 1 : 59 // reset to 59 if below 0
  }

  return minute
}

function handleChange(props) {
  const { onChange = () => {}, value: stateValue } = props
  const { hour, meridiem, minute } = parseValue(stateValue)

  return (type, action, value = null) => {
    const nextHour = getNextHourValue({ action, minute, hour, type, value })
    const nextMeridiem = getNextMeridiemValue({
      action,
      hour,
      meridiem,
      minute,
      type,
      value,
    })
    const nextMinute = getNextMinuteValue({ action, minute, type, value })
    const buildResponse =
      typeof stateValue === 'object' ? buildResponseObject : buildResponseString

    const response = buildResponse(nextHour, nextMinute, nextMeridiem)
    return onChange(response)
  }
}

function parse24HourObject(value) {
  const { hour = 12, minute = 0 } = value
  const meridiem = hour > 11 ? 'PM' : 'AM'
  // Adjust for 12 hours
  const parsedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour

  return {
    hour: parsedHour,
    meridiem,
    minute,
  }
}

function parseString(value) {
  const [time, meridiem] = value.split(' ')
  const [hour, minute] = time.split(':')
  const hourValue = toInteger(hour)
  const minuteValue = toInteger(minute)
  const parsedHour =
    meridiem === 'PM' && hourValue > 12 ? hourValue - 12 : hourValue

  return {
    hour: parsedHour,
    meridiem,
    minute: minuteValue,
  }
}

function parseValue(value) {
  return typeof value === 'object'
    ? parse24HourObject(value)
    : parseString(value)
}
