import { compose } from 'recompose'
import lifecycle from 'recompose/lifecycle'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { getPrettyDuration } from '@lighthouse/common'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { Component } from 'react'

import { includes, isObject, noop } from 'lodash'
import { colors } from 'config/theme'
import { Block } from 'components/common'
import FlagsHOC from 'components/flags/hoc'
import ListAction from 'components/list-next/components/action'
import ListContent from 'components/list-next/components/content'
import ListDescription from 'components/list-next/components/description'
import ListIcon from 'components/list-next/components/icon'
import ListItem from 'components/list-next'
import ListSubtitle from 'components/list-next/components/subtitle'
import ListTitle from 'components/list-next/components/title'
import TimeAgo from 'components/time-ago'
import { withUsers } from 'components/useUsers'

import AuditEntry from '../AuditEntry'
import styles from './styles'
import { withTranslation } from 'react-i18next'

const applicationUsersModule = getModule('applicationUsers')

const timestampStyles = {
  color: colors.gray.normal,
  fontSize: '11px',
}

class RowContent extends Component {
  constructor(props) {
    super(props)

    this.categoryStrategies = {
      'audit-entry-new': this.buildAuditEntry.bind(this),
      'audit-entry-update': this.buildAuditEntry.bind(this),
      'exception-opened': this.buildExceptionOpened.bind(this),
      'exception-resolved': this.buildExceptionClosed.bind(this),
      'issue-new': this.buildIssue.bind(this),
      'issue-update': this.buildIssue.bind(this),
      'issue-assignee': this.buildIssue.bind(this),
      'task-entry-new': this.buildTaskEntry.bind(this),
      'task-entry-update': this.buildTaskEntry.bind(this),
      'shift-start': this.buildShiftStart.bind(this),
      'shift-end': this.buildShiftEnd.bind(this),
    }
  }

  render() {
    const {
      flags,
      handleGoToZone,
      item,
      style,
      timezone,
      zoneCache,
    } = this.props
    const { category, meta, timestamp, zone } = item

    const buildPropsFn = this.categoryStrategies[category] || noop
    const hasZone = !!zoneCache[zone]

    const timestampText = moment(timestamp).format('YYYY-MM-DD HH:mm')
    const mTimezoneTime = moment(timestampText).tz(timezone)
    const timezoneTimeText = mTimezoneTime.format('MMM D h:mm:ss a')
    const timezoneAbbrText = mTimezoneTime.format('z')

    const isException = includes(
      ['exception-resolved', 'exception-opened'],
      category
    )
    const time =
      isException && meta && meta.scheduleName
        ? timezoneTimeText
        : `${timezoneTimeText} (${timezoneAbbrText})`
    const props = buildPropsFn(item, time, flags)

    if (!props) return null

    const mergedStyles = { ...styles.row, ...style, ...props.rowStyles }

    if (category === 'audit-entry-new' || category === 'audit-entry-update') {
      return (
        <AuditEntry
          hasZone={hasZone}
          handleGoToZone={handleGoToZone}
          item={item}
          onClick={props.linkHandler}
          style={mergedStyles}
          timeAgo={{ timestamp, timezone }}
          userAndTimestamp={props.row3}
          zone={zone}
        />
      )
    }

    return (
      <Block>
        <ListItem {...mergedStyles} onClick={props.linkHandler}>
          <Block>
            <ListIcon name={props.iconName} styles={props.iconStyles}>
              <TimeAgo
                fromNow
                style={timestampStyles}
                timestamp={timestamp}
                timezone={timezone}
              />
            </ListIcon>
          </Block>
          <ListContent>
            <ListTitle>{props.row1}</ListTitle>
            <ListSubtitle>{props.row2}</ListSubtitle>
            <ListDescription>{props.row3}</ListDescription>
          </ListContent>
          {hasZone && handleGoToZone && (
            <ListAction icon="map-target" onClick={handleGoToZone(zone)} />
          )}
        </ListItem>
      </Block>
    )
  }

  buildAuditEntry(item, time, flags) {
    const { getUserFullName } = this.props
    const { description, documentId, geo = {}, meta, title, user } = item

    const coordinates = geo.coordinates || []
    const [lng, lat] = coordinates

    const resource = 'auditentries'

    const { score, targetServiceLevel = null, title: metaTitle } = meta

    const linkHandler = this.handleClick({
      icon: 'auditentries',
      id: documentId,
      lat,
      lng,
      resource,
      score,
      showMarker: true,
      targetServiceLevel,
      title: metaTitle,
    })
    return {
      iconName: 'pencil-circle',
      iconStyles: styles.activityIcon,
      row1: title,
      row2: description,
      row3: `${getUserFullName(user)} - ${time}`,
      linkHandler,
    }
  }

  buildExceptionOpened(item, time) {
    const { t } = this.props
    const { meta, title } = item
    const scheduleName = meta && meta.scheduleName

    if (!scheduleName) {
      return {
        iconName: 'circle-cross',
        iconStyles: { ...styles.activityIcon, ...styles.iconNegative },
        row1: title,
        row2: t('activityPanel.rowContent.exception.opened', { time }),
        row3: t('activityPanel.rowContent.exception.open'),
      }
    }

    return {
      iconName: 'circle-cross',
      iconStyles: { ...styles.activityIcon, ...styles.iconNegative },
      row1: scheduleName,
      row2: title,
      row3: t('activityPanel.rowContent.exception.opened', { time }),
    }
  }

  buildExceptionClosed(item, time) {
    const { t } = this.props
    const { meta, timestamp, title } = item
    const opened = meta && meta.opened
    const scheduleName = meta && meta.scheduleName

    const closed = moment(timestamp)
    const duration = getPrettyDuration(
      moment.duration(closed.diff(moment(opened)))
    )

    if (!scheduleName) {
      return {
        iconName: 'circle-cross',
        iconStyles: { ...styles.activityIcon, ...styles.iconPositive },
        row1: title,
        row2: t('activityPanel.rowContent.exception.closed', { time }),
        row3: t('activityPanel.rowContent.exception.duration', {
          duration,
        }),
      }
    }

    return {
      iconName: 'circle-cross',
      iconStyles: { ...styles.activityIcon, ...styles.iconPositive },
      row1: scheduleName,
      row2: title,
      row3: t('activityPanel.rowContent.exception.closedDuration', {
        time,
        duration,
      }),
    }
  }

  buildIssue(item, time, flags) {
    const { getUserFullName } = this.props
    const { description, documentId, geo = {}, meta, title, user } = item

    const coordinates = geo.coordinates || []
    const [lng, lat] = coordinates

    const isDuress = meta && meta.issueType === 'duress'
    const link = `/reports/issues/${documentId}`
    const duressStyles = isDuress ? styles.duressIcon : {}
    const rowStyles = isDuress ? styles.rowDuress : {}
    const markerIcon = isDuress ? 'duress' : undefined
    const resource = 'issue'

    // NOTE: we must support legacy maps so instead of
    // updating the url we open the link
    const linkHandler = this.handleClick({
      icon: markerIcon,
      id: documentId,
      lat,
      lng,
      resource,
      showMarker: !!(lat && lng),
      title,
    })

    return {
      iconName: 'alert',
      iconStyles: {
        ...styles.activityIcon,
        ...styles.duressIcon,
      },
      important: isDuress,
      linkHandler,
      rowStyles,
      row1: title,
      row2: description,
      row3: `${getUserFullName(user)} - ${time}`,
    }
  }

  buildTaskEntry(item, time, flags) {
    const { getUserFullName } = this.props
    const { description, documentId, geo = {}, title, user } = item

    const coordinates = geo.coordinates || []
    const [lng, lat] = coordinates

    const resource = 'taskentries'

    const linkHandler = this.handleClick({
      id: documentId,
      lat,
      lng,
      resource,
      showMarker: !!(lat && lng),
      title,
    })

    return {
      iconName: 'check-checked',
      iconStyles: styles.activityIcon,
      row1: title,
      row2: description,
      row3: `${getUserFullName(user)} - ${time}`,
      linkHandler,
    }
  }

  buildShiftStart(item, time) {
    const { getUserFullName } = this.props
    const { title, user } = item

    const userId = isObject(user) ? user._id : user

    return {
      iconName: 'id-card',
      iconStyles: { ...styles.activityIcon, ...styles.iconPositive },
      row1: title,
      row2: getUserFullName(userId),
      row3: time,
    }
  }

  buildShiftEnd(item, time) {
    const { getUserFullName, t } = this.props
    const { meta, timestamp, user } = item
    const start = meta && meta.start

    const started = moment(start)
    const finished = moment(timestamp)
    const duration = getPrettyDuration(moment.duration(finished.diff(started)))
    const userId = isObject(user) ? user._id : user

    return {
      iconName: 'id-card',
      iconStyles: { ...styles.activityIcon, ...styles.iconNegative },
      row1: t('activityPanel.rowContent.shiftEnd'),
      row2: getUserFullName(userId),
      row3: `${time} - ${duration}`,
    }
  }

  handleGoToDetails(link) {
    return () => window.open(link)
  }

  handleClick(params) {
    const { history } = this.props

    return () => {
      const nextSearch = queryString.stringify(params)
      history.push({ search: `?${nextSearch}` })
    }
  }
}

export default compose(
  FlagsHOC,
  withRouter,
  connect(mapStateToProps),
  withTranslation(),
  withUsers,
  lifecycle({ componentDidMount })
)(RowContent)

function componentDidMount() {
  const {
    fetchUser,
    item: { user },
  } = this.props

  if (!user) return

  return fetchUser({ userId: user })
}

function mapStateToProps(state) {
  const applicationUsersSelectors = applicationUsersModule.selectors(state)()

  return {
    timezone: state.app.timezone,
    getUserFullName: userId =>
      applicationUsersSelectors.getUserFullName(userId),
    zoneCache: state.zones.cache,
  }
}

RowContent.propTypes = {
  getUserFullName: PropTypes.func,
  handleGoToZone: PropTypes.func,
  item: PropTypes.object,
  style: PropTypes.object,
  timezone: PropTypes.string,
  zoneCache: PropTypes.object,
}
