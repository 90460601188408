import { getModule } from '@lighthouse/sdk'
import React, { useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

// components
import { Flex, Text } from 'components/common'
import setEndpoints from '../components/set-endpoints'
import { InputText, BasicForm, FieldGroup, FieldSet } from 'components/form'
import Alert from 'components/alert'
import Background from 'components/background'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'
import Logo from 'components/logo'
import { Modal, ModalHeader } from '../components'

const DEFAULT_ERROR_MESSAGE =
  'Error: Please check your password is at least 6 characters long'
const userModule = getModule('user')

function ResetPassword(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const { t } = useTranslation()
  const { history, match, resetPassword } = props

  function changePassword(e) {
    const value = e.target.value
    setPassword(value)
  }

  function submit(e) {
    e.preventDefault()

    if (!password) {
      return setError('Please enter a new password')
    }

    setError('')

    const resetPasswordToken = match.params.resetPasswordToken

    const payload = {
      newPassword: password,
      token: resetPasswordToken,
    }

    resetPassword(payload)
      .then(response => {
        const { error } = response

        if (error) {
          return setError(handleErrorResponse(error))
        }

        if (isMobile) {
          setSuccess(
            'Your password has been reset. Please go back to the mobile app'
          )
          return null
        }
        setSuccess('Your password has been reset. Redirecting to login...')

        setTimeout(() => {
          // redirect to login
          history.push('/login')
        }, 3000)
        return null
      })
      .catch(error => {
        console.error(error)
        setError(handleErrorResponse(error))
      })

    return null
  }

  const errorAlert = error && (
    <Alert marginBottom={0} messages={[error]} type="error" />
  )

  const successAlert = success && (
    <Alert marginBottom={0} messages={[success]} type="success" />
  )

  return (
    <Background>
      <Flex
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
        width={isMobile ? '90%' : 560}
      >
        <Logo />
        <Modal width="100%">
          {successAlert}
          {!success && (
            <>
              <ModalHeader>Reset your Password</ModalHeader>
              {errorAlert}
              <BasicForm onSubmit={submit} noValidate>
                <FieldGroup>
                  <FieldSet>
                    {isMobile ? <Text>{t('labelNewPassword')}</Text> : null}
                    <InputText
                      type="password"
                      label={isMobile ? '' : t('labelNewPassword')}
                      required
                      value={password}
                      onChange={changePassword}
                    />
                  </FieldSet>
                </FieldGroup>
                <ButtonGroup align="right" border>
                  {isMobile ? null : (
                    <Button link="/login">{t('button.cancel')}</Button>
                  )}
                  <Button type="submit" theme="positive noMargin">
                    {t('button.reset')}
                  </Button>
                </ButtonGroup>
              </BasicForm>
            </>
          )}
        </Modal>
      </Flex>
    </Background>
  )
}

export default compose(
  setEndpoints,
  connect(null, mapDispatchToProps)
)(ResetPassword)

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: payload =>
      dispatch(userModule.resetPasswordRequest(payload)),
  }
}

function handleErrorResponse(error) {
  let errorMessage
  switch (error.name) {
    case 'InvalidResetPasswordTokenError':
      errorMessage =
        'This reset password request has expired. Please start the reset password process again'
      break
    default:
      errorMessage = error.message || DEFAULT_ERROR_MESSAGE
  }

  return errorMessage
}
