/*
 * Area Module
 */

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import {
  attempt,
  chain,
  includes,
  isError,
  get,
  filter,
  find,
  minBy,
  memoize,
  noop,
  sortBy,
} from 'lodash'
import center from '@turf/center'
import distance from '@turf/distance'
import { feature } from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import booleanWithin from '@turf/boolean-within'
import booleanOverlap from '@turf/boolean-overlap'
import { getType } from '@turf/invariant'
import crud from '../../crud'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'
import buildActionCreators from './action-creators'

const resource = 'areas'
const OUTDOOR_AREAS = ['location', 'building', 'geofence', 'point']
export { default as schema } from './schema'

export const actions = crud.actions(resource)

Object.assign(actions, {
  ADD_LOCATIONS_TO_GROUP_REQUEST: `lighthouse/${resource}/ADD_LOCATIONS_TO_GROUP_REQUEST`,
  ADD_LOCATIONS_TO_GROUP_SUCCESS: `lighthouse/${resource}/ADD_LOCATIONS_TO_GROUP_SUCCESS`,
  ADD_LOCATIONS_TO_GROUP_ERROR: `lighthouse/${resource}/ADD_LOCATIONS_TO_GROUP_ERROR`,
  REMOVE_LOCATIONS_FROM_GROUP_REQUEST: `lighthouse/${resource}/REMOVE_LOCATIONS_FROM_GROUP_REQUEST`,
  REMOVE_LOCATIONS_FROM_GROUP_SUCCESS: `lighthouse/${resource}/REMOVE_LOCATIONS_FROM_GROUP_SUCCESS`,
  REMOVE_LOCATIONS_FROM_GROUP_ERROR: `lighthouse/${resource}/REMOVE_LOCATIONS_FROM_GROUP_ERROR`,
})

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn(resource),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const customActionCreators = buildActionCreators(
  actions,
  applicationResourceUrlFn,
  applicationParamsFn,
  authorizationHeadersFn,
)

export const actionCreators = Object.assign(
  {},
  crudActionCreators,
  customActionCreators,
  {
    getGraph,
    updateGraph,
  },
)

const crudSelectors = crud.selectors(resource)
const positionSelector = state => state.app.position
const byType = createSelector(
  crudSelectors.cache,
  cache => memoize(type => (
    filter(cache, ['entity.type', type])
  )),
)

const outdoors = createSelector(
  crudSelectors.cache,
  cache => (
    filter(cache, area => (
      includes(
        OUTDOOR_AREAS,
        area && area.entity && area.entity.type,
      )
    ))
  ),
)

/**
 * Find the enclosing area type of the supplied geoJSONPoint
 */
const findEnclosing = createSelector(
  byType,
  byTypeFn => memoize((geoJSONPoint, type) => {
    const areas = byTypeFn(type)
    const featurePoint = attempt(feature, geoJSONPoint)

    if (isError(featurePoint)) {
      return undefined
    }

    // NOTE: sort areas by distance initially so that the closest location is
    // returned when performing the find below. Area locations can overlap so
    // we want to ensure the closest area is always returned
    const areasByDistance = sortBy(areas, (area) => {
      const { center: areaCenter, geometry } = area.entity

      const centerPoint = areaCenter || center(geometry)

      const distanceToPoint = distance(centerPoint, featurePoint, {
        units: 'meters',
      })

      return distanceToPoint
    })

    return find(areasByDistance, (area) => {
      const geometry = get(area, 'entity.geometry')
      const polygon = attempt(feature, geometry)

      if (isError(polygon)) {
        // TODO add some kind of error logging here
        return false
      }

      return booleanPointInPolygon(featurePoint, polygon)
    })
  }, (geoJSONPoint, type) => (`${get(geoJSONPoint, 'coordinates', []).join('-')}-${type}`)),
)

/**
 * Find the enclosing area types within supplied area id
 */
const findEnclosingById = createSelector(
  crudSelectors.cache,
  cache => memoize((selectedId, types = []) => {
    const selectedArea = cache[selectedId]
    const selectedAreaGeometry = get(selectedArea, 'entity.geometry')
    const selectedAreaPolygon = attempt(feature, selectedAreaGeometry)

    if (isError(selectedAreaPolygon)) return []

    const enclosingAreas = filter(cache, ({ id, entity }) => {
      if (!entity || entity.error) return false

      const isSelectedId = id === selectedId
      const isType = types.length ? includes(types, entity.type) : true

      if (isSelectedId || !isType) return false

      const geometry = entity && entity.geometry
      const polygon = attempt(feature, geometry)

      if (isError(polygon)) return false

      return booleanWithin(polygon, selectedAreaPolygon)
    })

    return enclosingAreas
  }, (selectedId, types = []) => {
    const typesStr = types.join('-')
    return `${selectedId}-${typesStr}`
  }),
)

/**
 * Returns areas for specified types which are intersecting or within the supplied area id
 */
const findIntersectingById = createSelector(crudSelectors.cache, cache =>
  memoize(
    (selectedId, types = []) => {
      const selectedArea = cache[selectedId]
      const selectedAreaGeometry = get(selectedArea, 'entity.geometry')
      const selectedAreaPolygon = attempt(feature, selectedAreaGeometry)

      if (isError(selectedAreaPolygon)) return []

      const intersectingAreas = filter(cache, ({ id, entity }) => {
        if (!entity || entity.error) return false

        const isSelectedId = id === selectedId
        const isType = types.length ? includes(types, entity.type) : true

        if (isSelectedId || !isType) return false

        const geometry = entity && entity.geometry
        const polygon = attempt(feature, geometry)

        if (isError(polygon)) return false

        try {
          const within =
            getType(selectedAreaPolygon) !== 'Point' &&
            booleanWithin(polygon, selectedAreaPolygon)

          const contains =
            getType(polygon) !== 'Point' &&
            booleanWithin(selectedAreaPolygon, polygon)

          const overlaps =
            getType(polygon) === getType(selectedAreaPolygon) &&
            booleanOverlap(polygon, selectedAreaPolygon)

          return within || contains || overlaps
        } catch (err) {
          return false
        }
      })

      return intersectingAreas
    },
    (selectedId, types = []) => {
      const typesStr = types.join('-')
      return `${selectedId}-${typesStr}`
    },
  ),
)

/**
 * Returns areas nearest (by center point) to the position set in state
 */
const sortByPosition = createSelector(
  crudSelectors.cache,
  positionSelector,
  (cache, position) => memoize((maxDistance) => {
    const distanceFn = distanceToPosition(position)
    // sort areas by distance of center point to position
    return chain(cache)
      .reject(area => area.error)
      .map(area => ({
        ...area.entity,
        distance: distanceFn(area),
      }))
      .filter(area => (
        // area is considered nearby if distance is less than maxDistance or it
        // has a distance value at all
        maxDistance ? area.distance < maxDistance : area.distance
      ))
      .sortBy('distance')
      .value()
  }),
)

/**
 * Returns the nearest area by type
 */
const nearestByType = createSelector(
  byType,
  positionSelector,
  (byTypeSelector, position) =>
    memoize((type) => {
      const areas = byTypeSelector(type)
      const area = minBy(areas, distanceToPosition(position))
      return area && area.entity
    })
)

export const selectors = Object.assign({}, crudSelectors, {
  byType,
  outdoors,
  findEnclosing,
  findEnclosingById,
  findIntersectingById,
  sortByPosition,
  nearestByType,
})

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

function distanceToPosition(position) {
  if (!position) return noop

  return (area) => {
    if (!area.entity) {
      return 99999
    }

    const { center: areaCenter, geometry } = area.entity
    const centerPoint = areaCenter || center(geometry)
    const distanceToPoint = distance(centerPoint, position, {
      units: 'meters',
    })
    return distanceToPoint
  }
}

function getGraph(areaId, options = {}) {
  const path = `${areaId}/graph`
  return crudActionCreators.request(path, options)
}

function updateGraph(areaId, payload, params = {}) {
  const path = `${areaId}/graph`
  const method = 'PUT'

  return crudActionCreators.request(path, {
    method,
    payload,
    params,
  })
}
