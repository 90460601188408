import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { HasPermission } from '@lighthouse/react-components'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'

import { Absolute, Block, Flex } from 'components/common'
import { colors } from 'config/theme'
import Button from 'components/button'
import Flags from 'components/flags'
import Icon from 'components/icon'
import LegendItem from 'components/mapping/legend-item'
import Radio from 'components/radio'
import Toggle from 'components/toggle'
import Image from 'components/image'

import { useTranslation } from 'react-i18next'

const LEGEND_TAB = 'legend'
const OPTIONS_TAB = 'options'
const SELECTED_TAB_SESSION_KEY = 'lio:maps:controls:selectedTab'

export default compose(
  withState('selectedTab', 'setSelectedTab', props =>
    props.selectedTab === null
      ? ''
      : props.selectedTab || sessionStorage.getItem(SELECTED_TAB_SESSION_KEY)
  ),
  withHandlers({ handleClick }),
  lifecycle({ componentWillUnmount })
)(MapControls)

function MapControls(props) {
  const {
    bottom,
    filters,
    handleClick,
    left,
    onFilterChange: handleFiltersChange,
    selectedTab,
    setSelectedTab,
    right,
    top,
  } = props

  const { t } = useTranslation()

  const isLegendVisible = selectedTab === LEGEND_TAB
  const isOptionsVisible = selectedTab === OPTIONS_TAB
  const isTabVisible = isLegendVisible || isOptionsVisible

  useEffect(() => {
    const anyToggledOn =
      filters['signal.qrcode'] ||
      filters['signal.nfc'] ||
      filters['signal.beacon']

    handleFiltersChange('signal', anyToggledOn)
  }, [filters])

  return (
    <Block
      bottom={bottom}
      left={left}
      minWidth="185px"
      position={'absolute'}
      right={right}
      top={top}
      zIndex={900}
    >
      <Flex flexDirection="column" lineHeight="1.5">
        {isTabVisible && (
          <Flex
            backgroundColor="white"
            borderRadius="4px 4px 0px 0px"
            minHeight="250px"
          >
            {isLegendVisible && (
              <Flex flex={1} flexDirection="column" padding="10px">
                <Caret onClick={() => handleClick(LEGEND_TAB)} />
                <LegendItem
                  icon={
                    <Icon
                      name="user"
                      fontSize="18px"
                      paddingRight="8px"
                      color={colors.green.normal}
                    />
                  }
                  label={t('mapOptions.legendItem.active')}
                />
                <LegendItem
                  icon={
                    <Icon
                      name="user"
                      fontSize="18px"
                      paddingRight="8px"
                      color={colors.orange.normal}
                    />
                  }
                  label={t('mapOptions.legendItem.recent')}
                />
                <LegendItem
                  icon={
                    <Icon
                      name="user"
                      fontSize="18px"
                      paddingRight="8px"
                      color={colors.gray.normal}
                    />
                  }
                  label={t('mapOptions.legendItem.inactive')}
                />
                <LegendItem
                  icon={
                    <Icon
                      name="car"
                      fontSize="18px"
                      paddingRight="8px"
                      color={colors.green.normal}
                    />
                  }
                  label={t('mapOptions.legendItem.userInVehicle')}
                />

                <Flex
                  alignItems="center"
                  backgroundColor={colors.white}
                  borderTop={`1px solid ${colors.gray.lighter}`}
                  margin="10px"
                />

                <LegendItem
                  icon={
                    <Icon
                      color="#92CB66"
                      fontSize="20px"
                      name="map-task"
                      marginLeft={-1}
                      paddingRight="8px"
                    />
                  }
                  label={t('mapOptions.legendItem.task')}
                />
                <LegendItem
                  icon={
                    <Icon
                      color={colors.orange.normal}
                      fontSize="20px"
                      name="map-issue"
                      marginLeft={-1}
                      paddingRight="8px"
                    />
                  }
                  label={t('mapOptions.legendItem.issue')}
                />
                <LegendItem
                  icon={
                    <Icon
                      color={colors.pink.normal}
                      fontSize="20px"
                      name="map-audit"
                      marginLeft={1}
                      paddingRight="8px"
                    />
                  }
                  label={t('mapOptions.legendItem.audit')}
                />

                <Flex
                  alignItems="center"
                  backgroundColor={colors.white}
                  borderTop={`1px solid ${colors.gray.lighter}`}
                  margin="12px"
                />

                <Flex alignItems="center" height={24}>
                  <Block paddingRight="8px">
                    <Flex
                      background="rgba(51,170,227,0.4)"
                      borderRadius={10}
                      height={20}
                      marginLeft={-2}
                      position="relative"
                      width={20}
                    >
                      <Block
                        background={colors.blue.normal}
                        borderRadius={8}
                        color={colors.white}
                        fontWeight="bold"
                        fontSize="9px"
                        height={16}
                        lineHeight="16px"
                        margin={2}
                        textAlign="center"
                        width={16}
                      >
                        2
                      </Block>
                    </Flex>
                  </Block>
                  <Block fontSize="10px" fontWeight={500} color="#666">
                    {t('mapOptions.legendItem.clusterWithLocation')}
                  </Block>
                </Flex>
                <Flex alignItems="center" height={24}>
                  <Block paddingRight="8px">
                    <Flex
                      background="rgba(69,204,113,0.4)"
                      borderRadius={10}
                      height={20}
                      marginLeft={-2}
                      position="relative"
                      width={20}
                    >
                      <Block
                        background={colors.green.normal}
                        borderRadius={8}
                        color={colors.white}
                        fontSize="9px"
                        fontWeight="bold"
                        height={16}
                        lineHeight="16px"
                        margin={2}
                        textAlign="center"
                        width={16}
                      >
                        2
                      </Block>
                    </Flex>
                  </Block>
                  <Block fontSize="10px" fontWeight={500} color="#666">
                    {t('mapOptions.legendItem.clusterWithoutLocation')}
                  </Block>
                </Flex>
                <Flex alignItems="center" height={24}>
                  <Block
                    borderBottom={2}
                    borderLeft={2}
                    borderRight={0}
                    borderStyle="solid"
                    borderColor="rgba(69,204,113,0.4)"
                    borderTop={0}
                    height={12}
                    marginLeft={7}
                    marginRight={6}
                    marginTop={-14}
                    width={12}
                  />
                  <Block fontSize="10px" fontWeight={500} color="#666">
                    {t('mapOptions.legendItem.activeUserCount')}
                  </Block>
                </Flex>
              </Flex>
            )}
            {isOptionsVisible && (
              <Flex flex={1} flexDirection="column" padding="10px">
                <Flags mapSatelliteTiles>
                  {enabled =>
                    enabled ? (
                      <Fragment>
                        <Caret onClick={() => handleClick(OPTIONS_TAB)} />
                        <RadioOption
                          enabled={filters.map}
                          label={t('mapOptions.optionsItem.map')}
                          onClick={() => handleFiltersChange('map', true)}
                        />
                        <RadioOption
                          enabled={filters.satellite}
                          label={t('mapOptions.optionsItem.satellite')}
                          onClick={() => handleFiltersChange('satellite', true)}
                        />
                        <Flex
                          alignItems="center"
                          backgroundColor={colors.white}
                          borderTop={`1px solid ${colors.gray.lighter}`}
                          margin="10px"
                        />
                      </Fragment>
                    ) : (
                      <Block marginBottom="20px">
                        <Caret onClick={() => handleClick(OPTIONS_TAB)} />
                      </Block>
                    )
                  }
                </Flags>
                <FilterOption
                  enabled={filters.tooltip}
                  iconName="tooltip"
                  label={t('mapOptions.optionsItem.tooltips')}
                  onClick={() =>
                    handleFiltersChange('tooltip', !filters.tooltip)
                  }
                />
                <FilterOption
                  enabled={filters.user}
                  iconName="user"
                  label={t('mapOptions.optionsItem.people')}
                  onClick={() => handleFiltersChange('user', !filters.user)}
                />
                <FilterOption
                  enabled={filters.location}
                  iconName="locations"
                  label={t('mapOptions.optionsItem.locations')}
                  onClick={() =>
                    handleFiltersChange('location', !filters.location)
                  }
                />
                <FilterOption
                  enabled={filters.geofences}
                  iconName="geo-fence"
                  label={t('mapOptions.optionsItem.geofences')}
                  onClick={() =>
                    handleFiltersChange('geofences', !filters.geofences)
                  }
                />
                <FilterOption
                  enabled={filters.signal}
                  iconName="beacon"
                  label={t('mapOptions.optionsItem.signals')}
                  onClick={() => {
                    handleFiltersChange('signal', !filters.signal)
                    handleFiltersChange('signal.beacon', !filters.signal)
                    handleFiltersChange('signal.qrcode', !filters.signal)
                    handleFiltersChange('signal.nfc', !filters.signal)
                  }}
                />
                <FilterOption
                  enabled={filters['signal.beacon']}
                  iconName="circle-check"
                  label={t('mapOptions.optionsItem.beacon')}
                  onClick={() =>
                    handleFiltersChange(
                      'signal.beacon',
                      !filters['signal.beacon']
                    )
                  }
                />
                <FilterOption
                  enabled={filters['signal.qrcode']}
                  iconName="check-unchecked"
                  label={t('mapOptions.optionsItem.qrcode')}
                  onClick={() =>
                    handleFiltersChange(
                      'signal.qrcode',
                      !filters['signal.qrcode']
                    )
                  }
                />
                <FilterOption
                  enabled={filters['signal.nfc']}
                  iconName="expand"
                  label={t('mapOptions.optionsItem.nfc')}
                  onClick={() =>
                    handleFiltersChange('signal.nfc', !filters['signal.nfc'])
                  }
                />
                <HasPermission module="auditEntry">
                  <FilterOption
                    enabled={filters.auditentries}
                    iconName="pencil-circle"
                    label={t('mapOptions.optionsItem.audits')}
                    onClick={() =>
                      handleFiltersChange('auditentries', !filters.auditentries)
                    }
                  />
                </HasPermission>
                <HasPermission module="issue">
                  <FilterOption
                    enabled={filters.issue}
                    iconName="alert"
                    label={t('mapOptions.optionsItem.issues')}
                    onClick={() => handleFiltersChange('issue', !filters.issue)}
                  />
                </HasPermission>
                <HasPermission module="taskEntry">
                  <FilterOption
                    enabled={filters.taskentries}
                    iconName="check-box"
                    label={t('mapOptions.optionsItem.tasks')}
                    onClick={() =>
                      handleFiltersChange('taskentries', !filters.taskentries)
                    }
                  />
                </HasPermission>
              </Flex>
            )}
          </Flex>
        )}
        <Flex>
          <Button
            backgroundColor={
              isLegendVisible ? colors.white : colors.gray.lightest
            }
            borderBottomRightRadius={0}
            borderColor={'rgba(255,255,255,0.5)'}
            borderRightWidth={1}
            borderStyle={'solid'}
            borderTopLeftRadius={isTabVisible ? 0 : 4}
            borderTopRightRadius={0}
            borderWidth={2}
            dataTestId="legend-button"
            flex={1}
            marginRight="0px"
            onClick={() => handleClick(LEGEND_TAB)}
          >
            {t('mapOptions.legend')}
          </Button>
          <Button
            backgroundColor={
              isOptionsVisible ? colors.white : colors.gray.lightest
            }
            borderBottomLeftRadius={0}
            borderColor={'rgba(255,255,255,0.5)'}
            borderLeftWidth={1}
            borderStyle={'solid'}
            borderTopLeftRadius={0}
            borderTopRightRadius={isTabVisible ? 0 : 4}
            borderWidth={2}
            dataTestId="options-button"
            flex={1}
            marginRight="0px"
            onClick={() => handleClick(OPTIONS_TAB)}
          >
            {t('mapOptions.options')}
          </Button>
        </Flex>
      </Flex>
    </Block>
  )
}

MapControls.propTypes = {
  bottom: PropTypes.number,
  filters: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  left: PropTypes.number,
  onFilterChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func.isRequired,
  right: PropTypes.number,
  top: PropTypes.number,
}

function componentWillUnmount() {
  const { selectedTab } = this.props
  sessionStorage.setItem(SELECTED_TAB_SESSION_KEY, selectedTab)
}

function handleClick(props) {
  const { selectedTab, setSelectedTab } = props

  return tab => {
    const hasSelectedSameTab = selectedTab === tab
    const nextTab = hasSelectedSameTab ? null : tab
    setSelectedTab(nextTab)
  }
}

function Caret(props) {
  const { onClick: handleClick } = props

  return (
    <Absolute top="7px" right="0">
      <Block onClick={handleClick}>
        <Icon
          fontSize="12px"
          name="arrow-down-thick"
          padding="10px"
          color="#999"
        />
      </Block>
    </Absolute>
  )
}

function FilterOption(props) {
  const { enabled, label, iconName, onClick: handleClick } = props
  const nested = label === 'QR' || label === 'Beacons' || label === 'NFC'
  const nestedIcon =
    label === 'QR'
      ? 'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/qr-red.svg'
      : label === 'Beacons'
      ? 'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/map-pin.svg'
      : 'https://assets-lighthouse-io.s3.amazonaws.com/maps/icons/nfc.png'

  return (
    <Flex
      alignItems="center"
      marginLeft={nested ? '20px' : '0px'}
      onClick={handleClick}
    >
      <Block minWidth="27px">
        {nested ? (
          <Image alt={label} src={nestedIcon} width="50%" />
        ) : (
          <Icon name={iconName} fontSize="18px" color="#666" />
        )}
      </Block>
      <Block fontSize="10px" fontWeight={500} color="#666">
        {label}
      </Block>
      <Block marginLeft="auto">
        <Toggle enabled={enabled} />
      </Block>
    </Flex>
  )
}

function RadioOption(props) {
  const { enabled, label, onClick: handleClick } = props

  return (
    <Flex alignItems="center" padding="4px 0px" onClick={handleClick}>
      <Block minWidth="27px">
        <Radio checked={enabled} />
      </Block>
      <Block fontSize="10px" fontWeight={500} color="#666">
        {label}
      </Block>
    </Flex>
  )
}
