import { colors } from 'config/theme'

export default {
  root: {
    position: 'relative',
    backgroundColor: colors.gray.lightest,
    marginBottom: 0,
    flexShrink: 0,
    '@media print': {
      display: 'none',
      visibility: 'hidden',
    },
  },
  filterContainer: {
    display: 'block',
    position: 'relative',
    visibility: 'visible',
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  searchContainer: {
    borderRight: `1px solid ${colors.gray.lighter}`,
  },
}
