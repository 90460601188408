import React from 'react'
import { connect } from 'react-redux'
import { memoize } from 'lodash'

import colors from 'config/theme/colors'
import { dateTime } from 'config/theme/formats'

import { Block, Flex, ListItem } from 'components/common'
import Icon from 'components/icon'
import DatetimeTimezone from 'components/datetime-timezone'

export default connect(mapStateToProps)(EntryListItem)

function EntryListItem({ active, item, onClick, templateName }) {
  const { createdAt, name } = item

  const radiumStyles = {
    ':hover': {
      backgroundColor: colors.blue.lightest,
    },
  }

  const createdAtDatetime = (
    <DatetimeTimezone datetime={createdAt} format={dateTime.date} />
  )

  const metaData = (
    <Block>
      Created: {createdAtDatetime} | Template: {templateName}
    </Block>
  )

  return (
    <ListItem
      alignItems="center"
      borderBottom={`1px solid ${colors.gray.lightest}`}
      cursor="pointer"
      display="flex"
      fontSize={12}
      marginLeft={-15}
      marginRight={-15}
      onClick={onClick}
      paddingBottom={10}
      paddingLeft={15}
      paddingRight={15}
      paddingTop={10}
      radiumStyles={radiumStyles}
    >
      <Flex
        flex="1"
        flexWrap="wrap"
        paddingTop={5}
        paddingRight={0}
        paddingBottom={0}
        paddingLeft={0}
      >
        <Flex flex="1 1 100%" fontSize={12}>
          {name}
        </Flex>
        <Flex color={colors.gray.light} fontSize={11} paddingTop={3}>
          {metaData}
        </Flex>
      </Flex>
      <Flex>{active && <Icon name="check" />}</Flex>
    </ListItem>
  )
}

function mapStateToProps(state, props) {
  const { item } = props
  const { template } = item

  const templateResource = getTemplate(state)(template)
  const templateEntity = templateResource ? templateResource.entity : {}
  const templateName = templateEntity.name || 'Unknown Template'

  return {
    templateName,
  }
}

function getTemplate(state) {
  return memoize(templateId => state.content.templates.cache[templateId])
}
