import { get, upperCase } from 'lodash'
import moment from 'moment-timezone'

import {
  getAssigneesFullNames,
  getLocationReference,
  getPrettyDuration,
  getStatusDetails,
  getTimezoneDatetime,
  getUserFullName,
} from '../'

export function getIssueDetails(data) {
  const { entity, timezone, users } = data

  const {
    assignees,
    createdAt,
    duration,
    gps,
    sequenceId,
    status,
    user,
  } = entity

  const assigneeNames = getAssigneesFullNames(users, assignees)
  const durationInMins = moment.duration(duration, 'minutes')
  const durationText = getPrettyDuration(durationInMins)
  const fullName = getUserFullName(users, user)
  const gpsText = get(gps, 'reverseGeocoded.label', 'Unknown Location')
  const isClosedStatus = status === 'closed'
  const locationText = getLocationReference(data)
  const statusDetails = getStatusDetails(status)
  const statusStyle = statusDetails.style
  const statusColor = statusStyle.color
  const statusText = upperCase(statusDetails.text)
  const timezoneDatetime = getTimezoneDatetime({
    timestamp: createdAt,
    timezone,
  })

  return {
    assigneeNames,
    durationText,
    gpsText,
    fullName,
    isClosedStatus,
    locationText,
    sequenceId,
    statusStyle,
    statusColor,
    statusText,
    timezoneDatetime,
  }
}
