import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { get, isEmpty, omit } from 'lodash'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import React from 'react'

import colors from 'config/theme/colors'

import Panel from '../panel'

import Form from './components/form'

const signalModule = getModule('signals')

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(),
  withState('loadError', 'setLoadError'),
  withState(
    'isEditing',
    'setEditing',
    ({ isEditingSignal }) => !!isEditingSignal
  ),
  withHandlers({
    getTitle,
    handleCancel,
    handleClose,
    handleEdit,
    handleReset,
  }),
  lifecycle({ componentDidMount })
)(SignalView)

function SignalView(props) {
  const {
    dirty,
    change,
    error,
    form,
    getTitle,
    handleCancel,
    handleClose,
    handleEdit,
    handleReset,
    handleSubmit,
    id,
    invalid,
    isEditing,
    isResolving,
    loadError,
    pristine,
    setEditing,
    submitFailed,
    submitting,
    touch,
  } = props

  const title = getTitle()

  return (
    <Panel
      color={colors.blue}
      error={loadError}
      isResolving={isResolving}
      onClose={handleClose}
      resource="Signal"
      title={title}
    >
      <Form
        change={change}
        dirty={dirty}
        error={error}
        form={form}
        handleCancel={handleCancel}
        handleEdit={handleEdit}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        id={id}
        invalid={invalid}
        isEditing={isEditing}
        onClose={handleClose}
        pristine={pristine}
        readOnly={!isEditing}
        setEditing={setEditing}
        submitFailed={submitFailed}
        submitting={submitting}
        touch={touch}
      />
    </Panel>
  )
}

function componentDidMount() {
  const { fetchSignal, hasLoaded, id, setLoadError } = this.props

  if (hasLoaded) return

  return fetchSignal(id)
    .then(handleResponse)
    .catch(error => setLoadError(error))
}

function getTitle(props) {
  const { entity, isResolving, hasLoaded } = props

  return () => {
    if (isResolving && !hasLoaded) return 'Loading Signal'
    if (entity.label) return entity.label

    return 'Unknown Signal'
  }
}

function handleResponse(response) {
  const { data, error } = response

  if (error) return Promise.reject(new Error(error))

  return data
}

function handleClose(props) {
  return () => props.onClose()
}

function handleCancel(props) {
  const { history, setEditing } = props

  return () => {
    const params = queryString.parse(history.location.search)
    const nextSearch = queryString.stringify(omit(params, ['action']))
    history.push({ search: `?${nextSearch}` })
    setEditing(false)
  }
}

function handleEdit(props) {
  const { history, setEditing } = props

  return () => {
    const params = queryString.parse(history.location.search)
    const nextSearch = queryString.stringify({ ...params, action: 'edit' })
    history.push({ search: `?${nextSearch}` })
    setEditing(true)
  }
}

function handleReset(props) {
  const { history, initialValues } = props

  return () => {
    const params = queryString.parse(history.location.search)

    const { geometry } = initialValues
    const [lng, lat] = geometry.coordinates
    const nextSearch = queryString.stringify({ ...params, lat, lng })
    history.push({ search: `?${nextSearch}` })
    props.reset()
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSignal: id => dispatch(signalModule.findById(id)),
  }
}

function mapStateToProps(state, props) {
  const { id } = props

  const selectors = signalModule.selectors(state)('default')
  const signalCache = selectors.cache()

  const signalResource = signalCache[id] || {}
  const isResolving = signalResource.state === 'resolving'
  const entity = signalResource.entity || {}
  const form = `signal-${id}`
  const hasLoaded = !isEmpty(entity)
  const initialValues = entity

  return {
    entity,
    form,
    hasLoaded,
    initialValues,
    isResolving,
  }
}
