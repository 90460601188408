import {
  assign,
  filter,
  includes,
  isEmpty,
  stubTrue,
  toLower,
  trim,
} from 'lodash'
import { List } from 'react-virtualized'
import {
  compose,
  lifecycle,
  pure,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose'
import Downshift from 'downshift'
import onClickOutside from 'react-onclickoutside'
import PropTypes from 'prop-types'
import React from 'react'

import FilterDropdownItem from 'components/filter-next/filter-dropdown-item'
import Spinner from 'components/spinner'

import styles from './styles'

// This was an override for Radium,
// as pseudo-selectors had issues with the input element
import './styles.css'
import { useTranslation } from 'react-i18next'

const MAX_HEIGHT = 294
const ROW_HEIGHT = 42

export default compose(
  withState('contentLoaded', 'setContentLoaded', false),
  withState('dropdownItems', 'setDropdownItems', []),
  withState('searchValue', 'setSearchValue', ''),
  withHandlers({
    handleClickOutside,
    handleDropdownChange,
    itemsFilter,
  }),
  lifecycle({
    componentDidMount,
    componentWillReceiveProps,
  }),
  withPropsOnChange(['dropdownItems', 'searchValue'], filterDropdownItems),
  withHandlers({
    renderItem,
  }),
  onClickOutside,
  pure
)(Dropdown)

function Dropdown(props) {
  const {
    category,
    contentLoaded,
    currentFilters = {},
    dropdownItems,
    enableSearch,
    filteredItems,
    handleDropdownChange,
    itemsFilter,
    onOuterClick,
    renderItem,
    searchValue,
    setSearchValue,
    width = 275,
    ...customStyles
  } = props

  const { t } = useTranslation()

  const menuStyles = assign({}, styles.menu, customStyles)

  const totalHeight = filteredItems.length * ROW_HEIGHT
  const height = totalHeight > MAX_HEIGHT ? MAX_HEIGHT : totalHeight

  return (
    <Downshift
      onOuterClick={onOuterClick}
      onSelect={handleDropdownChange}
      stateReducer={stateReducer}
    >
      {({ getItemProps }) => {
        const rowRenderer = rowProps =>
          renderItem({ ...rowProps, getItemProps })

        return (
          <div style={menuStyles} className="filter-dropdown">
            {contentLoaded ? (
              <div>
                {enableSearch && (
                  <div style={styles.searchWrapper}>
                    <input
                      autoFocus
                      onChange={e => setSearchValue(e.target.value)}
                      placeholder={t('placeholder.selectOrSearch')}
                      value={searchValue}
                      style={styles.searchInput}
                    />
                  </div>
                )}
                <List
                  height={height}
                  rowCount={filteredItems.length}
                  rowHeight={ROW_HEIGHT}
                  rowRenderer={rowRenderer}
                  style={styles.menuList}
                  width={width}
                />
              </div>
            ) : (
              <div style={styles.spinner}>
                <Spinner size="medium" type="dots" />
              </div>
            )}
          </div>
        )
      }}
    </Downshift>
  )
}

Dropdown.propTypes = {
  category: PropTypes.string,
  contentLoaded: PropTypes.bool.isRequired,
  currentFilters: PropTypes.object.isRequired,
  dropdownItems: PropTypes.array,
  enableSearch: PropTypes.bool.isRequired,
  fetchItems: PropTypes.func.isRequired,
  filteredItems: PropTypes.array.isRequired,
  handleDropdownChange: PropTypes.func.isRequired,
  itemsFilter: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onOuterClick: PropTypes.func.isRequired,
  onSet: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func.isRequired,
  width: PropTypes.number,
}

function componentDidMount() {
  const { fetchItems, setContentLoaded, setDropdownItems } = this.props

  fetchItems().then(dropdownItems => {
    setDropdownItems(dropdownItems)
    setContentLoaded(true)
  })
}

function componentWillReceiveProps(nextProps) {
  const { fetchItems, setContentLoaded, setDropdownItems } = this.props
  if (!nextProps.contentLoaded) {
    fetchItems().then(dropdownItems => {
      setDropdownItems(dropdownItems)
      setContentLoaded(true)
    })
  }
}

function filterDropdownItems(props) {
  const { dropdownItems, itemsFilter } = props

  return {
    filteredItems: filter(dropdownItems, itemsFilter),
  }
}

function handleClickOutside(props) {
  return () => props.onOuterClick()
}

function handleDropdownChange(props) {
  const { category, currentFilters, onClear, onSet, setContentLoaded } = props

  return item => {
    if (!category) {
      setContentLoaded(false)
    }

    return isCurrentFilter({ category, currentFilters, item })
      ? onClear({ category, value: item.value })
      : onSet(item)
  }
}

function isCurrentFilter({ category = '', currentFilters = {}, item = {} }) {
  const current = currentFilters[category] || []
  return includes(current, item.value)
}

function itemsFilter(props) {
  const { enableSearch, searchValue } = props

  if (!enableSearch) return stubTrue

  return item => {
    if (!item || isEmpty(item)) return false

    const rawSearchField = item.search || item.label
    const searchField = toLower(trim(rawSearchField))
    const searchTerm = toLower(trim(searchValue))

    return searchField.includes(searchTerm)
  }
}

function renderItem({ category, currentFilters, filteredItems }) {
  return ({ getItemProps, index, key, style }) => {
    const item = filteredItems[index]
    const { id, onClick } = getItemProps({ item })

    const current = isCurrentFilter({
      category,
      currentFilters,
      item,
    })

    const iconName =
      item.action === 'delete'
        ? 'cross'
        : current
        ? 'checked'
        : !category
        ? 'arrow'
        : null

    return (
      <FilterDropdownItem
        icon={iconName}
        id={id}
        key={key}
        label={item.label}
        onClick={onClick}
        {...style}
      />
    )
  }
}

function stateReducer(state, changes) {
  // this prevents the menu from being closed when the user
  // selects an item with a keyboard or mouse
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        isOpen: true,
        highlightedIndex: state.highlightedIndex,
      }
    default:
      return changes
  }
}
