/*
 * Circle Spinner
 * =======
 */

import React from 'react'
import PropTypes from 'prop-types'
import MDSpinner from 'react-md-spinner'
import Radium from 'radium'
import { colors as brandColors } from 'config/theme/brand'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  'align-middle': {
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}

export default Radium(CircleSpinner)

function CircleSpinner(props) {
  const { align, customStyles } = props
  const rootStyles = [styles.root, customStyles]

  if (align === 'middle') {
    rootStyles.push(styles[`align-${align}`])
  }

  return (
    <div style={rootStyles}>
      <MDSpinner singleColor={brandColors.primary} />
    </div>
  )
}

CircleSpinner.propTypes = {
  align: PropTypes.oneOf(['middle']),
  customStyles: PropTypes.object,
}
