import { SEND_TRACKING } from '../../../constants'

export default options => store => next => (action) => {
  const { clickTrackingFn } = options
  const { type, trackingFn, hookedAction } = action

  const isTrackingType = type === SEND_TRACKING

  if (clickTrackingFn && trackingFn && isTrackingType) {
    const state = store.getState()
    const { trackType, props } = trackingFn(state, hookedAction)

    clickTrackingFn(trackType, props)
  }

  return next(action)
}
