import Radium from 'radium'
import React from 'react'

import { Block } from 'components/common'
import Button from 'components/button'
import colors from 'config/theme/colors'
import Icon from 'components/icon'

const buttonStyle = {
  backgroundColor: 'transparent',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  color: colors.white,
  fontSize: 10,
  ':hover': {
    color: colors.white,
  },
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  position: 'absolute',
  right: 0,
  top: 0,
}

export default Radium(HeaderPanel)

function HeaderPanel(props) {
  const { color, onClose, resource, title, toggleExpand, toggleIcon } = props

  const buttonStyles = {
    ...buttonStyle,
    ':hover': {
      backgroundColor: color.lighter,
    },
  }

  if (toggleExpand) buttonStyles.padding = 15

  const toggleButton = toggleExpand && (
    <Button
      onClick={toggleExpand}
      style={{ ...buttonStyles, padding: '13px 14px', right: 40 }}
      type="button"
    >
      <Icon fontSize="12px" name={toggleIcon} />
    </Button>
  )

  return (
    <Block backgroundColor={color.normal} padding={20} position="relative">
      <Block color={colors.white} fontWeight={100} marginTop={20} opacity={0.5}>
        {resource}
      </Block>
      <Block
        color={colors.white}
        cursor="text"
        fontSize={'16px'}
        fontWeight={300}
        marginTop={5}
      >
        {title}
      </Block>
      {toggleButton}
      <Button onClick={onClose} style={buttonStyles} type="button">
        <Icon name="close" />
      </Button>
    </Block>
  )
}
