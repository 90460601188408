import { assign, capitalize, get, isEqual, mapValues, toNumber } from 'lodash'
import { getLocationReference } from '@lighthouse/common'
import moment, { duration } from 'moment'
import React from 'react'

import { AREA_TYPES, DEFAULT_REPORTING_PERIOD_LONG } from 'config/constants'

import CellLocation from 'components/table-next/cell-location'

export function componentWillMount() {
  const { config, fetch, listFilters, resetList, setFilters } = this.props
  const { from, to } = listFilters

  if (from && to) {
    resetList()
    return fetch({ appendToList: false })
  }

  const initialRange = getStartingDateRange(config.reportingPeriod)
  const initialFilters = assign({}, initialRange, listFilters)
  return setFilters(config.listId, initialFilters)
}

export function componentWillReceiveProps(nextProps) {
  const prevProps = this.props
  const filtersEqual = isEqual(prevProps.listFilters, nextProps.listFilters)
  if (filtersEqual) return

  // reset list pagination after new filters to ensure we start the new query
  // from page 1
  nextProps.resetList()
  nextProps.fetch({ appendToList: false })
}

export function fetchHistorical(props) {
  const { fetch, list, listId, paginate } = props

  return params => {
    const nextPage = get(list, 'pagination.links.next.page', '1')
    const pageOffset = toNumber(nextPage)
    paginate(listId, { page: pageOffset })
    return fetch(params)
  }
}

export function getFilterUser(user) {
  const { firstName, lastName, search } = user.entity
  const label =
    firstName && lastName ? `${firstName} ${lastName}` : 'Unknown user'

  return {
    label,
    search,
    value: get(user, 'entity.user._id'),
  }
}

export function getFilterZone(zone) {
  const { name } = zone.entity
  const label = name || 'Unknown Zone'

  return {
    label,
    value: zone.id,
  }
}

export function getStartingDateRange(days = DEFAULT_REPORTING_PERIOD_LONG) {
  const from = moment()
    .subtract(days - 1, 'days')
    .startOf('day')
    .toDate()

  const to = moment()
    .endOf('day')
    .toDate()

  return {
    from,
    to,
  }
}

export function getTitle({ from, label, to, t }) {
  const capitalizedTitle = capitalize(label)
  return from
    ? t('labelTitleDateRange', {
        title: capitalizedTitle,
        from: moment(from).format('MMM D'),
        to: moment(to).format('MMM D'),
      })
    : t('labelAllTitle', { title: capitalizedTitle })
}

export function isRowLoaded({ list }) {
  return ({ index }) => !!list.items[index]
}

export function renderDuration({
  cellData,
  rowData,
  measurement = 'milliseconds',
}) {
  const durationValue = get(rowData, 'entity.duration')
  if (durationValue) {
    return cellData && duration(durationValue, measurement).humanize()
  }

  return cellData && ''
}

export function renderFineDuration({
  cellData,
  rowData,
  measurement = 'milliseconds',
}) {
  const durationValue = get(rowData, 'entity.duration')
  if (durationValue) {
    const durationByMeasurement = duration(durationValue, measurement)
    const days = durationByMeasurement.get('days')
    const hours = durationByMeasurement.get('hours')
    const minutes = durationByMeasurement.get('minutes')
    let fineDuration = ''
    fineDuration += days !== 0 ? days + 'd ' : ''
    fineDuration += hours !== 0 ? hours + 'h ' : ''
    fineDuration += minutes !== 0 ? minutes + 'm' : ''
    return cellData && fineDuration
  }

  return cellData && ''
}

export function renderHeader({ label }) {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {label}
    </div>
  )
}

export function renderLocationReference(props) {
  const { areaLocations: areaLocationCache, zoneCache = {} } = props

  return ({ rowData }) => {
    if (!rowData || !rowData.entity) return

    const areaLocations = mapValues(areaLocationCache, 'entity')
    const zones = mapValues(zoneCache, 'entity')

    const geocodedLabelPath = rowData.entity.geometryReverseGeocoded
      ? 'geometryReverseGeocoded.label'
      : 'gps.reverseGeocoded.label'

    const label = getLocationReference({
      areaLocations,
      entity: rowData.entity,
      geocodedLabelPath,
      zones,
    })

    const actualGps = get(rowData, 'entity.rawGps.geometry', null)
    const entityGps = get(rowData, 'entity.gps.geometry', null)

    return (
      <CellLocation
        actualGps={actualGps}
        entityGps={entityGps}
        distanceThreshold={250}
        label={label}
      />
    )
  }
}
