import actions from './action-types'
import actionCreators from './action-creators'
import reducers from './reducers'
import selectors from './selectors'

export default {
  actions,
  actionCreators,
  reducers,
  selectors,
}
