import { REQUEST } from '../../../../middleware/request'

export default function buildActionCreators(actions, baseUrlFn, headersFn, paramsFn) {
  return {
    markAsRead,
  }

  function markAsRead(opts) {
    const {
      roomId,
      speakerId,
      timestamp,
    } = opts
    return (dispatch, getState) => {
      const state = getState()

      const params = paramsFn(state, {
        roomId,
      })

      const endpoint = `${baseUrlFn(params, state)}/${params.roomId}/actions/mark-as-read`
      const headers = headersFn({
        speakerId,
      }, state)
      const payload = {
        timestamp,
      }
      const attachToActions = {
        roomId,
        speakerId,
      }

      return dispatch(markAsReadRequest(endpoint, headers, payload, attachToActions))
    }
  }

  function markAsReadRequest(endpoint, headers, payload, attachToActions) {
    const action = {
      types: [
        actions.MARK_AS_READ_REQUEST,
        actions.MARK_AS_READ_SUCCESS,
        actions.MARK_AS_READ_ERROR,
      ],
      method: 'PUT',
      endpoint,
      headers,
      body: payload,
      attachToActions,
    }

    return {
      [REQUEST]: action,
    }
  }
}
