import PropTypes from 'prop-types'
import React from 'react'
import { colors as brandColors } from 'config/theme/brand'

import { Block } from 'components/common'

export default function Radio(props) {
  const { checked = false, disabled, onChange } = props

  return (
    <Block
      backgroundColor="#FFF"
      borderColor={checked ? brandColors.primary : '#999'}
      borderRadius={8}
      boxSizing="border-box"
      borderStyle="solid"
      borderWidth={1}
      cursor={disabled ? 'inherit' : 'pointer'}
      height={16}
      position="relative"
      width={16}
    >
      <label>
        {onChange && (
          <input
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            style={{
              visibility: 'hidden',
            }}
            type="radio"
          />
        )}
        <Block
          backgroundColor={checked ? brandColors.primary : '#FFF'}
          borderRadius={4}
          cursor={disabled ? 'inherit' : 'pointer'}
          height={8}
          left={3}
          position="absolute"
          top={3}
          width={8}
        />
      </label>
    </Block>
  )
}

Radio.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
