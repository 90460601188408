import React, { Component, Fragment } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import FlagsHOC from 'components/flags/hoc'
import cuid from 'cuid'

import {
  arrayPush,
  arrayRemoveAll,
  change,
  Field,
  FieldArray,
  formValueSelector,
} from 'redux-form'

import { validation } from '@lighthouse/sdk'

import {
  assign,
  chain,
  filter,
  find,
  get,
  isEmpty,
  isFinite,
  isNil,
  join,
  map,
  omit,
} from 'lodash'

import {
  FieldGroup,
  FieldSet,
  InputCheckbox,
  InputDisplayImage,
  InputMaterialCheckbox,
  InputSelect,
  InputText,
  InputWysiwyg,
} from 'components/form'

import { blankField, blankFormGroup } from 'components/templates/helpers'
import { Block, Flex } from 'components/common'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'
import Caret from 'components/caret'
import Confirmable from 'components/confirmable'
import colors from 'config/theme/colors'
import TitleBar from 'modules/title-bar'
import Icon from 'components/icon'
import Anchor from 'components/anchor'

import { getSummaryFieldCount } from '../helpers'
import styles from './styles'
import { withTranslation } from 'react-i18next'
import { TOOLTIPS } from 'config/constants'
import i18next from 'i18next'
import duplicateName from '../../../helpers/duplicateName'

const MAX_SUMMARY_FIELDS = 3

const centerHeaderStyles = assign({}, styles.headerCell, styles.center)
const defaultSelectOptions = ['']
const starRatingOptions = ['1', '2', '3', '4', '5']

const fieldTooltip = [TOOLTIPS.field.titleT, TOOLTIPS.field.messageT]
const referenceTooltip = [
  TOOLTIPS.templateFieldReference.titleT,
  TOOLTIPS.templateFieldReference.messageT,
]

const summaryFieldTooltip = [
  TOOLTIPS.summaryField.titleT,
  [TOOLTIPS.summaryField.messageT, { MAX_SUMMARY_FIELDS }],
]

const typeTooltip = [TOOLTIPS.type.titleT, TOOLTIPS.type.messageT]

const defaultFieldTypeOptions = [
  {
    label: 'Date',
    labelT: 'labelDate',
    value: 'date',
  },
  {
    label: 'Display Image',
    labelT: 'labelDisplayImage',
    value: 'image-display',
  },
  {
    label: 'Display Text',
    labelT: 'labelDisplayText',
    value: 'text-display',
  },
  {
    label: 'File',
    labelT: 'labelFile',
    value: 'file',
  },
  {
    label: 'Number',
    labelT: 'labelNumber',
    value: 'number',
  },
  {
    label: 'Media',
    labelT: 'labelMedia',
    value: 'photo',
  },
  {
    label: 'Relationship',
    labelT: 'labelRelationship',
    value: 'relationship',
  },
  {
    label: 'Select',
    labelT: 'labelSelect',
    value: 'select',
  },
  {
    label: 'Signature',
    labelT: 'labelSignature',
    value: 'signature',
  },
  {
    label: 'Switch',
    labelT: 'labelSwitch',
    value: 'switch',
  },
  {
    label: 'Text',
    labelT: 'labelText',
    value: 'text',
  },
  {
    label: 'Time',
    labelT: 'labelTime',
    value: 'time',
  },
  {
    label: 'User',
    labelT: 'labelUser',
    value: 'reference',
  },
]

const fieldReadVisibilityOptions = [
  { label: 'Display Always', labelT: 'labelDisplayAlways', value: true },
  {
    label: 'Display On Form Entry Only',
    labelT: 'labelDisplayOnFormEntryOnly',
    value: false,
  },
]

const fieldDateFormatOptions = [
  { label: 'mm/dd/yy', value: 'MM/DD/YY' },
  { label: 'dd/mm/yy', value: 'DD/MM/YY' },
]

const fieldTimeFormatOptions = [
  { label: '12 hour clock', labelT: 'label12HourClock', value: 'h:mm a' },
  { label: '24 hour clock', labelT: 'label24HourClock', value: 'HH:mm' },
]

const filterCollectionOptions = [
  {
    label: 'Content Template',
    labelT: 'labelContentTemplate',
    value: 'contentTemplate',
  },
]

const repeatableOptions = [
  { label: 'Unlimited', labelT: 'labelUnlimited', value: 0 },
  { label: 'Not Repeatable', labelT: 'labelNotRepeatable', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
]

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

const validateReference = (reference, form) => {
  const formGroups = get(form, 'formGroups', [])
  const fieldGroups = []
  const fields = []
  formGroups.forEach(formGroup => {
    formGroup.fieldGroups.forEach(fieldGroup => {
      fields.push(...(fieldGroup.fields || []))
    })
  })

  return validateReferences({ fields, reference })
}

const validateReferences = ({ fields, reference }) => {
  if (!fields || !reference) {
    return
  }

  const matchingReferenceFields = fields.filter(
    field => !!field.reference && field.reference === reference
  )

  if (matchingReferenceFields.length > 1) {
    return `References must be unique, please update: "${matchingReferenceFields
      .map(field => field.label)
      .join(', ')}".`
  }

  return
}

class Template extends Component {
  constructor(props) {
    super(props)

    this.onSelectTypeChange = this.onSelectTypeChange.bind(this)
    this.renderFormGroups = this.renderFormGroups.bind(this)
    this.renderFieldGroups = this.renderFieldGroups.bind(this)
    this.renderFields = this.renderFields.bind(this)
    this.renderSelectOptions = this.renderSelectOptions.bind(this)
    this.renderButtonGroup = this.renderButtonGroup.bind(this)
  }

  render() {
    const {
      formValueSelector,
      isFooterForm,
      isHeaderForm,
      readOnly,
      small,
    } = this.props

    const field = isFooterForm
      ? 'footerForm'
      : isHeaderForm
      ? 'headerForm'
      : 'formGroups'
    const formGroups = formValueSelector(field)
    const summaryFieldCount = getSummaryFieldCount(formGroups)
    // NOTE: pass ordering key to force render when order changes
    const orderingKey = join(
      map(formGroups, group => group._id || group.tempId),
      ''
    )

    return (
      <div style={styles.root}>
        <FieldArray
          name={field}
          component={this.renderFormGroups}
          orderingKey={orderingKey}
          readOnly={readOnly}
          summaryFieldCount={summaryFieldCount}
          small={small}
        />
      </div>
    )
  }

  onFieldTypeChange(fieldPath, required) {
    const { change, formValueSelector } = this.props

    const optionsPath = `${fieldPath}.options`
    const validationPath = `${fieldPath}.validation`
    const valuePath = `${fieldPath}.value`

    return (event, newValue) => {
      const isDisplayText = newValue === 'text-display'
      const isImageDisplay = newValue === 'image-display'
      const isDisplayField = isDisplayText || isImageDisplay
      const isSwitch = newValue === 'switch'

      const options = formValueSelector(optionsPath) || {}
      // NOTE: only reset required if fieldtype is switch, display image or text
      const nextRequired =
        isSwitch || isDisplayText || isImageDisplay ? false : required || false
      // NOTE: only reset summary if fieldtype is display text
      const nextSummary =
        isDisplayText || isImageDisplay ? false : options.summary || false

      // Note: Delete showOnRead if the field changes to a non image/text display field
      const nextShowOnRead =
        isDisplayField && options.showOnRead !== undefined
          ? options.showOnRead
          : true

      const nextOptions = {
        ...options,
        showOnRead: nextShowOnRead,
        required: nextRequired,
        summary: nextSummary,
      }

      // Reset options, validation and value on change
      change(optionsPath, nextOptions)
      change(validationPath, { enum: [] })
      change(valuePath, null)
    }
  }

  onSelectTypeChange(fieldPath) {
    const { arrayPush, arrayRemoveAll, change, formValueSelector } = this.props

    const enumPath = `${fieldPath}.validation.enum`
    const typePath = `${fieldPath}.options.type`

    const previousType = formValueSelector(typePath)

    return (event, newValue) => {
      const isStars = newValue === 'stars'
      const defaultOptions = isStars ? starRatingOptions : defaultSelectOptions
      const wasStars = previousType === 'stars'

      change(typePath, newValue)

      if (wasStars) {
        arrayRemoveAll(enumPath)
        defaultOptions.forEach(option => arrayPush(enumPath, option))
      } else {
        const hasOptions = formValueSelector(enumPath).length

        if (!hasOptions) {
          defaultOptions.forEach(option => arrayPush(enumPath, option))
        }
      }
    }
  }

  renderFormGroups({ fields, readOnly, small, summaryFieldCount }) {
    const { dataTestIdPrefix, t } = this.props
    const formFormGroups = fields.map((formPath, index) => {
      const { _id, label, repeatable, tempId } = fields.get(index)
      const hasRepeatable = !isNil(repeatable)
      const newRepeatable = hasRepeatable ? repeatable : 1

      const repeatableExists = find(
        repeatableOptions,
        item => item.value === newRepeatable
      )

      if (!repeatableExists) {
        repeatableOptions.push({ label: repeatable, value: repeatable })
      }

      const title = label || t('labelNewGroup')
      const dataTestId = dataTestIdPrefix
        ? `${dataTestIdPrefix}-form-group-title-${index}`
        : `form-group-title-${index}`

      return (
        <div key={_id || tempId} style={styles.formGroup} id={title}>
          <TitleBar title={title}>
            {this.renderButtonGroup({ fields, index })}
          </TitleBar>
          <FieldGroup boxed>
            <FieldSet>
              <Field
                component={InputText}
                dataTestId={dataTestId}
                label={t('labelGroupLabel')}
                name={`${formPath}.label`}
                placeholder={t('placeholder.label')}
                readOnly={readOnly}
                required
                small={small}
                validate={[isRequired]}
              />
              <Field
                component={InputText}
                dataTestId="template-group-description"
                label={t('labelDescription')}
                name={`${formPath}.description`}
                placeholder={t('placeholder.description')}
                readOnly={readOnly}
                required={false}
                small={small}
              />
              <Field
                component={InputSelect}
                clearable={false}
                isValidNewOption={repeatableIsValidOption}
                label={t('labelRepeatable')}
                name={`${formPath}.repeatable`}
                options={map(repeatableOptions, ({ label, labelT, value }) => {
                  return { label: labelT ? t(labelT) : label, value }
                })}
                placeholder={t('placeholder.chooseOptionOrAddNewOption')}
                readOnly={readOnly}
                required={false}
                shouldSort={false}
                small={small}
                tooltip={[
                  t(TOOLTIPS.templateRepeatable.titleT),
                  t(TOOLTIPS.templateRepeatable.messageT),
                ]}
              />
              <Field
                component={InputMaterialCheckbox}
                label={t('labelUserCanSkip')}
                name={`${formPath}.canSkip`}
                required
              />
            </FieldSet>
            <FieldArray
              component={this.renderFieldGroups}
              name={`${formPath}.fieldGroups`}
              readOnly={readOnly}
              small={small}
              summaryFieldCount={summaryFieldCount}
            />
          </FieldGroup>
        </div>
      )
    })
    const addGroupDataTestId = dataTestIdPrefix
      ? `${dataTestIdPrefix}-add-group-button`
      : 'add-group-button'

    return (
      <div>
        {formFormGroups}
        <Button
          dataTestId={addGroupDataTestId}
          theme="primary"
          style={{ marginBottom: '40px', marginTop: '20px' }}
          onClick={() => fields.push(blankFormGroup())}
        >
          {t('button.addGroup')}
        </Button>
      </div>
    )
  }

  renderFieldGroups({ fields, readOnly, small, summaryFieldCount }) {
    const { disableSummaryFields, flags, t } = this.props

    // NOTE: content templates disables summary fields
    const isSummaryFieldsEnabled = !disableSummaryFields
    const fieldNameWidth = isSummaryFieldsEnabled ? '15%' : '40%'

    const formFieldGroups = fields.map((fieldGroupPath, index) => {
      const { _id, tempId } = fields.get(index)

      return (
        <div key={_id || tempId}>
          <table style={styles.fieldsTable}>
            <thead>
              <tr>
                <td width={fieldNameWidth} style={styles.headerCell}>
                  <div style={styles.titleWithTooltip}>
                    {t('labelField')}
                    <Anchor
                      tooltip={[t(fieldTooltip[0]), t(fieldTooltip[1])]}
                      style={styles.tooltip}
                    >
                      <Icon name="help" />
                    </Anchor>
                  </div>
                </td>
                {flags.templateFieldReferences ? (
                  <td width={fieldNameWidth} style={styles.headerCell}>
                    <div style={styles.titleWithTooltip}>
                      {t('labelReference')}
                      <Anchor
                        tooltip={[
                          t(referenceTooltip[0]),
                          t(referenceTooltip[1]),
                        ]}
                        style={styles.tooltip}
                      >
                        <Icon name="help" />
                      </Anchor>
                    </div>
                  </td>
                ) : null}
                <td width="10%" style={centerHeaderStyles}>
                  Required
                </td>
                {isSummaryFieldsEnabled && (
                  <td width="15%" style={centerHeaderStyles}>
                    <div style={styles.titleWithTooltip}>
                      {t('labelSummaryField')}
                      <Anchor
                        tooltip={[
                          t(summaryFieldTooltip[0]),
                          t(
                            summaryFieldTooltip[1][0],
                            summaryFieldTooltip[1][1]
                          ),
                        ]}
                        style={styles.tooltip}
                      >
                        <Icon name="help" />
                      </Anchor>
                    </div>
                  </td>
                )}
                <td width="17%" style={styles.headerCell}>
                  <div style={styles.titleWithTooltip}>
                    {t('labelType')}
                    <Anchor
                      tooltip={[t(typeTooltip[0]), t(typeTooltip[1])]}
                      style={styles.tooltip}
                    >
                      <Icon name="help" />
                    </Anchor>
                  </div>
                </td>
                <td width="30%" style={styles.headerCell}>
                  {t('labelOptions')}
                </td>
                <td width="5%" style={styles.headerCell} />
              </tr>
            </thead>
            <FieldArray
              name={`${fieldGroupPath}.fields`}
              component={this.renderFields}
              readOnly={readOnly}
              rerenderOnEveryChange
              small={small}
              summaryFieldCount={summaryFieldCount}
            />
          </table>
        </div>
      )
    })

    return <div>{formFieldGroups}</div>
  }

  renderFields({ fields, readOnly, small, summaryFieldCount }) {
    const {
      dataTestIdPrefix,
      disableSummaryFields,
      enableRelationshipFields,
      flags,
      templateResources,
      t,
    } = this.props

    const fieldTypeOptions = filter(defaultFieldTypeOptions, field => {
      if (field.value === 'relationship' && !enableRelationshipFields)
        return false
      return true
    })

    // NOTE: content templates disables summary fields
    const isSummaryFieldsEnabled = !disableSummaryFields

    const formFields = fields.map((fieldPath, index) => {
      const { _id, fieldtype, options = {}, tempId } = fields.get(index)
      const { required, summary, type } = options

      const displayType =
        fieldtype === 'text-display' || fieldtype === 'image-display'

      const requiredDisabled = fieldtype === 'switch' || displayType
      const summaryDisabled =
        displayType || (!summary && summaryFieldCount >= MAX_SUMMARY_FIELDS)

      let fieldOptions = <td style={styles.bodyCell} />

      if (fieldtype === 'select') {
        const selectOptions = [
          {
            label: t('labelCheckboxes'),
            value: 'select-multi',
          },
          {
            label: t('labelMultiSelectList'),
            value: 'select-multi-list',
          },
          {
            label: t('labelRadioButtons'),
            value: 'select-single',
          },
          {
            label: t('labelSingleSelectList'),
            value: 'select-single-list',
          },
          {
            label: t('labelStarRating'),
            value: 'stars',
          },
        ]

        if (type !== 'stars') {
          fieldOptions = (
            <td style={styles.bodyCell}>
              <Field
                clearable={false}
                component={InputSelect}
                onChange={this.onSelectTypeChange(fieldPath)}
                options={selectOptions}
                name={`${fieldPath}.options.type`}
                placeholder={t('placeholder.selectType')}
                readOnly={readOnly}
                validate={[isRequired]}
                small={small}
                style={[styles.smallMarginBottom]}
              />
              <FieldArray
                component={this.renderSelectOptions}
                name={`${fieldPath}.validation.enum`}
                readOnly={readOnly}
                small={small}
              />
            </td>
          )
        }

        if (type === 'stars') {
          fieldOptions = (
            <td style={styles.bodyCell}>
              <Field
                clearable={false}
                component={InputSelect}
                onChange={this.onSelectTypeChange(fieldPath)}
                options={selectOptions}
                name={`${fieldPath}.options.type`}
                placeholder={t('placeholder.selectType')}
                readOnly={readOnly}
                validate={[isRequired]}
                small={small}
                style={[styles.smallMarginBottom]}
              />
            </td>
          )
        }
      }

      if (fieldtype === 'relationship') {
        // TODO: Need to update this to reflect option
        // defined in options filter collection
        const templateOptions = chain(templateResources)
          .map(({ entity }) => ({
            label: entity.name,
            value: entity._id,
          }))
          .sortBy('label')
          .value()

        fieldOptions = (
          <td style={styles.bodyCell}>
            <Field
              clearable={false}
              component={InputSelect}
              options={map(
                filterCollectionOptions,
                ({ label, labelT, value }) => {
                  return { label: labelT ? t(labelT) : label, value }
                }
              )}
              name={`${fieldPath}.options.filterCollection`}
              placeholder={t('placeholder.selectFilterCollection')}
              readOnly={readOnly}
              required
              small={small}
              style={[styles.smallMarginBottom]}
            />
            <Field
              clearable={false}
              component={InputSelect}
              options={templateOptions}
              multi
              name={`${fieldPath}.options.filters`}
              placeholder={t('placeholder.selectFilters')}
              readOnly={readOnly}
              required
              small={small}
              style={[styles.smallMarginBottom]}
            />
          </td>
        )
      }

      if (fieldtype === 'text') {
        fieldOptions = (
          <td style={styles.bodyCellOption}>
            <Field
              component={InputCheckbox}
              label={t('labelMultiLine')}
              name={`${fieldPath}.options.multi`}
              readOnly={readOnly}
              required={null}
              small={small}
              style={styles.noMarginBottom}
            />
          </td>
        )
      }

      if (fieldtype === 'text-display') {
        fieldOptions = (
          <td style={styles.bodyCell}>
            <Field
              component={InputWysiwyg}
              label=""
              name={`${fieldPath}.value`}
              readOnly={readOnly}
              required={false}
              style={[styles.editor, styles.smallMarginBottom]}
            />
            <Field
              clearable={false}
              defaultValue
              options={map(
                fieldReadVisibilityOptions,
                ({ label, labelT, value }) => {
                  return { label: labelT ? t(labelT) : label, value }
                }
              )}
              component={InputSelect}
              name={`${fieldPath}.options.showOnRead`}
              readOnly={readOnly}
              required={false}
              small={small}
            />
          </td>
        )
      }

      if (fieldtype === 'date') {
        fieldOptions = (
          <td style={styles.bodyCell}>
            <Field
              clearable={false}
              component={InputSelect}
              options={fieldDateFormatOptions}
              name={`${fieldPath}.options.format`}
              placeholder={t('placeholder.selectDateFormat')}
              readOnly={readOnly}
              required
              small={small}
              style={[styles.smallMarginBottom]}
              validate={[isRequired]}
            />
          </td>
        )
      }

      if (fieldtype === 'time') {
        fieldOptions = (
          <td style={styles.bodyCell}>
            <Field
              clearable={false}
              component={InputSelect}
              options={map(
                fieldTimeFormatOptions,
                ({ label, labelT, value }) => {
                  return { label: labelT ? t(labelT) : label, value }
                }
              )}
              name={`${fieldPath}.options.format`}
              placeholder={t('placeholder.selectTimeFormat')}
              readOnly={readOnly}
              required
              small={small}
              style={[styles.smallMarginBottom]}
              validate={[isRequired]}
            />
          </td>
        )
      }

      if (fieldtype === 'image-display') {
        fieldOptions = (
          <td style={styles.bodyCell}>
            <Field
              component={InputDisplayImage}
              name={`${fieldPath}.value`}
              readOnly={readOnly}
              required={false}
              style={[styles.smallMarginBottom]}
            />
            <Field
              clearable={false}
              defaultValue
              options={map(
                fieldReadVisibilityOptions,
                ({ label, labelT, value }) => {
                  return { label: labelT ? t(labelT) : label, value }
                }
              )}
              component={InputSelect}
              name={`${fieldPath}.options.showOnRead`}
              readOnly={readOnly}
              required={false}
              small={small}
            />
          </td>
        )
      }

      if (fieldtype === 'reference') {
        const selectOptions = [
          {
            label: t('labelSingleSelectList'),
            value: 'select-single-list',
          },
        ]

        fieldOptions = (
          <td style={styles.bodyCell}>
            <Field
              clearable={false}
              component={InputSelect}
              options={selectOptions}
              name={`${fieldPath}.options.type`}
              placeholder={t('labelSingleSelectList')}
              readOnly={readOnly}
              small={small}
              style={[styles.smallMarginBottom]}
              disabled={true}
            />
          </td>
        )
      }

      const arrowUpColor = index === 0 ? colors.gray.lighter : colors.gray.light

      const arrowDownColor =
        index + 1 === fields.length ? colors.gray.lighter : colors.gray.light

      const arrowUpCursor = index === 0 ? 'not-allowed' : 'pointer'

      const arrowDownCursor =
        index + 1 === fields.length ? 'not-allowed' : 'pointer'

      const dataTestId = dataTestIdPrefix
        ? `${dataTestIdPrefix}-field-name-${index}`
        : `field-name-${index}`

      return (
        <tr key={_id || tempId}>
          <td style={styles.bodyCell}>
            <Field
              component={InputText}
              dataTestId={dataTestId}
              name={`${fieldPath}.label`}
              readOnly={readOnly}
              required
              small={small}
              validate={[isRequired]}
            />
          </td>
          {flags.templateFieldReferences ? (
            <td style={styles.bodyCell}>
              <Field
                component={InputText}
                dataTestId={`${dataTestId}-reference`}
                name={`${fieldPath}.reference`}
                small={small}
                validate={[validateReference]}
              />
            </td>
          ) : null}
          <td style={styles.bodyCellCheckbox}>
            <Field
              required={required}
              disabled={requiredDisabled}
              component={InputCheckbox}
              name={`${fieldPath}.options.required`}
              readOnly={readOnly}
              small={small}
            />
          </td>
          {isSummaryFieldsEnabled && (
            <td style={styles.bodyCellCheckbox}>
              <Field
                component={InputCheckbox}
                disabled={summaryDisabled}
                name={`${fieldPath}.options.summary`}
                readOnly={readOnly}
                small={small}
              />
            </td>
          )}
          <td style={styles.bodyCell}>
            <Field
              clearable={false}
              options={map(fieldTypeOptions, ({ label, labelT, value }) => {
                return { label: labelT ? t(labelT) : label, value }
              })}
              component={InputSelect}
              onChange={this.onFieldTypeChange(fieldPath, required)}
              name={`${fieldPath}.fieldtype`}
              readOnly={readOnly}
              required
              small={small}
            />
          </td>
          {fieldOptions}
          <td style={styles.bodyCell}>
            <Flex alignItems="center">
              {!readOnly && fields.length > 1 && (
                <Button
                  onClick={() => fields.remove(index)}
                  padding="0px 5px"
                  theme="simple noMargin"
                >
                  <Icon name="trash" theme={styles.trashIcon} />
                </Button>
              )}
              {fields.length > 1 && (
                <Flex alignItems="center" flexDirection="column" flexGrow={1}>
                  <Block
                    cursor={arrowUpCursor}
                    onClick={() => {
                      if (index === 0) return
                      fields.swap(index, index - 1)
                    }}
                    padding="5px"
                  >
                    <Caret color={arrowUpColor} up />
                  </Block>
                  <Block
                    cursor={arrowDownCursor}
                    onClick={() => {
                      if (index + 1 === fields.length) return
                      fields.swap(index, index + 1)
                    }}
                    padding="0px 5px 5px 5px"
                  >
                    <Caret color={arrowDownColor} down />
                  </Block>
                </Flex>
              )}
            </Flex>
          </td>
        </tr>
      )
    })

    const addFieldStyles = assign({}, styles.bodyCell, styles.noBorder)

    return (
      <tbody>
        {formFields}
        <tr>
          <td style={addFieldStyles}>
            {!readOnly && (
              <Button
                dataTestId="add-field-button"
                theme="simple"
                onClick={() => fields.push(blankField())}
              >
                {t('button.addField')}
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    )
  }

  renderSelectOptions({ fields, readOnly, small }) {
    const { t } = this.props

    const inputOptions = fields.map((fieldPath, index) => {
      const clearable = fields.length > 1

      const onRemove = event => {
        event.preventDefault()
        fields.remove(index)
      }

      return (
        <div key={index} style={styles.smallMarginBottom}>
          <Field
            clearable={clearable}
            component={InputText}
            name={fieldPath}
            onRemove={onRemove}
            readOnly={readOnly}
            small={small}
            validate={[isRequired]}
          />
        </div>
      )
    })

    return (
      <div style={styles.selectOptions}>
        {inputOptions}
        {!readOnly && (
          <Button onClick={() => fields.push('')} theme="positive">
            {t('button.add')}
          </Button>
        )}
      </div>
    )
  }

  renderButtonGroup({ fields, index }) {
    const { isFooterForm, isHeaderForm, t } = this.props
    const multipleFormGroups = fields.length > 1
    const moveUp = index !== 0
    const moveDown = index + 1 !== fields.length
    const { label } = fields.get(index)

    const allgroups = fields.getAll()
    const alllabels = allgroups.map(group => group.label)

    if (multipleFormGroups || isHeaderForm || isFooterForm) {
      return (
        <div
          style={{ padding: 10, justifyContent: 'flex-end', display: 'flex' }}
        >
          <ButtonGroup>
            <Confirmable
              message={t('button.removeFormGroupConfirm')}
              onConfirmed={() => fields.remove(index)}
            >
              {({ handleConfirm, isConfirming, confirmEl }) =>
                isConfirming ? (
                  confirmEl
                ) : (
                  <Fragment>
                    <Button
                      onClick={handleConfirm}
                      marginRight="10px"
                      theme="simple"
                    >
                      {t('button.remove')}
                    </Button>
                  </Fragment>
                )
              }
            </Confirmable>
            <Button
              onClick={() => {
                const fieldToDuplicate = fields.get(index)
                const duplicatedField = {
                  ...fieldToDuplicate,
                  _id: undefined,
                  key: cuid(),
                  tempId: cuid(),
                  label: duplicateName(fieldToDuplicate.label, alllabels),
                  fieldGroups: fieldToDuplicate.fieldGroups.map(fieldGroup => {
                    return {
                      ...fieldGroup,
                      _id: undefined,
                      key: cuid(),
                      tempId: cuid(),
                      fields: fieldGroup.fields.map(field => {
                        return {
                          ...field,
                          _id: undefined,
                          key: cuid(),
                          tempId: cuid(),
                        }
                      }),
                    }
                  }),
                }
                const currentVerticalPosition = window.scrollY
                const groupHeight =
                  label !== undefined
                    ? document.getElementById(label).clientHeight
                    : 700
                const newVerticalPosition =
                  currentVerticalPosition + groupHeight
                window.scrollTo(0, newVerticalPosition)
                return fields.splice(index + 1, 0, duplicatedField)
              }}
            >
              {t('button.duplicate')}
            </Button>
            {moveUp && (
              <Button
                noMargin={index + 1 === fields.length}
                onClick={() => fields.swap(index, index - 1)}
              >
                {t('button.moveUp')}
              </Button>
            )}
            {moveDown && (
              <Button noMargin onClick={() => fields.swap(index, index + 1)}>
                {t('button.moveDown')}
              </Button>
            )}
          </ButtonGroup>
        </div>
      )
    } else {
      return (
        <div
          style={{
            paddingBottom: 10,
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <ButtonGroup>
            <Button
              onClick={() => {
                const fieldToDuplicate = fields.get(index)
                const duplicatedField = {
                  ...fieldToDuplicate,
                  _id: undefined,
                  key: cuid(),
                  tempId: cuid(),
                  label: duplicateName(fieldToDuplicate.label, alllabels),
                  fieldGroups: fieldToDuplicate.fieldGroups.map(fieldGroup => {
                    return {
                      ...fieldGroup,
                      _id: undefined,
                      key: cuid(),
                      tempId: cuid(),
                      fields: fieldGroup.fields.map(field => {
                        return {
                          ...field,
                          _id: undefined,
                          key: cuid(),
                          tempId: cuid(),
                        }
                      }),
                    }
                  }),
                }
                const currentVerticalPosition = window.scrollY
                const groupHeight =
                  label !== undefined
                    ? document.getElementById(label).clientHeight
                    : 700
                const newVerticalPosition =
                  currentVerticalPosition + groupHeight
                window.scrollTo(0, newVerticalPosition)
                return fields.splice(index + 1, 0, duplicatedField)
              }}
            >
              {t('button.duplicate')}
            </Button>
          </ButtonGroup>
        </div>
      )
    }
  }
}

export default compose(
  FlagsHOC,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Template)

function mapStateToProps(state, props) {
  const { form } = props
  const selector = formValueSelector(form)

  return {
    formValueSelector: formPath => selector(state, formPath),
  }
}

function mapDispatchToProps(dispatch, props) {
  const { form } = props

  return {
    arrayPush: (field, value) => dispatch(arrayPush(form, field, value)),
    arrayRemoveAll: field => dispatch(arrayRemoveAll(form, field)),
    change: (field, value) => dispatch(change(form, field, value)),
  }
}

function repeatableIsValidOption({ label }) {
  if (isNil(label) || isEmpty(label)) return false
  return isFinite(Number(label))
}
