import { colors } from 'config/theme'

export default {
  root: {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
  },
  base: {
    indeterminate: {
      checkbox: {
        border: 'none',
        marginRight: '10px',
        outline: 0,
        visibility: 'hidden',
      },
      span: {
        before: {
          border: 'none',
          borderRadius: '2px',
          content: '',
          cursor: 'pointer',
          height: '16px',
          left: 0,
          position: 'absolute',
          top: 0,
          transition: 'background 0.3s',
          width: '16px',
        },
        after: {
          borderBottom: `2px solid ${colors.white}`,
          content: '',
          cursor: 'pointer',
          height: '4px',
          position: 'absolute',
          left: 3,
          top: '3px',
          width: '10px',
        },
      },
    },
    checked: {
      checkbox: {
        border: 'none',
        marginRight: '10px',
        outline: 0,
        visibility: 'hidden',
      },
      span: {
        before: {
          border: 'none',
          borderRadius: '2px',
          content: '',
          cursor: 'pointer',
          height: '16px',
          left: 0,
          position: 'absolute',
          top: 0,
          transition: 'background 0.3s',
          width: '16px',
        },
        after: {
          borderBottom: `2px solid ${colors.white}`,
          borderLeft: `2px solid ${colors.white}`,
          borderTop: 'none',
          borderRight: 'none',
          content: '',
          cursor: 'pointer',
          height: '4px',
          left: '3px',
          position: 'absolute',
          top: '4px',
          transform: 'rotate(-45deg)',
          width: '8px',
        },
      },
    },
    unchecked: {
      checkbox: {
        marginRight: '10px',
        outline: 0,
        visibility: 'hidden',
      },
      span: {
        before: {
          background: colors.white,
          border: '2px solid #444',
          borderRadius: '2px',
          content: '',
          cursor: 'pointer',
          height: '12px',
          left: 0,
          position: 'absolute',
          top: 0,
          transition: 'background 0.3s',
          width: '12px',
        },
        after: {
          content: '',
          left: 0,
          position: 'absolute',
          top: 0,
        },
      },
    },
  },
  enabled: {
    indeterminate: {
      checkbox: {
        background: colors.blue.normal,
      },
      span: {
        before: {
          background: colors.blue.normal,
        },
      },
    },
    unchecked: {
      span: {
        before: {
          border: `2px solid ${colors.gray.normal}`,
        },
      },
    },
    checked: {
      checkbox: {
        background: colors.blue.normal,
      },
      span: {
        before: {
          background: colors.blue.normal,
        },
      },
    },
  },
  disabled: {
    indeterminate: {
      checkbox: {
        background: colors.gray.light,
      },
      span: {
        before: {
          background: colors.gray.light,
        },
      },
    },
    unchecked: {
      span: {
        before: {
          border: `2px solid ${colors.gray.light}`,
        },
      },
    },
    checked: {
      checkbox: {
        background: colors.gray.light,
      },
      span: {
        before: {
          background: colors.gray.light,
        },
      },
    },
  },
}
