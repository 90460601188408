import React from 'react'
import ButtonPrompt from 'components/button-prompt'

import styles from './styles'
import { useTranslation } from 'react-i18next'

export default function WarningPrompt(props) {
  const { t } = useTranslation()
  const { text = t('prompts.warning.resolveWarnings') } = props

  return (
    <ButtonPrompt key="buttonPrompt" text={text} style={styles} icon="alert" />
  )
}
