import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import Radium from 'radium'
import styles from './styles'

import { Block } from 'components/common'

class Checkbox extends Component {
  componentDidMount() {
    findDOMNode(this).indeterminate = this.props.checked === 'indeterminate'
  }

  componentDidUpdate() {
    findDOMNode(this).indeterminate = this.props.checked === 'indeterminate'
  }

  render() {
    const { disabled, error, onChange, readOnly, required, value } = this.props

    if (readOnly) {
      return <Block padding={10}>{value ? 'Yes' : 'No'}</Block>
    }

    const errorStyles = error ? styles.error : null

    // NOTE its useful to know the difference between readonly and
    // disabled http://stackoverflow.com/a/7730719
    const disabledStyles = disabled ? styles.disabled : null
    const baseStyles = [styles.root, errorStyles, disabledStyles]

    return (
      <input
        checked={value}
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
        required={required}
        style={baseStyles}
        type="checkbox"
      />
    )
  }
}

export default Radium(Checkbox)
