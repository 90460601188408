import Radium from 'radium'
import React, { Component } from 'react'

import { Block } from 'components/common'

import ContentEntry from './entry'
import ContentEntries from './entries'
import ContentTemplate from './template'
import Tabs from './tabs'
import TabItem from './tabs/tab-item'

const initialState = {
  contentTemplate: null,
  selectExisting: null,
  selectNew: true,
  selectedTemplate: null,
}

class ContentWizard extends Component {
  constructor(props) {
    super(props)

    this.selectedExisting = this.selectedExisting.bind(this)
    this.selectedNew = this.selectedNew.bind(this)
    this.updateWizard = this.updateWizard.bind(this)

    this.state = initialState
  }

  render() {
    const { area, areaEntries, updateAreaView } = this.props

    const {
      contentTemplate,
      selectNew,
      selectExisting,
      selectedTemplate,
    } = this.state

    const entity =
      contentTemplate && contentTemplate.entity.asMutable({ deep: true })

    return (
      <Block>
        {!selectedTemplate && (
          <Tabs>
            <TabItem active={!!selectNew} onClick={this.selectedNew}>
              New entry from template
            </TabItem>
            <TabItem active={!!selectExisting} onClick={this.selectedExisting}>
              Assign existing entries
            </TabItem>
          </Tabs>
        )}
        {selectNew && !selectedTemplate && (
          <ContentTemplate
            contentTemplate={entity}
            updateAreaView={updateAreaView}
            updateWizard={this.updateWizard}
          />
        )}
        {selectNew && selectedTemplate && (
          <ContentEntry
            area={area}
            contentTemplate={entity}
            updateAreaView={updateAreaView}
            updateWizard={this.updateWizard}
          />
        )}

        {selectExisting && (
          <ContentEntries
            area={area}
            areaEntries={areaEntries}
            updateAreaView={updateAreaView}
            updateWizard={this.updateWizard}
          />
        )}
      </Block>
    )
  }

  selectedExisting() {
    this.updateWizard({
      selectExisting: true,
      selectNew: false,
    })
  }

  selectedNew() {
    this.updateWizard({
      selectExisting: false,
      selectNew: true,
    })
  }

  updateWizard(state) {
    this.setState(state)
  }
}

export default Radium(ContentWizard)
