import React from 'react'
import { Field } from 'redux-form'

import { InputSelect, InputText } from 'components/form'
import SimpleTable, { Column } from '../../../../components/table-simple'
import { InputTypes, PluginOption } from './options.types'
import { Span } from 'components/common'

const booleanOptions = [
  { label: 'TRUE', value: true },
  { label: 'FALSE', value: false },
]

const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  color: '#666',
  fontSize: 13,
  borderBottom: '1px solid #eee',
}

interface Props {
  options: PluginOption[]
}

const PluginFormTable = ({ options }: Props): JSX.Element => {
  const getComponentType = (type: InputTypes): JSX.Element => {
    switch (type) {
      case InputTypes.Select:
        return InputSelect
      case InputTypes.Text:
        return InputText
    }
  }

  const renderColumnName = ({ cellData, rowData }) => {
    return !rowData.isSubOption ? (
      cellData
    ) : (
      <Span paddingLeft="20px">{cellData}</Span>
    )
  }

  return (
    <div style={{ height: '100%' }}>
      <SimpleTable
        data={options}
        style={{ overflow: 'auto' }}
        headerStyle={{
          color: '#666',
          fontSize: 13,
          fontWeight: 500,
          padding: '15px 0',
          borderBottom: '1px solid #ebebeb',
        }}
      >
        <Column
          dataKey="name"
          label="Name"
          cellStyle={cellStyle}
          cellRenderer={renderColumnName}
        ></Column>
        <Column
          dataKey="description"
          label="Description"
          flex={3}
          cellStyle={cellStyle}
        />
        <Column
          dataKey="fieldName"
          label="Option"
          cellStyle={{ ...cellStyle, padding: '10px 0' }}
          cellRenderer={({ cellData, rowData }) => (
            <Field
              component={getComponentType(rowData.type)}
              name={`plugins.${cellData}`}
              options={booleanOptions}
              required
              noMargin
              disabled={rowData.disabled}
            />
          )}
        />
      </SimpleTable>
    </div>
  )
}

export default PluginFormTable
