import { compose, withHandlers, withState } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'
import TextArea from 'react-textarea-autosize'

import { Block } from 'components/common'
import { typography } from 'config/theme'
import { useTranslation } from 'react-i18next'

const style = {
  backgroundColor: 'white',
  borderColor: '#eee',
  borderRadius: '4px',
  borderStyle: 'solid',
  borderWidth: '1px',
  boxSizing: 'border-box',
  color: '#333',
  display: 'block',
  fontSize: '14px',
  fontWeight: 300,
  minHeight: '48px',
  outline: 'none',
  overflow: 'hidden',
  padding: '14px',
  transition: '0.3s',
  width: '100%',
  fontFamily: typography.defaultFontFamily,
  ':placeholder': {
    color: '#eee',
  },
  ':focus': {
    borderColor: '#666',
  },
}

const formStyle = { margin: 0 }

export default compose(
  withState('value', 'setValue', ''),
  withHandlers({
    handleChange,
    handleSubmit,
  }),
  withHandlers({
    handleKeyPress,
  })
)(Composer)

function Composer({
  dataTestId = 'input-message-field',
  handleChange,
  handleKeyPress,
  placeholderText,
  value,
}) {
  const { t } = useTranslation()

  return (
    <Block>
      <form onSubmit={handleSubmit} style={formStyle}>
        <TextArea
          data-testid={dataTestId}
          style={style}
          value={value}
          placeholder={placeholderText || t('placeholder.enterMessage')}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </form>
    </Block>
  )
}

Composer.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  value: PropTypes.string,
}

function handleChange({ setValue }) {
  return eventOrValue => {
    const { target } = eventOrValue
    const value = target ? target.value : eventOrValue

    setValue(value)
  }
}

function handleKeyPress({ handleSubmit, value }) {
  return e => {
    const hasMessage = value !== ''

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if (hasMessage) handleSubmit(e)
    }
  }
}

function handleSubmit({ group, onSend, setValue, value }) {
  return e => {
    e.preventDefault()

    const payload = {
      group,
      text: value,
    }

    onSend(payload, () => setValue(''))
  }
}
