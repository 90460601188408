import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, ModalHeader } from '../../../components'
import Form from './components/form'
import Anchor from 'components/anchor'
import { Flex } from 'components/common'
import { colors } from 'config/theme'
import { TOOLTIPS } from 'config/constants'

export { SelectApplication as SelectApplicationForTest }

export default function SelectApplication() {
  const { t } = useTranslation()
  const styles = {
    display: 'block',
    position: 'relative',
    color: colors.white,
    marginTop: 35,
    textDecoration: 'underline',
  }

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <Modal>
        <ModalHeader>{t('login.header.applicationSignIn')}</ModalHeader>
        <Form />
      </Modal>
      <Anchor
        style={styles}
        tooltip={[
          t(TOOLTIPS.applicationId.titleT),
          t(TOOLTIPS.applicationId.messageT),
        ]}
      >
        {t('login.whatIsMyApplicationId')}
      </Anchor>
    </Flex>
  )
}
