import { isFunction, noop } from 'lodash'
import { pure } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { colors, typography } from 'config/theme'
import { Flex } from 'components/common'

export default pure(ListNext)

function ListNext(props) {
  const { children, key, onClick: handleOnClick, ...customStyles } = props

  const hasOnClickFunction = isFunction(handleOnClick)

  const onClick = hasOnClickFunction ? handleOnClick : noop
  const hoverBackgroundColor = hasOnClickFunction ? colors.gray.lightest : null
  const cursor = hasOnClickFunction ? 'pointer' : 'default'

  return (
    <Flex
      alignItems="center"
      borderBottom={`1px solid ${colors.gray.lighter}`}
      cursor={cursor}
      fontFamily={typography.defaultFontFamily}
      hoverBackgroundColor={hoverBackgroundColor}
      key={key}
      onClick={onClick}
      padding={10}
      {...customStyles}
    >
      {children}
    </Flex>
  )
}

ListNext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
}
