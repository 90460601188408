import PropTypes from 'prop-types'
import React from 'react'

import { Block, Flex } from 'components/common'
import Caret from 'components/caret'
import Icon from 'components/icon'
import Spinner from 'components/spinner'
import { colors, typography } from 'config/theme'

const fontFamily = typography.defaultFontFamily

export default SearchInput
export { SearchInput as SearchInputForTest }

function SearchInput(props) {
  const {
    clearSelection,
    getLabelProps,
    getInputProps,
    isLoading,
    openMenu,
    placeholder,
    selectedItem,
  } = props

  return (
    <Flex background="#FFF" height={60} lineHeight="60px" paddingLeft="20px">
      <label {...getLabelProps()} />
      <input
        data-testid="search-input-field"
        {...getInputProps({
          onClick: openMenu,
          onFocus: openMenu,
          placeholder,
          style: {
            background: 'inherit',
            border: 'none',
            boxSizing: 'border-box',
            flexGrow: 1,
            fontSize: '13px',
            fontFamily,
            outline: 'none',
            lineHeight: '59px',
            textOverflow: 'ellipsis',
          },
        })}
      />
      <Flex alignSelf="center" paddingLeft={12} paddingRight={12}>
        {!isLoading && !!selectedItem && (
          <Block
            color={colors.gray.light}
            fontSize={18}
            paddingLeft={10}
            onClick={clearSelection}
          >
            ×
          </Block>
        )}
        {isLoading && <Spinner size="small" type="dots" />}
      </Flex>
    </Flex>
  )
}

SearchInput.propTypes = {
  clearSelection: PropTypes.func.isRequired,
  getLabelProps: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  openMenu: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectedItem: PropTypes.object,
}
