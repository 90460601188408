/**
 * Messages Speakers Profile Reducer:
 * Handles storage of profile for current speaker
 */

import {
  actions,
} from '../'
import { parseState } from '../../../../helpers'

export default function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case actions.GET_PROFILE_REQUEST:
      return state.set('state', 'resolving')
    case actions.GET_PROFILE_SUCCESS:
      return state.merge({
        state: 'resolved',
        data: action.data,
      })
    case actions.GET_PROFILE_ERROR:
      return state.merge({
        state: 'resolved',
        error: action.error,
      })
    default:
      return state
  }
}


/**
 * Example State
 *
 * {
 *   speakers: {
 *     profile: {
 *        state: 'resolved',
 *        data: { ... },
 *        error: null
 *     }
 *   }
 * }
 *
 */
