import Loadable from 'react-loadable'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PageLoader from 'components/page-loader'

const Entry = Loadable({
  loader: () => import('./components/entry'),
  loading: PageLoader,
})

const List = Loadable({
  loader: () => import('./components/list'),
  loading: PageLoader,
})

export default function IssuesRoute(): Switch {
  return (
    <Switch>
      <Route
        component={Entry}
        exact
        path={['/templates/issues/entry/:id', '/templates/issues/entry']}
      />
      <Route component={List} exact path="/templates/issues" />
      <Redirect to="/404" />
    </Switch>
  )
}
