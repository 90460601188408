import { REQUEST } from '../../../middleware/request'
import { omit } from 'lodash'

export default function buildActionCreators(actions, baseUrlFn, headersFn, paramsFn) {
  return {
    aggregateRequest,
  }

  function aggregateRequest(params) {
    return (dispatch, getState) => {
      const state = getState()

      if (paramsFn) {
        params = paramsFn(state, params)
      }

      const endpoint = `${baseUrlFn(params, state)}/locations/${params.locationId}/aggregate`
      const headers = headersFn(params, state)
      const sanitizedParams = omit(params, 'applicationId', 'locationId')
      return dispatch(aggregateResource(endpoint, headers, sanitizedParams))
    }
  }

  function aggregateResource(endpoint, headers, params) {
    return {
      [REQUEST]: {
        types: [actions.AGGREGATE_REQUEST, actions.AGGREGATE_SUCCESS, actions.AGGREGATE_ERROR],
        endpoint,
        headers,
        query: params,
      },
    }
  }
}
