import moment from 'moment-timezone'

import { GetNext, Interval } from '../scheduling.types'

/**
 * Generates next occurrence interval for non repeat strategy
 */
export function* getNextNoRepeat({
  start,
  end,
  timezone,
}: GetNext): IterableIterator<Interval> {
  const nextOccurrenceStart: number = moment.tz(start, timezone).valueOf()
  const nextOccurrenceEnd: number = moment.tz(end, timezone).valueOf() - 1

  if (nextOccurrenceStart < start) {
    return
  }

  if (nextOccurrenceEnd <= nextOccurrenceStart) {
    return
  }

  const nextOccurrence: Interval = [nextOccurrenceStart, nextOccurrenceEnd]

  yield nextOccurrence
}
