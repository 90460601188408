import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import React from 'react'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'
import withState from 'recompose/withState'

import colors from 'config/theme/colors'

import Panel from '../../../panel'

import Entry from './components/entry'
import TemplateSelect from './components/template-select'
import { withTranslation } from 'react-i18next'

const LIST_ID = 'allIssues'

const templatesModule = getModule('templates')

export default compose(
  withState('error', 'setError'),
  withState('step', 'setStep', 0),
  withState('templateId', 'setTemplateId'),
  withTranslation(),
  withHandlers({ handleOnPrev, handleOnNext }),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(buildProps),
  lifecycle({ componentWillMount })
)(NewIssue)

function NewIssue(props) {
  const {
    error,
    handleOnNext,
    handleOnPrev,
    isResolving,
    onClose: handleClose,
    onFirstStep,
    onLastStep,
    setTemplateId,
    templateId,
    title,
    toggleExpand,
    toggleIcon,
    urlGeometry,
    t,
  } = props

  return (
    <Panel
      color={colors.orange}
      error={error}
      isResolving={isResolving}
      onClose={handleClose}
      resource={t('issuePanel.header.issue')}
      title={title}
      toggleExpand={toggleExpand}
      toggleIcon={toggleIcon}
    >
      {onFirstStep && (
        <TemplateSelect
          onCancel={handleClose}
          onNext={handleOnNext}
          setTemplateId={setTemplateId}
          templateId={templateId}
        />
      )}
      {onLastStep && (
        <Entry
          onCancel={handleOnPrev}
          onClose={handleClose}
          templateId={templateId}
          urlGeometry={urlGeometry}
        />
      )}
    </Panel>
  )
}

function buildProps(props) {
  const { isResolving, step, templateId, t } = props

  const onFirstStep = step === 0
  const onLastStep = step === 1

  let title = t('issuePanel.header.newIssue')

  if (onFirstStep && isResolving) {
    title = t('panel.header.loadingTemplates')
  }
  if (onFirstStep && !isResolving) {
    title = t('panel.header.selectTemplate')
  }

  return {
    onFirstStep,
    onLastStep,
    title,
  }
}

function componentWillMount() {
  const { fetchTemplates, setError } = this.props

  return fetchTemplates({ perPage: 9999, type: 'issue' })
    .then(handleResponse)
    .catch(error => setError(error))
}

function handleOnPrev(props) {
  const { step, setStep } = props

  return () => setStep(step - 1)
}

function handleOnNext(props) {
  const { step, setStep } = props

  return () => setStep(step + 1)
}

function handleResponse(response) {
  const { data, error } = response

  if (error) return Promise.reject(new Error(error))

  return data
}

function mapStateToProps(state) {
  const selectors = templatesModule.selectors(state)(LIST_ID)
  const isResolving = selectors.state === 'resolving'

  return {
    isResolving,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTemplates: params => dispatch(templatesModule.query(LIST_ID, params)),
  }
}
