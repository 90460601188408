import { Redirect, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import React from 'react'

import PageLoader from 'components/page-loader'

const Dashboard = Loadable({
  loader: () => import('./components/main'),
  loading: PageLoader,
})

export default function DashboardRoute() {
  return (
    <Switch>
      <Route component={Dashboard} exact path="/reports/dashboard" />
      <Redirect to="/404" />
    </Switch>
  )
}
