import {
  isLength,
  isRequired,
} from '../../validation'

export default {
  name: {
    label: 'Name',
    name: 'name',
    placeholder: 'Name',
    required: true,
    validate: [isRequired()],
  },
  addressStreet: {
    label: 'Street',
    name: 'address.street',
    placeholder: 'Street Address',
    validate: [isLength({
      min: 0,
      max: 40,
    })],
  },
  addressCity: {
    label: 'City',
    name: 'address.city',
    placeholder: 'City',
    validate: [],
  },
  addressState: {
    label: 'State',
    name: 'address.state',
    placeholder: 'State',
    required: true,
    validate: [isRequired()],
  },
  addressPostalCode: {
    label: 'Post Code',
    name: 'address.postalCode',
    placeholder: 'Post code',
    validate: [],
  },
  addressCountry: {
    label: 'Country',
    name: 'address.country',
    placeholder: 'Select Country',
    required: true,
    validate: [isRequired()],
  },
  timezone: {
    label: 'Timezone',
    name: 'timezone',
    placeholder: 'Select Timezone',
    required: true,
    validate: [isRequired()],
  },
}
