import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getModule, validation } from '@lighthouse/sdk'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Field, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form'

import { get } from 'lodash'

import colors from 'config/theme/colors'

import { Sync } from 'components/controls'

import { BasicForm, InputText } from 'components/form'

import {
  getErrorCount,
  handleFormError,
  handleSaveResponse,
} from 'components/form/helpers'

import { Block } from 'components/common'

import Alert from 'components/alert'
import ButtonGroup from 'components/button-group'
import i18next from 'i18next'

const areas = getModule('areas')
const contentEntries = getModule('content', 'entries')

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

class SyncContentEntryForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  }

  render() {
    const {
      children,
      dirty,
      entryId,
      error,
      errorCount,
      handleCancel,
      handleSubmit,
      invalid,
      pristine,
      readOnly,
      reset,
      showErrorOnUnTouched,
      small,
      submitFailed,
      submitting,
    } = this.props

    const controls = (
      <Sync
        dirty={dirty}
        errorCount={errorCount}
        handleCancel={handleCancel}
        id={entryId}
        invalid={invalid}
        pristine={pristine}
        submitting={submitting}
        reset={reset}
      />
    )

    return (
      <Block>
        <BasicForm onSubmit={handleSubmit(this.saveForm.bind(this))} noValidate>
          <Block
            bottom={61}
            fontSize={12}
            overflow="auto"
            paddingTop={20}
            paddingRight={15}
            paddingBottom={20}
            paddingLeft={15}
            position="absolute"
            top={156}
            width={420}
          >
            {error && submitFailed && <Alert type="error" messages={[error]} />}
            <Field
              required
              component={InputText}
              name="name"
              label="Entry Name"
              readOnly={readOnly}
              showErrorOnUnTouched={showErrorOnUnTouched}
              small={small}
              validate={[isRequired]}
            />
            {children}
          </Block>
          <Block
            borderTop={`1px solid ${colors.gray.lightest}`}
            bottom={0}
            height={60}
            overflow="hidden"
            paddingTop={0}
            paddingRight={15}
            paddingBottom={0}
            paddingLeft={15}
            position="absolute"
            width={420}
          >
            <ButtonGroup align="left">{controls}</ButtonGroup>
          </Block>
        </BasicForm>
      </Block>
    )
  }

  saveForm(formValues) {
    const {
      entryId,
      saveEntry,
      templateId,
      updateAreaView,
      userId,
    } = this.props

    const entryPayload = {
      entry: formValues,
      name: formValues.name,
      template: templateId,
      user: userId,
    }

    updateAreaView({ isLoading: true })

    return saveEntry(entryId, entryPayload)
      .then(handleSaveResponse('entry'))
      .catch(handleFormError)
      .finally(() =>
        updateAreaView({
          isLoading: false,
          isSyncEntryMode: false,
        })
      )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm()
)(SyncContentEntryForm)

function mapStateToProps(state, props) {
  const { form } = props

  const errors = getFormSyncErrors(form)(state) || {}
  const errorCount = getErrorCount(errors)
  const values = getFormValues(form)(state) || {}
  const userId = get(state, 'user.data._id')

  return {
    errorCount,
    name: values.name,
    userId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveArea: (id, payload) => dispatch(areas.save({}, payload, id)),
    saveEntry: (id, payload) => dispatch(contentEntries.save({}, payload, id)),
  }
}
