import { ceil } from 'lodash'
import { compose, pure, withHandlers, withState } from 'recompose'
import onClickOutside from 'react-onclickoutside'
import PropTypes from 'prop-types'
import React from 'react'
import Measure from 'react-measure'

import { Flex, Block } from 'components/common'
import { colors } from 'config/theme'

import Dropdown from '../dropdown'
import Icon from 'components/icon'

export default compose(
  withState('isOpen', 'setOpen', false),
  withHandlers({
    handleClickOutside,
  }),
  onClickOutside,
  pure
)(MenuItem)

function MenuItem(props) {
  const {
    bold,
    center,
    content,
    disableSelectedList,
    dropdownItems,
    enableSearch,
    icon,
    isOpen,
    setOpen,
    text,
    ...styleProps
  } = props

  const customStyles = {
    ...(bold && { fontWeight: 500 }),
    ...(center && { justifyContent: 'center' }),
    ...(dropdownItems && { cursor: 'pointer' }),
    ...(isOpen && { backgroundColor: colors.gray.lighter }),
  }

  return (
    <Measure>
      {({ width }) => {
        // Required to right align dropdown
        const dropdownMarginLeft = ceil(width) - 182
        return (
          <Block
            borderLeftColor={colors.gray.lighter}
            borderLeftStyle="solid"
            borderLeftWidth={1}
            color={colors.gray.normal}
            fontSize={13}
            onClick={() => setOpen(!isOpen)}
            transition={2.5}
            {...styleProps}
          >
            <Flex
              alignItems="center"
              height={48}
              paddingLeft={16}
              paddingRight={16}
              {...customStyles}
            >
              {icon && <Icon fontSize="18px" name={icon} />}
              {content && (
                <Block
                  marginLeft={icon ? 6 : 0}
                  maxWidth={600}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {content}
                </Block>
              )}
            </Flex>
            {dropdownItems && isOpen && (
              <Dropdown
                enableSearch={enableSearch}
                items={dropdownItems}
                marginLeft={dropdownMarginLeft}
              />
            )}
          </Block>
        )
      }}
    </Measure>
  )
}

MenuItem.propTypes = {
  bold: PropTypes.bool,
  center: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableSelectedList: PropTypes.bool,
  dropdownItems: PropTypes.array,
  enableSearch: PropTypes.bool,
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

function handleClickOutside({ setOpen }) {
  return () => setOpen(false)
}
