import React from 'react'
import Entry from './entry'

export default function SyncWizard(props) {
  const { area, entryId, updateAreaView } = props

  return (
    <div>
      <Entry area={area} entryId={entryId} updateAreaView={updateAreaView} />
    </div>
  )
}
