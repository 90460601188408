import Pubnub from 'pubnub'
import { noop } from 'lodash'

const services = {
  pubnub: PubnubInterface,
}

export function connection(serviceId, config) {
  const ServiceInterface = services[serviceId]

  if (!ServiceInterface) {
    throw new Error('Invalid socket service supplied')
  }

  return new ServiceInterface(config)
}

// TODO abstract pubnub logic to own files if necessary

export function PubnubInterface(config) {
  const {
    subscribe_key,
  } = config

  if (!subscribe_key) {
    console.error('pubnub:error', config)
    throw new Error('Missing pubnub configuration')
  }

  this.connection = new Pubnub(config)
  /* this.createConnection(config)*/
}

// Wrap a method around pubnub so we can easily stub it out
PubnubInterface.prototype.createConnection = function createConnection(config) {
  this.connection = new Pubnub(config)
}

PubnubInterface.prototype.subscribe = function subscribe(opts, callback = noop) {
  const {
    channel,
  } = opts

  this.connection.subscribe({
    channel,
    message: function pubnubMessage(message) {
      return callback(null, message)
    },
    error: function pubnubError(error) {
      console.error('pubnub:subscribe:error', {
        error,
        opts,
      })
      return callback(error)
    },
  })
}

PubnubInterface.prototype.unsubscribe = function unsubscribe(channel) {
  this.connection.unsubscribe({ channel })
}
