import { pure } from 'recompose'
import PropTypes from 'prop-types'
import React from 'react'

import { Block, Flex, Span } from 'components/common'
import { colors } from 'config/theme'
import DatetimeTimezone from 'components/datetime-timezone'

export default pure(DateSeparator)

function DateSeparator({ date }) {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      height={0}
      marginBottom={16}
      marginTop={16}
      width="100%"
      zIndex={-1}
    >
      <Block
        borderBottomColor={colors.gray.lighter}
        borderBottomStyle="solid"
        borderBottomWidth={1}
        display="inline-block"
        width="100%"
      />
      <Block marginTop={-14}>
        <Span
          backgroundColor={colors.white}
          color={colors.gray.light}
          fontSize={12}
          padding={6}
        >
          <DatetimeTimezone datetime={date} format="DD/MM/YYYY" />
        </Span>
      </Block>
    </Flex>
  )
}

DateSeparator.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
}
