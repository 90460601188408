import { capitalize, chain, get } from 'lodash'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'

const userApplicationsModule = getModule('userApplications')
const DEFAULT_AUTH = { label: 'Lighthouse.io', value: 'password' }

export default connect(mapStateToProps)

function mapStateToProps(state, props) {
  const currentApp = userApplicationsModule.getCurrentApplication(state)
  const authStrategies = chain(currentApp)
    .get('application.authenticationStrategies', [])
    .map(value => ({ label: capitalize(value), value }))
    .compact()
    .concat(DEFAULT_AUTH)
    .sortBy('label')
    .value()

  return {
    authStrategies,
  }
}
