import React from 'react'
import Base from '../base'

export default function Span(props) {
  const { children, className, onClick, title, ...componentProps } = props

  return (
    <Base
      className={className}
      display="inline-block"
      onClick={onClick}
      title={title}
      {...componentProps}
    >
      {children}
    </Base>
  )
}
