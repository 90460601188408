import colors from 'config/theme/colors'

export default {
  button: {
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: colors.gray.lighter,
    },
    height: 48,
    marginLeft: 0,
    marginRight: 0,
    flexShrink: 0,
  },
  icon: {
    fontSize: '20px',
    marginRight: 10,
  },
}
