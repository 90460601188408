import { request as sdkRequest } from '@lighthouse/sdk'
import { get } from 'lodash'
import { connect, useSelector } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { GetAccessToken, useAuth, withAuth } from './useAuth'

interface ReduxState {
  app: {
    applicationId: string
    region: string
    endpoints: Record<string, string>
  }
  authentication: {
    accessToken: string
  }
}

type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

interface FetchOptions {
  body?: string
  headers?: Record<string, string>
  method: FetchMethod
}

type RequestOptions = PostAndPutOptions | GetAndDeleteOptions

interface PostAndPutOptions {
  body?: Record<string, any>
  headers?: Record<string, string>
  method: 'POST' | 'PUT'
}

interface GetAndDeleteOptions {
  headers?: Record<string, string>
  method: 'GET' | 'DELETE'
}

interface RequestConfig {
  scopeByApplication?: boolean
}

interface Response<T> {
  json: T
  totalCount: number | null
  lastKey: string | null
}

export type Request = <T>(
  url: string,
  options?: RequestOptions,
  config?: RequestConfig
) => Promise<Response<T>>

interface MakeRequestConfig extends RequestConfig {
  applicationId: string
  endpoints: Record<string, string>
  legacyAccessToken: string
  region: string
  getAccessToken: GetAccessToken
}

type MakeRequest = <T>(
  url: string,
  options: RequestOptions,
  config: MakeRequestConfig
) => Promise<T>

interface RequestObject {
  request: Request
}

// TODO we could revisit this and implement state for loading, error etc

export function useRequest(): RequestObject {
  const { getAccessToken } = useAuth()
  const { applicationId, endpoints, region } = useSelector<
    ReduxState,
    ReduxState['app']
  >(state => state.app)
  const legacyAccessToken = useSelector<ReduxState, string>(state =>
    get(state, 'authentication.accessToken')
  )

  const request: Request = async (path, options = {}, config = {}) => {
    return await makeRequest(path, options, {
      applicationId,
      endpoints,
      getAccessToken,
      legacyAccessToken,
      region,
      ...config,
    })
  }

  return { request }
}

export const withRequest = compose(
  connect(mapStateToProps),
  withAuth,
  withHandlers({
    request: (props: MakeRequestConfig): Request => async (
      path,
      options = {}
    ) => {
      const {
        applicationId,
        endpoints,
        getAccessToken,
        legacyAccessToken,
        region,
      } = props

      return await makeRequest(path, options, {
        applicationId,
        endpoints,
        getAccessToken,
        legacyAccessToken,
        region,
      })
    },
  })
)

function mapStateToProps(state: ReduxState) {
  const { applicationId, endpoints, region } = state.app

  const legacyAccessToken = get(state, 'authentication.accessToken')

  return {
    legacyAccessToken,
    applicationId,
    endpoints,
    region,
  }
}

const makeRequest: MakeRequest = async (path, options, config) => {
  const {
    applicationId,
    endpoints,
    getAccessToken,
    legacyAccessToken,
    region,
    scopeByApplication = true,
  } = config
  let baseUrl = endpoints[region]

  if (scopeByApplication) {
    baseUrl = `${baseUrl}/applications/${applicationId}`
  }

  const requestUrl = `${baseUrl}${path}`

  const requestHeaders = options.headers || {}
  const requestOptions: FetchOptions = {
    headers: {
      'lio-application-id': applicationId,
      ...requestHeaders,
    },
    method: options.method || 'GET',
  }

  if (options.body) {
    requestOptions.body = JSON.stringify(options.body)
  }
  console.debug('useRequest: making request...', {
    requestUrl,
    requestOptions,
  })

  const requestHelpers = {
    requiresAuthorization: true,
    getAuthorization: async () => {
      const bearerAccessToken = await getAccessToken()

      if (bearerAccessToken) {
        return `bearer ${bearerAccessToken}`
      }

      return legacyAccessToken
    },
  }

  const response = await sdkRequest(requestUrl, requestOptions, requestHelpers)

  console.debug('useRequest: request successful!')

  return response
}
