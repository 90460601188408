import React from 'react'

import colors from 'config/theme/colors'
import { ListItem } from 'components/common'
import Icon from 'components/icon'
import Spinner from 'components/spinner'
import { LockIcon } from '../../../../../templates/components/LockIcon/LockIcon'

export default function TemplateListItem(props) {
  const {
    active,
    isLoading = false,
    hasError = false,
    item,
    onClick,
    locked = false,
    display,
    alignItems,
  } = props

  const { title, name } = item

  const label = name ? name : title
  const radiumStyles = {
    ':hover': {
      backgroundColor: colors.blue.lightest,
    },
  }

  return (
    <ListItem
      onClick={onClick}
      borderBottom={`1px solid ${colors.gray.lightest}`}
      cursor="pointer"
      display={display || 'block'}
      fontSize={12}
      flex="1 100%"
      height={40}
      lineHeight="40px"
      justifyContent="space-between"
      paddingTop={0}
      paddingRight={15}
      paddingBottom={0}
      paddingLeft={15}
      radiumStyles={radiumStyles}
      overflow="hidden"
      alignItems={alignItems || 'center'}
    >
      {label}
      {active && !isLoading && hasError && (
        <Icon
          name="circle-cross"
          fontSize="22px"
          theme={{ color: colors.red.normal }}
        />
      )}
      {active && !isLoading && !hasError && <Icon float="right" name="check" />}
      {active && isLoading && <Spinner type="dots" size="small" />}
      {locked && <LockIcon />}
    </ListItem>
  )
}
