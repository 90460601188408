import {
  assign,
  memoize,
  omit,
  transform,
} from 'lodash'

import recycleState from 'redux-recycle'
import { enableBatching } from 'redux-batched-actions'
import { AUTHENTICATION_UNSET, UNAUTHENTICATE_SUCCESS } from './authentication'
import {
  defaultRecycleActions,
} from '../constants'

/**
 * Memoize the getModuleInterface function so repeated calls to the same module
 * do not result in unnecessary processing. Note we use a resolve for the cache
 * key as lodash by default only uses the first argument, whereas we expose sub
 * modules under a 2nd argument
 */
export const getModule = memoize(
  getModuleInterface,
  (...args) => args.join('-'),
)

// NOTE This is the best way I can think of to get the modules exported as
// properties of an object so they can be dynamically referenced (e.g.
// modules[moduleName]). If we can think of a more elegant way to this then that
// would be awesome
export const modules = {
  activity: require('./activity'),
  analytics: require('./analytics'),
  app: require('./app'),
  applicationUsers: require('./application-users'),
  areas: require('./areas'),
  assets: require('./assets'),
  auditEntries: require('./audit-entries'),
  audits: require('./audits'),
  authentication: require('./authentication'),
  content: require('./content'),
  dataExports: require('./data-exports'),
  events: require('./events'),
  exceptions: require('./exceptions'),
  files: require('./files'),
  geo: require('./geo'),
  graph: require('./graph'),
  issues: require('./issues'),
  jobActivities: require('./job-activities'),
  jobs: require('./jobs'),
  locations: require('./locations'),
  logs: require('./logs'),
  loops: require('./loops'),
  loopServicing: require('./loop-servicing'),
  maps: require('./maps'),
  messages: require('./messages'),
  "reports-locations": require('./reports-locations'),
  roles: require('./roles'),
  schedules: require('./schedules'),
  serviceHours: require('./service-hours'),
  shifts: require('./shifts'),
  signals: require('./signals'),
  tags: require('./tags'),
  taskEntries: require('./task-entries'),
  tasks: require('./tasks'),
  templates: require('./templates'),
  user: require('./user'),
  userApplications: require('./user-applications'),
  version: require('./version'),
  visits: require('./visits'),
  winteam: require('./winteam'),
  zones: require('./zones'),
}

export default {
  app: modules.app.reducer,
  activity: recycleState(modules.activity.reducer, defaultRecycleActions),
  analytics: recycleState(modules.analytics.reducer, defaultRecycleActions),
  applicationUsers: recycleState(modules.applicationUsers.reducer, defaultRecycleActions),
  areas: recycleState(modules.areas.reducer, defaultRecycleActions),
  assets: recycleState(modules.assets.reducer, defaultRecycleActions),
  auditEntries: recycleState(modules.auditEntries.reducer, defaultRecycleActions),
  audits: recycleState(modules.audits.reducer, defaultRecycleActions),
  // authentication state is not recycled when switching applications,
  // so we don't user the default recycle actions
  authentication: modules.authentication.reducer,
  content: recycleState(modules.content.reducer, defaultRecycleActions),
  graph: recycleState(modules.graph.reducer, defaultRecycleActions),
  dataExports: recycleState(modules.dataExports.reducer, defaultRecycleActions),
  events: recycleState(modules.events.reducer, defaultRecycleActions),
  exceptions: recycleState(modules.exceptions.reducer, defaultRecycleActions),
  files: recycleState(modules.files.reducer, defaultRecycleActions),
  geo: recycleState(enableBatching(modules.geo.reducer), defaultRecycleActions),
  issues: recycleState(modules.issues.reducer, defaultRecycleActions),
  jobActivities: recycleState(modules.jobActivities.reducer, defaultRecycleActions),
  jobs: recycleState(modules.jobs.reducer, defaultRecycleActions),
  locations: recycleState(modules.locations.reducer, defaultRecycleActions),
  logs: modules.logs.reducer,
  loopServicing: recycleState(modules.loopServicing.reducer, defaultRecycleActions),
  loops: recycleState(modules.loops.reducer, defaultRecycleActions),
  maps: recycleState(modules.maps.reducer, defaultRecycleActions),
  messages: recycleState(modules.messages.reducer, defaultRecycleActions),
  'reports-locations': recycleState(modules['reports-locations'].reducer, defaultRecycleActions),
  roles: recycleState(modules.roles.reducer, defaultRecycleActions),
  schedules: recycleState(modules.schedules.reducer, defaultRecycleActions),
  serviceHours: recycleState(modules.serviceHours.reducer, defaultRecycleActions),
  shifts: recycleState(modules.shifts.reducer, defaultRecycleActions),
  signals: recycleState(modules.signals.reducer, defaultRecycleActions),
  tags: recycleState(modules.tags.reducer, defaultRecycleActions),
  taskEntries: recycleState(modules.taskEntries.reducer, defaultRecycleActions),
  tasks: recycleState(modules.tasks.reducer, defaultRecycleActions),
  templates: recycleState(modules.templates.reducer, defaultRecycleActions),
  user: recycleState(modules.user.reducer, [AUTHENTICATION_UNSET, UNAUTHENTICATE_SUCCESS]),
  // We don't want the user application reducer to recycle on set
  // current like the others, because that will just reset the state
  // every time we call it an make it useless
  userApplications: recycleState(modules.userApplications.reducer, [AUTHENTICATION_UNSET, UNAUTHENTICATE_SUCCESS]),
  version: modules.version.reducer,
  visits: recycleState(modules.visits.reducer, defaultRecycleActions),
  winteam: recycleState(modules.winteam.reducer, defaultRecycleActions),
  zones: recycleState(modules.zones.reducer, defaultRecycleActions),
}

/*
 * Example usage of `module()`
 * ---------------------------
 * const location = module('location')
 *
 * // actions
 * location.crud.query(listId)
 * location.crud.save(payload)
 * location.crud.find('1')
 *
 * // selctors
 * const selectors = location.selectors(state)
 * const defaultSelectors = selectors()
 * defaultSelectors.list({ foo: 'bar' })
 * const allSelectors = selectors('all')
 * allSelectors.list({ foo2: 'bar2 })
 *
 * const map = module('map')
 * map.setLocation()
 */

function getModuleInterface(moduleName, subModule) {
  const moduleInterface = subModule ?
                          modules[moduleName][subModule] :
                          modules[moduleName]

  if (!moduleInterface) {
    throw new Error(`Could not create unknown module ${moduleName} ${subModule || ''}`)
  }

  const isCrudModule = !!moduleInterface.actionCreators

  // for non-crud modules just return the module as is
  // TODO don't include unnecessary exports with the module (e.g. ACTIONS, reducer)
  if (!isCrudModule) {
    return moduleInterface
  }

  const {
    actionCreators,
    selectors,
  } = moduleInterface

  const customSelectors = omit(selectors, 'cache', 'list', 'state', 'current', 'filter')
  const sanitizedModule = omit(moduleInterface, 'actionCreators', 'selectors')

  // Expose the module in a use structure
  return (assign({}, sanitizedModule, {
    ...actionCreators,
    // NOTE that the selectors function is curry-style to allow for flexiblity
    // for working with data. E.g you will always be using the same state, so that
    // option is passed first. You can then call the return function multiple times
    // with different listIds to return wrapped selectors for those lists
    selectors: state => (listId) => {
      const wrappedCustomSelectors = transform(customSelectors, (accum, selectorFn, selectorKey) => {
        accum[selectorKey] = selectorFn(state)
      }, {})
      return {
        cache: () => selectors.cache(state),
        list: opts => selectors.list(state)(listId, opts),
        state: selectors.state(state)(listId),
        current: () => selectors.current(state),
        ...wrappedCustomSelectors,
      }
    },
  }))
}
