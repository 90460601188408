import { get } from 'lodash'
import queryString from 'querystring'

interface MapEntryQuery {
  icon: string
  id: string
  lat: string
  lng: string
  resource: string
  showMarker: string
  title: string
  [key: string]: string
}

export function buildMapEntryQuery(props: MapEntryQuery): string {
  return queryString.stringify(props)
}

export function buildMapAuditEntryQuery(entity): string {
  const {
    _id: id,
    gps,
    score = {
      percentageResult: 0,
    },
    targetServiceLevel = null,
    title,
  } = entity

  const lat = get(gps, 'geometry.coordinates.1')
  const lng = get(gps, 'geometry.coordinates.0')
  const icon = 'auditentries'
  const resource = 'auditentries'

  const auditQueryString = buildMapEntryQuery({
    icon,
    id,
    lat,
    lng,
    resource,
    score: `${score.percentageResult}%`,
    showMarker: 'true',
    targetServiceLevel,
    title,
  })

  return auditQueryString
}
