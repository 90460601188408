import { debounce } from 'lodash'
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'
import { useMount } from 'react-use'
import Oidc, { UserManager, UserManagerSettings } from '@lighthouse/oidc-client'
// import emitter from 'utils/emitter'
import * as logger from 'utils/logger'

if (process.env.NODE_ENV === 'development') {
  Oidc.Log.logger = console
}

const networkErrorRegex = /(connection error|network error)/i

const LOCAL_STORAGE_NAMESPACE = 'lio-auth'
export const CREDENTIALS_ID = 'oidc-credentials'
export const CONFIG_ID = 'oidc-config'

export interface OidcCredentials {
  accessToken: string
  accessTokenExpirationDate: string
  refreshToken: string
  idToken: string
}

export interface OidcConfig {
  adapter?: string
  audience?: string
  authorizeUrl: string
  clientId: string
  clientSecret?: string
  cognitoLogoutUrl?: string
  discoveryUri?: string
  endSessionUrl?: string
  issuer: string
  jwksUrl?: string
  loginLabel: string
  revokeUrl?: string
  scopes?: string[]
  tokenUrl?: string
  userInfoUrl?: string
}

type CredentialName = 'oidc-credentials' | 'oidc-config'

type CredentialType<T> = T extends 'oidc-credentials'
  ? OidcCredentials
  : T extends 'oidc-config'
  ? OidcConfig
  : never

export type Authorize = (authConfig?: OidcConfig) => Promise<{} | void>
export type AuthorizeCallback = () => Promise<Oidc.User | void>
export type ConfigureAuthClient = (authConfig?: OidcConfig) => Oidc.UserManager
export type GetAccessToken = () => Promise<string | void>
export type GetCredentials = <T extends CredentialName>(
  credentialId: T
) => Promise<CredentialType<T> | void>

export type RefreshAccessToken = (refreshToken: string) => Promise<Oidc.User>
export type RemoveCredentials = (credentialsId: string) => Promise<void>
export type SetCredentials = (
  credentialId: string,
  credentials: Record<string, any>
) => Promise<void>
type Revoke = () => Promise<void>
type Logout = () => Promise<void | { redirected?: boolean }>

interface AuthContextValue {
  authorize: Authorize
  authorizeCallback: AuthorizeCallback
  getAccessToken: GetAccessToken
  getCredentials: GetCredentials
  logout: Logout
  revoke: Revoke
  removeCredentials: RemoveCredentials
  setCredentials: SetCredentials
}

export const AuthContext = createContext({
  authorize: () => Promise.resolve(),
  authorizeCallback: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(),
  getCredentials: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  removeCredentials: () => Promise.resolve(),
  revoke: () => Promise.resolve(),
  setCredentials: () => Promise.resolve(),
} as AuthContextValue)

// NOTE Create a debounced version of silentRefresh. This allows us to cache the
// result by refresh token when multiple requests are fired concurrently, e.g
// when loading a full page with a lot of requests
const debouncedSilentRefresh = debounce(
  async (refreshToken: string, auth: Oidc.UserManager) => {
    console.debug('AuthProvider: (debounced) refreshAccessToken...')

    const result = await auth.signinSilent(refreshToken)

    console.debug('AuthProvider: (debounced) refreshAccessToken success!')

    return result
  },
  10000,
  { leading: true, trailing: false }
)

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  // NOTE auth should be a mutable value so consumers don't have to rely on
  // scope of re-renders (like they would if it was state)
  const auth = useRef<Oidc.UserManager>()

  const [initialising, setInitialising] = useState(true)

  useMount(() => {
    ;(async () => {
      console.debug('AuthProvider: onMount')
      const config = await getCredentials(CONFIG_ID)

      if (!config) {
        setInitialising(false)
        return
      }

      configureAuthClient(config)
      setInitialising(false)
    })()
  })

  const configureAuthClient: ConfigureAuthClient = (config: OidcConfig) => {
    console.debug('AuthProvider: configureAuthClient')
    const userManagerSettings = getUserManagerSettings(config)
    const authClient = new UserManager(userManagerSettings)
    auth.current = authClient
    console.debug('AuthProvider: authClient configured!', { authClient })
    return authClient
  }

  const authorize: Authorize = useCallback(async () => {
    try {
      const config = await getCredentials(CONFIG_ID)

      if (!config) {
        logger.error('AuthorizeError', {
          description: 'Could not determine config for authorize',
        })
        throw new Error('AuthorizeError')
      }

      // clear any existing state
      if (auth.current) {
        console.debug('AuthProvider: Clearing existing auth client state...')
        await auth.current.removeUser()
        console.debug('AuthProvider: Auth client state cleared!')
      }

      // Setup the authClient
      const authClient = configureAuthClient(config)

      console.debug('AuthProvider: authorize...')

      await authClient.signinRedirect()
    } catch (err) {
      logger.error('AuthProviderAuthorizeError', {
        err: err.message,
        stack: err.stack,
      })
    }
  })

  const authorizeCallback: AuthorizeCallback = useCallback(async () => {
    console.debug('AuthProvider: authorizeCallback')

    if (!initialising && !auth) {
      throw new Error('AuthClientException')
    }

    try {
      const user = await auth.current.signinRedirectCallback()

      await setCredentials(CREDENTIALS_ID, {
        accessToken: user.access_token,
        accessTokenExpirationDate: user.expires_at * 1000,
        idToken: user.id_token,
        refreshToken: user.refresh_token,
      })

      return user
    } catch (err) {
      logger.error('AuthProviderAuthorizeCallbackError', {
        err: err.message,
        stack: err.stack,
      })

      throw err
    }
  })

  // NOTE Will return the access token or, if expired, attempt to renew it and return
  // the updated token
  const getAccessToken: GetAccessToken = useCallback(async () => {
    console.debug('AuthProvider: getAccessToken')

    try {
      const credentials = await getCredentials(CREDENTIALS_ID)

      if (!credentials) return

      const {
        accessToken,
        accessTokenExpirationDate,
        refreshToken,
      } = credentials

      const now = new Date()
      const exp = new Date(accessTokenExpirationDate)

      const expiryDuration = Math.round((+exp - +now) / 1000)
      const expiryLog =
        expiryDuration < 0
          ? `expired ${expiryDuration * -1} seconds ago`
          : `expires in ${expiryDuration} seconds`

      console.debug(`AuthProvider: accessToken ${expiryLog}`)

      if (now < exp) {
        return accessToken
      }

      console.debug('AuthProvider: accessToken expired, attempt refresh...', {
        exp: new Date(exp),
      })

      if (!auth) {
        console.debug('AuthProvider: skipping refresh, client cont configured')
        return
      }

      const result = await refreshAccessToken(refreshToken)

      console.debug('AuthProvider: refreshed access token!')

      const {
        access_token: nextAccessToken,
        expires_at: nextTokenExpirationDate,
        id_token: nextIdToken,
        refresh_token: nextRefreshToken,
      } = result

      await setCredentials(CREDENTIALS_ID, {
        accessToken: nextAccessToken,
        accessTokenExpirationDate: nextTokenExpirationDate * 1000,
        idToken: nextIdToken,
        refreshToken: nextRefreshToken,
      })

      console.debug('AuthProvider: new accessToken retrieved')

      return nextAccessToken
    } catch (err) {
      console.error(err)
      logger.error('AuthGetAccessTokenError', {
        err: err.message,
        stack: err.stack,
      })

      throw err
    }
  })

  const refreshAccessToken: RefreshAccessToken = async (
    refreshToken: string
  ) => {
    try {
      const authConfig = await getCredentials(CONFIG_ID)

      if (!authConfig) {
        // NOTE Tokens cannot be refreshed without config, so clear credentials and force user to re-authenticate
        console.debug(
          'AuthProvider: No config available to refresh, clearing credentials...'
        )
        await removeCredentials(CREDENTIALS_ID)
        throw new Error('RefreshAccessTokenError - config not found')
      }

      const result = await debouncedSilentRefresh(refreshToken, auth.current)

      console.debug('AuthProvider: refreshAccessToken success!')

      return result
    } catch (err) {
      logger.error('AuthRefreshAccessTokenError', {
        err: err.message,
        stack: err.stack,
      })

      throw err
    }
  }

  const getCredentials: GetCredentials = async credentialId => {
    try {
      const key = `${LOCAL_STORAGE_NAMESPACE}:${credentialId}`
      const credentials = localStorage.getItem(key)

      if (!credentials || credentials === 'undefined') {
        console.debug('AuthProvider: No credentials found in storage')
        return
      }

      const credentialsObj = JSON.parse(credentials)

      return Promise.resolve(credentialsObj)
    } catch (err) {
      logger.error('AuthGetCredentialsError', {
        err: err.messsage,
        stack: err.stack,
      })

      throw err
    }
  }

  const setCredentials: SetCredentials = async (
    credentialId: string,
    credentials: Record<string, any>
  ): Promise<void> => {
    try {
      console.debug('AuthProvider: setCredentials...', credentialId)
      const key = `${LOCAL_STORAGE_NAMESPACE}:${credentialId}`
      const credentialsJson = JSON.stringify(credentials)

      localStorage.setItem(key, credentialsJson)
      console.debug('AuthProvider: setCredentials success!')
    } catch (err) {
      logger.error('AuthSetCredentialsError', {
        err: err.messsage,
        stack: err.stack,
      })

      throw err
    }
  }

  const removeCredentials: RemoveCredentials = async credentialId => {
    try {
      const key = `${LOCAL_STORAGE_NAMESPACE}:${credentialId}`
      localStorage.removeItem(key)
    } catch (err) {
      logger.error('AuthRemoveCredentialsError', {
        err: err.message,
        stack: err.stack,
      })
      throw err
    }
  }

  const revoke: Revoke = useCallback(async () => {
    try {
      console.debug('AuthProvider: revoke...')

      if (auth.current) {
        console.debug('AuthProvider: config found, revoking...')

        // NOTE Catch any errors and do not re-throw for revoke request. If
        // anything goes wrong here it's not worth holding up the user
        try {
          console.debug('AuthProvider: revokeAccessToken()...')
          await auth.current.revokeAccessToken()

          console.debug('AuthProvider: remove user...')
          await auth.current.removeUser()

          console.debug('AuthProvider: clear state...')
          await auth.current.clearStaleState()
        } catch (err) {
          logger.error('OidcRevokeError', {
            err: err.message,
            stack: err.stack,
          })
        }
      }

      console.debug('AuthProvider: removing credentials...')

      // clear credentials
      await removeCredentials(CREDENTIALS_ID)
    } catch (err) {
      logger.error('AuthRevokeError', {
        err: err.messsage,
        stack: err.stack,
      })

      throw err
    }
  })

  const logout: Logout = useCallback(async () => {
    try {
      console.debug('AuthProvider: logout...')

      await revoke()

      const config = await getCredentials(CONFIG_ID)

      if (!config) {
        console.warn('AuthProvider: config not found to process logout')
        return
      }

      // NOTE It's not standard SSO behaviour to log the user out of the OP when
      // they logout of the RP. But on Lighthouse, it's common for users to
      // share devices, especially on mobile. So we do call the end session
      // endpoint if it's specified in the config
      if (config.endSessionUrl) {
        // NOTE endSessionUrl is an endpoint that conforms with the oidc spec
        console.debug('AuthProvider: logging out with end session url')

        await auth.current.signoutRedirect()

        // NOTE inform the consuming code that we redirected
        return {
          redirected: true,
        }
      } else if (config.cognitoLogoutUrl) {
        // NOTE cognito doesn't follow the oidc spec for logouts so we have to handle it manually
        console.debug('AuthProvider: logging out with congito url')

        // NOTE the logoutUri is what cognito redirects back to after completing logout
        const logoutUri = `${window.location.origin}/login`
        const fullCognitoLogoutUrl = `${config.cognitoLogoutUrl}?client_id=${config.clientId}&logout_uri=${logoutUri}`

        window.location.href = fullCognitoLogoutUrl

        return {
          redirected: true,
        }
      }
    } catch (err) {
      logger.error('LogoutError', {
        err: err.messsage,
        stack: err.stack,
      })

      throw err
    }
  })

  const value = {
    authorize,
    authorizeCallback,
    getAccessToken,
    getCredentials,
    logout,
    removeCredentials,
    revoke,
    setCredentials,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider.')
  }

  return context
}

// NOTE For use only when necessary with recompose code. Prefer refactoring with
// hooks and useAuth where possible
export const withAuth = (Component: FC) => {
  return (props: any) => {
    return (
      <AuthContext.Consumer>
        {value => {
          return <Component {...props} {...value} />
        }}
      </AuthContext.Consumer>
    )
  }
}

function getUserManagerSettings(config: OidcConfig): UserManagerSettings {
  const redirectUri = window.location.origin + '/login/callback'

  // NOTE custom authorizeUrl indicates to use metadata. Might need to be more
  // explicity in future
  const metadata = config.authorizeUrl
    ? {
        issuer: config.issuer,
        authorization_endpoint: config.authorizeUrl,
        userinfo_endpoint: config.userInfoUrl,
        revocation_endpoint: config.revokeUrl,
        end_session_endpoint: config.endSessionUrl,
        token_endpoint: config.tokenUrl,
      }
    : undefined

  const settings: UserManagerSettings = {
    authority: config.issuer,
    loadUserInfo: false,
    client_id: config.clientId,
    redirect_uri: redirectUri,
    // silent_redirect_uri: redirectUri,
    response_type: 'code',
    metadata,
    extraQueryParams: {
      audience: 'https://api.lighthouse.io',
    },
    scope: config.scopes.join(' ') || 'openid',
  }

  if (!!config.clientSecret) {
    console.debug('AuthProvider: Configured to use client secret')
    settings.client_authentication = 'client_secret_basic'
    settings.client_secret = config.clientSecret
  }

  return settings
}
