import Radium from 'radium'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'

import { chain, concat, filter, includes } from 'lodash'

import colors from 'config/theme/colors'
import { Block } from 'components/common'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'

import ControlWrapper from '../../../../../components/control-wrapper'
import FieldWrapper from '../../../../../components/field-wrapper'
import ListItem from './components/list-item'
import { withTranslation } from 'react-i18next'

const areas = getModule('areas')
const contentEntries = getModule('content', 'entries')

class Entries extends Component {
  constructor(props) {
    super(props)

    this.onCancel = this.onCancel.bind(this)
    this.onEntrySelect = this.onEntrySelect.bind(this)
    this.saveEntries = this.saveEntries.bind(this)

    const selectedEntries = props.areaEntries || []

    this.state = {
      selectedEntries,
    }
  }

  render() {
    const { entries, t } = this.props

    const { selectedEntries } = this.state

    const entryItems = chain(entries)
      .sortBy(['entity.name'])
      .map(({ entity }, index) => (
        <ListItem
          active={includes(selectedEntries, entity._id)}
          item={entity}
          key={index}
          onClick={() => this.onEntrySelect(entity._id)}
        />
      ))
      .value()

    const isDisabled = selectedEntries.length === 0

    return (
      <Block>
        <FieldWrapper top={156}>{entryItems}</FieldWrapper>
        <ControlWrapper>
          <ButtonGroup>
            <Button onClick={this.onCancel}>{t('button.cancel')}</Button>
            <Button
              disabled={isDisabled}
              theme="positive"
              onClick={this.saveEntries}
            >
              {t('button.save')}
            </Button>
          </ButtonGroup>
        </ControlWrapper>
      </Block>
    )
  }

  onCancel() {
    this.props.updateAreaView({ isNewEntryMode: false })
  }

  onEntrySelect(id) {
    const { selectedEntries } = this.state

    if (includes(selectedEntries, id)) {
      return this.setState({
        selectedEntries: filter(selectedEntries, template => template !== id),
      })
    }

    this.setState({
      selectedEntries: concat(selectedEntries, id),
    })
  }

  saveEntries() {
    const { area, saveArea, updateAreaView } = this.props

    const { selectedEntries } = this.state

    const areaEntity = area.entity
    const id = areaEntity._id
    const entries = selectedEntries

    updateAreaView({ isLoading: true })

    return saveArea(id, { entries })
      .catch(handleError)
      .finally(() =>
        updateAreaView({
          isAddIssueTemplateMode: false,
          isLoading: false,
        })
      )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  Radium
)(Entries)

function mapStateToProps(state) {
  const entrySelectors = contentEntries.selectors(state)('all')

  return {
    entries: entrySelectors.cache(),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveArea: (id, payload) => dispatch(areas.save({}, payload, id)),
  }
}

function handleError(error) {
  const message = error.message || 'Error saving templates for area'
  console.error(message)
}
