import hooks from '../hooks'

/**
 * Creates a set of actions for a CRUD namespace
 * @param {String} namespace for namespacing actions
 */
export default (namespace) => {
  if (!namespace) {
    throw new Error('`namespace` argument is required when creating action types')
  }

  const actions = {
    REQUEST: `lighthouse/${namespace}/REQUEST`,
    REQUEST_SUCCESS: `lighthouse/${namespace}/REQUEST_SUCCESS`,
    REQUEST_ERROR: `lighthouse/${namespace}/REQUEST_ERROR`,

    QUERY_REQUEST: `lighthouse/${namespace}/QUERY_REQUEST`,
    QUERY_SUCCESS: `lighthouse/${namespace}/QUERY_SUCCESS`,
    QUERY_ERROR: `lighthouse/${namespace}/QUERY_ERROR`,

    FIND_REQUEST: `lighthouse/${namespace}/FIND_REQUEST`,
    FIND_SUCCESS: `lighthouse/${namespace}/FIND_SUCCESS`,
    FIND_ERROR: `lighthouse/${namespace}/FIND_ERROR`,

    SAVE_REQUEST: `lighthouse/${namespace}/SAVE_REQUEST`,
    SAVE_SUCCESS: `lighthouse/${namespace}/SAVE_SUCCESS`,
    SAVE_ERROR: `lighthouse/${namespace}/SAVE_ERROR`,
    SAVE_ROLLBACK: `lighthouse/${namespace}/SAVE_ROLLBACK`,

    REMOVE_REQUEST: `lighthouse/${namespace}/REMOVE_REQUEST`,
    REMOVE_SUCCESS: `lighthouse/${namespace}/REMOVE_SUCCESS`,
    REMOVE_ERROR: `lighthouse/${namespace}/REMOVE_ERROR`,

    SET_CURRENT: `lighthouse/${namespace}/SET_CURRENT`,
    UNSET_CURRENT: `lighthouse/${namespace}/UNSET_CURRENT`,

    ADD_TO_CACHE: `lighthouse/${namespace}/ADD_TO_CACHE`,
    REMOVE_FROM_CACHE: `lighthouse/${namespace}/REMOVE_FROM_CACHE`,

    CLEAR_LIST_ITEMS: `lighthouse/${namespace}/CLEAR_LIST_ITEMS`,
    ADD_TO_LIST: `lighthouse/${namespace}/ADD_TO_LIST`,
    REMOVE_FROM_LIST: `lighthouse/${namespace}/REMOVE_FROM_LIST`,

    INVALIDATE_ITEMS: `lighthouse/${namespace}/INVALIDATE_ITEMS`,
    INVALIDATE_LIST: `lighthouse/${namespace}/INVALIDATE_LIST`,

    SET_LIST_FILTERS: `lighthouse/${namespace}/SET_LIST_FILTERS`,
    CLEAR_LIST_FILTERS: `lighthouse/${namespace}/CLEAR_LIST_FILTERS`,

    CLEAR_LIST_SORT: `lighthouse/${namespace}/CLEAR_LIST_SORT`,
    SET_LIST_SORT: `lighthouse/${namespace}/SET_LIST_SORT`,

    SET_PAGINATION_OPTS: `lighthouse/${namespace}/SET_PAGINATION_OPTS`,
    SET_PAGINATION_LINKS: `lighthouse/${namespace}/SET_PAGINATION_LINKS`,
    CLEAR_PAGINATION: `lighthouse/${namespace}/CLEAR_PAGINATION`,

    DISCARD_OPTIMISTIC: `lighthouse/${namespace}/DISCARD_OPTIMISTIC`,
  }

  hooks(actions, namespace)

  return actions
}
