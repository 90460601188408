import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import { compose, setPropTypes } from 'recompose'
import Link from 'components/link'
import Spinner from 'components/spinner'
import styles from './styles'

const propTypes = {
  href: PropTypes.string, // href can be used to create a hyperlink button
  link: PropTypes.string, // link can be used to create a react router link
  onClick: PropTypes.func,
  theme: PropTypes.string,
  loading: PropTypes.bool,
}

export default compose(Radium, setPropTypes(propTypes))(Button)

function Button(props) {
  const {
    children,
    dataTestId,
    disabled,
    first,
    hoverBackgroundColor,
    hoverColor,
    href,
    last,
    link,
    noMargin,
    noPadding,
    loading,
    onClick,
    size,
    stacked,
    style: customStyles,
    tabIndex,
    theme,
    type = 'button',
    ...styleProps
  } = props

  const baseStyles = [
    styles.root,
    size && styles[size],
    stacked && styles.stacked,
    first && styles.first,
    last && styles.last,
    disabled && styles.disabled,
    noMargin && styles.noMargin,
    noPadding && styles.noPadding,
  ]

  const themeStyles = []

  if (theme) {
    const themeArr = theme.split(' ')
    themeArr.forEach(theme => {
      themeStyles.push(styles[theme])
    })
  }

  // NOTE: setting undefined properties on :hover
  // will break styles so do conditional checks!
  const hoverStyles = {
    ':hover': {},
  }

  if (hoverColor) {
    hoverStyles[':hover'].color = hoverColor
  }

  if (hoverBackgroundColor) {
    hoverStyles[':hover'].backgroundColor = hoverBackgroundColor
  }

  const buttonStyles = [
    ...baseStyles,
    ...themeStyles,
    customStyles,
    hoverStyles,
    styleProps,
  ]

  const spinner = loading && (
    <Spinner
      type="dots"
      size="small"
      theme="inverted"
      customStyles={styles.spinner}
    />
  )

  if (typeof href !== 'undefined') {
    return (
      <a
        data-testid={dataTestId}
        disabled={disabled}
        href={href}
        style={buttonStyles}
      >
        {spinner}
        {children}
      </a>
    )
  }

  if (typeof link !== 'undefined') {
    return (
      <Link
        data-testid={dataTestId}
        disabled={disabled}
        to={link}
        style={buttonStyles}
      >
        {spinner}
        {children}
      </Link>
    )
  }

  if (typeof onClick !== 'undefined') {
    return (
      <button
        data-testid={dataTestId}
        disabled={disabled}
        onClick={onClick}
        style={buttonStyles}
        tabIndex={tabIndex}
        type={type}
      >
        {spinner}
        {children}
      </button>
    )
  }

  return (
    <button
      data-testid={dataTestId}
      disabled={disabled}
      style={buttonStyles}
      tabIndex={tabIndex}
      type={type}
    >
      {spinner}
      {children}
    </button>
  )
}
