import {
  isRequired,
} from '../../validation'

export default {
  name: {
    label: 'Name',
    name: 'name',
    placeholder: 'Name',
    required: true,
    validate: [isRequired()],
  },
  template: {
    label: 'Template',
    name: 'template',
    placeholder: 'Select Template',
    required: true,
    validate: [isRequired()],
  },
  location: {
    label: 'Location',
    name: 'location',
    placeholder: 'Select Location',
  },
  zones: {
    label: 'Zones',
    name: 'zones',
    placeholder: 'Select Zone',
  },
}
