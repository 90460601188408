import { getModule } from '@lighthouse/sdk'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { get, isNil, some } from 'lodash'

import colors from 'config/theme/colors'
import { Block, Flex } from 'components/common'
import Button from 'components/button'
import Icon from 'components/icon'
import TemplateEntry from 'components/templates/entry'

import Form from './form'

const contentTemplates = getModule('content', 'templates')
const contentEntries = getModule('content', 'entries')

class ContentEntry extends Component {
  static propTypes = {
    data: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.back = this.back.bind(this)
    this.toggleEditMode = this.toggleEditMode.bind(this)

    this.state = { isEditingEntry: false }
  }

  render() {
    const { area, entryEntity, templateEntity, updateAreaView } = this.props

    const { isEditingEntry } = this.state

    const id = entryEntity._id
    const formGroups = get(entryEntity, 'entry.formGroups')
    const name = entryEntity.name
    const relations = get(entryEntity, 'entry.relations', [])
    const updatedAt = entryEntity.updatedAt

    const formName = `content-entry-${id}`
    const initialValues = { name, formGroups }
    const readOnly = !isEditingEntry

    const templateUpdatedAt = templateEntity.updatedAt
    const isTemplateInSync = new Date(templateUpdatedAt) < new Date(updatedAt)

    return (
      <Block>
        <Flex
          alignItems="center"
          background={colors.gray.white}
          borderBottom={`1px solid ${colors.gray.lightest}`}
          fontSize={12}
          height={55}
          lineHeight="55px"
          paddingTop={0}
          paddingRight={15}
          paddingBottom={0}
          paddingLeft={15}
          position="absolute"
          top={100}
          width={420}
        >
          <Button
            onClick={this.back}
            backgroundColor="transparent"
            padding="0px 10px"
          >
            <Icon fontSize={20} name="arrow-left" />
          </Button>
          <span>{name}</span>
        </Flex>
        <Form
          area={area}
          enableReinitialize
          entryId={id}
          form={formName}
          initialValues={initialValues}
          isEditingEntry={isEditingEntry}
          isTemplateInSync={isTemplateInSync}
          readOnly={readOnly}
          relations={relations}
          small
          templateId={templateEntity._id}
          toggleEditMode={this.toggleEditMode}
          updateAreaView={updateAreaView}
        >
          <TemplateEntry
            form={formName}
            initialValues={initialValues}
            readOnly={readOnly}
            small
            templateEntity={templateEntity}
          />
        </Form>
      </Block>
    )
  }

  back() {
    this.props.updateAreaView({
      selectedId: null,
    })
  }

  toggleEditMode() {
    this.setState({ isEditingEntry: !this.state.isEditingEntry })
  }
}

export default compose(connect(mapStateToProps))(ContentEntry)

function mapStateToProps(state, props) {
  const { entryId } = props

  const entrySelectors = contentEntries.selectors(state)('all')
  const templateSelectors = contentTemplates.selectors(state)('all')

  const entryResources = entrySelectors.cache()
  const templateResources = templateSelectors.cache()

  const entryEntity = get(entryResources, `${entryId}.entity`, {})
  const templateEntity = get(
    templateResources,
    `${entryEntity.template}.entity`,
    {}
  )

  return {
    entryEntity,
    templateEntity,
  }
}

function handleResponses(responses) {
  const hasErrors = some(responses, response => !isNil(response.error))

  if (!hasErrors) return

  return Promise.reject(new Error('Error fetching relationship options'))
}
