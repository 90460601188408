import React from 'react'
import Button from 'components/button'
import { Flex, Text } from 'components/common'

interface StatusBannerProps {
  buttonText: string | undefined
  text?: string
  TextAsComponent?: React.ElementType
  canAction: boolean
  handleChange: (status: boolean) => Promise<void>
  loading: boolean
  disabled: boolean
}

export const StatusBanner = ({
  buttonText,
  TextAsComponent,
  text,
  canAction,
  handleChange,
  loading,
  disabled,
}: StatusBannerProps): JSX.Element => {
  return (
    <Flex
      width="720px"
      background="rgb(246, 246, 246)"
      gap="1rem"
      padding="0.5rem 0.25rem 0.5rem 1rem"
      justifyContent="space-between"
      alignItems="center"
    >
      {text ? <Text fontWeight={300}>{text}</Text> : <TextAsComponent />}
      {canAction && (
        <Button
          disabled={disabled}
          loading={loading}
          paddingLeft="20px"
          onClick={handleChange}
          theme="primary"
          width="30%"
        >
          {!loading && buttonText}
        </Button>
      )}
    </Flex>
  )
}
