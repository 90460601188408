import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { WithPermissions } from '@lighthouse/react-components'
import { compose, setPropTypes, withHandlers, withState } from 'recompose'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { getModule } from '@lighthouse/sdk'

import AuthWrapper from '../components/auth-wrapper'
import SelectApplication from './components/select-application'
import SignIn from './components/sign-in'
import setEndpoints from '../components/set-endpoints'
import { useAccessToken } from 'components/useAccessToken'
import { authenticationSuccessHandler } from '../lib'

const authenticationModule = getModule('authentication')

const PROP_TYPES = {
  applicationId: PropTypes.string,
  clearAuthentication: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

export default compose(
  WithPermissions, // @NOTE: WithPermissions is here to provide hasModulePermission to withHandlers function.
  withRouter,
  setEndpoints,
  connect(mapStateToProps, mapDispatchToProps),
  withState('permissionError', 'setPermissionError'),
  withHandlers({ authenticationSuccessHandler }),
  setPropTypes(PROP_TYPES)
)(Login)

export { Login as LoginForTest }

function Login(props) {
  const {
    applicationId,
    authenticationSuccessHandler,
    clearAuthentication,
    history,
    location,
    permissionError,
  } = props
  const { getAccessToken } = useAccessToken()

  useEffect(() => {
    ;(async () => {
      const nextPath = get(location, 'state.nextPathname')

      // NOTE if accessToken is available in state, we assume the user has
      // navigated to the login page while being logged in. In this case we can
      // redirect them to whatever is specified in nextPath or to the root of the
      // console

      const accessToken = await getAccessToken()

      if (accessToken) {
        return history.replace(nextPath || '/')
      }

      // NOTE if no accessToken is available, then clear authentication and application id
      // which in turn resets the redux state. This guards against any corrupted state that
      // may have been left behind
      clearAuthentication()
    })()
  }, [])

  return (
    <AuthWrapper>
      {!applicationId && <SelectApplication />}
      {applicationId && (
        <SignIn
          handleSubmit={authenticationSuccessHandler}
          location={location}
          permissionError={permissionError}
        />
      )}
    </AuthWrapper>
  )
}

function mapStateToProps(state) {
  return {
    applicationId: state.app.applicationId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearAuthentication: () =>
      dispatch(authenticationModule.clearAuthentication()),
  }
}
