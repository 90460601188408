import { getGeometryGeocodedString } from '../../../helpers/get-geometry-geocoded-string'
import { head, join, split, tail } from 'lodash'

export function getFormattedAddress(reverseGeoCoded) {
  const geometry = getGeometryGeocodedString(reverseGeoCoded)
  const splitGeoString = split(geometry, ', ')
  const headText = head(splitGeoString)
  const tailText = join(tail(splitGeoString), ' ')
  const formatted = `${headText}, ${tailText}`

  return formatted
}
