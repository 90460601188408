/*
 * Service Hours Module
 */

import { combineReducers } from 'redux'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import crud from '../../crud'

const resource = 'serviceHours'

export { default as helpers } from './helpers'
export { default as schema } from './schema'

export const actions = crud.actions(resource)

export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn('service-hours'),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})
