import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import styles from './styles'

export default Radium(Wrapper)

function Wrapper(props) {
  const style = [styles.root, props.theme]

  return (
    <div className="wrapper" style={style}>
      {props.children}
    </div>
  )
}

Wrapper.propTypes = {
  theme: PropTypes.string,
}
