import {
  get,
  isArray,
  transform,
} from 'lodash'
import { serializeError } from '../../../errors'
import * as states from '../../../constants/states'
import { parseState } from '../../../helpers'

export default actions => function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case actions.FIND_REQUEST: {
      const { optimistic } = action
      state = state.setIn([action.id, 'state'], optimistic ? states.RESOLVED : states.RESOLVING)
      state = state.setIn([action.id, 'error'], null)
      return state
    }
    case actions.FIND_SUCCESS: {
      if (get(state, `${action.id}.retrying`)) {
        state = state.setIn([action.id, 'retrying'], null)
      }
      state = state.setIn([action.id, 'id'], action.id)
      state = state.setIn([action.id, 'error'], null)
      state = state.setIn([action.id, 'state'], states.RESOLVED)

      const entity = state.getIn([action.id, 'entity'])
      const nextEntity = { ...entity, ...action.data }
      state = state.setIn([action.id, 'entity'], nextEntity)

      return state
    }
    case actions.FIND_ERROR:
      if (state[action.id]) {
        state = state.setIn([action.id, 'state'], action.retrying ? states.RESOLVING : states.RESOLVED)
        state = state.setIn([action.id, 'error'], serializeError(action.error))
        if (action.retrying) {
          state = state.setIn([action.id, 'retrying', 'attempt'], action.retrying.attempt)
        }
      }
      return state
    case actions.SAVE_REQUEST: {
      const requestData = get(action, 'payload') || action.data || {}
      const { optimistic, params = {} } = action
      const entity = { ...requestData }
      // for existing data (update) clear any existing errors
      if (state[action.id]) {
        state = state.setIn([action.id, 'error'], null)
      }

      if (action.id) {
        // set reference for save attempt
        state = state.setIn([action.id, 'saveAttemptedAt'], new Date())
      }

      // for optimistic saving, we store the new data in cache before it has
      // actually saved to the server and mark as resolved
      if (optimistic) {
        // when setting data optimistically, we should ensure the new data is
        // merged with existing data as we support partial updates
        const existingResource = get(state, action.id) || {}
        const existingData = existingResource.entity || {}

        // It's useful to have the `id` value stored on the resource. Note, this
        // might not always be set in entity._id because it could be an
        // optimistic request
        if (!existingResource.originalEntity) {
          state = state.setIn([action.id, 'originalEntity'], existingData)
        }
        state = state.setIn([action.id, 'id'], action.id)
        state = state.setIn([action.id, 'entity'], Object.assign({}, existingData, entity))
        state = state.setIn([action.id, 'state'], states.RESOLVING)
        state = state.setIn([action.id, 'optimistic'], true)
        // NOTE the params are required in case of a rollback & retry, when all
        // context is derived from this state
        state = state.setIn([action.id, 'params'], params)
      } else if (action.id) {
        state = state.setIn([action.id, 'state'], states.RESOLVING)
      }

      return state
    }
    case actions.SAVE_ROLLBACK: {
      const { payload: error = {} } = action

      // NOTE set the resource state to rolled-back, but persist the optimistic
      // data and the error. This allows a retry to be requests as well as
      // discarding to the original entity
      state = state.setIn([action.id, 'state'], states.ROLLED_BACK)
      state = state.setIn([action.id, 'error'], serializeError(error))
      state = state.setIn([action.id, 'optimistic'], true)

      return state
    }
    case actions.SAVE_SUCCESS:
    case actions.ADD_TO_CACHE:
      const actionData = get(action, 'payload.json') || action.data || {}
      const localResource = state[action.id] || {}

      // NOTE we retrieve optimistic value from resource state on save success
      // cases as it might not be available on the action
      const { optimistic } = localResource

      if (optimistic) {
        // delete the temp data for optimistically stored document
        state = state.without(action.id)
      }

      // handle bulk save
      if (isArray(actionData)) {
        // NOTE we're assume bulk save is for new items only
        const entities = transform(actionData, (accum, data) => {
          const id = data._id || data.id || data.Id || data.ID
          accum[id] = {
            id,
            entity: data,
            state: states.RESOLVED,
          }
          return accum
        }, {})
        state = state.merge(entities)
        return state
      }

      const id = actionData._id || actionData.id || actionData.Id || actionData.ID
      state = state.setIn([id, 'id'], id)
      state = state.setIn([id, 'state'], states.RESOLVED)
      state = state.setIn([id, 'optimistic'], false)
      state = state.setIn([id, 'entity'], actionData)
      state = state.setIn([id, 'originalEntity'], false)

      return state
    case actions.SAVE_ERROR:
      // only update state for existing/optimistic data
      if (action.id) {
        state = state.setIn([action.id, 'error'], serializeError(action.error))
        state = state.setIn([action.id, 'state'], states.SAVE_FAILED)
      }

      return state
    case actions.QUERY_SUCCESS:
      action.data.forEach((item) => {
        const id = item._id || item.id || item.Id || item.ID
        const mappedItem = {
          ...item,
          _id: id.toString(),
        }
        state = state.update(id, cachedItem => (
          cachedItem
            ? cachedItem.merge({
              id,
              state: states.RESOLVED,
              entity: cachedItem.entity
                ? cachedItem.entity.merge(mappedItem)
                : mappedItem,
            })
            : {
              id,
              state: states.RESOLVED,
              entity: mappedItem,
            }
        ))
      })
      return state
    case actions.REMOVE_REQUEST:
      if (state[action.id]) {
        state = state.setIn([action.id, 'state'], states.RESOLVING)
      }
      return state
    case actions.REMOVE_SUCCESS:
    case actions.REMOVE_FROM_CACHE:
      if (state[action.id]) {
        state = state.without(action.id)
      }
      return state
    case actions.REMOVE_ERROR:
      if (state[action.id]) {
        state = state.setIn([action.id, 'error'], serializeError(action.error))
        state = state.setIn([action.id, 'state'], states.REMOVE_FAILED)
      }
      return state
    case actions.INVALIDATE_ITEMS:
      action.ids.forEach((id) => {
        const item = state[id]
        state = state.setIn([id, 'state'], 'invalidated')
      })
      return state
    case actions.DISCARD_OPTIMISTIC: {
      if (!action.id) return state

      const isPersisted = get(state, [action.id, 'entity', '_id'])

      if (!isPersisted) {
        // When data hasn't been persisted it should be removed from cache altogether
        return state.without(action.id)
      }

      const originalEntity = get(state, [action.id, 'originalEntity'])

      if (!originalEntity) return state

      return state.update(action.id, (resource) => {
        resource = resource.merge({
          entity: originalEntity,
          error: null,
          state: states.RESOLVED,
        })
        return resource.without(['error', 'optimistic', 'originalEntity'])
      })
    }
    default:
      return state
  }
}

