/*
 * Job Activity Module
 */

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  filter,
  memoize,
  orderBy,
} from 'lodash'

import crud from '../../crud'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

const resource = 'jobActivities'

export { default as schema } from './schema'

export const actions = crud.actions(resource)

export const actionCreators = crud.actionCreators(actions, {
  baseUrlFn,
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

const crudSelectors = crud.selectors(resource)

const filterListByJob = createSelector(
  [crudSelectors.list],
  listSelector => memoize((options) => {
    const listActivities = listSelector(options.listId)
    return orderBy(
      filter(listActivities, activity => (
        options.job ? activity.entity.job === options.job : true
      ),
    ), ['entity.createdAt'], ['desc'])
  }, (options) => {
    const cacheKey = `${options.listId}-${options.job}`
    return cacheKey
  }),
)

export const selectors = Object.assign(
  {},
  crudSelectors,
  {
    filterListByJob,
  },
)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

export function baseUrlFn(params) {
  const jobsEndpoint = applicationResourceUrlFn('jobs')(params)
  return params.job ?
    `${jobsEndpoint}/${params.job}/activities` :
    `${jobsEndpoint}/activities`
}
