import { camelCase } from 'lodash'
import { compose, pure } from 'recompose'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'

import colors from 'config/theme/colors'

import { Block, Flex, Span } from 'components/common'
import Chevron from 'components/chevron'
import Icon from 'components/icon'

export default compose(Radium, pure)(FilterDropdownItem)

function FilterDropdownItem(props) {
  const { icon = 'cross', label, onClick, ...styles } = props

  const { t } = useTranslation()

  return (
    <Flex
      {...styles}
      alignItems="center"
      borderBottomColor={colors.gray.lighter}
      borderBottomStyle="solid"
      borderTop={0}
      borderBottomWidth={1}
      color={colors.gray.normal}
      cursor="pointer"
      flexDirection="row"
      fontSize={12}
      hoverBackgroundColor={colors.gray.lightest}
      onClick={onClick}
      overflow="hidden"
    >
      <Block
        cursor="pointer"
        flexGrow={1}
        marginLeft={16}
        overflow="hidden"
        textOverflow="ellipsis"
        title={label}
        whiteSpace="nowrap"
      >
        {t(camelCase(`label ${label}`), label)}
      </Block>
      <Block marginRight={16}>
        {icon === 'arrow' && <Chevron right thin />}
        {icon === 'checked' && <Icon fontSize="12px" name="check" />}
        {icon === 'cross' && <CloseIcon />}
      </Block>
    </Flex>
  )
}

FilterDropdownItem.propTypes = {
  hasArrow: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

function CloseIcon() {
  return (
    <Span cursor="pointer" fontSize={14}>
      ×
    </Span>
  )
}
