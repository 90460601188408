import { find } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'
import Image from 'components/image'
import Video from 'components/video'

const videoRgx = new RegExp('.mp4$')
const videoType = 'video/mp4'

export default function Thumbnail(props) {
  const { alt = 'Thumbnail Image', link, src, width, ...styleProps } = props
  const isVideoType = link && videoRgx.test(link)

  return (
    <Flex
      alignItems="center"
      border="1px solid #eee"
      borderRadius="4px"
      justifyContent="center"
      minHeight="100px"
      minWidth="100px"
      padding="5px"
      {...styleProps}
    >
      {link ? (
        isVideoType ? (
          <Video src={link} type={videoType} width={width} />
        ) : (
          <a href={link} rel="noopener noreferrer" target="_blank">
            <Image alt={alt} src={src} width={width} />
          </a>
        )
      ) : (
        <Image alt={alt} src={src} width={width} />
      )}
    </Flex>
  )
}

Thumbnail.propTypes = {
  alt: PropTypes.string.isRequired,
  link: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
}
