import React from 'react'

import { Block } from 'components/common'
import colors from 'config/theme/colors'

export default function ControlWrapper({ children, height }) {
  return (
    <Block
      backgroundColor="#fff"
      borderTop={`1px solid ${colors.gray.lightest}`}
      bottom={0}
      height={height || 70}
      overflow="hidden"
      paddingTop={0}
      paddingRight={15}
      paddingBottom={0}
      paddingLeft={15}
      position="absolute"
      transition="0.5s"
      width="calc(100% - 30px)"
    >
      {children}
    </Block>
  )
}
