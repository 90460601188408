import {
  isInteger,
  isNaN,
  stubTrue,
  toNumber,
  trim,
} from 'lodash'

import validator from 'validator'

export function isEmail(options = {}) {
  const {
    errorMessage = 'This field must be a valid email address',
  } = options

  return (value) => {
    if (!value) return undefined
    if (validator.isEmail(trim(value))) return undefined
    return errorMessage
  }
}

export function isLength(options = {}) {
  const {
    errorMessage,
    max,
    min,
  } = options

  let defaultErrorMessage

  if ((min && !isInteger(min)) || (max && !isInteger(max))) {
    throw new Error('You must pass integer min/max values')
  }

  if (min && max) {
    defaultErrorMessage = `This field should be between ${min} and ${max} characters`
  }

  if (min && !max) {
    defaultErrorMessage = `This field should be at least ${min} characters`
  }

  if (max && !min) {
    defaultErrorMessage = `This field should be at most ${max} characters`
  }

  return (value) => {
    if (!value) return undefined
    if (validator.isLength(trim(value), { min, max })) return undefined
    return errorMessage || defaultErrorMessage
  }
}

export function isRequired(options = {}) {
  const {
    errorMessage = 'This is a required field',
  } = options

  return (value) => {
    if (trim(value)) return undefined
    return errorMessage
  }
}

export function isNumber(options = {}) {
  const {
    errorMessage = 'This field must be a number',
  } = options

  return (value) => {
    if (!value) return undefined
    if (!isNaN(toNumber(value))) return undefined
    return errorMessage
  }
}

export function isNumberOrDecimal(options = {}) {
  const {
    errorMessage = 'This field must be a number or decimal up to 2 decimal places',
  } = options

  return (value) => {
    const decimalRegex = /^\d{1,6}(\.\d{1,2})?$/

    if (!value) return undefined
    if (!decimalRegex.test(value)) return errorMessage

    return undefined
  }
}

export function isWholeNumber(options = {}) {
  const {
    errorMessage = 'This field must be a whole number',
  } = options

  return (value) => {
    const wholeRegex = /^(0|[1-9]\d*)$/

    if (!value) return undefined
    if (!wholeRegex.test(value)) return errorMessage

    return undefined
  }
}

export function isMin(options = {}) {
  const {
    minValue = 0,
    errorMessage = 'This field cannot be lower than',
  } = options

  return (value) => {
    if (!value) return undefined
    if (value < minValue) return `${errorMessage} ${minValue}`
    return undefined
  }
}

export function isMax(options = {}) {
  const {
    maxValue = 100,
    errorMessage = 'This field cannot be higher than',
  } = options

  return (value) => {
    if (!value) return undefined
    if (value > maxValue) return `${errorMessage} ${maxValue}`
    return undefined
  }
}

export function isUUID(options = {}) {
  const {
    errorMessage = 'This field must be a valid UUID',
  } = options

  return (value) => {
    if (!value) return undefined
    if (validator.isUUID(trim(value))) return undefined
    return errorMessage
  }
}

export function validate(validateFn, errorMessage, predicateFn = stubTrue) {
  return (value, allValues, props) => {
    if (!predicateFn(value, allValues, props)) return undefined
    if (!validateFn(value, allValues, props)) return undefined

    return errorMessage
  }
}
