import colors from 'config/theme/colors'
import typography from 'config/theme/typography'

export default {
  root: {
    height: '48px',
    lineHeight: '48px',
    borderRight: `1px solid ${colors.gray.lighter}`,
    display: 'flex',
  },
  noBorder: {
    borderRight: '0 none',
  },
  input: {
    background: '0 none',
    border: '0 none',
    outline: 'none',
    padding: '0 10px',
    fontFamily: typography.defaultFontFamily,
    fontSize: '12px',
    fontWeight: 500,
    color: colors.gray.normal,
  },
  icon: {
    height: '48px',
    lineHeight: '48px',
    paddingLeft: '20px',
    fontSize: '16px',
    color: colors.gray.normal,
  },
  clearBtn: {
    cursor: 'pointer',
    lineHeight: '32px',
    marginRight: 7,
    padding: 8,
  },
}
