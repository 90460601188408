import { getModule } from '@lighthouse/sdk'
import React, { useEffect, useMemo } from 'react'
import { Block } from 'components/common'
import Select from '../select'
import { useDispatch, useSelector } from 'react-redux'

const rolesModule = getModule('roles')

const DEFAULT_LIST_ID = 'default'
export default function RolesSelect(props) {
  const {
    className,
    clearable,
    dataTestId = 'roles-select',
    disabled,
    disableSelectedList,
    error,
    multi,
    onBlur,
    onChange,
    placeholder,
    required,
    value,
    type,
    warningMessage,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(rolesModule.query('default', { perPage: 999 }))
  }, [dispatch])

  const classes = error ? `${className}, error` : className
  const isObjectValue = value && value.length && typeof value[0] === 'object'

  const roles = useSelector(state =>
    rolesModule
      .selectors(state)(DEFAULT_LIST_ID)
      .list(DEFAULT_LIST_ID)
  )

  const options = useMemo(
    () =>
      roles
        .map(roleRestriction => ({
          label: roleRestriction.entity.name,
          search: roleRestriction.entity.search,
          value: roleRestriction.entity._id,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [roles]
  )

  return (
    <Block position={'relative'}>
      <Select
        className={classes}
        clearable={clearable}
        dataTestId={dataTestId}
        disabled={disabled}
        disableSelectedList={disableSelectedList}
        multi={multi}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        required={required}
        selectAllLimit={150}
        showRemoveItems={true}
        showSelectAll={true}
        type={type}
        value={isObjectValue ? value.map(val => val.id) : value}
        warningMessage={warningMessage}
      />
    </Block>
  )
}
