import './style.css'
import 'moment-timezone'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { isBoolean, once } from 'lodash'
import { withSafeInterval } from '@hocs/safe-timers'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import moment from 'moment'
import Promise from 'bluebird'
import Radium from 'radium'
import React from 'react'
import Timeline from 'react-visjs-timeline'
import withHandlers from 'recompose/withHandlers'
import withProps from 'recompose/withProps'

import { getDateInTimezone } from 'helpers/datetime'
import ClockTicker from 'components/clock-ticker'
import Blink from 'components/blink'
import Button from 'components/button'
import Caret from 'components/caret'
import DateTimePicker from 'components/date-time-picker-deprecated'
import Icon from 'components/icon'

import styles from './styles.js'
import { useTranslation } from 'react-i18next'

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24

const DEFAULT_OPTS = {
  width: '100%',
  height: '60px',
  stack: false,
  showMajorLabels: true,
  showCurrentTime: true,
  zoomMin: ONE_DAY_IN_MS,
  zoomMax: ONE_DAY_IN_MS,
  type: 'background',
  format: {
    minorLabels: {
      minute: 'h:mma',
      hour: 'ha',
    },
  },
}

const DEFAULT_DATES = { from: null, to: null }
const DATETIME_FORMAT = 'h:mma D MMM'
const TIMELINE_ANIMATION_OPTS = {
  duration: 2000,
  easingFunction: 'easeInOutQuad',
}
const TIMELINE_CURRENT_TIME_REFRESH = 60000

const activityModule = getModule('activity')
const applicationUserModule = getModule('applicationUsers')
const geoModule = getModule('geo')
const eventsModule = getModule('events')

export default compose(
  Radium,
  withSafeInterval,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(mapTimelineTzProps),
  withHandlers({ getEvents }),
  withHandlers({ fetchActivities, getLiveEvents }),
  withHandlers({ gotoHistoricalPointInTime, gotoLivePointInTime }),
  withHandlers({ goToDate, movePeriod }),
  withHandlers({
    getHistoricalEvents,
    goBackOneDay,
    goForwardOneDay,
    handleDatePickerChange,
    onRangechanged,
    onTimechanged,
  }),
  withHandlers({ onSelectTime, refreshCurrentTime }),
  lifecycle({ componentWillMount })
)(MapTimeline)

function MapTimeline(props) {
  const {
    // TODO this is confusing legacy code where isLive being true used to
    // represent the real-time maps and false the heat maps. This is super
    // confusing because we have a live/historical mode for the timeline in
    // real-time too. We can re-implement and tidy up once we bring heat
    // mapping to the new map format
    isRealTime = true,
    currentTimeTz,
    datepickerDisplay,
    goBackOneDay,
    goForwardOneDay,
    handleDatePickerChange,
    onRangechanged,
    onSelectTime,
    onTimechanged,
    timeline: {
      currentTime = new Date(),
      dates = DEFAULT_DATES,
      endTime,
      isLive,
      startTime,
    },
    timezone,
    gotoLivePointInTime,
  } = props

  const { t } = useTranslation()

  // only show custom times when not in live mode
  const customTimes = !isLive
    ? {
        timeHandle: currentTime,
      }
    : {}

  const start =
    startTime ||
    moment
      .tz(timezone)
      .startOf('day')
      .toDate()

  const end =
    endTime ||
    moment
      .tz(timezone)
      .endOf('day')
      .toDate()

  const minDate = moment
    .tz(timezone)
    .startOf('day')
    .subtract(90, 'days')
    .toDate()

  const maxDate = moment.tz(timezone).toDate()

  const timelineOptions = {
    ...DEFAULT_OPTS,
    end,
    moment: date => moment(date).tz(timezone),
    start,
  }

  const dateValue = isRealTime
    ? currentTimeTz && currentTimeTz.format('YYYY-MM-DD HH:mm')
    : dates

  const displayClock = datepickerDisplay === 'clock'
  const displaySelectedDates = datepickerDisplay === 'selectedDates'
  const selectedDates = displaySelectedDates ? displayDates() : null

  return (
    <div>
      <div style={styles.clock}>
        <DateTimePicker
          disableTimepicker={isRealTime}
          maxDate={maxDate}
          minDate={minDate}
          minDateText="Date range queries are limited to within last 90 days"
          numberOfMonths={isRealTime && 2}
          onChange={handleDatePickerChange}
          position={'tl'}
          toMonth={new Date()}
          value={dateValue}
        >
          <Icon name="calendar" theme={styles.calendarIcon} />
          {displayClock && (
            <ClockTicker
              datetime={!isLive && currentTime}
              showDate
              style={styles.dateText}
              tick={isLive}
              timezone={timezone}
            />
          )}
          {displaySelectedDates && (
            <span style={styles.dateText}>{selectedDates}</span>
          )}
        </DateTimePicker>
        <Blink disabled={!isLive} customStyle={styles.clockBtnPosition}>
          <Button
            onClick={gotoLivePointInTime}
            theme={isLive ? '' : 'positive'}
            style={[styles.clockBtn, isLive && styles.clockBtnLive]}
          >
            {isLive ? t('button.live') : t('button.goLive')}
          </Button>
        </Blink>
      </div>
      <div style={styles.timeline}>
        <Timeline
          options={timelineOptions}
          customTimes={customTimes}
          animate={TIMELINE_ANIMATION_OPTS}
          // items={exceptionItems}
          rangechangedHandler={onRangechanged}
          timechangedHandler={onTimechanged}
          clickHandler={onSelectTime}
        />
        <Button
          theme="primary"
          onClick={goBackOneDay}
          style={[styles.timelineBtn, styles.prevBtn]}
        >
          <Caret left color="white" />
        </Button>
        <Button
          theme="primary"
          onClick={goForwardOneDay}
          style={[styles.timelineBtn, styles.nextBtn]}
        >
          <Caret right color="white" />
        </Button>
      </div>
    </div>
  )
}

function componentWillMount() {
  const {
    fetchActivities,
    getHistoricalEvents,
    getLiveEvents,
    setSafeInterval,
    setTimeline,
    refreshCurrentTime,
    timeline = {},
    timezone,
  } = this.props

  const mTimezone = moment().tz(timezone)

  const {
    currentTime = new Date(),
    endTime = mTimezone.endOf('day').toDate(),
    startTime = mTimezone.startOf('day').toDate(),
  } = timeline

  const isLive = isBoolean(timeline.isLive) ? timeline.isLive : true

  setTimeline({
    currentTime,
    endTime,
    startTime,
    isLive,
  })

  setSafeInterval(refreshCurrentTime, TIMELINE_CURRENT_TIME_REFRESH)

  const promises = [fetchActivities({ currentTime, endTime, startTime })]

  if (isLive) {
    promises.push(getLiveEvents())
  } else {
    promises.push(getHistoricalEvents(currentTime))
  }

  return Promise.all(promises)
}

/**
 * Returns a string of the dates value, formatted
 */
function displayDates(props) {
  const {
    timeline: { dates = {} },
  } = props

  if (!dates.from || !dates.to) {
    return 'Select a Date range'
  }

  return `${moment(dates.from).format(DATETIME_FORMAT)} -
    ${moment(dates.to).format(DATETIME_FORMAT)}`
}

function getEvents(props) {
  const { getLatestEvents, setMarkersFromEvents } = props

  return params =>
    getLatestEvents({ params }).then(response =>
      setMarkersFromEvents(response.data)
    )
}

function getHistoricalEvents(props) {
  const { getEvents } = props

  return timestamp => {
    const between = buildBetweenString(timestamp)
    return getEvents({ between })
  }
}

function getLiveEvents(props) {
  const { getEvents } = props

  return () => {
    const now = moment()
    const period = now.subtract(8, 'hours')
    const after = period.toISOString()

    return getEvents({ after })
  }
}

function goBackOneDay(props) {
  const { movePeriod } = props
  return () => movePeriod({ hours: 24, direction: 'prev' })
}

function goForwardOneDay(props) {
  const { movePeriod } = props
  return () => movePeriod({ hours: 24, direction: 'next' })
}

function goToDate(props) {
  const { gotoHistoricalPointInTime, gotoLivePointInTime, timezone } = props

  return date => {
    const dateInTimezone = getDateInTimezone(date, timezone)

    const startOfDay = dateInTimezone.startOf('day').toDate()
    const endOfDay = dateInTimezone.endOf('day').toDate()
    const middleOfDay = moment(startOfDay)
      .add(12, 'hours')
      .toDate()

    const startTime = startOfDay
    const endTime = endOfDay
    const currentTime = middleOfDay
    const now = new Date()

    if (moment(middleOfDay).isSame(now, 'day')) {
      return gotoLivePointInTime()
    }

    return gotoHistoricalPointInTime({ startTime, endTime, currentTime })
  }
}

function gotoHistoricalPointInTime(props) {
  const {
    getEvents,
    fetchActivities,
    removeMarkersByTypes,
    setLoading,
    setTimeline,
  } = props

  return opts => {
    const { currentTime, endTime, startTime } = opts

    removeMarkersByTypes(['user'])

    const between = buildBetweenString(currentTime)

    setTimeline({
      currentTime,
      endTime,
      isLive: false,
      startTime,
    })

    setLoading(true)

    const promises = [
      getEvents({ between }),
      fetchActivities({ currentTime, endTime, startTime }),
    ]

    return Promise.all(promises).then(() => setLoading(false))
  }
}

function fetchActivities(props) {
  const { fetchActivity } = props

  return opts => {
    const currentTimeUtc = moment(opts.currentTime).toISOString()
    const startTimeUtc = moment(opts.startTime).toISOString()

    return fetchActivity({
      between: `${startTimeUtc}|${currentTimeUtc}`,
      expiresAt: `>=${currentTimeUtc}`,
      perPage: 200,
      sort: '-timestamp',
    })
  }
}

function handleDatePickerChange(props) {
  const { goToDate } = props
  return value => goToDate(value)
}

function movePeriod(props) {
  const { startTimeTz, endTimeTz, gotoHistoricalPointInTime } = props

  return opts => {
    const { hours, direction = 'next' } = opts

    const momentFn = direction === 'next' ? 'add' : 'subtract'
    const newStart = startTimeTz[momentFn](hours, 'hours').startOf('hour')
    const newEnd = endTimeTz[momentFn](hours, 'hours').endOf('hour')

    gotoHistoricalPointInTime({
      startTime: newStart.toDate(),
      endTime: newEnd.toDate(),
      currentTime: newStart.add(hours / 2, 'hours').toDate(),
    })
  }
}

function gotoLivePointInTime(props) {
  const {
    fetchActivities,
    getLiveEvents,
    removeMarkersByTypes,
    setLoading,
    setTimeline,
    timeline,
    timezone,
  } = props

  return () => {
    if (timeline.isLive) return

    const mTimezone = moment().tz(timezone)
    const startTime = mTimezone.startOf('day').toDate()
    const endTime = mTimezone.endOf('day').toDate()
    const currentTime = new Date()

    // fetch and set live data...

    removeMarkersByTypes(['user'])
    setLoading(true)

    setTimeline({
      currentTime,
      endTime,
      isLive: true,
      startTime,
    })

    const promises = [
      getLiveEvents(),
      fetchActivities({
        currentTime,
        endTime,
        startTime,
      }),
    ]

    return Promise.all(promises).then(() => setLoading(false))
  }
}

function onRangechanged(props) {
  const { setTimeline } = props

  return event => {
    // TODO what is this prop
    if (!event.byUser) return

    setTimeline({
      startTime: event.start,
      endTime: event.end,
    })
  }
}

function onSelectTime(props) {
  const {
    fetchAllApplicationUsers,
    gotoHistoricalPointInTime,
    gotoLivePointInTime,
    timeline,
  } = props

  const { startTime, endTime } = timeline

  return event => {
    const isFutureTime = event.time > new Date()

    const isLive = isFutureTime

    if (isLive) {
      return gotoLivePointInTime()
    }

    const currentTime = event.time

    // NOTE Currently we don't support user name reference on events, so
    // unfortunately we have to fetch the data from the server. We fetch all
    // users as we don't know which are relevant to the particular moment in
    // history
    fetchAllApplicationUsers()

    return gotoHistoricalPointInTime({
      currentTime,
      endTime,
      startTime,
    })
  }
}

function onTimechanged(props) {
  const { setTimeline } = props

  return event => {
    const now = moment()
    const timeIsInFuture = moment(event.time).isAfter(now)
    const newTime = timeIsInFuture ? now.toDate() : event.time

    setTimeline({
      currentTime: newTime,
    })
  }
}

function refreshCurrentTime(props) {
  const { cleanMarkers, setTimeline, timeline } = props

  return () => {
    if (!timeline.isLive) return
    setTimeline({ currentTime: new Date() })
    cleanMarkers()
  }
}

function buildBetweenString(timestamp, hourPeriod = 8) {
  const mCurrentTime = moment(timestamp)
  const before = mCurrentTime.toISOString()
  const after = mCurrentTime.subtract(hourPeriod, 'hours').toISOString()

  const between = `${after}|${before}`
  return between
}

function mapStateToProps(state) {
  return {
    timeline: state.geo.timeline || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cleanMarkers: () => dispatch(geoModule.cleanMarkers()),
    fetchActivity: params => dispatch(activityModule.query('default', params)),
    fetchAllApplicationUsers: once(() =>
      dispatch(
        applicationUserModule.query('all', {
          // NOTE it's important to include `user` in fields as that's how we map the
          // user to the event, i.e. the application user id is different
          fields: 'firstName,lastName,user',
          perPage: 9999,
        })
      )
    ),
    getLatestEvents: options =>
      dispatch(eventsModule.request('latest-by-user', options)),
    setTimeline: opts => dispatch(geoModule.setTimeline(opts)),
    removeMarkersByTypes: type =>
      dispatch(geoModule.removeMarkersByTypes(type)),
  }
}

function mapTimelineTzProps(props) {
  const { timeline, timezone } = props

  return {
    currentTimeTz: moment(timeline.currentTime).tz(timezone),
    startTimeTz: moment(timeline.startTime).tz(timezone),
    endTimeTz: moment(timeline.endTime).tz(timezone),
  }
}
