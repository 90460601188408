import cacheReducer from './cache'
import currentReducer from './current'
import listReducer from './list'

export default actions => ({
  cache: cacheReducer(actions),
  current: currentReducer(actions),
  list: listReducer(actions),
})

