import React from 'react'
import Icon from 'components/icon'
import styles from './styles'

export default function ButtonPrompt(props) {
  const { style = styles.root } = props

  const promptIcon = props.icon && (
    <Icon name={props.icon} theme={styles.icon} />
  )

  return (
    <div style={style}>
      {promptIcon}
      {props.text}
    </div>
  )
}
