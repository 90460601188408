import { isEmpty, capitalize as capitalizeFn } from 'lodash'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'

import colors from 'config/theme/colors'
import { Anchor, Block, Flex } from 'components/common'

import styles from './styles'
import './styles.css'

class FormText extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['email', 'number', 'password', 'text']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    type: 'text',
  }

  render() {
    const {
      autoComplete,
      autoFocus,
      capitalize,
      clearable,
      dataTestId,
      disabled,
      multiline,
      name,
      onKeyPress,
      onRemove,
      placeholder,
      readOnly,
      required,
      small,
      style: customStyle = [],
      type,
      input = {},
      meta = {},
    } = this.props

    let { error, onBlur, onChange, touched, value } = this.props

    // NOTE if we pass Text directly as the
    // component to a redux form field we'll
    // need to use the input/meta properties
    // unless we want to override
    error = error || meta.error
    onBlur = onBlur || input.onBlur
    onChange = onChange || input.onChange
    touched = touched || meta.touched
    value = capitalize ? capitalizeFn(value) : value

    if (readOnly) {
      return (
        <Block dataTestId={dataTestId} padding={10} wordBreak="break-word">
          {isEmpty(value) ? '' : value}
        </Block>
      )
    }

    const errorStyles = error && touched ? styles.inputError : null
    const smallInput = small ? styles.smallInput : null

    // NOTE its useful to know the difference between readonly and
    // disabled http://stackoverflow.com/a/7730719
    const disabledStyles = disabled ? styles.disabled : null
    const baseStyles = [styles.root, errorStyles, disabledStyles]

    const field = multiline ? (
      <textarea
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        data-testid={dataTestId}
        name={name}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        style={[...baseStyles, styles.textarea, smallInput, ...customStyle]}
        value={value}
      />
    ) : (
      <Flex alignItems="center">
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          data-testid={dataTestId}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          style={[...baseStyles, styles.input, smallInput, customStyle]}
          type={type}
          value={value}
        />
        {clearable && onRemove && (
          <Anchor
            color={colors.gray.light}
            cursor="pointer"
            data-testid={`${dataTestId} anchor`}
            href="#"
            onClick={onRemove}
            tabIndex="-1"
            marginLeft={-30}
            padding={7}
          >
            &times;
          </Anchor>
        )}
      </Flex>
    )

    return field
  }
}

export default Radium(FormText)
