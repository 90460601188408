import { compose, withHandlers } from 'recompose'
import debounceHandler from '@hocs/debounce-handler'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'components/common'

import { SearchInput } from './components'

export default compose(
  withHandlers({ handleStateChange }),
  debounceHandler('onInputValueChange', 700)
)(Search)

export { Search as SearchForTest }

function Search(props) {
  const {
    children,
    handleStateChange,
    isLoading,
    itemToString,
    onClearSelection,
    onInputValueChange,
    onSelection,
    placeholder,
    selectedItem,
    zIndex,
  } = props

  return (
    <Downshift
      itemToString={itemToString}
      onChange={onSelection}
      onInputValueChange={onInputValueChange}
      selectedItem={selectedItem}
      stateReducer={handleStateChange}
    >
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        inputValue,
        openMenu,
        selectedItem,
      }) => (
        <div style={{ zIndex }}>
          <SearchInput
            clearSelection={onClearSelection || clearSelection}
            getLabelProps={getLabelProps}
            getInputProps={getInputProps}
            isLoading={isLoading}
            openMenu={openMenu}
            placeholder={placeholder}
            selectedItem={selectedItem}
          />
          {children({
            getMenuProps,
            getItemProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          })}
        </div>
      )}
    </Downshift>
  )
}

// NOTE: #512 bug on downshift library where
// when choosing the selected item it will
// reset the input value in the internal state!
// Workaround which can be removed once fixed
// https://github.com/paypal/downshift/issues/512
function handleStateChange(props) {
  const { itemToString } = props

  return (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          inputValue:
            state.selectedItem === changes.selectedItem
              ? itemToString(state.selectedItem)
              : changes.inputValue,
        }
      default:
        return changes
    }
  }
}

Search.propTypes = {
  children: PropTypes.func.isRequired,
  itemToString: PropTypes.func.isRequired,
  onClearSelection: PropTypes.func,
  onInputValueChange: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  zIndex: PropTypes.number,
}
