import React from 'react'
import styles from './styles'

import { assign } from 'lodash'

export default function ListPlaceholder(props) {
  const style = assign({}, styles.root, props.style)

  return (
    <div style={style}>
      <div style={styles.icon}>
        <div style={styles.placeholderIcon} />
        <div style={styles.placeholderLine} />
      </div>
      <div style={styles.details}>
        <div style={styles.placeholderTitle} />
        <div style={styles.placeholderLine} />
        <div style={styles.placeholderLine} />
      </div>
    </div>
  )
}
