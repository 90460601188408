import Radium from 'radium'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import { withTranslation } from 'react-i18next'
import { chain, concat, filter, includes } from 'lodash'
import colors from 'config/theme/colors'
import { Block } from 'components/common'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'

import emitter from 'utils/emitter'
import ControlWrapper from '../../../control-wrapper'
import FieldWrapper from '../../../field-wrapper'
import ListItem from '../../../list-item'

const areas = getModule('areas')

class AuditTemplateList extends Component {
  constructor(props) {
    super(props)

    this.onCancel = this.onCancel.bind(this)
    this.onAuditSelect = this.onAuditSelect.bind(this)
    this.saveAudits = this.saveAudits.bind(this)

    const selectedAudits = props.areaAudits || []
    this.state = {
      selectedAudits,
    }
  }

  render() {
    const { auditCache, t } = this.props

    const { selectedAudits } = this.state

    const auditItems = chain(auditCache)
      .sortBy(['entity.title'])
      .map(({ entity }, index) => (
        <ListItem
          active={includes(selectedAudits, entity._id)}
          item={entity}
          key={index}
          locked={entity.locked}
          display="flex"
          alignItems="center"
          onClick={() => {
            if (entity.locked) {
              return emitter.emit('notification:add', {
                message: t('alert.message.locationAddAuditTemplateLocked'),
                title: t('alert.title.error'),
                theme: 'alert',
              })
            }
            this.onAuditSelect(entity._id)
          }}
        />
      ))
      .value()

    const isDisabled = selectedAudits.length === 0

    return (
      <Block>
        <Block
          background={colors.gray.white}
          borderBottom={`1px solid ${colors.gray.lightest}`}
          fontSize={12}
          paddingTop={20}
          paddingRight={15}
          paddingBottom={20}
          paddingLeft={15}
        >
          Select new audit forms:
        </Block>
        <FieldWrapper top={156}>{auditItems}</FieldWrapper>
        <ControlWrapper>
          <ButtonGroup>
            <Button onClick={this.onCancel}>{t('button.cancel')}</Button>
            <Button
              disabled={isDisabled}
              theme="positive"
              onClick={this.saveAudits}
            >
              {t('button.save')}
            </Button>
          </ButtonGroup>
        </ControlWrapper>
      </Block>
    )
  }

  onCancel() {
    this.props.updateAreaView({ isAddAuditTemplateMode: false })
  }

  onAuditSelect(id) {
    const { selectedAudits } = this.state

    if (includes(selectedAudits, id)) {
      return this.setState({
        selectedAudits: filter(selectedAudits, audit => audit !== id),
      })
    }

    this.setState({
      selectedAudits: concat(selectedAudits, id),
    })
  }

  saveAudits() {
    const { area, updateAreaView, updateReferences } = this.props

    const { selectedAudits } = this.state

    const areaEntity = area.entity
    const id = areaEntity._id
    const audits = selectedAudits

    updateAreaView({ isLoading: true })

    return updateReferences(id, audits)
      .catch(handleError)
      .finally(() =>
        updateAreaView({
          isAddAuditTemplateMode: false,
          isLoading: false,
        })
      )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  Radium
)(AuditTemplateList)

function mapStateToProps(state) {
  return {
    auditCache: state.audits.cache,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateReferences: (areaId, auditIds) =>
      dispatch(areas.updateReferences(areaId, 'audits', auditIds)),
  }
}

function handleError(error) {
  const message = error.message || 'Error saving audits for area'
  console.error(message)
}
