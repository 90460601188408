import React from 'react'

import { Flex } from 'components/common'

interface BackgroundProps {
  height?: string | number
  width?: string | number
  children: JSX.Element
}

export default function Background(props: BackgroundProps): JSX.Element {
  const { height = '100%', width = '100%', children } = props
  return (
    <Flex
      alignItems="center"
      /* eslint-disable react/jsx-no-duplicate-props */
      /* http://colorzilla.com/gradient-editor/#008ed8+0,00b4e4+80 */
      background="#008ed8" /* Old browsers */
      // @ts-ignore
      background="-moz-linear-gradient(45deg, #008ed8 0%, #00b4e4 80%)" /* FF3.6-15 */
      background="webkit-linear-gradient(45deg, #008ed8 0%,#00b4e4 80%)" /* Chrome10-25,Safari5.1-6 */
      background="linear-gradient(45deg, #008ed8 0%,#00b4e4 80%)" /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      /* eslint-enable react/jsx-no-duplicate-props */
      flexDirection="column"
      height={height}
      justifyContent="center"
      left={0}
      position="absolute"
      top={0}
      width={width}
    >
      {children}
    </Flex>
  )
}
