import Immutable, { isImmutable } from 'seamless-immutable'
import {
  actions,
} from '../'

export default function reducer(state = Immutable({}), action = {}) {
  if (!isImmutable(state)) {
    state = Immutable(state)
  }

  switch (action.type) {
    case actions.AVERAGE_DURATION_SUCCESS:
      // NOTE It's a bit confusing, but when we query average duration
      // we are also given the count of exceptions for the query
      // params, so we might as well store that too
      state = state.setIn(
        ['count', action.slug],
        action.data.exceptionsLength,
      )
      state = state.setIn(
        ['averageDuration', action.slug],
        action.data.averageDuration,
      )
      return state
    case actions.AVERAGE_DURATION_ERROR:
      return state.setIn(
        ['averageDuration', 'error'],
        action.error,
      )
    default:
      return state
  }
}
