import { chain, isEmpty } from 'lodash'

import { getUserFullName } from '../'

export function getAssigneesFullNames(users, assignees) {
  if (isEmpty(assignees)) {
    return 'No users assigned'
  }

  const fullNames = chain(assignees)
    .map(userId => getUserFullName(users, userId))
    .compact()
    .join(', ')
    .value()

  return fullNames
}
