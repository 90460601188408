import React from 'react'
import { SimpleTableProps } from './simpleTable.types'
import HeaderRow from './headerRow'
import BodyRow from './bodyRow'

const simpleTable = (props: SimpleTableProps): JSX.Element => {
  const { children, data, rowStyle = {}, headerStyle = {}, style = {} } = props
  const columns = React.Children.toArray(children)

  return (
    <div style={{ height: '100%', ...style }}>
      <HeaderRow columns={columns} headerStyle={headerStyle} />
      <div>
        {data.map((rowData, rowIndex) => (
          <BodyRow
            key={`Row-${rowIndex}`}
            columns={columns}
            rowData={rowData}
            rowIndex={rowIndex}
            rowStyle={rowStyle}
          />
        ))}
      </div>
    </div>
  )
}

export default simpleTable
