/**
 * NOTE Why do we have this component? The AuthProvider sits above our Redux
 * Provider in the react tree so we can't access the legacy accessToken from
 * that hook.  This is a dedicated hook that can be used throughout the app to
 * return the appropriate accessToken without that limitation
 * TODO In future when legacy tokens are full deprecated, this can be moved into
 * the useAuth component
 */

import { useSelector } from 'react-redux'
import { useAuth, GetAccessToken } from './useAuth'
import { useAsync } from 'react-use'
import * as logger from 'utils/logger'

interface ReduxState {
  authentication: {
    accessToken: string
  }
}

interface UseAccessTokenResult {
  getAccessToken: GetAccessToken
  loading: boolean
  accessToken?: string
}

export function useAccessToken(): UseAccessTokenResult {
  const { getAccessToken } = useAuth()
  const legacyAccessToken = useSelector<ReduxState>(
    state => state?.authentication?.accessToken
  )

  const accessToken = useAsync(getAccessToken)

  async function getAccessTokenWrapper() {
    let oidcAccessToken: string | void

    try {
      oidcAccessToken = await getAccessToken()
    } catch (err) {
      logger.error('UseAccessTokenGetAccessTokenError', {
        err: err.message,
        stack: err.stack,
      })
    }

    return oidcAccessToken || legacyAccessToken
  }

  return {
    accessToken: accessToken.value || legacyAccessToken,
    // It can still be useful expose an async function to be consumed as well as
    // the token and loading state, hence why this is here
    getAccessToken: getAccessTokenWrapper,
    // It's important to use the loading state when consuming this hook.
    // Without, the consumer might assume the token doesn't exist when in fact
    // it's still resolving
    loading: accessToken.loading,
  }
}
