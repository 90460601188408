import { stubTrue } from 'lodash'
import {
  compose,
  lifecycle,
  shouldUpdate,
  withHandlers,
  withState,
} from 'recompose'

/**
 * Throttles parent re-renders using timeout value passed. Any updates from
 * above this composed function will be ran at maximum once every {timeout}
 * seconds
 */
export default (timeout, predicate = stubTrue) =>
  compose(
    withState('expiredAt', 'setExpiry'),
    withState('intervalTimer', 'setIntervalTimer'),
    withHandlers({
      startInterval: props => () => {
        const { intervalTimer, setExpiry, setIntervalTimer } = props

        console.debug('⏰ [throttle] start')

        clearInterval(intervalTimer)

        const interval = setInterval(() => {
          console.debug(`⏰ [throttle] interval: ${timeout}`)
          const now = Date.now()
          setExpiry(now)
        }, timeout)

        setIntervalTimer(interval)
      },
      stopInterval: props => () => {
        const { intervalTimer, setExpiry, setIntervalTimer } = props

        console.debug('⏰ [throttle] stop')

        clearInterval(intervalTimer)
        setExpiry(null)
        setIntervalTimer(null)
      },
    }),
    lifecycle({
      componentDidMount: function componentDidMount() {
        const { startInterval } = this.props

        const shouldBeRunning = predicate(this.props)

        // Don't start interval if predicate fails
        if (!shouldBeRunning) return

        startInterval()
      },
      componentDidUpdate: function componentDidUpdate() {
        const { intervalTimer, startInterval, stopInterval } = this.props

        const shouldBeRunning = predicate(this.props)

        if (!shouldBeRunning && !intervalTimer) return

        if (!shouldBeRunning && intervalTimer) {
          stopInterval()
          return
        }

        if (shouldBeRunning && !intervalTimer) {
          startInterval()
          return
        }
      },
      componentWillUnmount: function componentWillUnmount() {
        this.props.stopInterval()
      },
    }),
    shouldUpdate((props, nextProps) => {
      const shouldBeRunning = predicate(nextProps)

      // Don't suppress if throttling disabled
      if (!shouldBeRunning) return true

      const previouslyRunning = predicate(props)

      // Refresh if state has changed to running
      if (!previouslyRunning) return true

      return props.expiredAt !== nextProps.expiredAt
    })
  )
