import moment from 'moment-timezone'

import { GetNext, Interval, Unit } from '../scheduling.types'

/**
 * Generates next occurrence interval for x day of x week of month strategy
 */
export function* getNextXDayOfXWeekOfMonth({
  end,
  isInitial,
  options,
  start,
  timezone,
}: GetNext): IterableIterator<Interval> {
  const { dayOfWeek, duration, frequency, weekOfMonth } = options
  const { unit: durationUnit, value: durationValue } = duration
  const { unit: frequencyUnit, value: frequencyValue } = frequency

  let dateCursor: number = isInitial
    ? moment
        .tz(start, timezone)
        .startOf(Unit.Month)
        .valueOf()
    : moment
        .tz(start, timezone)
        .add(frequencyValue, frequencyUnit)
        .startOf(Unit.Month)
        .valueOf()

  while (dateCursor < end) {
    const mStartOfMonth = moment.tz(dateCursor, timezone)
    const year: number = mStartOfMonth.year()
    const month: number = mStartOfMonth.month()
    const startDayOfMonth: number = mStartOfMonth.isoWeekday()

    const weekOffset: number = (weekOfMonth - 1) * 7
    const day: number =
      startDayOfMonth > dayOfWeek
        ? dayOfWeek - startDayOfMonth + 8
        : dayOfWeek - startDayOfMonth + 1
    const date: number = weekOffset + day

    const nextOccurrenceEnd: number = moment
      .tz(timezone)
      .year(year)
      .month(month)
      .date(date)
      .add(1, Unit.Day)
      .startOf(Unit.Day)
      .valueOf()

    const nextOccurrenceStart: number = moment
      .tz(nextOccurrenceEnd, timezone)
      .subtract(durationValue, durationUnit)
      .valueOf()

    if (nextOccurrenceEnd <= nextOccurrenceStart || nextOccurrenceEnd > end) {
      return
    }

    if (nextOccurrenceStart >= start) {
      const nextOccurrence: Interval = [
        nextOccurrenceStart,
        nextOccurrenceEnd - 1,
      ]

      yield nextOccurrence
    }

    const nextDateCursor: number = moment
      .tz(dateCursor, timezone)
      .add(1, Unit.Month)
      .valueOf()

    dateCursor = nextDateCursor
  }
}
