import { Interval } from '../scheduling.types'

/**
 * Checks if interval within another interval
 * Accepts two intervals and checks whether within other interval
 *
 * intervalWithin([7,12], [5,15])
 * returns true
 */
export function intervalWithin(
  firstInterval: Interval,
  secondInterval: Interval
): Boolean {
  return (
    firstInterval[0] > secondInterval[0] && firstInterval[1] < secondInterval[1]
  )
}
