import PropTypes from 'prop-types'
import Radium from 'radium'
import React, { Component } from 'react'
import styles from './styles'

export default Radium(ButtonGroup)

function ButtonGroup(props) {
  const { align, border, stacked, children } = props

  const alignStyles =
    align === 'right' ? styles.right : align === 'center' ? styles.center : null

  const rootStyles = [
    styles.root,
    alignStyles,
    border && styles.border,
    stacked && styles.stacked,
  ]

  return <div style={rootStyles}>{children}</div>
}

ButtonGroup.propTypes = {
  align: PropTypes.string,
  border: PropTypes.bool,
  stacked: PropTypes.bool,
}
