import {
  filter,
  find,
  groupBy,
  isEmpty,
  map,
  size,
  sortBy,
  take,
  toString,
  reduce,
} from 'lodash'

import moment from 'moment-timezone'
import Promise from 'bluebird'

import {
  generateDefinition,
  buildLocationScansContent,
  buildLocationScansBoundaries,
  horizontalLine,
  summaryStatTable,
  summaryWrapperTable,
  table,
  summaryFieldsTable,
  text,
  zebraFillColor,
} from '../helpers'

import {
  getLocationReference,
  getTimezoneAbbr,
  getTimezoneDatetime,
} from '../../helpers'
import { launchIcon } from '../../images'

const DEFAULT_MAX_SCANS = 200
const MAX_ATTACHMENTS = 100
const LIGHTHOUSE_BASE_URL = 'https://app.lighthouse.io'

/**
 * buildActivityPdf
 *
 * @param {object} pdfOptions - the pdf options
 * @param {string} pdfOptions.fileTitle - pdf file title
 * @param {function} pdfOptions.footer - function executed to generate footer
 * @param {function} pdfOptions.header - function executed to generate header
 * @param {string} pdfOptions.logoUrl - pdf logo url
 * @param {array} pdfOptions.pageMargins - pdf page margins
 * @param {string} pdfOptions.pageOrientation - pdf page orientation
 * @param {string} pdfOptions.pageSize - pdf page size
 * @param {object} pdfOptions.styles - pdf styles
 * @param {object} pdfOptions.title - pdf title
 * @param {object} data - pdf data
 * @param {array} data.audits - audit documents
 * @param {string} data.end - end datetime of summary report
 * @param {array} data.events - event documents
 * @param {object} data.location - location document
 * @param {array} data.locations - location documents
 * @param {array} data.issues - issue documents
 * @param {bool} data.maxScans - max scans to include in standard report
 * @param {bool} data.showAlternate - show alternate summary report
 * @param {string} data.start - start datetime of summary report
 * @param {object} data.settings - settings properties
 * @param {string} data.settings.awsS3BaseUrl - aws S3 base url
 * @param {string} data.settings.cloudinaryBaseUrl - cloudinary base url
 * @param {array} data.tasks - task documents
 * @param {string} data.timestamp - timestamp string
 * @param {string} data.timezone - timezone string
 * @param {array} data.zones - zone documents
 * @param {array} data.users - user documents
 * @returns {Promise} returns pdfmake definition object
 */
export async function buildActivityPdf(pdfOptions, data) {
  const { timestamp, timezone } = data

  const title = 'Daily Activity Report'
  const content = await generateContent(data)

  return generateDefinition({
    content,
    fileTitle: title,
    timestamp,
    timezone,
    type: 'Activity Summary',
    ...pdfOptions,
  })
}

async function generateContent(data) {
  const activitySection = await buildActivitySection(data)
  const summarySection = buildSummarySection(data)
  const titleTable = buildTitleTable(data)

  return [titleTable, summarySection, activitySection]
}

async function buildActivitySection(data) {
  const { showAlternate = false } = data

  if (showAlternate) {
    const locationScans = buildLocationScansSection(data)
    const otherActivity = await buildOtherActivitySection(data)

    return [locationScans, otherActivity]
  }

  const activitySummary = await buildActivitySummarySection(data)

  return [activitySummary]
}

async function buildActivitySummarySection(data) {
  const {
    audits,
    events,
    issues,
    maxScans = DEFAULT_MAX_SCANS,
    settings,
    tasks,
    timezone,
  } = data

  const enterEvents = filter(events, event => event.type === 'enter')
  const exceedsMaxScans = size(enterEvents) > maxScans
  const scans = take(enterEvents, maxScans)

  const auditRows = map(audits, getFormRowData('audits', data))
  const issueRows = map(issues, getFormRowData('issues', data))
  const scanRows = map(scans, getScanRowData(data))
  const taskRows = map(tasks, getFormRowData('tasks', data))

  const formCount = size([...auditRows, ...issueRows, ...taskRows])
  const exceedsMaxForms = formCount > MAX_ATTACHMENTS

  const rows = [...auditRows, ...issueRows, ...scanRows, ...taskRows]
  const sortedRows = sortBy(rows, ['timestamp'], ['asc'])
  const title = text('Activity Summary', { style: 'summarySectionHeader' })

  const header = table({
    body: [[title]],
    layout: 'noBorders',
    style: 'summaryHeaderTable',
  })

  const hLine = horizontalLine()
  const activityTable = await buildActivityTable({
    rows: sortedRows,
    settings,
    timezone,
  })
  const activitySummarySection = [header, hLine, activityTable]

  if (exceedsMaxScans) {
    const maxScansText = {
      text: [
        text(
          `Location Scans in the Daily Activity Report are limited to a total of ${maxScans}.`
        ),
        text(' To view all Location Scans visit '),
        text('the Events Report', {
          link: `${LIGHTHOUSE_BASE_URL}/reports/events`,
        }),
        text(' in the Lighthouse Management Console.'),
      ],
      style: 'small',
      margin: [0, 10, 0, 10],
    }

    activitySummarySection.push(maxScansText)
  }

  if (exceedsMaxForms) {
    const maxFormsText = {
      text: [
        text(
          `Detailed Form Submissions in the Daily Activity Report are limited to a total of ${MAX_ATTACHMENTS}. `
        ),
        text(
          'To access individual Form Submissions, open the hyperlink next to the respective submission within the Activity Summary table. Alternatively, they may be accessed in the Lighthouse Management Console.'
        ),
      ],
      style: 'small',
      margin: [0, 10, 0, 10],
    }
    activitySummarySection.push(maxFormsText)
  }

  return activitySummarySection
}

async function buildActivityTable({ rows, settings, timezone }) {
  const timezoneAbbr = getTimezoneAbbr(timezone)

  const activityTableHeader = [
    text('Activity', { bold: true }),
    { text: '' }, // icon column
    text('Location', { bold: true }),
    text('User', { bold: true }),
    text(`Timestamp (${timezoneAbbr})`, { alignment: 'right', bold: true }),
  ]

  const activityTableRows = await Promise.map(rows, async function(data) {
    const { link, location, icon, name, summaryFields, timestamp, title } = data

    const timezoneHour = getTimezoneDatetime({
      format: 'ddd DD h:mm a',
      showTzAbbr: false,
      timestamp,
      timezone,
    })

    const iconCell = icon
      ? { alignment: 'center', fit: [8, 8], image: icon, link }
      : { text: '' }

    // NOTE: pass link to each column as
    // we can't wrap the entire table row
    const rows = [
      [
        text(title, { link }),
        iconCell,
        text(location, { link }),
        text(name, { link }),
        text(timezoneHour, { alignment: 'right', link }),
      ],
    ]

    const hasSummaryFields = !isEmpty(summaryFields)

    if (hasSummaryFields) {
      const fieldsTable = await summaryFieldsTable({
        fields: summaryFields,
        settings,
        timezone,
      })
      rows.push([fieldsTable])
    }

    // NOTE: we return a table per row which includes the entry details and the
    // summary fields if relevant
    const activityTableRow = [
      table({
        body: [...rows],
        colSpan: 5,
        dontBreakRows: true,
        headerRow: 0,
        layout: {
          hLineWidth: () => 0,
          paddingLeft: () => 0,
          paddingRight: () => 0,
          paddingTop: () => 5,
          paddingBottom: () => 5,
          vLineWidth: () => 0,
        },
        // NOTE: the timestamp must fill up the rest of the table space as
        // otherwise the padding would be off between this table and the parent
        // table that wraps it
        widths: [130, 25, 110, 110, '*'],
      }),
    ]

    return activityTableRow
  })

  const activityTableBody = isEmpty(activityTableRows)
    ? [[text('No other activity', { colSpan: 5 })]]
    : activityTableRows

  const activityTable = table({
    body: [activityTableHeader, ...activityTableBody],
    dontBreakRows: true,
    layout: {
      hLineWidth: () => 0,
      fillColor: zebraFillColor,
      paddingLeft: () => 5,
      paddingRight: () => 5,
      paddingTop: () => 5,
      paddingBottom: () => 5,
      vLineWidth: () => 0,
    },
    widths: [120, 15, 100, 100, '*'],
  })

  return activityTable
}

function buildLocationScansSection(data) {
  const { events, start, timezone, zones } = data

  const title = text('Location Scans', { style: 'summarySectionHeader' })

  const header = table({
    body: [[title]],
    layout: 'noBorders',
    style: 'summaryHeaderTable',
  })

  // use start time and timezone to figure out the interval boundaries
  const hourIntervalBoundaries = buildLocationScansBoundaries(start, timezone)

  // pair interval boundaries into column headers for the table
  const hourIntervalColumnHeaders = hourIntervalBoundaries.reduce(
    (columns, interval, index, intervals) => {
      if (index < size(intervals) - 1)
        // skip the final iteration as it's just the end of the last interval
        columns.push(
          text(`${interval.format('h')}-${intervals[index + 1].format('hA')}`, {
            alignment: 'center',
            bold: true,
          })
        )
      return columns
    },
    []
  )

  const scansTableHeader = [
    text('Location', { bold: true }),
    ...hourIntervalColumnHeaders,
    text('Total', { alignment: 'right', bold: true }),
  ]

  const hLine = horizontalLine()

  // NOTE: only show the enter events within the summary table
  const enterEvents = filter(events, event => event.type === 'enter')
  const sortedZones = sortBy(zones, ['name'])

  const groupedEventsByZone = reduce(
    sortedZones,
    (memo, zone) => {
      const zoneEvents = filter(
        enterEvents,
        event => event.zone && event.zone.toString() === zone._id.toString()
      )
      memo[zone._id] = zoneEvents
      return memo
    },
    {}
  )

  const scansTableRows = map(groupedEventsByZone, (events, zone = '') => {
    const sortedGroup = sortBy(events, ['timestamp'], ['asc'])

    // put events into interval buckets
    const locationScansByInterval = buildLocationScansContent(
      hourIntervalBoundaries,
      events
    )
    // map the interval buckets to a format fit for the table
    const locationScansByIntervalContent = locationScansByInterval.map(
      interval => {
        return { alignment: 'center', text: size(interval) }
      }
    )

    const totalEvents = size(sortedGroup)

    const zoneDoc =
      zone && find(zones, doc => doc._id.toString() === zone.toString())
    const zoneName = (zoneDoc && zoneDoc.name) || 'Unknown Location'

    return [
      zoneName,
      ...locationScansByIntervalContent,
      { alignment: 'right', bold: true, text: totalEvents },
    ]
  })

  const scansTableBody = isEmpty(scansTableRows)
    ? [[text('No location scans', { colSpan: 10 })]]
    : scansTableRows

  const scansTable = table({
    body: [scansTableHeader, ...scansTableBody],
    widths: [100, '*', '*', '*', '*', '*', '*', '*', '*', '*'],
  })

  return [header, hLine, scansTable]
}

function buildSummarySection(data) {
  const { audits, events, issues, tasks } = data

  //const userEvents = groupBy(events, 'user')
  const eventsByType = groupBy(events, 'type')

  const enterEventsCount = size(eventsByType.enter)
  //const geoEventsCount = size(eventsByType.geo)
  const issuesCount = size(issues)
  const tasksCount = size(tasks)
  const auditsCount = size(audits)
  //const uniqueUsers = size(userEvents)

  const auditsText = text('AUDITS', { style: 'summaryText' })
  //const geoText = text('GPS', { style: 'summaryText' })
  const issuesText = text('ISSUES', { style: 'summaryText' })
  //const peopleText = text('PEOPLE', { style: 'summaryText' })
  const scansText = text('SCANS', { style: 'summaryText' })
  const tasksText = text('TASKS', { style: 'summaryText' })

  const auditsCountText = text(toString(auditsCount), { style: 'summaryCount' })
  //const peopleCountText = text(toString(uniqueUsers), { style: 'summaryCount' })
  const scansCountText = text(toString(enterEventsCount), {
    style: 'summaryCount',
  })
  //const geoCountText = text(toString(geoEventsCount), { style: 'summaryCount' })
  const issuesCountText = text(toString(issuesCount), { style: 'summaryCount' })
  const tasksCountText = text(toString(tasksCount), { style: 'summaryCount' })

  const auditTable = summaryStatTable({
    body: [[auditsText], [auditsCountText]],
  })
  //const peopleTable = summaryStatTable({
  //  body: [[peopleText], [peopleCountText]],
  //})
  //const gpsTable = summaryStatTable({ body: [[geoText], [geoCountText]] })
  const scanTable = summaryStatTable({ body: [[scansText], [scansCountText]] })
  const issueTable = summaryStatTable({
    body: [[issuesText], [issuesCountText]],
  })
  const taskTables = summaryStatTable({ body: [[tasksText], [tasksCountText]] })

  const wrapperTable = summaryWrapperTable({
    body: [
      [
        /*peopleTable,*/
        /*gpsTable,*/
        scanTable,
        issueTable,
        taskTables,
        auditTable,
      ],
    ],
  })

  return wrapperTable
}

async function buildOtherActivitySection(data) {
  const { audits, issues, tasks, settings, timezone } = data

  const auditRows = map(audits, getFormRowData('audits', data))
  const issueRows = map(issues, getFormRowData('issues', data))
  const taskRows = map(tasks, getFormRowData('tasks', data))

  const rows = [...auditRows, ...issueRows, ...taskRows]
  const sortedRows = sortBy(rows, ['timestamp'], ['asc'])
  const title = text('Other Activity', { style: 'summarySectionHeader' })

  const header = table({
    body: [[title]],
    layout: 'noBorders',
    style: 'summaryHeaderTable',
  })

  const hLine = horizontalLine()
  const activityTable = await buildActivityTable({
    rows: sortedRows,
    settings,
    timezone,
  })

  const otherActivitySummarySection = [header, hLine, activityTable]

  const formCount = size(rows)
  const exceedsMaxForms = formCount > MAX_ATTACHMENTS

  if (exceedsMaxForms) {
    const maxFormsText = {
      text: [
        text(
          `Detailed Form Submissions in the Daily Activity Report are limited to a total of ${MAX_ATTACHMENTS}. `
        ),
        text(
          'To access individual Form Submissions, open the hyperlink next to the respective submission within the Other Activity table. Alternatively, they may be accessed in the Lighthouse Management Console.'
        ),
      ],
      style: 'small',
      margin: [0, 10, 0, 10],
    }
    otherActivitySummarySection.push(maxFormsText)
  }

  return otherActivitySummarySection
}

function buildTitleTable(data) {
  const { location, start, end, timezone } = data
  const { address = {} } = location

  const headerTitle = text('Daily Activity Report', { style: 'title' })
  const headerSubTitle = text(location.name, { style: 'subTitle' })
  const addressText = !isEmpty(address)
    ? `${address.street}, ${address.city || ''} ${address.state ||
        ''} ${address.postalCode || ''}`
    : ''
  const headerAddress = text(addressText, { style: 'small' })

  const mTimezoneStart = moment.tz(start, timezone)
  const mTimezoneEnd = moment.tz(end, timezone)
  const timezoneAbbr = getTimezoneAbbr(timezone)

  const rangeStart = moment(mTimezoneStart).format('MMM DD h:mma')

  const rangeEnd = moment(mTimezoneEnd)
    .add(1, 'hour')
    .startOf('hour')
    .format('MMM DD h:mma')

  const headerRange = text(`${rangeStart} - ${rangeEnd} (${timezoneAbbr})`, {
    style: 'subTitle2',
  })

  const body = !isEmpty(address)
    ? [[headerTitle], [headerSubTitle], [headerAddress], [headerRange]]
    : [[headerTitle], [headerSubTitle], [headerRange]]

  return table({
    body,
    layout: 'noBorders',
    style: 'titleTable',
  })
}

function getFormRowData(collection, { locations, users, zones }) {
  return document => {
    const {
      _id,
      createdAt,
      entry = {},
      title = 'Unknown',
      user = '',
    } = document

    const location = getLocationReference({
      entity: document,
      locations,
      zones,
    })

    const link = `${LIGHTHOUSE_BASE_URL}/reports/${collection}/${_id}`
    const timestamp = createdAt
    const userDoc = find(users, doc => doc._id.toString() === user.toString())
    const name =
      (userDoc && `${userDoc.firstName} ${userDoc.lastName}`) || 'Unknown User'

    // NOTE: currently only issues and tasks will return summary fields, audits
    // aren't supported and will return an empty array here
    const summaryFields = entry.summaryFields || []

    return {
      icon: launchIcon,
      location,
      link,
      name,
      summaryFields,
      timestamp,
      title,
    }
  }
}

function getScanRowData({ users, zones }) {
  return document => {
    const { timestamp, user = '', zone = '' } = document

    const userDoc =
      user && find(users, doc => doc._id.toString() === user.toString())
    const zoneDoc =
      zone && find(zones, doc => doc._id.toString() === zone.toString())

    const zoneName = zoneDoc && zoneDoc.name
    const location = zoneName || 'Unknown Location'

    const name =
      (userDoc && `${userDoc.firstName} ${userDoc.lastName}`) || 'Unknown User'

    const title = 'Location Scan'

    return {
      location,
      timestamp,
      name,
      title,
    }
  }
}
