import React from 'react'
import { getModule } from '@lighthouse/sdk'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import * as logger from 'utils/logger'
import { useRequest, withRequest, Request } from './useRequest'

const applicationUserModule = getModule('applicationUsers')

// NOTE the user is in fact an application user in terms of model used from the
// API
interface User {
  firstName: string
  lastName: string
  username: string
}

interface CachedUser {
  entity: User
  id: string
}

interface Response {
  json: User
  lastKey: string | null
  links: string | null
  totalCount: number | null
}

interface FetchUserOptions {
  userId: string
}

type FetchUser = (options: FetchUserOptions) => Promise<User | undefined>

interface WrappedFetchUserOptions extends FetchUserOptions {
  getUserSelector: (userId: string) => CachedUser | undefined
  addToCache: (data: User) => void
  request: Request
}

type WrappedFetchUser = (
  options: WrappedFetchUserOptions
) => Promise<User | undefined>

interface UseUsersResult {
  fetchUser: FetchUser
}

export function useUsers(): UseUsersResult {
  const { request } = useRequest()
  const dispatch = useDispatch()
  const applicationUserSelectors = useSelector(state =>
    applicationUserModule.selectors(state)()
  )

  const fetchUser: FetchUser = async ({ userId }) => {
    return await wrappedFetchUser({
      addToCache: data => dispatch(applicationUserModule.addToCache(data)),
      getUserSelector: applicationUserSelectors.getApplicationUser,
      request,
      userId,
    })
  }

  return {
    fetchUser,
  }
}

export const withUsers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRequest,
  withHandlers({
    addToCache: props => user => props.addToCache(user),
    fetchUser: (props): FetchUser => async ({ userId }) => {
      return await wrappedFetchUser({
        addToCache: data => props.addToCache(data),
        getUserSelector: props.getApplicationUser,
        request: props.request,
        userId,
      })
    },
  })
)

function mapStateToProps(state) {
  const applicationUserSelectors = applicationUserModule.selectors(state)()

  return {
    getApplicationUser: applicationUserSelectors.getApplicationUser,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addToCache: (user: User) =>
      dispatch(applicationUserModule.addToCache(user)),
  }
}

const wrappedFetchUser: WrappedFetchUser = async ({
  addToCache,
  getUserSelector,
  request,
  userId,
}) => {
  try {
    console.debug('FetchUser', { userId })

    const cachedUser = getUserSelector(userId)

    // NOTE future option: bypass cache
    if (cachedUser?.entity) {
      console.debug('FetchUser returning cached user entity', {
        cachedUser: cachedUser.entity,
      })
      return cachedUser.entity
    }

    const response = (await request(
      `/users/actions/get-by-user/${userId}`
    )) as Response

    const user = response.json

    console.debug('FetchUser success!', { user })

    addToCache(user)

    return user
  } catch (err) {
    console.error('FetchUserError', {
      err,
    })
    logger.error('FetchUserError', {
      err: err.message,
      stack: err.stack,
    })

    return undefined
  }
}
