import { first, map, orderBy } from 'lodash'
import { compose, withProps } from 'recompose'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Block, Flex } from 'components/common'
import DateSeparator from './components/date-separator'
import NoMessagesBox from './components/no-messages-box'
import Composer from '../composer'
import Message from '../message'

const wrapperStyles = {
  display: 'flex',
  flexDirection: 'column-reverse',
  flexGrow: 1,
  overflowY: 'scroll',
}

class Chat extends Component {
  constructor(props) {
    super(props)

    this.shouldScrollBottom = true
    this.wrapper = null

    this.setWrapperRef = element => {
      this.wrapper = element
    }
  }

  componentWillUpdate() {
    if (this.wrapper) {
      const node = this.wrapper

      this.shouldScrollBottom =
        node.scrollTop + node.offsetHeight === node.scrollHeight
    }
  }

  componentDidUpdate({ messages: previousMessages = [] }) {
    const { messages: nextMessages = [] } = this.props

    if (nextMessages.length !== previousMessages.length) {
      const latestMessage = nextMessages.slice(-1)[0] || false

      // NOTE: We define an owner message as the last message in the list
      // without a date. Because we optimistically add new owner messages to the
      // list, they don't get a date value until the request resolves from the API.
      // This can only happen for your messages, so we know if the last message is
      // from this user / owner
      const newOwnerMessage = latestMessage && !latestMessage.date
      if (newOwnerMessage) {
        this.shouldScrollBottom = true
      }
    }

    if (this.shouldScrollBottom) {
      const node = this.wrapper

      node.scrollTop = node.scrollHeight
    }
  }

  render() {
    const { onSend, sortedMessages = [], textSize } = this.props

    const hasMessages = sortedMessages.length > 0
    const firstMessage = hasMessages && sortedMessages.slice(-1)[0]
    let prevMessage = null

    return (
      <Flex flexDirection="column" height="100%">
        <div ref={this.setWrapperRef} style={wrapperStyles}>
          {hasMessages ? (
            map(sortedMessages, (message, index) => {
              const { date } = message

              const prevDate = prevMessage && prevMessage.date
              const isSameDay = prevDate && moment(date).isSame(prevDate, 'day')

              // set up next iteration
              prevMessage = message

              return (
                <Block key={index}>
                  <Message {...message} textSize={textSize} />
                  {prevDate && !isSameDay && <DateSeparator date={prevDate} />}
                </Block>
              )
            })
          ) : (
            <NoMessagesBox />
          )}
          {/* NOTE: This is actually first due to the column-reverse flex layout */}
          {firstMessage && <DateSeparator date={firstMessage.date} />}
        </div>
        <Block padding={16}>
          <Composer onSend={onSend} />
        </Block>
      </Flex>
    )
  }
}

export default compose(withProps(sortMessages))(Chat)

Chat.propTypes = {
  messages: PropTypes.array,
  title: PropTypes.string.isRequired,
}

function sortMessages({ messages = [] }) {
  return {
    sortedMessages: orderBy(messages, ['date'], ['desc']),
  }
}
