/*
 * ApplicationUser Module
 */

import {
  find,
  filter,
  memoize,
} from 'lodash'

import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import buildActionCreators from './action-creators'

import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

import crud from '../../crud'
import hooks from './hooks'

export { default as schema } from './schema'

const resource = 'applicationUsers'

const actions = crud.actions(resource)
const actionCreators = {}

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn('users'),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
  save: {
    // always use put for save requests
    method: 'PUT',
  },
})

const customActionCreators = buildActionCreators(
  actions,
  applicationResourceUrlFn('users'),
  authorizationHeadersFn,
  applicationParamsFn,
)

// merge custom actions
Object.assign(actions, {
  MESSAGES_REGISTRATION_REQUEST: `lighthouse/${resource}/MESSAGES_REGISTRATION_REQUEST`,
  MESSAGES_REGISTRATION_SUCCESS: `lighthouse/${resource}/MESSAGES_REGISTRATION_SUCCESS`,
  MESSAGES_REGISTRATION_ERROR: `lighthouse/${resource}/MESSAGES_REGISTRATION_ERROR`,
  MESSAGES_DEREGISTRATION_REQUEST: `lighthouse/${resource}/MESSAGES_DEREGISTRATION_REQUEST`,
  MESSAGES_DEREGISTRATION_SUCCESS: `lighthouse/${resource}/MESSAGES_DEREGISTRATION_SUCCESS`,
  MESSAGES_DEREGISTRATION_ERROR: `lighthouse/${resource}/MESSAGES_DEREGISTRATION_ERROR`,
})

// merge crud + custom action creators
Object.assign(
  actionCreators,
  crudActionCreators,
  customActionCreators,
)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})
export {
  actions,
  actionCreators,
}

hooks(actions)

const crudSelectors = crud.selectors(resource)

const getApplicationUser = createSelector(
  crudSelectors.cache,
  cache => memoize(userId => find(cache, ['entity.user._id', userId])),
)

const getUserFullName = createSelector(
  crudSelectors.cache,
  cache => memoize((userId) => {
    const applicationUser = find(cache, ['entity.user._id', userId])

    if (!applicationUser) {
      return 'Unknown User'
    }

    const { firstName, lastName } = applicationUser.entity
    return `${firstName} ${lastName}`
  }),
)

const onlineCount = createSelector(
  crudSelectors.cache,
  (cache) => {
    const onlineUsers = filter(cache, 'entity.user.online')
    return onlineUsers.length
  },
)

export const selectors = Object.assign(
  {},
  crudSelectors,
  {
    getApplicationUser,
    getUserFullName,
    onlineCount,
  },
)
