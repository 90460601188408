import { AutoSizer, InfiniteLoader, List } from 'react-virtualized'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import NoResults from 'components/no-results'

export default class InfiniteLoaderList extends Component {
  constructor(props) {
    super(props)

    this.noRowsRenderer = this.noRowsRenderer.bind(this)
  }

  noRowsRenderer() {
    if (this.props.refreshing) return

    return <NoResults message="No Activity" />
  }

  render() {
    const {
      isRowLoaded,
      loadMoreRows,
      rowRenderer,
      listRowCount,
      loaderRowCount,
      refreshing,
    } = this.props

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={loaderRowCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={registerChild}
                height={height}
                onRowsRendered={onRowsRendered}
                noRowsRenderer={this.noRowsRenderer}
                rowCount={listRowCount}
                rowHeight={70}
                rowRenderer={rowRenderer}
                width={width}
                refreshing={refreshing}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }
}

InfiniteLoader.propTypes = {
  isRowLoaded: PropTypes.func,
  loadMoreRows: PropTypes.func,
  rowRenderer: PropTypes.func,
  listRowCount: PropTypes.number,
  loaderRowCount: PropTypes.number,
  refreshing: PropTypes.string,
}
