import { colors as brandColors } from 'config/theme/brand'
import colors from 'config/theme/colors'
import Color from 'color'

const primaryColor = brandColors.primary
const warningColor = brandColors.warning
const margin = 10

export default {
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: '500',
    height: '40px',
    lineHeight: '40px',
    padding: '0 20px',
    color: colors.gray.normal,
    backgroundColor: colors.gray.lighter,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderWidth: 0,
    borderStyle: 'none',
    textDecoration: 'none',
    transition: '0.2s',
    cursor: 'pointer',
    marginRight: margin,
    ':hover': {
      backgroundColor: colors.gray.lightest,
    },
    ':focus': {
      outline: 'none',
    },
  },

  small: {
    padding: '0 10px',
    height: '30px',
    lineHeight: '30px',
    fontSize: 11,
  },

  square: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },

  large: {
    padding: '0 30px',
    height: '50px',
    lineHeight: '50px',
    fontSize: 14,
  },

  largeIcon: {
    fontSize: 20,
    padding: '0 15px',
  },

  stacked: {
    marginRight: 0,
    marginBottom: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.gray.lighter,
    borderTopWidth: 0,
  },

  first: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderTop: `1px solid ${colors.gray.lighter}`,
  },

  last: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  noMargin: {
    marginRight: 0,
  },

  noPadding: {
    padding: 0,
  },

  flatLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  disabled: {
    opacity: '.5',
    cursor: 'default',
  },

  primary: {
    backgroundColor: primaryColor,
    color: colors.white,
    ':hover': {
      backgroundColor: Color(primaryColor)
        .lightness(48)
        .hexString(),
    },
  },

  warning: {
    backgroundColor: warningColor,
    color: colors.white,
    ':hover': {
      backgroundColor: Color(warningColor)
        .lightness(48)
        .hexString(),
    },
  },

  positive: {
    backgroundColor: colors.green.normal,
    color: colors.white,
    ':hover': {
      backgroundColor: colors.green.light,
    },
  },

  negative: {
    backgroundColor: colors.red.normal,
    color: colors.white,
    ':hover': {
      backgroundColor: colors.red.light,
    },
  },

  action: {
    backgroundColor: colors.red.normal,
    color: colors.white,
    ':hover': {
      backgroundColor: Color(colors.red.normal)
        .lightness(48)
        .hexString(),
    },
  },

  simple: {
    backgroundColor: colors.white,
    marginRight: 0,
    ':hover': {
      backgroundColor: colors.gray.lightest,
    },
  },

  transparent: {
    backgroundColor: 'transparent',
    color: colors.gray.lighter,
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.gray.light,
    },
  },

  spinner: {
    marginRight: '1em',
    verticalAlign: 'middle',
    height: '40px',
  },

  tableHeader: {
    backgroundColor: colors.gray.lightest,
    marginRight: 0,
    ':hover': {
      backgroundColor: colors.gray.lighter,
    },
  },

  xsmall: {
    padding: '0 5px',
    height: '20px',
    lineHeight: '20px',
  },
}
