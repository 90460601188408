import compose from 'recompose/compose'
import onlyUpdateForPropTypes from 'recompose/onlyUpdateForPropTypes'
import PropTypes from 'prop-types'
import React from 'react'
import setPropTypes from 'recompose/setPropTypes'

import Entry from './components/entry'
import New from './components/new'

const PROP_TYPES = {
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  toggleIcon: PropTypes.string,
  urlGeometry: PropTypes.object,
}

export default compose(
  onlyUpdateForPropTypes,
  setPropTypes(PROP_TYPES)
)(TaskView)

function TaskView(props) {
  const {
    id,
    onClose: handleClose,
    toggleExpand,
    toggleIcon,
    urlGeometry,
  } = props

  const isExistingTask = !!id

  if (isExistingTask) {
    return (
      <Entry
        id={id}
        onClose={handleClose}
        toggleExpand={toggleExpand}
        toggleIcon={toggleIcon}
      />
    )
  }

  return (
    <New
      onClose={handleClose}
      toggleExpand={toggleExpand}
      toggleIcon={toggleIcon}
      urlGeometry={urlGeometry}
    />
  )
}
