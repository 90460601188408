import {
  map,
} from 'lodash'

import {
  actions,
} from '../'

import {
  parseState,
} from '../../../helpers'

export default function reducer(state, action = {}) {
  state = parseState(state)

  switch (action.type) {
    case actions.NEARBY_REQUEST:
      return state.set('state', 'resolving')
    case actions.NEARBY_SUCCESS:
      state = state.set('state', 'resolved')
      state = state.set('items', map(action.data, '_id'))
      state = state.set('error', null)
      return state
    case actions.NEARBY_ERROR:
      state = state.set('state', 'resolved')
      state = state.set('error', action.error)
      return state
    case actions.NEARBY_RESET:
      return state.set('items', [])
    default:
      return state
  }
}
