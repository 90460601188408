import React from 'react'

import { Base } from '../'

export default function Block(props) {
  const { children, dataTestId, onClick, onKeyDown, ...componentProps } = props

  return (
    <Base
      cursor={onClick ? 'pointer' : 'inherit'}
      dataTestId={dataTestId}
      display="block"
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...componentProps}
    >
      {children}
    </Base>
  )
}
