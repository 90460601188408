import moment from 'moment-timezone'

import { GetNext, Interval, Unit } from '../scheduling.types'

/**
 * Generates next occurrence interval for last day of month strategy
 */
export function* getNextLastDayOfMonth({
  end,
  isInitial,
  options,
  start,
  timezone,
}: GetNext): IterableIterator<Interval> {
  const { duration, frequency } = options
  const { unit: durationUnit, value: durationValue } = duration
  const { unit: frequencyUnit, value: frequencyValue } = frequency

  let dateCursor: number = isInitial
    ? moment
        .tz(start, timezone)
        .startOf(Unit.Month)
        .valueOf()
    : moment
        .tz(start, timezone)
        .add(frequencyValue, frequencyUnit)
        .startOf(Unit.Month)
        .valueOf()

  while (dateCursor < end) {
    const nextOccurrenceEnd: number = moment
      .tz(dateCursor, timezone)
      .endOf(Unit.Month)
      .add(1, Unit.Day)
      .startOf(Unit.Day)
      .valueOf()

    const nextOccurrenceStart: number = moment
      .tz(nextOccurrenceEnd, timezone)
      .subtract(durationValue, durationUnit)
      .valueOf()

    if (nextOccurrenceEnd <= nextOccurrenceStart || nextOccurrenceEnd > end) {
      return
    }

    if (nextOccurrenceStart >= start) {
      const nextOccurrence: Interval = [
        nextOccurrenceStart,
        nextOccurrenceEnd - 1,
      ]

      yield nextOccurrence
    }

    const nextDateCursor: number = moment
      .tz(dateCursor, timezone)
      .add(1, Unit.Month)
      .valueOf()

    dateCursor = nextDateCursor
  }
}
