import PropTypes from 'prop-types'
import React from 'react'

import { colors } from 'config/theme'
import Button from 'components/button'
import Icon from 'components/icon'

const styles = {
  fontSize: 20,
  color: colors.gray.dark,
}

export default function ListAction(props) {
  const { icon, onClick, ...customStyles } = props

  return (
    <Button onClick={onClick} padding="0 10px" {...customStyles}>
      <Icon name={icon} theme={styles} />
    </Button>
  )
}

ListAction.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
