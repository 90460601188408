import { chain, get } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getModule } from '@lighthouse/sdk'
import Radium from 'radium'
import React, { Component } from 'react'
import Resolver from 'components/resolver'

import { Block } from 'components/common'
import colors from 'config/theme/colors'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'

import ControlWrapper from '../../../../control-wrapper'
import FieldWrapper from '../../../../field-wrapper'
import ListItem from '../../../../list-item'
import { withTranslation } from 'react-i18next'

const contentTemplates = getModule('content', 'templates')
const listId = 'all'

class Template extends Component {
  constructor(props) {
    super(props)

    this.onCancel = this.onCancel.bind(this)
    this.onTemplateSelect = this.onTemplateSelect.bind(this)
  }

  render() {
    const { contentTemplate, templates, updateWizard, t } = this.props

    const templateId = contentTemplate && contentTemplate._id

    const templateItems = chain(templates)
      .sortBy(['entity.name'])
      .map(({ entity }, index) => (
        <ListItem
          active={templateId === entity._id}
          item={entity}
          key={index}
          onClick={() => this.onTemplateSelect(entity._id)}
        />
      ))
      .value()

    const isDisabled = !templateId

    return (
      <Block>
        <FieldWrapper top={156}>{templateItems}</FieldWrapper>
        <ControlWrapper>
          <ButtonGroup>
            <Button onClick={this.onCancel}>{t('button.cancel')}</Button>
            <Button
              disabled={isDisabled}
              theme="positive"
              onClick={() => updateWizard({ selectedTemplate: true })}
            >
              {t('button.next')}
            </Button>
          </ButtonGroup>
        </ControlWrapper>
      </Block>
    )
  }

  onCancel() {
    this.props.updateAreaView({ isNewEntryMode: false })
  }

  onTemplateSelect(templateId) {
    const { getTemplate, updateWizard } = this.props

    const contentTemplate = getTemplate(templateId)

    updateWizard({ contentTemplate })
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  Radium
)(Template)

function mapStateToProps(state) {
  const selectors = contentTemplates.selectors(state)(listId)

  return {
    getTemplate: id => get(state, `content.templates.cache.${id}`),
    templates: selectors.list(),
  }
}
