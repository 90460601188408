/*
 * Dots Spinner
 * =======
 * Based on https://github.com/elementalui/elemental
 */

import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import colors from 'config/theme/colors'
import { colors as brandColors } from 'config/theme/brand'

// https://github.com/FormidableLabs/radium/tree/master/docs/api#keyframes
const pulseKeyframes = Radium.keyframes(
  {
    '0%': {
      opacity: 0,
    },
    '40%': {
      opacity: 1,
    },
    '80%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
  'Spinner'
)

const styles = {
  root: {
    display: 'inline-block',
  },
  'align-middle': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    display: 'inline-block',
    fontSize: 14,
    // height: 40,
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  'spinner-small': {
    fontSize: 4,
    // height: 20
  },
  'spinner-medium': {
    fontSize: 8,
    // height: 20
  },
  'spinner-large': {
    fontSize: 24,
    // height: 60
  },
  'theme-primary': {
    backgroundColor: brandColors.primary,
  },
  'theme-secondary': {
    backgroundColor: brandColors.primary,
  },
  'theme-inverted': {
    backgroundColor: 'white',
  },
  dot: {
    animation: 'x 1s infinite ease-in-out',
    animationName: pulseKeyframes,
    backgroundColor: colors.gray.light,
    borderRadius: '50%',
    display: 'inline-block',
    height: '1em',
    verticalAlign: 'middle',
    width: '1em',
  },
  firstDot: {},
  secondDot: {
    animationDelay: '0.16s',
    marginLeft: '.75em',
  },
  thirdDot: {
    animationDelay: '0.32s',
    marginLeft: '.75em',
  },
}

export default Radium(DotsSpinner)

function DotsSpinner(props) {
  const { size, theme, align, height, customStyles } = props
  const rootStyles = [styles.root, customStyles]
  const spinnerStyles = [styles.spinner]
  const dotStyles = [styles.dot]

  if (height) {
    rootStyles.push({ height })
  }

  if (size) {
    spinnerStyles.push(styles[`spinner-${size}`])
  }

  if (theme) {
    dotStyles.push(styles[`theme-${theme}`])
  }

  if (align === 'middle') {
    rootStyles.push(styles[`align-${align}`])
  }

  return (
    <div style={rootStyles}>
      <div style={spinnerStyles}>
        <span style={[...dotStyles, styles.firstDot]} />
        <span style={[...dotStyles, styles.secondDot]} />
        <span style={[...dotStyles, styles.thirdDot]} />
      </div>
    </div>
  )
}

DotsSpinner.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  theme: PropTypes.oneOf(['primary', 'secondary', 'inverted']),
  align: PropTypes.oneOf(['middle']),
  height: PropTypes.number,
  customStyles: PropTypes.object,
}
