/*
 * Location Module
 */

import { combineReducers } from 'redux'
import crud from '../../crud'
import {
  applicationParamsFn,
  applicationResourceUrlFn,
  authorizationHeadersFn,
} from '../app'

const resource = 'locations'

export { default as schema } from './schema'

export const actions = crud.actions(resource)

const crudActionCreators = crud.actionCreators(actions, {
  baseUrlFn: applicationResourceUrlFn(resource),
  headersFn: authorizationHeadersFn,
  statePath: `${resource}`,
  paramsFn: applicationParamsFn,
})

export const actionCreators = Object.assign(
  {},
  crudActionCreators,
  {
    getGraph,
    updateGraph,
  },
)

export const selectors = crud.selectors(resource)

const crudReducers = crud.reducers(actions)

export const reducer = combineReducers({
  ...crudReducers,
})

function getGraph(locationId, options = {}) {
  const path = `${locationId}/graph`
  return crudActionCreators.request(path, options)
}

function updateGraph(locationId, payload, params = {}) {
  const path = `${locationId}/graph`
  const method = 'PUT'

  return crudActionCreators.request(path, {
    method,
    payload,
    params
  })
}