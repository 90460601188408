import { colors as brandColors } from 'config/theme/brand'
import colors from 'config/theme/colors'

export default {
  // TODO delete me
  placeholder: {
    background: 'f1f1f1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    color: '#CCC',
    height: 300,
    fontSize: 20,
    textAlign: 'center',
  },
  header: {
    position: 'relative',
    padding: 20,
    backgroundColor: brandColors.primary,
  },
  area: {
    color: colors.white,
    fontWeight: 100,
    marginTop: 20,
    opacity: '.5',
  },
  title: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 300,
    marginTop: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'transparent',
    fontSize: 10,
    color: colors.blue.lightest,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    ':hover': {
      backgroundColor: colors.blue.light,
      color: colors.white,
    },
  },

  addTemplate: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
    width: 50,
    height: 51,
    marginRight: 0,
    padding: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: colors.white,
    fontSize: '32px',
    borderLeft: `1px solid ${colors.gray.lightest}`,
    borderBottom: `1px solid ${colors.gray.lightest}`,
    color: colors.gray.lighter,
    ':hover': {
      color: colors.gray.light,
    },
  },
  spinner: {
    marginTop: 55,
    marginBottom: 55,
  },
}
