import colors from 'config/theme/colors'

export default {
  root: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    padding: '6px 6px 0 6px',
    borderLeft: `1px solid ${colors.gray.lighter}`,
  },
  clearFilters: {
    height: 36,
    lineHeight: '36px',
    padding: '0 10px',
    cursor: 'pointer',
    color: colors.gray.light,
    ':hover': {
      color: colors.gray.normal,
    },
    fontSize: '12px',
    margin: '0 6px 6px 0',
  },
}
