/*
 * Dashboard Analytics Module
 */

import {
  applicationResourceUrlFn,
  applicationParamsFn,
  authorizationHeadersFn,
} from '../app'
import buildActionCreators from './action-creators'
import reducer from './reducer'

const resource = 'analytics'

const actions = {
  QUERY_REQUEST: `lighthouse/${resource}/QUERY_REQUEST`,
  QUERY_SUCCESS: `lighthouse/${resource}/QUERY_SUCCESS`,
  QUERY_ERROR: `lighthouse/${resource}/QUERY_ERROR`,
  SET_FILTERS: `lighthouse/${resource}/SET_FILTERS`,
}

const baseUrlFn = applicationResourceUrlFn(resource)

const actionCreators = buildActionCreators(
  actions,
  baseUrlFn,
  authorizationHeadersFn,
  applicationParamsFn,
)

export {
  actions,
  actionCreators,
  reducer,
}
