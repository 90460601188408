import React from 'react'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'

export default function SaveButton(props) {
  const { t } = useTranslation()

  const {
    dataTestId = 'save-button',
    invalid,
    onClick,
    submitting,
    submittingText = t('button.saving'),
    text = t('button.save'),
  } = props

  const isDisabled = invalid || submitting
  const saveText = submitting ? submittingText : text

  return (
    <Button
      dataTestId={dataTestId}
      disabled={isDisabled}
      loading={submitting}
      onClick={onClick}
      type="button"
      theme="positive"
    >
      {saveText}
    </Button>
  )
}
