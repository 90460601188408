import { Interval } from '../scheduling.types'

/**
 * Check if interval intersects another interval's start
 * Accepts two intervals and checks whether the first intersects the second's start
 *
 * intervalIntersectsStart([2,10], [5,15])
 * returns true
 */
export function intervalIntersectsStart(
  firstInterval: Interval,
  secondInterval: Interval
): Boolean {
  return (
    firstInterval[0] <= secondInterval[0] &&
    firstInterval[1] > secondInterval[0]
  )
}
