// TODO is this universal?
export default class DomainError extends Error {
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name

    // @ts-ignore support both node and browser environments
    if (typeof Error.captureStackTrace === 'function') {
      // @ts-ignore
      Error.captureStackTrace(this, this.constructor)
    } else { 
      this.stack = (new Error(message)).stack
    }
  }
}
