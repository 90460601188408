import React from 'react'

import { colors } from 'config/theme'

export default function TableRow({ children, isHeader }) {
  const style = {
    backgroundColor: isHeader ? colors.gray.lightest : colors.white,
    color: colors.gray.normal,
    height: isHeader ? 50 : 80,
  }

  return <tr style={style}>{children}</tr>
}
