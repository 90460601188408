import React from 'react'
import Radium, { keyframes } from 'radium'
import { assign } from 'lodash'

const blink = keyframes(
  {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.4,
    },
    '100%': {
      opacity: 1,
    },
  },
  'Blink'
)

const animationStyle = {
  animationName: blink,
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
}

export default Radium(({ disabled, children, customStyle, duration = 4 }) => {
  // in secs
  const style = assign({}, customStyle)

  if (!disabled) {
    assign(style, animationStyle, {
      animationDuration: `${duration}s`,
    })
  }

  return <div style={[style]}>{children}</div>
})
