import { Motion, spring } from 'react-motion'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import PropTypes from 'prop-types'
import React from 'react'
import setPropTypes from 'recompose/setPropTypes'
import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'

import { getAlignSelf } from '../../utils'
import { Block } from 'components/common'
import ButtonNext from 'components/button-next'
import Icon from 'components/icon'

import {
  FLOATING_BUTTON_ROTATION_DEFAULT,
  FLOATING_BUTTON_ROTATION_MAX,
  FLOATING_BUTTON_ZINDEX,
} from '../../constants'

const PROP_TYPES = {
  enabled: PropTypes.bool.isRequired,
  listVisible: PropTypes.bool.isRequired,
  rotation: PropTypes.number.isRequired,
  screenPosition: PropTypes.string.isRequired,
  setListVisibility: PropTypes.func.isRequired,
  setRotation: PropTypes.func.isRequired,
}

export default compose(
  withState('rotation', 'setRotation', 0),
  setPropTypes(PROP_TYPES),
  withHandlers({ handleClick }),
  lifecycle({ componentDidUpdate })
)(FloatingButton)

function FloatingButton(props) {
  const {
    dataTestId,
    enabled,
    handleClick,
    listVisible,
    rotation,
    screenPosition,
    setListVisibility,
    setRotation,
  } = props

  const alignSelf = getAlignSelf(screenPosition)

  return (
    <Motion
      defaultStyle={{ rotate: FLOATING_BUTTON_ROTATION_DEFAULT }}
      style={{ rotate: spring(rotation) }}
    >
      {style => {
        const transform = `rotate(${style.rotate}deg)`

        return (
          <Block alignSelf={alignSelf}>
            <ButtonNext
              dataTestId={dataTestId}
              circle
              disabled={!enabled}
              large
              onClick={handleClick}
              padding={0}
              primary
              zIndex={FLOATING_BUTTON_ZINDEX}
              pointer-events={'all'}
            >
              <Icon lineHeight={null} name="add-thick" transform={transform} />
            </ButtonNext>
          </Block>
        )
      }}
    </Motion>
  )
}

function componentDidUpdate(prevProps) {
  const { listVisible, setRotation } = this.props

  if (listVisible !== prevProps.listVisible) {
    if (listVisible) {
      return setRotation(FLOATING_BUTTON_ROTATION_MAX)
    }

    setRotation(FLOATING_BUTTON_ROTATION_DEFAULT)
  }
}

function handleClick(props) {
  const { listVisible, setListVisibility } = props

  return () => setListVisibility(!listVisible)
}
