// requesting with server
export const RESOLVING = 'resolving'
// request complete (fetch, save) but not necessarily successful
export const RESOLVED = 'resolved'
// saving the document failed
export const SAVE_FAILED = 'save-failed'
// removing the document failed
export const REMOVE_FAILED = 'remove-failed'
// data is stale and should be re-fetched (useful for caching)
export const INVALIDATED = 'invalidated'
// data was saved optimistically but eventually failed
export const ROLLED_BACK = 'rolled-back'
