export function getAlignSelf(screenPosition) {
  if (screenPosition === 'topright' || screenPosition === 'bottomright') {
    return 'flex-end'
  }

  return 'flex-start'
}

export function getFlexColumnDirection(screenPosition) {
  if (screenPosition === 'topleft' || screenPosition === 'topright') {
    return 'column-reverse'
  }

  return 'column'
}

export function getFlexRowDirection(screenPosition) {
  if (screenPosition === 'bottomleft' || screenPosition === 'topleft') {
    return 'row-reverse'
  }

  return 'row'
}

export default {
  getFlexColumnDirection,
  getFlexRowDirection,
  getAlignSelf,
}
